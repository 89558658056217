import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { DateFormatType, formatDate } from '@utils';
import ArrowIcon from '~/assets/svg/controls/arrow--right.svg';
import { CalendarType } from '.';
import { CalendarHeaderStyled } from './styles';

type CustomHeaderProps = ReactDatePickerCustomHeaderProps & {
    calendarType: CalendarType;
    onCalendarTypeChange: (calendarType: CalendarType) => void;
    title?: string;
};

export const CustomHeader = ({
    date,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    calendarType,
    title,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    onCalendarTypeChange
}: CustomHeaderProps) => (
    <CalendarHeaderStyled>
        {title && <span>{title}</span>}
        <div>
            <button
                onClick={calendarType === 'day' ? decreaseMonth : decreaseYear}
                disabled={prevMonthButtonDisabled}
                type='button'>
                <ArrowIcon />
            </button>

            <button
                type='button'
                className='date-title'
                onClick={() => onCalendarTypeChange(calendarType === 'day' ? 'month' : 'year')}>
                {calendarType === 'day'
                    ? formatDate(date, 'MMM, yyyy' as DateFormatType)
                    : formatDate(date, 'YYY' as DateFormatType)}
            </button>

            <button
                onClick={calendarType === 'day' ? increaseMonth : increaseYear}
                disabled={nextMonthButtonDisabled}
                type='button'>
                <ArrowIcon />
            </button>
        </div>
    </CalendarHeaderStyled>
);
