export const DownloadFileToDesktop = (uri: string, filename: string) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = filename;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const isFileOverSize = (file: File, maxSizeInBytes: number) => {
    return file.size > maxSizeInBytes;
};

export const getFilenameFromURL = (url: string) => {
    if (!url) {
        return url;
    }

    const parts = url.split('/');
    const filename = parts[parts.length - 1];
    return filename;
};

export const getFileExtension = (filename: string) => {
    if (!filename) {
        return filename;
    }

    const parts = filename.split('.');
    if (parts.length > 1) {
        return parts[parts.length - 1];
    }

    return null;
};

export const getFileContentAsync = async (file: Blob) => {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = () => {
            reject(new Error('Failed to read file'));
        };

        reader.readAsText(file as Blob);
    });
};

export const getFileSeparator = (firstLineFile: string) => {
    const allowedSeparators = [';', ',', '\t', '|'];

    let defaultSeparator = null;

    for (const separator of allowedSeparators) {
        if (firstLineFile.includes(separator)) {
            defaultSeparator = separator;
            break;
        }
    }

    return defaultSeparator;
};

export const mapFileLines = (content: string) => {
    if (content) {
        const fileLines = (content as string).split('\n');
        const firstLine = fileLines[0];
        const defaultSeparator = getFileSeparator(firstLine);

        return fileLines.map((line) => {
            return line.replaceAll('\r', '').split(defaultSeparator);
        });
    }

    return null;
};
