import styled from 'styled-components';
import { InputGroup } from '../inputGroup';

export const InputGroupStyled = styled(InputGroup)`
    margin-bottom: 0.2rem;
    margin-top: 2.4rem;

    div:first-child {
        max-width: 25.6rem;
    }

    button {
        width: auto;
    }
`;
