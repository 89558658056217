import { Shimmer } from '../shimmer';
import { FunnelStage, FunnelStageProps } from './funnelStage';
import { FunnelChartStyled } from './styles';

type FunnelChartProps<T> = FunnelStageProps<T> & {
    isLoading?: boolean;
};
export const FunnelChart = <T,>({ content, data, stage, backgroundColor, isLoading = false }: FunnelChartProps<T>) => {
    return (
        <Shimmer isActive={isLoading}>
            <FunnelChartStyled>
                <FunnelStage
                    data-shimmer
                    backgroundColor={backgroundColor}
                    stage={stage}
                    data={data}
                    content={content}
                />
            </FunnelChartStyled>
        </Shimmer>
    );
};

export * from './funnelStage';
