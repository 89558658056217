import { CardBody, CardHeader } from '@components';
import { useTranslator } from '@hooks';
import { ContactConfiguration as ContactConfigurationModel, SendingTechnologyTypeEnum } from '@models';
import { SubToolColors } from '~/styles/theme';
import { Contact } from './contact';
import { CardStyled } from './styles';

type ContactConfigurationProps = {
    contacts?: Array<ContactConfigurationModel>;
    allowedSendingTechnologies: Array<SendingTechnologyTypeEnum>;
    subToolColor?: SubToolColors;
    showIsActiveCheckbox?: boolean;
};

export const ContactConfiguration = ({
    allowedSendingTechnologies,
    subToolColor,
    contacts = [],
    showIsActiveCheckbox
}: ContactConfigurationProps) => {
    const { title } = useTranslator().subTools.contactConfiguration;
    return (
        <CardStyled type='group' subToolColor={subToolColor}>
            <CardHeader title={String(title)} />
            <CardBody>
                {allowedSendingTechnologies.map((sendingTechnology, index) => {
                    const maybeContactConfiguration = contacts.find(
                        (contact) => contact.sendingTechnology === sendingTechnology
                    );
                    return (
                        <Contact
                            key={maybeContactConfiguration?.id ?? index}
                            contact={{
                                index,
                                isActive: true,
                                contactConfigurationId: maybeContactConfiguration?.id ?? 0,
                                sendingTechnology
                            }}
                            showIsActiveCheckbox={showIsActiveCheckbox}
                        />
                    );
                })}
            </CardBody>
        </CardStyled>
    );
};
