import styled, { css } from 'styled-components';
import BaterryIcon from '~/assets/svg/icons/icon__battery.svg';
import ChromeIcon from '~/assets/svg/icons/icon__chrome.svg';
import ImageIcon from '~/assets/svg/icons/icon__image-file.svg';
import MoreHorizontal from '~/assets/svg/icons/icon__more.svg';
import CloseIcon from '~/assets/svg/icons/icon__plus.svg';
import VolumeIcon from '~/assets/svg/icons/icon__volume.svg';
import WifiIcon from '~/assets/svg/icons/icon__wifi.svg';
import { mobileOnly } from '~/styles';

export const WindowsContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.philippineSilver};
    display: flex;
    flex-direction: column;
    max-width: 42rem;
    min-width: 37rem;
`;

export const TaskbarStyled = styled.div`
    background-color: ${({ theme }) => theme.colors.grayPurple};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2rem;
    padding: 0.8rem;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.gallery};

    .date-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
            color: ${({ theme }) => theme.colors.geminiGray};
            font: normal 1.2rem League Spartan;
            font-size: 1.2rem;
            letter-spacing: 0.13px;
        }
    }
`;

export const NotificationContainerStyled = styled.div`
    display: grid;
    grid-template-rows: 16rem 2.6rem 1fr auto;
    border-radius: 0px 0px 6px 6px;
    background-color: ${({ theme }) => theme.colors.white};
    margin: 1rem 0.8rem 0.6rem 5rem;
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};

    .no-image {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.gallery};
    }

    .notification-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem 0.5rem 1rem;

        .browser-info {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .browser-name {
                font-size: 1.2rem;
                color: ${({ theme }) => theme.colors.geminiGray};
                line-height: unset;
            }
        }

        .notification-header-icons {
            display: flex;
            align-items: center;
            gap: 0.6rem;
        }
    }

    .notification-body {
        margin-top: 1rem;
        padding: 0 1.2rem;
        display: inline-grid;
        grid-template-columns: 4rem auto;
        gap: 1rem;
        color: ${({ theme }) => theme.colors.geminiGray};

        .icon {
            background-color: ${({ theme }) => theme.colors.white};
            height: 3.8rem;
            width: 3.8rem;
            display: flex;
            border-radius: 6px;
            justify-content: center;
            align-items: center;

            .no-image {
                background-color: ${({ theme }) => theme.colors.gallery};
                border: 1px solid ${({ theme }) => theme.colors.geminiGray};
            }
        }

        .notification-message {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .title {
                font-size: 1.4rem;
                font-weight: ${({ theme }) => theme.fontWeights.medium};
                margin-bottom: 0.5rem;
                color: ${({ theme }) => theme.colors.geminiGray};

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .text {
                font-size: 1.2rem;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .domain {
                margin-top: 0.6rem;
                font-size: 1.2rem;
            }
        }
    }

    .notification-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        padding: 0 1.2rem;
        gap: 2rem;

        .action-button {
            display: flex;
            flex: 1;
            align-items: center;
            width: 15.5rem;
            height: 2.7rem;
            border: 1px solid ${({ theme }) => theme.colors.geminiGray};
            border-radius: 6px;
            padding: 0.5rem;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.geminiGray};
            margin-bottom: 1rem;
            line-height: unset;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;

            ${mobileOnly(css`
                width: 13rem;
            `)}
        }
    }
`;

export const ImageIconStyle = styled(ImageIcon)`
    width: 2.5rem;
`;

export const ChromeIconStyle = styled(ChromeIcon)`
    fill: ${({ theme }) => theme.colors.geminiGray};
    width: 1.5rem;
`;

export const WifiIconStyle = styled(WifiIcon)`
    width: 2.2rem;
`;

export const VolumeIconStyle = styled(VolumeIcon)`
    width: 2.2rem;
`;

export const BaterryIconStyled = styled(BaterryIcon)`
    width: 2.5rem;
`;

export const CloseIconStyled = styled(CloseIcon)`
    align-self: center;
    transform: rotate(45deg);
    width: 1rem;
    height: 1rem;
    color: ${({ theme }) => theme.colors.gray};
`;

export const MoreHorizontalStyled = styled(MoreHorizontal)`
    transform: rotate(90deg);
    width: 1.2rem;
    height: 1.2rem;
    color: ${({ theme }) => theme.colors.gray};
`;
