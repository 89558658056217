import styled from 'styled-components';

export const WhatsappManualContentStyled = styled.div`
    display: flex;
    gap: 0.8rem;
    flex: 1;
    align-items: center;
    cursor: default;
    justify-content: flex-end;

    // iury.sousa - Remover Quando a Modal de Modelos de Whatsapp manual for feita
    visibility: hidden;

    & > button {
        height: 4rem;
        width: 4rem;
        padding: 0;

        svg {
            height: 4rem;
            width: 4rem;
        }
    }
`;

export const WhatsappManualInputStyled = styled.div`
    width: 100%;
    height: 4rem;
    max-width: 28.5rem;
    border: 1px solid ${({ theme }) => theme.colors.purple};
    border-radius: 0.4rem;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    & > svg {
        height: 4rem;
        width: 4rem;
        color: ${({ theme }) => theme.colors.green};
    }

    & > p {
        flex: 1;
    }

    & > button {
        border-radius: 0;
        padding: 0;
        height: 4rem;
        width: 4rem;
        svg {
            height: 3.2rem;
            width: 3.2rem;
        }
    }
`;
