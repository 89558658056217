import HTMLReactParser from 'html-react-parser';
import { format } from '~/utils/whatsappFormat';
import { ContainerStyled, ConversationStyled } from './styles';

type WhatsAppTemplatePreviewProps = {
    message?: string;
};

export const WhatsAppTemplatePreview = ({ message }: WhatsAppTemplatePreviewProps) => {
    return (
        <ContainerStyled>
            <ConversationStyled>
                <div>
                    <p>{HTMLReactParser(format(message))}</p>
                </div>
            </ConversationStyled>
        </ContainerStyled>
    );
};
