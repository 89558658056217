import { InputFormGroup } from '@components';
import { useTranslator } from '@hooks';
import { PlanCalculatorStyled } from './styles';

export const AutomationToolFormContent = () => {
    const {
        checkoutPage: { planCalculatorModal }
    } = useTranslator();

    return (
        <PlanCalculatorStyled>
            <InputFormGroup
                label={String(planCalculatorModal.form.storeVisitsAmount.label)}
                name='storeVisitsAmount'
                type='text'
                mask='integer'
                autoFocus
                placeholder={String(planCalculatorModal.form.storeVisitsAmount.placeholder)}
            />
            <InputFormGroup
                label={String(planCalculatorModal.form.storeOrderAmount.label)}
                name='storeOrderAmount'
                type='text'
                mask='integer'
                placeholder={String(planCalculatorModal.form.storeOrderAmount.placeholder)}
            />
            <InputFormGroup
                label={String(planCalculatorModal.form.storeBankSlipOrderAmount.label)}
                name='storeBankSlipOrderAmount'
                type='text'
                mask='integer'
                placeholder={String(planCalculatorModal.form.storeBankSlipOrderAmount.placeholder)}
            />
        </PlanCalculatorStyled>
    );
};
