import { MouseEventHandler } from 'react';
import ArrowRight from '~/assets/svg/controls/arrow--right.svg';
import { FontSizes } from '~/styles/tokens';
import { Text } from '../../text';
import { CardLinkStyled } from './styles';

type CardLinkProps = {
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    testId?: string;
    size?: FontSizes;
    isUnderline?: boolean;
    showArrow?: boolean;
};

export const CardLink = ({
    text,
    testId,
    onClick,
    size = 'xs',
    isUnderline = false,
    showArrow = true,
    ...props
}: CardLinkProps) => {
    return (
        <CardLinkStyled
            isUnderline={isUnderline}
            color='deepCerise'
            styledAs='link'
            onClick={onClick}
            testId={testId}
            {...props}>
            <Text as='span' size={size}>
                {text}
            </Text>
            {showArrow && <ArrowRight />}
        </CardLinkStyled>
    );
};
