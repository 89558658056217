import { useMemo } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { useRouter } from 'next/router';
import { Button, Dialog, DialogHeading, DialogClose, DialogButtonClose, Loading } from '@components';
import { useTranslator, useFetch } from '@hooks';
import { MessageSequenceStep, MessageSequenceStepTemplate, SubToolIdEnum, Theme, themeTypeBySubToolId } from '@models';
import { AlertOptions } from '@providers';
import { captureException } from '@sentry/nextjs';
import { VALUE_TEN } from '@utils';
import { GetThemeTemplateAsync, NEWSLETTER_THEME_DETAILS_URL } from '~/services/newsletterApiService';
import {
    normalizeBaseThemeVariables,
    removeBaseThemeVariables,
    translatedStepTemplates
} from '~/services/templateService';
import { EmailTemplatePreview } from '../../emailTemplate/emailTemplatePreview';
import { ThemePreviewModalBodyStyled, ThemePreviewModalFooterStyled } from './styles';

type ThemePreviewModalProps = {
    options: AlertOptions;
    theme?: Theme;
    variables: Array<string>;
    subToolId: SubToolIdEnum;
    onSelectTheme: (theme: Theme) => void;
};

export const ThemePreviewModal = ({ options, theme, variables, subToolId, onSelectTheme }: ThemePreviewModalProps) => {
    const { preview, actions } = useTranslator()?.subTools?.newsletterTheme;
    const { locale, locales, defaultLocale } = useRouter();
    const { data: selectedTheme, isLoading } = useFetch(
        theme?.id && {
            url: NEWSLETTER_THEME_DETAILS_URL,
            themeId: theme.id,
            themeType: themeTypeBySubToolId[subToolId]
        },
        async ({ themeId, themeType }) => {
            const response = GetThemeTemplateAsync(themeId, themeType);

            return (await response).data;
        },
        {
            onError: (error) => captureException(error),
            dedupingInterval: minutesToMilliseconds(VALUE_TEN)
        }
    );

    const handleSelectTheme = () => {
        onSelectTheme(theme);
        options.onOpenChange(false);
    };

    const currentTemplate = useMemo(() => {
        if (!selectedTheme) {
            return {} as MessageSequenceStepTemplate;
        }
        const defaultTheme = removeBaseThemeVariables(selectedTheme);
        const { baseHTML, productHtml, reviewHtml } = normalizeBaseThemeVariables({
            baseHTML: defaultTheme.htmlBase,
            productHtml: defaultTheme.htmlItems,
            reviewHtml: defaultTheme.htmlReviews
        });

        let templateStep = {
            templates: [
                {
                    baseContent: baseHTML,
                    productContent: productHtml,
                    reviewContent: reviewHtml
                }
            ]
        } as MessageSequenceStep;

        templateStep = translatedStepTemplates({ locale, locales, defaultLocale }, templateStep, subToolId);
        const emailTemplate = templateStep.templates[0];

        return emailTemplate;
    }, [selectedTheme, subToolId, locale, locales, defaultLocale]);

    return (
        <Dialog {...options}>
            <ThemePreviewModalBodyStyled>
                <DialogHeading>{`${preview?.title}: ${theme?.name}`}</DialogHeading>
                {!isLoading && (
                    <EmailTemplatePreview
                        subject={`${preview?.title}: ${theme?.name}`}
                        baseHtml={currentTemplate?.baseContent}
                        productHtml={currentTemplate?.productContent}
                        reviewHtml={currentTemplate?.reviewContent}
                        variables={variables}
                        subToolId={subToolId}
                    />
                )}
                <Loading inLoading={isLoading} size='large' />
                <DialogClose />

                {!isLoading && (
                    <ThemePreviewModalFooterStyled>
                        <DialogButtonClose color='purple' isOutlined>
                            {String(actions.cancel)}
                        </DialogButtonClose>
                        <Button color='purple' onClick={handleSelectTheme}>
                            {String(actions.selectTheme)}
                        </Button>
                    </ThemePreviewModalFooterStyled>
                )}
            </ThemePreviewModalBodyStyled>
        </Dialog>
    );
};
