import { ContainerStyled, SMSChatStyled } from './styles';

type SmsTemplatePreviewProps = {
    message?: string;
};

export const SmsTemplatePreview = ({ message }: SmsTemplatePreviewProps) => {
    return (
        <ContainerStyled>
            <SMSChatStyled>
                <div>
                    <p>{message}</p>
                </div>
            </SMSChatStyled>
        </ContainerStyled>
    );
};
