import styled, { css } from 'styled-components';
import ArrowRightIcon from '~/assets/svg/controls/arrow--right.svg';

type CarouselContainerStyledProps = {
    isLoading?: boolean;
    initialSpacing?: number;
};

export const CarouselContainerStyled = styled.div<CarouselContainerStyledProps>`
    position: relative;
    ${({ isLoading }) =>
        isLoading &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `}

    .keen-slider:not([data-keen-slider-disabled]) {
        align-content: flex-start;
        display: flex;
        overflow: hidden;
        position: relative;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
        width: 100%;
        gap: ${({ initialSpacing = 0 }) => `${initialSpacing}px`};
        transition: gap 0.1s linear;

        .keen-slider__slide {
            position: relative;
            width: 100%;
            min-height: 100%;
        }

        &[data-keen-slider-reverse] {
            flex-direction: row-reverse;
        }

        &[data-keen-slider-v] {
            flex-wrap: wrap;
        }
    }

    &:hover {
        & > svg {
            opacity: 1;
        }
    }
`;

export type ArrowIconStyledProps = {
    disabled: boolean;
};

const ArrowIconBaseStyled = styled(ArrowRightIcon)<ArrowIconStyledProps>`
    color: ${({ theme }) => theme.colors.purple};
    width: 3rem;
    height: 3rem;
    transition: all 0.15s;
    cursor: pointer;
    position: absolute;
    top: 50%;
    opacity: 0;
    transition: opacity 0.6s;
`;

export const ArrowRightIconStyled = styled(ArrowIconBaseStyled)<ArrowIconStyledProps>`
    right: 0;
    transform: translateY(-50%);
    ${({ theme, disabled }) => css`
        color: ${!disabled ? theme.colors.purple : theme.colors.lightPurple};
    `}
`;

export const ArrowLeftIconStyled = styled(ArrowIconBaseStyled)<ArrowIconStyledProps>`
    transform: rotate(180deg) translateY(50%);

    ${({ theme, disabled }) => css`
        color: ${!disabled ? theme.colors.purple : theme.colors.lightPurple};
    `}
`;
