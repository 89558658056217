import { ReactNode } from 'react';
import Head from 'next/head';
import { useTranslator } from '@hooks';
import { SingInLayoutStyled } from './style';

export const SignInLayout = ({ children }: { children: ReactNode }) => {
    const { signInPage } = useTranslator();

    return (
        <SingInLayoutStyled>
            <Head>
                <title>{String(signInPage.title)}</title>
            </Head>
            {children}
        </SingInLayoutStyled>
    );
};
