import isEmpty from 'lodash/isEmpty';

export type TreeItem<T> = T & { isRoot?: boolean; level?: number; parent?: TreeItem<T>; children?: TreeItem<T>[] };

export const ROOT_NODE_NAME = 'root';

const isChildren = (children: Array<TreeItem<unknown>>) => !isEmpty(children);

export const findItem = <T>(
    elementKey: keyof TreeItem<T>,
    keyValue: string | number,
    node: TreeItem<T>,
    callback: (node: TreeItem<T>) => void
) => {
    if (node[elementKey] === keyValue) {
        callback && callback(node);
    } else {
        if (isChildren(node.children)) {
            for (const child of node.children) {
                findItem(elementKey, keyValue, child, callback);
            }
        }
    }
};

export const getFirstLeafItem = <T>(node: TreeItem<T>): TreeItem<T> | null => {
    if (!node?.isRoot && isEmpty(node?.children)) {
        return node;
    } else {
        if (isChildren(node.children)) {
            for (const child of node.children) {
                const item = getFirstLeafItem(child);

                if (item !== null) {
                    return item;
                }
            }
        }
    }

    return null;
};

export const removeItem = <T>(elementKey: keyof T, id: string | number, items: Array<TreeItem<T>>) => {
    const rootNode = items[0];

    const removeParentItemIfNoChildren = (item: TreeItem<T>) => {
        if (!isChildren(item.children)) {
            remove(item[elementKey], rootNode, items);
        }
    };

    const remove = (nodeId: unknown, item: TreeItem<T>, currentItem: TreeItem<T> | Array<TreeItem<T>>) => {
        if (item[elementKey] === nodeId && !item.isRoot) {
            const currentTreeItem = currentItem as TreeItem<T>;
            const { isRoot, children = [] } = currentTreeItem;
            const { length } = children;

            const canRemoveItem = (isRoot && length > 1) || (!isRoot && length > 0);

            if (canRemoveItem) {
                currentTreeItem.children = children?.filter((child) => child[elementKey] !== nodeId);
            }

            removeParentItemIfNoChildren(currentTreeItem);
        } else if (isChildren(item.children)) {
            for (const child of item.children) {
                remove(nodeId, child, item);
            }
        }
    };

    remove(id, rootNode, items);
};

const addNewNode = <T>(item: T, parent: T, level = 0) => {
    const newNode = item as TreeItem<T>;
    newNode.parent = parent;
    newNode.level = level;
    newNode.isRoot = level === 0;
    return newNode;
};

export const buildTree = <T>(
    array: T[],
    elementKey: keyof T,
    parentKey: keyof T,
    defaultRootKey = ROOT_NODE_NAME
): TreeItem<T>[] => {
    const tree = [] as TreeItem<T>[];

    const hasRootNode = !!array.find((item) => item[elementKey] === defaultRootKey);

    if (hasRootNode === false) {
        array.push({ [elementKey]: defaultRootKey, level: 0 } as T);
    }

    for (const item of array) {
        if (item[parentKey]) {
            const parent = array.filter((elem) => elem[elementKey] === item[parentKey]).pop() as TreeItem<T>;

            if (!isChildren(parent.children)) {
                parent.children = [];
            }

            parent.children.push(addNewNode(item, parent, parent.level + 1));
        } else {
            tree.push(addNewNode(item, null));
        }
    }

    return tree;
};

export const convertTreeToList = <T>(tree: Array<TreeItem<T>>): Array<T> => {
    const treeItems = [...tree];
    const items: Array<T> = [];

    while (treeItems.length !== 0) {
        const item = treeItems.pop();

        if (!isChildren(item.children)) {
            items.push(item);
        } else {
            const newItem = { ...item };
            delete newItem.children;
            items.push(newItem);

            for (let i = item.children.length - 1; i >= 0; i--) {
                treeItems.push(item.children[i]);
            }
        }
    }

    return items;
};
