import styled from 'styled-components';

export const TermsAndConditionModalStyled = styled.div`
    width: 120rem;
    height: 100%;
    object {
        width: 100%;
        height: 62vh;
    }
`;
