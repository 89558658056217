import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ExpandableGroup } from '~/components/elements/expandableGroup';

export const ContainerStyled = styled(ExpandableGroup)`
    display: flex;
    flex-direction: column;
    overflow: inherit;
`;

export const AutomationItemStyled = styled(motion.div)`
    margin-bottom: 13rem;
`;

export const AutomationRulerContainerStyled = styled(ExpandableGroup)`
    overflow: inherit;
    & > div {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
    }
`;

export const HeaderStyled = styled.header`
    margin-bottom: 2.4rem;
    display: flex;
    justify-content: space-between;
    gap: 3.2rem;
    flex-wrap: wrap;

    & > button {
        height: fit-content;
        margin: auto 0;
    }

    & > form {
        display: flex;

        & > div {
            flex-direction: row;
            align-items: center;
            padding: 0;
            margin: auto 0;

            label {
                font-weight: normal;
                margin: 0;
                margin-right: 1.6rem;
            }

            & > div {
                min-width: 25.3rem;
                flex: 1;

                & > div {
                    min-width: 28.7rem;
                    right: 0;
                }
            }
        }
    }
`;

export const CustomTriggerButtonsContainer = styled(motion.div)`
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;
