import { PropsWithChildren } from 'react';
import { Element, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { ButtonStyled } from './styles';

type CliqueHereToViewVideoProps = PropsWithChildren<unknown> & {
    onClick?: () => void;
};

const CliqueHereToViewVideo = ({ children, onClick }: CliqueHereToViewVideoProps) => {
    return <ButtonStyled onClick={onClick}>{children}</ButtonStyled>;
};

export const replaceClickHereValue = (props?: CliqueHereToViewVideoProps) =>
    ({
        replace(domNode) {
            if (domNode instanceof Element && domNode.attribs?.id === 'click-here') {
                return <CliqueHereToViewVideo {...props}>{domToReact(domNode.children)}</CliqueHereToViewVideo>;
            }

            return domNode;
        }
    } as HTMLReactParserOptions);
