import { useSubToolPage, useTranslatedRoute, useTranslator } from '@hooks';
import { SendingTechnology, SendingTechnologyTypeEnum, SubTool, SubToolIdEnum, ToolTypeEnum } from '@models';
import { useAlert } from '@providers';
import { COMERCIAL_WHATSAPP_CHAT } from '@utils';
import ArrowRight from '~/assets/svg/controls/arrow--right.svg';
import { Button, getSendingTechnologyIcons, Heading, Link, Text } from '~/components/elements';
import { routesName } from '~/locales/route';
import { SubToolColors } from '~/styles/theme';
import { AutomationToolModal, PlanCalculatorModal } from '../checkout';
import {
    CalculatePlanCardStyled,
    ContainerStyled,
    ContentStyled,
    PreCheckoutCardStyled,
    SendingTechnologyContentStyled,
    SubscriptionCardStyled,
    SubToolCardStyled
} from './styles';

export type PreCheckoutCardType = 'subTool' | 'calculatePlan' | 'subscription';

type PreCheckoutCardProps = {
    type: PreCheckoutCardType;
    subTool?: SubTool;
    sendingTechnologies?: SendingTechnology[];
};

const Container = ({ children }) => {
    return <ContainerStyled>{children}</ContainerStyled>;
};

const Content = ({ children }) => {
    return <ContentStyled>{children}</ContentStyled>;
};

const SendingTechnologyContent = ({ subToolId }) => {
    const { show } = useAlert();
    const { buttons } = useTranslator();
    const sendingTecnologyIcons = getSendingTechnologyIcons();

    const getIcon = (iconType: SendingTechnologyTypeEnum) =>
        sendingTecnologyIcons.find((icon) => icon.type === iconType);

    return (
        <SendingTechnologyContentStyled>
            {subToolId !== SubToolIdEnum.Emails && (
                <ul>
                    <li>{getIcon(SendingTechnologyTypeEnum.WhatsApp).icon}</li>
                    <li>{getIcon(SendingTechnologyTypeEnum.Push).icon}</li>
                    <li>{getIcon(SendingTechnologyTypeEnum.SMS).icon}</li>
                    <li>{getIcon(SendingTechnologyTypeEnum.Email).icon}</li>
                </ul>
            )}
            <Button
                styledAs='link'
                onClick={() =>
                    show(null, null, {
                        content: (args) => <AutomationToolModal {...args} subToolId={subToolId} />
                    })
                }>
                {String(buttons.viewMore)}
            </Button>
        </SendingTechnologyContentStyled>
    );
};

const SubToolCard = ({ subTool }) => {
    const { getSubToolPageData } = useSubToolPage();
    const { translatedSubToolName, subToolKey, icon } = getSubToolPageData(subTool.id);

    return (
        <PreCheckoutCardStyled>
            <SubToolCardStyled subToolColor={subToolKey as SubToolColors}>
                <i>{icon}</i>
                <header>
                    <Heading as='h6'>{translatedSubToolName}</Heading>
                    <Text size='sm'>{subTool.description}</Text>
                </header>

                <SendingTechnologyContent subToolId={subTool.id} />
            </SubToolCardStyled>
        </PreCheckoutCardStyled>
    );
};

const CalculatePlanCard = ({ sendingTechnologies }) => {
    const translateRoute = useTranslatedRoute();
    const { show } = useAlert();
    const { buttons } = useTranslator();

    const handleChange = (creditsAmount: number) => {
        return creditsAmount;
    };

    const calculatePlan = () => {
        show(null, null, {
            content: (props) => (
                <PlanCalculatorModal
                    {...props}
                    showCustomPlan
                    showSelectPlanButton={false}
                    toolType={ToolTypeEnum.AutomationTool}
                    sendingTechnologies={sendingTechnologies}
                    onSelectPlan={handleChange}
                />
            )
        });
    };

    return (
        <PreCheckoutCardStyled expand>
            <CalculatePlanCardStyled>
                <header>
                    <Heading as='h1' color='purple'>
                        Vamos personalizar a sua experiência?
                    </Heading>
                    <Text size='md'>Responda o nosso Quiz e te indicaremos a Assinatura ideal para você!</Text>
                </header>
                <div>
                    <Button color='purple' onClick={calculatePlan}>
                        {String(buttons.calculatePlan)}
                    </Button>
                    <Link href={routesName.checkout.plan} as={translateRoute(routesName.checkout.plan)}>
                        <Button isOutlined color='purple'>
                            {String(buttons.goToCheckoutPage)}
                        </Button>
                    </Link>

                    <a href={COMERCIAL_WHATSAPP_CHAT} target='_blank' rel='noreferrer'>
                        <Text size='sm' fontWeight='semiBold'>
                            {String(buttons.talkToTheSalesRepresentative)}
                        </Text>
                    </a>
                </div>
            </CalculatePlanCardStyled>
        </PreCheckoutCardStyled>
    );
};

const SubscriptionCard = () => {
    const translateRoute = useTranslatedRoute();

    return (
        <PreCheckoutCardStyled>
            <SubscriptionCardStyled>
                <Link href={routesName.checkout.plan} as={translateRoute(routesName.checkout.plan)}>
                    <div>
                        <Heading as='h2' color='lightGray'>
                            Escolher Assinatura
                        </Heading>
                        <Text size='md'>Ir para o checkout</Text>
                    </div>
                    <i>
                        <ArrowRight />
                    </i>
                </Link>
            </SubscriptionCardStyled>
        </PreCheckoutCardStyled>
    );
};

const PreCheckoutCard = ({ type, subTool, sendingTechnologies }: PreCheckoutCardProps) => {
    if (type === 'subTool') {
        return <SubToolCard subTool={subTool} />;
    } else if (type === 'calculatePlan') {
        return <CalculatePlanCard sendingTechnologies={sendingTechnologies} />;
    } else {
        return <SubscriptionCard />;
    }
};

export const PreCheckout = {
    Container,
    Content,
    Card: PreCheckoutCard,
    SendingTechnologyContent
};
