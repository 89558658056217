import { ArrowIconStyled } from './styles';

type Props = {
    className?: string;
    direction: 'up' | 'down';
};

export const AnimatedArrow = ({ direction, className }: Props) => (
    <ArrowIconStyled direction={direction} className={className} />
);
