import { PaginatedList, SubscriptionInvoice } from '@models';
import axiosClient from './apiService';

export const GetAllPaginatedURL = '/invoice';
type GetAllPaginatedProps = {
    pageNumber?: number;
    pageSize?: number;
};

export const GetAllPaginatedAsync = async (
    { pageNumber = 1, pageSize = 10 }: GetAllPaginatedProps,
    signal: AbortSignal = null
) => {
    return axiosClient.get<PaginatedList<SubscriptionInvoice>>(GetAllPaginatedURL, {
        signal,
        params: {
            pageNumber,
            pageSize
        }
    });
};
