import styled from 'styled-components';
import { Form } from '@unform/web';

const checkoutFormGap = '3.6rem';

export const ActionButtonsContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: calc(5rem - ${checkoutFormGap});
    padding-bottom: 8rem;

    a:only-child {
        margin-left: auto;
    }

    button {
        min-width: 16.4rem;
    }

    #button__submit {
        position: relative;
        overflow: hidden;
    }
`;

export const CheckoutFormStyled = styled(Form)`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${checkoutFormGap};
    justify-content: flex-start;
    align-content: center;
`;
