import { ReactNode } from 'react';
import { SelectFormGroup, SelectData } from '@components';
import { useTranslator } from '@hooks';
import AlignLeftIcon from '~/assets/svg/icons/icon__align-left.svg';
import PlusIcon from '~/assets/svg/icons/icon__plus.svg';
import { RuleDefaultProps } from '../';
import { AddRuleDropdownOptionStyled, InputStyled } from './styles';

type AddRuleDropdownProps = Pick<RuleDefaultProps, 'onCreateRule' | 'rule' | 'onCreateRuleGroup'> & {
    isVisible: boolean;
};

export type AddRuleDropdownType = 'add-rule' | 'add-group';

type DropdownOptions = {
    value: AddRuleDropdownType;
    icon: ReactNode;
    label: string;
};

export const AddRuleDropdown = ({ onCreateRule, onCreateRuleGroup, rule, isVisible = false }: AddRuleDropdownProps) => {
    const {
        subTools: {
            navigationTrigger: {
                abandonmentConfigurationCard: { addRuleDropdown }
            }
        }
    } = useTranslator();

    if (!isVisible) {
        return <></>;
    }

    const handleChange = (value: AddRuleDropdownType) => {
        if (value === 'add-rule') {
            onCreateRule(rule);
            return;
        }

        onCreateRuleGroup(rule);
    };

    const optionsData: Array<DropdownOptions> = [
        {
            value: 'add-rule',
            icon: <PlusIcon />,
            label: String(addRuleDropdown.options.addRule)
        },
        {
            value: 'add-group',
            icon: <AlignLeftIcon />,
            label: String(addRuleDropdown.options.addGroup)
        }
    ];

    const dropdownOptions = optionsData.map(
        (item) =>
            ({
                id: item.value,
                label: item.label,
                children: (
                    <AddRuleDropdownOptionStyled>
                        {item.icon} {item.label}
                    </AddRuleDropdownOptionStyled>
                )
            } as SelectData)
    );

    return (
        <InputStyled className='add-rule-dropdown'>
            <SelectFormGroup
                name='addRule'
                placeholder={String(addRuleDropdown.placeholder)}
                enableUnform={false}
                onOptionChange={({ id }) => handleChange(id as AddRuleDropdownType)}
                disableCheck
                blockChange
                testId={`add-dropdown-${rule.id}`}
                icon={<PlusIcon />}
                options={dropdownOptions}
            />
        </InputStyled>
    );
};
