import styled, { css } from 'styled-components';

export type PhoneProps = {
    isReduced?: boolean;
};

export const PhoneContainerStyled = styled.div<PhoneProps>`
    display: inline-block;
    border-radius: 5.4rem;
    padding: 1rem;
    padding-top: 0.6rem;
    background-color: ${({ theme }) => theme.colors.darkGray};
    margin: 0 auto;
    overflow: hidden;
    min-width: 25rem;
    transition: padding 0.15s ease-out, border-radius 0.15s ease-out;

    ${({ isReduced }) =>
        isReduced === true &&
        css`
            padding-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `};
`;

export const PhoneCameraStyled = styled.div`
    position: relative;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.darkGray};
    height: 2.5rem;
    width: 13.5rem;
    margin: 0 auto;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    z-index: 5;
`;

export const PhoneDisplayStyled = styled.div<PhoneProps>`
    overflow: hidden;
    border-radius: 5rem;
    margin-top: -2rem;
    transition: border-radius 0.15s ease-out;

    ${({ isReduced }) =>
        isReduced === true &&
        css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `};
`;

export const ContentDataStyled = styled.div<PhoneProps>`
    width: 30rem;
    transition: height 0.15s ease-out;
    height: ${({ isReduced }) => (isReduced === true ? '30rem' : '50rem')};
`;
