import styled, { css } from 'styled-components';
import IconAttentionSymbolTriangle from '~/assets/svg/controls/icon__attention-symbol-triangle.svg';
import { desktopOnly, mobileOnly } from '~/styles/mediaQueries';

export const ReasonSuspendedPlanStyled = styled.div`
    padding: 2.8rem 2.4rem 0;

    & + header {
        padding-top: 3.2rem;
    }

    ${desktopOnly(css`
        padding: 3.2rem 4rem 0;
    `)}

    main {
        background-color: ${({ theme }) => theme.colors.white};
        padding: 2.4rem 1.6rem;
        border: 1px solid ${({ theme }) => theme.colors.oldGold};
        border-radius: ${({ theme }) => theme.radii.lg};

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.6rem;

        ${mobileOnly(css`
            flex-direction: column;

            h6,
            p {
                text-align: center;
            }
        `)}

        p {
            margin-top: 0.8rem;
        }

        button {
            white-space: nowrap;
        }
    }
`;

export const IconAttentionSymbolTriangleStyled = styled((props) => <IconAttentionSymbolTriangle {...props} />)`
    width: 3.2rem;
    height: 3.2rem;
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.yellow};

    ${mobileOnly(css`
        width: 4rem;
        height: 4rem;
    `)}
`;
