import { useCallback } from 'react';
import { Button, Card, CardHeader, Text } from '@components';
import { DiscountCoupon } from '@containers';
import { AlertDialog } from '@dialogs';
import { useTranslator } from '@hooks';
import { PaymentMethodTypeEnum } from '@models';
import {
    AlertProvider,
    SubscriptionPaymentFormData,
    SubscriptionReducerActionTypesEnum,
    useCheckout,
    usePaymentMethods
} from '@providers';
import { CurrentPaymentMethod } from './currentPaymentMethod';
import { EditPaymentMethod } from './editPaymentMethod';
import { ReasonSuspendedPlan } from './reasonSuspendedPlan';
import { ActionsButtonStyled, CardBodyStyled } from './styles';

export const PaymentMethods = () => {
    const {
        paymentMethod: { title, infoAlert },
        subscriptionData: { actions }
    } = useTranslator().checkoutPage;

    const { isUpdatingPaymentMethod, setIsUpdatingPaymentMethod, setOldPaymentMethod } = usePaymentMethods();
    const {
        hasACardInCanceledSubscription,
        hasSubscription,
        subscriptionFormData,
        dispatchSubscriptionFormData,
        isSuspendedPlan
    } = useCheckout();

    const isUpdate = (hasSubscription || hasACardInCanceledSubscription) && !isSuspendedPlan;

    const handleUpdatePaymentClick = () => {
        setOldPaymentMethod(subscriptionFormData?.subscriptionPayment);
        setIsUpdatingPaymentMethod(true);
    };
    const actionsButton = (
        <ActionsButtonStyled>
            {!isUpdatingPaymentMethod && (
                <Button
                    color='purple'
                    isOutlined
                    testId='button__edit-payment-method'
                    onClick={handleUpdatePaymentClick}>
                    {actions.edit}
                </Button>
            )}
        </ActionsButtonStyled>
    );

    const handleChangePaymentMethod = useCallback(
        (paymentMethod: PaymentMethodTypeEnum) => {
            const { discountCoupon, hasAcceptedTheTerms } = subscriptionFormData?.subscriptionPayment ?? {
                hasAcceptedTheTerms: false,
                discountCoupon: null
            };

            const subscriptionPayment: SubscriptionPaymentFormData = {
                ...subscriptionFormData?.subscriptionPayment,
                paymentMethod,
                discountCoupon,
                hasAcceptedTheTerms
            };

            dispatchSubscriptionFormData({
                type: SubscriptionReducerActionTypesEnum.UpdatePayment,
                payload: { subscriptionPayment }
            });
        },
        [subscriptionFormData, dispatchSubscriptionFormData]
    );

    const showCurrentPayment =
        (hasSubscription || hasACardInCanceledSubscription) && !isUpdatingPaymentMethod && !isSuspendedPlan;
    return (
        <Card type='group'>
            <AlertProvider content={AlertDialog}>{isSuspendedPlan && <ReasonSuspendedPlan />}</AlertProvider>
            <CardHeader title={String(title)} actions={isUpdate && actionsButton} />
            <CardBodyStyled type='group' editPayment={!showCurrentPayment}>
                {showCurrentPayment ? (
                    <CurrentPaymentMethod />
                ) : (
                    <EditPaymentMethod onChangePaymentMethod={handleChangePaymentMethod} />
                )}
                <DiscountCoupon />
                <Text size='xs'>{String(infoAlert)}</Text>
            </CardBodyStyled>
        </Card>
    );
};

export * from './editPaymentMethod';
