import _has from 'lodash/has';
import _lowerFirst from 'lodash/lowerFirst';
import { translator, StatusErrorType } from '@hooks';
import { BackEndResponseErrorData } from '../apiService';
import { SystemErrorStatus } from './statusError';

const ERR_NETWORK = 'ERR_NETWORK';

export class EnviouApiResponseError<T = SystemErrorStatus.InternalError, U = unknown> extends Error {
    constructor(
        public message: string,
        public code: string = null,
        public errors: Array<BackEndResponseErrorData<T, U>> = [],
        public requestUrl: string = null,
        public statusCode: number = null,
        public router: {
            locale?: string;
            defaultLocale?: string;
            locales?: Array<string>;
        } = null
    ) {
        super(message);
        this.name = 'EnviouApiResponseError';

        Object.setPrototypeOf(this, EnviouApiResponseError.prototype);
    }

    getErrors(statusErrorType: StatusErrorType) {
        if (this.errors && this.router && this.router.locales && this.router.locale) {
            const { statusError } = translator(this.router).api;

            if (this.code === ERR_NETWORK) {
                return [
                    {
                        code: SystemErrorStatus.NetworkError,
                        message: String(statusError.systemErrorStatus.networkError)
                    }
                ];
            }

            return this.errors.map((error) => {
                const { code, data } = error;

                const hasStatusType = _has(statusError, statusErrorType);
                const hasStatusCode = _has(statusError[statusErrorType], _lowerFirst(String(code)));

                if (hasStatusType && hasStatusCode) {
                    return {
                        code,
                        message: String(statusError[statusErrorType][_lowerFirst(String(code))]),
                        data
                    };
                }

                return {
                    code,
                    message: String(statusError.systemErrorStatus.internalError),
                    data
                };
            });
        }

        return [];
    }

    getFirstError(statusErrorType: StatusErrorType) {
        const errors = this.getErrors(statusErrorType);

        if (errors && errors.length > 0) {
            return errors[0];
        }

        const { statusError } = translator(this.router).api;

        return {
            code: SystemErrorStatus.InternalError,
            message: String(statusError.systemErrorStatus.internalError)
        };
    }
}
