import { KeyboardEventHandler, useCallback, useState, useMemo } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { Button, InputFormGroup, Pagination, Label, SelectFormGroup, SelectData } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { PaginatedList, SubToolIdEnum, Theme, themeTypeBySubToolId } from '@models';
import { useAlert } from '@providers';
import { VALUE_TEN } from '@utils';
import LogoEnviouHorizontal from '~/assets/svg/logo__enviou--horizontal.svg?url';
import {
    GetAvailableCategoriesAsync,
    GetAvailableThemesAsync,
    NEWSLETTER_THEME_CATEGORIES_URL,
    NEWSLETTER_THEME_URL
} from '~/services/newsletterApiService';
import {
    CustomizeYourThemeStyled,
    FiltersInputStyled,
    FormGroupStyled,
    InputStyled,
    SearchIconStyled,
    SearchInputStyled,
    ThemeListStyled,
    ThemeStyled
} from './styles';
import { ThemeCard } from './themeCard';
import { ThemePreviewModal } from './themePreviewModal';

type ThemesProps = {
    onSelectedTheme?: (selectedTheme?: Theme | null) => void;
    variablesList: Array<string>;
    subToolId: SubToolIdEnum;
};

enum SeeByEnum {
    MoreRecent = 1,
    MoreUsed = 2
}

const ALL_CATEGORIES = 'All';
const MAX_ITEMS_PER_PAGE = 4;

const defaultItems = {
    items: Array.from({ length: MAX_ITEMS_PER_PAGE }).map(
        (_, index) =>
            ({
                id: index,
                category: '.',
                name: '.',
                themeTemplate: {},
                thumbnail: LogoEnviouHorizontal
            } as Theme)
    )
} as PaginatedList<Theme>;

export const Themes = ({ onSelectedTheme, variablesList, subToolId }: ThemesProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [description, setDescription] = useState('');
    const [descriptionTemp, setDescriptionTemp] = useState('');
    const [sort, setSort] = useState(1);
    const [categoryName, setCategoryName] = useState('');
    const { show } = useAlert();

    const {
        subTools: { newsletterTheme },
        enums: { seeByEnum }
    } = useTranslator();

    const handlePageChange = useCallback(
        (pageNumber: number) => {
            if (pageNumber === currentPage) {
                return;
            }
            setCurrentPage(pageNumber);
        },
        [currentPage]
    );

    const handleKeyUpForm: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.key === 'Enter') {
            setDescription(descriptionTemp);
        }
    };

    const handleCategoryChange = (value: string | number) => {
        if (value === categoryName) {
            return;
        }

        setCurrentPage(1);
        setDescription('');
        setDescriptionTemp('');
        setCategoryName(value as string);
    };

    const handleSortChange = (value: number | string) => {
        setCurrentPage(1);
        setSort(value as number);
    };

    const handleViewTheme = (theme?: Theme) => {
        show(null, null, {
            content: (args) => (
                <ThemePreviewModal
                    {...args}
                    theme={theme}
                    subToolId={subToolId}
                    onSelectTheme={onSelectedTheme}
                    variables={variablesList}
                />
            )
        });
    };

    const { data: themes, isLoading } = useFetch(
        {
            url: NEWSLETTER_THEME_URL,
            themeName: description,
            categoryName,
            sort,
            currentPage,
            themeType: themeTypeBySubToolId[subToolId]
        },
        async ({ themeName: description, categoryName, sort, themeType }) => {
            const response = await GetAvailableThemesAsync({
                themeName: description,
                categoryName: categoryName === ALL_CATEGORIES ? null : categoryName,
                sort,
                pageNumber: currentPage,
                pageSize: MAX_ITEMS_PER_PAGE,
                themeType
            });

            return response.data;
        },
        {
            keepPreviousData: true,
            fallbackData: defaultItems,
            dedupingInterval: minutesToMilliseconds(VALUE_TEN)
        }
    );

    const handleSubmitFilter = () => {
        setCurrentPage(1);
        setDescription(descriptionTemp);
    };

    const { data: categories } = useFetch(NEWSLETTER_THEME_CATEGORIES_URL, async () => {
        const response = GetAvailableCategoriesAsync();

        return (await response).data;
    });

    const { seeByOptions, categoryOptions } = useMemo(() => {
        const categoryOptions: Array<SelectData> = [
            { id: ALL_CATEGORIES, label: String(newsletterTheme.filters.category.all) }
        ];

        categories?.forEach((item) => {
            categoryOptions.push({
                id: item,
                label: item
            });
        });

        const seeByOptions: Array<SelectData> = [
            {
                id: SeeByEnum.MoreRecent,
                label: String(seeByEnum.moreRecent)
            },
            {
                id: SeeByEnum.MoreUsed,
                label: String(seeByEnum.moreUsed)
            }
        ];

        return {
            categoryOptions,
            seeByOptions
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    return (
        <ThemeStyled>
            <div onKeyUp={handleKeyUpForm}>
                <FormGroupStyled>
                    <CustomizeYourThemeStyled>
                        <Label>{String(newsletterTheme.title)}</Label>
                        <Button color='purple' type='button' onClick={() => onSelectedTheme()}>
                            {String(newsletterTheme.actions.customizeYourTheme)}
                        </Button>
                    </CustomizeYourThemeStyled>
                    <InputStyled>
                        <SearchInputStyled>
                            <InputFormGroup
                                name='description'
                                type='text'
                                enableUnform={false}
                                inputSize='small'
                                disabled={isLoading}
                                value={descriptionTemp}
                                onChange={(event) => setDescriptionTemp(event.target.value)}
                                placeholder={String(newsletterTheme.filters.description.placeholder)}>
                                <SearchIconStyled onClick={handleSubmitFilter} />
                            </InputFormGroup>
                        </SearchInputStyled>
                        <FiltersInputStyled>
                            <Label fontWeight='regular' size='xs'>
                                {String(newsletterTheme.filters.category.label)}
                            </Label>
                            <SelectFormGroup
                                name='category'
                                enableUnform={false}
                                defaultValue={ALL_CATEGORIES}
                                options={categoryOptions}
                                onOptionChange={({ id }) => handleCategoryChange(id)}
                                disabled={isLoading}
                            />
                        </FiltersInputStyled>
                        <FiltersInputStyled>
                            <Label fontWeight='regular' size='xs'>
                                {String(newsletterTheme.seeBy)}
                            </Label>
                            <SelectFormGroup
                                name='order'
                                enableUnform={false}
                                options={seeByOptions}
                                onOptionChange={({ id }) => handleSortChange(id)}
                                defaultValue={SeeByEnum.MoreRecent}
                                disabled={isLoading}
                            />
                        </FiltersInputStyled>
                    </InputStyled>
                </FormGroupStyled>
            </div>
            <ThemeListStyled>
                {(themes?.items ?? []).map((item) => (
                    <ThemeCard
                        key={item.id}
                        theme={item}
                        onSelectedTheme={onSelectedTheme}
                        onClickViewTheme={handleViewTheme}
                        isLoading={isLoading}
                    />
                ))}
            </ThemeListStyled>
            <Pagination
                totalRecords={themes?.totalItems}
                currentPage={currentPage ?? 1}
                pageLimit={themes?.pageSize}
                onPageChange={({ currentPage }) => handlePageChange(currentPage === 0 ? 1 : currentPage)}
            />
        </ThemeStyled>
    );
};
