import styled from 'styled-components';

export const VolumeResourceCreditContentStyled = styled.div`
    display: flex;
    flex-direction: column;

    & > button {
        justify-content: flex-start;
        margin-top: 1.2rem;
    }
`;

export const VolumeResourceCreditTooltipStyled = styled.div`
    max-width: 31.5rem;
`;
