import { ReactNode, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type ClientOnlyPortalProps = {
    children: ReactNode;
    selector: string;
};

export const ClientOnlyPortal = ({ children, selector }: ClientOnlyPortalProps) => {
    const portalRef = useRef();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        portalRef.current = document.querySelector(selector);
        setMounted(true);

        return () => {
            setMounted(false);
        };
    }, [selector]);

    return mounted ? createPortal(children, portalRef.current) : null;
};
