import styled from 'styled-components';
import { cssFlexGridByDevice, FlexGridValues } from '~/styles';

export type FormGroupStyledProps = {
    flexGrid?: FlexGridValues;
};
export const FormGroupStyled = styled.div<FormGroupStyledProps>`
    display: flex;
    flex-wrap: wrap;
    gap: 3.2rem 2.4rem;
    ${({ flexGrid }) => flexGrid && cssFlexGridByDevice(flexGrid)};
`;
