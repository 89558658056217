import styled, { css } from 'styled-components';
import check from '~/assets/svg/controls/check.svg?url';

export type ToggleButtonStylesProps = {
    selected?: boolean;
    disabled?: boolean;
    inputSize?: 'small' | 'medium' | 'large';
};

export const ToggleButtonStyled = styled.button<ToggleButtonStylesProps>`
    width: 100%;
    min-width: max-content;
    padding: 1.8rem 2.6rem;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.colors.purple : theme.colors.lightPurple)};
    background: ${({ theme }) => theme.default.input.backgroundColor};
    border-radius: 0.4rem;
    position: relative;
    text-align: initial;
    transition: padding-left 0.15s ease;
    font-size: 2rem;
    padding-left: ${({ selected }) => (selected ? '3.8rem' : '1.8rem')};
    color: ${({ theme }) => theme.default.input.color};
    font-weight: ${({ theme, selected }) => (selected ? theme.fontWeights.semiBold : theme.fontWeights.regular)};

    ${({ inputSize, selected }) =>
        inputSize === 'small' &&
        css`
            font-size: 2rem;
            padding: 0.8rem 1.6rem;
            line-height: 2.1rem;
            letter-spacing: 0.02px;
            height: 4rem;
            padding-left: ${selected ? '3.8rem' : '1.6rem'};
        `}

    &:before {
        content: url(${check});
        width: 1.2rem;
        display: block;
        opacity: ${({ selected }) => (selected ? 1 : 0)};
        transition: opacity 0.15s ease-out;
        position: absolute;
        color: ${({ theme }) => theme.colors.purple};
        line-height: 1.8rem;
        left: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover,
    &:focus {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;
