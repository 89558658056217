import { Phone, Address } from '@models';

export enum DocumentTypeEnum {
    Cpf = 0,
    Cnpj = 1
}

export enum PaymentTypeEnum {
    None = 0,
    Plan = 1,
    Subscription = 2
}

export type BillingResponsible = {
    name: string;
    email: string;
    phone: Phone;
    cellPhone: Phone;
};

export type Customer = {
    id?: number;
    name: string;
    billingResponsible?: BillingResponsible;
    documentNumber?: string;
    documentType?: DocumentTypeEnum;
    address: Address;
    paymentTemplate: PaymentTypeEnum;
};
