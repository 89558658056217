import { useState } from 'react';
import { Button, Link, Heading, Text } from '@components';
import { useSubToolPage, useTranslator } from '@hooks';
import { SubToolIdEnum } from '@models';
import PlayIcon from '~/assets/svg/icons/icon__play.svg';
import SettingsIcon from '~/assets/svg/icons/icon__setting.svg';
import { GetSubToolNameById } from '~/services/subToolApiService';
import { SubToolColors } from '~/styles/theme';
import { SubToolTutorialItemStyled, TutorialSubToolIconStyled } from './styles';
import { WatchTutorialModal } from './watchTutorialModal';

type SubToolTutorialItemProps = {
    subToolId: SubToolIdEnum;
    videoUrl?: string;
};

export const SubToolTutorialItem = ({ subToolId, videoUrl }: SubToolTutorialItemProps) => {
    const [showWatchModal, setShowWatchModal] = useState(false);

    const { getSubToolPageData } = useSubToolPage();
    const {
        buttons,
        pages: {
            helpAndTutorials: {
                tutorials: { subTool: subToolTranslation }
            }
        }
    } = useTranslator();

    const { icon, subToolKey, translatedSubToolName, urls } = getSubToolPageData(subToolId, {
        params: { subToolPage: { subTool: GetSubToolNameById(subToolId) } }
    });

    return (
        <SubToolTutorialItemStyled>
            <div>
                <TutorialSubToolIconStyled subToolColor={subToolKey as SubToolColors}>{icon}</TutorialSubToolIconStyled>
                <Heading as='h6'>{translatedSubToolName}</Heading>
                <Text size='md'>{String(subToolTranslation[subToolKey])}</Text>
                <div>
                    <WatchTutorialModal
                        title={translatedSubToolName}
                        description={String(subToolTranslation[subToolKey])}
                        subToolIcon={icon}
                        subToolColor={subToolKey as SubToolColors}
                        videoUrl={videoUrl}
                        createTemplateLink={urls.subToolPage}
                        dialogOptions={{
                            open: showWatchModal,
                            onOpenChange: setShowWatchModal,
                            enableOutsideClosed: false
                        }}>
                        <Button buttonSize='small' color='purple' onClick={() => setShowWatchModal(true)}>
                            <PlayIcon />
                            {String(buttons.watchTutorial)}
                        </Button>
                    </WatchTutorialModal>

                    <Link href={urls.subToolPage.href} as={urls.subToolPage.as}>
                        <Button buttonSize='small' color='purple' isOutlined>
                            <SettingsIcon /> <span>{String(buttons.createTemplate)}</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </SubToolTutorialItemStyled>
    );
};
