import styled from 'styled-components';
import { Box } from '~/components/elements/box';

export const CurrentAndNewAutomationPlanDetailsStyled = styled.div`
    display: flex;
    gap: 1.6rem;
    margin-top: 1.6rem;

    div:nth-child(2) {
        box-shadow: 1px 3px 10px #00000029;

        & > span {
            color: ${({ theme }) => theme.colors.deepCerise};
            font-weight: ${({ theme }) => theme.fontWeights.bold};
        }
    }
`;

export const AutomationPlanDetailsStyled = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 1.6rem;

    & > span {
        margin-bottom: 0.8rem;
    }
`;
