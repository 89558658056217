import { PreCheckout, PreCheckoutCardType } from '@containers';
import { SendingTechnology, SubTool, SubToolIdEnum } from '@models';
import { Heading, Text } from '~/components/elements';
import { PreCheckoutTemplateStyled } from './styles';

type PreCheckoutTemplateProps = {
    subTools: SubTool[];
    sendingTechnologies: SendingTechnology[];
};

type CardListType = {
    type: PreCheckoutCardType;
    id?: SubToolIdEnum;
};

export const PreCheckoutTemplate = ({ subTools, sendingTechnologies }: PreCheckoutTemplateProps) => {
    const automationToolCards: CardListType[] = [
        { type: 'subTool', id: SubToolIdEnum.Repurchase },
        { type: 'subTool', id: SubToolIdEnum.CartRecovery },
        { type: 'calculatePlan' },
        { type: 'subTool', id: SubToolIdEnum.NavigationTrigger },
        { type: 'subTool', id: SubToolIdEnum.CustomTrigger },
        { type: 'subTool', id: SubToolIdEnum.BankSlipReminder },
        { type: 'subscription' }
    ];

    const emailMarketingCards: CardListType[] = [
        { type: 'subTool', id: SubToolIdEnum.Emails },
        { type: 'subscription' }
    ];

    return (
        <PreCheckoutTemplateStyled>
            <PreCheckout.Container>
                <Heading as='h1' color='purple'>
                    Automação de Marketing
                </Heading>
                <Text size='md'>
                    Integre sua loja virtual ou site a Automações de Marketing que enviam E-mail, SMS, Push e/ou
                    Whatsapp com o objetivo de gerar vendas e aumentar a taxa de conversão!
                </Text>

                <PreCheckout.Content>
                    {automationToolCards.map((item) => {
                        if (item.type === 'subTool') {
                            const subTool = subTools.find((tool) => tool.id === item.id);
                            return <PreCheckout.Card key={item.id} type={item.type} subTool={subTool} />;
                        }

                        return (
                            <PreCheckout.Card
                                key={item.type}
                                type={item.type}
                                sendingTechnologies={sendingTechnologies}
                            />
                        );
                    })}
                </PreCheckout.Content>
            </PreCheckout.Container>

            <PreCheckout.Container>
                <Heading as='h1' color='purple'>
                    Ferramentas de E-mail Marketing
                </Heading>
                <Text size='md'>
                    Soluções para criar, enviar e monitorar campanhas de e-mail, permitindo personalização, segmentação
                    de contatos, automação de envios e análise de métricas para uma comunicação eficaz com os clientes.
                </Text>
                <PreCheckout.Content>
                    {emailMarketingCards.map((item) => {
                        if (item.type === 'subTool') {
                            const subTool = subTools.find((tool) => tool.id === item.id);
                            return <PreCheckout.Card key={item.id} type={item.type} subTool={subTool} />;
                        }

                        return <PreCheckout.Card key={item.type} type={item.type} />;
                    })}
                </PreCheckout.Content>
            </PreCheckout.Container>
        </PreCheckoutTemplateStyled>
    );
};
