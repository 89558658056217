export type Wildcard = '*' | '~' | '_' | '```' | string;

export interface WhatsappFormatRule {
    wildcard: Wildcard;
    wildcardReplace?: string;
    openTag: string;
    closeTag: string;
}

const MOD_TWO = 2;
const LF_CHAR_CODE = 10;

const isAplhanumeric = (text: string) => {
    if (!text) {
        return false;
    }

    return /^[a-z0-9]+$/gi.test(text);
};

const getIndexes = (text: string, wildcard: Wildcard, callback?: () => void) => {
    const indices: number[] = [];

    for (let i = 0; i < text.length; i += 1) {
        if (text[i] === wildcard) {
            if (indices.length % MOD_TWO) {
                if (text[i - 1] === ' ' || isAplhanumeric(text[i + 1])) {
                    callback && callback();
                    break;
                } else {
                    indices.push(i);
                }
            } else if (typeof text[i + 1] === 'undefined' || text[i + 1] === ' ' || isAplhanumeric(text[i - 1])) {
                callback && callback();
                break;
            } else {
                indices.push(i);
            }
        } else if (text[i].charCodeAt(0) === LF_CHAR_CODE && indices.length % MOD_TWO) {
            indices.pop();
            callback && callback();
        }
    }

    if (indices.length % MOD_TWO) {
        indices.pop();
    }

    return indices;
};

const injectTags = (text: string, indices: number[], rule: WhatsappFormatRule) => {
    let index = 0;

    indices.forEach((currentValue, currentIndex) => {
        const tag = currentIndex % MOD_TWO ? rule.closeTag : rule.openTag;

        let value = currentValue;
        value += index;

        text = text.substring(0, value) + tag + text.substring(value + 1);

        index += tag.length - 1;
    });

    return text;
};

const execRule = (text: string, rule: WhatsappFormatRule) => {
    const newRule = { ...rule };

    if (rule.wildcard === '```') {
        newRule.wildcard = rule.wildcardReplace;
        text = text.replaceAll(rule.wildcard, newRule.wildcard);
    }

    const indices: number[] = getIndexes(text, newRule.wildcard, () => {
        text = text.replaceAll(rule.wildcardReplace, rule.wildcard);
    });

    return injectTags(text, indices, rule);
};

const parseText = (text: string, rules: WhatsappFormatRule[]) => {
    const final: string = rules.reduce((transformed, rule) => {
        return execRule(transformed, rule);
    }, text);

    return final.replace(/\n/gi, '<br>');
};

export const whatsappRules: WhatsappFormatRule[] = [
    {
        closeTag: '</strong>',
        openTag: '<strong>',
        wildcard: '*'
    },
    {
        closeTag: '</i>',
        openTag: '<i>',
        wildcard: '_'
    },
    {
        closeTag: '</s>',
        openTag: '<s>',
        wildcard: '~'
    },
    {
        closeTag: '</code>',
        openTag: '<code>',
        wildcard: '```',
        wildcardReplace: '⠀'
    }
];

export const format = (text: string, rules?: WhatsappFormatRule[]) => parseText(text, rules ?? whatsappRules);
