import _uniqueId from 'lodash/uniqueId';
import { MessageSequenceType } from '@models';
import { RepurchaseItemConfiguration } from './useManageRepurchaseItems';

const MAX_ITEMS_PER_PAGE = 16;

export const fakeRepurchaseItemConfigurations = Array.from({ length: MAX_ITEMS_PER_PAGE }).map(
    (_, index) =>
        ({
            id: _uniqueId('default-id-'),
            identifier: String(index),
            imageUrl: null,
            name: '',
            type: 'general',
            templateType: MessageSequenceType.General,
            automaticAverageRepurchasePeriod: 0,
            manualAverageRepurchasePeriod: 0,
            countConsideredRepurchases: 0,
            hasTemplate: false,
            isActive: false
        } as RepurchaseItemConfiguration)
);
