import styled, { css } from 'styled-components';

export type InputStyledProps = {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    hasOutline?: boolean;
    outlineColor?: string;
    inputSize?: 'small' | 'medium' | 'large';
};

export const InputStyled = styled.input<InputStyledProps>`
    ${({
        theme,
        inputSize = 'large',
        color = theme.default.input.color,
        backgroundColor = theme.default.input.backgroundColor,
        borderColor = theme.default.input.borderColor,
        hasOutline = false,
        outlineColor = theme.default.input.outlineColor
    }) => css`
        width: 100%;
        color: ${color};
        background-color: ${backgroundColor};
        border: 0.1rem solid ${borderColor};
        border-radius: 0.4rem;
        font: normal 1.8rem 'Roboto', sans-serif;
        padding: 1.6rem;
        ${hasOutline ? `outline-color: ${outlineColor};` : `outline: none;`}

        &[type='color'] {
            height: 5.6rem;
            min-width: 10rem;
            padding: 0.8rem;
        }

        ${inputSize === 'small' &&
        css`
            font-size: 1.6rem;
            padding: 0.8rem 1.6rem;
            line-height: 2.1rem;
            letter-spacing: 0.02px;
        `}
    `}
`;
