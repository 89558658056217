import styled from 'styled-components';

export const SendingTechnologyContainer = styled.div`
    margin-top: -3.2rem;

    & > strong {
        line-height: 0;
        color: ${({ theme }) => theme.colors.licorice};
        display: flex;
        gap: 2.6rem;
        width: 100%;
        align-items: center;

        button {
            flex: 1;
            align-items: center;
            justify-content: start;
            text-decoration: underline;
            font-weight: normal;
            padding-left: 0;
        }
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        padding-top: 1.6rem;
        max-width: 21rem;

        li {
            display: grid;
            grid-template-columns: 20rem auto;

            & > span:last-child {
                text-align: end;
            }
        }
    }
`;
