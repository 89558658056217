import { CardBody, CardHeader } from '@components';
import { useTranslator } from '@hooks';
import { ToolTypeEnum } from '@models';
import { useMySubscriptionProvider } from '@providers';
import { AutomationCreditsBarChart } from './automationCreditsBarChart';
import { LooseCreditsBarChart } from './looseCreditsBarChart';
import { AutomationContentStyled, CardStyled } from './styles';

export const EmailMarketingSummary = () => {
    const {
        pages: { mySubscription }
    } = useTranslator();

    const { isLoading, bulkSendingTool, subscription, tools } = useMySubscriptionProvider((state) => {
        return {
            bulkSendingTool: state.bulkSendingTool,
            isLoading: state.isLoading,
            subscription: state.subscription,
            tools: state.tools
        };
    });

    const labels = bulkSendingTool.summary.filter((item) => item.text);
    const barChartItems = bulkSendingTool.summary.filter((item) => !item.text);

    return (
        <CardStyled type='group' borderColor='purple'>
            <CardHeader title={String(mySubscription.automations.emailMarketing)} />
            <CardBody>
                <AutomationContentStyled>
                    <AutomationCreditsBarChart
                        labels={labels}
                        barChartItems={barChartItems}
                        haveUnlimitedBalance={bulkSendingTool.haveUnlimitedBalance}
                        totalCredits={bulkSendingTool.totalCredits}
                        usedCreditsAmount={bulkSendingTool.totalUsedCredits}
                        isLoading={isLoading}
                    />
                    <LooseCreditsBarChart
                        totalCredits={bulkSendingTool.totalLooseCredits}
                        usedCreditsAmount={bulkSendingTool.totalUsedLooseCredits}
                        toolType={ToolTypeEnum.BulkSending}
                        isLoading={isLoading}
                        subscription={subscription}
                        tools={tools}
                    />
                </AutomationContentStyled>
            </CardBody>
        </CardStyled>
    );
};
