/* istanbul ignore file */
/** Contains the values of status codes defined for HTTP. */
export enum HttpStatusCodeEnum {
    /**
     * Indicates that the client can continue with its request.
     */
    Continue = 100,
    /**
     * Indicates that the protocol version or protocol is being changed.
     */
    SwitchingProtocols = 101,
    /**
     * Indicates that the server has accepted the complete request but hasn't completed it yet.
     */
    Processing = 102,
    /**
     * Indicates to the client that the server is likely to send a final response with the header
     * fields included in the informational response.
     */
    EarlyHints = 103,
    /**
     * Indicates that the request succeeded and that the requested information is in the response.
     * This is the most common status code to receive.
     */
    OK = 200,
    /**
     * Indicates that the request resulted in a new resource created before the response was sent.
     */
    Created = 201,
    /**
     * Indicates that the request has been accepted for further processing.
     */
    Accepted = 202,
    /**
     * Indicates that the returned metainformation is from a cached copy instead of
     * the origin server and therefore may be incorrect.
     */
    NonAuthoritativeInformation = 203,
    /**
     * Indicates that the request has been successfully processed and that the response is intentionally blank.
     */
    NoContent = 204,
    /**
     * Indicates that the client should reset (not reload) the current resource.
     */
    ResetContent = 205,
    /**
     * Indicates that the response is a partial response as requested by a GET request that includes a byte range.
     */
    PartialContent = 206,
    /**
     * Indicates multiple status codes for a single response during a Web Distributed Authoring
     * and Versioning (WebDAV) operation. The response body contains XML that describes
     * the status codes.
     */
    MultiStatus = 207,
    /**
     * Indicates that the members of a WebDAV binding have already been enumerated in a preceding
     * part of the multistatus response, and are not being included again.
     */
    AlreadyReported = 208,
    /**
     * Indicates that the server has fulfilled a request for the resource, and the response is a representation
     * of the result of one or more instance-manipulations applied to the current instance.
     */
    IMUsed = 226,
    /**
     * Indicates that the requested information has multiple representations. The default action
     * is to treat this status as a redirect and follow the contents of the Location
     * header associated with this response. Ambiguous is a synonym for MultipleChoices.
     */
    Ambiguous = 300,
    /**
     * Indicates that the requested information has multiple representations. The default action
     * is to treat this status as a redirect and follow the contents of the Location
     * header associated with this response. MultipleChoices is a synonym for Ambiguous.
     */
    MultipleChoices = 300,
    /**
     * Indicates that the requested information has been moved to the URI specified in the Location
     * header. The default action when this status is received is to follow the Location
     * header associated with the response. When the original request method was POST,
     * the redirected request will use the GET method. Moved is a synonym for MovedPermanently.
     */
    Moved = 301,
    /**
     * Indicates that the requested information has been moved to the URI specified in the Location
     * header. The default action when this status is received is to follow the Location
     * header associated with the response. MovedPermanently is a synonym for Moved.
     */
    MovedPermanently = 301,
    /**
     * Indicates that the requested information is located at the URI specified in the Location header.
     * The default action when this status is received is to follow the Location header
     * associated with the response. When the original request method was POST, the
     * redirected request will use the GET method. Found is a synonym for Redirect.
     */
    Found = 302,
    /**
     * Indicates that the requested information is located at the URI specified in the Location header.
     * The default action when this status is received is to follow the Location header
     * associated with the response. When the original request method was POST, the
     * redirected request will use the GET method. Redirect is a synonym for Found.
     */
    Redirect = 302,
    /**
     *  automatically
     * redirects the client to the URI specified in the Location header as the result
     * of a POST. The request to the resource specified by the Location header will
     * be made with a GET. RedirectMethod is a synonym for SeeOther.
     */
    RedirectMethod = 303,
    /**
     *  automatically
     * redirects the client to the URI specified in the Location header as the result
     * of a POST. The request to the resource specified by the Location header will
     * be made with a GET. SeeOther is a synonym for RedirectMethod
     */
    SeeOther = 303,
    /**
     * Indicates that the client's cached copy is up to date. The contents of the resource are not transferred.
     */
    NotModified = 304,
    /**
     * Indicates that the request should use the proxy server at the URI specified in the Location header.
     */
    UseProxy = 305,
    /**
     *  is a proposed extension to the HTTP/1.1 specification that is not fully specified.
     */
    Unused = 306,
    /**
     * Indicates that the request information is located at the URI specified in the Location
     * header. The default action when this status is received is to follow the Location
     * header associated with the response. When the original request method was POST,
     * the redirected request will also use the POST method. RedirectKeepVerb is a synonym
     * for TemporaryRedirect.
     */
    RedirectKeepVerb = 307,
    /**
     * Indicates that the request information is located at the URI specified in the Location
     * header. The default action when this status is received is to follow the Location
     * header associated with the response. When the original request method was POST,
     * the redirected request will also use the POST method. TemporaryRedirect is a
     * synonym for RedirectKeepVerb.
     */
    TemporaryRedirect = 307,
    /**
     * Indicates that the request information is located at the URI specified in the Location
     * header. The default action when this status is received is to follow the Location
     * header associated with the response. When the original request method was POST,
     * the redirected request will also use the POST method.
     */
    PermanentRedirect = 308,
    /**
     * Indicates that the request could not be understood by the server. System.Net.HttpStatusCode.BadRequest
     * is sent when no other error is applicable, or if the exact error is unknown or
     * does not have its own error code.
     */
    BadRequest = 400,
    /**
     * Indicates that the requested resource requires authentication. The WWW-Authenticate header
     * contains the details of how to perform the authentication.
     */
    Unauthorized = 401,
    /**
     *  is reserved for future use.
     */
    PaymentRequired = 402,
    /**
     * Indicates that the server refuses to fulfill the request.
     */
    Forbidden = 403,
    /**
     * Indicates that the requested resource does not exist on the server.
     */
    NotFound = 404,
    /**
     * Indicates that the request method (POST or GET) is not allowed on the requested resource.
     */
    MethodNotAllowed = 405,
    /**
     * Indicates that the client has indicated with Accept headers that it will not accept any
     * of the available representations of the resource.
     */
    NotAcceptable = 406,
    /**
     * Indicates that the requested proxy requires authentication. The Proxy-authenticate
     * header contains the details of how to perform the authentication.
     */
    ProxyAuthenticationRequired = 407,
    /**
     * Indicates that the client did not send a request within the time the server was expecting
     * the request.
     */
    RequestTimeout = 408,
    /**
     * Indicates that the request could not be carried out because of a conflict on the server.
     */
    Conflict = 409,
    /**
     * Indicates that the requested resource is no longer available.
     */
    Gone = 410,
    /**
     * Indicates that the required Content-length header is missing.
     */
    LengthRequired = 411,
    /**
     * Indicates that a condition set for this request failed, and the request cannot be carried
     * out. Conditions are set with conditional request headers like If-Match, If-None-Match,
     * or If-Unmodified-Since.
     */
    PreconditionFailed = 412,
    /**
     * Indicates that the request is too large for the server to process.
     */
    RequestEntityTooLarge = 413,
    /**
     * Indicates that the URI is too long.
     */
    RequestUriTooLong = 414,
    /**
     * Indicates that the request is an unsupported type.
     */
    UnsupportedMediaType = 415,
    /**
     * Indicates that the range of data requested from the resource cannot be returned,
     * either because the beginning of the range is before the beginning of the resource,
     * or the end of the range is after the end of the resource.
     */
    RequestedRangeNotSatisfiable = 416,
    /**
     * Indicates that an expectation given in an Expect header could not be met by the server.
     */
    ExpectationFailed = 417,
    /**
     * Indicates that the request was directed at a server that is not able to produce a response.
     */
    MisdirectedRequest = 421,
    /**
     * Indicates that the request was well-formed but was unable to be followed due
     * to semantic errors.
     */
    UnprocessableEntity = 422,
    /**
     * Indicates that the source or destination resource is locked.
     */
    Locked = 423,
    /**
     * Indicates that the method couldn't be performed on the resource because the requested action
     * depended on another action and that action failed.
     */
    FailedDependency = 424,
    /**
     * Indicates that the client should switch to a different protocol such as TLS/1.0.
     */
    UpgradeRequired = 426,
    /**
     * Indicates that the server requires the request to be conditional.
     */
    PreconditionRequired = 428,
    /**
     * Indicates that the user has sent too many requests in a given amount of time.
     */
    TooManyRequests = 429,
    /**
     * Indicates that the server is unwilling to process the request because its header
     * fields (either an individual header field or all the header fields collectively)
     * are too large.
     */
    RequestHeaderFieldsTooLarge = 431,
    /**
     * Indicates that the server is denying access to the resource as a consequence of a legal demand.
     */
    UnavailableForLegalReasons = 451,
    /**
     * Indicates that a generic error has occurred on the server.
     */
    InternalServerError = 500,
    /**
     * Indicates that the server does not support the requested function.
     */
    NotImplemented = 501,
    /**
     * Indicates that an intermediate proxy server received a bad response from another proxy or the origin server.
     */
    BadGateway = 502,
    /**
     * Indicates that the server is temporarily unavailable, usually due to high load or maintenance.
     */
    ServiceUnavailable = 503,
    /**
     * Indicates that an intermediate proxy server timed out while waiting for a response from
     * another proxy or the origin server.
     */
    GatewayTimeout = 504,
    /**
     * Indicates that the requested HTTP version is not supported by the server.
     */
    HttpVersionNotSupported = 505,
    /**
     * Indicates that the chosen variant resource is configured to engage in transparent
     * content negotiation itself and, therefore, isn't a proper endpoint in the negotiation
     * process.
     */
    VariantAlsoNegotiates = 506,
    /**
     * Indicates that the server is unable to store the representation needed to complete
     * the request.
     */
    InsufficientStorage = 507,
    /**
     * Indicates that the server terminated an operation because it encountered an infinite loop
     * while processing a WebDAV request with "Depth: infinity". This status code is
     * meant for backward compatibility with clients not aware of the 208 status code
     * System.Net.HttpStatusCode.AlreadyReported appearing in multistatus response bodies.
     */
    LoopDetected = 508,
    /**
     * Indicates that further extensions to the request are required for the server to fulfill it.
     */
    NotExtended = 510,
    /**
     * Indicates that the client needs to authenticate to gain network access; it's
     * intended for use by intercepting proxies used to control access to the network.
     */
    NetworkAuthenticationRequired = 511
}
