import styled, { DefaultTheme } from 'styled-components';
import { Text } from '../../text';

export type MultiStepItemStyleProps = {
    /** The current state of this step  */
    state?: 'Unknown' | 'Concluded' | 'Selected';
    /** The color of current background-color */
    backgroundColor?: string;
};

export const MultiStepItemStyled = styled.li`
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;

    a {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }
`;

export const MultiStepItemTextStyled = styled(Text)<Pick<MultiStepItemStyleProps, 'state'>>`
    margin-top: 0.8rem;
    text-align: center;
    font-weight: ${({ state }) => (state === 'Selected' ? 'bold' : 'normal')};
    color: ${({ state, theme }) => (state !== 'Unknown' ? theme.colors.deepCerise : theme.colors.lightBlack)};
`;

const setActiveStateColor = ({ state, theme }: Pick<MultiStepItemStyleProps, 'state'> & { theme: DefaultTheme }) =>
    state !== 'Unknown' ? theme.colors.purple : theme.colors.lightPurple;

export const IconCircleStyled = styled.div<MultiStepItemStyleProps>`
    width: 3.4rem;
    height: 3.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    border-radius: 50%;
    border-style: solid;
    border-color: ${({ state, theme }) => setActiveStateColor({ state, theme })};
    border-width: 0.15rem;

    svg {
        stroke: ${({ state, theme }) => setActiveStateColor({ state, theme })};
        fill: ${({ state, theme }) => setActiveStateColor({ state, theme })};
    }
`;
