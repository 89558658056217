import { DetailedStatisticsStatus, SubToolIdEnum } from '@models';
import {
    DateFormatEnum,
    formatDate,
    getCapitalizeEnumKey,
    SortEnum,
    PeriodType,
    isCustomizeTimePeriodType,
    DateFilterOptionsWithCustomTimePeriod,
    calculateDatePeriod,
    getValidDate,
    DateFilterOptions
} from '@utils';
import { AutomationStatisticsFilterParamsState, QueryParamsType } from '.';

type DateQueryParams = Pick<QueryParamsType, 'periodType' | 'startDate' | 'endDate'>;

type CustomerQueryParams = Omit<QueryParamsType, keyof DateQueryParams>;

const defaultPeriodType: DateFilterOptionsWithCustomTimePeriod = 'last30Days';

export type DateParams = {
    periodType?: PeriodType;
    startDate?: Date;
    endDate?: Date;
};

export const isDateParamsChanged = (value: DateParams, currentValue: DateParams) => {
    const { periodType, startDate, endDate } = value;
    return (
        currentValue.periodType !== periodType ||
        currentValue.startDate !== startDate ||
        currentValue.endDate !== endDate
    );
};

export const isCustomerParamsChanged = (value: CustomerQueryParams, currentValue: CustomerQueryParams) => {
    return (
        value.status !== currentValue.status ||
        value.page !== currentValue.page ||
        value.search !== currentValue.search ||
        value.sortByDate !== currentValue.sortByDate ||
        value.subTool !== currentValue.subTool ||
        value.customTriggerId !== currentValue.customTriggerId
    );
};

export const calculateAndFormatDateParams = (
    periodType: PeriodType,
    startDate: Date = null,
    endDate: Date = null
): DateQueryParams => {
    const datePeriod = calculateDatePeriod[periodType](startDate, endDate);
    const formactedStartDate = formatDate(datePeriod.startDate, DateFormatEnum.OnlyDateUSFormat);
    const formactedEndDate = datePeriod.endDate && formatDate(datePeriod.endDate, DateFormatEnum.OnlyDateUSFormat);

    return createDateParams(periodType, formactedStartDate, formactedEndDate);
};

export const createDateParams = (
    periodType: PeriodType,
    startDate: string = null,
    endDate: string = null
): DateQueryParams => ({ periodType, startDate, endDate });

export const createAutomationStatisticsFilterParamsState = (queryParams: QueryParamsType) => {
    const currentPeriodType = calculateDatePeriod[queryParams?.periodType]
        ? queryParams?.periodType
        : defaultPeriodType;

    const parameters = {
        status: getCapitalizeEnumKey(queryParams.status, DetailedStatisticsStatus),
        subTool: queryParams.subTool ? SubToolIdEnum[queryParams.subTool] : null,
        customTriggerId: !isNaN(parseInt(queryParams.customTriggerId)) ? Number(queryParams.customTriggerId) : null,
        sortByDate: getCapitalizeEnumKey(queryParams.sortByDate, SortEnum) ?? SortEnum.Desc,
        page: !isNaN(parseInt(queryParams.page)) ? Number(queryParams.page) : 1,
        search: queryParams.search ?? ''
    };

    if (!isCustomizeTimePeriodType(queryParams?.periodType)) {
        const currentStartDate = getValidDate(queryParams?.startDate);
        const currentEndDate = queryParams?.endDate ? getValidDate(queryParams?.endDate) : null;

        const datePeriod = calculateDatePeriod[currentPeriodType as DateFilterOptions](
            currentStartDate,
            currentEndDate
        );

        return {
            periodType: currentPeriodType as DateFilterOptions,
            startDate: datePeriod.startDate,
            endDate: datePeriod.endDate,
            ...parameters
        } as AutomationStatisticsFilterParamsState;
    }

    return {
        periodType: 'customizeTimePeriod',
        ...parameters
    } as AutomationStatisticsFilterParamsState;
};
