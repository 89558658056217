import { useMemo, forwardRef, HTMLProps, ReactNode, useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { Select, SelectData } from '.';
import { UseSelectProps } from './useSelect';

export type BasicSelectProps = UseSelectProps & {
    placeholder?: string;
    defaultValue?: string | number;
    options: Array<SelectData>;
    disabled?: boolean;
    disableCheck?: boolean;
    icon?: ReactNode;
    testId?: string;
};

export const BasicSelect = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement> & BasicSelectProps>(
    function BasicSelect(
        { testId, icon, id, placeholder, disabled, defaultValue = null, options = [], ...props },
        ref
    ) {
        const [optionList, setOptionList] = useState(options);
        const defaultOption = useMemo(() => {
            if (defaultValue === undefined || !optionList) {
                return null;
            }

            const currentIndex = optionList.findIndex((item) => item.id === defaultValue);
            const currentOption = currentIndex > -1 ? optionList[currentIndex] : null;

            if (!currentOption) {
                return null;
            }

            return {
                index: currentIndex,
                label: currentOption.label
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [defaultValue, optionList]);

        useEffect(() => {
            if (!_isEqual(options, optionList)) {
                setOptionList(options);
            }
        }, [options, optionList]);

        return (
            <Select.Root {...props} defaultOption={defaultOption}>
                <Select.Trigger testId={testId} disabled={disabled} placeholder={placeholder} ref={ref} id={id}>
                    {icon}
                </Select.Trigger>
                <Select.Content>
                    {optionList.map(({ children, ...option }) => (
                        <Select.Option id={option.id} key={option.id} label={option.label} data={option}>
                            {children}
                        </Select.Option>
                    ))}
                </Select.Content>
            </Select.Root>
        );
    }
);
