import styled from 'styled-components';
import check from '~/assets/svg/controls/check.svg?url';

export type OptionStyledProps = {
    checked?: boolean;
};

export const OptionStyled = styled.button<OptionStyledProps>`
    width: 100%;
    padding: 1rem;
    background-color: transparent;
    text-align: start;
    color: ${({ theme }) => theme.default.input.color};

    & > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:not([role='separator'], &.option-group-title):before {
        content: url(${check});
        width: 1.2rem;
        display: ${({ checked }) => (checked ? 'block' : 'none')};
        position: absolute;
        margin-left: -2rem;
        color: ${({ theme }) => theme.colors.purple};
        line-height: 1.8rem;
    }

    &:not([role='separator'], &.option-group-title):hover,
    &:not([role='separator'], &.option-group-title):focus {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.purple};
    }

    &[role='separator'] {
        display: block;
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.colors.lightPurple};
        margin: 0.8rem 0;
        padding: 0;
    }

    &.option-group-title {
        padding: 0.8rem 1rem;
        display: block;
        line-height: 2.1rem;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.lightGray};
    }
`;
