import { ComponentProps } from 'react';
import { CardHeader } from '~/components/elements/card/header';
import { useTranslator } from '~/hooks/useTranslator';
import { DiscountCoupon, EditPaymentMethod } from '../../checkout';
import { CardStyled, PaymentContentStyled } from './styles';

type ContractLooseBalancePaymentFormProps = Pick<ComponentProps<typeof EditPaymentMethod>, 'onChangePaymentMethod'>;

export const ContractLooseBalancePaymentForm = ({ onChangePaymentMethod }: ContractLooseBalancePaymentFormProps) => {
    const { pages } = useTranslator();

    return (
        <CardStyled type='group'>
            <CardHeader title={String(pages.contractLooseBalance.paymentMethodSection.title)} />
            <PaymentContentStyled>
                <EditPaymentMethod onChangePaymentMethod={onChangePaymentMethod} />
                <DiscountCoupon />
            </PaymentContentStyled>
        </CardStyled>
    );
};
