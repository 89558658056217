import { ReactElement } from 'react';
import { FunnelStageStyled } from './styles';

export type FunnelStageData<T = unknown> = {
    key?: number;
    data?: T;
    backgroundColor?: string;
    stage?: FunnelStageData<T>;
};

export type FunnelStageProps<T> = FunnelStageData<T> & {
    content: ({ data }: Pick<FunnelStageProps<T>, 'data'>) => ReactElement;
};

export const FunnelStage = <T,>({
    content: Content,
    stage: currentItem,
    data,
    backgroundColor,
    ...props
}: FunnelStageProps<T>) => {
    return (
        <FunnelStageStyled backgroundColor={backgroundColor} {...props}>
            <Content data={data} />
            {currentItem && (
                <FunnelStage
                    backgroundColor={currentItem.backgroundColor}
                    stage={currentItem.stage}
                    data={currentItem.data}
                    content={Content}
                />
            )}
        </FunnelStageStyled>
    );
};
