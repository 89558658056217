import { useMemo, useState } from 'react';
import { Scope } from '@unform/core';
import { Card, CardBody, CardHeader } from '~/components/elements/card';
import { type SelectDataType } from '~/components/elements/select';
import { Text } from '~/components/elements/text';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { InfoTooltip } from '~/components/elements/tooltip/infoTooltip';
import { SelectFormGroup } from '~/components/forms/selectFormGroup';
import { useNumberFunctions } from '~/hooks/useNumberFunctions';
import { useReplace } from '~/hooks/useReplace';
import { useTranslator } from '~/hooks/useTranslator';
import { type Tool, ToolTypeEnum } from '~/models/tool';
import { useCoupon } from '~/providers/checkoutProvider';
import { CalculateSendingTechnologyValue, GetAllToolDiscountWithCoupon } from '~/services/subscriptionService';
import { AcceptTerms } from '../../checkout/invoiceDetails/acceptTerms';
import { AutomaticContractingLooseBalanceForm } from '../automaticContracting';
import { DiscountsCard, ContractingCardStyled, ContractingContentStyled, TotalPayableCard } from './styles';

export type LooseBalanceCredits = {
    id: number;
    currency: string;
    looseValue: number;
    creditAmount: number;
};

type BalanceContractingProps = {
    toolType: ToolTypeEnum;
    credits: Array<LooseBalanceCredits>;
    automaticContractingScopePath: string;
    showAutomaticContractingForm: boolean;
};

export const BalanceContracting = ({
    toolType,
    credits = [],
    automaticContractingScopePath,
    showAutomaticContractingForm
}: BalanceContractingProps) => {
    const [creditAmountSelected, setCreditAmountSelected] = useState<SelectDataType>({
        id: credits[0].id,
        label: '',
        creditAmount: credits[0].creditAmount
    });

    const replace = useReplace();
    const { formatNumber, formatCurrency } = useNumberFunctions();
    const { couponAttemptResult } = useCoupon();
    const { pages } = useTranslator();
    const { balanceContractingSection } = pages.contractLooseBalance;
    const { dropdowns } = balanceContractingSection;

    const creditsPerMonthOptions = useMemo(
        () =>
            credits.map((item) => ({
                id: item.id,
                label: replace(String(dropdowns.creditsPerMonth.option), {
                    creditAmount: formatNumber(item.creditAmount),
                    value: formatCurrency(item.looseValue, item.currency)
                }),
                creditAmount: item.creditAmount
            })),
        [credits] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const totals = useMemo(() => {
        if (!creditAmountSelected) {
            return {
                amount: 0,
                couponDiscount: null,
                total: 0
            };
        }

        const tool = {
            id: toolType,
            type: toolType,
            sendingTechnologies: credits.map((item) => ({
                id: item.id,
                creditAmount: item.creditAmount,
                monthlyValue: item.looseValue
            }))
        } as Tool;

        const selectedAmount = creditAmountSelected.creditAmount as number;

        const amount = CalculateSendingTechnologyValue(
            tool,
            toolType === ToolTypeEnum.AutomationTool ? selectedAmount : 0,
            toolType === ToolTypeEnum.BulkSending ? selectedAmount : 0,
            [creditAmountSelected.id as number],
            1 // Month
        );

        const toolDiscounts = GetAllToolDiscountWithCoupon(
            [{ tool, amount, currentAmount: selectedAmount }],
            couponAttemptResult
        );

        const couponDiscount = toolDiscounts[0] ?? { discountAmount: 0, discountPercentage: 0 };
        const total = amount - couponDiscount.discountAmount;

        return { couponDiscount, amount, total };
    }, [couponAttemptResult, creditAmountSelected, toolType, credits]);

    return (
        <ContractingCardStyled type='group'>
            <CardHeader
                title={
                    <>
                        {String(balanceContractingSection.title.text)}
                        <InfoTooltip>
                            <TooltipContent>{String(balanceContractingSection.title.tooltip)}</TooltipContent>
                        </InfoTooltip>
                    </>
                }
            />
            <ContractingContentStyled>
                <Card type='default'>
                    <CardBody>
                        <SelectFormGroup
                            name='creditsPerMonth'
                            options={creditsPerMonthOptions}
                            onOptionChange={(data) => setCreditAmountSelected(data)}
                        />
                    </CardBody>
                </Card>

                {showAutomaticContractingForm && (
                    <Scope path={automaticContractingScopePath}>
                        <AutomaticContractingLooseBalanceForm credits={credits} />
                    </Scope>
                )}

                {totals?.couponDiscount?.discountAmount > 0 && (
                    <DiscountsCard>
                        <CardBody>
                            <Text as='strong' size='md'>
                                {String(balanceContractingSection.discounts)}
                            </Text>
                            <Text as='span' size='md'>
                                <span>Cupom</span>
                                <span>-{formatCurrency(totals.couponDiscount.discountAmount)}</span>
                            </Text>
                            <Text as='strong' size='md'>
                                <span>{String(balanceContractingSection.discountTotal)}</span>
                                <span>{formatCurrency(totals.couponDiscount.discountAmount)}</span>
                            </Text>
                        </CardBody>
                    </DiscountsCard>
                )}

                <TotalPayableCard type='default'>
                    <div>
                        <Text as='strong' size='md'>
                            {String(balanceContractingSection.total)}
                        </Text>
                        <Text as='strong' size='md'>
                            {formatCurrency(totals.total)}
                        </Text>
                    </div>
                    <AcceptTerms />
                </TotalPayableCard>
            </ContractingContentStyled>
        </ContractingCardStyled>
    );
};
