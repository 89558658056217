import { useEffect, useRef } from 'react';
import { InputFormGroup, SelectData, SelectFormGroup } from '@components';
import { useTranslator } from '@hooks';
import { ItemSort, useManageRepurchaseItemsProvider } from '@providers';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { SearchGroupStyled, FormGroupStyled, SearchIconStyled, DropdownContentStyled } from './styles';

export const SearchItemConfigurationForm = () => {
    const {
        pages: {
            manageRepurchaseItem: {
                sections: {
                    setup: {
                        inputs: { search, orderBy }
                    }
                }
            }
        }
    } = useTranslator();

    const { params, onSortByChange, onFilter } = useManageRepurchaseItemsProvider((context) => context.filter);
    const formRef = useRef<FormHandles>();

    const sortByOptions: Array<SelectData> = [
        { id: 'nameAsc', label: String(orderBy.options.alphabeticalAsc) },
        { id: 'nameDesc', label: String(orderBy.options.alphabeticalDesc) },
        { id: 'reliabilityAsc', label: String(orderBy.options.reliabilityAsc) },
        { id: 'reliabilityDesc', label: String(orderBy.options.reliabilityDesc) },
        { id: 'repurchasePeriodAsc', label: String(orderBy.options.repurchasePeriodAsc) },
        { id: 'repurchasePeriodDesc', label: String(orderBy.options.repurchasePeriodDesc) }
    ];

    useEffect(() => {
        if (!formRef.current) {
            return;
        }

        formRef.current.setData({ search: params.search, sortBy: params.sort });
    }, [params.search, params.sort]);

    return (
        <SearchGroupStyled>
            <Form
                ref={formRef}
                onSubmit={(formData) => onFilter(formData.search)}
                initialData={{ search: params.search, sortBy: params.sort }}>
                <FormGroupStyled>
                    <InputFormGroup
                        containerClassName='search-input'
                        name='search'
                        type='text'
                        placeholder={String(search.placeholder)}
                        aria-label={String(search.label)}
                        onKeyDown={(event) => event.key === 'Enter' && formRef.current?.submitForm()}
                        inputSize='small'>
                        <SearchIconStyled onClick={formRef.current?.submitForm} />
                    </InputFormGroup>
                    <DropdownContentStyled>
                        <SelectFormGroup
                            name='sortBy'
                            label={String(orderBy.label)}
                            onOptionChange={(data) => onSortByChange(data.id as ItemSort)}
                            options={sortByOptions}
                        />
                    </DropdownContentStyled>
                </FormGroupStyled>
            </Form>
        </SearchGroupStyled>
    );
};
