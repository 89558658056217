import { FormGroup, InputFormGroup } from '@components';
import { useTranslator } from '@hooks';

type BillingResponsibleFormProps = {
    enableUnform?: boolean;
};

export const BillingResponsibleForm = ({ enableUnform = false }: BillingResponsibleFormProps) => {
    const { form } = useTranslator().checkoutPage.subscriptionData.billingResponsible;

    return (
        <FormGroup enableUnform={enableUnform} unformScopePath='billingResponsible'>
            <InputFormGroup
                type='text'
                name='name'
                placeholder={String(form.name.placeholder)}
                autoComplete='name'
                label={String(form.name.name)}
                flexGrid={{ mobileAndTablet: 12, desktop: 6 }}
                testId='input__billing-responsible-name'
            />

            <InputFormGroup
                type='email'
                name='email'
                autoComplete='email'
                placeholder={String(form.email.placeholder)}
                label={String(form.email.name)}
                flexGrid={{ mobileAndTablet: 12, desktop: 6 }}
                testId='input__billing-responsible-email'
            />

            <InputFormGroup
                type='tel'
                name='telephone'
                autoComplete='tel'
                placeholder={String(form.telephone.placeholder)}
                label={String(form.telephone.name)}
                mask='phone'
                flexGrid={{ mobileAndTablet: 12, desktop: 6 }}
                testId='input__billing-responsible-telephone'
            />

            <InputFormGroup
                type='tel'
                name='cellPhoneForWhatsApp'
                autoComplete='tel'
                placeholder={String(form.cell.placeholder)}
                label={String(form.cell.name)}
                mask='phone'
                flexGrid={{ mobileAndTablet: 12, desktop: 6 }}
                testId='input__billing-responsible-cellphone'
            />
        </FormGroup>
    );
};
