import { Shimmer, Text, Link, Button } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import {
    SubscriptionInvoiceStatusEnum,
    PaymentMethodTypeEnum,
    SubscriptionInvoice,
    SubscriptionInvoiceTypeEnum
} from '@models';
import { Form } from '@unform/web';
import { CompareDateResult, compareDates, DateFormatEnum, formatDate, GetTranslatedEnumName } from '@utils';
import { routesName } from '~/locales/route';
import { PaymentContentStyled, PaymentsListStyled, PaymentStatusStyled, PaymentStyled } from './styles';

type PaymentsProps = {
    isLoading: boolean;
    payments: Array<SubscriptionInvoice>;
};

const getPaymentType = (payment: SubscriptionInvoice) => {
    const subcriptionPaymentType = payment.subscription?.paymentMethod?.type || PaymentMethodTypeEnum.None;
    let looseBalancePaymentType: PaymentMethodTypeEnum;

    if (payment.bankSlipBarcode) {
        looseBalancePaymentType = PaymentMethodTypeEnum.BankSlip;
    } else if (payment.gatewayId?.includes('PAYID')) {
        looseBalancePaymentType = PaymentMethodTypeEnum.Gateway;
    } else {
        looseBalancePaymentType = PaymentMethodTypeEnum.CreditCard;
    }

    return payment.type === SubscriptionInvoiceTypeEnum.Single ? looseBalancePaymentType : subcriptionPaymentType;
};

export const Payments = ({ payments = [], isLoading = false }: PaymentsProps) => {
    const { buttons, enums } = useTranslator();
    const { formatCurrency } = useNumberFunctions();

    return (
        <PaymentsListStyled>
            <Form onSubmit={(event) => event.preventDefault()}>
                <Shimmer isActive={isLoading}>
                    {payments.map((payment) => {
                        let status =
                            payment.status === SubscriptionInvoiceStatusEnum.Waiting &&
                            compareDates(new Date(), payment.dueDate) === CompareDateResult.Bigger
                                ? SubscriptionInvoiceStatusEnum.Vanquished
                                : payment.status;

                        if (status === SubscriptionInvoiceStatusEnum.BillingByThirdParty) {
                            status = SubscriptionInvoiceStatusEnum.PaymentOK;
                        }

                        const paySubscription =
                            payment.status === SubscriptionInvoiceStatusEnum.Waiting ||
                            payment.status === SubscriptionInvoiceStatusEnum.SecondCopy;

                        const paymentType = getPaymentType(payment);

                        return (
                            <PaymentStyled key={payment.id} data-shimmer>
                                <PaymentContentStyled>
                                    <Text as='span' size='xs'>
                                        {formatDate(payment.dueDate, DateFormatEnum.MonthAndYear)}
                                    </Text>
                                    <Text as='span' size='xs'>
                                        {payment.subscription.id}
                                    </Text>
                                    <Text as='span' size='sm'>
                                        {GetTranslatedEnumName(
                                            PaymentMethodTypeEnum[paymentType],
                                            enums.paymentMethodTypeEnum
                                        )}
                                    </Text>
                                    <Text as='span' size='xs' title={formatDate(payment.dueDate, 'datetime')}>
                                        {formatDate(payment.dueDate, 'short')}
                                    </Text>
                                    <Text
                                        as='span'
                                        size='xs'
                                        title={payment.paymentDate && formatDate(payment.paymentDate, 'datetime')}>
                                        {payment.paymentDate && formatDate(payment.paymentDate, 'short')}
                                    </Text>
                                    <PaymentStatusStyled as='span' size='sm' status={status}>
                                        {GetTranslatedEnumName(
                                            SubscriptionInvoiceStatusEnum[status],
                                            enums.subscriptionInvoiceStatusEnum
                                        )}
                                    </PaymentStatusStyled>
                                    <Text as='span' size='xs'>
                                        {formatCurrency(payment.value)}
                                    </Text>

                                    {paySubscription && (
                                        <Link
                                            href={{
                                                pathname: routesName.checkout.bankSlip,
                                                query: { invoiceId: payment.id }
                                            }}
                                            as={routesName.checkout.bankSlip}
                                            translatedParams={{ invoiceId: payment.id }}>
                                            <Button buttonSize='small' styledAs='link' color='deepCerise'>
                                                {String(buttons.paySubscription)}
                                            </Button>
                                        </Link>
                                    )}
                                </PaymentContentStyled>
                            </PaymentStyled>
                        );
                    })}
                </Shimmer>
            </Form>
        </PaymentsListStyled>
    );
};
