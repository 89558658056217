import { ReactNode, useContext } from 'react';
import { useListItem } from '@floating-ui/react';
import CheckIcon from '~/assets/svg/controls/check.svg';
import { SelectContext } from '../context';
import { OptionStyled } from './styles';
import { SelectData } from '..';

type SelectOptionProps = { id?: string | number; label: string; children?: ReactNode; data?: SelectData };

export const SelectOption = ({ id, label, children, data, ...props }: SelectOptionProps) => {
    const { activeIndex, selectedIndex, disableCheck, getItemProps, handleSelect } = useContext(SelectContext);
    const { ref, index } = useListItem({ label });

    const isActive = activeIndex === index;
    const isSelected = selectedIndex === index && disableCheck === false;
    const selected = isActive && isSelected;

    return (
        <OptionStyled
            ref={ref}
            data-testid={id}
            role='option'
            type='button'
            aria-selected={selected}
            data-active={isActive}
            tabIndex={isActive ? 0 : -1}
            {...getItemProps({ onClick: () => handleSelect(index, data) })}
            {...props}>
            {isSelected && <CheckIcon className='selected-icon' />}
            {children ?? label}
        </OptionStyled>
    );
};
