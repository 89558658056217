const MINUTES = 60;
const HOURS = 24;

export const minutesToDays = (minutes: number) => {
    if (!minutes || minutes === 0) {
        return 0;
    }

    return Math.floor(minutes / (HOURS * MINUTES));
};

export const daysToMinutes = (days: number) => {
    return days * HOURS * MINUTES;
};
