import styled from 'styled-components';
import { dataValidateStyle } from '~/styles/mixins';

export type ToggleButtonGroupStylesProps = {
    orientation?: 'horizontal' | 'vertical';
    spacing?: number;
};

export const ToggleButtonGroupStyled = styled.div<ToggleButtonGroupStylesProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${({ orientation }) => (orientation === 'horizontal' ? 'row' : 'column')};
    gap: ${({ spacing = 1 }) => spacing}rem;

    ${dataValidateStyle}
`;
