import styled from 'styled-components';
import { Button } from '~/components/elements/button';

export const CardLinkStyled = styled(Button)<{ isUnderline: boolean }>`
    cursor: pointer;
    gap: 0.5rem;
    padding: 0;

    svg {
        width: 0.7rem;
        height: 0.4rem;
        height: 100%;
    }

    span {
        text-decoration: ${({ isUnderline }) => (isUnderline ? 'underline' : 'none')};
    }
`;
