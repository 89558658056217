import styled, { css } from 'styled-components';
import { TooltipTrigger } from './trigger';

export type TooltipStyleProps = {
    htmlTag?: string;
};

export type TooltipContentStyleProps = {
    background?: string;
    color?: string;
};

export const TooltipStyled = styled.div.attrs<TooltipStyleProps>(({ htmlTag = 'div' }) => ({
    as: htmlTag
}))<TooltipStyleProps>`
    position: relative;
    display: inline-block;

    > svg {
        vertical-align: middle;
    }

    &:hover,
    &:focus {
        cursor: help;
    }
`;

export const InfoTooltipTriggerStyled = styled(TooltipTrigger)`
    height: 2.4rem;
    width: 2.4rem;
    margin: 0;
    margin-left: 0.8rem;
    line-height: 0;
    text-align: center;

    svg {
        height: 1.8rem;
        color: ${({ theme }) => theme.colors.purple};
        padding-bottom: 1px;
    }
`;

export const CustomTooltipContentStyled = styled.div<TooltipContentStyleProps>`
    ${({ theme, background = theme.colors.grayPurple, color = 'inherit' }) => css`
        padding: 1.6rem;
        max-width: 22rem;
        border-radius: ${theme.radii.lg};
        background: ${background};
        color: ${color};
        box-shadow: ${theme.default.boxShadow.default};
        z-index: 100;
        font-size: ${theme.fontSizes.xs};
        font-family: ${theme.fonts.primary};
        line-height: ${theme.lineHeights.short};

        .tooltip-arrow {
            fill: ${background};
        }
    `}
`;
