export enum BillingPeriodEnum {
    Month = 1,
    Quarter = 3,
    Semester = 6,
    Year = 12
}

export type BillingPeriod = {
    id: number;
    name: string;
    culture: string;
    periodicityInMonths: BillingPeriodEnum | number;
    discountPercentage?: number;
};
