import { MouseEventHandler, ChangeEventHandler } from 'react';
import { InputMaskEnum } from '@utils';
import { InputType } from '~/components/forms';
import { InputFormGroupStyledProps } from '~/components/forms/inputFormGroup/styles';
import {
    ContainerInputGroupStyled,
    ItemGroupInputStyled,
    ItemGroupButtonStyled,
    ItemGroupInputWrapper
} from './styles';

type InputGroupProps = {
    inputPlaceHolder?: string;
    buttonText?: string;
    onClick?: MouseEventHandler<HTMLLabelElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    inputType?: InputType;
    inputMask?: keyof typeof InputMaskEnum;
    inputName: string;
    filename?: string;
    accept?: string;
} & InputFormGroupStyledProps;

export const InputGroup = ({
    inputPlaceHolder,
    buttonText,
    onClick,
    onChange,
    inputType,
    inputName,
    inputMask,
    accept,
    filename,
    ...props
}: InputGroupProps) => {
    return (
        <ContainerInputGroupStyled {...props}>
            <ItemGroupInputWrapper>
                <ItemGroupInputStyled
                    type={inputType}
                    placeholder={inputPlaceHolder}
                    filename={filename}
                    name={inputName}
                    mask={inputMask}
                    accept={accept}
                    onChange={onChange}
                    inputSize='small'
                />
            </ItemGroupInputWrapper>
            <ItemGroupButtonStyled htmlFor={inputName} as='label' onClick={onClick}>
                {buttonText}
            </ItemGroupButtonStyled>
        </ContainerInputGroupStyled>
    );
};
