import { useState } from 'react';
import { useTranslator } from '@hooks';
import { ConfirmDialogButtonProps, ConfirmDialogProps } from './confirmDialog';
import { ConfirmDeletionDialogStyled } from './styles';

type ConfirmDeletionDialogProps = Omit<ConfirmDialogProps, 'buttons'> & {
    onToBackClick?: () => void;
    onDeleteClick?: () => Promise<void> | void;
    isLoading?: boolean;
};

export const ConfirmDeletionDialog = ({
    onToBackClick,
    onDeleteClick,
    isLoading: initialLoading,
    ...props
}: ConfirmDeletionDialogProps) => {
    const [isLoading, setIsLoading] = useState(initialLoading);
    const { delete: deleteButton, toGoBack } = useTranslator().buttons;

    const handleDelete = async () => {
        setIsLoading(true);

        try {
            await onDeleteClick();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const buttons: Array<ConfirmDialogButtonProps> = [
        {
            title: String(toGoBack),
            onClick: onToBackClick,
            color: 'purple',
            isOutlined: true
        },
        {
            title: String(deleteButton),
            color: 'red',
            onClick: handleDelete,
            inLoading: isLoading,
            disabled: isLoading
        }
    ];
    return <ConfirmDeletionDialogStyled buttons={buttons} {...props} />;
};
