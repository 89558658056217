import { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Heading } from '@components';
import { useTranslator } from '@hooks';
import { DetailedStatisticsStatus, ExportStatisticsSelection, transactionStatusByStatisticsStatus } from '@models';
import { useAlert, useAutomationStatisticsProvider } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles } from '@unform/core';
import { addDays, DownloadFileToDesktop } from '@utils';
import {
    Dialog,
    DialogHeading,
    DialogTrigger,
    DialogClose,
    DialogButtonClose,
    DialogFooter
} from '~/components/elements/dialog';
import { StartExportingStatistics } from '~/services/statisticsApiService';
import { getLatestExports, saveLatestExports, type ExportData } from './exportsData';
import { LatestExports } from './latestExports';
import { runExportDownloadPolling } from './runExportDownloadPolling';
import { Content, DialogContentStyled, DropdownContentStyled } from './styles';

const NUMBER_OF_DAYS_FOR_EXPIRY_OF_EXPORTED_FILES = 7;

export const ExportEstatisticsModal = () => {
    const formRef = useRef<FormHandles>();
    const [isLoading, setIsLoading] = useState(false);
    const [latestExports, setLatestExports] = useState<Array<ExportData>>([]);
    const [open, setOpen] = useState(false);
    const { success, error } = useAlert();

    const {
        buttons,
        pages: {
            automationStatistics: { exportDataModal }
        }
    } = useTranslator();

    useEffect(() => {
        if (!open) {
            return;
        }

        const exports = getLatestExports();
        setLatestExports(exports);
    }, [open]);

    const { filterParams } = useAutomationStatisticsProvider((state) => ({
        filterParams: state.filterParams
    }));

    const updateExportData = (exportData: ExportData) => {
        const tempExports = latestExports.filter((item) => item.fileName !== exportData.fileName);
        const exports = [exportData, ...tempExports];
        setLatestExports(exports);
        saveLatestExports(exports);
    };

    const handleSubmit = async (data: { type: ExportStatisticsSelection }) => {
        setIsLoading(true);

        const { startDate, endDate, search, sortByDate, status, subTool } = filterParams;
        let statuses = [];
        let itemsWithSales = null;

        if (status && status !== DetailedStatisticsStatus.GeneratedSales) {
            statuses = transactionStatusByStatisticsStatus[status] as unknown as Array<number>;
            itemsWithSales = false;
        } else if (status === DetailedStatisticsStatus.GeneratedSales) {
            itemsWithSales = true;
        }

        try {
            const exportingStatisticsResponse = await StartExportingStatistics(
                { startDate, endDate, search, sortByDate, subToolIDs: [subTool], statuses, itemsWithSales },
                data.type
            );

            const fileName = exportingStatisticsResponse.data;
            const existsFile = latestExports.find((item) => item.fileName === fileName);
            let fileUrl = existsFile?.fileUrl;

            if (!fileUrl) {
                fileUrl = await runExportDownloadPolling(fileName);
            } else {
                DownloadFileToDesktop(fileUrl, fileName);
            }

            if (fileUrl) {
                updateExportData({
                    downloadDate: new Date(),
                    expirationDate: addDays(new Date(), NUMBER_OF_DAYS_FOR_EXPIRY_OF_EXPORTED_FILES),
                    fileName,
                    fileUrl
                });

                success(String(exportDataModal.messages.success), undefined, { autoClose: true });
            }

            setIsLoading(false);
        } catch (exception) {
            error(String(exportDataModal.messages.error), undefined, { autoClose: true });
            setIsLoading(false);
            captureException(exception);
        }
    };

    return (
        <Dialog enableOutsideClosed={false} open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button buttonSize='small' color='purple' onClick={() => setOpen(true)}>
                    {String(buttons.export)}
                </Button>
            </DialogTrigger>
            <DialogContentStyled>
                <DialogHeading>{String(exportDataModal.title)}</DialogHeading>
                <Content onSubmit={handleSubmit} initialData={{ type: ExportStatisticsSelection.Csv }} ref={formRef}>
                    <DropdownContentStyled>
                        <Heading as='h6'>{String(exportDataModal.exportTypeLabel)}</Heading>
                        <Dropdown
                            name='type'
                            inputSize='small'
                            options={[
                                { value: ExportStatisticsSelection.Csv, text: 'CSV' },
                                { value: ExportStatisticsSelection.Json, text: 'JSON' }
                            ]}
                        />
                    </DropdownContentStyled>
                    <LatestExports latestExports={latestExports} />
                </Content>
                <DialogClose />
                <DialogFooter>
                    <DialogButtonClose buttonSize='small' color='red'>
                        {String(buttons.cancel)}
                    </DialogButtonClose>
                    <Button
                        buttonSize='small'
                        color='purple'
                        inLoading={isLoading}
                        disabled={isLoading}
                        onClick={() => formRef.current?.submitForm()}>
                        {String(buttons.export)}
                    </Button>
                </DialogFooter>
            </DialogContentStyled>
        </Dialog>
    );
};
