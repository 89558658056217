import styled, { css } from 'styled-components';
import { Card } from '~/components/elements/card';
import { Heading } from '~/components/elements/heading';
import { mobileOnly } from '~/styles';

const removeBorderLeftIfSizeScreenIsMobile = mobileOnly(
    css`
        border-left: none;
    `
);

const addBorderBottomAndSetDetailsOnMobileSize = (borderColor: string) =>
    mobileOnly(css`
        flex-direction: column;
        & > div:not(:last-child) {
            border-bottom: 1px solid ${borderColor};

            & > div {
                padding: 1.6rem 3.2rem;
            }
        }
    `);

export const CurrentPlanDetailsStyled = styled(Card)`
    main {
        padding: 0;

        & > div {
            display: flex;
            flex-direction: row;

            & > div:first-child {
                justify-content: center;

                & > div {
                    padding: 1.6rem 3.2rem;
                }
            }

            & > div:last-child {
                flex: 1;
            }

            & > div:only-child ul {
                border-left: none;
            }

            ${({ theme }) => addBorderBottomAndSetDetailsOnMobileSize(theme.colors.lightPurple)}

            div {
                display: flex;
                flex-direction: column;
            }
        }
    }
`;

export const CurrentPlanListStyled = styled.ul`
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${(props) => props.theme.colors.lightPurple};
    ${removeBorderLeftIfSizeScreenIsMobile};
`;

export const CurrentPlanListItemStyled = styled.li`
    display: flex;
    flex-direction: row;
    padding: 1.6rem 3.2rem;
    align-items: center;
    gap: 0 1.6rem;
    height: 8rem;
    justify-content: space-between;

    :not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.colors.lightPurple};
    }
`;

export const CurrentPlanItemDetailsStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }
`;

export const CurrentPlanTitleStyled = styled(Heading)`
    text-transform: none;
    padding: 3.2rem 3.2rem 2.4rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightPurple};
    margin: 0;
`;
