export const registerServiceWorker = async () => {
    if (!('serviceWorker' in navigator)) {
        return;
    }
    await navigator.serviceWorker.register('/service-worker.js');
};

export const getReadyServiceWorker = async () => {
    if (!('serviceWorker' in navigator)) {
        return;
    }
    return await navigator.serviceWorker.ready;
};
