import { DownloadFileToDesktop, HttpStatusCodeEnum, wait } from '@utils';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { CheckStatistics } from '~/services/statisticsApiService';

const POLLING_INTERVAL_MS = 5_000;

/** Maximum number of attempts to query the file */
const MAX_NUMBER_TRIES = 180;

/** During file generation, it may take a few seconds before processing and sending it to S3,
    in which case there is no reason to keep checking the file,
    this delay will serve to wait a few seconds before the first verification 
*/
const WAIT_TIME_BEFORE_FIRST_QUERYING_IF_THE_FILE_EXISTS = 10_000;

/**
 * Performs a poll to monitor whether the export file is available, if it is, it returns the file link for download
 * @param filename The name of the file to download
 * @param maxNumberTries Maximum number of attempts to query the file
 * @returns the file link to download
 */
export const runExportDownloadPolling = async (
    filename: string,
    maxNumberTries: number = MAX_NUMBER_TRIES
): Promise<string> => {
    await wait(WAIT_TIME_BEFORE_FIRST_QUERYING_IF_THE_FILE_EXISTS);

    return await new Promise((resolve, reject) => {
        const pollingId = setInterval(async () => {
            try {
                if (maxNumberTries <= 1) {
                    throw new Error('Timeout exceeded');
                }

                const response = await CheckStatistics(filename);

                if (response.status === HttpStatusCodeEnum.OK) {
                    clearInterval(pollingId);
                    const url = response.data;

                    DownloadFileToDesktop(url, filename);
                    resolve(url);
                }
            } catch (error) {
                if (error instanceof EnviouApiResponseError) {
                    if (error.statusCode === HttpStatusCodeEnum.NotFound) {
                        // Keep polling.
                    } else {
                        clearInterval(pollingId);
                        reject(error);
                    }
                } else {
                    clearInterval(pollingId);
                    reject(error);
                }
            }
            maxNumberTries--;
        }, POLLING_INTERVAL_MS);
    });
};
