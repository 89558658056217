import { AnimatePresence } from 'framer-motion';
import { SubToolConfiguration } from '@models';
import { isGreaterThanZero } from '@utils';
import { AutomationRuler } from '../automationRuler';
import { CustomTriggerRuler } from '../automationRuler/customTriggerRuler';
import { Header } from './header';
import { AutomationItemStyled, AutomationRulerContainerStyled } from './styles';

export type AutomationData = {
    type: 'myAutomations' | 'myCustomTriggers';
    title: string;
    subtitle: string;
    subTools?: Array<SubToolConfiguration>;
};

export type AutomationProps = {
    automation: AutomationData;
};

export const Automation = ({ automation }: AutomationProps) => {
    const { subTools = [] } = automation ?? {};

    const showHeader =
        (isGreaterThanZero(subTools.length) && automation.type === 'myCustomTriggers') ||
        automation.type === 'myAutomations';

    return (
        <AutomationItemStyled>
            {showHeader && <Header automation={automation} />}
            <AutomationRulerContainerStyled
                isExpanded={isGreaterThanZero(subTools.length)}
                initial={{ height: 'auto' }}>
                <AnimatePresence initial={false}>
                    {automation.type === 'myAutomations'
                        ? subTools.map((subToolConfiguration) => (
                              <AutomationRuler
                                  key={subToolConfiguration.subTool.id}
                                  subToolConfiguration={subToolConfiguration}
                              />
                          ))
                        : subTools.map((subToolConfiguration) => (
                              <CustomTriggerRuler
                                  key={subToolConfiguration.subTool.id}
                                  subToolConfiguration={subToolConfiguration}
                              />
                          ))}
                </AnimatePresence>
            </AutomationRulerContainerStyled>
        </AutomationItemStyled>
    );
};
