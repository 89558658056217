import styled from 'styled-components';

export const BulletNumberStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    min-width: 4.8rem;
    height: 4.8rem;
    min-height: 4.8rem;
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;

    & > strong {
        margin-top: 5px;
        color: ${({ theme }) => theme.colors.deepCerise};
        line-height: 0;
    }
`;
