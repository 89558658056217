import styled from 'styled-components';
import { CardHeaderStyled } from '~/components/elements/card/header/styles';
import { SubToolColors } from '~/styles/theme';

export const HeaderStyled = styled(CardHeaderStyled)`
    padding: 0;
    width: 100%;
    gap: 2rem;

    & > div {
        display: flex;
        gap: 2rem;
    }
`;

export const TitleStyled = styled.div`
    flex: 1;

    p {
        margin-bottom: 0;
        margin-top: 1.2rem;
    }
`;

export const IconContainerStyled = styled.div<{ subToolColor: SubToolColors }>`
    display: flex;
    gap: 1.6rem;
    align-items: center;

    & > i {
        height: 5.4rem;
        width: 5.4rem;
        background-color: ${({ theme }) => theme.colors.white};
        display: flex;
        border-radius: 50%;

        svg {
            height: 4.2rem;
            width: 4.2rem;
            margin: auto;
            color: ${({ theme, subToolColor }) => theme.subToolColors[subToolColor]};
        }
    }
`;
