import { ChangeEvent, useState, useRef } from 'react';
import * as yup from 'yup';
import { Button, InputGroup, Text } from '@components';
import { useTranslator } from '@hooks';
import { AlertOptions, useAlert } from '@providers';
import { captureException } from '@sentry/nextjs';
import type { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { getFileContentAsync, mapFileLines } from '@utils';
import { Dialog, DialogClose, DialogHeading, DialogFooter } from '~/components/elements/dialog';
import { ImportContactInfoModal } from '../mapImportedInfoModal';
import { DialogContentStyled } from './styles';

type ImportContactModalProps = {
    options: AlertOptions;
    sequenceId?: number;
};

const SUPPORTED_FORMATS = ['.csv', 'text/csv', 'application/csv'];
const IMPORT_FILE_INPUT_NAME = 'importFile';

export const ImportContactModal = ({ options, sequenceId }: ImportContactModalProps) => {
    const [file, setFile] = useState<File>(null);
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef<FormHandles>();
    const {
        buttons,
        pages: {
            myAutomations: { importContactModal }
        }
    } = useTranslator();
    const { show, error } = useAlert();

    const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (!files) {
            return;
        }

        setFile(files[0]);
    };

    const handleSubmit: SubmitHandler = async (data, _, event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            formRef.current.setErrors({});
            const importFileSchema = yup.object().shape({
                importFile: yup.string().required(String(importContactModal.messages.fileNotFound))
            });

            await importFileSchema.validate(data, { abortEarly: false });

            const formData = new FormData(event.target as HTMLFormElement);
            const file = formData.get(IMPORT_FILE_INPUT_NAME) as File;

            if (!SUPPORTED_FORMATS.includes(file.type)) {
                formRef.current.setFieldError(
                    IMPORT_FILE_INPUT_NAME,
                    String(importContactModal.messages.invalidFileFormat)
                );
                return;
            }

            const content = await getFileContentAsync(file as Blob);
            const fileLineContent = mapFileLines(content as string);

            show(null, null, {
                content: (options) => (
                    <ImportContactInfoModal
                        {...options}
                        file={file}
                        fileLineContent={fileLineContent}
                        sequenceId={sequenceId}
                    />
                )
            });
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));
                formRef.current.setErrors(errorMessages);
            } else {
                error(String(importContactModal.messages.failedReadFile));
                captureException(exception);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog {...options}>
            <DialogContentStyled fileName={file?.name} placeholder={String(importContactModal.inputPlaceholder)}>
                <DialogHeading>{importContactModal.title}</DialogHeading>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <div>
                        <InputGroup
                            inputSize='small'
                            inputType='file'
                            inputName={IMPORT_FILE_INPUT_NAME}
                            accept={SUPPORTED_FORMATS.join(',')}
                            buttonText={String(buttons.chooseFile)}
                            onChange={handleFileSelected}
                            inputPlaceHolder={file?.name}
                        />

                        <Text>{String(importContactModal.supportedFiles)}</Text>
                    </div>
                    <DialogFooter>
                        <Button
                            type='submit'
                            color='purple'
                            buttonSize='small'
                            inLoading={isLoading}
                            disabled={isLoading}>
                            {String(buttons.send)}
                        </Button>
                    </DialogFooter>
                </Form>

                <DialogClose />
            </DialogContentStyled>
        </Dialog>
    );
};
