import { ReactNode } from 'react';
import { CardFooterStyled, CardFooterStyledProps } from './styles';

export type CardFooterProps = CardFooterStyledProps & {
    children?: ReactNode;
    cardChild?: boolean;
};

export const CardFooter = ({ children, type, ...props }: CardFooterProps) => {
    return (
        <CardFooterStyled {...props} type={type}>
            {children}
        </CardFooterStyled>
    );
};

CardFooter.defaultProps = {
    cardChild: true
};
