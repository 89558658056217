import { MouseEventHandler, useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { Button, CardBody, CardHeader, ExpandableGroup, FormGroup, InputFormGroup } from '@components';
import { useTranslator } from '@hooks';
import { PaymentMethodTypeEnum } from '@models';
import IconCoupon from '~/assets/svg/icons/icon__coupon.svg';
import {
    SubscriptionPlanFormData,
    SubscriptionPaymentFormData,
    SubscriptionReducerActionTypesEnum,
    useCheckout,
    useCoupon,
    usePaymentMethods
} from '~/providers/checkoutProvider';
import { GetCouponAttemptResultAsync } from '~/services/apiService';
import { GetBillingPeriodById } from '~/services/subscriptionService';
import { DiscountCouponStyled, CouponMessageStyled } from './styles';

const WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_COUPON_ACTIVATION = 400;

export const DiscountCoupon = () => {
    const { title, placeholder, activeButton } = useTranslator().checkoutPage.discountCoupon;
    const { subscriptionFormData, dispatchSubscriptionFormData } = useCheckout();
    const { activateCoupon, couponAttemptResult } = useCoupon();
    const { billingPeriods } = usePaymentMethods();
    const [couponCode, setCouponCode] = useState<string>();
    const [isExpanded, setIsExpanded] = useState<boolean>(!!subscriptionFormData?.subscriptionPayment?.discountCoupon);
    const [isLoading, setIsLoading] = useState(false);

    const handleActivateCouponClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        async (event) => {
            event.preventDefault();
            const subscriptionPayment: SubscriptionPaymentFormData = subscriptionFormData?.subscriptionPayment ?? {
                hasAcceptedTheTerms: false,
                paymentMethod: PaymentMethodTypeEnum.CreditCard,
                discountCoupon: null
            };

            const subscriptionPlan: SubscriptionPlanFormData = subscriptionFormData?.subscriptionPlan;

            if (!couponCode && !subscriptionPayment.discountCoupon && !subscriptionPlan?.billingPeriodId) {
                return;
            }

            setIsLoading(true);

            const billingPeriod = GetBillingPeriodById(billingPeriods, subscriptionPlan?.billingPeriodId);

            await GetCouponAttemptResultAsync(
                couponCode || subscriptionPayment.discountCoupon || null,
                billingPeriod?.periodicityInMonths
            )
                .then((response) => response.data)
                .then((couponAttemptResult) => {
                    subscriptionPayment.discountCoupon = couponCode;

                    dispatchSubscriptionFormData({
                        type: SubscriptionReducerActionTypesEnum.UpdatePayment,
                        payload: { subscriptionPayment }
                    });

                    activateCoupon(couponAttemptResult);
                })
                .finally(() => setIsLoading(false));
        },
        [couponCode, subscriptionFormData, billingPeriods, dispatchSubscriptionFormData, activateCoupon]
    );

    const handleActivateCouponOnChange = debounce((event) => {
        const couponCode = event.target.value;

        if (!couponCode) {
            const subscriptionPayment: SubscriptionPaymentFormData = subscriptionFormData?.subscriptionPayment ?? {
                hasAcceptedTheTerms: false,
                paymentMethod: PaymentMethodTypeEnum.CreditCard,
                discountCoupon: null
            };

            subscriptionPayment.discountCoupon = null;

            dispatchSubscriptionFormData({
                type: SubscriptionReducerActionTypesEnum.UpdatePayment,
                payload: { subscriptionPayment }
            });
            activateCoupon(null);
        }

        setCouponCode(couponCode);
    }, WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_COUPON_ACTIVATION);

    const handleHeaderClick = useCallback(() => {
        setIsExpanded((state) => !state);
    }, []);

    const { couponMessage, isCouponApplied } = couponAttemptResult || {};

    return (
        <DiscountCouponStyled type='default'>
            <CardHeader subtitle={title} icon={<IconCoupon />} showExpandIcon={true} onClick={handleHeaderClick} />
            <ExpandableGroup isExpanded={isExpanded}>
                <CardBody>
                    <FormGroup className='discount-coupon'>
                        <div>
                            <InputFormGroup
                                type='text'
                                name='discountCoupon'
                                flexGrid={{ mobile: 12, tabletAndDesktop: 7 }}
                                placeholder={String(placeholder)}
                                onChange={handleActivateCouponOnChange}
                                testId='input__discount-coupon'
                                disabled={isLoading}
                            />
                            <Button
                                onClick={handleActivateCouponClick}
                                type='button'
                                color='purple'
                                buttonSize='small'
                                flexGrid={{ mobile: 12 }}
                                isOutlined={!isCouponApplied}
                                testId='button__activate-coupon'
                                inLoading={isLoading}>
                                {activeButton}
                            </Button>
                        </div>

                        {!isLoading && couponMessage && (
                            <CouponMessageStyled size='xs' isSuccess={isCouponApplied}>
                                {couponMessage}
                            </CouponMessageStyled>
                        )}
                    </FormGroup>
                </CardBody>
            </ExpandableGroup>
        </DiscountCouponStyled>
    );
};
