import {
    DateFormatEnum,
    formatDate,
    PeriodType,
    isCustomizeTimePeriodType,
    DateFilterOptionsWithCustomTimePeriod,
    calculateDatePeriod,
    getValidDate,
    DateFilterOptions
} from '@utils';

export type DateQueryParams = Partial<Record<keyof DateParams, string>>;

const defaultPeriodType: DateFilterOptionsWithCustomTimePeriod = 'thisMonth';

export type DateParams = {
    periodType?: PeriodType;
    startDate?: Date;
    endDate?: Date;
};

export const isDateParamsChanged = (value: DateParams, currentValue: DateParams) => {
    const { periodType, startDate, endDate } = value;
    return (
        currentValue.periodType !== periodType ||
        currentValue.startDate !== startDate ||
        currentValue.endDate !== endDate
    );
};

export const calculateAndFormatDateParams = (
    periodType: PeriodType,
    startDate: Date = null,
    endDate: Date = null
): DateQueryParams => {
    const datePeriod = calculateDatePeriod[periodType](startDate, endDate);
    const formactedStartDate = formatDate(datePeriod.startDate, DateFormatEnum.OnlyDateUSFormat);
    const formactedEndDate = datePeriod.endDate && formatDate(datePeriod.endDate, DateFormatEnum.OnlyDateUSFormat);

    return createDateParams(periodType, formactedStartDate, formactedEndDate);
};

export const createDateParams = (
    periodType: PeriodType,
    startDate: string = null,
    endDate: string = null
): DateQueryParams => ({ periodType, startDate, endDate });

export const mergeDateQueryParamsObject = <T = unknown>(
    queryParams: DateQueryParams,
    othersParams: { [key: string]: unknown }
) => {
    if (!isCustomizeTimePeriodType(queryParams?.periodType)) {
        const currentPeriodType = calculateDatePeriod[queryParams?.periodType]
            ? queryParams?.periodType
            : defaultPeriodType;

        const currentStartDate = getValidDate(queryParams?.startDate);
        const currentEndDate = queryParams?.endDate ? getValidDate(queryParams.endDate) : null;

        const datePeriod = calculateDatePeriod[currentPeriodType as DateFilterOptions](
            currentStartDate,
            currentEndDate
        );

        return {
            periodType: currentPeriodType as DateFilterOptions,
            startDate: datePeriod.startDate,
            endDate: datePeriod.endDate,
            ...othersParams
        } as T;
    }

    return {
        periodType: 'customizeTimePeriod',
        ...othersParams
    } as T;
};
