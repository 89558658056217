import styled from 'styled-components';
import { DefaultLayoutContentStyled } from '../styles';

export const LayoutStyled = styled.div`
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LayoutContentStyled = styled(DefaultLayoutContentStyled)``;
