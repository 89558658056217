import { forwardRef, LiHTMLAttributes, ReactElement, ReactNode } from 'react';
import { Link, LinkProps } from '../../link';
import { MenuItemStyled } from './styles';

export type MenuItemProps = LiHTMLAttributes<HTMLLIElement> & {
    label?: ReactNode;
    isRoot?: boolean;
    link?: Pick<LinkProps, 'href' | 'as'>;
    icon?: ReactElement;
    renderElementAnchor?: boolean;
};

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(function MenuItem(
    { label, children, link, icon, tabIndex, role = 'none', isRoot = false, renderElementAnchor = true, ...props },
    ref
) {
    return (
        <MenuItemStyled isRoot={isRoot} {...props} ref={ref} role={role} tabIndex={tabIndex}>
            {renderElementAnchor && (
                <Link href={link?.href ?? '#'} as={link?.as} role='menuitem' tabIndex={-1}>
                    {icon}
                    {children ?? label}
                </Link>
            )}
            {!renderElementAnchor && (children || label || icon) && (
                <button role='menuitem' tabIndex={-1}>
                    {icon}
                    {children ?? label}
                </button>
            )}
        </MenuItemStyled>
    );
});
