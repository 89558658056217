import { ReactNode } from 'react';
import HTMLReactParser from 'html-react-parser';
import Head from 'next/head';
import { useTheme } from 'styled-components';
import { Footer, Header, MultiStepItem, MultiStepMenu } from '@components';
import { useTranslator } from '@hooks';
import { NoticeCard } from '~/components/elements/noticeCard';
import { routesName } from '~/locales/route';
import { useCheckout } from '~/providers/checkoutProvider';
import { CheckoutLayoutStyled, LayoutContentStyled } from './styles';

type CheckoutLayoutProps = {
    children: ReactNode;
    showMultiStepMenu?: boolean;
    showNewCustomerAlert?: boolean;
};

export const CheckoutLayout = ({
    children,
    showMultiStepMenu = true,
    showNewCustomerAlert = true
}: CheckoutLayoutProps) => {
    const theme = useTheme();
    const { currentStepIndex } = useCheckout();
    const { multiStepMenu, head, newCustomer } = useTranslator().checkoutPage;

    return (
        <CheckoutLayoutStyled>
            <Head>
                <title>{String(head.title)}</title>
            </Head>

            <Header />
            <main>
                {showNewCustomerAlert && (
                    <NoticeCard.Root className='customer-alert'>
                        <NoticeCard.Content>
                            <NoticeCard.TedIcon />
                            <NoticeCard.ContentBody>
                                <NoticeCard.Title>{String(newCustomer.alert.title)}</NoticeCard.Title>
                                <NoticeCard.Description>
                                    {HTMLReactParser(String(newCustomer.alert.description))}
                                </NoticeCard.Description>
                            </NoticeCard.ContentBody>
                        </NoticeCard.Content>
                        <NoticeCard.CloseButton />
                    </NoticeCard.Root>
                )}

                {showMultiStepMenu && (
                    <MultiStepMenu
                        currentStepIndex={currentStepIndex}
                        backgroundColor={theme.default.backgroundColor}
                        marginTop='3.2rem'>
                        <MultiStepItem route={routesName.checkout.plan}>{String(multiStepMenu.step01)}</MultiStepItem>
                        <MultiStepItem route={routesName.checkout.subscriptionData}>
                            {String(multiStepMenu.step02)}
                        </MultiStepItem>
                        <MultiStepItem route={routesName.checkout.payment}>
                            {String(multiStepMenu.step03)}
                        </MultiStepItem>
                    </MultiStepMenu>
                )}

                <LayoutContentStyled>{children}</LayoutContentStyled>

                <Footer contentSize='medium' />
            </main>
        </CheckoutLayoutStyled>
    );
};

export default CheckoutLayout;
