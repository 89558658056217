import { useCallback, useMemo } from 'react';
import HTMLReactParser from 'html-react-parser';
import _toNumber from 'lodash/toNumber';
import { Card, CardText, CardHeader, SelectFormGroup, SelectData } from '@components';
import { CurrentPlanDetails } from '@containers';
import { useReplace, useTranslator, useNumberFunctions } from '@hooks';
import {
    SubscriptionPlanFormData,
    SubscriptionReducerActionTypesEnum,
    useCheckout,
    usePaymentMethods
} from '~/providers/checkoutProvider';
import { CustomOptionStyled, CardBodyStyled } from './styles';

export const SelectBillingPeriod = () => {
    const { billingPeriod } = useTranslator().checkoutPage.subscriptionPlan;
    const { formatPercentage } = useNumberFunctions();
    const replace = useReplace();
    const { billingPeriods } = usePaymentMethods();
    const { dispatchSubscriptionFormData, subscriptionFormData, hasSubscription, subscription } = useCheckout();

    const handleChange = useCallback(
        (value: number | string) => {
            const subscriptionPlan: SubscriptionPlanFormData = subscriptionFormData?.subscriptionPlan ?? {
                sendingTechnologies: null
            };

            subscriptionPlan.billingPeriodId = _toNumber(value);

            dispatchSubscriptionFormData({
                type: SubscriptionReducerActionTypesEnum.UpdatePlan,
                payload: { subscriptionPlan }
            });
        },
        [dispatchSubscriptionFormData, subscriptionFormData]
    );

    const billingPeriodsOptions = useMemo(() => {
        return billingPeriods?.map((period) => {
            return {
                id: period.id,
                label: period.discountPercentage
                    ? replace(billingPeriod.dropdown.discountBuPeriodLabel, {
                          name: period.name,
                          discount: formatPercentage(period.discountPercentage)
                      })
                    : period.name,
                children: (
                    <CustomOptionStyled>
                        {HTMLReactParser(
                            period.discountPercentage
                                ? replace(billingPeriod.dropdown.discountByPeriod, {
                                      name: period.name,
                                      discount: formatPercentage(period.discountPercentage)
                                  })
                                : period.name
                        )}
                    </CustomOptionStyled>
                )
            } as SelectData;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingPeriods]);

    return (
        <Card type='group' borderColor='purple'>
            <CardHeader title={String(billingPeriod.title)} />

            <CardBodyStyled type='group'>
                {hasSubscription && (
                    <CurrentPlanDetails
                        currentPlanItemDetails={{
                            label: String(billingPeriod.description),
                            value: subscription.billingPeriod.name
                        }}
                        description={String(billingPeriod.descriptionPlanChange)}
                    />
                )}
                <div>
                    <CardText type='subtitle' value={billingPeriod.dropdown.title} />
                    <SelectFormGroup
                        name='billingPeriodId'
                        placeholder={String(billingPeriod.dropdown.placeholder)}
                        testId='dropdown__billing-period'
                        options={billingPeriodsOptions}
                        onOptionChange={(data) => handleChange(data.id)}
                        defaultValue={1}
                    />
                </div>
            </CardBodyStyled>
        </Card>
    );
};

export default SelectBillingPeriod;
