import { ReactNode, Children, isValidElement, MutableRefObject, useCallback, forwardRef, ForwardedRef } from 'react';
import { RadioButtonProps, RadioButton } from './radioButton';
import { RadioButtonGroupStyled, RadioButtonGroupStyledProps } from './styles';

type RadioButtonValue = number | string;

export type RadioButtonGroupProps = RadioButtonGroupStyledProps & {
    children: ReactNode;
    name: string;
    error?: string;
    defaultValue?: RadioButtonValue;
};

type InputsArray = Array<HTMLInputElement>;

type RenderRadiosProps = {
    children: ReactNode;
    name: string;
    currentRadioButtonRef: InputsArray;
    defaultValue?: RadioButtonValue;
};

const RenderRadios = ({ children, name, defaultValue, currentRadioButtonRef }: RenderRadiosProps): JSX.Element => {
    const radioIsChecked = useCallback((value: RadioButtonValue) => value === defaultValue, [defaultValue]);

    return (
        <>
            {Children.map<ReactNode, ReactNode>(children, (child, index) => {
                if (isValidElement<RadioButtonProps>(child)) {
                    return (
                        <RadioButton
                            {...child.props}
                            ref={(reference) => (currentRadioButtonRef[index] = reference)}
                            name={name}
                            value={child.props.value}
                            defaultChecked={radioIsChecked(child.props.value)}
                        />
                    );
                }
            })}
        </>
    );
};

export const RadioButtonGroup = forwardRef<InputsArray, RadioButtonGroupProps>(
    ({ children, name, error, ...props }, ref: ForwardedRef<InputsArray>) => {
        return (
            <RadioButtonGroupStyled data-validate={error} {...props}>
                <RenderRadios
                    name={name}
                    /* When a ForwardedRef does not refer to a function, it is equivalent to a MutableRefObject.
                        See more: https://stackoverflow.com/questions/62238716/using-ref-current-in-react-forwardref */
                    currentRadioButtonRef={(ref as MutableRefObject<InputsArray>).current}
                    defaultValue={props.defaultValue}>
                    {children}
                </RenderRadios>
            </RadioButtonGroupStyled>
        );
    }
);

RadioButtonGroup.displayName = 'RadioButtonGroup';

export * from './radioButton';
