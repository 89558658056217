import { hoursToMilliseconds } from 'date-fns';
import { CardFlags, Text } from '@components';
import { useFetch, useTranslator, useReplace } from '@hooks';
import { COMPANY_INFORMATION, isDevelopment, APP_VERSION } from '@utils';
import { GetCardFlags, PaymentApiRoutes } from '~/services/apiService';
import { FooterMedium } from './medium';
import {
    FooterStyled,
    FooterContentStyled,
    LegalInformationContainerStyled,
    LegalInformationContentStyled,
    LegalInformationColumnStyled,
    FooterCardFlagsContainerStyled
} from './styles';

type FooterProps = {
    contentSize?: 'small' | 'medium' | 'large';
};

export const Footer = ({ contentSize = 'medium' }: FooterProps) => {
    const { data: cardFlags } = useFetch(
        { url: PaymentApiRoutes.CardFlags },
        async (_, signal) => (await GetCardFlags(signal)).data,
        {
            keepPreviousData: true,
            dedupingInterval: hoursToMilliseconds(1)
        }
    );

    const { footer } = useTranslator();
    const replace = useReplace();
    const appVersion = isDevelopment() ? footer.development_version : replace(footer.production_version, APP_VERSION);

    return (
        <FooterStyled>
            <FooterContentStyled>
                {contentSize === 'medium' && <FooterMedium />}
                {contentSize === 'large' && <></>}
            </FooterContentStyled>

            <LegalInformationContainerStyled>
                <LegalInformationContentStyled>
                    <LegalInformationColumnStyled>
                        <Text size='xs'>{`${COMPANY_INFORMATION.CORPORATE_NAME} - CNPJ: ${COMPANY_INFORMATION.CNPJ}`}</Text>
                        <Text size='xxs' fontWeight='light'>
                            {appVersion}
                        </Text>
                    </LegalInformationColumnStyled>

                    <FooterCardFlagsContainerStyled>
                        <CardFlags cardFlags={cardFlags} height={2} />
                    </FooterCardFlagsContainerStyled>
                </LegalInformationContentStyled>
            </LegalInformationContainerStyled>
        </FooterStyled>
    );
};

export default Footer;
