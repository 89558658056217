import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { mobileOnly } from '~/styles';
import {
    horizontalLineStyle,
    HORIZONTAL_LINE_SPACING,
    HORIZONTAL_SPACING,
    VERTICAL_LINE_POSITION,
    VERTICAL_SPACING
} from '../styles';

export const RuleStyled = styled(motion.div)`
    position: relative;
    margin-left: ${HORIZONTAL_SPACING}rem;
    display: flex;
    flex-direction: column;

    & > div:not(.rule-group) {
        margin-top: ${VERTICAL_SPACING}rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: -2rem;
        left: -${HORIZONTAL_LINE_SPACING}rem;
        opacity: 0.4;
        border-left: 1px solid ${({ theme }) => theme.colors.enlightenedPurple};
        width: 1px;
        height: 100%;
        z-index: 0;
    }

    ${horizontalLineStyle}

    &:after {
        top: ${VERTICAL_LINE_POSITION}rem;
        ${mobileOnly(css`
            top: calc(50% + 2rem);
            width: ${HORIZONTAL_SPACING}rem;
        `)}
    }
`;
