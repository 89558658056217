import styled from 'styled-components';
import ChromeIcon from '~/assets/svg/icons/icon__chrome.svg';

export const AndroidContainer = styled.div`
    height: 35rem;
    overflow-y: hidden;

    .screen-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.philippineSilver};

        & > p {
            font-size: ${({ theme }) => theme.fontSizes['7xl']};
            font-weight: bold;
            margin-top: 6rem;
            color: ${({ theme }) => theme.colors.geminiGray};
        }
    }
`;

export const NotificationContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1rem;
    margin: 0px auto;
    min-height: 6rem;
    gap: 0.6rem;
    width: calc(100% - 7rem);
    max-height: 9rem;
    border-radius: ${({ theme }) => theme.radii.sm};
    color: ${({ theme }) => theme.colors.geminiGray};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};

    .browser-info {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        .browser-name {
            position: relative;
            margin-right: 0.3rem;
            font-size: 1rem;

            &:after {
                content: ' ';
                width: 0.25rem;
                height: 0.25rem;
                position: absolute;
                background-color: ${({ theme }) => theme.colors.gray};
                border-radius: ${({ theme }) => theme.radii.full};
                top: 0.35rem;
                right: -0.6rem;
            }
        }

        .domain {
            font-size: 1rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .title {
        font-size: 1.2rem;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .text {
        font-size: 1rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ChromeIconStyle = styled(ChromeIcon)`
    fill: #707070;
    width: 1.5rem;
`;
