import { useCallback } from 'react';
import { Card, CardHeader } from '@components';
import { useTranslator } from '@hooks';
import { Address } from '@models';
import { captureException } from '@sentry/nextjs';
import { useAddress, useCheckout, SubscriptionDataFormData, SubscriptionReducerActionTypesEnum } from '~/providers';
import { BillingDataForm } from '../form';
import { CardBodyStyled } from '../styles';

export const CreateBillingData = () => {
    const { billingData } = useTranslator().checkoutPage.subscriptionData;
    const { formRef, dispatchSubscriptionFormData } = useCheckout();
    const { countries } = useAddress();

    const parseAddressToFormHandle = useCallback(
        async (address: Address) => {
            try {
                const subscriptionData: SubscriptionDataFormData = formRef.current.getData()
                    ?.subscriptionData as SubscriptionDataFormData;

                subscriptionData.billingData = {
                    ...subscriptionData?.billingData,
                    address: {
                        ...subscriptionData?.billingData?.address,
                        city: address.city,
                        street: address.street ?? '',
                        countryCode: address.country?.code,
                        neighborhood: address.neighborhood ?? '',
                        stateCode: address.state?.code,
                        zipCode: address.zipCode,
                        number: subscriptionData.billingData.address.number ?? '',
                        complement: subscriptionData.billingData.address.complement ?? ''
                    }
                };

                dispatchSubscriptionFormData({
                    type: SubscriptionReducerActionTypesEnum.UpdateSubscriptionData,
                    payload: { subscriptionData }
                });
            } catch (error) {
                captureException(error);
            }
        },
        [formRef, dispatchSubscriptionFormData]
    );

    return (
        <Card type='group'>
            <CardHeader title={String(billingData.title)} />
            <CardBodyStyled type='group'>
                <BillingDataForm
                    enableUnform
                    formRef={formRef}
                    countries={countries}
                    prefixFormScopePath='subscriptionData'
                    parseAddressToFormHandle={parseAddressToFormHandle}
                />
            </CardBodyStyled>
        </Card>
    );
};
