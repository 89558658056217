import { useMemo } from 'react';
import { MotionProps, usePresence } from 'framer-motion';
import { getSendingTechnologyIcons, Shimmer, Text } from '@components';
import { useStepFrequency, useTranslator } from '@hooks';
import { SubToolIdEnum } from '@models';
import { StepData, useAutomationRulerProvider } from '@providers';
import { createQueryParams } from '@utils';
import { RulerStepPopup, RulerStepPopupItem } from '../';
import { SendingTechnologyTooltip } from './sendingTechnologyTooltip';
import {
    RulerStepContentStyled,
    RulerStepHeaderStyled,
    RulerStepSendingTechonlogyListStyled,
    RulerStepStyled
} from './styles';

export type RulerStepProps = StepData & {
    isLoading?: boolean;
};

export const RulerStep = ({ step, messageSequence, isLoading = false }: RulerStepProps) => {
    const { getSubToolPageDetails, duplicateStep, confirmStepRemoval, subToolConfiguration, automationRuler } =
        useAutomationRulerProvider((context) => ({
            getSubToolPageDetails: context.getSubToolPageDetails,
            duplicateStep: context.duplicateStep,
            confirmStepRemoval: context.confirmStepRemoval,
            subToolConfiguration: context.subToolConfiguration,
            automationRuler: context.automationRuler
        }));

    const {
        subTool: { id: subToolId }
    } = subToolConfiguration;

    const {
        subTool: { key: subToolKey }
    } = automationRuler;

    const {
        rulerStepComponent: { popupOptions }
    } = useTranslator().pages.myAutomations;

    const { getFrequency } = useStepFrequency(subToolId);

    const icons = getSendingTechnologyIcons();

    const popupItems = useMemo(() => {
        const { urls } = getSubToolPageDetails({
            params: {
                editTemplatePage: {
                    params: createQueryParams({
                        stepId: String(Number(step.id)),
                        type: String(Number(messageSequence.type)),
                        identifier: messageSequence?.identifier
                    })
                }
            }
        });

        const stepData: StepData = {
            subToolId: subToolId as SubToolIdEnum,
            messageSequence,
            step
        };

        return [
            {
                id: 'edit',
                description: String(popupOptions.edit),
                url: urls.editTemplatePage
            },
            {
                id: 'duplicate',
                description: String(popupOptions.duplicate),
                onClick: () => duplicateStep(step.id)
            },
            {
                id: 'remove',
                description: String(popupOptions.remove),
                onClick: () => confirmStepRemoval(stepData),
                isRemoveOption: true
            }
        ] as Array<RulerStepPopupItem & { id: keyof typeof popupOptions }>;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, messageSequence, subToolId, duplicateStep, confirmStepRemoval, getSubToolPageDetails]);

    const { description: frequencyDescription, subToolFrequencyDescription } = getFrequency(
        step.intervalMinutes,
        step.intervalRange
    );

    const [isPresent, safeToRemove] = usePresence();

    const animations = {
        layout: true,
        initial: 'out',
        animate: isPresent ? 'in' : 'out',
        variants: {
            in: { scale: 1, opacity: 1 },
            out: { scale: 0.5, opacity: 0 }
        },
        onAnimationComplete: () => !isPresent && safeToRemove(),
        transition: { ease: 'easeInOut', duration: 0.4, layout: { duration: 0.2 } }
    } as MotionProps;

    return (
        <Shimmer isActive={isLoading}>
            <RulerStepStyled borderColor={subToolKey} {...animations}>
                <div data-shimmer>
                    <RulerStepHeaderStyled>
                        <Text as='strong' size='lg'>
                            {frequencyDescription}
                        </Text>
                        {!isLoading && <RulerStepPopup subToolKey={subToolKey} items={popupItems} />}
                    </RulerStepHeaderStyled>
                    <RulerStepContentStyled>
                        <Text as='span' size='xxs'>
                            {subToolFrequencyDescription}
                        </Text>
                        <div>
                            <Text size='md'>{step.name}</Text>
                        </div>
                        <RulerStepSendingTechonlogyListStyled>
                            {(step.templates ?? []).map((template) => {
                                const icon = icons.find((item) => item.type === template.sendingTechnology);
                                const status = template.isActive ? 'active' : 'inactive';

                                return (
                                    <SendingTechnologyTooltip
                                        key={template.sendingTechnology}
                                        type={template.sendingTechnology}
                                        icon={icon.icon}
                                        status={status}
                                    />
                                );
                            })}
                        </RulerStepSendingTechonlogyListStyled>
                    </RulerStepContentStyled>
                </div>
            </RulerStepStyled>
        </Shimmer>
    );
};
