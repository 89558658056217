import { SendingTechnology } from '@models';

export enum CustomerPlanType {
    None = 0,

    /** @deprecated Not used in Enviou 3.0 */
    CartRecovery = 1,

    BulkSendingTool = 2,

    /** @deprecated Not used in Enviou 3.0 */
    DecoleStartup = 3,

    /** @deprecated Not used in Enviou 3.0 */
    MonthlyContract = 4,

    /** @deprecated Not used in Enviou 3.0 */
    PurchaseTheme = 5,

    /** @deprecated Not used in Enviou 3.0 */
    BankSlipReminder = 6,

    ThirdPartyBilling = 7,

    Loose = 8,

    AutomationTool = 9
}

export type SubscriptionTool = {
    Id?: number;
    totalCredits: number;
    price: number;
    type: CustomerPlanType;
    isUnlimited?: boolean;
    sendingTechnologies?: Array<SubscriptionToolSendingTechnology>;
    automaticContracting?: AutomaticContractingLooseBalance;
};

export type SubscriptionToolSendingTechnology = {
    sendingTechnology?: SendingTechnology;
    price: number;
    currency: string;
};

export type AutomaticContractingLooseBalance = {
    isEnable: boolean;
    minBalancePercentage?: number;
    balanceToBeContracted?: number;
    maxTimesToRecontract?: number;
    currentTimeOfContracting?: number;
};
