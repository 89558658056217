import styled from 'styled-components';
import { SubToolColors } from '~/styles/theme';

export const PopupActionStyled = styled.button`
    display: flex;
    box-shadow: none;
    border: none;
    color: ${({ theme }) => theme.colors.white};
    background-color: transparent;
    padding: 0;

    &:focus-visible {
        outline-width: 0.2rem;
    }

    &:not(:disabled):hover {
        color: ${({ theme }) => theme.colors.gallery};
        background-color: transparent;
    }

    svg {
        height: 2.6rem;
        width: 2.6rem;
        cursor: pointer;
    }
`;

export const PopupStyled = styled.ul<{ subToolColor: SubToolColors }>`
    outline: none;
    width: 14rem;
    height: max-content;
    border-radius: ${({ theme }) => theme.radii.lg};
    border: 1px solid ${({ subToolColor, theme }) => theme.subToolColors[subToolColor]};
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 999;
    list-style: none;
    overflow: hidden;

    li {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        font-family: ${({ theme }) => theme.fonts.primary};
        line-height: ${({ theme }) => theme.lineHeights.base};
        font-size: ${({ theme }) => theme.fontSizes.sm};
        transition: all 0.1s ease;
        color: ${({ theme }) => theme.colors.purple};
        cursor: pointer;
        outline-width: 0;

        &:focus-visible,
        &:hover {
            background-color: ${({ theme }) => theme.colors.eminencePurple};
            color: ${({ theme }) => theme.colors.white};
        }

        &.remove-option {
            color: ${({ theme }) => theme.colors.sangria};

            &:focus-visible,
            &:hover {
                background-color: ${({ theme }) => theme.colors.sangria};
                color: ${({ theme }) => theme.colors.white};
            }
        }

        & > a,
        & > span {
            color: inherit;
            display: block;
            padding: 1.6rem;
        }
    }
`;
