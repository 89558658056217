import { AriaRole, ReactNode } from 'react';
import NextLink, { LinkProps as LinkNextProps } from 'next/link';
import { useTranslatedRoute } from '@hooks';
import { ReplaceData } from '@utils';

export type LinkProps = LinkNextProps & {
    children: ReactNode;
    as?: string;
    showLink?: boolean;
    role?: AriaRole;
    tabIndex?: number;
    translatedParams?: ReplaceData;
};

export const Link = ({ showLink = true, children, href, as, role, tabIndex, translatedParams, ...args }: LinkProps) => {
    const translatorRoute = useTranslatedRoute();

    if (showLink) {
        return (
            <NextLink href={href} as={translatorRoute(as, translatedParams)} {...args}>
                <a tabIndex={tabIndex} role={role}>
                    {children}
                </a>
            </NextLink>
        );
    }

    return <>{children}</>;
};
