import { useRedirectToPainel2, useTranslatedRoute, useTranslator } from '@hooks';
import { PAINELN2_CAMPAIGN_LINK } from '@utils';
import EmailIcon from '~/assets/svg/icons/icon__email--open.svg';
import MarketingIcon from '~/assets/svg/icons/icon__marketing.svg';
import { routesName } from '~/locales/route';
import { Menu, MenuItem } from './menu';
import { IconStyled, NavbarStyled } from './styles';

export const Navbar = () => {
    const { redirect } = useRedirectToPainel2();
    const translateRoute = useTranslatedRoute();
    const { header, subTools } = useTranslator();

    return (
        <NavbarStyled aria-label='Main navigation'>
            <ul role='menubar'>
                <Menu
                    label={String(header.home)}
                    link={{ href: routesName.root, as: translateRoute(routesName.root, null) }}
                />
                <Menu label={String(subTools.common.head.title)} tabIndex={0}>
                    <MenuItem
                        label={String(header.toolsPopup.emailMarketing)}
                        link={{
                            href: redirect(PAINELN2_CAMPAIGN_LINK, false)
                        }}
                        icon={
                            <IconStyled>
                                <EmailIcon />
                            </IconStyled>
                        }
                    />
                    <MenuItem
                        label={String(header.toolsPopup.marketingAutomations)}
                        link={{
                            href: routesName.tool.automationTool,
                            as: translateRoute(routesName.tool.automationTool, { subTool: '' })
                        }}
                        icon={
                            <IconStyled>
                                <MarketingIcon />
                            </IconStyled>
                        }
                    />
                </Menu>
                <Menu label={String(header.toolsPopup.statistics)}>
                    <MenuItem
                        label={String(header.toolsPopup.emailMarketing)}
                        link={{ href: redirect(PAINELN2_CAMPAIGN_LINK, false) }}
                        icon={
                            <IconStyled>
                                <EmailIcon />
                            </IconStyled>
                        }
                    />
                    <MenuItem
                        label={String(header.toolsPopup.marketingAutomations)}
                        link={{ href: routesName.tool._statistics, as: translateRoute(routesName.tool.statistics) }}
                        icon={
                            <IconStyled>
                                <MarketingIcon />
                            </IconStyled>
                        }
                    />
                </Menu>
                <Menu
                    label={String(header.mySubscription)}
                    link={{ href: routesName.mySubscription.base, as: translateRoute(routesName.mySubscription.base) }}
                />
            </ul>
        </NavbarStyled>
    );
};
