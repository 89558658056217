import * as yup from 'yup';
import { useAutomaticContractingLooseBalanceValidation } from '~/components/containers/contractLooseBalance/automaticContracting';
import { useTranslator } from '~/hooks/useTranslator';
import { AutomaticContractingLooseBalance } from '~/models/subscriptionTool';
import { SubscriptionPaymentFormData } from '~/providers/reducers/subscriptionReducer';
import {
    type PaymentMethodOmit,
    useValidation as useSubscriptionValidation
} from '../../containers/checkout/checkoutForm/validation';

export type ContractLooseBalanceSchema = SubscriptionPaymentFormData & {
    creditsPerMonth: number;
    automaticContracting?: AutomaticContractingLooseBalance;
};

export const useValidation = () => {
    const {
        checkoutPage,
        pages: { contractLooseBalance }
    } = useTranslator();
    const { subscriptionPayment } = checkoutPage.form.dataValidation;

    const { paymentMethodDataSchema } = useSubscriptionValidation();
    const { automaticContractingLooseBalanceSchema } = useAutomaticContractingLooseBalanceValidation();

    const contractLooseBalanceSchema: yup.SchemaOf<Omit<ContractLooseBalanceSchema, PaymentMethodOmit>> = yup
        .object()
        .optional()
        .shape({
            ...paymentMethodDataSchema.fields,
            discountCoupon: yup.string().optional(),
            hasAcceptedTheTerms: yup
                .boolean()
                .required(String(subscriptionPayment.hasAcceptedTheTerms.required))
                .oneOf([true], String(subscriptionPayment.hasAcceptedTheTerms.required)),
            creditsPerMonth: yup
                .number()
                .required(String(contractLooseBalance.balanceContractingSection.dropdowns.creditsPerMonth.required)),
            automaticContracting: automaticContractingLooseBalanceSchema
        });

    return { contractLooseBalanceSchema };
};
