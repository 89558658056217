import styled from 'styled-components';
import { CardBody } from '~/components/elements/card';
import { CardTextTitleStyled } from '~/components/elements/card/text/styles';
import { SelectFormGroup } from '~/components/forms/selectFormGroup';

export const HeaderStyled = styled(CardTextTitleStyled)`
    margin-bottom: 2.4rem;
    justify-content: center;
`;

export const SelectFormGroupStyled = styled(SelectFormGroup)`
    padding: 0;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    label {
        font-size: ${({ theme }) => theme.fontSizes.xs};
        font-weight: normal;
        white-space: nowrap;
        margin-bottom: 0;
    }

    & > button {
        font-size: ${({ theme }) => theme.fontSizes.xs};
        white-space: nowrap;
    }

    & > div {
        width: 25rem;
    }
`;

export const ToggleSwitchContent = styled.div`
    margin-bottom: 1.6rem;

    div > input + label {
        color: ${({ theme }) => theme.colors.lightBlack};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: ${({ theme }) => theme.fontSizes.xs};
        white-space: wrap;
        margin-bottom: 0;
    }
`;

export const CardBodyStyled = styled(CardBody)`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;
