import HTMLReactParser from 'html-react-parser';
import { css } from 'styled-components';
import { IFrame } from '@components';
import { useTranslator } from '@hooks';
import { convertToYouTubeEmbed } from '@utils';
import PlayIcon from '~/assets/svg/icons/icon__play.svg';

// Cannot use styled-component functions here, the values must be fixed
export const iframeLoadingStyled = css`
    body {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        text-align: center;
        color: #661174;
        font-size: 1.25rem;
        font-family: 'League Spartan', Roboto, sans-serif;
    }
`;

type VideoProps = {
    videoUrl: string;
};

export const Video = ({ videoUrl }: VideoProps) => {
    const url = convertToYouTubeEmbed(videoUrl);
    const {
        pages: {
            helpAndTutorials: {
                tutorials: { loading }
            }
        }
    } = useTranslator();

    if (!url) {
        return (
            <i>
                <PlayIcon />
            </i>
        );
    }

    return (
        <IFrame
            src={url}
            allowFullScreen
            headChildren={<style type='text/css'>{HTMLReactParser(iframeLoadingStyled.join(''))}</style>}>
            <p>{String(loading)}</p>
        </IFrame>
    );
};
