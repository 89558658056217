import styled from 'styled-components';

export const BarChartLabelStyled = styled.li`
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: 25rem 1fr 1fr;
    align-items: center;
    min-height: 2.4rem;

    & > span:first-child {
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 1.6rem;

        span {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 25rem;
        }
    }

    & > span:last-child {
        text-align: end;
    }
`;

export const BarChartLabelHighlightStyled = styled.span<{ color: string }>`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    display: inline-block;
`;
