import styled, { DefaultTheme, ThemedStyledProps, css } from 'styled-components';
import { Card } from '~/components/elements/card';
import type { CardStyleProps } from '~/components/elements/card/styles';
import { FormGroup } from '~/components/forms';
import { mobileAndTabletOnly } from '~/styles';
import { SubToolColors } from '~/styles/theme';

type SubToolCardStyledProps = {
    subToolColor: SubToolColors;
};

type CardProps = ThemedStyledProps<CardStyleProps & SubToolCardStyledProps, DefaultTheme>;

export const FormGroupStyled = styled(FormGroup)`
    padding: 0;

    & > div {
        padding: 0;

        & + div {
            padding-left: 1.6rem;

            ${mobileAndTabletOnly(css`
                padding-left: 0;
            `)}
        }

        & > label {
            margin: 0.8rem 0;
        }
    }
`;

export const CardStyled = styled(Card).attrs((props: CardProps) => ({
    borderColor: props.theme.subToolColors[props.subToolColor]
}))<SubToolCardStyledProps>`
    padding: 3.2rem 4rem;

    & > header {
        padding: 0;
    }
    & > main {
        margin-top: 2.4rem;
        padding: 0;
        gap: 1.6rem;
    }
`;

export const ContactContent = styled.div``;
