import { SendingTechnologyTypeEnum } from './sendingTechnology';
import { SubToolConfiguration } from './subToolConfiguration';
import { NavigationTriggerRule } from './subToolNavigationTrigger';

export type TrackAnalytics = { [tagName: string]: string };

export enum ShippingOrderEnum {
    FirstSending = 0,
    SecondSending = 1,
    ThirdSending = 2,
    FourthSending = 3,
    FifthSending = 4,
    Finalization = 5,
    Error = 6,
    OptOut = 7,
    SubtoolNotConfigured = 8
}

export enum SubToolTemplateConfigurationTypeEnum {
    General = 1,
    Category = 2,
    Product = 3
}

export enum SubToolTemplateConfigurationFrequencyRange {
    Seconds = 0,
    Minutes = 1,
    Hours = 2,
    Days = 3,
    Weeks = 4
}

export type SubToolTemplateConfiguration = {
    id?: number;
    subToolConfiguration: SubToolConfiguration;
    baseThemeId?: number;
    shippingOrder: ShippingOrderEnum;
    frequencyInMinutes: number;
    frequencyRange?: SubToolTemplateConfigurationFrequencyRange;
    subject: string;
    baseContent: string;
    productContent: string;
    reserveProductContent: string;
    reviewContent: string;
    isActive: boolean;
    trackAnalytics: TrackAnalytics;
    itemsPerLine: string;
    sendingTechnology: SendingTechnologyTypeEnum;
    type: SubToolTemplateConfigurationTypeEnum;
    identifier?: string;
    default?: boolean;
    rules?: Array<NavigationTriggerRule>;
    creationDate?: Date;
};

export type ManualContactSubToolTemplateConfiguration = {
    SubToolTemplateConfigurationId?: number;
    subToolConfigurationId: number;
    baseContent: string;
    isActive: boolean;
    default: boolean;
};
