import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';
import { mobileOnly, tabletAndDesktopOnly } from '~/styles';
import { DebugMode, horizontalLineStyle, showTreeValue, HORIZONTAL_SPACING, SHADOW_TRANSPARENCY } from '../styles';

type InputStyledProps = {
    label?: string;
    fieldType?: 'condition-type' | 'condition-value';
};

const showLabel = (theme: DefaultTheme, value?: string) =>
    value &&
    tabletAndDesktopOnly(
        css`
            &:before {
                content: '${value}';
                position: absolute;
                top: -2.8rem;
                left: 0;
                font-weight: ${theme.fontWeights.semiBold};
                font-size: ${theme.fontSizes.md};
                font-family: ${theme.fonts.primary};
            }
        `
    );

export const InputStyled = styled(motion.div)<InputStyledProps>`
    padding: 0;
    width: 100%;

    & > div {
        padding: 0;
    }

    &.add-rule-dropdown {
        position: relative;
        max-width: 16rem;

        ${horizontalLineStyle}

        button {
            & + div {
                width: 24rem;
            }
        }
    }

    &.event-type {
        max-width: 30rem;

        button {
            & > span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 30rem;
            }
            & + div {
                min-width: 30rem;
            }
        }
    }

    &.condition-type {
        max-width: 20rem;
        position: relative;

        button {
            z-index: 1;
            & + div {
                min-width: 20rem;
            }
        }

        ${({ theme, fieldType, label }) => fieldType === 'condition-type' && showLabel(theme, label)}
    }

    &.condition-value {
        max-width: 30rem;
        display: flex;
        gap: 1.6rem;
        position: relative;

        ${({ theme, fieldType, label }) => fieldType === 'condition-value' && showLabel(theme, label)};
    }
`;

export const AddRuleDropdownOptionStyled = styled.span`
    display: flex;
    align-items: center;
    position: relative;

    svg {
        margin-right: 1rem;
        height: 1em;
        color: ${({ theme }) => theme.colors.enlightenedPurple};
    }
`;

export const ActionsStyled = styled(motion.div)`
    display: flex;
    flex-direction: row;
    gap: 1.6rem;

    & > button {
        width: 4rem;
        height: 4rem;
        border: none;
        border-radius: 50%;
        box-shadow: 1px 1px 6px ${({ theme }) => rgba(theme.colors.lightBlack, SHADOW_TRANSPARENCY)};
        color: ${({ theme }) => theme.colors.purple};
        padding: 0;

        svg {
            display: block;
            height: 100%;
            margin-top: 0.2rem;
            color: ${({ theme }) => theme.colors.sangria};
        }
    }
`;

export const LogicalOperatorStyled = styled(motion.div)<DebugMode>`
    display: flex;
    gap: 1.6rem;
    position: relative;

    &:first-child:after {
        content: unset;
    }

    & > div {
        width: ${HORIZONTAL_SPACING}rem;
        position: relative;

        & > div {
            z-index: 1;
        }

        button[name='logicalOperator'] {
            & + div {
                width: 9.6rem;
            }
        }
    }

    ${({ debugValue }) => showTreeValue(debugValue)}
`;

export const RuleContentStyled = styled(motion.div)<DebugMode>`
    display: flex;
    width: 100%;
    gap: 0.8rem;
    z-index: auto;
    position: relative;

    ${({ debugValue }) => showTreeValue(debugValue)}

    ${mobileOnly(css`
        flex-direction: column;

        & :is(.condition-type, .condition-value, .event-type) {
            max-width: 100%;
        }

        &:before {
            top: -16%;
        }
    `)}
`;
