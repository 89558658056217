/* istanbul ignore file: for now it will be ignored because it does not have methods that need to be tested */

/**
 * Formats a number using the currency code in the default of the given language
 * @param {number} value The value to be formatted
 * @param {string} locale The name of the current culture
 * @param {string} currency The ISO Currency Symbol
 * @returns The value formatted as string
 */
export const currencyFormatter = (
    value: number,
    locale: string,
    currency: string,
    options?: Omit<Intl.NumberFormatOptions, 'currency'>
): string => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency, ...options }).format(value);
};

/* istanbul ignore next */
export const numberFormatter = (value: number, locale: string, options?: Intl.NumberFormatOptions): string => {
    return new Intl.NumberFormat(locale, options).format(value);
};

/* istanbul ignore next */
export const percentageFormatter = (value: number, locale: string): string => {
    return new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value);
};

export const isGreaterThanZero = (value: number) => {
    return value > 0;
};
export const isGreaterThanOne = (value: number) => {
    return value > 1;
};
export const getCompactValue = (value: number, maxValue = 1_000_000) => (value > maxValue ? 'compact' : 'standard');
