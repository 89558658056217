import _isEmpty from 'lodash/isEmpty';
import { replace } from '@utils';
import {
    delimiter,
    getAllTemplateVariables,
    getVariable as getLocalVariable,
    normalizeVariable,
    VariableParentKey
} from '../utils';
import { PreviewData } from './generatePreview';

const filterByAllowedVariables = (
    templateVariables: RegExpMatchArray | [],
    variableParentKey: VariableParentKey,
    allowedVariables: Array<string> = []
) => {
    if (!templateVariables || _isEmpty(allowedVariables)) {
        return templateVariables;
    }

    const allowedVariableKeys = allowedVariables.map(
        (item) => getLocalVariable(normalizeVariable(item), variableParentKey)?.key
    );

    return templateVariables.filter((variableName) =>
        allowedVariableKeys.includes(getLocalVariable(normalizeVariable(variableName), variableParentKey)?.key)
    );
};

const replaceTemplateVariable = (
    preview: string,
    previewData: PreviewData,
    variableName: string,
    variableParentKey: VariableParentKey
) => {
    const normalizedVariableName = normalizeVariable(variableName);
    const variable = getLocalVariable(normalizedVariableName, variableParentKey);

    if (variable) {
        const value = previewData[variable.key] ?? '';
        return replace(preview, { [normalizedVariableName]: value }, delimiter);
    }

    return preview;
};

export const generateTemplatePreview = (
    template: string,
    previewData: PreviewData,
    variableParentKey: VariableParentKey,
    allowedVariables: Array<string> = []
) => {
    const variables = getAllTemplateVariables(template);
    const filterAllowedVariables = filterByAllowedVariables(variables, variableParentKey, allowedVariables);

    let preview = template;
    filterAllowedVariables.forEach((variableName) => {
        preview = replaceTemplateVariable(preview, previewData, variableName, variableParentKey);
    });

    return preview;
};
