import { CPF_LENGTH } from './constants';

export const CNPJ_LENGTH = 14;
const MAX_LENGTH_OF_CHECK_DIGIT = 2;

export const isAValidCPF = (value: string): boolean => {
    if (!value || value.length < CPF_LENGTH) {
        return false;
    }

    let length = value.length - MAX_LENGTH_OF_CHECK_DIGIT;
    const digits = value.slice(length);

    let sum: number;
    const baseNumber = 10;

    const isAValidDigit = (digit: number) => {
        sum = 0;
        length += digit;
        for (let x = 0; x < length; x++) {
            sum += Number(value.charAt(x)) * (baseNumber + digit - x);
        }

        let remainder = (sum * baseNumber) % CPF_LENGTH;
        if (remainder >= baseNumber) {
            remainder = 0;
        }
        if (remainder !== Number(digits.charAt(digit))) {
            return false;
        }

        return true;
    };

    return isAValidDigit(0) && isAValidDigit(1);
};

export const isAValidCNPJ = (value: string) => {
    if (!value || value.length < CNPJ_LENGTH) {
        return false;
    }

    let length = value.length - MAX_LENGTH_OF_CHECK_DIGIT;
    const digits = value.slice(length);

    let sum: number;
    const baseNumber = 11;
    const baseIndex = 2;
    const positionNine = 9;
    const seven = 7;

    const isAValidDigit = (digit: number) => {
        sum = 0;
        length += digit;
        let pos = length - seven;

        for (let i = length; i >= 1; i--) {
            sum += Number(value.charAt(length - i)) * pos--;
            if (pos < baseIndex) {
                pos = positionNine;
            }
        }

        const remainder = sum % baseNumber < baseIndex ? 0 : baseNumber - (sum % baseNumber);

        if (remainder !== Number(digits.charAt(digit))) {
            return false;
        }

        return true;
    };

    return isAValidDigit(0) && isAValidDigit(1);
};
