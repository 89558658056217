import styled from 'styled-components';

const MAX_FUNNEL_HEIGHT = 71.75; /* 71.75rem */

export const FunnelStageContentStyled = styled.span<{ stagesLength?: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: ${({ stagesLength = 7 }) => `${MAX_FUNNEL_HEIGHT / stagesLength}rem`};
    gap: ${({ theme }) => theme.space.xxs};

    strong {
        color: ${({ theme }) => (theme.theme === 'light' ? theme.colors.white : theme.colors.lightBlack)};
    }

    & > span {
        color: ${({ theme }) => theme.colors.paleCyan};
    }
`;
