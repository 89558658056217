import styled from 'styled-components';
import { PluginsColors } from '~/styles/theme';

export const PluginCardStyled = styled.li<{ pluginType: PluginsColors }>`
    padding: 2.4rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};

    & > div {
        display: flex;
        gap: 2.4rem;
        height: 100%;

        svg {
            align-self: center;
            width: 4.8rem;
            height: 4.8rem;
        }

        & > div {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
            flex: 1;
            justify-content: center;

            h3 {
                color: ${({ theme, pluginType }) => theme.pluginsColors[pluginType]};
            }

            p {
                max-width: 24rem;
            }
        }
    }
`;
