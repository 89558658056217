import styled from 'styled-components';

export const SelectContentStyled = styled.div`
    max-height: 35rem;
    overflow: auto;
    z-index: 10000;

    display: flex;
    flex-direction: column;
    outline: 0;

    border-radius: ${({ theme }) => theme.radii.sm};
    background-color: ${({ theme }) => theme.default.input.backgroundColor};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
`;
