import styled from 'styled-components';
import { containerPadding } from '../styles';

export const ModalHeaderStyled = styled.header`
    ${containerPadding}
    gap: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 0.2rem ${({ theme }) => theme.colors.lightPurple};
    color: ${({ theme }) => theme.colors.lightBlack};
    text-transform: uppercase;

    > button {
        cursor: pointer;
        line-height: 0;
        border: unset;
        background: unset;
        width: 3rem;
        color: ${({ theme }) => theme.colors.purple};
        border-radius: 50%;
    }
`;
