import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Box } from '~/components/elements/box';
import { DialogContent } from '~/components/elements/dialog';
import { mobileAndTabletOnly, mobileOnly } from '~/styles';

export const SetupConfigurationContentStyled = styled.div`
    margin-top: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
`;

export const ItemConfigurationListStyled = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    ${mobileOnly(css`
        gap: 1.6rem;
    `)}

    .not-found-items {
        text-align: center;
        color: ${({ theme }) => theme.colors.philippineSilver};
        margin-top: 2.2rem;
    }
`;

export const ListItemStyled = styled(Box).attrs({ as: motion.li })`
    padding: 0%;
    position: relative;
    overflow: initial;

    & > span {
        position: absolute;
        right: 1.6rem;
        bottom: 0.8rem;
        font-size: ${({ theme }) => theme.fontSizes.xxs};
        color: ${({ theme }) => theme.colors.philippineSilver};
    }

    ${mobileAndTabletOnly(css`
        &.list-header {
            display: none;
        }
    `)}
` as unknown as typeof motion.li;

export const ItemConfigurationStyled = styled.div`
    display: grid;
    grid-template-columns: 5fr minmax(22rem, 0.5fr) minmax(21.4rem, 0.5fr) minmax(13.8rem, 0.5fr) auto;
    grid-column-gap: 1.6rem;
    align-items: center;
    cursor: pointer;
    padding: 1.6rem 2.4rem;

    .template-type-dropdown {
        padding: 0;

        & > div > div {
            min-width: 28.7rem;
            right: 0;
        }
    }

    .manual-average-period-input {
        padding: 0;
        height: fit-content;
        max-width: 12.6rem;
        margin: 0 auto;
    }

    .template-type-dropdown > div:before,
    .manual-average-period-input > div:before {
        white-space: nowrap;
        font-size: 1.2rem;
        bottom: -1.9rem;
    }

    & > button,
    & > a > button {
        margin: 0 auto;
        white-space: nowrap;
        padding: 1rem 1.2rem 0.83rem;
    }

    .view-more-button {
        text-decoration: underline;
    }

    ${mobileAndTabletOnly(css`
        grid-template-columns: minmax(22rem, 0.5fr) minmax(12.4rem, 0.5fr) minmax(12.4rem, 0.5fr) auto;
        & > div:first-child {
            grid-column: 1 / -1;
            margin-bottom: 1.6rem;
        }
    `)}

    ${mobileOnly(css`
        .view-more-button {
            display: none;
        }
    `)}
`;

export const DialogContentStyled = styled(DialogContent)`
    padding: 3.2rem;
    width: 100%;
    max-width: 66rem;

    & > h2 {
        margin-bottom: 1.6rem;
        color: ${({ theme }) => theme.colors.sangria};
    }

    & > p {
        font-size: ${({ theme }) => theme.fontSizes.md};
        max-width: 57.6rem;
        margin: 0 auto;
    }

    footer {
        margin-top: 2.4rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.6rem;

        a,
        button {
            width: 100%;
            max-width: 31.4rem;
        }

        a:last-child {
            text-decoration: underline;
        }
    }
`;
