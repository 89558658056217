import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > section {
        margin-bottom: 16rem;
    }

    & > svg {
        visibility: hidden;
        position: absolute;
    }
`;
