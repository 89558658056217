import styled from 'styled-components';
import { Form } from '@unform/web';
import LogoEnviouSlogan from '~/assets/svg/logo/logo__enviou-slogan.svg';

export const SignInFormStyled = styled(Form)`
    width: 100%;
    max-width: 40rem;
    padding: 2.4rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.16);

    input[type='text'],
    input[type='password'] {
        width: 100%;
        padding: 0.8rem 1.6rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 1.6rem;
        line-height: 1.5;
        outline-color: ${({ theme }) => theme.colors.purple};

        &:first-child {
            border-top-left-radius: 0.4rem;
            border-top-right-radius: 0.4rem;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:nth-child(2) {
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &:last-child {
            border-bottom-left-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
        }
    }

    > p {
        font: normal 1.4rem 'Roboto', sans-serf;
        color: ${({ theme }) => theme.default.dangerColor};
        text-align: center;
    }

    button {
        width: 100%;
    }

    a {
        font-size: 1.6rem;
        color: ${({ theme }) => theme.colors.purple};
        cursor: pointer;
        text-align: center;
    }
`;

export const LogoStyled = styled(LogoEnviouSlogan).attrs({ title: 'Logo Enviou' })`
    width: 100%;
    max-width: 20rem;
`;

export const SingInHeaderStyled = styled.header`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1.6rem;

    svg {
        margin-bottom: 2.4rem;
    }

    h1 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1.6rem;

        a {
            text-decoration: none;
            color: ${({ theme }) => theme.colors.purple};
        }
    }
`;

export const FormContentStyled = styled.div`
    margin: 1.6rem 0;
    display: flex;
    flex-direction: column;
`;
