import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type ExpandableGroupStyledProps = {
    hidden?: boolean;
};

export const ExpandableGroupStyled = styled(motion.div)<ExpandableGroupStyledProps>`
    overflow: hidden;

    ${({ hidden }) =>
        hidden &&
        css`
            & > div {
                visibility: hidden;
            }
        `}
`;
