import styled from 'styled-components';
import { cssFlexGridByDevice, FlexGridValues } from '~/styles';

export type RadioButtonFormGroupStyledProps = {
    flexGrid: FlexGridValues;
};

export const RadioButtonFormGroupStyled = styled.div<RadioButtonFormGroupStyledProps>`
    display: flex;
    flex-direction: column;
    ${({ flexGrid }) => cssFlexGridByDevice(flexGrid)}

    > label {
        margin-bottom: 1.6rem;
    }
`;
