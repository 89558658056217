import {
    compareAsc,
    format,
    parse,
    isDate,
    formatDistance,
    formatRelative,
    addDays as addDaysFns,
    parseISO
} from 'date-fns';
import * as locales from 'date-fns/locale';

export enum DateFormatEnum {
    DateAndTimeFormatUS = 'yyyy-MM-dd hh:mm:ss',
    DateAndTimeFormatPtBR = 'dd/MM/yyyy hh:mm:ss',
    OnlyTime = 'hh:mm:ss',
    OnlyDateUSFormat = 'yyyy-MM-dd',
    OnlyDatePtBRFormat = 'dd/MM/yyyy',
    Day = 'd',
    Month = 'MMMM',
    Year = 'yyyy',
    MonthAndYear = 'MM/yyyy'
}

export enum CompareDateResult {
    Minor = -1,
    Equal = 0,
    Bigger = 1
}

export type DateFormatType = 'short' | 'datetime' | 'long' | 'relative' | 'ago' | DateFormatEnum;

export const customLocales = {
    ...locales,
    'es-ES': locales.es,
    'pt-BR': locales.ptBR,
    'en-US': locales.enUS
} as const;

export const formatDate = (
    value: Date | number,
    dateFormat: DateFormatType,
    locale?: string,
    baseDate: Date | number = new Date()
): string => {
    const localeId = locale || (typeof window !== 'undefined' ? document.documentElement.lang || 'pt-BR' : 'pt-BR');
    const currentLocale = customLocales[localeId?.replace('-', '') || 'ptBR'];

    if (isDate(value)) {
        switch (dateFormat) {
            case 'short':
                return format(value, 'P', { locale: currentLocale });
            case 'datetime':
                return format(value, 'P p', { locale: currentLocale });
            case 'long':
                return format(value, 'PPPP', { locale: currentLocale });
            case 'relative':
                return formatRelative(value, baseDate, { locale: currentLocale });
            case 'ago':
                return formatDistance(value, baseDate, {
                    locale: currentLocale,
                    addSuffix: true
                });
            default:
                return format(value, dateFormat, { locale: currentLocale });
        }
    }

    return format(new Date(value), 'P pp', { locale: currentLocale });
};

/**
 * Compare the two dates and return Bigger(1) if the first date is after the second, Minor(-1) if the first date is before the second or (Equal)0 if dates are equal.
 * @param {Date} initialDate The first date to compare
 * @param {Date} finalDate the second date to compare
 * @param {DateFormatEnum} dateFormat Date format for comparison
 * @returns The result of the comparison
 */
export const compareDates = (
    initialDate: Date,
    finalDate: Date,
    dateFormat: DateFormatEnum = DateFormatEnum.OnlyDateUSFormat
): CompareDateResult => {
    initialDate = parse(formatDate(initialDate, dateFormat), dateFormat, new Date());
    finalDate = parse(formatDate(finalDate, dateFormat), dateFormat, new Date());

    return compareAsc(initialDate, finalDate) as CompareDateResult;
};

export const addDays = (date: Date | number, amount: number) => addDaysFns(date, amount);

export const convertStringToDate = (date: string): Date => parseISO(date);

/**
 * Checks if the string is a valid ISO or UTC type date
 * @param {Date} value The string date value
 * @returns `True` if is valid
 */
export const isIsoOrUtcDateString = (value: string): boolean => {
    const dateIsoOrUtcRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

    return value && typeof value === 'string' && dateIsoOrUtcRegex.test(value);
};
