import { ReactNode } from 'react';
import Head from 'next/head';
import { HeaderContainer, Text, Link } from '@components';
import { useRedirectToPainel2, useTranslator } from '@hooks';
import ArrowRight from '~/assets/svg/controls/arrow--right.svg';
import LogoEnviou from '~/assets/svg/logo/logo__enviou-slogan.svg';
import { PAINEL2_URL } from '~/utils/constants';
import { LayoutContentStyled, LayoutStyled, HeaderContentStyled } from './styles';

type MigrationLayoutProps = {
    children: ReactNode;
};

export const MigrationLayout = ({ children }: MigrationLayoutProps) => {
    const { redirect } = useRedirectToPainel2(PAINEL2_URL);
    const { buttons } = useTranslator();

    return (
        <LayoutStyled>
            <Head>
                <title>Migração</title>
            </Head>

            <HeaderContainer>
                <HeaderContentStyled>
                    <Link href={redirect('/', false)}>
                        <i>
                            <ArrowRight />
                        </i>
                        <Text as='span' size={'xl'} fontWeight='semiBold'>
                            {String(buttons.backToTheMiddlePanel)}
                        </Text>
                    </Link>
                    <Link href={redirect('/', false)}>
                        <LogoEnviou title='Logo Enviou' height='4rem' className='logo__enviou' />
                    </Link>
                </HeaderContentStyled>
            </HeaderContainer>
            <LayoutContentStyled>{children}</LayoutContentStyled>
        </LayoutStyled>
    );
};
