import {
    endOfMonth,
    endOfWeek,
    endOfYear,
    isDate,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    startOfDay,
    endOfDay
} from 'date-fns';
import { convertStringToDate } from '@utils';

export type DateFilterOptions =
    | 'today'
    | 'thisWeek'
    | 'thisMonth'
    | 'thisYear'
    | 'last30Days'
    | 'last60Days'
    | 'last90Days'
    | 'date';

export type DateFilterOptionsWithCustomTimePeriod = DateFilterOptions | 'customizeTimePeriod';
export type PeriodType = DateFilterOptionsWithCustomTimePeriod | string;

type CalculateDatePeriodType = {
    [key in DateFilterOptions]: (startDate?: Date, endDate?: Date) => { startDate?: Date; endDate?: Date };
};

const currentDate = new Date();
const thirtyDays = 30;
const sixtyDays = 60;
const ninetyDays = 90;

export const getValidDate = (value?: string) =>
    value && isDate(new Date(value)) ? convertStringToDate(value) : currentDate;

export const calculateDatePeriod: CalculateDatePeriodType = {
    date: (startDate, endDate) => ({ startDate: startOfDay(startDate), endDate: endDate && endOfDay(endDate) }),
    today: () => ({ startDate: startOfDay(currentDate), endDate: endOfDay(currentDate) }),
    thisWeek: () => ({ startDate: startOfWeek(currentDate), endDate: endOfWeek(currentDate) }),
    thisMonth: () => ({ startDate: startOfMonth(currentDate), endDate: endOfMonth(currentDate) }),
    thisYear: () => ({ startDate: startOfYear(currentDate), endDate: endOfYear(currentDate) }),
    last30Days: () => ({ startDate: subDays(startOfDay(currentDate), thirtyDays), endDate: endOfDay(currentDate) }),
    last60Days: () => ({ startDate: subDays(startOfDay(currentDate), sixtyDays), endDate: endOfDay(currentDate) }),
    last90Days: () => ({ startDate: subDays(startOfDay(currentDate), ninetyDays), endDate: endOfDay(currentDate) })
} as const;

export const isCustomizeTimePeriodType = (periodType: PeriodType) => periodType === 'customizeTimePeriod';
export const isDateType = (periodType: PeriodType) => periodType === 'date';
