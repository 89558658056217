import { PushNotificationContent } from '@models';
import axiosClient from './apiService';

export const GetPushConfigurationIconUrl = '/sending-technology/push/configuration/icon';

export const DownloadPushSdksAsync = async () => {
    return axiosClient.get<Blob>('/sending-technology/push/download-sdks', { responseType: 'blob' });
};

export const SaveConfigurationAsync = async (data: FormData) => {
    return axiosClient.post('/sending-technology/push/configuration', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const SavePushContentImage = async (data: FormData) => {
    return axiosClient.post<string>('/sending-technology/push/content/image', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const GetPushConfigurationIconUrlAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<string>(GetPushConfigurationIconUrl, { signal });
};

export const SendWebPushNotificationTestAsync = async (
    pushSubscription: PushSubscriptionJSON,
    pushNotificationContent: PushNotificationContent
) => {
    return axiosClient.post('/sending-technology/push/subscription/test', {
        pushSubscription,
        pushNotificationContent
    });
};
