import { MouseEventHandler, useState, useMemo } from 'react';
import { Text, SelectFormGroup } from '@components';
import { useTranslator } from '@hooks';
import { GetTranslatedEnumName, GetNumericValuesFromEnum } from '@utils';
import RemoveIcon from '~/assets/svg/icons/icon__remove.svg';

export enum ImportContactFields {
    Name = 1,
    Email = 2,
    CellPhone = 3,
    InfoAdd1 = 4,
    InfoAdd2 = 5
}

export const FieldItem = ({ fieldName, formRef }) => {
    const [mappedFieldValue, setMappedFieldValue] = useState<string | number>();
    const {
        pages: {
            myAutomations: { importContactInfoModal }
        }
    } = useTranslator();

    const dropdownOptions = useMemo(
        () =>
            GetNumericValuesFromEnum(ImportContactFields).map((item) => {
                return {
                    id: item,
                    label: GetTranslatedEnumName(
                        ImportContactFields[item],
                        importContactInfoModal.list.mappedFieldDropdown.options
                    )
                };
            }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleClearMappedFieldDropdown: MouseEventHandler<SVGSVGElement> = (event) => {
        event.stopPropagation();
        formRef.current.clearField(fieldName);
        setMappedFieldValue(null);
    };

    return (
        <li>
            <Text as='span' size='md'>
                {fieldName}
            </Text>
            <SelectFormGroup
                name={fieldName}
                placeholder={String(importContactInfoModal.list.mappedFieldDropdown.placeholder)}
                options={dropdownOptions}
                icon={
                    mappedFieldValue && <RemoveIcon onClick={handleClearMappedFieldDropdown} className='remove-icon' />
                }
                onOptionChange={({ id }) => setMappedFieldValue(id)}
            />
        </li>
    );
};
