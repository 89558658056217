import { useMemo } from 'react';
import HTMLReactParser from 'html-react-parser';
import { Button, previewDefaultStyle } from '@components';
import { VariableParentKey, useTemplate, useTranslator } from '@hooks';
import { SendingTechnologyTypeEnum, SubToolIdEnum } from '@models';
import { useAuth } from '@providers';
import { EmailTemplatePreviewStyled, PreviewButton, PreviewStyled } from './styles';

type EmailTemplatePreviewProps = {
    subject?: string;
    baseHtml?: string;
    productHtml?: string;
    reserveProductHtml?: string;
    recommendationHTML?: string;
    reviewHtml?: string;
    variables?: Array<string>;
    subToolId: SubToolIdEnum;
    displayReserveProduct?: boolean;
    onChangeDisplayReserveProduct?: () => void;
};

const GetVariableParentKey = (subToolId?: SubToolIdEnum): VariableParentKey => {
    switch (subToolId) {
        case SubToolIdEnum.Repurchase:
            return 'repurchase';
        case SubToolIdEnum.NavigationTrigger:
            return 'navigationTrigger';
        case SubToolIdEnum.CartRecovery:
            return 'cartRecovery';
        case SubToolIdEnum.BankSlipReminder:
            return 'bankSlipReminder';
        case SubToolIdEnum.CustomTrigger:
            return 'customTrigger';
    }
};

export const EmailTemplatePreview = ({
    subToolId,
    baseHtml = '',
    productHtml = '',
    reserveProductHtml = '',
    reviewHtml = '',
    subject = '',
    recommendationHTML = '',
    variables = [],
    displayReserveProduct = null,
    onChangeDisplayReserveProduct,
    ...props
}: EmailTemplatePreviewProps) => {
    const { user } = useAuth();
    const { generateTemplatePreviewBySendingTechnology } = useTemplate(GetVariableParentKey(subToolId));
    const { emptyMessage, previewButton, displayReserveProductsButton, doNotDisplayProductsButton } =
        useTranslator().subTools.emailModelData;

    const content = useMemo(
        () =>
            generateTemplatePreviewBySendingTechnology({
                baseHtml,
                productHtml,
                reserveProductHtml,
                recommendationHTML,
                displayReserveProduct,
                reviewHtml,
                user,
                allowedVariables: variables,
                sendingTechnology: SendingTechnologyTypeEnum.Email
            }),
        [
            baseHtml,
            productHtml,
            reviewHtml,
            reserveProductHtml,
            recommendationHTML,
            user,
            generateTemplatePreviewBySendingTechnology,
            variables,
            displayReserveProduct
        ]
    );

    const handleViewInNewTab = () => {
        const windowElement = window.open('about:blank', '');
        windowElement.document.write(
            `<title>${subject}</title><style type='text/css'>${previewDefaultStyle}</style>`,
            content
        );
    };

    return (
        <EmailTemplatePreviewStyled {...props}>
            {content && (
                <PreviewButton>
                    <Button
                        type='button'
                        styledAs='link'
                        color='deepCerise'
                        onClick={handleViewInNewTab}
                        testId='view-in-new-tab-button'>
                        <u>{previewButton}</u>
                    </Button>
                    {displayReserveProduct !== null && (
                        <Button
                            type='button'
                            styledAs='link'
                            color='deepCerise'
                            onClick={onChangeDisplayReserveProduct}>
                            <u>
                                {displayReserveProduct
                                    ? String(doNotDisplayProductsButton)
                                    : String(displayReserveProductsButton)}
                            </u>
                        </Button>
                    )}
                </PreviewButton>
            )}

            <div>
                {/* NOTE: The styles of the styled-component do not work because an iframe is being used,
            to apply styles, the style element or css of the styled-component must be added to the head Children prop */}
                <PreviewStyled
                    data-testid='email-template-preview'
                    headChildren={<style type='text/css'>{HTMLReactParser(previewDefaultStyle)}</style>}>
                    {content ? HTMLReactParser(content) : <span className='empty-message'>{emptyMessage}</span>}
                </PreviewStyled>
            </div>
        </EmailTemplatePreviewStyled>
    );
};
