import { Button } from '@components';
import { useTranslator } from '@hooks';
import { SOCIAL_ADDRESS } from '@utils';
import { PageTitle } from '~/styles/defaultComponentStyles';
import { SubToolHelpContent } from './styles';

export const HelpSection = () => {
    const {
        buttons,
        pages: {
            helpAndTutorials: { help }
        }
    } = useTranslator();

    return (
        <SubToolHelpContent>
            {/* <Box as='ul'>
                {Object.keys(help.list).map((item) => (
                    <HelpItem key={item} {...help.list[item]} />
                ))}
            </Box> */}

            <div>
                <PageTitle>{String(help.didNotFindWhatYouNeeded)}</PageTitle>
                <a href={SOCIAL_ADDRESS.ZENDESK} target='_blank' rel='noopener noreferrer'>
                    <Button color='purple' buttonSize='small'>
                        {String(buttons.viewMore)}
                    </Button>
                </a>
            </div>
        </SubToolHelpContent>
    );
};
