import { MutableRefObject, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
    PhoneFramePreview,
    ModelsTemplateEditor,
    WhatsAppTemplatePreview,
    SelectFormGroup,
    SelectData
} from '@components';
import { VariableParentKey, useFetch, useTemplate, useTranslator } from '@hooks';
import { CustomizationVariable, SendingTechnologyTypeEnum, SubTool } from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles } from '@unform/core';
import { getOnlyNumbers, isDevelopment, MAX_LENGTH_TELEPHONE, MIN_LENGTH_TELEPHONE } from '@utils';
import { GetWhatsAppTemplatesAsync, GetWhatsAppTemplatesUrl, SendTestWhatsApp } from '~/services/subToolApiService';
import { InputGroupStyled, TestIsNotFreeSpanStyled, TestSendingContainer } from './styles';

type WhatsAppTemplateEditorProps = {
    subTool?: SubTool;
    formRef: MutableRefObject<FormHandles>;
    initialValue: string;
    inputName?: string;
    customizationVariables?: CustomizationVariable[];
    isDefaultTemplate?: boolean;
    subToolKeyIdentifier: VariableParentKey;
    isReduced?: boolean;
    onChangeEnableTechnology?: (checked: boolean, sendingTechnologyType: SendingTechnologyTypeEnum) => void;
    enableTechnology?: boolean;
    subToolColor?: string;
    subToolId: number;
};

const CELL_PHONE_FIELD_NAME = 'CellPhone';
const INPUT_NAME = 'whatsappMessage';

export const WhatsAppTemplateEditor = ({
    formRef,
    initialValue,
    inputName,
    customizationVariables = [],
    subToolKeyIdentifier,
    onChangeEnableTechnology,
    subToolColor,
    subToolId,
    enableTechnology = true,
    isReduced = false
}: WhatsAppTemplateEditorProps) => {
    const { error, success } = useAlert();
    const [selectedTemplateId, setSelectedTemplateId] = useState(initialValue ?? undefined);
    const { generateTemplatePreviewBySendingTechnology } = useTemplate(subToolKeyIdentifier);
    const { user } = useAuth();
    const testInputName = `${INPUT_NAME}${CELL_PHONE_FIELD_NAME}`;
    const sendingTechnology = SendingTechnologyTypeEnum.WhatsApp;

    const { data, isLoading } = useFetch(
        GetWhatsAppTemplatesUrl,
        async () => {
            const templates = await GetWhatsAppTemplatesAsync();
            return templates.data;
        },
        {
            keepPreviousData: true,
            fallbackData: []
        }
    );

    const formattedMessage = useMemo(() => {
        if (!selectedTemplateId) return '';

        const selectedTemplate = data.find((d) => d.id === selectedTemplateId);
        return generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: selectedTemplate?.structure?.body?.text ?? '',
            sendingTechnology: sendingTechnology,
            allowedVariables: []
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTemplateId, data]);

    const [isCheckSecurityModalOpen, setIsCheckSecurityModalOpen] = useState(false);

    const {
        dialogs: { errors, successes },
        subTools: {
            modelsTemplateEditor: { errorMessages, sendTestMessageButtonText, describedInputPlaceholder },
            whatsAppTemplateEditor: { title, subtitle, noTemplate, testIsNotFree }
        }
    } = useTranslator();

    const whatsAppSelectOptions: SelectData[] = useMemo(() => {
        return data.map((d) => ({
            id: d.id,
            label: d.name
        }));
    }, [data]);

    const closeCheckSecurityModal = async (recaptcha?: string) => {
        try {
            setIsCheckSecurityModalOpen(false);
            if (recaptcha) {
                const payload = {
                    to: getOnlyNumbers(formRef.current?.getFieldValue(inputName)),
                    text: formattedMessage,
                    recaptchaToken: recaptcha
                };

                // TODO: Revisar por que não ta sendo utilizado
                console.log(payload);
            }
        } catch (exception) {
            captureException(exception);
        }
    };

    const handleClick = async () => {
        try {
            const schema = Yup.object().shape({
                [INPUT_NAME]: Yup.string().required(errorMessages.emptySms).nullable(),

                [testInputName]: Yup.string()
                    .required(errorMessages.senderCellPhone.required)
                    .nullable()
                    .min(MIN_LENGTH_TELEPHONE, errorMessages.senderCellPhone.minLength)
                    .max(MAX_LENGTH_TELEPHONE, errorMessages.senderCellPhone.maxLength)
            });

            formRef.current.setFieldError(INPUT_NAME, null);
            formRef.current.setFieldError(testInputName, null);

            const message = formRef.current.getFieldValue(INPUT_NAME);
            const cellPhoneInputValue = formRef.current.getFieldValue(testInputName);
            await schema.validate(
                { [INPUT_NAME]: message, [testInputName]: cellPhoneInputValue },
                { abortEarly: false }
            );

            const tagsList = ['utm_source', 'utm_medium', 'utm_campaign'];
            const tagAnalytics = tagsList.reduce((accumulator, tag) => {
                accumulator[tag] = formRef.current.getFieldValue(`trackAnalytics[${tag}]`);
                return accumulator;
            }, {});

            await SendTestWhatsApp({ templateId: selectedTemplateId, to: cellPhoneInputValue, subToolId, tagAnalytics })
                .then((response) => {
                    if (!response?.data?.success) {
                        error(String(errors.failedToSendTestSMS.title), String(errors.failedToSendTestSMS.description));
                    } else {
                        success(String(successes.successfullySendingTestWhatsapp));
                    }
                })
                .catch((exception) => {
                    error(
                        String(errors.failedToSendTestWhatsapp.title),
                        String(errors.failedToSendTestWhatsapp.description)
                    );
                    captureException(exception);
                });
        } catch (exception) {
            if (exception instanceof Yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));

                const currentErrors = formRef.current.getErrors();
                formRef.current.setErrors({ ...currentErrors, ...errorMessages });

                isDevelopment() && console.log(errorMessages);
            } else {
                captureException(exception);
                error(
                    String(errors.failedToSendTestWhatsapp.title),
                    String(errors.failedToSendTestWhatsapp.description)
                );
            }
        }
    };

    const onChangeEnabled = (checked: boolean) => {
        onChangeEnableTechnology(checked, sendingTechnology);
    };

    return (
        <>
            <ModelsTemplateEditor
                key={`${INPUT_NAME}${CELL_PHONE_FIELD_NAME}EDITOR`}
                customizationVariables={customizationVariables}
                hasCustomizationVariables={false}
                subToolKeyIdentifier={subToolKeyIdentifier}
                subToolColor={subToolColor}
                subtitle={String(subtitle)}
                title={String(title)}
                closeCheckSecurityModal={closeCheckSecurityModal}
                onChangeEnableTechnology={onChangeEnabled}
                enableTechnology={enableTechnology}
                isCheckSecurityModalOpen={isCheckSecurityModalOpen}
                sendingTechnology={sendingTechnology}
                formComponents={
                    <SelectFormGroup
                        name={inputName ?? INPUT_NAME}
                        options={whatsAppSelectOptions}
                        disabled={isLoading}
                        placeholder={String(noTemplate)}
                        defaultValue={initialValue ?? undefined}
                        onOptionChange={({ id }) => {
                            if (typeof id === 'number') {
                                id = String(id);
                            }

                            setSelectedTemplateId(id);
                        }}
                    />
                }
                inputTestComponent={
                    <TestSendingContainer>
                        <InputGroupStyled
                            inputType='tel'
                            inputMask='phone'
                            inputName={testInputName}
                            buttonText={String(sendTestMessageButtonText)}
                            inputPlaceHolder={String(describedInputPlaceholder) + '*'}
                            onClick={handleClick}
                        />
                        <TestIsNotFreeSpanStyled>*{String(testIsNotFree)}</TestIsNotFreeSpanStyled>
                    </TestSendingContainer>
                }
                modelPreview={
                    <PhoneFramePreview isReduced={isReduced}>
                        <WhatsAppTemplatePreview message={formattedMessage} />
                    </PhoneFramePreview>
                }
            />
        </>
    );
};
