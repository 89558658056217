import styled, { css } from 'styled-components';
import { Form } from '@unform/web';
import { CardHeader } from '~/components/elements/card';
import { mobileAndTabletOnly, mobileOnly } from '~/styles';

export const AutomationStatisticsCustomerHeader = styled(CardHeader)``;

export const SearchFormStyled = styled(Form)`
    display: flex;
    gap: 1.6rem;
    justify-content: flex-end;
    flex-wrap: wrap;

    label {
        margin: 0;
        font-weight: normal;
        font-family: ${({ theme }) => theme.fonts.primary};
        font-size: ${({ theme }) => theme.fontSizes.xs};
        white-space: nowrap;
    }

    @media screen and (min-width: 1336px) {
        & > div:first-child {
            & > div:first-child {
                max-width: 14rem;
                transition: all 0.1s ease-out;
            }
        }

        .has-focus {
            position: relative;
            & > div:first-child {
                position: absolute;
                left: 0;
                top: 0;
                max-width: 100% !important;
            }

            & > div:last-child {
                opacity: 0;
            }
        }
    }
`;

export const SearchInputContent = styled.div`
    flex: 1 1 34.583%;

    ${mobileAndTabletOnly(css`
        align-self: end;
    `)}

    display: flex;
    gap: 1.6rem;

    & > div {
        padding: 0;
        width: 100%;

        &:first-child {
            min-width: 14rem;
            align-self: end;
            z-index: 2;

            i {
                width: 3.2rem;
                height: 3.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 0;
                margin: 0;
                margin-left: 0.4rem;
                transform: rotate(90deg);
                color: ${({ theme }) => theme.colors.philippineSilver};
                cursor: pointer;
            }

            input {
                padding-left: 0.4rem;
            }
        }

        &:last-child > div {
            flex-direction: row;
            gap: 0.8rem;
            align-items: center;
            flex: 1 1 0;

            & > div {
                min-width: 16rem;

                & > div {
                    width: 18.4rem;
                    right: 0;
                }
            }

            ${mobileAndTabletOnly(css`
                align-items: flex-start;
                flex-direction: column;
                align-self: baseline;
                height: 100%;

                & > div > div {
                    left: 0;
                }
            `)}
        }
    }

    ${mobileOnly(css`
        flex-direction: column;

        div {
            width: 100%;
        }
    `)}
`;

export const FilterContent = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1.6rem;
    padding: 0;
    flex-wrap: wrap;
    flex: 1 1 64%;

    & > div {
        padding: 0;
        width: fit-content;
        flex-direction: row;
        gap: 0.8rem;
        align-items: center;
        flex: 1 1 0;

        &:nth-child(1) > div {
            min-width: 26.5rem;

            & > div {
                width: 28.8rem;
                right: 0;
            }
        }

        &:nth-child(2) > div {
            min-width: 18rem;

            & > div {
                width: 21.8rem;
                right: 0;
            }
        }

        ${mobileAndTabletOnly(css`
            width: 100%;
            align-items: flex-start;
            flex-direction: column;

            & > div > div {
                left: 0;
            }
        `)}
    }
`;
