import axiosClient from './apiService';

export const CreateBraintreeClientInstance = async (authorization: string) => {
    const braintree = window.braintree;

    return await braintree.client
        .create({
            authorization
        })
        .then((clientInstance) => {
            return braintree.paypalCheckout.create({
                client: clientInstance
            });
        });
};

export const GetBraintreeAuthorization = async () => {
    return await axiosClient.get<string>(`payment/generate-client-token`);
};
