import styled from 'styled-components';
import { CardBody } from '~/components/elements/card';

export const CVVLabelStyled = styled.div`
    & + div,
    div {
        height: 1.8rem;
    }
`;

export const CardBodyStyled = styled(CardBody)`
    & > button {
        align-self: flex-start;
        margin-top: 2.4rem;
        padding: 0;
        font-size: ${({ theme }) => theme.fontSizes.xs};
        height: 0;
    }
`;
