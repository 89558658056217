import styled from 'styled-components';
import { ExpandableGroup } from '~/components/elements/expandableGroup';

export const SubToolHelpContent = styled.section`
    margin-top: 3.2rem;
    margin-bottom: 10rem;

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    & > div {
        margin-top: 4.8rem;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        align-items: center;
        justify-content: center;

        h1 {
            text-align: center;
        }

        button {
            min-width: 16.4rem;
        }
    }
`;

export const SubToolHelpItemStyled = styled.li`
    overflow: hidden;

    & + li:not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
    }
`;

export const SubToolHelpItemHeaderStyled = styled.div`
    padding: 2.4rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    justify-content: space-between;
    cursor: pointer;

    i {
        width: 2.4rem;
        height: 2.4rem;
        padding: 0.4rem;

        svg {
            height: 100%;
            width: 100%;
        }
    }
`;

export const SubToolHelpItemContentStyled = styled(ExpandableGroup)`
    & > div {
        padding: 0 2.4rem 2.4rem;
    }
`;
