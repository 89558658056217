import { useCallback, useEffect } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { Button, Card, CardHeader, Field } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { useAddress, useCheckout, useBillingData } from '@providers';
import { ApplyMask, InputMaskEnum, VALUE_TEN } from '@utils';
import { CurrentSubscriptionCardBodyStyled, FieldStyled, RowStyled } from '~/components/containers/checkout/styles';
import { GetStatesOfBrazil, STATES_OF_BRAZIL_URL } from '~/services/brasilApiService';
import { EditCardBody } from '../../billingResponsible/styles';

export const CurrentBillingData = () => {
    const { subscription, setSubscription } = useCheckout();
    const { subscriptionData } = useTranslator().checkoutPage;
    const { actions } = subscriptionData;
    const { title, form } = subscriptionData.billingData;
    const { address, name, documentNumber } = subscription.customer;
    const { setIsUpdatingBillingData } = useBillingData();
    const { setStates, states } = useAddress();

    useFetch(STATES_OF_BRAZIL_URL, async () => (await GetStatesOfBrazil()).data, {
        onSuccess: (data) => setStates(data),
        dedupingInterval: minutesToMilliseconds(VALUE_TEN)
    });

    const getStateByCode = useCallback(
        (stateCode: string) => states?.find((state) => state.code === stateCode),
        [states]
    );

    useEffect(() => {
        if (states?.length && address.state && !address.state?.name) {
            address.state = getStateByCode(address.state?.code);
            setSubscription({ ...subscription, customer: { ...subscription.customer, address: address } });
        }
    }, [address, states, subscription, getStateByCode, setSubscription]);

    return (
        <Card type='group'>
            <CardHeader
                title={String(title)}
                actions={
                    <Button
                        buttonSize='small'
                        color='purple'
                        isOutlined
                        testId='button__billing-data'
                        onClick={() => setIsUpdatingBillingData(true)}>
                        {actions.edit}
                    </Button>
                }
            />
            <EditCardBody type='group'>
                <Card>
                    <CurrentSubscriptionCardBodyStyled>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 12 }}>
                                <Field name={String(form.name.label)} value={name} />
                            </FieldStyled>
                        </RowStyled>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field
                                    name={String(form.taxpayerRegistry.label)}
                                    value={ApplyMask(documentNumber, InputMaskEnum.taxpayerRegistry)}
                                />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field
                                    name={String(form.zipCode.label)}
                                    value={ApplyMask(address.zipCode, InputMaskEnum.zipCode)}
                                />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(form.street.label)} value={address.street} />
                            </FieldStyled>
                        </RowStyled>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(form.number.label)} value={address.number} />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(form.complement.label)} value={address.complement} />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(form.neighborhood.label)} value={address.neighborhood} />
                            </FieldStyled>
                        </RowStyled>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(form.country.label)} value={address.country?.name} />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(form.state.label)} value={address.state?.name} />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(form.city.label)} value={address.city} />
                            </FieldStyled>
                        </RowStyled>
                    </CurrentSubscriptionCardBodyStyled>
                </Card>
            </EditCardBody>
        </Card>
    );
};
