import styled, { keyframes } from 'styled-components';
import { AlertColors } from '~/styles/theme';
import { DialogContent } from '../elements/dialog/content';
import { ConfirmDialog } from './confirmDialog';

type ProgressBarAlertStyledProps = {
    autoCloseDelay: number;
    alertColor: AlertColors;
};

type AlertDialogContentStyledProps = {
    alertColor: AlertColors;
};

export const ConfirmDeletionDialogStyled = styled(ConfirmDialog)`
    h2 {
        max-width: 32.8rem;
        margin: 2.4rem 4rem;
        color: ${({ theme }) => theme.colors.deepCerise};
    }
`;

export const ConfirmCancelationDialogStyled = styled(ConfirmDialog)`
    max-width: 48.8rem;

    h2 {
        margin: 2.4rem 4rem;
    }
`;

export const InfoDialogContentStyled = styled(DialogContent)`
    padding: 5rem 8rem;
`;

export const AlertDialogContentStyled = styled(DialogContent)<AlertDialogContentStyledProps>`
    padding: 0;
    flex-direction: column;
    overflow: hidden;
    margin-top: 5.6rem;

    & > div:first-child {
        padding: 2.4rem;
        max-width: 48.8rem;
        header {
            display: flex;
            gap: 0.4rem;
            flex-direction: column;

            div {
                display: flex;
                gap: 1.6rem;
                align-items: center;

                i {
                    width: 4rem;
                    height: 4rem;
                    align-self: baseline;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: 3.2rem;
                        height: 3.2rem;
                        color: ${({ theme, alertColor }) => theme.alertColors[alertColor]};
                    }
                }

                h2 {
                    color: ${({ theme, alertColor }) => theme.alertColors[alertColor]};
                    text-align: start;
                }
            }

            &:has(i) {
                p {
                    margin-left: 5.6rem;
                }
            }

            p {
                text-align: start;
            }
        }
    }
`;

const fill = keyframes`
  0% { width: 100% }
  100% { width: 0% } 
`;

export const ProgressBarAlertStyled = styled.div<ProgressBarAlertStyledProps>`
    width: 100%;
    height: 0.8rem;
    border-radius: 1rem;
    overflow: hidden;

    div {
        width: 0;
        background: ${({ theme, alertColor }) => theme.alertColors[alertColor]};
        height: 100%;
        border-radius: 1rem;
        animation: ${fill} ${({ autoCloseDelay }) => `${autoCloseDelay}s`} linear;
    }
`;
