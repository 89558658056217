import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import { isDevelopment } from '@utils';

type OnSaveOptions<T> = {
    updateLiveData?: (newData?: T) => void;
};

export type AutosaveStatus = 'submitting' | 'success' | 'failed';

export type OnSaveResult<T = unknown> = {
    status: AutosaveStatus;
    data?: T;
    error?: Error;
};

export type HandleSaveLiveData<T = unknown, U = unknown> = (
    data: T,
    options?: OnSaveOptions<T>
) => Promise<OnSaveResult<U>> | OnSaveResult<U>;

export type UseAutosaveProps<T = unknown, U = unknown> = {
    initialData?: T;
    interval?: number;
    onSave: HandleSaveLiveData<T, U>;
};

export type UseAutosaveData<T = unknown> = {
    currentData: T;
    savingData: T;
    saveData: (newData: T) => void;
    isSubmitting: boolean;
    status: AutosaveStatus;
    error?: Error;
};

export const useAutosave = <T, U>({
    initialData,
    onSave,
    interval = 2000
}: UseAutosaveProps<T, U>): UseAutosaveData<T> => {
    const [data, setData] = useState<T>(initialData);
    const [savingData, setSavingData] = useState<T>(initialData);
    const [status, setStatus] = useState<AutosaveStatus>();
    const [error, setError] = useState<Error>();

    const saveData = useCallback((newData: T) => {
        setData(newData);
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSave = useCallback(
        debounce(async (newData: T) => {
            isDevelopment() && console.log('autosave: updating live data', newData);
            setStatus('submitting');
            setSavingData(newData);
            saveData(null);

            const result = await onSave(newData, { updateLiveData: saveData });

            if (result.status === 'success') {
                setSavingData(null);
                setStatus('success');
                setError(null);
            } else {
                setStatus('failed');
                setError(result.error);
            }

            isDevelopment() && console.log(`autosave: ${result.status}`, result.error ? result.error : '');
        }, interval),
        [onSave, saveData, interval]
    );

    useEffect(() => {
        if (status === 'submitting' || !data || (isArray(data) && data.length === 0)) {
            return;
        }

        debouncedSave(data);
    }, [debouncedSave, data, status]);

    return { currentData: data, saveData, savingData, isSubmitting: status === 'submitting', status, error };
};
