import { PaginatedList, PaymentMethodTypeEnum, SubscriptionInvoice, SubscriptionInvoiceStatusEnum } from '@models';

export const MAX_PAGINATED_PAGE_SIZE = 10;

export const fallbackData = {
    items: Array.from({ length: MAX_PAGINATED_PAGE_SIZE }).map((_, index) => {
        return {
            id: index,
            subscription: {
                id: 123456,
                paymentMethod: {
                    id: 1,
                    name: '',
                    discountPercentage: 0,
                    type: PaymentMethodTypeEnum.CreditCard
                }
            },
            dueDate: new Date('2023-08-17T16:29:19.075Z'),
            status: SubscriptionInvoiceStatusEnum.Waiting,
            value: 0
        };
    }),
    pageNumber: 1,
    pageSize: 10,
    totalItems: 10
} as PaginatedList<SubscriptionInvoice>;
