import { EmailConfiguration, VerificationStatus } from '@models';
import axiosClient from './apiService';

export const GetConfiguration = '/sending-technology/email/configuration';

export const GetEmailConfigurationAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<EmailConfiguration>(GetConfiguration, { signal });
};

export const SaveConfigurationAsync = async (domain: string, signal: AbortSignal = null) => {
    return axiosClient.post<EmailConfiguration>(GetConfiguration, { domain }, { signal });
};

export const VerifyDomainAppointmentAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<{ verificationStatus: VerificationStatus }>(
        `${GetConfiguration}/verify-domain-appointment`,
        {
            signal
        }
    );
};
