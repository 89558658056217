import { useAutomationStatisticsProvider } from '@providers';
import { Shimmer } from '~/components/elements';
import { SumaryContainer } from './styles';
import { Summary } from './summaryItem';

export const AutomationStatisticsSummary = () => {
    const { isLoading, data } = useAutomationStatisticsProvider((state) => state.generalStatistics);

    return (
        <Shimmer isActive={isLoading}>
            <SumaryContainer>
                {data.toolStatistics
                    .filter((statistic) => statistic.isVisibleInSummary)
                    .map((automationStatistic) => (
                        <Summary key={automationStatistic.type} toolStatistic={automationStatistic} data-shimmer />
                    ))}
            </SumaryContainer>
        </Shimmer>
    );
};
