import styled from 'styled-components';
import { Text } from '../text';

export const BreadcrumbsStyled = styled.nav`
    display: flex;
    align-items: center;

    ol {
        color: ${(props) => props.theme.colors.lightBlack};
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
    }
`;

export const CrumbStyled = styled(Text).attrs({ as: 'li', size: 'md' })`
    display: flex;
    align-items: center;
    height: 100%;

    a {
        color: ${(props) => props.theme.colors.lightBlack};

        &:hover {
            color: ${(props) => props.theme.colors.purple};
            text-decoration: underline;
        }
    }

    span {
        margin: 0 0.4rem;
    }

    &[aria-current='page'] {
        color: ${(props) => props.theme.colors.purple};
        cursor: default;
    }
`;
