import _first from 'lodash/first';
import { Button, Text } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { SubscriptionTool } from '@models';
import { useCheckout } from '@providers';
import { DEFAULT_CURRENCY } from '@utils';
import { GetSendingTechnologyByName } from '~/services/subscriptionService';
import { InvoiceDetailsListStyled } from '../styles';

type InvoiceDetailsListProps = {
    subscriptionTool?: SubscriptionTool;
    showUpdatePlanButton?: boolean;
};

export const InvoiceDetailsList = ({ subscriptionTool, showUpdatePlanButton = false }: InvoiceDetailsListProps) => {
    const { subscriptionPlan, invoiceDetails } = useTranslator()?.checkoutPage;
    const { subgroups } = invoiceDetails;
    const { common } = subscriptionPlan?.automationPlans;

    const { formatCurrency } = useNumberFunctions();
    const { goToFirstStep } = useCheckout();

    const currency = _first(subscriptionTool?.sendingTechnologies)?.currency ?? DEFAULT_CURRENCY;

    return (
        <InvoiceDetailsListStyled>
            {(subscriptionTool?.sendingTechnologies || [])?.map((tool) => (
                <li key={tool.sendingTechnology.id}>
                    <Text as='span' size='xs'>
                        {GetSendingTechnologyByName(tool.sendingTechnology.type, common?.sendingTechnology)}
                    </Text>
                    <Text as='span' size='xs'>
                        {tool.price ? formatCurrency(tool.price, currency) : subgroups.total.free}
                    </Text>
                </li>
            ))}
            <li>
                <Text as='strong' size='md'>
                    {subgroups.automationPlans.total}
                </Text>
                <Text as='strong' size='md'>
                    {formatCurrency(subscriptionTool?.price ?? 0, currency)}
                </Text>
            </li>
            {showUpdatePlanButton && (
                <Button color='purple' isOutlined type='button' onClick={goToFirstStep}>
                    {subgroups.changePlan}
                </Button>
            )}
        </InvoiceDetailsListStyled>
    );
};
