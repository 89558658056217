import { HTMLAttributes } from 'react';
import styled from 'styled-components';

export type BarChartItemStyledProps = HTMLAttributes<HTMLDivElement> & {
    color: string;
    formattedPercentage?: string;
};

export const BarChartItemStyled = styled.span<BarChartItemStyledProps>`
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    width: ${({ formattedPercentage = '0%' }) => formattedPercentage};
    background-color: ${({ color }) => color};
    display: inline-block;
    transition: width 0.15s ease;
`;
