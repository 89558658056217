import { SendingTechnology } from './sendingTechnology';
import { SubscriptionStatusEnum } from './subscription';
import { SubToolIdEnum } from './subTool';

export enum ToolTypeEnum {
    AutomationTool = 1,
    BulkSending = 2
}

export type Tool = {
    id: number;
    name: string;
    type: ToolTypeEnum;
    sendingTechnologies: Array<SendingTechnology>;
};

export type ToolStatistics = {
    futureScheduledCount: number;
    scheduledCount: number;
    sentCount: number;
    openingCount: number;
    clickCount: number;
    conversionCount: number;
    totalConversionValue: number;
};

export type ToolUsageSummary = ToolStatistics & {
    isActive: boolean;
    subscriptionStatus: SubscriptionStatusEnum;
    returnOnInvestment?: number;
    searchPeriodDays: number;
    sentOverScheduledRatio: number;
    openingsOverSentRatio: number;
    clicksOverOpeningsRatio: number;
    salesOverClicksRatio: number;
};

export type AutomationToolUsageSummary = {
    subToolId: SubToolIdEnum;
    isActive: boolean;
    hasTemplates: boolean;
    conversionCount: number;
    totalConversionValue: number;
};
