import { useTranslatedRoute, useTranslator, useRedirectToPainel2, useReplace } from '@hooks';
import { useAuth, useAlert } from '@providers';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import LogoutIcon from '~/assets/svg/icons/icon__logout.svg';
import MySubscriptionIcon from '~/assets/svg/icons/icon__my-subscription.svg';
import PuzzleIcon from '~/assets/svg/icons/icon__puzzle.svg';
import StoreIcon from '~/assets/svg/icons/icon__store.svg';
import UserIcon from '~/assets/svg/icons/icon__user.svg';
import UsersIcon from '~/assets/svg/icons/icon__users.svg';
import { routesName } from '~/locales/route';
import { SendingTechnologySettingModal } from '../sendingTechnologySettingsModal';
import { MenuItem } from './menu';
import { IconStyled, UserMenuHeaderStyled } from './styles';
import { UserMenu } from './userMenu';

export const UserSection = () => {
    const { header } = useTranslator();
    const translateRoute = useTranslatedRoute();
    const { user, SignOut } = useAuth();
    const { show } = useAlert();
    const { redirect } = useRedirectToPainel2();
    const replace = useReplace();

    const routes = [
        {
            title: String(header.userPopup.myData),
            href: redirect(routesName.myData, false),
            icon: (
                <IconStyled>
                    <UserIcon />
                </IconStyled>
            )
        },
        {
            title: String(header.mySubscription),
            href: routesName.mySubscription.base,
            as: translateRoute(routesName.mySubscription.base),
            icon: (
                <IconStyled>
                    <MySubscriptionIcon />
                </IconStyled>
            )
        },
        {
            title: String(header.userPopup.storeData),
            href: redirect(routesName.storeDetails, false),
            icon: (
                <IconStyled>
                    <StoreIcon />
                </IconStyled>
            )
        },
        {
            title: String(header.userPopup.integrations),
            href: redirect(
                replace(
                    routesName.integrations,
                    { platformId: user?.platform?.integration || '' },
                    { start: '{', end: '}' }
                ),
                false
            ),
            icon: (
                <IconStyled>
                    <PuzzleIcon />
                </IconStyled>
            )
        },
        {
            title: (
                <span>
                    Configurações de <br /> Tecnologias de Envio
                </span>
            ),
            onClick: () => show(null, null, { content: SendingTechnologySettingModal }),
            icon: (
                <IconStyled>
                    <UsersIcon />
                </IconStyled>
            )
        },
        {
            title: String(header.userPopup.partners),
            href: redirect(routesName.partners, false),
            icon: (
                <IconStyled>
                    <UsersIcon />
                </IconStyled>
            )
        },
        {
            title: String(header.helpAndTutorials),
            href: routesName.helpAndTutorials,
            as: translateRoute(routesName.helpAndTutorials),
            icon: (
                <IconStyled>
                    <HelpIcon height='2rem' />
                </IconStyled>
            )
        },
        {
            title: String(header.userPopup.signOut),
            onClick: () => SignOut(),
            icon: (
                <IconStyled>
                    <LogoutIcon />
                </IconStyled>
            )
        }
    ];

    return (
        <UserMenu label={String(header.myAccount)} allowHover={false} user={user}>
            {user && (
                <>
                    <UserMenuHeaderStyled renderElementAnchor={false} tabIndex={undefined}>
                        <div>
                            <div>
                                <strong>{user.name}</strong>
                                <span>{user.email}</span>
                            </div>
                        </div>
                    </UserMenuHeaderStyled>
                    <MenuItem role='separator' renderElementAnchor={false} />
                </>
            )}
            {routes.map((route, index) => (
                <MenuItem
                    key={index}
                    label={route.title}
                    icon={route.icon}
                    onClick={route?.onClick}
                    renderElementAnchor={!!route?.href}
                    link={{ href: route?.href, as: route?.as }}
                />
            ))}
        </UserMenu>
    );
};
