import { CardFlag } from '@models';
import * as CreditCardBrands from '~/assets/svg/credit-card-brand';
import { CardFlagsStyled, CardFlagsStyledProps } from './styled';

type CardFlagsProps = CardFlagsStyledProps & {
    cardFlags: Array<CardFlag>;
};

export const CardFlags = ({ cardFlags = [], ...props }: CardFlagsProps) => {
    return (
        <CardFlagsStyled {...props}>
            {Object.entries(CreditCardBrands)
                ?.filter(([name]) => cardFlags?.some((cardFlag) => cardFlag.name === name))
                ?.map(([name, BrandSvg]) => (
                    <li key={name}>
                        <BrandSvg title={name.replace(/([a-z])([A-Z])/, '$1 $2')} />
                    </li>
                ))}
        </CardFlagsStyled>
    );
};
