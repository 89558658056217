import * as yup from 'yup';
import { useTranslator } from '@hooks';
import { SubToolIdEnum, SubToolTemplateConfigurationTypeEnum, TrackAnalytics } from '@models';
import { MAX_SMS_MESSAGE_SIZE, MAX_SUBJECT_SIZE } from '@utils';
import { ActionViewType } from '.';

export type EmailEditorFormData = {
    subject: string;
    baseHTML: string;
    productHTML?: string;
    reserveProductHTML?: string;
    recommendationHTML?: string;
    reviewHTML?: string;
};

export type PushEditorActionListFormData = {
    title: string;
    action: string;
    icon?: string;
    redirectUrl?: string;
};

export type PushEditorFormData = {
    title: string;
    icon?: string;
    body: string;
    redirectUrl?: string;
    image?: string;
    oneButton?: {
        text: string;
        link: string;
    };
    twoButtons?: {
        left: {
            text: string;
            link: string;
        };
        right: {
            text: string;
            link: string;
        };
    };
    actionViewOn: ActionViewType;
};

export type TemplateConfigurationFormData = {
    stepName?: string;
    customTriggerName?: string;
    smsMessage: string;
    smsEnabled?: boolean;
    whatsappEnabled?: boolean;
    whatsappMessage: string;
    sendingOrder?: number;
    sendingPeriod: number | string;
    type: number;
    identifier?: string;
    pushEnabled?: boolean;
    pushNotification: PushEditorFormData;
    templateEmailEditor: EmailEditorFormData;
    templateEmailEditorEnabled?: boolean;
    trackAnalytics: TrackAnalytics;
    baseThemeId?: number;
};

const MIN_SIZE_SMS_TEMPLATE = 1;

const MIN_DAYS_SHIPPING_PERIOD = 1;
const MAX_DAYS_SHIPPING_PERIOD = 365;

// TODO: iury.sousa - Centralize the main validations in function and use all sub tools templates
// Remove duplicity of code
export const useValidation = () => {
    const translator = useTranslator();

    const { sendingPeriod, smsMessage, whatsappMessage, subject, baseHTML, stepName, customTriggerName } =
        translator.subTools.subToolTemplateConfiguration.form;

    // NOTE: Keep the order of validations according to the structure and positions of the inputs on the page.
    // In this way, when generating an error, the screen focus will be redirected to the input in the correct order
    const subToolTemplateConfigurationSchema: yup.SchemaOf<TemplateConfigurationFormData> = yup.object().shape({
        stepName: yup.string().required(String(stepName.required)).nullable(),
        customTriggerName: yup
            .string()
            .trim()
            .when('$subToolId', {
                is: (subToolId: SubToolIdEnum) => subToolId === SubToolIdEnum.CustomTrigger,
                then: (schema) => schema.required(String(customTriggerName.required))
            })
            .nullable(),
        sendingOrder: yup.number().optional(),
        baseThemeId: yup.number().optional().nullable(),
        type: yup.number().optional().nullable(),

        templateEmailEditorEnabled: yup.boolean().optional().nullable(),
        smsEnabled: yup.boolean().optional().nullable(),
        whatsappEnabled: yup.boolean().optional().nullable(),
        pushEnabled: yup.boolean().optional().nullable(),

        identifier: yup.string().when('type', {
            is: (type: number) => type === SubToolTemplateConfigurationTypeEnum.Category,
            then: (schema) => schema.required().nullable(),
            otherwise: (schema) => schema.optional()
        }),
        sendingPeriod: yup
            .number()
            .typeError(String(sendingPeriod.required))
            .required(String(sendingPeriod.required))
            .min(MIN_DAYS_SHIPPING_PERIOD, String(sendingPeriod.min))
            .max(MAX_DAYS_SHIPPING_PERIOD, String(sendingPeriod.max))
            .nullable(),
        smsMessage: yup
            .string()
            .trim()
            .when('smsEnabled', {
                is: (smsEnabled) => Boolean(smsEnabled),
                then: (schema) =>
                    schema
                        .required(String(smsMessage.required))
                        .min(MIN_SIZE_SMS_TEMPLATE, String(smsMessage.min))
                        .max(MAX_SMS_MESSAGE_SIZE, String(smsMessage.max))
            })
            .nullable(),
        whatsappMessage: yup
            .string()
            .trim()
            .when('whatsappEnabled', {
                is: (whatsappEnabled) => Boolean(whatsappEnabled),
                then: (schema) => schema.required(String(whatsappMessage.required))
            })
            .nullable(),
        pushNotification: yup
            .object()
            .shape({
                title: yup
                    .string()
                    .trim()
                    .when('$pushEnabled', {
                        is: (pushEnabled) => Boolean(pushEnabled),
                        then: (schema) =>
                            schema
                                .required(String(subject.required))
                                .min(MIN_SIZE_SMS_TEMPLATE, String(smsMessage.min))
                                .max(MAX_SMS_MESSAGE_SIZE, String(smsMessage.max))
                    })
                    .nullable(),
                body: yup
                    .string()
                    .trim()
                    .when('$pushEnabled', {
                        is: (pushEnabled: unknown) => Boolean(pushEnabled),
                        then: (schema) =>
                            schema
                                .required(String(subject.required))
                                .min(MIN_SIZE_SMS_TEMPLATE, String(smsMessage.min))
                                .max(MAX_SMS_MESSAGE_SIZE, String(smsMessage.max))
                    })
                    .nullable(),
                image: yup.string().optional(),
                actionViewOn: yup.string().optional().nullable(),
                redirectUrl: yup
                    .string()
                    .optional()
                    .nullable()
                    .when(['actionViewOn', '$pushEnabled'], {
                        is: (actionViewOn: string, pushEnabled: unknown) =>
                            Boolean(pushEnabled) && actionViewOn === 'click',
                        then: (schema) => schema.required()
                    }),
                oneButton: yup
                    .object()
                    .when(['actionViewOn', '$pushEnabled'], {
                        is: (actionViewOn: string, pushEnabled: unknown) =>
                            Boolean(pushEnabled) && actionViewOn === 'pushOneButton',
                        then: (schema) =>
                            schema.shape({
                                text: yup.string().trim().nullable().required(),
                                link: yup.string().trim().nullable().required()
                            })
                    })
                    .nullable(),
                twoButtons: yup
                    .object()
                    .when(['actionViewOn', '$pushEnabled'], {
                        is: (actionViewOn: string, pushEnabled: unknown) =>
                            Boolean(pushEnabled) && actionViewOn === 'pushTwoButtons',
                        then: (schema) =>
                            schema.shape({
                                left: yup.object().shape({
                                    text: yup.string().trim().nullable().required(),
                                    link: yup.string().trim().nullable().required()
                                }),
                                right: yup.object().shape({
                                    text: yup.string().trim().nullable().required(),
                                    link: yup.string().trim().nullable().required()
                                })
                            })
                    })
                    .nullable(),
                icon: yup.string().optional().nullable()
            })
            .default({})
            .nullable()
            .optional(),
        templateEmailEditor: yup
            .object()
            .shape({
                subject: yup
                    .string()
                    .trim()
                    .when(['$templateEmailEditor.enabled'], {
                        is: (templateEmailEditorEnabled) => Boolean(templateEmailEditorEnabled),
                        then: (schema) => schema.required(String(subject.required)).max(MAX_SUBJECT_SIZE, subject.max)
                    })

                    .nullable(),
                baseHTML: yup
                    .string()
                    .when('$templateEmailEditor.enabled', {
                        is: (templateEmailEditorEnabled) => Boolean(templateEmailEditorEnabled),
                        then: (schema) => schema.required(String(baseHTML.required))
                    })
                    .nullable(),
                productHTML: yup.string().optional(),
                reserveProductHTML: yup.string().optional(),
                recommendationHTML: yup.string().optional(),
                reviewHTML: yup.string().optional()
            })
            .optional(),
        trackAnalytics: yup.object().shape({}).optional()
    });

    return { subToolTemplateConfigurationSchema };
};
