import styled from 'styled-components';

export const CreditCardNumberStyled = styled.span`
    display: flex;
    width: 100%;
    gap: 1rem;
    flex-wrap: wrap;

    svg {
        width: 2.8rem;
    }
`;
