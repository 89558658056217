import { useCallback, useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import _first from 'lodash/first';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import * as yup from 'yup';
import { captureException } from '@sentry/nextjs';
import { SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { PayPalForm } from '~/components/containers/checkout/paypalForm';
import { BalanceContracting } from '~/components/containers/contractLooseBalance/balanceContracting';
import { ContractLooseBalancePaymentForm } from '~/components/containers/contractLooseBalance/paymentForm';
import { Button } from '~/components/elements/button';
import { Link } from '~/components/elements/link';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { Tooltip } from '~/components/elements/tooltip/tooltip';
import { TooltipTrigger } from '~/components/elements/tooltip/trigger';
import { useApi } from '~/hooks/useApi';
import { useReplace } from '~/hooks/useReplace';
import { useTranslatedRoute } from '~/hooks/useTranslatedRoute';
import { useTranslator } from '~/hooks/useTranslator';
import { routesName } from '~/locales/route';
import { PaymentMethodTypeEnum } from '~/models/paymentMethod';
import { SendingTechnologyTypeEnum } from '~/models/sendingTechnology';
import { AutomaticContractingLooseBalance } from '~/models/subscriptionTool';
import { ToolTypeEnum } from '~/models/tool';
import { useAlert } from '~/providers/alertProvider';
import { useCheckout, useCoupon, useTools } from '~/providers/checkoutProvider';
import { SubscriptionPaymentFormData } from '~/providers/reducers/subscriptionReducer';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { ContractLooseBalanceAsync } from '~/services/subscriptionApiService';
import { GetAllPaginatedURL } from '~/services/subscriptionInvoiceApiService';
import { GetToolByType } from '~/services/subscriptionService';
import { GetToolIdByName } from '~/services/toolService';
import { PageTitle } from '~/styles';
import { BANK_SLIP_COMPENSATION_OFFSET } from '~/utils/constants';
import { DateFormatEnum, formatDate } from '~/utils/dateFunctions';
import { GetTranslatedEnumName } from '~/utils/enumFunctions';
import { nameOf } from '~/utils/tsFunctions';
import { createPayload } from './createPayload';
import { ContractLooseBalanceContentStyled, ContractLooseBalanceStyled } from './styles';
import { useValidation } from './validation';

type QueryParams = { tool: string | number };

export type LooseBalanceFormData = SubscriptionPaymentFormData & {
    creditsPerMonth: number;
    automaticContracting?: AutomaticContractingLooseBalance;
};

export const ContractLooseBalanceTemplate = () => {
    const { formRef, subscriptionFormData, subscription } = useCheckout();
    const {
        enums,
        buttons,
        pages,
        checkoutPage: {
            form: { acceptTerms }
        },
        api: { statusError }
    } = useTranslator();
    const { contractLooseBalance } = pages;
    const { locale, push } = useRouter();
    const { couponAttemptResult } = useCoupon();
    const { error, success } = useAlert();
    const translateRoute = useTranslatedRoute();
    const { mutate } = useSWRConfig();

    const { execute, loading: isLoading } = useApi(ContractLooseBalanceAsync, {
        onError: (exception) => {
            const errorMessage =
                exception instanceof EnviouApiResponseError
                    ? exception.getFirstError('contractLooseBalanceErrorStatus').message
                    : String(statusError.systemErrorStatus.internalError);

            error(errorMessage);
        },
        onSuccess: async (response) => {
            success(String(contractLooseBalance.messages.success));
            const invoice = (await response).data;

            mutate({ url: GetAllPaginatedURL, currentPage: 1 }, null, { revalidate: true });
            if (invoice.bankSlipUrl) {
                const translatedRoutePath = translateRoute(routesName.checkout.bankSlip, { invoiceId: invoice.id });

                await push(
                    {
                        pathname: routesName.checkout.bankSlip,
                        query: { invoiceId: invoice.id }
                    },
                    translatedRoutePath
                );
                return;
            }

            await push(routesName.mySubscription.base, translateRoute(routesName.mySubscription.base));
        }
    });

    const replace = useReplace();
    const { query } = useRouter();
    const { contractLooseBalanceSchema } = useValidation();

    const toolId = GetToolIdByName((query as QueryParams).tool, locale);
    const toolName = GetTranslatedEnumName(ToolTypeEnum[toolId], enums.toolType);
    const { tools } = useTools();

    const currentPaymentMethod = subscriptionFormData?.subscriptionPayment?.paymentMethod;
    const hasAcceptedTheTerms = subscriptionFormData?.subscriptionPayment?.hasAcceptedTheTerms;
    const creditCard = subscriptionFormData?.subscriptionPayment?.creditCard;

    const hasCreditCardInSubscription =
        subscription?.paymentMethod?.type === PaymentMethodTypeEnum.CreditCard && !!creditCard;

    const [showAutomaticContractingForm, setShowAutomaticContractingForm] = useState(
        currentPaymentMethod === PaymentMethodTypeEnum.CreditCard
    );

    const credits = useMemo(() => {
        const technologies = GetToolByType(tools, toolId)?.sendingTechnologies;

        return technologies
            ?.filter((technology) => technology.type === SendingTechnologyTypeEnum.Email)
            ?.map((technology) => ({
                id: technology.id,
                currency: technology.currency,
                looseValue: technology.looseValue,
                creditAmount: technology.creditAmount
            }));
    }, [tools, toolId]);

    const handleChangePaymentMethod = (paymentMethod: PaymentMethodTypeEnum) => {
        setShowAutomaticContractingForm(
            paymentMethod === PaymentMethodTypeEnum.CreditCard || paymentMethod === PaymentMethodTypeEnum.Gateway
        );
    };

    const handleSubmit: SubmitHandler<LooseBalanceFormData> = async (formData) => {
        try {
            formRef.current.setErrors({});

            await contractLooseBalanceSchema.validate(formData, {
                abortEarly: false
            });

            const totalCredits = credits.find((item) => item.id === formData.creditsPerMonth);

            const payload = createPayload({
                subscription,
                formData,
                creditAmount: totalCredits?.creditAmount,
                toolId,
                couponAttemptResult
            });

            execute(payload);
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((yupError) => (errorMessages[yupError.path] = yupError.message));
                formRef.current.setErrors(errorMessages);
            } else {
                captureException(exception);
            }
        }
    };

    const handleAuthorizePayment = useCallback(
        (nonce: string) => {
            formRef.current?.setFieldValue('payPal.nonce', nonce);
            formRef.current?.submitForm();
        },
        [formRef]
    );

    return (
        <ContractLooseBalanceStyled>
            <PageTitle>{replace(String(contractLooseBalance.title), { tool: toolName })}</PageTitle>

            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{
                    automaticContracting: {
                        isEnable: false,
                        minBalancePercentage: 0.9,
                        balanceToBeContracted: _first(credits)?.id,
                        maxTimesToRecontract: 3
                    } as AutomaticContractingLooseBalance,
                    creditCard,
                    bankSlip: {
                        firstDueDate: formatDate(
                            addDays(new Date(), BANK_SLIP_COMPENSATION_OFFSET),
                            DateFormatEnum.OnlyDateUSFormat
                        )
                    },
                    cardFlagName: creditCard && subscription.card?.brand,
                    isLooseBalance: true,
                    disableCreditCardFields: hasCreditCardInSubscription
                }}>
                <ContractLooseBalanceContentStyled>
                    <ContractLooseBalancePaymentForm onChangePaymentMethod={handleChangePaymentMethod} />
                    <BalanceContracting
                        credits={credits}
                        toolType={toolId}
                        automaticContractingScopePath={nameOf<LooseBalanceFormData>('automaticContracting')}
                        showAutomaticContractingForm={showAutomaticContractingForm}
                    />
                </ContractLooseBalanceContentStyled>

                <footer>
                    <Link href={routesName.mySubscription.base} as={routesName.mySubscription.base}>
                        <Button color='purple' isOutlined buttonSize='small'>
                            {String(buttons.cancel)}
                        </Button>
                    </Link>
                    <Tooltip open={!hasAcceptedTheTerms ? undefined : false}>
                        <TooltipTrigger>
                            <Button
                                type={currentPaymentMethod === PaymentMethodTypeEnum.Gateway ? 'button' : 'submit'}
                                color='purple'
                                buttonSize='small'
                                disabled={!hasAcceptedTheTerms || isLoading}
                                inLoading={isLoading}>
                                {String(buttons.makePayment)}

                                <PayPalForm
                                    onAuthorizePayment={handleAuthorizePayment}
                                    name='payPal.nonce'
                                    show={
                                        hasAcceptedTheTerms &&
                                        !isLoading &&
                                        currentPaymentMethod === PaymentMethodTypeEnum.Gateway
                                    }
                                />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>{String(acceptTerms)}</p>
                        </TooltipContent>
                    </Tooltip>
                </footer>
            </Form>
        </ContractLooseBalanceStyled>
    );
};
