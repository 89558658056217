import { QueryParamsType, RepurchaseItemsQueryParamsState } from './useManageRepurchaseItems';

export const createRepurchaseItemsFilterParamsState = (queryParams: QueryParamsType) => {
    const parameters = {
        itemType: queryParams?.itemType ?? 'general',
        sort: queryParams?.sort ?? 'nameAsc',
        page: !isNaN(parseInt(queryParams.page)) ? Number(queryParams.page) : 1,
        search: queryParams.search ?? ''
    };

    return {
        periodType: 'customizeTimePeriod',
        ...parameters
    } as RepurchaseItemsQueryParamsState;
};
