import { css } from 'styled-components';

const ckeditorClasses = css`
    .cke_editable {
        word-wrap: break-word;
    }

    .cke_contents_ltr blockquote {
        padding-left: 20px;
        padding-right: 8px;
        border-left-width: 5px;
    }

    .cke_contents_rtl blockquote {
        padding-left: 8px;
        padding-right: 20px;
        border-right-width: 5px;
    }
`;

const bodyStyle = css`
    font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 0.9rem;
    line-height: 1.6;
    color: #333;
    background-color: #fff;
    margin: 0;
    padding: 20px;
`;

const defaultStyle = css`
    blockquote {
        font-style: italic;
        font-family: Georgia, Times, 'Times New Roman', serif;
        padding: 2px 0;
        border-style: solid;
        border-color: #ccc;
        border-width: 0;
    }

    a {
        color: #0782c1;
    }

    ol,
    ul,
    dl {
        margin-right: 0px;
        padding: 0 40px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
        line-height: 1.2;
    }

    hr {
        border: 0px;
        border-top: 1px solid #ccc;
    }

    img.right {
        border: 1px solid #ccc;
        float: right;
        margin-left: 15px;
        padding: 5px;
    }

    img.left {
        border: 1px solid #ccc;
        float: left;
        margin-right: 15px;
        padding: 5px;
    }

    pre {
        white-space: pre-wrap;
        word-wrap: break-word;
        -moz-tab-size: 4;
        tab-size: 4;
    }

    .marker {
        background-color: Yellow;
    }

    span[lang] {
        font-style: italic;
    }

    figure {
        text-align: center;
        outline: solid 1px #ccc;
        background: rgba(0, 0, 0, 0.05);
        padding: 10px;
        margin: 10px 20px;
        display: inline-block;
    }

    figure > figcaption {
        text-align: center;
        display: block;
    }

    a > img {
        padding: 1px;
        margin: 1px;
        border: none;
    }

    table {
        line-height: inherit;
        font-size: inherit;
    }

    .code-featured {
        border: 5px solid red;
    }

    .math-featured {
        padding: 20px;
        box-shadow: 0 0 2px rgba(200, 0, 0, 1);
        background-color: rgba(255, 0, 0, 0.05);
        margin: 10px;
    }

    .image-clean {
        border: 0;
        background: none;
        padding: 0;
    }

    .image-clean > figcaption {
        font-size: 0.9em;
        text-align: right;
    }

    .image-grayscale {
        background-color: white;
        color: #666;
    }

    .image-grayscale img,
    img.image-grayscale {
        filter: grayscale(100%);
    }

    .embed-240p {
        max-width: 426px;
        max-height: 240px;
        margin: 0 auto;
    }

    .embed-360p {
        max-width: 640px;
        max-height: 360px;
        margin: 0 auto;
    }

    .embed-480p {
        max-width: 854px;
        max-height: 480px;
        margin: 0 auto;
    }

    .embed-720p {
        max-width: 1280px;
        max-height: 720px;
        margin: 0 auto;
    }

    .embed-1080p {
        max-width: 1920px;
        max-height: 1080px;
        margin: 0 auto;
    }

    .empty-message {
        font-size: 1.115rem;
        text-align: center;
        width: 100%;
        display: block;
    }
`;

const preview = css`
    body {
        ${bodyStyle}
        padding: 0;
    }
    ${defaultStyle}
`;

const ckEditorStyle = css`
    body {
        ${bodyStyle}
    }
    ${defaultStyle}
    ${ckeditorClasses}
`;

export const ckeditorDefaultStyle: string = ckEditorStyle.length > 0 ? ckEditorStyle.join('') : '';

export const previewDefaultStyle: string = preview.length > 0 ? preview.join('') : '';
