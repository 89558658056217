import { createContext, useState, Dispatch, SetStateAction, ReactNode, useMemo, useContext } from 'react';
import { NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import { useCookieState } from '@hooks';
import { User } from '@models';
import {
    translateRoute,
    getExpiration,
    wait,
    PAINELN2_LOG_OUT,
    isBrowser,
    LOCAL_STORAGE_TEMP_PRODUCTS_KEY,
    replace
} from '@utils';
import { routesName } from '~/locales/route';
import { IsActivePainel2, SetDefaultHeader } from '~/services/apiService';
import * as AuthService from '~/services/authService';

type AuthContextData = {
    user: User;
    token: string;
    isAuthenticated: boolean;
    SignIn: (username: string, password: string, redirectTo?: string) => Promise<void>;
    TwoFactorAuthentication: (code: string, antiForgeryToken: string, redirectTo?: string) => Promise<void>;
    SignOut: () => void;
    SetToken: (token: string, expiresIn: Date) => void;
    SetUser: Dispatch<SetStateAction<User>>;
    RecoverPassword: (username: string) => Promise<boolean>;
};

type AuthProviderProps = {
    children: ReactNode;
    context?: NextPageContext;
    user?: User;
    token?: string;
};

export const AuthContext = createContext({} as AuthContextData);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children, context, user: initialUser, token: initialToken }: AuthProviderProps) => {
    const router = useRouter();
    const { cache } = useSWRConfig();
    const [expireToken, setExpireToken] = useState<Date>(initialToken && new Date(getExpiration(initialToken)));

    const [token, setToken] = useCookieState<string>(process.env.NEXT_PUBLIC_TOKEN_COOKIE_NAME, initialToken, context, {
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'lax',
        path: '/',
        expires: expireToken
    });

    const [user, setUser] = useCookieState<User>(process.env.NEXT_PUBLIC_USER_COOKIE_NAME, initialUser, context, {
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'lax',
        path: '/'
    });

    const isAuthenticated = useMemo<boolean>(() => !!token && !!user, [token, user]);

    const SetToken = (strToken: string, expiresIn: Date) => {
        SetDefaultHeader({ Authorization: `Bearer ${strToken}` });

        setExpireToken(expiresIn);
        setToken(strToken);
    };

    const SignIn = async (username: string, password: string, redirectTo?: string) => {
        const responseData = await AuthService.SignIn({ username, password });

        if (responseData.needs2FA) {
            const params = `token=${encodeURIComponent(responseData.antiForgeryToken)}`;
            const route = translateRoute(routesName.twoFactor, router.locale, {
                params
            });

            const routeUrl = replace(routesName.twoFactor, { params }, { start: '{', end: '}' });

            await router.push(routeUrl, route);
            return;
        }

        setUser(responseData.user);
        SetToken(responseData.token, responseData.expiresIn);

        const TIME_IN_MILLISECONDS_TO_WAIT_FOR_COOKIES_TO_LOAD = 200;
        await wait(TIME_IN_MILLISECONDS_TO_WAIT_FOR_COOKIES_TO_LOAD);

        await router.push(translateRoute(`/${redirectTo || ''}`, responseData.user.locale || router.locale));
    };

    const TwoFactorAuthentication = async (twoFactorToken: string, antiForgeryToken: string, redirectTo?: string) => {
        const responseData = await AuthService.TwoFactorAuthentication({ antiForgeryToken, twoFactorToken });

        setUser(responseData.user);
        SetToken(responseData.token, responseData.expiresIn);

        const TIME_IN_MILLISECONDS_TO_WAIT_FOR_COOKIES_TO_LOAD = 200;
        await wait(TIME_IN_MILLISECONDS_TO_WAIT_FOR_COOKIES_TO_LOAD);

        await router.push(translateRoute(`/${redirectTo || ''}`, responseData.user.locale || router.locale));
    };

    const SignOut = async () => {
        setToken(null);
        setUser(null);

        AuthService.SignOut(context);
        cache.clear();

        if (isBrowser()) {
            localStorage.removeItem(LOCAL_STORAGE_TEMP_PRODUCTS_KEY);
        }

        if (await IsActivePainel2()) {
            window.location.assign(PAINELN2_LOG_OUT);
            return;
        }

        await router.push(routesName.signIn, translateRoute(routesName.signIn, router.locale));
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                token,
                isAuthenticated,
                SignIn,
                SignOut,
                SetToken,
                TwoFactorAuthentication,
                SetUser: setUser,
                RecoverPassword: AuthService.RecoverPassword
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
