import styled from 'styled-components';

export const TemplateTypeSectionStyled = styled.div`
    & > h6 {
        margin: ${({ theme }) => `${theme.space.xl} 0`};
    }

    & > div {
        display: flex;
        gap: 1.6rem;

        button {
            flex: 1 1 auto;
            max-width: 16.4rem;
        }
    }
`;
