import { MessageSequenceType } from './messageSequence';

export enum ItemConfigurationType {
    Store = 1,
    Category = 2,
    Product = 3
}

export enum ItemConfigurationSort {
    RepurchasePeriodAsc = 1,
    RepurchasePeriodDesc = 2,
    ReliabilityAsc = 3,
    ReliabilityDesc = 4,
    NameAsc = 5,
    NameDesc = 6
}

export type StoreItemConfiguration = {
    storeId: number;
    subToolId: number;
    hasTemplate: boolean;
    stepsAmount: number;
    templateType: MessageSequenceType;
};
