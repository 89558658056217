import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { translateRoute } from '@utils';

export const useTranslatedRoute = () => {
    const { locale, query } = useRouter();

    return useCallback((route: string, params = query) => translateRoute(route, locale, params), [locale, query]);
};

export default useTranslatedRoute;
