import { forwardRef, HTMLAttributes, useLayoutEffect } from 'react';
import { useId } from '@floating-ui/react';
import { useDialogContext } from '~/providers';
import { DialogDescriptionStyled } from './styles';

export const DialogDescription = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
    function DialogDescription({ children, ...props }, ref) {
        const { setDescriptionId } = useDialogContext();
        const id = useId();

        // Only sets `aria-describedby` on the Dialog root element
        // if this component is mounted inside it.
        useLayoutEffect(() => {
            setDescriptionId(id);
            return () => setDescriptionId(undefined);
        }, [id, setDescriptionId]);

        return (
            <DialogDescriptionStyled {...props} ref={ref} id={id}>
                {children}
            </DialogDescriptionStyled>
        );
    }
);
