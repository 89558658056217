import { Button, Text, InfoTooltip } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import {
    GeneralDetailedStatistics,
    statisticsStatusByTransactionStatus,
    SubToolIdEnum,
    DetailedStatisticsStatus
} from '@models';
import { useExpandableGroup } from '@providers';
import { convertToPhoneNumber, formatDate, getUncapitalizeEnumKeyByValue } from '@utils';
import { WhatsappInput } from '../whatsappInput';
import {
    CustomerContentStyled,
    CustomerDetails,
    CustomerItemStyled,
    StatusColor,
    StatusStyled,
    TooltipContentStyled
} from './styles';

export const CustomerItem = () => {
    const { buttons, enums } = useTranslator();
    const { toogleExpanded, triggerId, contentId, expanded, data } = useExpandableGroup();
    const { formatCurrency } = useNumberFunctions();

    const statistic = data as GeneralDetailedStatistics;
    const isGeneratedSales = statistic.totalSalesValue > 0;
    let statisticsStatus = statisticsStatusByTransactionStatus[statistic.status] as DetailedStatisticsStatus;
    statisticsStatus = isGeneratedSales ? DetailedStatisticsStatus.GeneratedSales : statisticsStatus;
    const statusName = getUncapitalizeEnumKeyByValue(statisticsStatus, DetailedStatisticsStatus);
    const status = String(enums.singularDetailsStatisticsStatus[statusName]);

    const formattedPhoneNumber = statistic.shopperMobile
        ? convertToPhoneNumber(statistic.shopperMobile)?.formatInternational()
        : '';

    return (
        <CustomerItemStyled
            onClick={toogleExpanded}
            onDrag={(event) => event.preventDefault()}
            id={triggerId}
            aria-expanded={expanded}
            aria-controls={contentId}>
            <Text as='time' size='xxs'>
                {formatDate(new Date(statistic.date), 'datetime')}
            </Text>
            <CustomerContentStyled>
                <CustomerDetails>
                    <Text as='strong' size='sm' lineHeight='tall' title={statistic.shopperName}>
                        {statistic.shopperName}
                    </Text>
                    <Text as='span' size='xs' lineHeight='tall'>
                        {statistic.shopperEmail}
                    </Text>
                    <Text as='span' size='xxs' lineHeight='tall' className='cell-number'>
                        {formattedPhoneNumber}
                    </Text>
                </CustomerDetails>

                <WhatsappInput />
            </CustomerContentStyled>
            <Text as='span' size='sm'>
                {statistic.subToolId === SubToolIdEnum.CustomTrigger
                    ? statistic.customTriggerName
                    : enums.subToolIdEnum[SubToolIdEnum[statistic.subToolId]]}
            </Text>
            <StatusStyled as='span' size='sm' status={statusName as StatusColor}>
                <span>
                    {status}
                    {statistic.errorMessage && (
                        <InfoTooltip>
                            <TooltipContentStyled>{statistic.errorMessage}</TooltipContentStyled>
                        </InfoTooltip>
                    )}
                </span>
                {isGeneratedSales && <span>{formatCurrency(statistic.totalSalesValue ?? 0, statistic.currency)}</span>}
            </StatusStyled>
            <Button buttonSize='small' styledAs='link' color='deepCerise'>
                {String(expanded ? buttons.viewLess : buttons.viewMore)}
            </Button>
        </CustomerItemStyled>
    );
};
