import {
    ContactConfigurationStatusEnum,
    MessageSequence,
    MessageSequenceStep,
    SendingTechnologyTypeEnum,
    SubToolConfigurationStatusEnum
} from '@models';
import { BankSlipReminderSubToolConfiguration } from '~/models/subToolBankSlipReminder';
import { axiosClient } from './apiService';
import { TestEmailRequestPayload, TestEmailResponsePayload } from './subToolApiService';

export type UpdateBankSlipReminderConfigurationContactsPayload = {
    id?: number;
    subToolConfigurationId: number;
    senderName: string;
    senderContact: string;
    contactAnswer?: string;
    status: ContactConfigurationStatusEnum;
    sendingTechnology: SendingTechnologyTypeEnum;
};

export type UpdateBankSlipReminderConfigurationPayload = {
    id?: number;
    subToolId: number;
    status: SubToolConfigurationStatusEnum;
    expirationDays: number;
    contactConfigurations: Array<UpdateBankSlipReminderConfigurationContactsPayload>;
};

export const getMessageSequencesUrl = '/sub-tool/bank-slip-reminder/message-sequences';
export const GetMessageSequencesAsync = (signal: AbortSignal = null) => {
    return axiosClient.get<MessageSequence>(getMessageSequencesUrl, { signal });
};

export const DeleteMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.delete<boolean>(`/sub-tool/bank-slip-reminder/message-sequences/steps/${stepId}`);
};

export const DuplicateMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.post<MessageSequenceStep>(
        `/sub-tool/bank-slip-reminder/message-sequences/steps/${stepId}/duplicate`
    );
};

export const ChangeStatusAsync = async (newStatus: SubToolConfigurationStatusEnum, bankSlipReminderId: number) => {
    return axiosClient.patch<boolean>(
        '/sub-tool/bank-slip-reminder/configuration/status',
        {
            newStatus
        },
        {
            params: {
                bankSlipReminderId
            }
        }
    );
};

export const GetSubToolConfigurationAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<BankSlipReminderSubToolConfiguration>('/sub-tool/bank-slip-reminder/configuration', {
        signal
    });
};

export const CreateOrUpdateSubToolConfigurationAsync = async (payload: UpdateBankSlipReminderConfigurationPayload) => {
    return axiosClient.put<BankSlipReminderSubToolConfiguration>('/sub-tool/bank-slip-reminder/configuration', payload);
};

export const SendTestEmail = async (payload: TestEmailRequestPayload) => {
    return axiosClient.post<TestEmailResponsePayload>('/sub-tool/bank-slip-reminder/send-test-email', payload);
};
