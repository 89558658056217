import styled from 'styled-components';
import { InputGroup } from '../inputGroup';
import { TooltipContent } from '../tooltip';

export const WhatsappLabelToolTipChildrenStyled = styled(TooltipContent)`
    max-width: 38rem;

    h3 {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    h4 {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    p {
        font-size: 1.4rem;
        line-height: 1.8rem;

        span {
            font-family: monospace;
        }
    }
`;

export const InputGroupStyled = styled(InputGroup)`
    margin-bottom: 0.2rem;
    margin-top: 2.4rem;
    div:first-child {
        max-width: 25.6rem;
    }

    button {
        width: auto;
    }
`;

export const TestIsNotFreeSpanStyled = styled.span`
    color: ${({ theme }) => theme.colors.lightGray};
`;

export const TestSendingContainer = styled.div`
    margin-top: auto;
`;
