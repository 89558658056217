import _isEqual from 'lodash/isEqual';
import * as yup from 'yup';
import { useTranslator } from '@hooks';
import {
    ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY,
    ContactConfiguration,
    ContactConfigurationStatusEnum,
    SendingTechnologyFieldEnum,
    SubToolConfiguration
} from '@models';
import { ParseSubToolConfigurationToFormData } from '~/services/subToolService';

export type ContactConfigurationFormData = {
    senderName: string;
    senderContact: string;
    contactAnswer: string;
    isActive: boolean;
    sendingTechnology?: number;
    contactConfigurationId?: number;
};

export type SubToolConfigurationFormData = {
    expirationDays: string;
    contactConfigurations: Array<ContactConfigurationFormData>;
};

export const useValidation = () => {
    const { form } = useTranslator().subTools.subToolConfiguration;

    const subToolConfigurationSchema: yup.SchemaOf<SubToolConfigurationFormData> = yup.object().shape({
        expirationDays: yup.string().required(form.bankSlipReminderExpirationDays.required),
        contactConfigurations: yup
            .array()
            .of(
                yup.object().shape({
                    senderName: yup
                        .string()
                        .trim()
                        .when(['sendingTechnology'], {
                            is: (sendingTechnology: number) =>
                                ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY[sendingTechnology].includes(
                                    SendingTechnologyFieldEnum.SenderName
                                ),
                            then: (schema) => schema.required(form.senderName.required)
                        }),
                    senderContact: yup
                        .string()
                        .when(['sendingTechnology'], {
                            is: (sendingTechnology: number) =>
                                ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY[sendingTechnology].includes(
                                    SendingTechnologyFieldEnum.SenderContact
                                ),
                            then: (schema) => schema.email(form.senderEmail.email).required(form.senderEmail.required)
                        })
                        .nullable(),
                    contactAnswer: yup
                        .string()
                        .when('sendingTechnology', {
                            is: (sendingTechnology: number) =>
                                ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY[sendingTechnology].includes(
                                    SendingTechnologyFieldEnum.ContactAnswer
                                ),
                            then: (schema) => schema.email(form.senderEmail.email)
                        })
                        .optional(),
                    isActive: yup.bool().optional().nullable(),
                    sendingTechnology: yup.number(),
                    contactConfigurationId: yup.number()
                })
            )
            .required()
    });

    return {
        subToolConfigurationSchema
    };
};

const setInitialContactsIfEmpty = (formData: SubToolConfigurationFormData, initialData: SubToolConfiguration) => {
    if (initialData.contactConfigurations?.length === 0) {
        initialData.contactConfigurations = [
            ...formData.contactConfigurations?.map((item: ContactConfigurationFormData) => {
                return {
                    sendingTechnology: item.sendingTechnology,
                    id: 0
                };
            })
        ] as Array<ContactConfiguration>;
    }
};

export const hasChangesInForm = (formData: SubToolConfigurationFormData, initialData: SubToolConfiguration) => {
    if (initialData && formData) {
        setInitialContactsIfEmpty(formData, initialData);
        const subToolConfigurationData = ParseSubToolConfigurationToFormData(initialData);

        const currentFormData = ParseSubToolConfigurationToFormData({
            ...formData,
            contactConfigurations: formData.contactConfigurations?.map((item: ContactConfigurationFormData) => ({
                ...item,
                id: item.contactConfigurationId,
                status: item.isActive ? ContactConfigurationStatusEnum.Active : ContactConfigurationStatusEnum.Inactive
            }))
        } as unknown as SubToolConfiguration);

        return !_isEqual(subToolConfigurationData, currentFormData);
    }

    return false;
};
