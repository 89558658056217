import _lowerFirst from 'lodash/lowerFirst';
import { Button, Link, Heading, Text } from '@components';
import { useNumberFunctions, useTranslator, useRedirectToPainel2 } from '@hooks';
import { Campaign, FriendlyCampaignStatus } from '@models';
import { formatDate, PAINELN2_CAMPAIGN_STATISTICS_LINK } from '@utils';
import { AlertColors } from '~/styles/theme';
import { CardItemStyled } from '../styles';
import { CardItemContentStyled, CardItemFieldStyled } from './styles';

type CampaignItemProps = {
    campaign: Campaign;
};

const statusColors: { [key in FriendlyCampaignStatus]: AlertColors } = {
    [FriendlyCampaignStatus.Cancelled]: 'error',
    [FriendlyCampaignStatus.Draft]: 'warning',
    [FriendlyCampaignStatus.Paused]: 'default',
    [FriendlyCampaignStatus.Programmed]: 'default',
    [FriendlyCampaignStatus.Sending]: 'info',
    [FriendlyCampaignStatus.Sent]: 'success'
};

export const CampaignItem = ({ campaign, ...props }: CampaignItemProps) => {
    const {
        pages: {
            home: {
                lastedCampaignsCard: { viewMoreButton, statusLabel, totalSentLabel }
            }
        },
        enums: { friendlyCampaignStatus }
    } = useTranslator();
    const { redirect } = useRedirectToPainel2();
    const { formatNumber } = useNumberFunctions();

    const translatedStatus = friendlyCampaignStatus[_lowerFirst(FriendlyCampaignStatus[campaign.friendlyStatus])];
    const viewMoreUrl = new URL(String(campaign.id), PAINELN2_CAMPAIGN_STATISTICS_LINK);

    return (
        <CardItemStyled {...props}>
            <CardItemContentStyled>
                <CardItemFieldStyled>
                    <Heading as='h6' size='md' color='deepCerise' title={campaign.name}>
                        {campaign.name}
                    </Heading>
                    <Text as='span' size='xs'>
                        {formatDate(new Date(campaign.startDate), 'short')}
                    </Text>
                </CardItemFieldStyled>
                <CardItemFieldStyled className='status' variant={statusColors[campaign.friendlyStatus]}>
                    <Text as='span' size='xs'>
                        {String(statusLabel)}
                    </Text>
                    <Text as='strong' size='md'>
                        {String(translatedStatus)}
                    </Text>
                </CardItemFieldStyled>
                <CardItemFieldStyled className='total-sent'>
                    <Text as='span' size='xs'>
                        {String(totalSentLabel)}
                    </Text>
                    <Text as='strong' size='md'>
                        {formatNumber(campaign.sentCount ?? 0)}
                    </Text>
                </CardItemFieldStyled>
                <CardItemFieldStyled>
                    <Link href={redirect(viewMoreUrl.href, false)}>
                        <Button buttonSize='small' color='purple' isOutlined>
                            {String(viewMoreButton)}
                        </Button>
                    </Link>
                </CardItemFieldStyled>
            </CardItemContentStyled>
        </CardItemStyled>
    );
};
