import { createContext, useContext } from 'react';
import { useSelect } from './useSelect';

export type SelectContextValue = ReturnType<typeof useSelect>;

export const SelectContext = createContext<SelectContextValue>({} as SelectContextValue);

export const useSelectContext = () => {
    const context = useContext(SelectContext);

    if (context == null) {
        throw new Error('Select components must be wrapped in <Select />');
    }

    return context;
};
