import { RefObject, useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

// NOTE: Functions is being used because of the overrides. Cannot do overrides with "arrow functions"

// override
function useEventListener<T extends keyof WindowEventMap>(
    eventName: T,
    handler: (event: WindowEventMap[T]) => void
): void;

// override
function useEventListener<T extends keyof HTMLElementEventMap, U extends HTMLElement = HTMLDivElement>(
    eventName: T,
    handler: (event: HTMLElementEventMap[T]) => void,
    element: RefObject<U>
): void;

// implementation
function useEventListener<
    T extends keyof WindowEventMap,
    U extends keyof HTMLElementEventMap,
    V extends HTMLElement | void = void
>(
    eventName: T | U,
    handler: (event: WindowEventMap[T] | HTMLElementEventMap[U] | Event) => void,
    element?: RefObject<V>
) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler);

    useIsomorphicLayoutEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        // Define the listening target
        const targetElement: V | Window = element?.current || window;
        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }

        // Create event listener that calls handler function stored in ref
        const eventListener: typeof handler = (event) => savedHandler.current(event);

        targetElement.addEventListener(eventName, eventListener);

        // Remove event listener on cleanup
        return () => {
            targetElement.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
}

export { useEventListener };
