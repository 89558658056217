import { Heading, Box, Text } from '@components';
import { useNumberFunctions, useReplace, useTranslator } from '@hooks';
import { CustomerPlanType } from '@models';
import { InvoiceDetailsList } from '../invoiceDetailsList';
import { InvoiceDetailsGroupStyled } from '../styles';
import { ToolsDetailsData } from '../toolsDetails';

export const AutomationPlanDetails = ({
    subscriptionTool,
    title,
    showUpdatePlanButton
}: ToolsDetailsData & { title: string }) => {
    const { subgroups } = useTranslator().checkoutPage.invoiceDetails;
    const { formatNumber } = useNumberFunctions();
    const replace = useReplace();

    const creditVolume = subscriptionTool.totalCredits;

    if (!creditVolume || creditVolume <= 0) {
        return <></>;
    }

    const toolLabel =
        subscriptionTool?.type === CustomerPlanType.BulkSendingTool
            ? subgroups.automationPlans.shipments
            : subgroups.automationPlans.credits;

    return (
        <InvoiceDetailsGroupStyled>
            <Heading as='h6'>{title}</Heading>
            <Box>
                {creditVolume && creditVolume > 0 && (
                    <Text as='strong' size='md'>
                        {replace(toolLabel, formatNumber(creditVolume))}
                    </Text>
                )}
                <InvoiceDetailsList showUpdatePlanButton={showUpdatePlanButton} subscriptionTool={subscriptionTool} />
            </Box>
        </InvoiceDetailsGroupStyled>
    );
};
