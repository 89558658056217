import { CouponAttemptResult, PaymentMethodTypeEnum, Subscription, ToolTypeEnum } from '@models';
import { getCardFlagName } from '@utils';
import { ContractLooseBalancePayload } from '~/services/subscriptionApiService';
import { LooseBalanceFormData } from '.';

const MIN_CREDIT_CARD_NUMBER = 4;

type CreatePayloadProps = {
    toolId: ToolTypeEnum;
    formData: LooseBalanceFormData;
    subscription: Subscription;
    creditAmount: number;
    couponAttemptResult?: CouponAttemptResult;
};

export const createPayload = ({
    formData,
    subscription,
    creditAmount,
    toolId,
    couponAttemptResult
}: CreatePayloadProps) => {
    let creditCardBrand = subscription.card?.brand;

    if (String(formData.creditCard?.number ?? '').length > MIN_CREDIT_CARD_NUMBER) {
        creditCardBrand = getCardFlagName(formData.creditCard?.number);
    }

    const card =
        Number(formData.paymentMethod) === PaymentMethodTypeEnum.CreditCard
            ? {
                  ...formData.creditCard,
                  brand: creditCardBrand
              }
            : null;

    const nextInvoiceDate = formData.bankSlip?.firstDueDate
        ? new Date(formData.bankSlip?.firstDueDate).toISOString()
        : null;

    let automaticContracting: ContractLooseBalancePayload['automaticContracting'] = null;

    if (formData.automaticContracting) {
        automaticContracting = {
            isEnable: formData.automaticContracting?.isEnable,
            minBalancePercentage: formData.automaticContracting?.minBalancePercentage,
            balanceToBeContracted: formData.automaticContracting?.balanceToBeContracted,
            maxTimesToRecontract: formData.automaticContracting?.maxTimesToRecontract
        };
    }

    const payload: ContractLooseBalancePayload = {
        subscriptionId: subscription.id,
        totalCredits: creditAmount,
        toolType: toolId,
        paymentMethodType: Number(formData.paymentMethod),
        payPalNonce: formData.payPal?.nonce ?? null,
        nextInvoiceDate,
        card,
        couponsIds: couponAttemptResult?.coupon?.id ? [couponAttemptResult?.coupon?.id] : [],
        automaticContracting: automaticContracting
    };

    return payload;
};
