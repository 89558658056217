import DatePicker from 'react-datepicker';
import datepickerCss from 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

export const DatePickerStyled = styled(DatePicker)`
    ${datepickerCss};
`;

export const CalendarHeaderStyled = styled.div`
    & > span {
        display: block;
    }

    & > div {
        margin: 1rem;
        display: flex;
        justify-content: center;
        line-height: 0;

        button {
            border: none;
            background: none;
            color: ${(props) => props.theme.colors.purple};
            transition: all 0.15s ease;
            cursor: pointer;

            &:focus-visible,
            &:active,
            &:hover {
                border: none;
                outline: none;
            }

            &:disabled {
                color: ${({ theme }) => theme.colors.philippineSilver};
                opacity: 0.7;
            }
        }

        button.date-title {
            flex: 1;
            text-transform: capitalize;
            font-weight: bold;
            font-size: ${({ theme }) => theme.fontSizes.xxs};
        }

        button:first-child {
            width: 1.75rem;
            transform: rotate(180deg);

            svg {
                height: 1.4rem;
                width: 1.4rem;
            }
        }

        button:last-child {
            width: 1.75rem;
            svg {
                height: 1.4rem;
                width: 1.4rem;
            }
        }
    }
`;
