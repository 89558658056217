import Image from 'next/image';
import { ViewModeProps } from '..';
import { Heading, Text } from '@components';
import { useTranslator } from '@hooks';
import {
    ArrowDownStyled,
    BaterryIconStyled,
    ChromeIconStyle,
    ImageIconStyle,
    MoreHorizontalStyled,
    NotificationContainerStyled,
    OsWrapperStyled,
    TaskbarStyled,
    WifiIconStyle
} from './styles';

export const MacOsViewElement = ({
    body,
    title,
    iconUrl,
    imageUrl,
    domain,
    currentDate,
    currentTime,
    selectedPushAction,
    pushActionList
}: ViewModeProps) => {
    const {
        buttons: { close },
        subTools: {
            pushTemplateEditor: {
                pushActionButtons: { buttonText }
            }
        }
    } = useTranslator();
    return (
        <OsWrapperStyled>
            <TaskbarStyled>
                <WifiIconStyle />
                <BaterryIconStyled />
                <p>
                    {currentDate} {currentTime}
                </p>
            </TaskbarStyled>

            <NotificationContainerStyled>
                <div className='notification-header'>
                    <div className='browser-info'>
                        <ChromeIconStyle />
                        <Text className='browser-name'>Google chrome</Text>
                    </div>
                    <div className='notification-header-icons'>
                        <MoreHorizontalStyled />
                        <ArrowDownStyled />
                    </div>
                </div>

                {imageUrl ? (
                    <Image src={imageUrl} alt='Push notification image' width={360} height={180} objectFit='cover' />
                ) : (
                    <div className='no-image'>
                        <ImageIconStyle />
                    </div>
                )}

                <div className='notification-body'>
                    <div className='icon'>
                        {iconUrl ? (
                            <Image width={64} height={64} src={iconUrl} alt='mac OS push notification preview icon' />
                        ) : (
                            <ImageIconStyle />
                        )}
                    </div>

                    <div className='notification-message'>
                        <Heading className='title'>{title}</Heading>
                        <Text className='domain'>{domain}</Text>
                        <Text className='text'>{body}</Text>
                    </div>
                </div>
                <div className='notification-actions'>
                    {selectedPushAction === 'pushOneButton' && (
                        <Text className='action-button'>
                            {pushActionList[selectedPushAction][0]?.title || String(buttonText)}
                        </Text>
                    )}

                    {selectedPushAction === 'pushTwoButtons' &&
                        pushActionList[selectedPushAction].map((button, index) => (
                            <Text key={index} className='action-button'>
                                {button?.title || String(buttonText)}
                            </Text>
                        ))}
                    <Text className='action-button'>{String(close)}</Text>
                </div>
            </NotificationContainerStyled>
        </OsWrapperStyled>
    );
};
