import { IframeHTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type IFrameProps = IframeHTMLAttributes<HTMLIFrameElement> & {
    headChildren?: ReactNode;
};

export const IFrame = ({ children, headChildren, ...props }: IFrameProps) => {
    const iframeRef = useRef<HTMLIFrameElement>();
    const [body, setBody] = useState<HTMLElement>();
    const [head, setHead] = useState<HTMLElement>();

    useEffect(() => {
        try {
            const { contentWindow } = iframeRef.current;
            if (contentWindow?.document) {
                const { body, head } = contentWindow?.document;
                setBody(body);
                setHead(head);
            }
        } catch {}
    }, []);

    return (
        <iframe ref={iframeRef} {...props}>
            {body && createPortal(children, body)}
            {head && createPortal(headChildren, head)}
        </iframe>
    );
};
