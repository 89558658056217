import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import {
    ButtonColorOption,
    ButtonLabelOption,
    ButtonShapeOption,
    ButtonSizeOption,
    ButtonStyle,
    Environment,
    PayPalCheckoutCreatePaymentOptions
} from '@models';
import { useBraintree } from '@providers';
import { captureException } from '@sentry/nextjs';
import { useField } from '@unform/core';
import { isProduction } from '@utils';
import { AuthorizationData, CancellationData, FlowType, Intent } from '~/models/paypal/callbackData';
import { CreateBraintreeClientInstance } from '~/services/braintreeService';
import { PayPalContainerStyled } from './styles';

type PayPalFormProps = {
    onAuthorizePayment?: (nonce: string) => void;
    show?: boolean;
    name?: string;
};

export const PayPalForm = ({
    onAuthorizePayment,
    show = true,
    name = 'subscriptionPayment.payPal.nonce'
}: PayPalFormProps) => {
    const { locale } = useRouter();
    const { authorization } = useBraintree();
    const payPalContainerRef = useRef<HTMLDivElement>();

    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [nonce, setNonce] = useState<string>(defaultValue);

    useEffect(() => {
        registerField<string>({
            name: fieldName,
            getValue: () => nonce,
            setValue: (_, value) => setNonce(value),
            clearValue: () => setNonce(null)
        });
    }, [fieldName, nonce, registerField]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const paypal = window.paypal;

            const initializePaypalSDK = async () => {
                if (!authorization) {
                    return;
                }
                try {
                    const env = isProduction() ? Environment.Production : Environment.Sandbox;
                    const paypalCheckoutInstance = await CreateBraintreeClientInstance(authorization);

                    const buttonStyle: ButtonStyle = {
                        label: ButtonLabelOption.Pay,
                        size: ButtonSizeOption.Large,
                        shape: ButtonShapeOption.Rect,
                        color: ButtonColorOption.Blue,
                        fundingicons: false,
                        tagline: false
                    };

                    const paymentOptions: PayPalCheckoutCreatePaymentOptions = {
                        flow: FlowType.Vault,
                        currency: 'BRL',
                        enableShippingAddress: true,
                        shippingAddressEditable: false,
                        intent: Intent.Authorize
                    };

                    const createPayment = () => {
                        return paypalCheckoutInstance.createPayment(paymentOptions);
                    };

                    const onAuthorize = async (data: AuthorizationData) => {
                        const payload = await paypalCheckoutInstance.tokenizePayment(data);

                        setNonce(payload.nonce);

                        env === Environment.Sandbox && console.log('Authorize', payload);

                        onAuthorizePayment(payload.nonce);
                        return payload;
                    };

                    const onCancel = (data: CancellationData) => {
                        env === Environment.Sandbox && console.log('Checkout payment cancelled', data);
                        setNonce(null);
                    };

                    const onError = (checkoutError?: string) => {
                        env === Environment.Sandbox && console.error('Checkout error: ', checkoutError);
                        setNonce(null);
                    };

                    paypal.Button.render(
                        {
                            env,
                            locale: locale.replace('-', '_') || 'pt_BR',
                            commit: true,
                            style: buttonStyle,
                            payment: createPayment,
                            onAuthorize,
                            onCancel,
                            onError
                        },
                        payPalContainerRef.current
                    );
                } catch (checkoutError) {
                    captureException(checkoutError);
                    setNonce(null);
                    logger.log(checkoutError);
                }
            };

            initializePaypalSDK();
        }

        return () => {
            const braintreeFrame = document.querySelector('.braintree-dispatch-frame');

            if (braintreeFrame) {
                document.body.removeChild(braintreeFrame);
            }
        };
    }, [authorization, locale, onAuthorizePayment]);

    return <PayPalContainerStyled show={show} ref={payPalContainerRef} data-validate={error} />;
};
