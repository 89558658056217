import { ReactNode } from 'react';
import { Card, Field } from '@components';
import { useTranslator } from '@hooks';
import { PaymentMethodTypeEnum } from '@models';
import { useCheckout, usePaymentMethods } from '@providers';
import { useField } from '@unform/core';
import {
    addDays,
    BANK_SLIP_COMPENSATION_OFFSET,
    CompareDateResult,
    compareDates,
    convertStringToDate,
    formatDate,
    getCardFlagName,
    HiddenCreditCardNumberMask
} from '@utils';
import * as CardBrands from '~/assets/svg/credit-card-brand';
import { CurrentSubscriptionCardBodyStyled, FieldStyled, RowStyled } from '~/components/containers/checkout/styles';
import { CreditCardNumberStyled } from './styles';

const TARGET_LENGTH_OF_THE_VALUE = 2;

export const CurrentPaymentMethod = () => {
    const { subscription, subscriptionFormData } = useCheckout();
    const { creditCardForm, bankSlipForm, paypalForm } = useTranslator().checkoutPage.subscriptionPayment;
    const { paymentMethods } = usePaymentMethods();
    const { error } = useField('payPal.nonce');

    const subscriptionPayment = { ...subscriptionFormData?.subscriptionPayment };

    const {
        expirationMonth = null,
        expirationYear = null,
        cardholderName,
        number: creditCardNumber = null
    } = subscriptionPayment?.creditCard || {};

    // eslint-disable-next-line eqeqeq
    const currentPaymentMethod = paymentMethods?.find((method) => method.type == subscriptionPayment?.paymentMethod);
    let nextInvoiceDate = null;
    let flagSVG: ReactNode = null;

    if (currentPaymentMethod) {
        const { type: paymentMethodType } = currentPaymentMethod;

        if (paymentMethodType === PaymentMethodTypeEnum.BankSlip) {
            nextInvoiceDate = convertStringToDate(
                subscriptionPayment?.bankSlip?.firstDueDate ?? new Date().toISOString()
            );

            if (compareDates(nextInvoiceDate, new Date()) === CompareDateResult.Minor) {
                nextInvoiceDate = addDays(new Date(), BANK_SLIP_COMPENSATION_OFFSET);
            }
        }

        if (paymentMethodType === PaymentMethodTypeEnum.CreditCard) {
            const cardFlag = getCardFlagName(`${creditCardNumber}`) || subscription?.card?.brand;

            if (cardFlag && CardBrands[cardFlag]) {
                const CardFlagSVG = CardBrands[cardFlag];
                flagSVG = <CardFlagSVG />;
            }
        }
    }

    return (
        <Card>
            <CurrentSubscriptionCardBodyStyled>
                <RowStyled>
                    <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 12 }}>
                        <Field
                            name={String(creditCardForm.chosenPayment.label)}
                            value={currentPaymentMethod?.name}
                            error={
                                error &&
                                currentPaymentMethod?.type === PaymentMethodTypeEnum.Gateway &&
                                String(paypalForm.updateRequired)
                            }
                        />
                    </FieldStyled>
                </RowStyled>

                {currentPaymentMethod?.type === PaymentMethodTypeEnum.BankSlip && (
                    <RowStyled>
                        <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 12 }}>
                            <Field
                                name={String(bankSlipForm.firstDueDate.label)}
                                value={formatDate(nextInvoiceDate, 'short')}
                            />
                        </FieldStyled>
                    </RowStyled>
                )}

                {currentPaymentMethod?.type === PaymentMethodTypeEnum.CreditCard && (
                    <>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 5 }}>
                                <Field
                                    name={String(creditCardForm.number.label)}
                                    value={
                                        creditCardNumber && (
                                            <CreditCardNumberStyled>
                                                <span>{HiddenCreditCardNumberMask(creditCardNumber)}</span>
                                                {flagSVG}
                                            </CreditCardNumberStyled>
                                        )
                                    }
                                />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 7 }}>
                                <Field name={String(creditCardForm.cardholderName.label)} value={cardholderName} />
                            </FieldStyled>
                        </RowStyled>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field
                                    name={String(creditCardForm.expiration.month.label)}
                                    value={expirationMonth?.toString()?.padStart(TARGET_LENGTH_OF_THE_VALUE, '0')}
                                />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(creditCardForm.expiration.year.label)} value={expirationYear} />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 4 }}>
                                <Field name={String(creditCardForm.cvv.label)} value='***' />
                            </FieldStyled>
                        </RowStyled>
                    </>
                )}
            </CurrentSubscriptionCardBodyStyled>
        </Card>
    );
};
