import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { FloatingOverlay } from '@floating-ui/react';
import { Button } from '../button';
import { defaultHeadingStyle, Heading } from '../heading';
import { Text } from '../text';

const FLOATING_OVERLAY_OPACITY = 0.22;

export const FloatingOverlayStyled = styled(FloatingOverlay)<{ isBlurredBackground: boolean }>`
    background-color: ${({ theme }) => rgba(theme.colors.eminencePurple, FLOATING_OVERLAY_OPACITY)};
    ${({ isBlurredBackground }) =>
        isBlurredBackground &&
        css`
            backdrop-filter: blur(1px);
        `};
    z-index: 9999;
    display: flex;
    justify-content: center;
    padding: 1.6rem;
`;

export const DialogHeadingStyled = styled(Heading).attrs({ as: 'h2', ...defaultHeadingStyle.h5 })`
    text-align: center;
`;

export const DialogDescriptionStyled = styled(Text).attrs({ size: 'md' })`
    color: ${({ theme }) => theme.colors.lightBlack};
    text-align: center;
`;

export const DialogContentStyled = styled.div`
    height: fit-content;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    margin-top: 25.7vh;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const DialogCloseButtonStyled = styled(Button)`
    padding: 0;
    border: none;
    height: 3.6rem;
    width: 3.6rem;
    background: transparent;
    color: ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
        background: transparent;
        color: ${({ theme }) => theme.colors.darkGray};
    }
`;

export const DialogFooterStyled = styled.footer`
    display: flex;
    margin-top: 2.4rem;
    gap: 3.6rem;

    button {
        width: 100%;
    }
`;
