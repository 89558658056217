import { ElementType } from 'react';
import styled, { css, StyledComponentPropsWithRef } from 'styled-components';
import { ThemeColor } from '~/styles/theme';
import { FontSizes, LineHeights, FontWeights } from '~/styles/tokens';

type Props = {
    size?: FontSizes & string;
    lineHeight?: LineHeights & string;
    fontWeight?: FontWeights & string;
    as?: ElementType;
    color?: ThemeColor & string;
};

type HeadingStyleData = {
    size: FontSizes;
    lineHeight: LineHeights;
    fontWeight: FontWeights;
};

type HeadingKeys = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type HeadingStyle = {
    [key in HeadingKeys]: HeadingStyleData;
};

export const defaultHeadingStyle: HeadingStyle = {
    h1: { size: '6xl', lineHeight: 'short', fontWeight: 'semiBold' },
    h2: { size: '6xl', lineHeight: 'short', fontWeight: 'regular' },
    h3: { size: '5xl', lineHeight: 'tall', fontWeight: 'semiBold' },
    h4: { size: '4xl', lineHeight: 'shorter', fontWeight: 'regular' },
    h5: { size: '2xl', lineHeight: 'shorter', fontWeight: 'semiBold' },
    h6: { size: 'lg', lineHeight: 'short', fontWeight: 'semiBold' }
};

const getHeadingStyle = (as: HeadingKeys | string): HeadingStyleData => {
    const style = defaultHeadingStyle[as];

    return style || { size: '6xl', lineHeight: 'short', fontWeight: 'regular' };
};

export const Heading = styled.h2<Props>`
    ${({ theme, size, fontWeight, lineHeight, as = 'h2', color }) => css`
        color: ${theme.colors[color ?? 'licorice'] ?? color};
        font-size: ${theme.fontSizes[size ?? getHeadingStyle(as as string).size] ?? size};
        line-height: ${theme.lineHeights[lineHeight ?? getHeadingStyle(as as string).lineHeight] ?? lineHeight};
        font-family: ${theme.fonts.primary};
        font-weight: ${theme.fontWeights[fontWeight ?? getHeadingStyle(as as string).fontWeight] ?? fontWeight};
    `};
`;

Heading.displayName = 'Heading';
export type HeadingProps = StyledComponentPropsWithRef<typeof Heading> & Props;
