import { ReactNode } from 'react';
import { CardText, ToggleSwitch, Tooltip, TooltipTrigger } from '@components';
import { useTranslator } from '@hooks';
import { CustomTriggerStatus, SubToolConfigurationStatusEnum, SubToolIdEnum } from '@models';
import { useAutomationRulerProvider } from '@providers';
import { Form } from '@unform/web';
import { TooltipContent } from '~/components/elements/tooltip/content';
import theme from '~/styles/theme';
import { HeaderStyled, TitleStyled, IconContainerStyled } from './styles';

type AutomationRulerHeaderProps = {
    children?: ReactNode;
    name?: string;
    messageSequenceId?: number;
};

export const AutomationRulerHeader = ({ messageSequenceId, children, name }: AutomationRulerHeaderProps) => {
    const {
        automationRuler,
        subToolConfiguration,
        changeSubToolStatus,
        isChangingStatus,
        customTrigger,
        isCustomTriggerChangingStatus,
        changeCustomTriggerStatus
    } = useAutomationRulerProvider((context) => ({
        automationRuler: context.automationRuler,
        subToolConfiguration: context.subToolConfiguration,
        changeSubToolStatus: context.changeSubToolStatus,
        isChangingStatus: context.isChangingStatus,
        changeCustomTriggerStatus: context.changeCustomTriggerStatus,
        customTrigger: context.customTrigger,
        isCustomTriggerChangingStatus: context.isCustomTriggerChangingStatus
    }));

    const {
        status,
        subTool: { id: subToolId }
    } = subToolConfiguration;

    const {
        title,
        description,
        subTool: { icon, key },
        showWarningNotSubToolConfigured
    } = automationRuler;

    const {
        automationRulerHeader: { inactiveTooltipDescription }
    } = useTranslator().pages.myAutomations;

    const handleChangeStatus = (checked: boolean) => {
        if ((!customTrigger && isChangingStatus) || (customTrigger && isCustomTriggerChangingStatus)) {
            return;
        }

        if (customTrigger) {
            !showWarningNotSubToolConfigured() &&
                changeCustomTriggerStatus(
                    checked ? CustomTriggerStatus.Active : CustomTriggerStatus.Inactive,
                    customTrigger.id
                );

            return;
        }

        !showWarningNotSubToolConfigured() &&
            changeSubToolStatus(
                checked ? SubToolConfigurationStatusEnum.Active : SubToolConfigurationStatusEnum.Inactive,
                subToolId === SubToolIdEnum.CustomTrigger ? messageSequenceId : subToolConfiguration.id
            );
    };

    const disabledSubTool = status === SubToolConfigurationStatusEnum.SendingsPaused;
    const isActive = !customTrigger
        ? status === SubToolConfigurationStatusEnum.Active
        : customTrigger.status === CustomTriggerStatus.Active;

    const toggleName = customTrigger ? `${customTrigger.name}.isActive` : `${subToolId}.isActive`;

    return (
        <HeaderStyled type='group'>
            <div>
                <IconContainerStyled subToolColor={key}>
                    <Form onSubmit={() => null}>
                        <Tooltip open={disabledSubTool ? null : false}>
                            <TooltipTrigger>
                                <ToggleSwitch
                                    color={theme.colors.eminencePurple}
                                    name={toggleName}
                                    checked={isActive}
                                    disabled={disabledSubTool}
                                    enableUnform={false}
                                    onChange={(event) => handleChangeStatus(event.target.checked)}
                                />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{String(inactiveTooltipDescription)}</p>
                            </TooltipContent>
                        </Tooltip>
                    </Form>
                    <i>{icon}</i>
                </IconContainerStyled>
                <TitleStyled>
                    <CardText type='title' value={name || title} />
                    <CardText type='description' value={description} />
                </TitleStyled>
            </div>
            {children}
        </HeaderStyled>
    );
};
