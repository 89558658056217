import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { RadioButtonLabelStyled, RadioButtonLabelTextStyled, RadioInputStyled } from './styles';

export type RadioButtonProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'type'> & {
    value: number;
    children: ReactNode;
    testId?: string;
};

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
    ({ children, value, testId, ...props }, ref) => {
        return (
            <RadioButtonLabelStyled htmlFor={String(value)} testId={testId}>
                <RadioInputStyled {...props} ref={ref} value={value} id={String(value)} />
                <RadioButtonLabelTextStyled>{children}</RadioButtonLabelTextStyled>
            </RadioButtonLabelStyled>
        );
    }
);

RadioButton.displayName = 'RadioButton';
