import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslatedRoute } from '@hooks';
import { AutomationToolUsageSummary, SubToolIdEnum } from '@models';
import { getSubToolPagePathById, replace } from '@utils';
import { routesName } from '~/locales/route';
import { GetSubToolNameById } from '~/services/subToolApiService';
import { MarketingAutomationResultStatus } from './marketingAutomationResult';

type RedirectToSubToolLink = {
    href?: string;
    as?: string;
    hasLink: boolean;
};

const getMarketingAutomationStatus = (subTool: AutomationToolUsageSummary) => {
    let status: MarketingAutomationResultStatus = subTool.hasTemplates ? 'active' : 'noTemplateFound';

    if (!subTool.isActive) {
        status = 'inactive';
    }

    return status;
};

const getMarketingAutomationSubToolRedirectLink = (
    subToolId: SubToolIdEnum,
    status: MarketingAutomationResultStatus,
    locale: string
) => {
    let routePath: string = routesName.tool.automationTool;
    let newRoute: string = routesName.subTool.subTool;

    let params: { [key: string]: string } = {
        subTool: GetSubToolNameById(subToolId, locale),
        subToolPagePath: getSubToolPagePathById(subToolId),
        params: ''
    };

    if (status === 'noTemplateFound') {
        routePath = routesName.subTool.baseTemplate;
        newRoute = routesName.subTool.template;

        routePath = replace(
            routePath,
            { subToolPagePath: getSubToolPagePathById(subToolId) },
            { start: '{', end: '}' }
        );

        if (subToolId === SubToolIdEnum.Repurchase) {
            routePath = routesName.subTool.repurchase.itemConfiguration;
            newRoute = routesName.subTool.repurchase.itemConfiguration;

            params = {
                params: ''
            };
        }
    }

    return {
        href: routePath,
        as: newRoute,
        params
    };
};

export const useMarketingAutomation = (subTool: AutomationToolUsageSummary) => {
    const translateRoute = useTranslatedRoute();
    const router = useRouter();

    const [status, setStatus] = useState<MarketingAutomationResultStatus>(() => getMarketingAutomationStatus(subTool));
    const [redirectToSubToolLink, setRedirectToSubToolLink] = useState<RedirectToSubToolLink>({ hasLink: false });

    useEffect(() => {
        const newStatus = getMarketingAutomationStatus(subTool);

        if (newStatus === 'active') {
            setStatus(newStatus);
            return;
        }

        const { href, as, params } = getMarketingAutomationSubToolRedirectLink(
            subTool.subToolId,
            newStatus,
            router.locale
        );

        const translatedRoutePath = translateRoute(as, params);

        setRedirectToSubToolLink({ href, as: translatedRoutePath, hasLink: true });
        setStatus(newStatus);
    }, [subTool, translateRoute, router]);

    return {
        status,
        redirectToSubToolLink
    };
};
