import styled from 'styled-components';
import { dataValidateStyle } from '~/styles';
import { Text } from '../text';

type FieldValueStyledProps = {
    empty?: boolean;
};

export const FieldStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    ${dataValidateStyle}
`;

export const FieldNameStyled = styled(Text)``;

export const FieldValueStyled = styled(Text)<FieldValueStyledProps>`
    color: ${({ empty, theme }) => (empty ? theme.colors.lightGray : 'currentColor')};
`;
