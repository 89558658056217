import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Sets state on Local Storage.
 *
 * @param key The local storage key value.
 * @param initialState The initial state value.
 * @returns Returns a stateful value of <T>, and a function to update it.
 */
export function useLocalStorageState<T>(key: string, initialState: T): [T, Dispatch<SetStateAction<T>>] {
    const [state, setState] = useState(() => {
        if (window) {
            const storageValue = localStorage.getItem(key);

            if (storageValue) {
                return JSON.parse(storageValue);
            }
        }

        return initialState;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
}

export default useLocalStorageState;
