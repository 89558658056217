import styled from 'styled-components';
import { Card } from '~/components/elements/card';
import { TooltipContent } from '~/components/elements/tooltip';

export const ActionButtonsContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.6rem;
`;

export const ContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    margin-bottom: 10rem;

    & > button {
        width: fit-content;
    }
`;

export const CardStyled = styled(Card).attrs(({ theme }) => ({ borderColor: theme.subToolColors.customTrigger }))`
    padding: 3.2rem;
    display: flex;
    flex-direction: column;

    & > header {
        padding: 0;
        margin-bottom: 3.2rem;
    }

    & > main {
        padding: 0;
        flex: 1;
        gap: 4.5rem;
        max-width: 78rem;

        h6 {
            margin-bottom: 1.6rem;
        }
    }
`;

export const TooltipContentStyled = styled(TooltipContent)`
    max-width: 20.8rem;
`;
