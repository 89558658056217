export enum VerificationStatus {
    Error = 0,
    Pending = 1,
    Active = 2
}

export type EmailConfiguration = {
    verificationStatus: VerificationStatus;
    verificationErrorMessage?: string;
    domain: string;
};
