import styled from 'styled-components';
import { DefaultLayoutContentStyled, DefaultLayoutStyled } from '../styles';

export const CheckoutLayoutStyled = styled(DefaultLayoutStyled)`
    .customer-alert {
        margin-top: 3.2rem;
    }
`;

export const LayoutContentStyled = styled(DefaultLayoutContentStyled)``;
