import { Modal, ModalBody, ModalHeader } from '@components';
import { useTranslator } from '@hooks';
import { ACCEPT_TERMS_LINK } from '@utils';
import { TermsAndConditionModalStyled } from './styles';

type TermsAndConditionModalProps = {
    onClose?: () => void;
    isOpen?: boolean;
};
export const TermsAndConditionModal = ({ onClose, isOpen }: TermsAndConditionModalProps) => {
    const { title } = useTranslator().checkoutPage.invoiceDetails.subgroups.acceptTerms;

    return (
        <Modal isOpen={isOpen} title={String(title)} onClose={onClose}>
            <ModalHeader showCloseButton closeButtonTestId={`button__close-terms-and-conditions`}>
                {title}
            </ModalHeader>
            <ModalBody>
                <TermsAndConditionModalStyled>
                    <object data={ACCEPT_TERMS_LINK} type='application/pdf'>
                        <embed src={ACCEPT_TERMS_LINK} type='application/pdf' />
                    </object>
                </TermsAndConditionModalStyled>
            </ModalBody>
        </Modal>
    );
};
