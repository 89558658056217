import {
    ContactConfigurationStatusEnum,
    ManualContactSubToolTemplateConfiguration,
    MessageSequenceStep,
    MessageSequenceStepCreate,
    MessageSequenceStepUpdate,
    MessageSequenceType,
    RepurchaseConsideredPeriodEnum,
    SendingTechnologyTypeEnum,
    ShippingOrderEnum,
    SubToolConfiguration,
    SubToolConfigurationStatusEnum,
    SubToolId,
    SubToolIdEnum,
    SubToolTemplateConfiguration,
    SubToolTemplateConfigurationTypeEnum,
    TrackAnalytics
} from '@models';
import { subToolParamsTranslator } from '~/locales/route';
import { WhatsAppTemplate } from '~/models/whatsapp';
import { axiosClient } from './apiService';

export type DeactivateSubToolTemplatePayload = {
    subToolId?: SubToolId;
    shippingOrder: ShippingOrderEnum;
    type: MessageSequenceType | SubToolTemplateConfigurationTypeEnum;
    identifier?: string;
};

export type CreateSubToolConfigurationContactsPayload = {
    id?: number;
    subToolConfigurationId: number;
    senderName: string;
    senderContact: string;
    contactAnswer?: string;
    status: ContactConfigurationStatusEnum;
    sendingTechnology: SendingTechnologyTypeEnum;
};

export type CreateSubToolConfigurationPayload = {
    id: number;
    repurchaseConsideredPeriod: RepurchaseConsideredPeriodEnum;
    subToolId: number;
    status: SubToolConfigurationStatusEnum;
    contactConfigurations: Array<CreateSubToolConfigurationContactsPayload>;
};

export type SubToolTemplatePayload = {
    subToolConfigurationId: number;
    subToolId: number;
    id?: number;
    baseThemeId?: number;
    shippingOrder: ShippingOrderEnum;
    frequencyInMinutes: number;
    subject?: string;
    baseContent?: string;
    productContent?: string;
    reserveProductContent?: string;
    reviewContent?: string;
    isActive: boolean;
    trackAnalytics: TrackAnalytics;
    itemsPerLine: number;
    sendingTechnologyType: SendingTechnologyTypeEnum;
    type: SubToolTemplateConfigurationTypeEnum;
    identifier?: string;
};

export type EmailAddress = {
    email: string;
    displayName?: string;
};

export type TestEmailRequestPayload = {
    to: EmailAddress;
    subToolId?: SubToolIdEnum;
    subject: string;
    template: string;
    recaptchaToken?: string;
};

export type TestSmsRequestPayload = {
    to: string;
    text: string;
    recaptchaToken: string;
};

export type TestWhatsAppRequestPayload = {
    to: string;
    templateId: string;
    subToolId: number;
    tagAnalytics: Record<string, string>;
};

export type TestEmailResponsePayload = {
    success: boolean;
};

type TestSmsResponsePayload = {
    success: boolean;
};

export const GetSubToolIdByName = (
    subToolName: number | string,
    locale = process.env.NEXT_PUBLIC_DEFAULT_CULTURE
): null | number => {
    if (!subToolName) {
        return null;
    }

    if (!isNaN(Number(subToolName.toString()))) {
        const hasSubTool = !!Object.keys(SubToolIdEnum).find((key) => key === subToolName);

        if (!hasSubTool) {
            return null;
        }

        return Number(subToolName);
    }

    const translatedSubToolId = subToolParamsTranslator[locale][subToolName];
    if (!translatedSubToolId) {
        return null;
    }

    return translatedSubToolId;
};

export const GetSubToolNameById = (subToolId: number, locale = process.env.NEXT_PUBLIC_DEFAULT_CULTURE) => {
    if (!subToolId) {
        return null;
    }

    const subTool = Object.entries(subToolParamsTranslator[locale]).find(([, value]) => value === subToolId);

    if (!subTool || subTool.length < 1) {
        return null;
    }

    return subTool[0];
};

export const GetSubToolConfigurationsBySubToolIdAsync = async (subToolId: number) => {
    return axiosClient.get<SubToolConfiguration>(`/sub-tool/configuration/${subToolId}/summary`);
};

export const GetSubToolConfigurationAsync = async (subToolId: number) => {
    return axiosClient.get<SubToolConfiguration>('/sub-tool/configuration', {
        params: {
            subToolId
        }
    });
};

export const GetAllAvailableSubToolConfigurationAsync = async () => {
    return axiosClient.get<Array<SubToolConfiguration>>('/sub-tool/configuration/all-available');
};

export const UpdateSubToolConfigurationAsync = async (payload: CreateSubToolConfigurationPayload) => {
    return axiosClient.put<SubToolConfiguration>('/sub-tool/configuration', payload);
};

export const CreateSubToolConfigurationAsync = async (payload: Omit<CreateSubToolConfigurationPayload, 'id'>) => {
    return axiosClient.post<SubToolConfiguration>('/sub-tool/configuration', payload);
};

export const GetSubToolTemplateConfigurationByIdAsync = async (subToolTemplateConfigurationId: number) => {
    return axiosClient.get<SubToolTemplateConfiguration>('sub-tool/configuration/templates', {
        params: { subToolTemplateConfigurationId }
    });
};

export const GetSubToolSendingTechnologyTypesFromStoreSubscriptionAsync = async (subToolId: number) => {
    return axiosClient.get<Array<SendingTechnologyTypeEnum>>(`/tool/sub-tools/${subToolId}/sending-technologies`);
};

export const SendTestSMS = async (payload: TestSmsRequestPayload) => {
    return axiosClient.post<TestSmsResponsePayload>('subtooltemplateconfiguration/send-test-sms', payload);
};

export const SendTestEmail = async (payload: TestEmailRequestPayload) => {
    return axiosClient.post<TestEmailResponsePayload>('subtooltemplateconfiguration/send-test-email', payload);
};

export const SendTestWhatsApp = async (payload: TestWhatsAppRequestPayload) => {
    return axiosClient.post('subtooltemplateconfiguration/send-test-whatsapp', payload);
};

// #region WhatsAppManual
export const CreateManualContactSubToolTemplateConfiguration = async (
    payload: ManualContactSubToolTemplateConfiguration
) => {
    return await axiosClient.post<SubToolTemplateConfiguration>('/sub-tool/configuration/templates/manual', payload);
};

export const UpdateManualContactSubToolTemplateConfiguration = async (
    payload: ManualContactSubToolTemplateConfiguration
) => {
    return await axiosClient.put<SubToolTemplateConfiguration>('/sub-tool/configuration/templates/manual', payload);
};
// #endregion

// TODO: Remover Todos esses métodos abaixo, usar o useSubToolPage para executar as operações abaixo
export const GetMessageSequenceAsync = async (subtoolPath: string, sequenceId: number) => {
    return axiosClient.get<Array<MessageSequenceStep>>(
        `/sub-tool/${subtoolPath}/message-sequences/${sequenceId}/steps`
    );
};

export const GetMessageSequenceStepAsync = async (subtoolPath: string, stepId: number) => {
    return axiosClient.get<MessageSequenceStep>(`/sub-tool/${subtoolPath}/message-sequences/steps/${stepId}`);
};

export const CreateMessageSequenceStepAsync = async (subtoolPath: string, payload: MessageSequenceStepCreate) => {
    return axiosClient.post<boolean>(`/sub-tool/${subtoolPath}/message-sequences/steps`, payload);
};

export const UpdateMessageSequenceStepAsync = async (subtoolPath: string, payload: MessageSequenceStepUpdate) => {
    return axiosClient.put<MessageSequenceStep>(`/sub-tool/${subtoolPath}/message-sequences/steps/`, payload);
};

export const DeleteMessageSequenceStepAsync = async (subtoolPath: string, stepId: number) => {
    return axiosClient.delete<boolean>(`/sub-tool/${subtoolPath}/message-sequences/steps/${stepId}`);
};

export const DuplicateMessageSequenceStepAsync = async (subtoolPath: string, stepId: number) => {
    return axiosClient.post<MessageSequenceStep>(
        `/sub-tool/${subtoolPath}/message-sequences/steps/${stepId}/duplicate`
    );
};

export const GetWhatsAppTemplatesUrl = '/sub-tool/configuration/templates/whatsapp';
export const GetWhatsAppTemplatesAsync = async () => axiosClient.get<WhatsAppTemplate[]>(GetWhatsAppTemplatesUrl);
