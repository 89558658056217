import { produce } from 'immer';
import _uniqBy from 'lodash/uniqBy';
import {
    CustomerPlanType,
    SendingTechnology,
    SendingTechnologyTypeEnum,
    SubscriptionTool,
    ToolTypeEnum
} from '@models';

export type SendingTechnologyPrice = {
    toolType: ToolTypeEnum;
    type: SendingTechnologyTypeEnum;
    price: number;
};

const automationToolIds = [
    CustomerPlanType.AutomationTool,
    CustomerPlanType.CartRecovery,
    CustomerPlanType.BankSlipReminder
];

/**
 * Creates a support list to calculate the values of each sending technology
 *
 * @param subscriptionTools Customer subscription toollist
 * @returns An object containing a list of the types of tools contracted and a list of objects with the type of technology and value
 */
const makeSubscriptionToolPriceByAutomationType = (subscriptionTools: SubscriptionTool[]) => {
    const automationToolsTypes = subscriptionTools.map((tool) => tool.type);
    const subscriptionToolPrices: Array<SendingTechnologyPrice> = [];

    if (
        automationToolsTypes.includes(CustomerPlanType.CartRecovery) ||
        automationToolsTypes.includes(CustomerPlanType.AutomationTool)
    ) {
        subscriptionToolPrices.push(
            { toolType: ToolTypeEnum.AutomationTool, type: SendingTechnologyTypeEnum.Email, price: 0 },
            { toolType: ToolTypeEnum.AutomationTool, type: SendingTechnologyTypeEnum.Push, price: 0 }
        );
    }

    if (automationToolsTypes.includes(CustomerPlanType.BankSlipReminder)) {
        subscriptionToolPrices.push({
            toolType: ToolTypeEnum.AutomationTool,
            type: SendingTechnologyTypeEnum.Email,
            price: 0
        });
    }

    if (automationToolsTypes.includes(CustomerPlanType.BulkSendingTool)) {
        subscriptionToolPrices.push({
            toolType: ToolTypeEnum.BulkSending,
            type: SendingTechnologyTypeEnum.Email,
            price: 0
        });
    }

    const noDuplicationsSubscriptionToolPrices = [
        ..._uniqBy(subscriptionToolPrices, (value) => [value.toolType, value.type].join())
    ];

    return {
        subscriptionToolPrices: noDuplicationsSubscriptionToolPrices,
        automationToolsTypes
    };
};

export const findSendingTechnologyWithClosestAmountOfCredit = (
    sendingTechnologies: Array<SendingTechnology>,
    creditAmount: number
) => {
    return sendingTechnologies.reduce<{ value: SendingTechnology; diff: number }>((result, value) => {
        const diff = Math.abs(value.creditAmount - creditAmount);

        if (result === null || diff < result.diff) {
            return { value, diff };
        }

        return result;
    }, null)?.value;
};

/**
 * Calculates the value of sending technologies by customer plan type
 * @description For old subscriptions (version less than 3.5) the structure of tools and prices is different,
 * in this case this function was created to calculate and return the values according to the new structure
 *
 * If the customer has hired cart and boleto or just boleto the total amount goes to the "E-mail" technology.
 * In this case the sms and push technology are free.
 *
 * For the case of the customer to hire only cart the amount will be divided by two, being used for the "E-mail" and "SMS".
 * Push in turn is free
 *
 * @param subscriptionTools Customer subscription toollist
 * @returns A list containing automation tools with the value of each sending technology
 */
export const calculateSendingTechnologyPriceByCustomerPlanType = (subscriptionTools: SubscriptionTool[]) => {
    const { automationToolsTypes, subscriptionToolPrices } =
        makeSubscriptionToolPriceByAutomationType(subscriptionTools);

    const automationToolPrice = subscriptionTools
        .filter((tool) => automationToolIds.includes(tool.type))
        .reduce((price, tool) => price + tool.price, 0);

    const emailMarketingPrice = subscriptionTools.find((tool) => tool.type === CustomerPlanType.BulkSendingTool);

    let tools = subscriptionToolPrices;

    const hasCartRecovery = automationToolsTypes.includes(CustomerPlanType.CartRecovery);
    const hasBankSlip = automationToolsTypes.includes(CustomerPlanType.BankSlipReminder);
    const hasAutomationTool = automationToolsTypes.includes(CustomerPlanType.AutomationTool);

    if (automationToolPrice > 0 && (hasBankSlip || hasCartRecovery || hasAutomationTool)) {
        tools = produce(tools, (draft) => {
            const email = draft.find(
                (item) => item.toolType === ToolTypeEnum.AutomationTool && item.type === SendingTechnologyTypeEnum.Email
            );
            email.price = automationToolPrice;
        });
    }

    if (emailMarketingPrice) {
        tools = produce(tools, (draft) => {
            const email = draft.find(
                (item) => item.toolType === ToolTypeEnum.BulkSending && item.type === SendingTechnologyTypeEnum.Email
            );
            email.price = emailMarketingPrice.price;
        });
    }

    return tools;
};
