import styled, { css } from 'styled-components';
import { Card } from '~/components/elements/card';
import { RadioButtonFormGroup } from '~/components/forms/radioButtonFormGroup';
import { mobileOnly } from '~/styles';

export const CardStyled = styled(Card)``;

export const RadioButtonGroupForPaymentOptionStyled = styled(RadioButtonFormGroup)`
    ${mobileOnly(css`
        flex-direction: column;
    `)}

    span {
        color: ${({ theme }) => theme.colors.purple};
        font-family: ${({ theme }) => theme.fonts.primary};
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        gap: 1rem;

        & > div {
            display: flex;
            flex-direction: column;

            & > span {
                color: ${({ theme }) => theme.colors.lightGray};
                font-size: 1.4rem;
                display: block;
                text-align: start;
            }
        }

        & > .check-radio__icon {
            opacity: 0;
            transition: opacity 0.15s;
            width: 1.4rem;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            &[data-selected='true'] {
                opacity: 1;
            }
        }
    }

    label {
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 1.2rem;
        min-height: 6.4rem;
        min-width: 19rem;

        input {
            display: none;
        }
    }
`;
