import { minutesToMilliseconds } from 'date-fns';
import { CardHeader, Pagination, Text } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { GetAllPaginatedAsync, GetAllPaginatedURL } from '~/services/subscriptionInvoiceApiService';
import { PageTitle } from '~/styles';
import { fallbackData, MAX_PAGINATED_PAGE_SIZE } from './fallbackData';
import { Payments } from './payments';
import {
    CardStyled,
    EmptyListStyled,
    MyPaymentsContainer,
    PaymentContainerStyled,
    PaymentContentStyled,
    PaymentHeaderStyled,
    PaymentsListStyled
} from './styles';

const DEDUPING_INTERVAL_IN_MINUTES = 1;

type MyPaymentsProps = {
    page: number;
    onChangePage: (newPage: number) => void;
};

export const MyPayments = ({ onChangePage, page = 1 }: MyPaymentsProps) => {
    const {
        common: { lists },
        pages: { mySubscription }
    } = useTranslator();
    const tableHeaderTranslator = mySubscription.myPayments.table.header;

    const { data: payments, isLoading } = useFetch(
        { url: GetAllPaginatedURL, currentPage: page },
        async ({ currentPage: pageNumber }, signal) =>
            (await GetAllPaginatedAsync({ pageNumber, pageSize: MAX_PAGINATED_PAGE_SIZE }, signal)).data,
        {
            fallbackData: fallbackData,
            keepPreviousData: true,
            dedupingInterval: minutesToMilliseconds(DEDUPING_INTERVAL_IN_MINUTES)
        }
    );

    return (
        <MyPaymentsContainer>
            <PageTitle>{String(mySubscription.myPayments.title)}</PageTitle>
            <CardStyled type='group' borderColor='purple'>
                <CardHeader title={String(mySubscription.myPayments.yourInvoices)} />
                <PaymentContainerStyled>
                    <PaymentsListStyled>
                        <PaymentHeaderStyled>
                            <PaymentContentStyled>
                                {Object.entries(tableHeaderTranslator).map(([key, value]) => {
                                    return (
                                        <Text key={key} as='strong' size='md'>
                                            {String(value)}
                                        </Text>
                                    );
                                })}
                            </PaymentContentStyled>
                        </PaymentHeaderStyled>
                    </PaymentsListStyled>
                    {payments?.items?.length > 0 ? (
                        <Payments isLoading={isLoading} payments={payments.items} />
                    ) : (
                        <EmptyListStyled as='span'>{String(lists.emptyLabel)}</EmptyListStyled>
                    )}
                </PaymentContainerStyled>
                <Pagination
                    currentPage={payments?.pageNumber}
                    pageLimit={payments?.pageSize}
                    totalRecords={payments?.totalItems}
                    onPageChange={(pagination) => onChangePage(pagination.currentPage)}
                />
            </CardStyled>
        </MyPaymentsContainer>
    );
};
