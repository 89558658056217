import { ReactElement } from 'react';
import orderBy from 'lodash/orderBy';
import { getAutomationStatisticsSummaryIcons } from '@components';
import {
    GeneralStatistics,
    SendingTechnologyTrackStatistics,
    SendingTechnologyTypeEnum,
    SubToolIdEnum,
    TrackStatistics,
    CustomTriggerGeneralStatistics
} from '@models';
import localesJSON from '~/locales/locales.json';
import { divideOrFallback } from '~/utils/math';

type SummaryStatisticsType = typeof localesJSON.pages.automationStatistics.summaryStatistics;
type SubToolIdType = typeof localesJSON.enums.subToolIdEnum;

export type StatisticType = 'schedule' | 'sent' | 'opening' | 'click' | 'sale' | 'rejection';

export type TrackStatisticData = {
    type: StatisticType;
    count: number;
    amount: number;
    percentage: number;
};

export type ToolStatisticData = TrackStatisticData & {
    icon?: ReactElement;
    title: string;
    percentageLabel?: string;
    description?: string;
    isVisibleInSummary?: boolean;
};

export type SubToolStatisticData = TrackStatistics & {
    title: string;
    openingPercentage: number;
    clickPercentage: number;
    salePercentage: number;
    subToolId: number;
};

export type CustomTriggerStatisticData = Omit<SubToolStatisticData, 'subToolId'> & { customTriggerId: number };

export type GeneralStatisticsDetails = {
    toolStatistics: Array<ToolStatisticData>;
    subToolStatistics: Array<SubToolStatisticData>;
};

export const ConvertTrackStatisticsToArray = (trackStatistics: TrackStatistics) => {
    return [
        // issue: https://github.com/enviou-ti/enviou-3/issues/753#issuecomment-1593436805
        // {
        //     type: 'schedule',
        //     amount: null,
        //     count: 1000,
        //     percentage: null
        // },
        {
            type: 'sent',
            amount: null,
            count: trackStatistics.sentCount ?? 0,
            percentage: null
        },
        {
            type: 'rejection',
            amount: null,
            count: trackStatistics.rejectionCount ?? 0,
            percentage: null
        },
        {
            type: 'opening',
            amount: null,
            count: trackStatistics.openingCount ?? 0,
            percentage: divideOrFallback(trackStatistics.openingCount, trackStatistics.sentCount)
        },
        {
            type: 'click',
            amount: null,
            count: trackStatistics.clickCount ?? 0,
            percentage: divideOrFallback(trackStatistics.clickCount, trackStatistics.openingCount)
        },
        {
            type: 'sale',
            amount: trackStatistics.soldAmount > 0 ? trackStatistics.soldAmount : 0,
            count: trackStatistics.saleCount ?? 0,
            percentage: divideOrFallback(trackStatistics.saleCount, trackStatistics.clickCount)
        }
    ] as Array<TrackStatisticData>;
};

export const GenerateCustomTriggerGeneralStatisticsDetailsSummary = (
    statisticData: CustomTriggerGeneralStatistics
): Array<CustomTriggerStatisticData> => {
    return statisticData.map((data) => {
        return {
            title: data.customTriggerName,
            clickCount: data.clickCount ?? 0,
            clickPercentage: divideOrFallback(data.clickCount, data.openingCount),
            openingCount: data.openingCount ?? 0,
            openingPercentage: divideOrFallback(data.openingCount, data.sentCount),
            saleCount: data.saleCount ?? 0,
            salePercentage: divideOrFallback(data.saleCount, data.clickCount),
            sentCount: data.sentCount ?? 0,
            soldAmount: data.soldAmount ?? 0,
            rejectionCount: data.rejectionCount ?? 0,
            customTriggerId: data.customTriggerId
        };
    });
};

export const GenerateGeneralStatisticsDetailsResume = (
    statisticsData: GeneralStatistics,
    summaryStatistics: SummaryStatisticsType,
    subToolTitleListName: SubToolIdType
) => {
    const summaryIcons = getAutomationStatisticsSummaryIcons();

    const trackStatistics = ConvertTrackStatisticsToArray(statisticsData);
    const toolStatistics = trackStatistics.map((trackStatistic) => {
        const isVisibleInSummary = trackStatistic.type !== 'rejection';

        return {
            icon: summaryIcons.find((icon) => icon.type === trackStatistic.type)?.icon,
            ...trackStatistic,
            title: String(summaryStatistics[trackStatistic.type].name),
            description: String(summaryStatistics[trackStatistic.type].description),
            percentageLabel: String(summaryStatistics[trackStatistic.type]?.percentageLabel),
            isVisibleInSummary
        } as ToolStatisticData;
    });

    const subToolStatistics: Array<SubToolStatisticData> = Array.from({ length: 5 }).map((_, index) => {
        const subToolId = index + 1;
        const subToolStatistic = statisticsData?.subToolStatisticsCollection?.find(
            (item) => item.subTool.id === subToolId
        );

        if (subToolStatistic) {
            const sentCount = subToolStatistic.sentCount;
            return {
                title: String(subToolTitleListName[SubToolIdEnum[subToolStatistic.subTool.id]]),
                clickCount: subToolStatistic.clickCount ?? 0,
                clickPercentage: divideOrFallback(subToolStatistic.clickCount, subToolStatistic.openingCount),
                openingCount: subToolStatistic.openingCount ?? 0,
                openingPercentage: divideOrFallback(subToolStatistic.openingCount, sentCount),
                saleCount: subToolStatistic.saleCount ?? 0,
                salePercentage: divideOrFallback(subToolStatistic.saleCount, subToolStatistic.clickCount),
                sentCount: subToolStatistic.sentCount ?? 0,
                soldAmount: subToolStatistic.soldAmount ?? 0,
                rejectionCount: subToolStatistic.rejectionCount ?? 0,
                subToolId: subToolStatistic.subTool.id
            };
        }

        return {
            title: String(subToolTitleListName[SubToolIdEnum[subToolId]]),
            clickCount: 0,
            clickPercentage: 0,
            openingCount: 0,
            openingPercentage: 0,
            saleCount: 0,
            salePercentage: 0,
            sentCount: 0,
            soldAmount: 0,
            rejectionCount: 0,
            subToolId
        };
    });

    const generalStatisticsDetailsCollection: GeneralStatisticsDetails = {
        toolStatistics,

        subToolStatistics: orderBy(subToolStatistics, 'title')
    };

    return generalStatisticsDetailsCollection;
};

export const GetDetailedGeneralStatisticsBySendingTechnology = (
    statistics: Array<SendingTechnologyTrackStatistics>,
    fallbackSendingTechnologies: Array<SendingTechnologyTypeEnum>,
    sendingTecnologyTranslation: unknown
) => {
    const techologiesTypes: Array<SendingTechnologyTypeEnum> =
        fallbackSendingTechnologies?.length > 0
            ? fallbackSendingTechnologies
            : Object.values(SendingTechnologyTypeEnum)
                  .filter((value) => typeof value === 'number' && value !== SendingTechnologyTypeEnum.WhatsAppManual)
                  .map((value) => Number(value));

    return techologiesTypes.map((sendingTechnologyId) => {
        const sendingTechnology = statistics?.find((item) => item.sendingTechnologyType === sendingTechnologyId);

        if (sendingTechnology) {
            return {
                data: ConvertTrackStatisticsToArray(sendingTechnology),
                sendingTechnologyType: sendingTechnology.sendingTechnologyType,
                sendingTechnologyName: String(
                    sendingTecnologyTranslation[
                        SendingTechnologyTypeEnum[sendingTechnology.sendingTechnologyType].toLowerCase()
                    ]
                )
            };
        }

        // fallback data
        return {
            data: ConvertTrackStatisticsToArray({
                clickCount: 0,
                openingCount: 0,
                rejectionCount: 0,
                saleCount: 0,
                sentCount: 0,
                soldAmount: 0
            }),
            sendingTechnologyType: sendingTechnologyId,
            sendingTechnologyName: String(
                sendingTecnologyTranslation[SendingTechnologyTypeEnum[sendingTechnologyId].toLowerCase()]
            )
        };
    });
};

export const fakeSendingTechnologyTrackStatistics: Array<SendingTechnologyTrackStatistics> = [
    {
        sendingTechnologyType: SendingTechnologyTypeEnum.Email,
        clickCount: 0,
        openingCount: 0,
        rejectionCount: 0,
        saleCount: 0,
        sentCount: 0,
        soldAmount: 0
    },
    {
        sendingTechnologyType: SendingTechnologyTypeEnum.SMS,
        clickCount: 0,
        openingCount: 0,
        rejectionCount: 0,
        saleCount: 0,
        sentCount: 0,
        soldAmount: 0
    }
];
