import {
    MessageSequence,
    MessageSequenceStep,
    PaginatedList,
    SubToolConfigurationStatusEnum,
    ItemConfigurationSort,
    MessageSequenceType,
    RepurchaseCategory,
    RepurchaseProduct,
    RepurchaseStore
} from '@models';
import { axiosClient } from './apiService';

type GetRepurchaseItemConfigurationProps = {
    page?: number;
    quantityPerPage?: number;
    search?: string;
    sort?: ItemConfigurationSort;
};

export type UpdateSubToolItemConfigurationPayload = {
    id: string;
    isActive: boolean;
    manualAverageRepurchasePeriod: number;
    templateType: MessageSequenceType;
};

const repurchaseRoute = '/sub-tool/repurchase';
export const getMessageSequencesUrl = `${repurchaseRoute}/message-sequences`;
export const getSummaryMessageSequenceUrl = `${repurchaseRoute}/message-sequence/summary`;
export const repurchaseStoresWithConfigurationRoute = `${repurchaseRoute}/configuration/stores`;
export const repurchaseCategoriesWithConfigurationRoute = `${repurchaseRoute}/configuration/categories`;
export const repurchaseProductsWithConfigurationRoute = `${repurchaseRoute}/configuration/products`;

export const GetMessageSequencesAsync = async (page = 1, pageSize = 3, signal: AbortSignal = null) => {
    return axiosClient.get<PaginatedList<MessageSequence>>(getMessageSequencesUrl, {
        signal,
        params: { page, pageSize }
    });
};

export const GetSummaryMessageSequence = async (messageSequenceType: MessageSequenceType, identifier: string) => {
    return axiosClient.get<MessageSequence>(getSummaryMessageSequenceUrl, {
        params: {
            messageSequenceType,
            identifier
        }
    });
};

export const GetRepurchaseStoresWithConfigurationAsync = async () => {
    return axiosClient.get<PaginatedList<RepurchaseStore>>(repurchaseStoresWithConfigurationRoute);
};

export const UpdateRepurchaseStoreConfigurationAsync = async (
    payload: Omit<UpdateSubToolItemConfigurationPayload, 'id'>
) => {
    return axiosClient.put(repurchaseStoresWithConfigurationRoute, payload);
};

export const GetRepurchaseCategoriesWithConfigurationAsync = async ({
    search,
    ...params
}: Omit<GetRepurchaseItemConfigurationProps, 'subToolId'>) => {
    return axiosClient.get<PaginatedList<RepurchaseCategory>>(repurchaseCategoriesWithConfigurationRoute, {
        params: { categoryName: search, ...params }
    });
};

export const UpdateRepurchaseCategoryConfigurationAsync = async (payload: UpdateSubToolItemConfigurationPayload) => {
    return axiosClient.put(repurchaseCategoriesWithConfigurationRoute, payload);
};

export const GetRepurchaseProductsWithConfigurationAsync = async ({
    search,
    ...params
}: Omit<GetRepurchaseItemConfigurationProps, 'subToolId'>) => {
    return axiosClient.get<PaginatedList<RepurchaseProduct>>(repurchaseProductsWithConfigurationRoute, {
        params: {
            ...params,
            searchProduct: search
        }
    });
};

export const UpdateRepurchaseProductConfigurationAsync = async (payload: UpdateSubToolItemConfigurationPayload) => {
    return axiosClient.put(repurchaseProductsWithConfigurationRoute, payload);
};

export const DeleteMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.delete<boolean>(`/sub-tool/repurchase/message-sequences/steps/${stepId}`);
};

export const DuplicateMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.post<MessageSequenceStep>(`/sub-tool/repurchase/message-sequences/steps/${stepId}/duplicate`);
};

export const ChangeStatusAsync = async (newStatus: SubToolConfigurationStatusEnum) => {
    return axiosClient.patch<boolean>('/sub-tool/repurchase/configuration/status', { newStatus });
};
