import { useRef, useState } from 'react';
import { Button, Link, Text } from '@components';
import { useTranslator } from '@hooks';
import { AlertOptions, useAlert } from '@providers';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Dialog, DialogClose, DialogDescription, DialogFooter, DialogHeading } from '~/components/elements/dialog';
import { routesName } from '~/locales/route';
import { SubmitContactListAsync, SubmitContactListPayload } from '~/services/customTriggerApiService';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { EnviouApiUnprocessableEntityError } from '~/services/errors/enviouApiUnprocessableEntityError';
import { ImportContactErrorStatus } from '~/services/errors/statusError';
import { ContactImportedSuccessfullyModal } from '../contactImportedSuccessfullyModal';
import { FieldItem } from './fieldItem';
import { generateContactListAsync, GenerateContactListError } from './generateContactList';
import { DialogContentStyled, MappedFieldsListStyled } from './styles';

type ImportData = {
    file?: Blob;
    fileLineContent?: Array<Array<string>>;
};

type ImportContactInfoModalProps = ImportData & {
    options: AlertOptions;
    sequenceId: number;
};

export const ImportContactInfoModal = ({ options, fileLineContent = [], sequenceId }: ImportContactInfoModalProps) => {
    const {
        buttons,
        dialogs,
        pages: {
            myAutomations: { importContactInfoModal }
        }
    } = useTranslator();
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef<FormHandles>();
    const { show, warning, removeAll } = useAlert();

    const handleFinalizeImport: SubmitHandler = async (data) => {
        try {
            setIsLoading(true);
            const contacts = (await generateContactListAsync(data, fileLineContent)) as Array<SubmitContactListPayload>;
            await SubmitContactListAsync(contacts, sequenceId);
            // TODO: Enviar dados ao backend quando houver um endpoint
            show(null, null, { content: ContactImportedSuccessfullyModal });
        } catch (exception) {
            if (exception instanceof GenerateContactListError) {
                return warning(
                    String(dialogs.titles.notice),
                    String(importContactInfoModal.messages[exception.message]),
                    { enableOutsideClosed: true }
                );
            }

            if (exception instanceof EnviouApiResponseError<ImportContactErrorStatus>) {
                return warning(
                    String(dialogs.titles.notice),
                    String(exception.getFirstError('importContactErrorStatus').message),
                    { enableOutsideClosed: true }
                );
            }

            if (exception instanceof EnviouApiUnprocessableEntityError) {
                return warning(
                    String(dialogs.titles.notice),
                    String(importContactInfoModal.messages.invalidInputData),
                    { enableOutsideClosed: true }
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    const firstLineFile = fileLineContent[0];

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(importContactInfoModal.title)}</DialogHeading>
                <DialogDescription>
                    {String(importContactInfoModal.subtitle)}{' '}
                    <Link href={routesName.helpAndTutorials} as={routesName.helpAndTutorials}>
                        {String(importContactInfoModal.tutorial)}
                    </Link>
                </DialogDescription>
                <Form ref={formRef} onSubmit={handleFinalizeImport}>
                    <MappedFieldsListStyled as='ul'>
                        <li>
                            <Text as='strong' size='lg'>
                                {String(importContactInfoModal.list.headers.field)}
                            </Text>
                            <Text as='strong' size='lg'>
                                {String(importContactInfoModal.list.headers.mapping)}
                            </Text>
                        </li>
                        {firstLineFile.map((fieldName, index) => (
                            <FieldItem key={index} fieldName={fieldName} formRef={formRef} />
                        ))}
                    </MappedFieldsListStyled>
                </Form>
                <DialogFooter>
                    <Button color='purple' buttonSize='small' isOutlined onClick={() => options.onOpenChange(false)}>
                        {String(buttons.toGoBack)}
                    </Button>
                    <Button
                        color='purple'
                        buttonSize='small'
                        onClick={() => formRef.current?.submitForm()}
                        inLoading={isLoading}
                        disabled={isLoading}>
                        {String(buttons.finalizeImport)}
                    </Button>
                </DialogFooter>
                <DialogClose onClick={() => removeAll(() => true)} />
            </DialogContentStyled>
        </Dialog>
    );
};
