import { Text } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { AcceptTerms } from '../acceptTerms';
import { InvoiceDetailsGroupStyled, InvoiceDetailsTotalStyled } from '../styles';
import { BoxStyled } from './styles';

type TotalDetailsProps = {
    totalToPay: number;
    currency: string;
    billingPeriodsName: string;
};
export const TotalDetails = ({ totalToPay, currency, billingPeriodsName = '' }: TotalDetailsProps) => {
    const { subgroups } = useTranslator().checkoutPage.invoiceDetails;
    const { formatCurrency } = useNumberFunctions();

    return (
        <InvoiceDetailsGroupStyled>
            <BoxStyled>
                <InvoiceDetailsTotalStyled>
                    <Text size='md' as='strong'>
                        {subgroups.total.title}
                    </Text>
                    <Text size='md' as='strong'>
                        <span>{formatCurrency(totalToPay, currency)}</span>
                        <Text as='span' size='xxs'>{`(${
                            subgroups.total.valueDescription
                        } ${billingPeriodsName.toLowerCase()})`}</Text>
                    </Text>
                </InvoiceDetailsTotalStyled>
                <AcceptTerms />
            </BoxStyled>
        </InvoiceDetailsGroupStyled>
    );
};
