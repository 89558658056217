import styled from 'styled-components';
import pattern from '~/assets/svg/background/whatsapp_pattern-cross.svg?url';
import MoreIcon from '~/assets/svg/icons/icon__more.svg';
import NoPicture from '~/assets/svg/icons/icon__nopicture.svg';
import PhoneIcon from '~/assets/svg/icons/icon__phone.svg';
import TickIcon from '~/assets/svg/icons/icon__tick.svg';
import VideoCall from '~/assets/svg/icons/icon__video-call.svg';
import ArrowLeft from '~/assets/svg/icons/icon__whatsapp_back_arrow.svg';

export const ContainerStyled = styled.div`
    height: 100%;
`;

export const UserBarStyled = styled.div`
    height: 6rem;
    background: #005e54;
    color: #fff;
    padding: 0 0.8rem;
    font-size: 2.4rem;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

export const UserBarContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    gap: 2rem;
`;

export const ActionItemsGroupStyled = styled.div`
    margin: 0 0 0 3rem;
    background: transparent;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    width: 9rem;
    gap: 1rem;
`;

export const ProfileStyled = styled.div`
    display: flex;
    align-items: center;
    span {
        font-size: 1.2rem;
    }
`;

export const ConversationStyled = styled.div`
    height: calc(100% - 1.2rem);
    position: relative;
    background: #f0e7de url(${pattern});
    z-index: 0;

    .message {
        color: #000;
        clear: both;
        line-height: 1.8rem;
        font-size: 1.2rem;
        padding: 0.8rem;
        position: relative;
        margin: 0.8rem 0;
        max-width: 85%;
        word-wrap: break-word;
        z-index: -1;

        &::after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
        }
    }

    .sent {
        background: #e1ffc7;
        border-radius: 0.5rem 0rem 0.5rem 0.5rem;
        float: right;

        span {
            display: inline-block;
            padding: 0 0 0 0.3rem;
            position: relative;
            bottom: -0.4rem;

            span {
                display: inline-block;
                margin-left: 0.2rem;
                position: relative;
                top: 0.4rem;
                height: 1.6rem;
                width: 1.6rem;
            }
        }

        &::after {
            border-width: 0 0 0.8rem 1rem;
            border-color: transparent transparent transparent #e1ffc7;
            top: 0;
            right: -1rem;
        }
    }

    .received {
        background: #fff;
        border-radius: 0 0.5rem 0.5rem 0.5rem;
        float: left;

        &::after {
            border-width: 0 1rem 0.8rem 0;
            border-color: transparent #fff transparent transparent;
            top: 0;
            left: -1rem;
        }
    }
`;
export const ConversationContainerStyled = styled.div`
    padding: 1.5rem;
    height: calc(100% - 4.8rem);
    overflow-x: hidden;

    margin-bottom: 0.5rem;

    code {
        font-family: monospace;
    }
    i {
        font-style: italic;
    }

    span {
        display: inline-block;
        float: right;
        padding: 0 0 0 0.7rem;
        position: relative;
        bottom: -0.4rem;

        span {
            display: inline-block;
            margin-left: 0.2rem;
            position: relative;
            top: 0.4rem;
            height: 1.6rem;
            width: 1.6rem;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const MessageReceivedStyled = styled.div``;
export const MoreIconStyled = styled(MoreIcon)`
    fill: ${({ theme }) => theme.default.whatsappComponent.iconColor};
    width: 2rem;
`;
export const NoPictureStyled = styled(NoPicture)`
    circle: {
        fill: ${({ theme }) => theme.default.whatsappComponent.iconColor};
    }
    path {
        fill: #d9d9d9;
    }

    path + path {
        fill: ${({ theme }) => theme.default.whatsappComponent.iconColor};
    }
    margin: 0 0.8rem;
`;
export const PhoneIconStyled = styled(PhoneIcon)`
    fill: ${({ theme }) => theme.default.whatsappComponent.iconColor};
    width: 1.6rem;
`;
export const VideoCallStyled = styled(VideoCall)`
    fill: ${({ theme }) => theme.default.whatsappComponent.iconColor};
    width: 2.2rem;
`;
export const ArrowLeftStyled = styled(ArrowLeft)`
    width: 2rem;
`;
export const TickIconStyled = styled(TickIcon)`
    path {
        fill: #4fc3f7;
    }
`;
