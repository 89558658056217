import styled from 'styled-components';
import { ExpandableGroup } from '~/components/elements/expandableGroup';

export const ExpandableGroupStyled = styled(ExpandableGroup)`
    overflow: initial;

    &:has(.gateway-content) {
        margin-top: -3rem;
    }
`;
