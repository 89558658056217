import { Fragment } from 'react';
import { Dialog, DialogClose, DialogDescription, DialogHeading, Text, Heading } from '@components';
import { useTranslator } from '@hooks';
import { AlertOptions } from '@providers';
import BulletPointIcon from '~/assets/svg/controls/bullet-point.svg';
import EnviouChartImg from '~/assets/svg/enviou--chart.svg';
import EnviouPageImg from '~/assets/svg/enviou--page.svg';
import { EmailMarketingContentStyled, DialogContentStyled } from './styles';

type PlanEmailMarketingModalProps = {
    options: AlertOptions;
};

export const PlanEmailMarketingModal = ({ options }: PlanEmailMarketingModalProps) => {
    const {
        emailMarketing: { modal }
    } = useTranslator().checkoutPage.subscriptionPlan;

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(modal.title)}</DialogHeading>
                <DialogDescription>{String(modal.description)}</DialogDescription>
                <EmailMarketingContentStyled>
                    {Object.entries(modal.sections).map(([key, value]) => {
                        const image =
                            key === 'newsletter' ? (
                                <EnviouPageImg className='page-image' />
                            ) : (
                                <EnviouChartImg className='chart-image' />
                            );
                        return (
                            <Fragment key={key}>
                                <div>
                                    <Heading as='h6'>{String(value.title)}</Heading>
                                    <Text size='md'>{String(value.description)}</Text>
                                    <ul>
                                        {Object.entries(value.details).map(([detailKey, detailValue]) => {
                                            return (
                                                <li key={detailKey}>
                                                    <BulletPointIcon />
                                                    <Text as='span' size='md'>
                                                        {String(detailValue)}
                                                    </Text>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div>{image}</div>
                            </Fragment>
                        );
                    })}
                </EmailMarketingContentStyled>
                <DialogClose />
            </DialogContentStyled>
        </Dialog>
    );
};
