import { forwardRef, useLayoutEffect } from 'react';
import { useId } from '@floating-ui/react';
import { useDialogContext } from '~/providers';
import { Heading, HeadingProps } from '../heading';
import { DialogHeadingStyled } from './styles';

export const DialogHeading = forwardRef<typeof Heading, HeadingProps>(function DialogHeading(
    { children, ...props },
    ref
) {
    const { setLabelId } = useDialogContext();
    const id = useId();

    // Only sets `aria-labelledby` on the Dialog root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
        setLabelId(id);
        return () => setLabelId(undefined);
    }, [id, setLabelId]);

    return (
        <DialogHeadingStyled {...props} ref={ref} id={id}>
            {children}
        </DialogHeadingStyled>
    );
});
