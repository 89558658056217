import styled, { css } from 'styled-components';
import { Card } from '~/components/elements/card';
import { Text } from '~/components/elements/text';
import { mobileOnly } from '~/styles';

export const DiscountCouponStyled = styled(Card)`
    padding-bottom: 3rem;

    header {
        h6 {
            padding-top: 0;
            line-height: 0;
        }
        svg {
            margin-top: 0;
        }
    }

    main {
        padding: 0.8rem 3rem 0;

        .discount-coupon {
            flex-direction: column;
            gap: 0.8rem;
            padding-bottom: 1px;

            & > div {
                display: flex;
                gap: 2.4rem;

                ${mobileOnly(css`
                    flex-direction: column;
                `)}
            }
        }

        & > div > div {
            padding-top: 0;
            padding-bottom: 0;
        }

        button {
            height: fit-content;
        }
    }
`;

export const CouponMessageStyled = styled(Text)<{ isSuccess: boolean }>`
    color: ${({ theme, isSuccess = false }) => (isSuccess ? theme.colors.green : theme.colors.sangria)};
`;
