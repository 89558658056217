import styled from 'styled-components';
import { Heading } from '../../heading';
import { Text } from '../../text';

export const CardTextTitleStyled = styled(Heading).attrs({ as: 'h5' })`
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 1rem;

    & > span {
        display: flex;
        svg {
            padding-left: 0;
            height: 0.9em;
            vertical-align: unset;
            color: ${({ theme }) => theme.colors.purple};
        }
    }
`;

export const CardTextSubTitleStyled = styled(Heading).attrs({ as: 'h6' })`
    color: ${({ theme }) => theme.default.color};
    padding-top: 1rem;
    display: inline-block;
`;

export const CardTextDescriptionStyled = styled(Text).attrs({ size: 'md' })`
    color: ${({ theme }) => theme.default.color};
`;
