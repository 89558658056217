import { GenerateTemplatePreviewProps, generateTemplatePreview } from './';
import { generateTextBlock } from './generatePreviewBlocks';

export const generateWhatsappTemplatePreview = ({
    translator,
    user,
    baseHtml,
    allowedVariables = [],
    parentKey
}: Omit<GenerateTemplatePreviewProps, 'productHtml' | 'reviewHtml'>) => {
    const data = generateTextBlock(translator, user);

    return generateTemplatePreview(baseHtml, data, parentKey, allowedVariables);
};
