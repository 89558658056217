import { SubToolConfiguration } from './subToolConfiguration';
import { Tool } from './tool';

export type SubToolId = number;

export enum SubToolIdEnum {
    CartRecovery = 1,
    BankSlipReminder = 2,
    Repurchase = 3,
    CustomTrigger = 4,
    NavigationTrigger = 5,
    Emails = 6
}

export type SubTool = {
    id: SubToolId;
    name: string;
    subToolConfiguration?: SubToolConfiguration;
    minCreditAmount: number;
    description: string;
    details: string;
    resources: Array<string>;
    videoUrl: string;
    tool: Tool;
};

export const subToolOrder = {
    [SubToolIdEnum.CartRecovery]: 1,
    [SubToolIdEnum.Repurchase]: 2,
    [SubToolIdEnum.BankSlipReminder]: 3,
    [SubToolIdEnum.NavigationTrigger]: 4,
    [SubToolIdEnum.CustomTrigger]: 5,
    [SubToolIdEnum.Emails]: 6
};

export enum SubToolsEventTriggers {
    BeforeRepurchaseDeadline = 0,
    AfterRepurchaseDeadline = 1,
    AfterAbandonment = 2,
    BeforeExpiration = 3,
    AfterPurchase = 4,
    AfterCartAbandonment = 5,
    AfterImport = 6
}

export type EventTriggersBySubTools = {
    [key in SubToolIdEnum]: Array<SubToolsEventTriggers>;
};

export const subToolsEventTriggersList: EventTriggersBySubTools = {
    [SubToolIdEnum.BankSlipReminder]: [SubToolsEventTriggers.BeforeExpiration, SubToolsEventTriggers.AfterPurchase],
    [SubToolIdEnum.CartRecovery]: [SubToolsEventTriggers.AfterCartAbandonment],
    [SubToolIdEnum.CustomTrigger]: [SubToolsEventTriggers.AfterImport],
    [SubToolIdEnum.NavigationTrigger]: [SubToolsEventTriggers.AfterAbandonment],
    [SubToolIdEnum.Repurchase]: [
        SubToolsEventTriggers.BeforeRepurchaseDeadline,
        SubToolsEventTriggers.AfterRepurchaseDeadline
    ],
    [SubToolIdEnum.Emails]: []
};
