import styled from 'styled-components';

export const EmailMarketingStyled = styled.div`
    h6 {
        margin-bottom: 1.2rem;
        padding-top: 0;
    }

    > div {
        margin-bottom: 1rem;
    }
`;
