import { rgba } from 'polished';
import { createGlobalStyle, css } from 'styled-components';
import { mobileOnly, tabletOnly } from './mediaQueries';

type CustomGlobalStyleProps = { body?: { backgroundColor?: string; color?: string } };

const setHtmlFontSizeOnMobileSize = mobileOnly(css`
    font-size: 54%; // 8.64px
`);
const setHtmlFontSizeOnTableSize = tabletOnly(css`
    font-size: 56.5%; // 9px
`);

const SCROLLBAR_THUMB_ALPHA = 0.4;
const SCROLLBAR_THUMB_HOVER_ALPHA = 0.7;

export default createGlobalStyle<CustomGlobalStyleProps>`
    * {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        box-sizing: border-box;
        
        &:focus-visible{
            outline: 3px solid ${({ theme }) => theme.colors.purple};
            outline-offset: -3px;
        }

        &::-webkit-scrollbar-track
        {
            background-color: ${({ theme }) => theme.colors.grayPurple};
            border-radius: 1rem;
        }

        &::-webkit-scrollbar
        {
            width: 8px;
            height: 8px;
            background-color: ${({ theme }) => theme.colors.grayPurple};           
        }

        &::-webkit-scrollbar-thumb
        {
            background-color: ${({ theme }) => rgba(theme.colors.gray, SCROLLBAR_THUMB_ALPHA)};
            transition: background-color .15s ease;
            border-radius: 1rem;
           
            &:hover{
                background-color: ${({ theme }) => rgba(theme.colors.gray, SCROLLBAR_THUMB_HOVER_ALPHA)};
            }
           
        }
    }

    #print-frame {
        z-index: 100000;
    }

    @media print {
        body * {
            display: none
        }
        #print-iframe {
            display: block;
            width:27cm;
            height:20cm;
        }
    }

    html {
        font-size: 62.5%; //10px
        scroll-behavior: smooth;
        transition: font-size .15s ease-in;
        -webkit-font-smoothing: antialiased;
        ${setHtmlFontSizeOnTableSize};
        ${setHtmlFontSizeOnMobileSize};
    }

    body {
        display: flex;
        flex-direction: column;
        margin: 0;
        font: ${({ theme }) => theme.fontWeights.regular} 1.6rem  ${({ theme }) => theme.fonts.primary};
        text-align: left;
        line-height: 1.5;
        overflow-x: hidden;

        background-color: ${({ body, theme }) => body?.backgroundColor || theme.default.backgroundColor};
        color: ${({ body, theme }) => body?.color || theme.default.color};
    }

    a {
        color: ${({ theme }) => theme.default.anchor.color};
        text-decoration: none;
    }

    mark {
        color: ${({ theme }) => theme.default.mark.color};
        background-color: ${({ theme }) => theme.default.mark.backgroundColor};

        &::selection {
            color: ${({ theme }) => theme.default.mark._selection.color};
            background: ${({ theme }) => theme.default.mark._selection.backgroundColor};
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: ${({ theme }) => theme.fonts.primary};
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    }

    b, strong {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    }

    small {
        font-size: small;
    }

    ::selection {
        color: ${({ theme }) => theme.default._selection.color};
        background: ${({ theme }) => theme.default._selection.backgroundColor};
    }

    .nobr {
        white-space: nowrap;
        hyphens: none;
    }

    .fade-animation {
        animation: fade-animation 0.4s cubic-bezier(0.65, 0, 0.35, 1) both;
    }

    svg {
        transition: height .15s ease-in, width .15s ease-in;
    }

    .shimmer-effect {
        animation: shimmer 2s infinite linear;
        background: ${({
            theme: {
                default: { shimmer }
            }
        }) =>
            `linear-gradient(to right, ${shimmer.primaryColor} 4%, ${shimmer.secondColorColor} 25%, ${shimmer.thirdColor} 36%)`};
        background-size: 1000px 100%;
        transition: opacity .2s ease-out;
        border-radius: .8rem;
        cursor: progress;

        * {
            opacity: 0;
            pointer-events: none;
        }        
    }

    @keyframes shimmer {
        0% {
            background-position: -1000px 0;
        }
        100% {
            background-position: 1000px 0;
        }
    }    

    input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.colors.philippineSilver};
    }

    .react-datepicker-popper {
        z-index: 99999;
    }
    
    .react-datepicker {
        font-family:  ${({ theme }) => theme.fonts.primary};
        font-size: ${({ theme }) => theme.fontSizes.xxs};
        border-radius: 1rem;
        border: 1px solid ${({ theme }) => theme.colors.lightPurple};
        box-shadow: ${({ theme }) => theme.default.boxShadow.default};
               
        &__today-button,
        &__header {
            background-color: ${({ theme }) => theme.colors.darkenedGrayPurple};
            border-color: ${({ theme }) => theme.colors.lightPurple};
            transition: all .10s ease;
        }
        &__today-button {
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
            &:hover {
                background-color:  ${({ theme }) => theme.colors.darkenedPurple};
                color:  ${({ theme }) => theme.colors.white};
            }
        }
        &__current-month {
            font-size: ${({ theme }) => theme.fontSizes.xs};
        }
        .react-datepicker-popper[data-placement^=bottom] &__triangle::before {
            border-bottom-color: ${({ theme }) => theme.colors.lightPurple};
        }
        
        &__current-month {
            color: ${({ theme }) => theme.colors.darkPurple};
        }
        
        &__day-name{
            width: 2.8rem;
            line-height: 2.8rem;
            color: ${({ theme }) => theme.colors.darkPurple};
        }
        &__day,
        &__month-text,
        &__year-text {
            width: 2.8rem;
            line-height: 2.8rem;
            transition: all .10s ease;
            &:hover {
                background-color: ${({ theme }) => theme.colors.darkenedGrayPurple};
                color: ${({ theme }) => theme.colors.lightBlack};
            }
        }
        &__day--selected,
        &__day--in-range,
        &__month-text--in-range,
        &__year-text--in-range {
            background-color: ${({ theme }) => theme.colors.enlightenedPurple};
        }
        &__day--disabled {
            color: ${({ theme }) => theme.colors.philippineSilver};
            opacity: .7;
        }
        &__day--in-selecting-range:not(.react-datepicker__day--in-range),
        &__month-text--in-selecting-range:not(.react-datepicker__month-text--in-range),
        &__year-text--in-selecting-range:not(.react-datepicker__year-text--in-range)
        {
            background-color: ${({ theme }) => theme.colors.lightPurple};
            color: ${({ theme }) => theme.colors.lightBlack};
        }
        &__day--keyboard-selected,
        &__month-text--keyboard-selected,
        &__year-text--keyboard-selected {
            background-color: ${({ theme }) => theme.colors.lightPurple};
            color: ${({ theme }) => theme.colors.lightBlack};
        }
        &__day--outside-month:not(&__day--disabled, &__day--in-range) {
            color: ${({ theme }) => theme.colors.enlightenedPurple};
            opacity: .5; 
        }
        
        &__navigation {
            &-icon::before {
                border-color:  ${({ theme }) => theme.colors.darkenedPurple};
            }
            &:hover {
                opacity: .4;
                *::before {
                    border-color:  ${({ theme }) => theme.colors.purple};
                }
            }
        }
        &__year--container,
        &__month-container {
            min-width: 22rem;
        }
        &__year-wrapper {
            margin: auto;
        }
    }
`;
