import { FloatingFocusManager, FloatingList, FloatingPortal } from '@floating-ui/react';
import { useSelectContext } from '../context';
import { SelectContentStyled } from './styles';

const OFFSET_WIDTH = 32;

export const SelectContent = ({ children, ...props }) => {
    const { refs, isMounted, context, strategy, styles, elementsRef, labelsRef, getFloatingProps } = useSelectContext();

    const selectWidth = refs?.reference?.current?.getBoundingClientRect()?.width + OFFSET_WIDTH ?? 'auto';

    return (
        <>
            {isMounted && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <SelectContentStyled
                            ref={refs.setFloating}
                            style={{
                                position: strategy,
                                top: context.y ?? 0,
                                left: context.x ?? 0,
                                ...styles,
                                width: selectWidth,
                                ...props.style
                            }}
                            {...getFloatingProps()}>
                            <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                                {children}
                            </FloatingList>
                        </SelectContentStyled>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );
};
