import styled from 'styled-components';
import { DialogContent } from '~/components/elements/dialog/content';

type DialogContentStyledProps = {
    fileName?: string;
    placeholder: string;
};

export const DialogContentStyled = styled(DialogContent)<DialogContentStyledProps>`
    padding: 3.2rem;
    width: 100%;
    max-width: 72rem;

    h2 {
        text-align: start;
        margin-bottom: 3.2rem;
        color: ${({ theme }) => theme.colors.deepCerise};
    }

    & > form {
        & > div {
            max-width: 51.2rem;
        }

        p {
            color: ${({ theme }) => theme.colors.philippineSilver};
            margin-top: 1rem;
        }

        &:has(div[data-validate]) {
            p {
                opacity: 0;
            }
        }
    }

    footer {
        margin-top: 1rem;

        button {
            width: fit-content;
            margin-left: auto;
        }
    }
`;
