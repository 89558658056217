import styled from 'styled-components';

export const SingInLayoutStyled = styled.main`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: ${({ theme }) => theme.colors.grayPurple};
    padding: ${({ theme }) => theme.container.paddingX};
`;
