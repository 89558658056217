export enum LineItemKind {
    Debit = 'debit',
    Credit = 'credit'
}

export type LineItem = {
    quantity: string;
    unitAmount: string;
    name: string;
    kind: LineItemKind;
    unitTaxAmount: string | undefined;
    description: string | undefined;
    productCode: string | undefined;
    url: string | undefined;
};

export enum ShippingOptionType {
    Shipping = 'SHIPPING',
    Pickup = 'PICKUP'
}

export type CurrencyAmount = {
    currency: string;
    value: string;
};

export type ShippingOption = {
    id: string;
    label: string;
    selected: boolean;
    type: ShippingOptionType;
    amount: CurrencyAmount;
};

export type Address = {
    line1: string;
    line2?: string | undefined;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
    phone?: string | undefined;
    recipientName?: string | undefined;
};

export type CreditFinancingOptions = {
    totalCost: CurrencyAmount;
    term: number;
    monthlyPayment: CurrencyAmount;
    totalInterest: CurrencyAmount;
    payerAcceptance: boolean;
    cartAmountImmutable: boolean;
};

export type AuthorizationResponseDetails = {
    email: string;
    payerId: string;
    firstName: string;
    lastName: string;
    countryCode?: string | undefined;
    phone?: string | undefined;
    shippingAddress?: Address | undefined;
    billingAddress?: Address | undefined;
    creditFinancingOffered?: CreditFinancingOptions | undefined;
};

export type AuthorizationResponse = {
    nonce: string;
    type: string;
    details: AuthorizationResponseDetails;
};

export enum FlowType {
    Vault = 'vault',
    Checkout = 'checkout'
}

export enum Intent {
    Authorize = 'authorize',
    Order = 'order',
    Capture = 'capture'
}

export type AuthorizationData = {
    payerId: string;
    paymentId?: string | undefined;
    billingToken?: string | undefined;
    vault?: boolean | undefined;
};

export type CancellationData = {
    billingID: string;
    // eslint-disable-next-line camelcase
    button_version: string;
    cancelUrl: string;
    intent: string;
    paymentID: string;
    paymentToken: string;
};
