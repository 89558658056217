import { ToolTypeEnum } from './tool';

export enum SendingTechnologyTypeEnum {
    Email = 1,
    SMS = 2,
    WhatsAppManual = 3,
    WhatsApp = 4,
    Push = 5
}

export const enum SendingTechnologyFieldEnum {
    SenderName,
    SenderContact,
    ContactAnswer
}

export type SendingTechnologyAllowedFields = {
    [key in SendingTechnologyTypeEnum]: Array<SendingTechnologyFieldEnum>;
};

/**
 * Data that must be asked for the user when configuring each sending technology.
 * Some of the data are required but automatically can be set by the server, hence the reason why we don't ask
 * all of them.
 */
export const ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY: Readonly<SendingTechnologyAllowedFields> = {
    [SendingTechnologyTypeEnum.SMS]: [],
    [SendingTechnologyTypeEnum.Email]: [
        SendingTechnologyFieldEnum.SenderName,
        SendingTechnologyFieldEnum.SenderContact,
        SendingTechnologyFieldEnum.ContactAnswer
    ],
    [SendingTechnologyTypeEnum.WhatsAppManual]: [],
    [SendingTechnologyTypeEnum.WhatsApp]: [],
    [SendingTechnologyTypeEnum.Push]: []
};

export type SendingTechnology = {
    id: number;
    type: SendingTechnologyTypeEnum;
    toolType: ToolTypeEnum;
    currency: string;
    monthlyValue: number;
    quarterlyValue: number;
    semesterValue: number;
    annualValue: number;
    looseValue: number;
    creditAmount: number;
    isCustomValue?: boolean;
};
