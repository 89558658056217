import styled, { css } from 'styled-components';
import { mobileAndTabletOnly, mobileOnly } from '~/styles';

export const MySubscriptionContainer = styled.div``;

export const AutomationsContent = styled.div`
    display: flex;
    gap: 3.2rem;

    & > section {
        max-width: 50%;
        height: fit-content;
    }

    ${mobileAndTabletOnly(css`
        flex-direction: column;

        & > section {
            max-width: 100%;
        }
    `)}
`;

export const PageHeaderStyled = styled.div`
    display: flex;
    gap: 1.6rem;
    margin-bottom: 3.2rem;

    .react-datepicker-wrapper {
        height: fit-content;
        width: 28rem;
        min-width: 28rem;
        align-self: flex-end;
        margin-left: auto;
    }

    ${mobileOnly(css`
        flex-direction: column;
    `)}
`;
