import { Children, forwardRef, HTMLProps, isValidElement } from 'react';
import {
    FloatingFocusManager,
    FloatingNode,
    FloatingPortal,
    FloatingTree,
    useFloatingParentNodeId
} from '@floating-ui/react';
import { useMenu, useTranslator } from '@hooks';
import { User } from '@models';
import { routesName } from '~/locales/route';
import { AnimatedArrow } from '../animatedArrow';
import { Link } from '../link';
import { Text } from '../text';
import { ImageProfile } from './imageProfile';
import { MenuProps } from './menu';
import { ArrowContentStyled, MenuStyled } from './menu/styles';
import { HelpAndTutorialsContentStyled, HelpIconStyled, UserMenuButtonStyled } from './styles';

type UserMenuProps = MenuProps & { user?: User };

const HelpAndTutorials = () => {
    const { header } = useTranslator();

    return (
        <Link href={routesName.helpAndTutorials} as={routesName.helpAndTutorials}>
            <HelpAndTutorialsContentStyled>
                <HelpIconStyled />
                <span>{String(header.helpAndTutorials)}</span>
            </HelpAndTutorialsContentStyled>
        </Link>
    );
};

const MenuComponent = forwardRef<HTMLElement, UserMenuProps & HTMLProps<HTMLElement>>(function UserMenuItem(
    { children, label, link, allowHover, user, ...props },
    forwardedRef
) {
    const labels = Children.map(children, (child) => (isValidElement(child) ? child.props.label : null));

    const {
        nodeId,
        isNested,
        isOpen,
        isMounted,
        referenceRef,
        getReferenceProps,
        styles,
        getFloatingProps,
        context,
        refs,
        items
    } = useMenu<HTMLElement>({
        elementRef: forwardedRef,
        labels,
        children,
        allowHoverEvent: allowHover,
        placement: 'bottom-end'
    });

    return (
        <FloatingNode id={nodeId}>
            <HelpAndTutorials />
            <UserMenuButtonStyled
                isRoot={!isNested}
                ref={referenceRef}
                link={link}
                data-open={isOpen ? '' : undefined}
                tabIndex={!isNested ? 0 : undefined}
                {...getReferenceProps({
                    ...props,
                    onClick(event) {
                        event.stopPropagation();
                    }
                })}>
                <ImageProfile src={user?.avatarUrl} alt={user?.name} width='4rem' height='4rem' />
                <Text as='strong' size='md'>
                    {label}
                </Text>
                {!isNested && children && (
                    <ArrowContentStyled aria-hidden>
                        <AnimatedArrow direction={isOpen ? 'up' : 'down'} />
                    </ArrowContentStyled>
                )}
            </UserMenuButtonStyled>
            <FloatingPortal>
                {isMounted && (
                    <FloatingFocusManager
                        context={context}
                        modal={false}
                        initialFocus={isNested ? -1 : 0}
                        returnFocus={true}>
                        <MenuStyled
                            ref={refs.setFloating}
                            tabIndex={0}
                            style={{
                                position: context.strategy,
                                top: context.y ?? 0,
                                left: context.x ?? 0,
                                ...styles
                            }}
                            {...getFloatingProps()}>
                            {items}
                        </MenuStyled>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        </FloatingNode>
    );
});

export const UserMenu = forwardRef<HTMLElement, UserMenuProps & HTMLProps<HTMLElement>>(function UserMenu(props, ref) {
    const parentId = useFloatingParentNodeId();

    if (parentId === null) {
        return (
            <FloatingTree>
                <MenuComponent {...props} ref={ref} />
            </FloatingTree>
        );
    }

    return <MenuComponent {...props} ref={ref} />;
});
