import { rgba } from 'polished';
import {
    automationToolFunnelChartColors,
    emailMarketingFunnelChartColors,
    pluginsColors,
    subToolColors
} from './theme';
import { fontSizes, fontWeights, fonts, lineHeights, radii, space } from './tokens';

const BOX_SHADOW_ALPHA_VALUE = 0.75;
const DISABLED_ALPHA_VALUE = 0.8;

const colors = {
    lightBlack: '#F4F4F5',
    white: '#24242B',
    gallery: '#EEEEEE',
    lightGray: '#80868C',
    darkGray: '#202020',
    gray: '#666666',
    philippineSilver: '#B4B4b4',
    grayPurple: '#18181b',
    darkenedGrayPurple: '#47474A',
    lightPurple: '#6F42A1',
    enlightenedPurple: '#9768D1',
    purple: '#9768D1',
    darkenedPurple: '#E5CEFD',
    darkPurple: '#490055',
    yellow: '#FFC026',
    greenLeaf: '#49860B',
    green: '#00B83B',
    paleCyan: '#A3FFC5',
    darkGreen: '#69A82A',
    pink: '#FF0766',
    lightPink: '#FF8EB9',
    blue: '#1C4CEB',
    lightBlue: '#00C1DC',
    redOrange: '#FF3B30',
    sangria: '#941010',

    // This section corresponds to Enviou 2023 style guide
    eminencePurple: '#79318F',
    oldGold: '#D4BA11',
    pennRed: '#941010',
    seaSalt: '#FAFAFA',
    darkMossGreen: '#366506',
    deepCerise: '#C42783',
    licorice: '#2F384C'
} as const;

const containerInRem = {
    maxWidth: 128,
    padding: 2.4
} as const;

export const alertColors = {
    default: colors.eminencePurple,
    info: colors.blue,
    warning: colors.oldGold,
    error: colors.sangria,
    success: colors.darkMossGreen
} as const;

export const theme = {
    colors,
    subToolColors,
    pluginsColors,
    alertColors,
    funnelChart: {
        automationToolFunnelChartColors,
        emailMarketingFunnelChartColors
    },
    container: {
        maxWidth: `${containerInRem.maxWidth}rem`,
        paddingX: `${containerInRem.padding}rem`,
        rem: containerInRem
    },
    theme: 'dark',
    fontSizes,
    fontWeights,
    fonts,
    lineHeights,
    radii,
    space,
    default: {
        color: colors.lightBlack,
        backgroundColor: colors.darkGray,
        borderColor: colors.lightBlack,
        dangerColor: colors.redOrange,
        boxShadow: {
            alphaValue: BOX_SHADOW_ALPHA_VALUE,
            default: `0 0.3rem 1rem ${rgba(colors.grayPurple, BOX_SHADOW_ALPHA_VALUE)}`
        },
        _selection: {
            color: colors.white,
            backgroundColor: colors.purple
        },
        anchor: {
            color: colors.purple
        },
        input: {
            color: colors.lightBlack,
            backgroundColor: colors.white,
            borderColor: colors.purple,
            disabledBorderColor: colors.gray,
            outlineColor: colors.darkPurple
        },
        label: {
            disabled: `${rgba(colors.lightGray, DISABLED_ALPHA_VALUE)}`
        },
        mark: {
            color: colors.lightBlack,
            backgroundColor: colors.lightPurple,
            _selection: {
                color: colors.white,
                backgroundColor: colors.darkPurple
            }
        },
        footer: {
            backgroundColor: colors.white,
            secondBackgroundColor: colors.darkenedGrayPurple,
            color: colors.lightBlack
        },
        shimmer: {
            primaryColor: colors.darkGray,
            secondColorColor: colors.gray,
            thirdColor: colors.darkGray
        },
        whatsappComponent: {
            iconColor: colors.lightBlack
        }
    }
} as const;

export default theme;
