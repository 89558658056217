import { useCallback, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { Button, Dialog, DialogClose, DialogFooter, DialogHeading } from '@components';
import { useValidation } from '@containers';
import { useFetch, useTranslator } from '@hooks';
import { Address } from '@models';
import {
    AlertOptions,
    BillingFormData as BillingFormDataType,
    SubscriptionReducerActionTypesEnum,
    useAddress,
    useCheckout
} from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles, SubmitHandler } from '@unform/core';
import { ApplyMask, convertPhoneToString, convertToPhoneModel, InputMaskEnum } from '@utils';
import { GetStatesOfBrazil, STATES_OF_BRAZIL_URL } from '~/services/brasilApiService';
import { BillingDataForm } from '../form';
import { BillingDataUnformStyled, DialogContentStyled } from './styles';

type EditBillingDataProps = {
    options: AlertOptions;
};

export const EditBillingDataModal = ({ options }: EditBillingDataProps) => {
    const formRef = useRef<FormHandles>(null);
    const { billingDataSchema } = useValidation();
    const { dispatchSubscriptionFormData, subscriptionFormData, subscription, setSubscription } = useCheckout();
    const { countries } = useAddress();
    const { address, name, documentNumber, billingResponsible } = subscription.customer;
    const { setStates, states } = useAddress();

    useFetch(STATES_OF_BRAZIL_URL, async () => (await GetStatesOfBrazil()).data, {
        onSuccess: (data) => setStates(data),
        onError: (error) => captureException(error)
    });

    const getCountryByCode = useCallback(
        (countryCode: string) => countries?.find((country) => country.code === countryCode),
        [countries]
    );

    const getStateByCode = useCallback(
        (stateCode: string) => states?.find((state) => state.code === stateCode),
        [states]
    );

    useEffect(() => {
        if (!address.state?.code) {
            return;
        }

        if (states?.length && !address.state?.name) {
            address.state = getStateByCode(address.state.code);
            setSubscription({ ...subscription, customer: { ...subscription.customer, address: address } });
        }
    }, [address, states, subscription, getStateByCode, setSubscription]);

    const handleSubmit: SubmitHandler<BillingFormDataType> = useCallback(
        async (formData, { reset }) => {
            try {
                formRef.current.setErrors({});

                await billingDataSchema.validate(formData, { abortEarly: false, context: { validTelephone: true } });

                subscription.customer.address = {
                    ...formData.address,
                    country: getCountryByCode(formData.address.countryCode),
                    state: getStateByCode(formData.address.stateCode)
                };

                subscription.customer.documentNumber = formData.cpfCnpj;
                subscription.customer.name = formData.corporateName;
                subscription.customer.billingResponsible.phone = convertToPhoneModel(formData.telephone);

                setSubscription(subscription);

                dispatchSubscriptionFormData({
                    type: SubscriptionReducerActionTypesEnum.UpdateSubscriptionData,
                    payload: {
                        subscriptionData: { ...subscriptionFormData?.subscriptionData, billingData: formData }
                    }
                });

                options.onOpenChange(false);
                reset();
            } catch (exception) {
                if (exception instanceof yup.ValidationError) {
                    const errorMessages = {};

                    exception.inner.forEach((error) => (errorMessages[error.path] = error.message));

                    formRef.current.setErrors(errorMessages);
                    console.log(errorMessages);
                } else {
                    captureException(exception);
                }
            }
        },
        [
            billingDataSchema,
            subscriptionFormData?.subscriptionData,
            subscription,
            dispatchSubscriptionFormData,
            getCountryByCode,
            getStateByCode,
            setSubscription,
            options
        ]
    );

    const { subscriptionData } = useTranslator().checkoutPage;
    const { actions, billingData } = subscriptionData;

    const parseAddressToFormHandle = useCallback(
        async (address: Address) => {
            if (address) {
                const data = formRef.current.getData() as BillingFormDataType;

                data.address = { ...address, countryCode: address.country?.code, stateCode: address.state?.code };

                formRef.current.setData(data);
            }
        },
        [formRef]
    );

    const initialData = {
        corporateName: name,
        cpfCnpj: ApplyMask(documentNumber, InputMaskEnum.taxpayerRegistry),
        telephone: ApplyMask(convertPhoneToString(billingResponsible?.phone), InputMaskEnum.phone),
        address: {
            ...address,
            zipCode: ApplyMask(address.zipCode, InputMaskEnum.zipCode),
            countryCode: address.country?.code,
            stateCode: address.state?.code
        }
    } as BillingFormDataType;

    const handleClose = () => {
        options.onOpenChange(false);
        formRef.current.reset();
    };

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(billingData.title)}</DialogHeading>
                <BillingDataUnformStyled ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
                    <BillingDataForm
                        formRef={formRef}
                        showTelephoneField
                        parseAddressToFormHandle={parseAddressToFormHandle}
                        countries={countries}
                    />
                </BillingDataUnformStyled>
                <DialogFooter>
                    <Button onClick={handleClose} testId='button__close-billing-data' color='red' isOutlined>
                        {String(actions.cancel)}
                    </Button>
                    <Button
                        onClick={() => formRef.current.submitForm()}
                        color='purple'
                        testId='button__save-billing-data'>
                        {String(actions.save)}
                    </Button>
                </DialogFooter>
                <DialogClose />
            </DialogContentStyled>
        </Dialog>
    );
};
