import { LabelHTMLAttributes } from 'react';
import { defaultHeadingStyle } from '../heading';
import { TextProps } from '../text';
import { LabelStyled } from './styles';

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & TextProps;

export const Label = ({ children, ...args }: LabelProps) => (
    <LabelStyled as='label' {...defaultHeadingStyle.h6} {...args}>
        {children}
    </LabelStyled>
);
