import { ContactConfiguration } from './contactConfiguration';
import { Customer } from './customer';
import { MessageSequence } from './messageSequence';
import { SubTool } from './subTool';

export enum SubToolConfigurationStatusEnum {
    Inactive = 0,
    Active = 1,
    SendingsPaused = 2
}

export enum RepurchaseConsideredPeriodEnum {
    Consumer = 1,
    Product = 2
}

export type SubToolConfiguration = {
    id?: number;
    subTool: SubTool;
    status: SubToolConfigurationStatusEnum;
    repurchaseConsideredPeriod: RepurchaseConsideredPeriodEnum;
    store: Customer;
    lastUpdateDate: Date;
    lastCartsConsolidationDate?: Date;
    contactConfigurations?: Array<ContactConfiguration>;
    messageSequences?: Array<MessageSequence>;
};
