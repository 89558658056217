import { useCallback, useState } from 'react';
import lowerFirst from 'lodash/lowerFirst';
import { Button, Text, CardLink } from '@components';
import { useNumberFunctions, usePrinter, useTranslator } from '@hooks';
import { SubscriptionInvoice, SubscriptionInvoiceStatusEnum } from '@models';
import { formatDate } from '@utils';
import { BankSlipInformationStyled } from './styles';

type BankSlipInformationType = {
    subscriptionInvoice?: SubscriptionInvoice;
};

export const BankSlipInformation = ({ subscriptionInvoice }: BankSlipInformationType) => {
    const { bankSlipPage, enums } = useTranslator();
    const { formatCurrency } = useNumberFunctions();
    const { openPrinter } = usePrinter(subscriptionInvoice?.bankSlipUrl);

    const [showCopiedBarcodeText, setShowCopiedBarcodeText] = useState(false);

    const handleCopyBarcodeClick = useCallback(() => {
        navigator.clipboard.writeText(subscriptionInvoice.bankSlipBarcode);
        setShowCopiedBarcodeText(true);

        const timeout = 1000;
        setTimeout(() => {
            setShowCopiedBarcodeText(false);
        }, timeout);
    }, [subscriptionInvoice.bankSlipBarcode]);

    const handleOpenBankSlip = useCallback(() => {
        const link = document.createElement('a');
        link.href = subscriptionInvoice?.bankSlipUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [subscriptionInvoice?.bankSlipUrl]);

    let invoiceStatus = SubscriptionInvoiceStatusEnum[subscriptionInvoice.status];
    invoiceStatus = enums.subscriptionInvoiceStatusEnum[lowerFirst(invoiceStatus)];

    return (
        <BankSlipInformationStyled subscriptionInvoiceStatus={subscriptionInvoice.status}>
            <ul>
                <li>
                    <Text as='span' size='md'>
                        {String(bankSlipPage.bankSlipInformation.status)}
                    </Text>
                    <Text as='strong' size='md'>
                        {invoiceStatus}
                    </Text>
                </li>
                <li>
                    <Text as='span' size='md'>
                        {String(bankSlipPage.bankSlipInformation.dateDue)}
                    </Text>
                    <Text as='strong' size='md'>
                        {formatDate(new Date(subscriptionInvoice.dueDate), 'short')}
                    </Text>
                </li>
                <li>
                    <Text as='span' size='md'>
                        {String(bankSlipPage.bankSlipInformation.value)}
                    </Text>
                    <Text as='strong' size='md'>
                        {formatCurrency(subscriptionInvoice.value, subscriptionInvoice.currency)}
                    </Text>
                </li>
                <li>
                    <Text as='span' size='md'>
                        {String(bankSlipPage.bankSlipInformation.barcode)}
                    </Text>
                    <div>
                        <Text as='strong' size='md'>
                            {subscriptionInvoice.bankSlipBarcode}
                        </Text>
                        <CardLink
                            isUnderline
                            size='md'
                            showArrow={false}
                            text={
                                showCopiedBarcodeText
                                    ? String(bankSlipPage.bankSlipInformation.copiedCode)
                                    : String(bankSlipPage.bankSlipInformation.copyCode)
                            }
                            onClick={handleCopyBarcodeClick}
                            testId='button__copy-bank-slip-information'
                        />
                    </div>
                </li>
                <li>
                    <Button buttonSize='small' color='purple' onClick={openPrinter} testId='button__print-bank-slip'>
                        {String(bankSlipPage.bankSlipInformation.printYourBankSlip)}
                    </Button>
                    <Button
                        buttonSize='small'
                        color='purple'
                        isOutlined
                        onClick={handleOpenBankSlip}
                        testId='button__access-complete-bank-slip'>
                        {String(bankSlipPage.bankSlipInformation.accessYourCompleteBankSlip)}
                    </Button>
                </li>
            </ul>
        </BankSlipInformationStyled>
    );
};
