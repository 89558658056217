import styled from 'styled-components';

export const OptionStyled = styled.button`
    width: 100%;
    padding: ${({ theme }) => `1rem ${theme.space.md} 0.83rem`};
    background-color: transparent;
    text-align: start;
    color: ${({ theme }) => theme.default.input.color};
    font-size: ${({ theme }) => theme.fontSizes.xxs};
    position: relative;

    & > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:focus {
        outline: 0;
    }

    &:not([role='separator'], [data-active='true'], &.option-group-title):hover,
    &:not([role='separator'], [data-active='true'], &.option-group-title):focus {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.purple};
    }

    &[data-active='true'] {
        background-color: ${({ theme }) => theme.colors.darkPurple};
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;

        .selected-icon,
        svg {
            color: ${({ theme }) => theme.colors.white};
        }
    }

    &[role='separator'] {
        display: block;
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.colors.lightPurple};
        margin: 0.8rem 0;
        padding: 0;
    }

    &.option-group-title {
        padding: 0.8rem 1rem;
        display: block;
        line-height: 2.1rem;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.lightGray};
    }

    .selected-icon {
        position: relative;
        width: 1.2rem;
        left: -0.8rem;
    }
`;
