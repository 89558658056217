import styled, { css } from 'styled-components';
import { TableCommonProps, BORDER_RADIUS } from '../styles';

export type TheadStyledProps = Pick<TableCommonProps, 'borderRadius'>;

export const TheadStyled = styled.thead<TheadStyledProps>`
    ${({ theme, borderRadius = BORDER_RADIUS }) => css`
        font-weight: bold;
        font-size: 1.6rem;

        tr {
            background-color: ${theme.colors.white};
            vertical-align: middle;

            th {
                background-color: ${({ theme }) => theme.colors.white};
                padding: 1.6rem 1rem;
                height: 1px;
                min-height: 1px;

                @-moz-document url-prefix() {
                    height: 100%;
                    min-height: auto;
                }
            }

            &:first-child {
                th {
                    &:first-child {
                        border-top-left-radius: ${borderRadius};
                        z-index: 1;
                    }

                    &:last-child {
                        border-top-right-radius: ${borderRadius};
                    }
                }
            }
        }
    `}
`;
