import styled, { css } from 'styled-components';
import { desktopOnly } from '~/styles/mediaQueries';

export const containerPadding = css`
    padding: 2.4rem;

    ${desktopOnly(css`
        padding: 2.4rem 6rem;
    `)}
`;

export const OverlayStyled = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    opacity: 0;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 200ms;
    backdrop-filter: blur(1px);

    & .modal-content {
        transform: translateY(-100px);
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
    }

    &.active {
        transition-duration: 250ms;
        transition-delay: 0ms;
        opacity: 1;

        & .modal-content {
            transform: translateY(0);
            opacity: 1;
            transition-delay: 150ms;
            transition-duration: 350ms;
        }
    }
`;

export const ModalStyled = styled.div`
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 0.4rem;
    background-color: ${({ theme }) => theme.colors.grayPurple};
    color: ${({ theme }) => theme.colors.lightBlack};
    box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.16);
    overflow: auto;

    ${desktopOnly(css`
        align-self: flex-start;
        margin-top: 5%;
        max-height: 80vh;
    `)}

    dialog {
        width: inherit;
        height: inherit;
        margin: 0 auto;
    }
`;
