import { CardHeader, CardBody, Heading, Button, InfoTooltip } from '@components';
import { useTranslator } from '@hooks';
import { MessageSequenceType } from '@models';
import { useManageRepurchaseItemsProvider } from '@providers';
import { CardStyled, TooltipContentStyled } from '../styles';
import { TemplateTypeSectionStyled } from './styles';

export type ButtonType = Lowercase<keyof typeof MessageSequenceType>;
type ButtonData = {
    type: ButtonType;
    name: string;
};

export const TemplateTypeSection = () => {
    const {
        pages: {
            manageRepurchaseItem: {
                sections: { templateType }
            }
        },
        enums: { subToolTemplateConfigurationType }
    } = useTranslator();

    const {
        onItemTypeChange,
        params: { itemType }
    } = useManageRepurchaseItemsProvider((context) => context.filter);

    const buttons = [
        {
            type: 'general',
            name: String(subToolTemplateConfigurationType.general)
        },
        {
            type: 'category',
            name: String(subToolTemplateConfigurationType.category)
        },
        {
            type: 'product',
            name: String(subToolTemplateConfigurationType.product)
        }
    ] as Array<ButtonData>;

    return (
        <CardStyled type='group'>
            <CardHeader title={String(templateType.title)} />
            <CardBody>
                <TemplateTypeSectionStyled>
                    <Heading as='h6'>
                        {String(templateType.description.label)}
                        <InfoTooltip>
                            <TooltipContentStyled>{String(templateType.description.tooltip)}</TooltipContentStyled>
                        </InfoTooltip>
                    </Heading>
                    <div>
                        {buttons.map((button) => (
                            <Button
                                key={button.type}
                                color='purple'
                                isOutlined={itemType !== button.type}
                                onClick={() => onItemTypeChange(button.type)}
                                buttonSize='small'>
                                {button.name}
                            </Button>
                        ))}
                    </div>
                </TemplateTypeSectionStyled>
            </CardBody>
        </CardStyled>
    );
};
