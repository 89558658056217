import { ReactNode } from 'react';
import HtmlReactParser from 'html-react-parser';
import { Alert } from '@providers';
import WarningIcon from '~/assets/svg/controls/icon__attention-symbol-triangle.svg';
import InfoIcon from '~/assets/svg/controls/icon__info.svg';
import SuccessIcon from '~/assets/svg/controls/icon__success.svg';
import { Dialog, DialogClose, DialogDescription, DialogHeading } from '~/components/elements/dialog';
import { AlertDialogContentStyled, ProgressBarAlertStyled } from './styles';

type AlertDialogProps = Alert & {
    children?: ReactNode;
};

const ONE_MILLISECOND = 1_000;

const alertIcons = {
    default: null,
    error: WarningIcon,
    success: SuccessIcon,
    warning: WarningIcon,
    info: InfoIcon
};

export const AlertDialog = ({ title, description, options, children, ...props }: AlertDialogProps) => {
    const { type = 'default', autoClose, timeout, hideProgressBar } = options ?? {};
    const AlertIcon = alertIcons[type];

    const totalSeconds = options.open ? Math.round(options.timeout / ONE_MILLISECOND) : 0;
    const showProgressBar = !hideProgressBar && autoClose && timeout;
    return (
        <Dialog {...options}>
            <AlertDialogContentStyled {...props} alertColor={type}>
                <div>
                    <header>
                        <div>
                            {type && AlertIcon && (
                                <i>
                                    <AlertIcon />
                                </i>
                            )}
                            <DialogHeading>{HtmlReactParser(title)}</DialogHeading>
                        </div>
                        {description && <DialogDescription>{HtmlReactParser(description)}</DialogDescription>}
                    </header>
                    {children}
                </div>

                <DialogClose />
                {showProgressBar && (
                    <ProgressBarAlertStyled autoCloseDelay={totalSeconds} alertColor={type}>
                        <div />
                    </ProgressBarAlertStyled>
                )}
            </AlertDialogContentStyled>
        </Dialog>
    );
};
