import styled, { DefaultTheme } from 'styled-components';
import { SubscriptionInvoiceStatusEnum } from '@models';
import { Card } from '~/components/elements/card';
import { Text } from '~/components/elements/text';

export const MyPaymentsContainer = styled.div`
    margin-top: 8rem;
    margin-bottom: 20rem;

    h1 {
        margin-bottom: 3.2rem;
    }
`;

export const CardStyled = styled(Card)`
    padding: 3.2rem 4rem;
    display: flex;
    flex-direction: column;

    & > header {
        padding: 0;
        margin-bottom: 2.4rem;

        h1 {
            margin-bottom: 0;
        }
    }
`;

export const PaymentContainerStyled = styled.div`
    overflow-x: auto;
`;

export const PaymentsListStyled = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 1rem;
    margin-bottom: 1.6rem;
    border: 1px solid ${({ theme }) => theme.colors.lightPurple};
`;

export const PaymentStyled = styled.li`
    width: fit-content;
    min-width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.8rem 2.4rem;

    & + li {
        border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
    }

    &:first-child {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    &:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
`;

export const PaymentHeaderStyled = styled(PaymentStyled)`
    padding: 1.4rem 2.4rem;
`;

export const PaymentContentStyled = styled.div`
    display: grid;
    grid-template-columns: 8rem 13rem 20.8rem 14.6rem 14.6rem 16.8rem 18rem 7rem 14.5rem;

    strong {
        color: ${({ theme }) => theme.colors.deepCerise};
        white-space: nowrap;

        &:not(:first-child) {
            text-align: center;
        }
    }

    & > div {
        justify-content: center;
    }

    span {
        white-space: nowrap;
        &:not(:first-child) {
            text-align: center;
        }
    }

    a {
        display: flex;

        button {
            text-decoration: underline;
            white-space: nowrap;
            padding: 0;
        }
    }
`;

const paymentStatusColor = (theme: DefaultTheme) => ({
    [SubscriptionInvoiceStatusEnum.Error]: theme.alertColors.error,
    [SubscriptionInvoiceStatusEnum.ExpiredCanceled]: theme.alertColors.error,
    [SubscriptionInvoiceStatusEnum.Vanquished]: theme.alertColors.error,
    [SubscriptionInvoiceStatusEnum.PaymentOK]: theme.alertColors.success,
    [SubscriptionInvoiceStatusEnum.Waiting]: theme.alertColors.warning,
    [SubscriptionInvoiceStatusEnum.Created]: theme.colors.lightBlack
});

export const PaymentStatusStyled = styled(Text)<{ status?: SubscriptionInvoiceStatusEnum }>`
    color: ${({ status, theme }) => paymentStatusColor(theme)[status] ?? theme.colors.lightBlack};
`;

export const EmptyListStyled = styled(PaymentStyled)`
    width: 100%;
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.lightPurple};
    display: inline-block;
    padding: 1.4rem 2.4rem;
`;
