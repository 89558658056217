import { CardBody, CardHeader, Shimmer } from '@components';
import { useTranslator } from '@hooks';
import { SubToolIdEnum } from '@models';
import { useAutomationStatisticsProvider } from '@providers';
import { CustomTriggerItem } from './customTriggerItem';
import { StatisticsPerSubToolHeader } from './header';
import { StatisticsPerSubToolContainer, SubToolListStyled } from './styles';
import { SubToolItem } from './subToolItem';

export const AutomationStatisticsPerSubTool = () => {
    const { isLoading: isSubToolLoading, data: subToolData } = useAutomationStatisticsProvider(
        (state) => state.generalStatistics
    );
    const { isLoading: isCustomTriggerLoading, data: customTriggerData } = useAutomationStatisticsProvider(
        (state) => state.generalCustomTriggerStatistics
    );
    const { subToolStatistics = [] } = subToolData ?? {};

    const {
        pages: {
            automationStatistics: { statisticsSection }
        }
    } = useTranslator();

    return (
        <StatisticsPerSubToolContainer type='group' borderColor='purple'>
            <CardHeader title={String(statisticsSection.title)} />
            <CardBody>
                <SubToolListStyled>
                    <Shimmer isActive={isSubToolLoading || isCustomTriggerLoading}>
                        <StatisticsPerSubToolHeader />

                        {subToolStatistics
                            .filter((subTool) => subTool.subToolId !== SubToolIdEnum.CustomTrigger)
                            .map((subTool) => (
                                <SubToolItem key={subTool.subToolId} subTool={subTool} data-shimmer />
                            ))}

                        {customTriggerData.map((customTrigger) => (
                            <CustomTriggerItem
                                key={customTrigger.customTriggerId}
                                customTrigger={customTrigger}
                                data-shimmer
                            />
                        ))}
                    </Shimmer>
                </SubToolListStyled>
            </CardBody>
        </StatisticsPerSubToolContainer>
    );
};
