import { Subscription, SubscriptionTool } from '@models';

export enum SubscriptionInvoiceStatusEnum {
    Created = 0,
    Waiting = 1,
    PaymentOK = 2,
    Refused = 3,
    Error = 4,
    ExpiredCanceled = 5,
    CanceledByReimbursement = 6,
    SecondCopy = 7,
    PlanCanceled = 8,
    BillingByThirdParty = 9,
    BillingByThirdPartyCPA = 10,
    Vanquished = 11
}

export enum SubscriptionInvoiceTypeEnum {
    None = 0,
    Recurrent = 1,
    Single = 2
}

export type SubscriptionInvoice = {
    id?: number;
    subscription: Subscription;
    bankSlipBarcode: string;
    bankSlipUrl: string;
    emissionDate: Date;
    paymentDate?: Date;
    dueDate: Date;
    description: string;
    status: SubscriptionInvoiceStatusEnum;
    type: SubscriptionInvoiceTypeEnum;
    gatewayId: string;
    isCredited: boolean;
    value: number;
    currency?: string;
    subscriptionInvoiceTools?: Array<SubscriptionTool>;
};
