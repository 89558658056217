import HTMLReactParser from 'html-react-parser';
import { useTranslator } from '@hooks';
import { format } from '~/utils/whatsappFormat';
import {
    ContainerStyled,
    UserBarStyled,
    ArrowLeftStyled,
    NoPictureStyled,
    VideoCallStyled,
    PhoneIconStyled,
    MoreIconStyled,
    ConversationStyled,
    ConversationContainerStyled,
    TickIconStyled,
    ProfileStyled,
    ActionItemsGroupStyled,
    UserBarContainerStyled
} from './styles';

type WhatsAppChatProps = {
    message?: string;
};

export const WhatsAppChat = ({ message }: WhatsAppChatProps) => {
    const { customerChat } = useTranslator().pages.subTool.cartRecovery.template.whatsAppManual;

    return (
        <ContainerStyled>
            <UserBarStyled>
                <UserBarContainerStyled>
                    <ProfileStyled>
                        <ArrowLeftStyled />
                        <NoPictureStyled />
                        <span>{String(customerChat)}</span>
                    </ProfileStyled>
                    <ActionItemsGroupStyled>
                        <VideoCallStyled />
                        <PhoneIconStyled />
                        <MoreIconStyled />
                    </ActionItemsGroupStyled>
                </UserBarContainerStyled>
            </UserBarStyled>
            <ConversationStyled>
                <ConversationContainerStyled>
                    <div className='message received'>{HTMLReactParser(format(message))}</div>
                    <div className='message sent'>
                        😀
                        <span>
                            <span>
                                <TickIconStyled />
                            </span>
                        </span>
                    </div>
                </ConversationContainerStyled>
            </ConversationStyled>
        </ContainerStyled>
    );
};
