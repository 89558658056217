import { useRef, useMemo, useState } from 'react';
import _first from 'lodash/first';
import * as yup from 'yup';
import { captureException } from '@sentry/nextjs';
import type { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import {
    AutomaticContractingLooseBalanceForm,
    useAutomaticContractingLooseBalanceValidation,
    LooseBalanceCredits
} from '~/components/containers/contractLooseBalance';
import { Button } from '~/components/elements/button';
import { Dialog, DialogContent, DialogClose, DialogFooter } from '~/components/elements/dialog';
import { useApi } from '~/hooks/useApi';
import { useFetch } from '~/hooks/useFetch';
import { useTranslator } from '~/hooks/useTranslator';
import { Subscription } from '~/models/subscription';
import { AutomaticContractingLooseBalance, CustomerPlanType } from '~/models/subscriptionTool';
import { ToolTypeEnum } from '~/models/tool';
import { AlertOptions, useAlert } from '~/providers/alertProvider';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import {
    GetAutomaticContractingLooseBalanceAsync,
    UpdateAutomaticContractingLooseBalanceAsync,
    AutomaticLooseBalanceResourceUrl
} from '~/services/subscriptionApiService';

export type AutomaticContractingLooseBalanceModalProps = {
    options: AlertOptions;
    subscription: Subscription;
    toolType: ToolTypeEnum;
    credits: Array<LooseBalanceCredits>;
    isLoading: boolean;
};

export const AutomaticContractingLooseBalanceModal = ({
    options,
    subscription,
    toolType,
    credits,
    isLoading: inLoading = false
}: AutomaticContractingLooseBalanceModalProps) => {
    const formRef = useRef<FormHandles>();
    const { error, success, warning } = useAlert();
    const { automaticContractingLooseBalanceSchema } = useAutomaticContractingLooseBalanceValidation();

    const { subscriptionToolType, subscriptionTool } = useMemo(() => {
        const subscriptionToolType =
            toolType === ToolTypeEnum.BulkSending ? CustomerPlanType.BulkSendingTool : CustomerPlanType.AutomationTool;

        const subscriptionTool = subscription.subscriptionTools.find((tool) => tool.type === subscriptionToolType);

        return { subscriptionToolType, subscriptionTool };
    }, [subscription.subscriptionTools, toolType]);

    const [subscriptionToolId, setSubscriptionToolId] = useState<number>(subscriptionTool?.Id);

    const {
        buttons,
        pages: {
            contractLooseBalance: { balanceContractingSection }
        },
        api: { statusError }
    } = useTranslator();

    const { data, isLoading: isGettingData } = useFetch(
        {
            url: AutomaticLooseBalanceResourceUrl,
            subscriptionToolType: subscriptionToolType,
            subscriptionId: subscription.id
        },
        async ({ subscriptionToolType, subscriptionId }, signal) => {
            const response = await GetAutomaticContractingLooseBalanceAsync(
                { subscriptionToolType, subscriptionId },
                signal
            );
            const subscriptionTool = response.data;

            setSubscriptionToolId(subscriptionTool?.Id);

            if (subscriptionTool?.automaticContracting?.minBalancePercentage) {
                const automaticContracting: AutomaticContractingLooseBalance = {
                    isEnable: subscriptionTool.automaticContracting.isEnable,
                    minBalancePercentage: subscriptionTool.automaticContracting.minBalancePercentage,
                    balanceToBeContracted: subscriptionTool.automaticContracting.balanceToBeContracted,
                    maxTimesToRecontract: subscriptionTool.automaticContracting.maxTimesToRecontract
                };

                formRef.current?.setData(automaticContracting);

                return automaticContracting;
            }
        },
        {
            keepPreviousData: true,
            fallbackData: {
                isEnable: false,
                minBalancePercentage: 0.9,
                balanceToBeContracted: _first(credits)?.id,
                maxTimesToRecontract: 3
            } as AutomaticContractingLooseBalance
        }
    );

    const { execute, loading: isUpdating } = useApi(UpdateAutomaticContractingLooseBalanceAsync, {
        onError: (exception) => {
            const errorMessage =
                exception instanceof EnviouApiResponseError
                    ? exception.getFirstError('contractLooseBalanceErrorStatus').message
                    : String(statusError.systemErrorStatus.internalError);

            error(errorMessage);
        },
        onSuccess: async (response) => {
            if ((await response).data) {
                success(String(balanceContractingSection.automaticContracting.success), null, { autoClose: true });

                options.onOpenChange(false);
            } else {
                warning('As alterações não foram salvas!', 'Verifique se os valores são diferentes dos iniciais.');
            }
        }
    });

    const handleSubmit: SubmitHandler<AutomaticContractingLooseBalance> = async (data) => {
        try {
            formRef.current.setErrors({});

            await automaticContractingLooseBalanceSchema.validate(data, {
                abortEarly: false
            });

            execute({ subscriptionId: subscription.id, subscriptionToolId, subscriptionToolType }, { ...data });
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((yupError) => (errorMessages[yupError.path] = yupError.message));
                formRef.current.setErrors(errorMessages);
            } else {
                captureException(exception);
            }
        }
    };

    const isLoading = inLoading || isUpdating || isGettingData;

    return (
        <Dialog {...options}>
            <DialogContent>
                <DialogClose />

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    aria-disabled={isLoading}
                    initialData={data}
                    id='AutomaticContractingLooseBalanceForm'>
                    <AutomaticContractingLooseBalanceForm credits={credits} />
                </Form>

                <DialogFooter>
                    <Button
                        type='reset'
                        form='AutomaticContractingLooseBalanceForm'
                        color='default'
                        buttonSize='small'
                        disabled={isLoading}
                        onClick={() => options.onOpenChange(false)}>
                        {String(buttons.cancel)}
                    </Button>
                    <Button
                        type='submit'
                        form='AutomaticContractingLooseBalanceForm'
                        color='purple'
                        buttonSize='small'
                        inLoading={isLoading}
                        disabled={isLoading}>
                        {String(buttons.save)}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
