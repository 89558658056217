import styled from 'styled-components';
import { Button } from '~/components/elements/button';
import { DialogContent } from '~/components/elements/dialog/content';
import { SubToolListStyled } from '../statisticsPerSubTool/styles';

export const SeeMoreButtonStyled = styled(Button)`
    text-decoration: underline;
    line-height: 2.4rem;
    align-self: flex-start;
    padding: 0;
`;

export const DialogContentStyled = styled(DialogContent)`
    padding: 4rem 3.2rem 3.2rem;
    margin-top: 8vh;
    width: 100%;
    max-width: ${({ theme }) => theme.container.rem.maxWidth}rem;

    h2 {
        text-align: start;
        text-transform: uppercase;
        margin-bottom: 3.2rem;
    }

    & > div > button {
        margin-top: 3.2rem;
    }
`;

export const Content = styled.div`
    overflow-x: auto;
`;

export const SendingTechnologyContentStyled = styled(SubToolListStyled)`
    margin-top: 2.4rem;
    grid-template-columns: 26rem auto;
    gap: 0.8rem;
    overflow-x: initial;

    li:first-child {
        margin-bottom: 0;
    }

    & + ul {
        margin-top: 1.6rem;
        padding: 1.6rem 0;
        border: 1px solid ${({ theme }) => theme.colors.lightPurple};
        border-radius: 1rem;
        gap: 1.6rem;
        width: fit-content;
    }
`;
