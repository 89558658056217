import { Children, cloneElement, isValidElement, ReactNode, useCallback } from 'react';
import { MultiStepItemProps } from './multiStepItem';
import { MultiStepMenuStyled, MultiStepMenuStyleProps, MultiStepMenuListStyled } from './styles';

type MultiStepMenuProps = {
    children?: ReactNode;
    /** The color of current background-color */
    backgroundColor?: string;
    currentStepIndex?: number;
} & MultiStepMenuStyleProps;

export const MultiStepMenu = ({ children, marginTop, backgroundColor, currentStepIndex = 0 }: MultiStepMenuProps) => {
    const getStepState = useCallback(
        (stepIndex: number): 'Unknown' | 'Concluded' | 'Selected' => {
            if (stepIndex < currentStepIndex) {
                return 'Concluded';
            } else if (stepIndex > currentStepIndex) {
                return 'Unknown';
            } else {
                return 'Selected';
            }
        },
        [currentStepIndex]
    );

    return (
        <MultiStepMenuStyled marginTop={marginTop} id='multiStepMenu'>
            <MultiStepMenuListStyled role='menu'>
                {Children.map<ReactNode, ReactNode>(children, (child, index) => {
                    if (isValidElement<MultiStepItemProps>(child)) {
                        return cloneElement(child, { backgroundColor, state: getStepState(index), ...child.props });
                    }
                })}
            </MultiStepMenuListStyled>
        </MultiStepMenuStyled>
    );
};

export { MultiStepItem } from './multiStepItem';

export default MultiStepMenu;
