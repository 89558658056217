import styled, { css } from 'styled-components';

export type RadioInputStyledProps = {
    backgroundColor?: string;
    borderColor?: string;
};

export type RadioButtonStyledProps = {
    testId?: string;
};

export const RadioButtonLabelStyled = styled.label.attrs<RadioButtonStyledProps>((props) => ({
    'data-testid': props.testId
}))<RadioButtonStyledProps>`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: start;
        align-items: center;
        cursor: pointer;
        padding: 1.6rem 1.8rem;
        border-radius: 0.4rem;
        border: 0.1rem solid ${theme.colors.purple};
        background-color: ${theme.default.backgroundColor};
        min-width: max-content;
        min-height: 7.2rem;
        flex: 1;

        &:hover {
            outline: ${({ theme }) => theme.colors.purple};
        }
    `}
`;

export const RadioInputStyled = styled.input.attrs<RadioInputStyledProps>({ type: 'radio' })<RadioInputStyledProps>`
    ${({
        theme,
        backgroundColor = theme.default.input.backgroundColor,
        borderColor = theme.default.input.borderColor
    }) => css`
        position: relative;
        display: inline-flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;
        left: 0;
        width: 1.25em;
        height: 1.25em;

        &::before {
            content: '';
            position: absolute;
            width: 1.25em;
            height: 1.25em;
            box-sizing: border-box;
            background-color: ${backgroundColor};
            border: 0.2rem solid ${borderColor};
            border-radius: 50%;
        }

        &:checked {
            &::after {
                content: '';
                position: absolute;
                width: 0.75em;
                height: 0.75em;
                border-radius: 50%;
                background-color: ${borderColor};
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    `}
`;

export const RadioButtonLabelTextStyled = styled.span`
    text-align: start;
    font: normal 1.6rem 'Roboto', sans-serif;
    cursor: pointer;
`;
