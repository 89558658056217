import { HTMLAttributes } from 'react';
import { Text } from '@components';
import { useNumberFunctions } from '@hooks';
import { getCompactValue } from '@utils';
import { BarChartLabelHighlightStyled, BarChartLabelStyled } from './styles';

type BarChartLabelData = {
    text: string;
    color?: string;
    value: number;
    showHighlightColor?: boolean;
};

type BarChartLabelProps = HTMLAttributes<HTMLLIElement> & BarChartLabelData;

export const BarChartLabel = ({ color, text, value, showHighlightColor = true, ...props }: BarChartLabelProps) => {
    const { formatNumber } = useNumberFunctions();
    return (
        <BarChartLabelStyled {...props}>
            <Text as='span' size='sm'>
                {showHighlightColor && <BarChartLabelHighlightStyled color={color} />}
                <span title={text}>{text}</span>
            </Text>
            <Text as='span' size='sm' title={formatNumber(value as number)}>
                {formatNumber(value as number, { notation: getCompactValue(value as number) })}
            </Text>
        </BarChartLabelStyled>
    );
};
