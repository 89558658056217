import { hoursToMilliseconds } from 'date-fns';
import { CardFlags, Text } from '@components';
import { BankSlipForm, CreditCardForm } from '@containers';
import { useTranslator, useFetch } from '@hooks';
import { PaymentMethodTypeEnum } from '@models';
import { GetCardFlags, PaymentApiRoutes } from '~/services/apiService';
import { AnimatedContainer, CardsAcceptedStyled } from './styles';

export const PaymentMethodForm = ({ paymentMethod }: { paymentMethod: PaymentMethodTypeEnum }) => {
    const { cardsAccepted } = useTranslator().checkoutPage.paymentMethod;
    const { data: cardFlags } = useFetch(
        { url: PaymentApiRoutes.CardFlags },
        async (_, signal) => (await GetCardFlags(signal)).data,
        {
            dedupingInterval: hoursToMilliseconds(1),
            keepPreviousData: true
        }
    );

    switch (paymentMethod) {
        case PaymentMethodTypeEnum.BankSlip:
            return (
                <AnimatedContainer>
                    <BankSlipForm />
                </AnimatedContainer>
            );
        case PaymentMethodTypeEnum.Gateway:
            return (
                <AnimatedContainer>
                    <div className='gateway-content' />
                </AnimatedContainer>
            );
        case PaymentMethodTypeEnum.CreditCard:
            return (
                <>
                    <CardsAcceptedStyled>
                        <div>
                            <Text as='span' size='md'>
                                {String(cardsAccepted)}
                            </Text>
                            <CardFlags cardFlags={cardFlags} height={1.77} />
                        </div>
                        <CreditCardForm />
                    </CardsAcceptedStyled>
                </>
            );
        default:
            return <></>;
    }
};
