import styled, { css } from 'styled-components';
import { Card } from '~/components/elements/card';
import { mobileAndTabletOnly } from '~/styles';

export const ContractLooseBalanceStyled = styled.div`
    margin-bottom: 10rem;

    & > p {
        margin-bottom: 3.2rem;
    }

    & > form > button {
        margin-top: 3.2rem;
        margin-left: auto;
    }
`;

export const ContractLooseBalanceContentStyled = styled.div`
    display: flex;
    gap: 3.2rem;

    ${mobileAndTabletOnly(css`
        flex-direction: column;
    `)}
`;

export const PaymentContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
`;

export const ContractingContentStyled = styled.div`
    display: flex;
    flex-direction: column;

    h5 {
        margin-top: 3.2rem;
        margin-bottom: 2.4rem;
        justify-content: start;
    }
`;

export const CardStyled = styled(Card)`
    padding: 3.2rem 4rem;
    display: flex;
    flex-direction: column;
    height: fit-content;

    & > header {
        padding: 0;
        margin-bottom: 3.2rem;
    }
`;

export const ContractingCardStyled = styled(CardStyled)`
    padding: 3.2rem 2.8rem;
    max-width: 49rem;
    height: auto;

    h5 {
        & > div {
            margin-left: 0;

            svg {
                height: 2rem;
            }
        }
    }

    ${mobileAndTabletOnly(css`
        max-width: 100%;
    `)}
`;

export const DiscountsCard = styled(CardStyled)`
    margin-top: 3.2rem;
    padding: 2.4rem 3rem !important;
    gap: 0 !important;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        & > strong,
        & > span {
            display: flex;
            gap: 1.6rem;
            justify-content: space-between;
        }

        & > span span:last-child {
            color: ${({ theme }) => theme.colors.deepCerise};
        }
    }
`;

export const TotalPayableCard = styled(CardStyled)`
    margin-top: 3.2rem;
    padding: 0.6rem 0 !important;
    gap: 0 !important;

    & > div {
        padding: 2.4rem 3rem;
    }

    & > div:first-child {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        gap: 1.6rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightPurple};

        strong {
            display: inline-block;
        }
    }
`;
