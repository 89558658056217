import { Fragment, MutableRefObject, useMemo, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import debounce from 'lodash/debounce';
import * as Yup from 'yup';
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    PhoneFrameMockup,
    SmsChat,
    TextareaFormGroup,
    ToggleSwitch,
    InfoTooltip,
    WhatsAppChat,
    Label
} from '@components';
import { CustomizationVariablesModal, CheckSecurityModal } from '@containers';
import { VariableParentKey, useTemplate, useTranslator } from '@hooks';
import { CustomizationVariable, SendingTechnologyTypeEnum, SubTool } from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles } from '@unform/core';
import {
    CUSTOM_VARIABLES_GUIDE_LINK,
    getOnlyNumbers,
    isDevelopment,
    MAX_LENGTH_TELEPHONE,
    MAX_MANUAL_CONTACT_MESSAGE_SIZE,
    MAX_SMS_MESSAGE_SIZE,
    MIN_LENGTH_TELEPHONE
} from '@utils';
import { HandleSendTestSMS, SendManualWhatsAppMessage } from '~/services/subToolService';
import {
    CardBodyStyled,
    Content,
    InputGroupStyled,
    PhoneContainer,
    SmsEditor,
    TitleStyled,
    ToolTipChildrenStyled,
    VariableInfoContainer,
    WhatsappLabelToolTipChildrenStyled
} from './styles';

type TextTemplateEditorProps = {
    title: string;
    subtitle: string;
    subTool?: SubTool;
    formRef: MutableRefObject<FormHandles>;
    initialValue: string;
    customizationVariables?: CustomizationVariable[];
    inputName: string;
    sendingTechnologyType?: SendingTechnologyTypeEnum;
    isDefaultTemplate?: boolean;
    subToolKeyIdentifier: VariableParentKey;
    textAreaPlaceholder?: string;
    isReduced?: boolean;
    onChangeEnableTechnology?: (checked: boolean, sendingTechnologyType: SendingTechnologyTypeEnum) => void;
    enableTechnology?: boolean;
    enableDefaultButton?: boolean;
    subToolColor?: string;
    showTechnologyEnableToggleSwitch?: boolean;
};

const WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_ON_CHANGE = 600;
const CELL_PHONE_FIELD_NAME = 'CellPhone';

// TODO: remove the coupling of sending technologies. This component must be visual only with no submission logic
// Create a WhatsappTemplateEdit and SmsTemplateEdit component and use TextTemplateEditor as child node
export const TextTemplateEditor = ({
    title,
    subtitle,
    formRef,
    initialValue,
    customizationVariables = [],
    inputName,
    sendingTechnologyType,
    isDefaultTemplate,
    subToolKeyIdentifier,
    textAreaPlaceholder,
    onChangeEnableTechnology,
    subToolColor,
    enableDefaultButton = false,
    enableTechnology = true,
    isReduced = false,
    showTechnologyEnableToggleSwitch = true
}: TextTemplateEditorProps) => {
    const [message, setMessage] = useState(initialValue ?? '');
    const [isDefault, setIsDefault] = useState(isDefaultTemplate ?? false);
    const { generateTemplatePreviewBySendingTechnology } = useTemplate(subToolKeyIdentifier);
    const { user } = useAuth();
    const cellPhoneInputName = `${inputName}${CELL_PHONE_FIELD_NAME}`;
    const { show, success, error } = useAlert();

    const variables = useMemo(() => {
        let variables = [];
        customizationVariables?.forEach((item) => {
            variables = [...variables, ...item.variables.map((variable) => variable?.toLowerCase())];
        });

        return variables.filter(Boolean);
    }, [customizationVariables]);

    const [formattedMessage, setFormattedMessage] = useState(() =>
        generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: initialValue,
            sendingTechnology: sendingTechnologyType,
            allowedVariables: variables
        })
    );

    const [isCheckSecurityModalOpen, setIsCheckSecurityModalOpen] = useState(false);

    const {
        dialogs: { successes, errors },
        subTools: {
            smsModelData: { customizationVariables: customizationVariablesText, errorMessages }
        },
        pages: {
            subTool: {
                cartRecovery: {
                    template: {
                        whatsAppManual: { textEditor, textFormatTooltip }
                    }
                }
            }
        }
    } = useTranslator();

    const openTestEmailModal = () => {
        setIsCheckSecurityModalOpen(true);
    };

    const closeCheckSecurityModal = async (recaptcha?: string) => {
        try {
            setIsCheckSecurityModalOpen(false);
            if (recaptcha) {
                const payload = {
                    to: getOnlyNumbers(formRef.current?.getFieldValue(cellPhoneInputName)),
                    text: formattedMessage,
                    recaptchaToken: recaptcha
                };

                switch (sendingTechnologyType) {
                    case SendingTechnologyTypeEnum.SMS:
                        await HandleSendTestSMS(payload)
                            .then((response) => {
                                if (!response?.data?.success) {
                                    error(
                                        String(errors.failedToSendTestSMS.title),
                                        String(errors.failedToSendTestSMS.description)
                                    );
                                } else {
                                    success(String(successes.successfullySendingTestSMS));
                                }
                            })
                            .catch((exception) => {
                                error(
                                    String(errors.failedToSendTestSMS.title),
                                    String(errors.failedToSendTestSMS.description)
                                );
                                captureException(exception);
                            });
                        break;
                }
            }
        } catch (exception) {
            captureException(exception);
        }
    };

    const openCustomizationVariablesModal = () => {
        show(null, null, {
            content: (data) => (
                <CustomizationVariablesModal
                    {...data}
                    customizationVariables={customizationVariables}
                    subToolKeyIdentifier={subToolKeyIdentifier}
                />
            )
        });
    };

    const handleTextChange = debounce((text: string) => {
        setMessage(text);

        const preview = generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: text,
            sendingTechnology: sendingTechnologyType ?? SendingTechnologyTypeEnum.SMS,
            allowedVariables: variables
        });

        setFormattedMessage(preview);
    }, WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_ON_CHANGE);

    const handleClick = async () => {
        try {
            const schema = Yup.object().shape({
                [inputName]: Yup.string().required(errorMessages.emptySms).nullable(),

                [cellPhoneInputName]: Yup.string()
                    .required(errorMessages.senderCellPhone.required)
                    .nullable()
                    .min(MIN_LENGTH_TELEPHONE, errorMessages.senderCellPhone.minLength)
                    .max(MAX_LENGTH_TELEPHONE, errorMessages.senderCellPhone.maxLength)
            });

            formRef.current.setFieldError(inputName, null);
            formRef.current.setFieldError(cellPhoneInputName, null);

            const message = formRef.current.getFieldValue(inputName);
            const cellPhoneInputValue = formRef.current.getFieldValue(cellPhoneInputName);
            await schema.validate(
                { [inputName]: message, [cellPhoneInputName]: cellPhoneInputValue },
                { abortEarly: false }
            );

            switch (sendingTechnologyType) {
                case SendingTechnologyTypeEnum.SMS:
                    openTestEmailModal();
                    break;
                case SendingTechnologyTypeEnum.WhatsAppManual:
                    SendManualWhatsAppMessage(cellPhoneInputValue, formattedMessage);
                    break;
                default:
                    break;
            }
        } catch (exception) {
            if (exception instanceof Yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));

                const currentErrors = formRef.current.getErrors();
                formRef.current.setErrors({ ...currentErrors, ...errorMessages });

                isDevelopment() && console.log(errorMessages);
            } else {
                captureException(exception);
                error(String(errors.failedToSendTestSMS.validationError), (exception as Error)?.message);
            }
        }
    };

    const getMaxMessageSize = () => {
        return sendingTechnologyType === SendingTechnologyTypeEnum.SMS
            ? MAX_SMS_MESSAGE_SIZE
            : MAX_MANUAL_CONTACT_MESSAGE_SIZE;
    };

    const getPhoneScreenContentBySendingTechnology = () => {
        return sendingTechnologyType === SendingTechnologyTypeEnum.SMS ? (
            <SmsChat message={formattedMessage} />
        ) : (
            <WhatsAppChat message={formattedMessage} />
        );
    };

    const variablesHelp =
        sendingTechnologyType === SendingTechnologyTypeEnum.SMS
            ? customizationVariablesText.helpButton.title
            : customizationVariablesText.helpButton.titleWhatsapp;

    return (
        <>
            <Card type='group' borderColor={subToolColor}>
                <CardHeader
                    title={
                        <TitleStyled>
                            {showTechnologyEnableToggleSwitch && (
                                <ToggleSwitch
                                    name={`${inputName}Enabled`}
                                    onChange={(event) =>
                                        onChangeEnableTechnology(event.target.checked, sendingTechnologyType)
                                    }
                                    enableUnform
                                    checked={enableTechnology}
                                />
                            )}
                            {title}
                        </TitleStyled>
                    }
                    actions={
                        customizationVariables &&
                        enableTechnology && (
                            <VariableInfoContainer>
                                <Button type='button' onClick={openCustomizationVariablesModal}>
                                    <InfoTooltip>
                                        <ToolTipChildrenStyled onClick={(event) => event.stopPropagation()}>
                                            {String(variablesHelp)}
                                            <a
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href={CUSTOM_VARIABLES_GUIDE_LINK}>
                                                {String(customizationVariablesText.helpButton.clickHere)}
                                            </a>
                                        </ToolTipChildrenStyled>
                                    </InfoTooltip>
                                    {String(customizationVariablesText.label)}
                                </Button>
                            </VariableInfoContainer>
                        )
                    }
                />
                <CardBodyStyled cardChild isExpanded={enableTechnology ?? true}>
                    <Content
                        isExpanded={enableTechnology}
                        initial={{ height: enableTechnology === false ? 0 : 'auto' }}>
                        <SmsEditor flexGrid={{ mobile: 12, desktop: 6 }}>
                            <div className='textInfoGroup'>
                                <Label>
                                    {subtitle}
                                    {sendingTechnologyType === SendingTechnologyTypeEnum.WhatsAppManual && (
                                        <InfoTooltip>
                                            <WhatsappLabelToolTipChildrenStyled>
                                                <h3>{textFormatTooltip.title}</h3>
                                                <p>{textFormatTooltip.description}</p>
                                                {Object.keys(textFormatTooltip.format).map((item) => {
                                                    return (
                                                        <Fragment key={item}>
                                                            <h4>{textFormatTooltip.format[item].title}</h4>
                                                            <p>
                                                                {HTMLReactParser(
                                                                    textFormatTooltip.format[item].description
                                                                )}
                                                            </p>
                                                        </Fragment>
                                                    );
                                                })}
                                            </WhatsappLabelToolTipChildrenStyled>
                                        </InfoTooltip>
                                    )}
                                </Label>
                                <TextareaFormGroup
                                    name={inputName}
                                    cols={30}
                                    rows={6}
                                    maxLength={getMaxMessageSize()}
                                    onChange={(event) => handleTextChange(event.target.value)}
                                    defaultValue={message}
                                    placeholder={textAreaPlaceholder}
                                />
                                <span>
                                    {message?.length ?? 0}/{getMaxMessageSize()}
                                </span>
                                {enableDefaultButton === true && (
                                    <Checkbox
                                        enableUnform
                                        name='isDefault'
                                        checked={isDefault}
                                        onChange={(event) => setIsDefault(event.currentTarget.checked)}>
                                        <b>{String(textEditor.isDefault)}</b>
                                    </Checkbox>
                                )}
                            </div>
                            <InputGroupStyled
                                inputType='tel'
                                inputMask='phone'
                                inputName={`${inputName}${CELL_PHONE_FIELD_NAME}`}
                                buttonText={String(textEditor.sendTestMessageButtonText)}
                                inputPlaceHolder={String(textEditor.inputPlaceHolder)}
                                onClick={handleClick}
                            />
                        </SmsEditor>

                        <PhoneContainer flexGrid={{ mobile: 12, desktop: 6 }}>
                            <PhoneFrameMockup isReduced={isReduced}>
                                {getPhoneScreenContentBySendingTechnology()}
                            </PhoneFrameMockup>
                        </PhoneContainer>
                    </Content>
                </CardBodyStyled>
            </Card>
            <CheckSecurityModal onClose={closeCheckSecurityModal} isOpen={isCheckSecurityModalOpen} />
        </>
    );
};
