import { Button } from '@components';
import RemoveIcon from '~/assets/svg/icons/icon__remove.svg';
import { RuleDefaultProps } from '../';
import { ActionsStyled } from './styles';

type RemoveRuleButtonProps = Pick<RuleDefaultProps, 'onRemove' | 'rule' | 'ruleElementAnimationIndex'> & {
    isVisible?: boolean;
};

export const RemoveRuleButton = ({
    rule,
    ruleElementAnimationIndex,
    onRemove,
    isVisible = true
}: RemoveRuleButtonProps) => {
    if (!isVisible) {
        return <></>;
    }

    return (
        <ActionsStyled>
            <Button testId={`remove-button-${rule.id}`} onClick={() => onRemove(rule.id, ruleElementAnimationIndex)}>
                <RemoveIcon />
            </Button>
        </ActionsStyled>
    );
};
