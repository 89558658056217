import { useEffect } from 'react';
import { isBefore, parseISO } from 'date-fns';
import { Card, CardBody, FormGroup, InputFormGroup } from '@components';
import { useTranslator } from '@hooks';
import { useCheckout } from '@providers';
import { DateFormatEnum, formatDate, addDays, BANK_SLIP_COMPENSATION_OFFSET } from '@utils';

export const BankSlipForm = () => {
    const { bankSlipForm } = useTranslator().checkoutPage.subscriptionPayment;
    const { formRef } = useCheckout();
    useEffect(() => {
        const firstDueDate = formRef.current.getFieldValue('subscriptionPayment.bankSlip.firstDueDate');

        if (!firstDueDate || isBefore(parseISO(firstDueDate), new Date())) {
            formRef.current.setFieldValue(
                'subscriptionPayment.bankSlip.firstDueDate',
                formatDate(addDays(new Date(), BANK_SLIP_COMPENSATION_OFFSET), DateFormatEnum.OnlyDateUSFormat)
            );
        }
    }, [formRef]);

    return (
        <Card>
            <CardBody>
                <FormGroup enableUnform unformScopePath='bankSlip'>
                    <InputFormGroup
                        type='date'
                        name='firstDueDate'
                        inputSize='small'
                        label={String(bankSlipForm.firstDueDate.label)}
                        flexGrid={{ tabletAndDesktop: 12, mobile: 12 }}
                        min={formatDate(new Date(), DateFormatEnum.OnlyDateUSFormat)}
                        testId='input__first-due-date'
                    />
                </FormGroup>
            </CardBody>
        </Card>
    );
};
