import { forwardRef } from 'react';
import { useTranslator } from '@hooks';
import { Form } from '@unform/web';
import { DateFilterOptionsWithCustomTimePeriod } from '@utils';
import { DropdownStyled } from './styles';

type FilterDropdownProps = {
    value?: string;
    onDropdownChange?: (selectedValue?: DateFilterOptionsWithCustomTimePeriod) => void;
    filterDateType?: DateFilterOptionsWithCustomTimePeriod;
    disabled?: boolean;
};

export const FilterDropdown = forwardRef<HTMLButtonElement, FilterDropdownProps>(function FilterDropdown(
    { filterDateType, value, disabled, onDropdownChange },
    ref
) {
    const {
        common: {
            dropdown: {
                dateFilters: { options, groupTitle }
            }
        }
    } = useTranslator();

    return (
        <Form onSubmit={() => null}>
            <DropdownStyled
                isDatePicker
                ref={ref}
                inputSize='small'
                name='date'
                disabled={disabled}
                enableUnform={false}
                value={filterDateType}
                onChange={(value) =>
                    onDropdownChange && onDropdownChange(value as DateFilterOptionsWithCustomTimePeriod)
                }
                options={[
                    { value: 'group-title', text: String(groupTitle), type: 'group-title', elementType: 'span' },
                    { value: 'today', text: String(options.today) },
                    { value: 'thisWeek', text: String(options.thisWeek) },
                    { value: 'thisMonth', text: String(options.thisMonth) },
                    { value: 'thisYear', text: String(options.thisYear) },
                    { value: 'first-separator', type: 'separator', elementType: 'span' },
                    { value: 'last30Days', text: String(options.last30Days) },
                    { value: 'last60Days', text: String(options.last60Days) },
                    { value: 'last90Days', text: String(options.last90Days) },
                    { value: 'second-separator', type: 'separator', elementType: 'span' },
                    { value: 'customizeTimePeriod', text: String(options.customizeTimePeriod) },
                    { value: 'date', text: value, isHidden: true }
                ]}
            />
        </Form>
    );
});
