import styled from 'styled-components';
import { IFrame } from '~/components/elements/iframe';

export const EmailTemplatePreviewStyled = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 62rem;
    height: 100%;

    & > div:last-child {
        flex: 1;
    }
`;

export const PreviewStyled = styled(IFrame)`
    width: 100%;
    height: 100%;
`;

export const PreviewButton = styled.div`
    margin-bottom: 1rem;
    display: flex;
    gap: 1.6rem;

    button {
        padding: 1rem 0;
    }
`;
