import styled, { css } from 'styled-components';
import ArrowDownIcon from '~/assets/svg/controls/arrow--down.svg';
import BaterryIcon from '~/assets/svg/icons/icon__battery.svg';
import ChromeIcon from '~/assets/svg/icons/icon__chrome.svg';
import ImageIcon from '~/assets/svg/icons/icon__image-file.svg';
import MoreHorizontal from '~/assets/svg/icons/icon__more.svg';
import WifiIcon from '~/assets/svg/icons/icon__wifi.svg';
import { tabletAndDesktopOnly } from '~/styles';

export const OsWrapperStyled = styled.div`
    background-color: ${({ theme }) => theme.colors.philippineSilver};
    display: flex;
    flex-direction: column;
    max-width: 42rem;
    min-width: 37rem;

    ${tabletAndDesktopOnly(css`
        width: 42rem;
    `)}
`;

export const TaskbarStyled = styled.div`
    background-color: ${({ theme }) => theme.colors.seaSalt};
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.8rem;
    align-items: center;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.gallery};

    p {
        color: ${({ theme }) => theme.colors.gray};
        font-size: 1.4rem;
        letter-spacing: 0.05rem;
    }
`;
export const NotificationContainerStyled = styled.div`
    display: grid;
    width: auto;

    grid-template-rows: 2.6rem 16rem 1fr auto;
    background-color: ${({ theme }) => theme.colors.white};
    margin: 1rem 0.8rem 0.6rem 5rem;
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    border-radius: ${({ theme }) => theme.radii.lg};

    .notification-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 1rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.seaSalt};

        .browser-info {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .browser-name {
                font-size: 1.2rem;
                line-height: unset;
                color: ${({ theme }) => theme.colors.geminiGray};
            }
        }

        .notification-header-icons {
            display: flex;
            align-items: center;
            gap: 0.6rem;
        }
    }

    .no-image {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.gallery};
    }

    .notification-body {
        padding: 0 1rem;
        margin-top: 1rem;
        display: inline-grid;
        grid-template-columns: 4rem auto;
        gap: 1rem;

        .icon {
            background-color: ${({ theme }) => theme.colors.gallery};
            height: 3.8rem;
            width: 3.8rem;
            display: flex;
            border-radius: 6px;
            justify-content: center;
            align-items: center;
        }

        .notification-message {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: ${({ theme }) => theme.colors.geminiGray};

            .title {
                font-size: 1.4rem;
                font-weight: ${({ theme }) => theme.fontWeights.medium};
                color: ${({ theme }) => theme.colors.geminiGray};
                margin-bottom: 0.4rem;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .domain {
                font-size: 1.2rem;
                margin-bottom: 0.4rem;
            }

            .text {
                font-size: 1.2rem;
                margin-bottom: 0.4rem;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .notification-actions {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1rem;
        padding-left: 0 1rem;
        padding: 0 1rem;

        .action-button {
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 2.6rem;
            padding-top: 0.4rem;
            background-color: ${({ theme }) => theme.colors.white};
            line-height: unset;
            color: ${({ theme }) => theme.colors.geminiGray};
            border-top: 1px solid ${({ theme }) => theme.colors.gallery};
            text-align: center;
            text-decoration: none;
            font-size: 1.2rem;
            cursor: pointer;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const WifiIconStyle = styled(WifiIcon)`
    width: 2.2rem;
`;

export const BaterryIconStyled = styled(BaterryIcon)`
    width: 2.5rem;
`;

export const ChromeIconStyle = styled(ChromeIcon)`
    fill: ${({ theme }) => theme.colors.geminiGray};
    width: 1.5rem;
`;

export const ImageIconStyle = styled(ImageIcon)`
    width: 2.5rem;
`;

export const ArrowDownStyled = styled(ArrowDownIcon)`
    align-self: center;
    width: 1rem;
    height: 1rem;
    color: ${({ theme }) => theme.colors.gray};
`;

export const MoreHorizontalStyled = styled(MoreHorizontal)`
    transform: rotate(90deg);
    width: 1.2rem;
    height: 1.2rem;
    color: ${({ theme }) => theme.colors.gray};
`;
