export enum CustomTriggerStatus {
    Inactive = 0,
    Active = 1
}

export type CustomTrigger = {
    id: number;
    name: string;
    token: string;
    status: CustomTriggerStatus;
    deletedDate?: Date;
};
