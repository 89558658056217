import { isValidPhoneNumber } from 'libphonenumber-js';
import _lowerFirst from 'lodash/lowerFirst';
import { emailRegex, getOnlyNumbers, DEFAULT_COUNTRY_TO_VALIDATE_CELL_NUMBER } from '@utils';
import { ImportContactFields } from './fieldItem';

export enum GenerateContactListErrorCode {
    MappingNotConfigured = 'mappingNotConfigured',
    AllInvalidContacts = 'allInvalidContacts'
}

export class GenerateContactListError extends Error {
    constructor(public message: GenerateContactListErrorCode) {
        super(message);
        this.name = 'GenerateContactListError';

        Object.setPrototypeOf(this, GenerateContactListError.prototype);
    }
}

type ContactFieldIndexes = {
    [key in Uncapitalize<keyof typeof ImportContactFields>]?: number;
};

type ContactFields = { [key: string]: ImportContactFields };

type MappedField = {
    fieldName: string;
    value: ImportContactFields;
    index: number;
};

const getMappedFields = (fields: ContactFields) => {
    const mappedFields = Object.entries(fields)
        .map(([fieldName, value], index) => ({ fieldName, value, index } as MappedField))
        .filter((item) => !!item.value);

    const hasEmailOrCellPhone = mappedFields.find(
        (item) => item.value === ImportContactFields.CellPhone || item.value === ImportContactFields.Email
    );
    const isConfiguredMapping = mappedFields.length > 0 && hasEmailOrCellPhone;

    if (!isConfiguredMapping) {
        throw new GenerateContactListError(GenerateContactListErrorCode.MappingNotConfigured);
    }

    return mappedFields;
};

const reduceMappedFields = (mappedFields: Array<MappedField>): ContactFieldIndexes => {
    return mappedFields.reduce<ContactFieldIndexes>(
        (prev, current) => {
            prev[_lowerFirst(ImportContactFields[Number(current.value)])] = current.index;
            return prev;
        },
        { name: null, email: null, cellPhone: null }
    );
};

const generateContactListByFileContent = (
    fileLinesContent: Array<Array<string>>,
    contactFieldIndexes: ContactFieldIndexes
) => {
    // I use flatMap because with it it is possible to skip the array item that is not valid.
    // With this it is not necessary to make a map and then a filter to remove the invalid items
    const contacts = fileLinesContent.flatMap((line) => {
        const email = line[contactFieldIndexes.email] ?? null;
        const cellPhone = getOnlyNumbers(line[contactFieldIndexes.cellPhone]) || null;
        const isValidEmail = email ? emailRegex.test(email) : false;
        const isValidCellPhone = cellPhone
            ? isValidPhoneNumber(cellPhone, DEFAULT_COUNTRY_TO_VALIDATE_CELL_NUMBER)
            : false;

        const isContactValid = isValidEmail || isValidCellPhone;
        if (!isContactValid) {
            return [];
        }

        return {
            name: line[contactFieldIndexes.name] ?? null,
            email: isValidEmail ? email : null,
            cellPhone: isValidCellPhone ? cellPhone : null,
            infoAdd1: line[contactFieldIndexes.infoAdd1] ?? null,
            infoAdd2: line[contactFieldIndexes.infoAdd2] ?? null
        };
    });

    if (contacts.length === 0) {
        throw new GenerateContactListError(GenerateContactListErrorCode.AllInvalidContacts);
    }

    return contacts;
};

export const generateContactList = (fields: ContactFields, fileLinesContent: Array<Array<string>>) => {
    const mappedFields = getMappedFields(fields);
    const contactFieldIndexes = reduceMappedFields(mappedFields);

    return generateContactListByFileContent(fileLinesContent, contactFieldIndexes);
};

export const generateContactListAsync = async (fields: ContactFields, fileLinesContent: Array<Array<string>>) => {
    return new Promise((resolve, reject) => {
        try {
            const contacts = generateContactList(fields, fileLinesContent);
            resolve(contacts);
        } catch (error) {
            reject(error);
        }
    });
};
