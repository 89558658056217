import { ReactNode } from 'react';
import { ExpandableGroup } from '../expandableGroup';
import { HeadingProps } from '../heading';
import { TextProps } from '../text';
import { useNoticeCard } from './root';
import {
    AdditionalGroup,
    NoticeGroupContentBodyStyled,
    NoticeGroupDescriptionStyled,
    NoticeGroupStyled,
    NoticeGroupTitleStyled
} from './styles';

type NoticeCardContentProps = { children?: ReactNode; className?: string };
type NoticeCardContentBodyProps = { children?: ReactNode; className?: string };

export const NoticeCardContent = ({ children, className }: NoticeCardContentProps) => {
    const { variant } = useNoticeCard();
    return (
        <NoticeGroupStyled variant={variant} className={className}>
            {children}
        </NoticeGroupStyled>
    );
};

export const NoticeCardExpandableContent = ({ children, className }: NoticeCardContentBodyProps) => {
    const { isOpen, initialExpansionValueNoticeCard } = useNoticeCard();

    if (!children) {
        return <></>;
    }

    return (
        <AdditionalGroup isExpanded={isOpen} initial={initialExpansionValueNoticeCard} className={className}>
            {children}
        </AdditionalGroup>
    );
};

export const NoticeCardContentBody = ({ children, className }: NoticeCardContentBodyProps) => {
    return <NoticeGroupContentBodyStyled className={className}>{children}</NoticeGroupContentBodyStyled>;
};

export const NoticeCardTitle = ({ children, as = 'h5', ...props }: HeadingProps) => {
    const { variant } = useNoticeCard();
    return (
        <NoticeGroupTitleStyled variant={variant} as={as} {...props}>
            {children}
        </NoticeGroupTitleStyled>
    );
};

export const NoticeCardDescription = ({ children, ...props }: TextProps) => {
    const { isOpen, initialExpansionValueNoticeCard } = useNoticeCard();

    if (!children) {
        return <></>;
    }

    return (
        <ExpandableGroup isExpanded={isOpen} initial={initialExpansionValueNoticeCard}>
            <NoticeGroupDescriptionStyled as='div' size='md' {...props}>
                {children}
            </NoticeGroupDescriptionStyled>
        </ExpandableGroup>
    );
};
