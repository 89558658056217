import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import { CardBody } from '~/components/elements/card/body';
import { CardHeader } from '~/components/elements/card/header';
import { useTranslator } from '~/hooks/useTranslator';
import { SendingTechnologyTypeEnum } from '~/models/sendingTechnology';
import { CustomerPlanType } from '~/models/subscriptionTool';
import { ToolTypeEnum } from '~/models/tool';
import { useMySubscriptionProvider } from '~/providers/mySubscriptionProvider';
import { CurrentSendingTechnologies, CurrentSendingTechnologyValue } from '../currentSendingTechnologies';
import { AutomationCreditsBarChart } from './automationCreditsBarChart';
import { LooseCreditsBarChart } from './looseCreditsBarChart';
import { AutomationContentStyled, CardStyled } from './styles';

export const MarketingAutomationSummary = () => {
    const {
        common,
        pages: { mySubscription }
    } = useTranslator();

    const { isLoading, automationTool, subscription, tools } = useMySubscriptionProvider((state) => {
        return {
            automationTool: state.automationTool,
            isLoading: state.isLoading,
            subscription: state.subscription,
            tools: state.tools
        };
    });

    const sendingTechnologies = useMemo(() => {
        return _orderBy(
            subscription.subscriptionTools
                ?.find((tool) => tool.type === CustomerPlanType.AutomationTool)
                ?.sendingTechnologies.map<CurrentSendingTechnologyValue>((item) => {
                    const current = SendingTechnologyTypeEnum[item.sendingTechnology.type];

                    return {
                        id: item.sendingTechnology.id,
                        name: common.sendingTechnology[current.toLowerCase()],
                        value: item.price
                    };
                }),
            'name'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);

    return (
        <CardStyled type='group' borderColor='purple'>
            <CardHeader title={String(mySubscription.automations.marketingAutomation)} />
            <CardBody>
                <AutomationContentStyled>
                    <AutomationCreditsBarChart
                        haveUnlimitedBalance={automationTool.haveUnlimitedBalance}
                        totalCredits={automationTool.totalCredits}
                        usedCreditsAmount={automationTool.totalUsedCredits}
                        labels={automationTool.summary}
                        barChartItems={automationTool.summary}
                        isLoading={isLoading}
                    />
                    <LooseCreditsBarChart
                        totalCredits={automationTool.totalLooseCredits}
                        usedCreditsAmount={automationTool.totalUsedLooseCredits}
                        toolType={ToolTypeEnum.AutomationTool}
                        isLoading={isLoading}
                        subscription={subscription}
                        tools={tools}
                    />
                    <CurrentSendingTechnologies sendingTechnologies={sendingTechnologies} />
                </AutomationContentStyled>
            </CardBody>
        </CardStyled>
    );
};
