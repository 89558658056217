import styled, { css, DefaultTheme } from 'styled-components';
import { SubscriptionInvoiceStatusEnum } from '@models';
import { mobileAndTabletOnly, mobileOnly } from '~/styles';

type BankSlipInformationStyledProps = {
    subscriptionInvoiceStatus: SubscriptionInvoiceStatusEnum;
};

const setStyleForMobileSize = mobileOnly(css`
    flex-direction: column;
    button {
        width: 100%;
    }
`);

const setStyleForTabletAndMobileSize = mobileAndTabletOnly(css`
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;

    div {
        ${setStyleForMobileSize}
        strong {
            text-align: start;
        }
    }

    &:last-child {
        button {
            width: 100%;
        }
    }
`);

const bankSlipInformationStyle = (theme: DefaultTheme, { subscriptionInvoiceStatus }: BankSlipInformationStyledProps) =>
    css`
        ul {
            background-color: ${theme.colors.white};
            border-radius: 1rem;
            border: 0.1rem solid ${theme.colors.lightPurple};
            list-style: none;

            li {
                display: flex;
                justify-content: space-between;
                padding: 1.8rem 3rem;
                align-items: center;
                gap: 1.8rem;
                color: ${theme.colors.lightBlack};

                div {
                    display: flex;
                    gap: 1.8rem;
                    justify-content: space-between;
                    align-items: center;

                    strong {
                        flex: 1;
                        text-align: end;
                        max-width: 32rem;
                    }

                    button {
                        margin-top: 0;
                        span {
                            &.copy-animation {
                                animation: fade-animation 0.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                            }

                            @keyframes fade-animation {
                                0% {
                                    opacity: 0;
                                }
                                100% {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                &:first-child {
                    strong {
                        color: ${() => {
                            switch (subscriptionInvoiceStatus) {
                                case SubscriptionInvoiceStatusEnum.Error:
                                case SubscriptionInvoiceStatusEnum.ExpiredCanceled:
                                    return theme.default.dangerColor;
                                case SubscriptionInvoiceStatusEnum.PaymentOK:
                                    return theme.colors.greenLeaf;
                                default:
                                    return theme.colors.yellow;
                            }
                        }};
                    }
                }

                &:not(:last-child) {
                    border-bottom: 0.1rem solid ${theme.colors.lightPurple};
                }

                &:last-child {
                    justify-content: center;
                    button {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 27.4rem;
                    }
                }

                ${setStyleForTabletAndMobileSize}
            }
        }
    `;

export const BankSlipInformationStyled = styled.section<BankSlipInformationStyledProps>`
    ${({ theme, subscriptionInvoiceStatus }) => bankSlipInformationStyle(theme, { subscriptionInvoiceStatus })}
`;
