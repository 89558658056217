/**
 * Divides two numbers, providing a fallback if the denominator is zero.
 */
export const divideOrFallback = (numerator: number, denominator: number, fallback = 0): number => {
    if (denominator === 0) {
        return fallback;
    }

    return numerator / denominator;
};
