import _first from 'lodash/first';
import _isEmpty from 'lodash/isEmpty';
import { escapeRegex } from '@utils';

export const delimiter = {
    start: '[',
    end: ']'
};

export const normalizeVariable = (variableName: string) => {
    const { start, end } = delimiter;
    return variableName.replace(start, '').replace(end, '').toLowerCase();
};

export const makeVariable = (variableName: string) => `${delimiter.start}${variableName}${delimiter.end}`;

export const getAllParametersPatternFromRegexVariable = (variableName: string) => {
    const pattern = /\((.*?)\)/gi;
    return [...variableName.matchAll(pattern)];
};

const replaceVariableParameter = (variableName: string, paramValue: string, pattern: string) => {
    const regex = new RegExp(escapeRegex(pattern), 'i');
    const variable = variableName.replace(regex, paramValue);

    return variable.replaceAll('\\', '');
};

export const replaceRegexVariableParameterPattern = (
    templateVariableParams: RegExpMatchArray | [],
    regexVariable: string
) => {
    let templateVariableName = regexVariable;

    if (!_isEmpty(templateVariableParams)) {
        const paramPatterns = getAllParametersPatternFromRegexVariable(templateVariableName);

        for (let index = 0; index < paramPatterns.length; index++) {
            const paramPattern = _first(paramPatterns[index]);
            const paramValue = templateVariableParams[index + 1];

            templateVariableName = replaceVariableParameter(templateVariableName, paramValue, paramPattern);
        }

        return templateVariableName;
    }

    return null;
};
