import { ReactNode } from 'react';
import { DialogOptions, useDialog } from '@hooks';
import { DialogContext } from '~/providers';

export * from './closeButton';
export * from './content';
export * from './description';
export * from './heading';
export * from './trigger';
export * from './footer';

export const Dialog = ({
    children,
    ...options
}: {
    children: ReactNode;
} & DialogOptions) => {
    const dialog = useDialog(options);
    return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
};
