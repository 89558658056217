/* eslint-disable @typescript-eslint/ban-types */
export type DelimiterProps = {
    start: string;
    end: string;
};

export type ReplaceData<T extends object = {}> = T | string | number;

/**
 * Decodes a string into bytes using Latin-1 (ISO-8859), and encodes those bytes into a string using Base64.
 * @param content The content string
 * @returns The encoded string
 */
export const toBase64 = (content: string): string => {
    return typeof window === 'undefined' ? Buffer.from(content).toString('base64') : window.btoa(content);
};

/**
 * Decodes a string of Base64-encoded data into bytes,
 * and encodes those bytes into a string using UTF8.
 * @param content The content string
 * @returns The decoded string
 */
export const fromBase64 = (content: string): string => {
    return typeof window === 'undefined' ? Buffer.from(content, 'base64').toString('utf-8') : window.atob(content);
};

const delimiterDefault: DelimiterProps = {
    start: '{{',
    end: '}}'
};

export const replace = <T extends object = {}>(
    string: string | object,
    data: ReplaceData<T> = '',
    delimiter = delimiterDefault
): string => {
    if (!string) {
        return;
    }
    const typeOf = typeof data;

    let { start, end } = delimiter;
    if (start === '[') {
        start = '\\[';
    }

    if (end === ']') {
        end = '\\]';
    }

    if (typeOf === 'string' || typeOf === 'number') {
        const regex = new RegExp(`${start}(.*?)${end}`, 'gi');

        return string.toString().replace(regex, data as string);
    }

    Object.keys(data).forEach((key) => {
        const regex = new RegExp(`${start}${escapeRegex(key)}${end}`, 'gi');
        const value = data[key];

        string = string.toString().replace(regex, value);
    });

    return string.toString();
};

export const getOnlyNumbers = (value: string): string => {
    return value?.replace(/\D/g, '');
};

/**
 * Escape values of a string such that characters that have a special meaning in a regex will no longer have.
 * E.g. escapeRegex('hello.') => 'hello\.'
 *
 * Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 */
export const escapeRegex = (value: string): string => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * In a given string `value`, replaces all instances of `searchValue` with `newValue`.
 *
 * This function should be used when compatibility with older versions of node / browsers is important.
 * It is ensured to work on node.js >= 14.
 */
export const replaceAll = (value: string, searchValue: string, newValue: string): string => {
    return value.replace(new RegExp(escapeRegex(searchValue), 'g'), () => newValue);
};
