import { HTMLAttributes, ReactNode } from 'react';
import HTMLReactParser from 'html-react-parser';
import { Text } from '../../text';
import { OptionStyledProps, OptionStyled } from './styles';

export type OptionItemType = 'item' | 'separator' | 'group-title';

export type OptionValueType = string | number | null;
export type OptionProps<T extends OptionValueType = OptionValueType> = Omit<
    HTMLAttributes<HTMLButtonElement>,
    'type' | 'onChange' | 'value'
> &
    OptionStyledProps & {
        children?: ReactNode;
        value: T;
        text?: string;
        disabled?: boolean;
        isHidden?: boolean;
        type?: OptionItemType;
        elementType?: keyof HTMLElementTagNameMap;
        onChange?: (value: T) => void;
    };

export const Option = <T extends OptionValueType = OptionValueType>({
    children,
    value,
    text,
    checked,
    onChange,
    type = 'item',
    elementType = 'button',
    isHidden = false,
    ...args
}: OptionProps<T>) => {
    if (isHidden) {
        return <></>;
    }

    return (
        <OptionStyled
            data-testid={value}
            checked={checked}
            value={value}
            as={elementType as never}
            {...args}
            className={`option-${type}`}
            role={type === 'separator' ? 'separator' : 'none'}
            onClick={() => type === 'item' && onChange(value)}
            type='button'>
            {type !== 'separator' && (children ?? <Text size='sm'>{HTMLReactParser(text)}</Text>)}
        </OptionStyled>
    );
};
