import styled from 'styled-components';

export const PluginsSectionStyled = styled.section`
    & > div {
        display: flex;

        ul {
            margin-top: 3.2rem;
            display: flex;
            flex: 1 1 auto;
            list-style: none;
            gap: 2.4rem;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }
`;
