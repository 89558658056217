import styled from 'styled-components';

export const ContainerStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
    padding: 4rem 2.5rem;
`;

export const SMSChatStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    width: 100%;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 95%;
        height: auto;
        margin: auto;
        padding: 1.5rem;
        min-height: 95px;
        background: #b6ebff;
        border-radius: 10px 10px 10px 0;
        box-shadow: 1px 1px 6px #2a334a26;

        p {
            height: auto;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            font-size: 1.5rem;
            text-align: left;
            width: 100%;
            color: black;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 16px 16px 0 0;
            border-color: #b6ebff transparent transparent transparent;
            filter: drop-shadow(1px 3px 3px #2a334a26);
        }
    }
`;
