import _isEmpty from 'lodash/isEmpty';
import { isGreaterThanOne, isGreaterThanZero, NO_CATEGORY, numberFormatter } from '@utils';
import translations from '~/locales/locales.json';

export type TranslationData = typeof translations;

type MakeRepurchaseDataDescriptionProps = {
    averageRepurchasePeriod?: number;
    countConsideredRepurchases?: number;
    translation: TranslationData;
    locale: string;
};

export const makeRepurchaseDataDescription = ({
    averageRepurchasePeriod,
    countConsideredRepurchases,
    translation,
    locale
}: MakeRepurchaseDataDescriptionProps) => {
    let repurchaseDetails = '';
    let averageRepurchasePeriodDescription = '';
    let countConsideredRepurchasesDescription = '';

    const {
        enums,
        pages: {
            manageRepurchaseItem: {
                sections: {
                    setup: {
                        listItem: { labels }
                    }
                }
            }
        }
    } = translation;

    if (isGreaterThanZero(averageRepurchasePeriod)) {
        const dayLabel = isGreaterThanOne(averageRepurchasePeriod)
            ? String(enums.typeMeasuresTimeEnum.days)
            : String(enums.singularSubToolTemplateConfigurationFrequencyRange.days);

        // Ex. en-US: 1 day, 10 days
        // Ex. pt-BR: 1 dia, 10 dias
        averageRepurchasePeriodDescription = `${numberFormatter(averageRepurchasePeriod, locale)} ${dayLabel}`;
    }

    if (isGreaterThanZero(countConsideredRepurchases)) {
        const repurchaseLabel = isGreaterThanOne(countConsideredRepurchases) ? labels.repurchases : labels.repurchase;

        // Ex. en-US: 1 repurchase, 10.000 repurchases
        // Ex. pt-BR: 1 recompra, 10.000 recompras
        countConsideredRepurchasesDescription = `${numberFormatter(
            countConsideredRepurchases,
            locale
        )} ${repurchaseLabel}`;
    }

    if (!_isEmpty(averageRepurchasePeriodDescription)) {
        // Ex. en-US: Suggested period: 10 days
        // Ex. pt-BR: Período Sugerido, 1 dia
        repurchaseDetails = `${String(labels.suggestedPeriod)} ${averageRepurchasePeriodDescription}`;
    }

    if (!_isEmpty(countConsideredRepurchasesDescription)) {
        if (!_isEmpty(repurchaseDetails)) {
            repurchaseDetails += ' | ';
        }

        // Ex. Reliability: 10 repurchases
        // Ex. Suggested period: 10 days | Reliability: 1 day
        repurchaseDetails += `${labels.reliability} ${countConsideredRepurchasesDescription}`;
    }

    return { repurchaseDetails, countConsideredRepurchasesDescription, averageRepurchasePeriodDescription };
};

export const getCategoryNameOrNoCategoryDescription = (translation: TranslationData, categoryName = '') => {
    return categoryName === NO_CATEGORY
        ? translation.pages.manageRepurchaseItem.sections.setup.listItem.inputs.noCategory
        : categoryName;
};

export const getNoCategoryIfTextIsEqualsNoCategoryValue = (translation: TranslationData, text: string) => {
    const { noCategory } = translation.pages.manageRepurchaseItem.sections.setup.listItem.inputs;

    if (String(noCategory).toLowerCase() === (text as string)?.toLowerCase()?.trim()) {
        return NO_CATEGORY;
    }

    return text;
};
