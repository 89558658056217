import styled from 'styled-components';
import { Form } from '@unform/web';
import { Box } from '~/components/elements/box';
import { Card } from '~/components/elements/card';
import { TooltipContent } from '~/components/elements/tooltip/content';

export const FormStyled = styled(Form)`
    margin-bottom: 10rem;

    h1 {
        margin-bottom: 3.2rem;
    }

    & > button {
        margin-top: 3.2rem;
        margin-left: auto;
        width: 16rem;
    }
`;

export const CardStyled = styled(Card).attrs(({ theme }) => ({ borderColor: theme.colors.purple }))`
    padding: 3.2rem;
    display: flex;
    flex-direction: column;

    & > header {
        padding: 0;
        margin-bottom: 3.2rem;
    }

    & > main {
        padding: 0;
        flex: 1;
        gap: 4.5rem;
        max-width: 70rem;

        h6 {
            margin-bottom: 1.6rem;
            display: flex;

            button {
                color: ${({ theme }) => theme.colors.deepCerise};
                text-decoration: underline;
                font-size: ${({ theme }) => theme.fontSizes.md};
                font-weight: normal;
                margin-left: 1rem;
            }
        }
    }
`;

export const FormContent = styled.div<{ fileName?: string }>`
    & > div {
        max-width: 60rem;

        &:has(div.input-error) {
            span {
                opacity: 0;
            }
        }

        & > span {
            display: block;
            margin-top: 1.2rem;
            color: ${({ theme }) => theme.colors.philippineSilver};
        }
    }
`;

export const InputBlockStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;

    ${Box} {
        padding: 0;
        width: 5.6rem;
        height: 5.6rem;
        border-radius: ${({ theme }) => theme.radii.sm};
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > div:last-child {
        flex: 1;
    }
`;

export const TooltipContentStyled = styled(TooltipContent)`
    max-width: 24.8rem;
`;
