import styled from 'styled-components';
import { ItemConfigurationStyled } from '../styles';

export const ItemConfigurationListHeaderStyled = styled(ItemConfigurationStyled)`
    color: ${({ theme }) => theme.colors.purple};
    grid-template-columns: 5fr minmax(22rem, 0.5fr) minmax(21.4rem, 0.5fr) minmax(22.8rem, 0.5fr) auto;
    align-items: start;
    cursor: default;

    span {
        white-space: nowrap;
        text-align: center;
        width: 100%;

        &:first-child {
            text-align: start;
            min-width: 18rem;
        }
    }
`;
