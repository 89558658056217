import { AnimatePresence } from 'framer-motion';
import { Card, CardBody, CardHeader, Divider, Text } from '@components';
import { useNumberFunctions, useReplace, useSubscriptionTotals, useTranslator } from '@hooks';
import { BillingPeriodEnum, ToolTypeEnum } from '@models';
import { GetTranslatedEnumName, isGreaterThanZero } from '@utils';
import { useCheckout, usePaymentMethods, useTools } from '~/providers/checkoutProvider';
import { GetBillingPeriodById } from '~/services/subscriptionService';
import { ItemResumeStyled, ListResumeStyled, TotalResumeStyled } from './styles';

type ResumeInformationProps = {
    currency?: string;
};

export const ResumeInformation = ({ currency = 'BRL' }: ResumeInformationProps) => {
    const { subscriptionFormData } = useCheckout();
    const { tools } = useTools();
    const { billingPeriods, paymentMethods } = usePaymentMethods();
    const { formatCurrency } = useNumberFunctions();
    const replace = useReplace();
    const {
        enums,
        checkoutPage: {
            subscriptionPlan: { resumeInformation }
        }
    } = useTranslator();

    const {
        billingPeriodId = null,
        emailMarketingAmount = null,
        creditVolume = null,
        sendingTechnologies = []
    } = subscriptionFormData?.subscriptionPlan || {};

    const selectedBillingPeriod = GetBillingPeriodById(billingPeriods, billingPeriodId);
    const { discountTotal, totalToPay, totalPerTool } = useSubscriptionTotals({
        billingPeriods,
        paymentMethods: paymentMethods,
        tools,
        couponAttemptResult: null,
        automationCreditAmount: creditVolume,
        bulkSendingCreditAmount: emailMarketingAmount,
        sendingTechnologyIds: sendingTechnologies,
        billingPeriod: selectedBillingPeriod,
        paymentMethod: null,
        enableCustomValue: false
    });

    const billingPeriodName = selectedBillingPeriod
        ? GetTranslatedEnumName(BillingPeriodEnum[selectedBillingPeriod.periodicityInMonths], enums.billingPeriodEnum)
        : '';

    return (
        <Card type='group'>
            <CardHeader title={resumeInformation.title} />
            <CardBody>
                <Card>
                    <CardBody>
                        <ListResumeStyled>
                            {totalPerTool.pricesPerTool.map((tool) => (
                                <ItemResumeStyled key={tool.toolType}>
                                    <Text size='md'>
                                        {replace(
                                            resumeInformation.itemResume,
                                            GetTranslatedEnumName(ToolTypeEnum[tool.toolType], enums.toolType)
                                        )}
                                    </Text>
                                    <Text size='md'>{formatCurrency(tool.price, currency)}</Text>
                                </ItemResumeStyled>
                            ))}
                        </ListResumeStyled>
                        <Divider indent={3} />

                        <AnimatePresence initial={false}>
                            {isGreaterThanZero(discountTotal) && (
                                <>
                                    <ItemResumeStyled negativeAmount>
                                        <Text size='md'>
                                            {replace(resumeInformation.discountByBillingPeriod, billingPeriodName)}
                                        </Text>
                                        <Text size='md'>{formatCurrency(-discountTotal, currency)}</Text>
                                    </ItemResumeStyled>
                                    <Divider indent={3} />
                                </>
                            )}
                        </AnimatePresence>

                        <TotalResumeStyled>
                            <Text size='md' as='strong'>
                                {resumeInformation.total}
                            </Text>
                            <Text size='md' as='strong'>
                                {formatCurrency(totalToPay, currency)}
                                {billingPeriodName && (
                                    <Text size='xs'>{replace(resumeInformation.totalValue, billingPeriodName)}</Text>
                                )}
                            </Text>
                        </TotalResumeStyled>
                    </CardBody>
                </Card>
            </CardBody>
        </Card>
    );
};
