import { Link } from '../link';
import { CrumbStyled, BreadcrumbsStyled } from './styles';
import { type Breadcrumb } from './useBreadcrumbs';

type BreadcrumbsProps = {
    separator?: string;
    breadcrumbs?: Array<Breadcrumb>;
};

export const Breadcrumbs = ({ separator = '>', breadcrumbs = [] }: BreadcrumbsProps) => {
    const tempBreadcrumbs = [...breadcrumbs];
    const currentPage = tempBreadcrumbs.pop();

    return (
        <BreadcrumbsStyled aria-label='Breadcrumb'>
            <ol>
                {breadcrumbs
                    ?.filter((breadcrumb) => breadcrumb.as !== currentPage.as)
                    ?.map((breadcrumb, index) => {
                        return (
                            <CrumbStyled key={index}>
                                <Link href={{ pathname: breadcrumb.href }} as={breadcrumb.as}>
                                    {breadcrumb.text}
                                </Link>
                                <span>{separator}</span>
                            </CrumbStyled>
                        );
                    })}

                <CrumbStyled key={currentPage.href} aria-current='page'>
                    {currentPage.text}
                </CrumbStyled>
            </ol>
        </BreadcrumbsStyled>
    );
};
