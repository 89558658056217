import styled from 'styled-components';
import { DefaultLayoutContentStyled, DefaultLayoutStyled } from '../styles';

export const LayoutStyled = styled(DefaultLayoutStyled)``;

export const LayoutContentStyled = styled(DefaultLayoutContentStyled)`
    padding-top: 2.4rem;

    [aria-label='Breadcrumb'] + div {
        margin-top: 2.4rem;
    }

    .notice {
        margin-bottom: calc(7.2rem - 3.6rem);
    }
`;
