import styled, { css } from 'styled-components';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import { CardBody } from '~/components/elements/card';
import { ExpandableGroup } from '~/components/elements/expandableGroup';
import { InputGroup } from '~/components/elements/inputGroup';
import { TooltipContent } from '~/components/elements/tooltip/content';

type CardBodyStyledProps = {
    isExpanded: boolean;
};

export const EmailFooterStyled = styled.div`
    display: flex;
    justify-content: end;
`;

export const HelpIconStyled = styled((props) => <HelpIcon {...props} />)`
    padding-left: 0.8rem;
    height: 1em;
`;

export const VariableInfoContainer = styled.div`
    display: flex;
    align-items: center;

    svg {
        color: currentColor;
    }
`;

export const TitleStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    position: relative;

    & > div {
        position: relative;
        bottom: 0.3rem;
    }
`;

export const CardBodyStyled = styled(CardBody)<CardBodyStyledProps>`
    ${({ isExpanded }) =>
        !isExpanded &&
        css`
            padding-bottom: 3.2rem;
            padding-top: 0;
            transition: padding 0.2s ease;
        `}
`;

export const ContentStyled = styled(ExpandableGroup)`
    & > div {
        padding: 1px;
    }
    .subject-group {
        & > div {
            padding: 0;
            padding-top: 1.6rem;
            padding-bottom: 0.8rem;
            max-width: 62rem;
            transition: margin-bottom 0.15s ease-out;

            & > div[data-validate] {
                margin-bottom: 1rem;
            }
        }

        div + span {
            color: ${({ theme }) => theme.colors.philippineSilver};
        }
    }

    [data-validate] {
        &::before {
            max-width: max-content;
            width: max-content;
        }
    }
`;

export const ToolTipChildrenStyled = styled(TooltipContent)`
    display: block;
    width: 28rem;
`;

export const InputGroupStyled = styled(InputGroup)`
    div:first-child {
        max-width: 25.6rem;
    }

    button {
        width: auto;
    }
`;

export const TabContentStyled = styled.div`
    margin: 3.2rem 0 2.8rem;
`;

export const TabContentLabelStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-bottom: 3.2rem;

    span:last-child {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const TabContentButtonStyled = styled.div`
    display: flex;
    gap: 3.2rem;
`;
