import { Shimmer } from '@components';
import { useNumberFunctions } from '@hooks';
import { divideOrFallback } from '@utils';
import { UsedCreditsAmountBarChart } from '../usedCreditsAmount';
import { BarChartHeaderItems } from './barChartHeaderItems';

type BarChartLabel = {
    id: string | number;
    text: string;
    color?: string;
    value?: number;
    totalCreditsUsed?: number;
};

type BarChartItem = {
    id?: string | number;
    text: string;
    color?: string;
    percentage?: number;
    totalCreditsUsed?: number;
};

type AutomationCreditsBarChartProps = {
    haveUnlimitedBalance: boolean;
    totalCredits?: number;
    usedCreditsAmount?: number;
    labels?: Array<BarChartLabel>;
    barChartItems?: Array<BarChartItem>;
    isLoading?: boolean;
};

export const AutomationCreditsBarChart = ({
    haveUnlimitedBalance = false,
    totalCredits = 0,
    usedCreditsAmount = 0,
    labels = [],
    barChartItems = [],
    isLoading = false
}: AutomationCreditsBarChartProps) => {
    const percentageCreditsUsed = divideOrFallback(usedCreditsAmount, totalCredits);
    const { formatNumber, formatPercentage } = useNumberFunctions();

    return (
        <Shimmer isActive={isLoading}>
            <UsedCreditsAmountBarChart.Root>
                <UsedCreditsAmountBarChart.Header>
                    <BarChartHeaderItems
                        data-shimmer
                        haveUnlimitedBalance={haveUnlimitedBalance}
                        totalCredits={totalCredits}
                        usedCreditsAmount={usedCreditsAmount}
                        percentageUsedCredits={percentageCreditsUsed}
                    />
                </UsedCreditsAmountBarChart.Header>
                <UsedCreditsAmountBarChart.Bar data-shimmer>
                    {barChartItems.map((item) => (
                        <UsedCreditsAmountBarChart.BarItem
                            key={item.id}
                            color={item.color}
                            title={`${formatPercentage(item.percentage, 'en-US')} - ${formatNumber(
                                item.totalCreditsUsed
                            )}`}
                            formattedPercentage={formatPercentage(item.percentage, 'en-US')}
                        />
                    ))}
                </UsedCreditsAmountBarChart.Bar>
                <UsedCreditsAmountBarChart.Labels className='used-credits-amount-bar-chart__labels'>
                    {labels.map((item) => (
                        <UsedCreditsAmountBarChart.Label
                            data-shimmer
                            key={item.id}
                            text={item.text}
                            value={item.totalCreditsUsed}
                            color={item.color}
                            showHighlightColor={!!item.color}
                        />
                    ))}
                </UsedCreditsAmountBarChart.Labels>
            </UsedCreditsAmountBarChart.Root>
        </Shimmer>
    );
};
