/* eslint-disable @typescript-eslint/ban-types */
import { ReactNode } from 'react';
import { AuthorizationData, AuthorizationResponse, CancellationData } from './callbackData';
import { Environment, ButtonStyle } from './configuration';

export enum FundingOption {
    BANCONTACT = 'bancontact',
    BLIK = 'blik',
    BOLETO = 'boleto',
    CARD = 'card',
    CREDIT = 'credit',
    ELV = 'elv',
    EPS = 'eps',
    GIROPAY = 'giropay',
    IDEAL = 'ideal',
    ITAU = 'itau',
    MAXIMA = 'maxima',
    MERCADOPAGO = 'mercadopago',
    MYBANK = 'mybank',
    OXXO = 'oxxo',
    P24 = 'p24',
    PAYPAL = 'paypal',
    SOFORT = 'sofort',
    VENMO = 'venmo',
    ZIMPLER = 'zimpler'
}

export type ButtonRenderer = {
    render(
        options: {
            env?: Environment | undefined;
            style?: ButtonStyle | undefined;
            locale?: string | undefined;

            payment?: (() => Promise<string>) | undefined;
            onAuthorize: (data: AuthorizationData, actions: object) => Promise<AuthorizationResponse>;
            onCancel?: ((data: CancellationData, actions: object) => void) | undefined;
            onError?: ((error: string) => void) | undefined;
            onShippingChange?: (() => void) | undefined;
            onAuth?: ((data: string | object) => object) | undefined;
            accessToken?: (() => void) | undefined;
            onClose?: (() => void) | undefined;

            funding?:
                | {
                      allowed?: FundingOption[] | undefined;
                      disallowed?: FundingOption[] | undefined;
                  }
                | undefined;

            sessionID?: string | undefined;
            buttonSessionID?: string | undefined;
            meta?: object | undefined;
            stage?: string | undefined;
            stageUrl?: string | undefined;
            authCode?: string | undefined;
            localhostUrl?: string | undefined;
            checkoutUri?: string | undefined;
            client?: object | undefined;
            commit?: boolean | undefined;
            experience?: object | undefined;
            fundingSource?: string | undefined;
            fundingOffered?: object | undefined;
            logLevel?: string | undefined;
            test?: object | undefined;
        },
        selector: string | ReactNode
    ): void;
};
