import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import { Card } from '~/components/elements/card';
import type { CardStyleProps } from '~/components/elements/card/styles';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { DropdownFormGroupStyled } from '~/components/forms/dropdownFormGroup/styles';
import { SubToolColors } from '~/styles/theme';

type SubToolCardStyledProps = {
    subToolColor: SubToolColors;
};

type CardProps = ThemedStyledProps<CardStyleProps & SubToolCardStyledProps, DefaultTheme>;

export const TooltipBodyStyled = styled(TooltipContent)`
    max-width: 20.8rem;
    display: block;
`;

export const DropDownFormGroupStyled = styled(DropdownFormGroupStyled)`
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.6rem;

    label {
        margin-bottom: 0.8rem;
    }

    & > div {
        min-width: 23rem;
        padding: 0;
    }
`;

export const HelpIconStyled = styled(HelpIcon)`
    padding-left: 0.8rem;
    height: 0.8em;
    color: ${({ theme }) => theme.colors.purple};
`;

export const CardStyled = styled(Card).attrs((props: CardProps) => ({
    borderColor: props.theme.subToolColors[props.subToolColor]
}))<SubToolCardStyledProps>`
    padding: 3.2rem 4rem;

    & > header {
        padding: 0;

        h1,
        h3 {
            margin-bottom: 0;
        }
    }
    & > main {
        margin-top: 2.4rem;
        padding: 0;
    }
`;
