import styled, { css } from 'styled-components';
import arrowDownPurple from '~/assets/svg/controls/arrow--down--purple.svg?url';
import { dataValidateStyle } from '~/styles/mixins';
import { Text } from '../text';

export type DropdownStyleProps = {
    background?: string;
    disabled?: boolean;
    iconPosition?: IconPosition;
    inputSize?: 'small' | 'medium' | 'large';
    disableCheck?: boolean;
    isDatePicker?: boolean;
};

export type IconWrapperStyleProps = {
    position?: IconPosition;
};

export type IconPosition = 'start' | 'end';

export const DropdownStyled = styled.div<DropdownStyleProps>`
    ${({ background, theme, disableCheck = false, isDatePicker = false }) => css`
        width: 100%;
        border-radius: 0.4rem;
        position: relative;
        display: flex;
        flex-direction: column;

        & > div {
            position: absolute;
            width: 100%;
            height: auto;
            max-height: 35rem;
            overflow: auto;
            background-color: ${background || theme.default.input.backgroundColor};
            border-radius: 0.4rem;
            box-shadow: ${({ theme }) => theme.default.boxShadow.default};
            padding: ${isDatePicker ? theme.space.md : `0.6rem ${disableCheck === true ? '1rem' : '1.6rem'}`};
            z-index: 10;

            &.show-direction-bottom {
                top: calc(100% + 0.8rem);
            }

            &.show-direction-top {
                bottom: calc(100% + 0.8rem);
            }
        }

        ${dataValidateStyle}
    `}
`;

export const SelectStyled = styled.button.attrs({ type: 'button' })<DropdownStyleProps & { hasValue: boolean }>`
    width: 100%;
    padding: 1.6rem;
    padding-right: 4.4rem;
    border: 0.1rem solid ${({ theme }) => theme.default.input.borderColor};
    border-radius: ${({ theme }) => theme.radii.sm};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fontSizes.md};

    color: ${({ hasValue, theme }) => (hasValue ? theme.colors.lightGray : theme.default.input.color)};
    text-align: start;
    display: flex;
    align-items: center;
    flex-direction: ${({ iconPosition }) => (iconPosition === 'start' ? 'row' : 'row-reverse')};
    justify-content: space-between;

    background-color: ${({ background, theme }) => background || theme.default.input.backgroundColor};
    background-image: ${({ isDatePicker }) => (isDatePicker ? 'none' : `url(${arrowDownPurple})`)};
    background-size: 1.2rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 1.6rem) center;
    transition: all 0.15s ease;

    &:focus {
        box-shadow: inset 0 0 0 0.1rem ${({ theme }) => theme.colors.purple};
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        border-color: ${({ theme }) => theme.default.input.disabledBorderColor};
        color: ${({ theme }) => theme.colors.philippineSilver};
        box-shadow: none;
        filter: grayscale(1);
        /* cursor: normal; */
    }

    ${({ inputSize, hasValue, theme, isDatePicker }) =>
        inputSize === 'small' &&
        css`
            font-size: ${isDatePicker ? theme.fontSizes.xs : theme.fontSizes.sm};
            color: ${hasValue ? theme.colors.lightGray : theme.colors.purple};
            font-weight: ${theme.fontWeights.semiBold};
            padding: ${`1rem ${theme.space.md} 0.83rem`};
            height: 4rem;

            &:has(svg) {
                svg {
                    margin-bottom: ${theme.space.xxxs};
                }
            }
        `}

    p,
    span,
    b,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit !important;
    }
`;

export const NoOptions = styled(Text).attrs({ size: 'sm' })`
    padding: 1rem;
    background-color: transparent;
    color: ${({ theme }) => theme.default.color};
    text-align: center;
`;

export const IconContainer = styled.div<IconWrapperStyleProps>`
    display: flex;
    height: 100%;
    justify-content: ${({ position }) => (position === 'start' ? 'flex-start' : 'flex-end')};
    align-items: center;

    svg {
        height: 1.25em;
        color: ${({ theme }) => theme.default.input.borderColor};
    }
`;
