import { KeyboardEventHandler, MouseEventHandler, useRef, useState } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import _uniqueId from 'lodash/uniqueId';
import { useSWRConfig } from 'swr';
import { useAutosave, useFetch, useTranslator } from '@hooks';
import { MessageSequence, MessageSequenceType, SubToolConfiguration, SubToolIdEnum } from '@models';
import { AutomationRulerProvider, RepurchaseItemConfiguration, useManageRepurchaseItemsProvider } from '@providers';
import { defaultMessageSequenceItem } from '~/components/containers/subTools/automationRuler';
import { UpdateRepurchaseStepAmount } from '~/services/cacheService';
import { GetSummaryMessageSequence, getSummaryMessageSequenceUrl } from '~/services/repurchaseApiService';
import { ItemConfigurationDetails } from '../itemConfigurationDetails';
import { ItemConfigurationRuler } from '../itemConfigurationRuler';
import { ListItemStyled } from '../styles';
import { ListItemSaveLoadingStyled } from './styles';

type ItemConfigurationProps = {
    'data-shimmer'?: boolean;
    allowExpandableContent?: boolean;
    item: RepurchaseItemConfiguration;
};

export const ItemConfiguration = ({ item, allowExpandableContent = true, ...props }: ItemConfigurationProps) => {
    const {
        listItem: { feedbackItem }
    } = useTranslator().pages.manageRepurchaseItem.sections.setup;
    const [expanded, setExpanded] = useState(false);
    const [messageSequence, setMessageSequence] = useState<MessageSequence>();

    const triggerId = useRef(_uniqueId('item-configuration-trigger-')).current;
    const templatesContentId = useRef(_uniqueId('item-configuration-region-')).current;

    const isExecuteFetch = !!item.type;
    const { cache } = useSWRConfig();

    const {
        data: cacheData,
        isLoading,
        mutate
    } = useFetch(
        isExecuteFetch &&
            expanded && {
                url: getSummaryMessageSequenceUrl,
                type: item.type,
                identifier: item.identifier
            },
        async ({ type, identifier }) =>
            (
                await GetSummaryMessageSequence(
                    type as unknown as MessageSequenceType,
                    type === 'general' ? null : identifier
                )
            ).data,
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setMessageSequence(data);
                UpdateRepurchaseStepAmount({
                    cacheKey: item.cacheKey,
                    itemId: item.identifier,
                    stepAmount: data.steps.length,
                    cache
                });
            },
            onError: () => setMessageSequence({} as MessageSequence),
            dedupingInterval: minutesToMilliseconds(1)
        }
    );

    const handleKeyDown: KeyboardEventHandler<HTMLLIElement> = (event) => {
        if (event.target !== event.currentTarget) {
            event.stopPropagation();
            return;
        }

        if (event.key === 'Enter' || event.key === ' ') {
            setExpanded(!expanded);
            event.preventDefault();
        }
    };

    const handleExpandContent: MouseEventHandler<HTMLDivElement> = (event) => {
        if (!allowExpandableContent) {
            return;
        }

        if ((event.target as HTMLButtonElement).id === `button-${item.identifier}`) {
            return;
        }

        setExpanded(!expanded);
    };

    const updateItem = useManageRepurchaseItemsProvider((context) => context.updateItem);
    const { saveData, isSubmitting, error: autoSaveError } = useAutosave({ onSave: updateItem });

    // TODO: Usar o contexto de ExpandableGroup quando a página de estatísticas for mergeada
    return (
        <AutomationRulerProvider
            revalidateOnChange={mutate}
            subToolConfiguration={{ subTool: { id: SubToolIdEnum.Repurchase } } as SubToolConfiguration}>
            <ListItemStyled
                tabIndex={0}
                onKeyDown={handleKeyDown}
                {...props}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: 'easeInOut', duration: 0.25 }}
                data-itemid={item.identifier}>
                <ItemConfigurationDetails
                    expanded={expanded}
                    templatesContentId={templatesContentId}
                    triggerId={triggerId}
                    item={item}
                    error={autoSaveError}
                    onItemChange={saveData}
                    onExpanded={handleExpandContent}
                />

                <ItemConfigurationRuler
                    messageSequence={messageSequence ?? cacheData ?? (isLoading && defaultMessageSequenceItem[0])}
                    expanded={expanded}
                    templatesContentId={templatesContentId}
                    triggerId={triggerId}
                    isLoading={isLoading && !(messageSequence ?? cacheData)}
                />

                {isSubmitting && (
                    <ListItemSaveLoadingStyled initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        {String(feedbackItem.saving)}
                    </ListItemSaveLoadingStyled>
                )}
            </ListItemStyled>
        </AutomationRulerProvider>
    );
};
