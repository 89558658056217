import { useState, useEffect, useRef, ForwardedRef, ReactNode, isValidElement, cloneElement, Children } from 'react';
import {
    autoUpdate,
    flip,
    offset,
    Placement,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFloatingNodeId,
    useFloatingParentNodeId,
    useFloatingTree,
    useHover,
    useInteractions,
    useListNavigation,
    useMergeRefs,
    useRole,
    useTransitionStyles,
    useTypeahead
} from '@floating-ui/react';

type UseMenuProps<T extends HTMLElement> = {
    elementRef: ForwardedRef<T>;
    labels: Array<string>;
    children?: ReactNode;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    initialOpen?: boolean;
    allowHoverEvent?: boolean;
    placement?: Placement;
    nestedPlacement?: Placement;
};

const OFFSET_Y = 10;

export const useMenu = <T extends HTMLElement>({
    elementRef,
    labels,
    children,
    initialOpen,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    placement = null,
    nestedPlacement = null,
    allowHoverEvent = true
}: UseMenuProps<T>) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const isOpen = controlledOpen ?? uncontrolledOpen;
    const setIsOpen = setControlledOpen ?? setUncontrolledOpen;

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [allowHover, setAllowHover] = useState(false);

    const listItemsRef = useRef<Array<T | null>>([]);
    const listContentRef = useRef(labels as Array<string | null>);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const isNested = parentId != null;

    const { refs, context } = useFloating<T>({
        nodeId,
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: isNested ? nestedPlacement ?? 'right-start' : placement ?? 'bottom-start',
        middleware: [offset({ mainAxis: isNested ? 0 : OFFSET_Y, alignmentAxis: 0 }), flip(), shift()],
        whileElementsMounted: autoUpdate
    });

    const { isMounted, styles } = useTransitionStyles(context, {
        duration: {
            close: 200,
            open: 400
        },
        initial: ({ placement }) => ({
            opacity: 0,
            transform:
                placement === 'bottom-start' || placement === 'bottom-end' ? 'translateY(-1rem)' : 'translateX(-1rem)'
        })
    });

    const hover = useHover(context, {
        enabled: allowHover && allowHoverEvent,
        delay: { open: 75 },
        handleClose: safePolygon({
            blockPointerEvents: true
        })
    });
    const click = useClick(context, {
        event: 'mousedown',
        toggle: !isNested || !allowHover,
        ignoreMouse: isNested
    });
    const role = useRole(context, { role: 'menu' });
    const dismiss = useDismiss(context);
    const listNavigation = useListNavigation(context, {
        listRef: listItemsRef,
        activeIndex,
        nested: isNested,
        onNavigate: setActiveIndex
    });
    const typeahead = useTypeahead(context, {
        enabled: isOpen,
        listRef: listContentRef,
        onMatch: isOpen ? setActiveIndex : undefined,
        activeIndex
    });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        hover,
        click,
        role,
        dismiss,
        listNavigation,
        typeahead
    ]);

    useEffect(() => {
        if (!tree) {
            return;
        }

        const handleTreeClick = () => setIsOpen(false);

        const onSubMenuOpen = (event: { nodeId: string; parentId: string }) => {
            if (event.nodeId !== nodeId && event.parentId === parentId) {
                setIsOpen(false);
            }
        };

        tree.events.on('click', handleTreeClick);
        tree.events.on('menuopen', onSubMenuOpen);

        return () => {
            tree.events.off('click', handleTreeClick);
            tree.events.off('menuopen', onSubMenuOpen);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree, nodeId, parentId]);

    useEffect(() => {
        if (isOpen && tree) {
            tree.events.emit('menuopen', { parentId, nodeId });
        }
    }, [tree, isOpen, nodeId, parentId]);

    useEffect(() => {
        const onPointerMove = ({ pointerType }: PointerEvent) => {
            if (pointerType !== 'touch') {
                setAllowHover(true);
            }
        };

        const onKeyDown = () => {
            setAllowHover(false);
        };

        window.addEventListener('pointermove', onPointerMove, {
            once: true,
            capture: true
        });
        window.addEventListener('keydown', onKeyDown, true);
        return () => {
            window.removeEventListener('pointermove', onPointerMove, {
                capture: true
            });
            window.removeEventListener('keydown', onKeyDown, true);
        };
    }, [allowHover]);

    const referenceRef = useMergeRefs([refs.setReference, elementRef]);

    const items = Children.map(children, (child, index) => {
        return (
            isValidElement(child) &&
            cloneElement(
                child,
                getItemProps({
                    tabIndex: activeIndex === index ? 0 : -1,
                    ref(node: T) {
                        listItemsRef.current[index] = node;
                    },
                    onClick(event) {
                        child.props.onClick?.(event);
                        tree?.events.emit('click');
                    },
                    onMouseEnter() {
                        if (allowHover && isOpen) {
                            setActiveIndex(index);
                        }
                    }
                })
            )
        );
    });

    return {
        referenceRef,
        getReferenceProps,
        getFloatingProps,
        getItemProps,
        isMounted,
        styles,
        nodeId,
        isNested,
        isOpen,
        listItemsRef,
        context,
        allowHover,
        tree,
        refs,
        activeIndex,
        setActiveIndex,
        items
    };
};
