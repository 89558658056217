import { Link } from '@components';
import { useAutomationRulerProvider } from '@providers';
import { ButtonStyled, GroupActionStyled } from './styles';

type SubToolActionButtonsProps = {
    isLoading?: boolean;
};

export const SubToolActionButtons = ({ isLoading = false }: SubToolActionButtonsProps) => {
    const automationRuler = useAutomationRulerProvider((context) => context.automationRuler);

    return (
        <GroupActionStyled>
            {automationRuler?.subTool?.actions?.map((action) => (
                <Link
                    key={action.type}
                    as={action.url?.as}
                    showLink={!!action.url}
                    tabIndex={-1}
                    href={
                        !action?.url?.usePathname
                            ? action.url?.href
                            : { pathname: action.url?.href, query: action.url?.query }
                    }>
                    <ButtonStyled
                        buttonSize='small'
                        color='purple'
                        hasIcon={!!action.icon}
                        isOutlined={action.button.isOutlined}
                        disabled={isLoading || action.button?.disabled}
                        onClick={action.button?.onClick}
                        className={action.button?.className}>
                        {action.description ?? action.icon}
                    </ButtonStyled>
                </Link>
            ))}
        </GroupActionStyled>
    );
};
