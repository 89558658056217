import { forwardRef, MouseEventHandler } from 'react';
import CloseIcon from '~/assets/svg/icons/icon__remove.svg';
import { useDialogContext } from '~/providers';
import { Button, ButtonProps } from '../button';
import { DialogCloseButtonStyled } from './styles';

type DialogCloseProps = ButtonProps;

export const DialogClose = forwardRef<HTMLButtonElement, DialogCloseProps>(function DialogClose(
    { onClick, ...props },
    ref
) {
    const { setOpen } = useDialogContext();

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        onClick && onClick(event);

        setOpen(false);
    };

    return (
        <DialogCloseButtonStyled type='button' {...props} ref={ref} onClick={handleClick}>
            <CloseIcon />
        </DialogCloseButtonStyled>
    );
});

export const DialogButtonClose = forwardRef<HTMLButtonElement, ButtonProps>(function DialogButtonClose(
    { onClick, ...props },
    ref
) {
    const { setOpen } = useDialogContext();

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        onClick && onClick(event);
        setOpen(false);
    };

    return <Button type='button' {...props} ref={ref} onClick={handleClick} />;
});
