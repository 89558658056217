import { ElementType } from 'react';
import styled, { css, StyledComponentPropsWithRef } from 'styled-components';

export type BoxProps = StyledComponentPropsWithRef<typeof Box> & {
    as?: ElementType;
};

export const Box = styled.div`
    ${({ theme }) => css`
        width: 100%;
        border: 1px solid ${theme.colors.lightPurple};
        border-radius: ${theme.radii.lg};
        overflow: hidden;
        padding: ${theme.space.xl};

        &:not(.shimmer-effect) {
            background: ${({ theme }) => theme.colors.white};
        }
    `};
`;

Box.displayName = 'Box';
