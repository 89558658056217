import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import { type NextRouter } from 'next/router';
import { SubToolIdEnum } from '@models';
import { SUBTOOL_PAGE_PATH } from './constants';
import { isBrowser } from './next';
import { replace, ReplaceData } from './stringFunctions';

export const getSubToolPagePathById = (subToolId: SubToolIdEnum) => {
    return SUBTOOL_PAGE_PATH[subToolId];
};

export const replaceRouteVariable = (route: string, params: ReplaceData = '') => {
    return replace(route, params, { start: '{', end: '}' });
};

export const replaceRouteVariableBySubToolId = (route: string, subToolId: SubToolIdEnum) => {
    return replaceRouteVariable(route, {
        subToolPagePath: getSubToolPagePathById(subToolId)
    });
};

export const isExternalHttp = (path: string) => {
    if (!path.startsWith('http')) {
        return false;
    }

    return new URL(path).origin !== location.origin;
};

export const pushQueryParam = (router: NextRouter, urlMask: string, param: string, value: string) => {
    if (isBrowser()) {
        const { pathname, query, push } = router;
        const newQuery = { ...query, [param]: value };
        push(
            {
                pathname,
                query: newQuery
            },
            urlMask
        );
    }
};

export const createQueryParams = (params: { [key: string]: string }) => {
    return new URLSearchParams(_omitBy(params, _isNil))?.toString();
};

export const checkRouteWithPattern = (route: string, pattern: string) => {
    const regex = new RegExp('^' + pattern.replace(/:[^/]+/g, '[^/]+') + '$');

    return regex.test(route);
};

export const isValidURL = (url: string) => {
    try {
        const validUrl = new URL(url);

        return !!validUrl;
    } catch (error) {
        return false;
    }
};
