import styled, { css } from 'styled-components';
import { TableCommonProps, MIN_WIDTH_FIRST_COLUMN } from '../styles';

export type TbodyStyledProps = Pick<TableCommonProps, 'minWidthFirstColumn'>;

export const TbodyStyled = styled.tbody<TbodyStyledProps>`
    ${({ theme, minWidthFirstColumn = MIN_WIDTH_FIRST_COLUMN }) => css`
        tr {
            vertical-align: middle;
            background-color: ${theme.colors.white};

            th {
                text-align: left;
                min-width: ${minWidthFirstColumn};

                font-weight: bold;
                font-size: 1.8rem;
            }

            &:not(.expansable-row) {
                td {
                    border-top: 1px solid ${theme.colors.lightPurple};
                }
            }

            &.expansable-row:last-child {
                td {
                    border-bottom: 1px solid ${theme.colors.lightPurple};
                }
            }
        }
    `}
`;
