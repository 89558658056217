import { forwardRef } from 'react';
import { FloatingArrow, FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react';
import { useTooltipProvider } from '@providers';
import { CustomTooltipContentStyled } from './styles';

export const TooltipContent = forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>(function TooltipContent(
    props,
    propRef
) {
    const context = useTooltipProvider();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const { isMounted, styles } = useTransitionStyles(context.context, {
        duration: {
            close: 200,
            open: 400
        }
    });

    return (
        <>
            {isMounted && (
                <FloatingPortal>
                    <CustomTooltipContentStyled
                        ref={ref}
                        style={{
                            position: context.strategy,
                            top: context.y ?? 0,
                            left: context.x ?? 0,
                            ...styles,
                            ...props.style
                        }}
                        {...context.getFloatingProps(props)}>
                        {props.children}
                        <FloatingArrow
                            className='tooltip-arrow'
                            ref={context.arrowRef}
                            context={context.context}
                            tipRadius={2}
                        />
                    </CustomTooltipContentStyled>
                </FloatingPortal>
            )}
        </>
    );
});
