import { motion } from 'framer-motion';
import styled from 'styled-components';
import semicircleRight from '~/assets/svg/controls/arrow--right.svg?url';
import { Carousel } from '~/components/elements/carousel';

export const RulerStepsContainer = styled(motion.div)`
    h6 {
        margin-bottom: 1.6rem;
    }
`;

export const CarouselStyled = styled(Carousel)`
    .keen-slider__slide + .keen-slider__slide {
        & > div:not(.add-button) {
            &:before {
                content: '';
                top: 50%;
                left: -1.5rem;
                position: absolute;
                width: 1.2rem;
                height: 1.6rem;
                display: inline-block;
                background-image: url(${semicircleRight});
                background-size: 1rem;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .keen-slider__slide:nth-child(5) + .keen-slider__slide {
        display: none;
    }
`;
