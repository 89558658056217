import { AnimatePresence } from 'framer-motion';
import { Heading, Slide } from '@components';
import { useTranslator } from '@hooks';
import { MessageSequence, MessageSequenceType } from '@models';
import { GetTranslatedEnumName } from '@utils';
import { AddRulerStepButton, RulerStep } from '../';
import { CarouselStyled, RulerStepsContainer } from './styles';

type RulerStepsProps = {
    messageSequence?: MessageSequence;
    isLoading?: boolean;
    showMessageSequenceName?: boolean;
    showCategoryName?: boolean;
};

const MAX_NUMBER_OF_TEMPLATES = 5;

export const RulerSteps = ({
    messageSequence,
    showCategoryName = false,
    showMessageSequenceName = true,
    isLoading = false
}: RulerStepsProps) => {
    const {
        enums: { subToolTemplateConfigurationType }
    } = useTranslator();

    const { steps = [], identifier, type, name, id } = messageSequence ?? ({} as MessageSequence);
    const templateType = GetTranslatedEnumName(MessageSequenceType[type], subToolTemplateConfigurationType);

    return (
        <RulerStepsContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {!isLoading && showMessageSequenceName && (
                <Heading as='h6'>
                    {showCategoryName && templateType}
                    {name && `${showCategoryName ? ' - ' : ''}${name}`}
                </Heading>
            )}
            <CarouselStyled name={`carousel-${identifier}`}>
                <AnimatePresence initial={false}>
                    {steps.map((step) => (
                        <Slide key={step.id}>
                            <RulerStep messageSequence={{ type, identifier }} step={step} isLoading={isLoading} />
                        </Slide>
                    ))}
                    {steps.length > 0 && steps.length < MAX_NUMBER_OF_TEMPLATES && (
                        <Slide>
                            <AddRulerStepButton messageSequence={{ type, identifier, id }} />
                        </Slide>
                    )}
                </AnimatePresence>
            </CarouselStyled>
        </RulerStepsContainer>
    );
};
