import styled, { css } from 'styled-components';
import { cssFlexGridByDevice, dataValidateStyle, FlexGridValues } from '~/styles';

export type InputFormGroupStyledProps = {
    flexGrid?: FlexGridValues;
    inputSize?: 'small' | 'medium' | 'large';
    isInvisible?: boolean;
};

export type WrapperInputProps = {
    disabled?: boolean;
    isFocus?: boolean;
};

export const InputFormGroupStyled = styled.div<InputFormGroupStyledProps>`
    width: 100%;
    display: ${({ isInvisible }) => (!isInvisible ? 'flex' : 'none')};
    flex-direction: column;
    ${({ flexGrid }) => flexGrid && cssFlexGridByDevice(flexGrid)}

    label {
        margin-bottom: 1.6rem;
    }

    ${({ inputSize, theme }) =>
        inputSize === 'small' &&
        css`
            height: auto;
            & > div {
                padding: 0.1rem;
            }

            label {
                margin-bottom: 0.8rem;
            }

            input {
                font-size: ${theme.fontSizes.md};
                line-height: ${theme.lineHeights.base};
                padding: 0.8rem 1.6rem;
                height: calc(4rem - 0.413rem);
                overflow: hidden;
                background-color: ${({ theme }) => theme.default.input.backgroundColor};
            }
        `}

    &.hidden {
        display: none;
    }
`;

export const WrapperInputStyled = styled.div<WrapperInputProps>`
    ${({ theme, isFocus = false }) => css`
        flex: 1;
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        font-family: ${theme.fonts.primary};
        font-size: ${theme.fontSizes.lg};
        color: ${theme.default.input.color};
        background-color: ${theme.default.input.backgroundColor};
        border: 0.1rem solid ${theme.default.input.borderColor};
        border-radius: ${theme.radii.sm};
        padding: 1.6rem;

        ${isFocus &&
        css`
            outline: 1px solid ${theme.default.input.borderColor};
        `};

        &:has(input:disabled) {
            color: ${theme.colors.philippineSilver};
            border: 0.1rem solid ${theme.default.input.disabledBorderColor};
        }

        input {
            flex: 1 1 100%;
            display: inline-flex;
            width: 100%;
            margin: 0;
            outline: none;
            border: none;
            color: currentColor;
            position: relative;
            background-color: ${theme.default.input.backgroundColor};

            &:disabled {
                background-color: transparent;
            }

            &[type='date']::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
            }
        }

        ${dataValidateStyle}
    `}
`;

export const InputIconStyled = styled.i`
    height: 1em;
    width: 1em;
    display: inline-flex;
    margin-left: 1rem;
    color: ${({ theme }) => theme.default.input.borderColor};
`;
