import { ReactNode, useRef } from 'react';
import { useEventListener } from '@hooks';
import { HeaderContent } from './content';
import { HeaderStyled } from './styles';

type HeaderProps = {
    showNavbar?: boolean;
    isStickyHeader?: boolean;
    children: ReactNode;
};

type MainHeaderProps = Omit<HeaderProps, 'children'>;

export const HeaderContainer = ({ children, isStickyHeader = true }: HeaderProps) => {
    const headerRef = useRef<HTMLHeadingElement>();

    const onScroll = () => {
        const header = headerRef.current;

        if (!isStickyHeader || !header) {
            return;
        }

        const sticky = header?.clientHeight;
        if (window.scrollY > sticky) {
            header.classList.add('header--sticky');
        } else if (window.scrollY === 0) {
            header.classList.remove('header--sticky');
        }
    };

    useEventListener('scroll', onScroll);

    return (
        <HeaderStyled ref={headerRef} isStickyHeader={isStickyHeader}>
            {children}
        </HeaderStyled>
    );
};

export const Header = ({ showNavbar = true, isStickyHeader = true }: MainHeaderProps) => {
    return (
        <HeaderContainer isStickyHeader={isStickyHeader}>
            <HeaderContent showNavbar={showNavbar} />
        </HeaderContainer>
    );
};
