import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { InputFormGroup, SelectFormGroup, SelectData } from '@components';
import { useTranslator } from '@hooks';
import { NavigationTriggerRuleConditionType, NavigationTriggerRuleEventType } from '@models';
import { GetTranslatedEnumName } from '@utils';
import { getDebugValue, RuleDefaultProps } from '../';
import { RemoveRuleButton } from './removeRuleButton';
import { InputStyled, RuleContentStyled } from './styles';

type RuleInputsProps = Pick<
    RuleDefaultProps,
    'animation' | 'path' | 'rule' | 'ruleElementAnimationIndex' | 'onRemove' | 'showRemoveButton'
> & {
    isVisible: boolean;
    showLabels?: boolean;
};

export const RuleInputs = ({
    animation,
    isVisible,
    path,
    rule,
    ruleElementAnimationIndex,
    onRemove,
    showRemoveButton,
    showLabels
}: RuleInputsProps) => {
    const {
        enums: { navigationTriggerRuleConditionType },
        subTools: {
            navigationTrigger: { abandonmentConfigurationCard }
        }
    } = useTranslator();
    const conditionOptions = useMemo(() => {
        return Object.keys(NavigationTriggerRuleConditionType)
            .filter((key) => typeof NavigationTriggerRuleConditionType[key] === 'number')
            .map((value) => {
                const name = GetTranslatedEnumName(value, navigationTriggerRuleConditionType);

                return {
                    label: name,
                    id: NavigationTriggerRuleConditionType[value]
                } as SelectData;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isVisible) {
        return <></>;
    }

    return (
        <motion.div
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.2, easings: 'easeOut' } }}>
            <RuleContentStyled
                custom={ruleElementAnimationIndex}
                animate={animation}
                debugValue={getDebugValue(rule.id, path)}>
                <InputStyled className='event-type'>
                    <SelectFormGroup
                        name='eventType'
                        key={`event-type-${rule.id}`}
                        defaultValue={rule.eventType ?? NavigationTriggerRuleEventType.UrlLoad}
                        options={[
                            {
                                id: NavigationTriggerRuleEventType.UrlLoad,
                                label: String(abandonmentConfigurationCard.eventTypeOptions.url)
                            }
                        ]}
                    />
                </InputStyled>
                <InputStyled
                    className='condition-type'
                    fieldType='condition-type'
                    label={showLabels && String(abandonmentConfigurationCard.labels.condition)}>
                    <SelectFormGroup
                        defaultValue={rule.conditionType ?? NavigationTriggerRuleConditionType.Equals}
                        name='conditionType'
                        key={`condition-type-${rule.id}`}
                        options={conditionOptions}
                    />
                </InputStyled>
                <InputStyled
                    className='condition-value'
                    fieldType='condition-value'
                    label={showLabels && String(abandonmentConfigurationCard.labels.url)}>
                    <InputFormGroup
                        inputSize='small'
                        name='conditionValue'
                        type='text'
                        key={`condition-value-${rule.id}`}
                        defaultValue={rule.conditionValue ?? ''}
                        placeholder={String(abandonmentConfigurationCard.conditionInput.placeholder)}
                    />
                    <RemoveRuleButton
                        onRemove={onRemove}
                        rule={rule}
                        isVisible={showRemoveButton}
                        ruleElementAnimationIndex={ruleElementAnimationIndex}
                    />
                </InputStyled>
            </RuleContentStyled>
        </motion.div>
    );
};
