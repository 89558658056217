import styled, { css } from 'styled-components';

export type DividerStyleProps = {
    color?: string;
    indent?: number;
};

export const DividerStyled = styled.hr<DividerStyleProps>`
    ${({ theme, color, indent }) => css`
        width: ${indent ? 'auto' : '100%'};
        margin: 1.6rem ${indent ? `-${indent}rem` : '0'};
        border-top: 0.1rem solid ${color ?? theme.colors.lightPurple};
    `}
`;
