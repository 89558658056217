import { ReactElement } from 'react';
import { Tooltip, TooltipContent } from '@components';
import { useReplace, useTranslator } from '@hooks';
import { SendingTechnologyTypeEnum } from '@models';
import { TooltipTriggerStyled } from './styles';

type SendinTechnologyTooltipProps = {
    status: 'active' | 'inactive';
    type: SendingTechnologyTypeEnum;
    icon: ReactElement;
};

export const SendingTechnologyTooltip = ({ icon, type, status }: SendinTechnologyTooltipProps) => {
    const {
        rulerStepComponent: { sendingTechnologyIconStatus, sendingTechnologyIconTooltip }
    } = useTranslator().pages.myAutomations;
    const replace = useReplace();

    const description = replace(sendingTechnologyIconTooltip[SendingTechnologyTypeEnum[type].toLowerCase()], {
        status: sendingTechnologyIconStatus[status]
    });

    return (
        <Tooltip placement='bottom'>
            <TooltipTriggerStyled htmlTag='li' isEnabled={status === 'active'}>
                {icon}
            </TooltipTriggerStyled>
            <TooltipContent>{description}</TooltipContent>
        </Tooltip>
    );
};
