import { Text } from '../../text';
import { BulletNumberStyled } from './styles';

export type BulletNumberProps = {
    show?: boolean;
    value?: string;
};

const TARGET_LENGTH_OF_THE_VALUE = 2;

export const BulletNumber = ({ value = '1', show = true }: BulletNumberProps) => {
    if (!show) {
        return <></>;
    }
    return (
        <BulletNumberStyled>
            <Text as='strong' size='lg'>
                {value.padStart(TARGET_LENGTH_OF_THE_VALUE, '0')}
            </Text>
        </BulletNumberStyled>
    );
};
