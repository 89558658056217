import { ReactNode } from 'react';
import { PhoneContainerStyled, PhoneCameraStyled, PhoneDisplayStyled, ContentDataStyled, PhoneProps } from './styles';

type PhoneFrameMockupProps = PhoneProps & {
    children?: ReactNode;
};

export const PhoneFrameMockup = ({ isReduced = false, children }: PhoneFrameMockupProps) => {
    return (
        <div>
            <PhoneContainerStyled isReduced={isReduced}>
                <PhoneCameraStyled />
                <PhoneDisplayStyled isReduced={isReduced}>
                    <ContentDataStyled isReduced={isReduced}>{children}</ContentDataStyled>
                </PhoneDisplayStyled>
            </PhoneContainerStyled>
        </div>
    );
};
