import {
    NavigationTriggerRule,
    NavigationTriggerRuleConfiguration,
    NavigationTriggerTemplateConfiguration,
    PaginatedList,
    ShippingOrderEnum,
    SubToolTemplateConfigurationFrequencyRange,
    SubToolTemplateConfigurationTypeEnum,
    MessageSequence,
    SubToolConfigurationStatusEnum,
    MessageSequenceStep,
    NavigationTriggerRuleConfigurationUpdate
} from '@models';
import axiosClient from './apiService';
import { SubToolTemplatePayload } from './subToolApiService';

export type NavigationTriggerTemplateConfigurationPayload = {
    ruleConfigurationId?: string;
    templateConfigurationType: SubToolTemplateConfigurationTypeEnum;
    rules: Array<NavigationTriggerRule>;
    frequencyInMinutes: number;
    frequencyRange: SubToolTemplateConfigurationFrequencyRange;
    templates: Array<SubToolTemplatePayload>;
    isActive?: boolean;
};

export type UpdateMessageSequenceNamePayload = {
    messageSequenceId: number;
    name: string;
};

export const GetActiveTemplatesAsync = async (
    shippingOrder?: ShippingOrderEnum | number,
    templateConfigurationType?: SubToolTemplateConfigurationTypeEnum,
    ruleConfigurationId?: string
) => {
    return axiosClient.get<Array<NavigationTriggerTemplateConfiguration>>('/sub-tool/navigation-trigger/templates', {
        params: { shippingOrder, templateConfigurationType, ruleConfigurationId }
    });
};

export const ChangeStatusAsync = async (newStatus: SubToolConfigurationStatusEnum) => {
    return axiosClient.patch<boolean>('/sub-tool/navigation-trigger/configuration/status', { newStatus });
};

export const getMessageSequencesUrl = '/sub-tool/navigation-trigger/message-sequences';
export const GetMessageSequencesAsync = (page = 1, pageSize = 3, signal: AbortSignal) => {
    return axiosClient.get<PaginatedList<MessageSequence>>(getMessageSequencesUrl, {
        signal,
        params: { page, pageSize }
    });
};

export const DeleteMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.delete<boolean>(`/sub-tool/navigation-trigger/message-sequences/steps/${stepId}`);
};

export const CreateOrUpdateTemplatesAsync = async (payload: NavigationTriggerTemplateConfigurationPayload) => {
    return axiosClient.put<NavigationTriggerTemplateConfiguration>('/sub-tool/navigation-trigger/templates', payload);
};

export const DuplicateMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.post<MessageSequenceStep>(
        `/sub-tool/navigation-trigger/message-sequences/steps/${stepId}/duplicate`
    );
};

export const GetRuleConfigurationByIdentifierAsync = (identifier: string) => {
    const requestUrl = `sub-tool/navigation-trigger/message-sequences/${identifier}/rule-configuration`;
    return axiosClient.get<NavigationTriggerRuleConfiguration>(requestUrl);
};

export const UpdateRuleConfigurationAsync = (payload: NavigationTriggerRuleConfigurationUpdate) => {
    return axiosClient.post<NavigationTriggerRuleConfigurationUpdate>(
        `sub-tool/navigation-trigger/rule-configuration`,
        payload
    );
};

export const UpdateMessageSequenceNameAsync = (payload: UpdateMessageSequenceNamePayload) => {
    return axiosClient.patch<boolean>(`sub-tool/navigation-trigger/message-sequences/name`, payload);
};
