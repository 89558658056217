import _first from 'lodash/first';
import {
    PaginatedList,
    RepurchaseCategory,
    RepurchaseData,
    RepurchaseProduct,
    RepurchaseStore,
    StoreItemConfiguration
} from '@models';
import { UpdateRepurchaseItemCacheKey } from '~/services/cacheKeys';
import { RepurchaseItemConfiguration } from './useManageRepurchaseItems';
import { makeRepurchaseDataDescription, getCategoryNameOrNoCategoryDescription, TranslationData } from './utils';

type ParseToRepurchaseItem<T = unknown> = {
    data: PaginatedList<T>;
    translation: TranslationData;
    locale: string;
    cacheKey: UpdateRepurchaseItemCacheKey;
};

const createRepurchaseItemConfiguration = (
    options: { translation: TranslationData; locale: string; cacheKey: UpdateRepurchaseItemCacheKey },
    repurchaseData?: RepurchaseData,
    storeItemConfiguration?: StoreItemConfiguration
) => {
    const { averageRepurchasePeriod, countConsideredRepurchases, manualAverageRepurchasePeriod } = repurchaseData ?? {};
    const { hasTemplate, templateType, stepsAmount } = storeItemConfiguration ?? {};
    const { translation, locale, cacheKey } = options;

    const { repurchaseDetails, averageRepurchasePeriodDescription, countConsideredRepurchasesDescription } =
        makeRepurchaseDataDescription({
            averageRepurchasePeriod,
            countConsideredRepurchases,
            translation,
            locale
        });

    return {
        averageRepurchasePeriod,
        manualAverageRepurchasePeriod: manualAverageRepurchasePeriod,
        countConsideredRepurchases: countConsideredRepurchases,
        hasTemplate,
        templateType,
        stepsAmount,
        repurchaseDetails,
        averageRepurchasePeriodDescription,
        countConsideredRepurchasesDescription,
        cacheKey
    } as RepurchaseItemConfiguration;
};

export const parseStoreToRepurchaseItemConfiguration = ({
    data,
    translation,
    locale,
    cacheKey
}: ParseToRepurchaseItem<RepurchaseStore>) => {
    const items = data?.items?.map((store) => {
        const { repurchaseData, configurations } = store ?? {};
        const configuration = _first(configurations);

        return {
            id: store.id,
            identifier: String(store.id),
            type: 'general',
            name: store.name,
            ...createRepurchaseItemConfiguration({ cacheKey, locale, translation }, repurchaseData, configuration)
        } as RepurchaseItemConfiguration;
    });

    return {
        ...data,
        items
    } as PaginatedList<RepurchaseItemConfiguration>;
};

export const parseCategoryToRepurchaseItemConfiguration = ({
    data,
    translation,
    locale,
    cacheKey
}: ParseToRepurchaseItem<RepurchaseCategory>) => {
    const items = data?.items?.map((category) => {
        const { repurchaseData, configurations } = category ?? {};
        const configuration = _first(configurations);

        return {
            id: category.id,
            identifier: category.name,
            type: 'category',
            name: getCategoryNameOrNoCategoryDescription(translation, category.name),
            ...createRepurchaseItemConfiguration({ cacheKey, locale, translation }, repurchaseData, configuration)
        } as RepurchaseItemConfiguration;
    });

    return {
        ...data,
        items
    } as PaginatedList<RepurchaseItemConfiguration>;
};

export const parseProductToRepurchaseItemConfiguration = ({
    data,
    translation,
    locale,
    cacheKey
}: ParseToRepurchaseItem<RepurchaseProduct>) => {
    const items = data?.items?.map((product) => {
        const { repurchaseData, configurations } = product ?? {};
        const configuration = _first(configurations);

        return {
            id: product.productIdentifier,
            identifier: product.productIdentifier,
            type: 'product',
            name: product.name,
            imageUrl: product.image,
            category: getCategoryNameOrNoCategoryDescription(translation, product.category),
            ...createRepurchaseItemConfiguration({ cacheKey, locale, translation }, repurchaseData, configuration)
        } as RepurchaseItemConfiguration;
    });

    return {
        ...data,
        items
    } as PaginatedList<RepurchaseItemConfiguration>;
};
