import styled from 'styled-components';
import calendarIcon from '~/assets/svg/icons/icon__calendar.svg?url';

type CalendarIconStyledProps = {
    day: string | number;
    size: string | number;
};

export const CalendarIconStyled = styled.i<CalendarIconStyledProps>`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1em;
    width: 1em;
    font-size: ${({ size }) => size};

    ::before {
        position: absolute;
        content: '';
        mask: url(${calendarIcon}) no-repeat 50% 50%;
        mask-size: cover;
        color: ${({ theme }) => theme.colors.purple};
        background-color: currentColor;
        height: 1em;
        width: 1em;
        bottom: 0;
    }

    ::after {
        position: absolute;
        content: '${({ day }) => day}';
        color: ${({ theme }) => theme.colors.lightBlack};
        bottom: 0;
        font-size: 0.35em;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        line-height: 1.8em;
    }
`;
