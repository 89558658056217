import styled, { css } from 'styled-components';
import { Card } from '~/components/elements/card';
import { mobileAndTabletOnly } from '~/styles';

export const ModelSubmissionPeriodStyled = styled(Card)``;

export const ModelSubmissionPeriodContainerStyled = styled.div`
    display: flex;

    ${mobileAndTabletOnly(css`
        flex-direction: column;
    `)}

    & > div {
        padding: 0.8rem;
    }
`;
