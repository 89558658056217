import { useEffect, useMemo, useRef, useState } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import _orderBy from 'lodash/orderBy';
import { InputFormGroup, SelectData, SelectDataType, SelectFormGroup } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { DetailedStatisticsStatus, SubToolIdEnum } from '@models';
import { useAutomationStatisticsProvider } from '@providers';
import { FormHandles } from '@unform/core';
import { GetNumericValuesFromEnum, getUncapitalizeEnumKeyByValue, SortEnum, uncapitalizeEnumKeys } from '@utils';
import SearchIcon from '~/assets/svg/icons/icon__search.svg';
import { getAllTriggersUrl, GetAllTriggersAsync } from '~/services/customTriggerApiService';
import { AutomationStatisticsCustomerHeader, FilterContent, SearchFormStyled, SearchInputContent } from './styles';

type StatisticsPerCustomerHeaderProps = {
    disableForm?: boolean;
};

const makeSubToolKey = (customTriggerId?: number, subTool?: SubToolIdEnum) => {
    if (subTool) {
        return `subTool-${SubToolIdEnum[subTool]}`;
    } else if (customTriggerId) {
        return `customTrigger-${customTriggerId}`;
    }

    return null;
};

const DEDUPING_INTERVAL_TIMEOUT_IN_MINUTES = 1;

export const StatisticsPerCustomerHeader = ({ disableForm = false }: StatisticsPerCustomerHeaderProps) => {
    const [searchFieldFocused, setSearchFieldFocused] = useState(false);
    const {
        enums,
        pages: {
            automationStatistics: {
                statisticsPerCustomerSection: { searchForm, title }
            }
        },
        enums: { subToolIdEnum }
    } = useTranslator();

    const { data: triggersData } = useFetch(
        { url: getAllTriggersUrl },
        async () => {
            return (await GetAllTriggersAsync()).data;
        },
        {
            dedupingInterval: minutesToMilliseconds(DEDUPING_INTERVAL_TIMEOUT_IN_MINUTES),
            keepPreviousData: true,
            fallbackData: {}
        }
    );

    const dropdowns = useMemo(() => {
        const nullValue: SelectData = { id: null, label: String(searchForm.subToolDropdown.options.default) };
        const subToolOptions = [nullValue]
            .concat(
                _orderBy(
                    GetNumericValuesFromEnum(SubToolIdEnum)
                        .filter((subToolId) => ![SubToolIdEnum.Emails, SubToolIdEnum.CustomTrigger].includes(subToolId))
                        .map<SelectData>((subToolId) => ({
                            id: makeSubToolKey(null, subToolId),
                            label: subToolIdEnum[SubToolIdEnum[subToolId]]
                        })),
                    'text'
                )
            )
            .concat(
                Object.entries(triggersData).map<SelectData>(([triggerId, triggerName]) => ({
                    id: makeSubToolKey(Number(triggerId), null),
                    label: triggerName
                }))
            );

        const sortByOptions: Array<SelectData> = [
            { id: 'asc', label: String(searchForm.sortBy.options.older) },
            { id: 'desc', label: String(searchForm.sortBy.options.mostRecent) }
        ];

        const defaultStatusValue: SelectData = {
            id: null,
            label: String(searchForm.statusDropdown.options.default)
        };

        const statusOptions = uncapitalizeEnumKeys(DetailedStatisticsStatus).map((key) => {
            return { id: key, label: String(enums.detailsStatisticsStatus[key]) };
        });
        return {
            subTool: subToolOptions,
            sortBy: sortByOptions,
            status: [defaultStatusValue, ..._orderBy(statusOptions, 'label')]
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggersData]);

    const searchFormRef = useRef<FormHandles>();

    const { filterParams, filter } = useAutomationStatisticsProvider((state) => ({
        filterParams: state.filterParams,
        filter: state.filterByCustomerParams
    }));

    useEffect(() => {
        if (!searchFormRef.current) {
            return;
        }

        searchFormRef.current.setData({
            search: filterParams.search,
            subTool: makeSubToolKey(filterParams.customTriggerId, filterParams.subTool),
            sortBy: getUncapitalizeEnumKeyByValue(filterParams.sortByDate, SortEnum),
            status: getUncapitalizeEnumKeyByValue(filterParams.status, DetailedStatisticsStatus)
        });
    }, [filterParams]);

    const handleSubmit = ({ search }) => {
        setSearchFieldFocused(false);
        filter({ search, page: '1' });
    };

    const handleChangeSubToolFilter = (value: SelectDataType) => {
        if (typeof value.id === 'number') {
            // Unreachable.
            return;
        }

        // "subTool-<number>" or "customTrigger-<number>"
        const commonProps = { page: '1' };
        const subToolIdOrCustomTriggerId = value.id?.split('-')[1];
        if (value.id?.startsWith('subTool')) {
            filter({
                subTool: subToolIdOrCustomTriggerId,
                customTriggerId: null,
                ...commonProps
            });
        } else if (value.id?.startsWith('customTrigger')) {
            filter({
                customTriggerId: subToolIdOrCustomTriggerId,
                subTool: null,
                ...commonProps
            });
        }
    };

    return (
        <AutomationStatisticsCustomerHeader
            title={String(title)}
            type='group'
            actions={
                <SearchFormStyled
                    ref={searchFormRef}
                    onSubmit={handleSubmit}
                    initialData={{
                        search: filterParams.search,
                        subTool: makeSubToolKey(filterParams.customTriggerId, filterParams.subTool),
                        sortBy: getUncapitalizeEnumKeyByValue(filterParams.sortByDate, SortEnum),
                        status: getUncapitalizeEnumKeyByValue(filterParams.status, DetailedStatisticsStatus)
                    }}>
                    <SearchInputContent className={searchFieldFocused ? 'has-focus' : ''}>
                        <InputFormGroup
                            type='text'
                            inputSize='small'
                            name='search'
                            disabled={disableForm}
                            onFocus={() => setSearchFieldFocused(true)}
                            onBlur={() => setSearchFieldFocused(false)}
                            placeholder={String(searchForm.search.placeholder)}
                            onKeyDown={(event) => event.key === 'Enter' && searchFormRef.current?.submitForm()}
                            icon={<SearchIcon height='1.8rem' onClick={() => searchFormRef.current?.submitForm()} />}
                        />
                        <div>
                            <SelectFormGroup
                                name='sortBy'
                                disabled={disableForm}
                                label={String(searchForm.sortBy.label)}
                                options={dropdowns.sortBy}
                                onOptionChange={(value) => filter({ sortByDate: value.id as string, page: '1' })}
                            />
                        </div>
                    </SearchInputContent>

                    <FilterContent>
                        <SelectFormGroup
                            name='subTool'
                            disabled={disableForm}
                            label={String(searchForm.subToolDropdown.label)}
                            options={dropdowns.subTool}
                            onOptionChange={(value) => handleChangeSubToolFilter(value)}
                        />
                        <SelectFormGroup
                            name='status'
                            disabled={disableForm}
                            label={String(searchForm.statusDropdown.label)}
                            options={dropdowns.status}
                            onOptionChange={(value) => filter({ status: value.id as string, page: '1' })}
                        />
                    </FilterContent>
                </SearchFormStyled>
            }
        />
    );
};
