import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import SearchIcon from '~/assets/svg/icons/icon__search.svg';
import { FormGroup } from '~/components/forms';
import { tabletAndDesktopOnly, mobileOnly } from '~/styles';
export const SearchGroupStyled = styled(motion.div).attrs({
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
})``;

export const FormGroupStyled = styled(FormGroup)`
    gap: 1.6rem;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;

    & > div {
        padding: 0;
        ${tabletAndDesktopOnly(css`
            button div span {
                width: 24rem;
            }
        `)}

        ${mobileOnly(css`
            button div span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 12rem;
            }
        `)}
    }

    .search-input {
        align-items: flex-end;
        & > div {
            flex-direction: row-reverse;
            max-width: 27rem;
        }

        svg {
            color: ${({ theme }) => theme.colors.philippineSilver};
            transform: rotate(90deg);
        }
    }
`;

export const SearchIconStyled = styled(SearchIcon)`
    margin-left: 1.6rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.purple};
`;

export const DropdownContentStyled = styled.div`
    display: flex;
    justify-content: flex-end;

    & > div {
        padding: 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 1.6rem;
        width: 100%;

        & > label {
            font-size: 1.6rem;
            color: ${({ theme }) => theme.colors.lightBlack};
            line-height: 2.1rem;
            letter-spacing: 0.01px;
            font-weight: normal;
            margin: 0;
            white-space: nowrap;
        }

        & > div {
            min-width: 23.3rem;

            & > div {
                min-width: 28.7rem;
                right: 0;
            }
        }
    }
`;
