import qs from 'qs';
import {
    Campaign,
    CustomTriggerGeneralStatistics,
    ExportStatisticsSelection,
    GeneralDetailedStatistics,
    GeneralStatistics,
    PaginatedList,
    SendingTechnologyTrackStatistics
} from '@models';
import { SortEnum } from '@utils';
import { axiosClient } from './apiService';

export type GetGeneralStatisticsRequestType = {
    startDate: Date;
    endDate?: Date;
    subToolId?: number;
};

export type GetDetailedStatisticsRequestType = {
    pageNumber?: number;
    pageSize?: number;
    startDate: Date;
    endDate?: Date;
    subToolIDs?: Array<number>;
    statuses?: Array<number>;
    search?: string;
    sortByDate?: SortEnum;
    itemsWithSales?: boolean;
    customTriggerIds?: Array<number>;
};

export const statisticsRoute = 'statistics';
export const customersStatisticsRoute = `${statisticsRoute}/customers`;
export const getLatestCampaignsUrl = `${statisticsRoute}/campaigns/recent`;
export const getGeneralStatisticsUrl = `${statisticsRoute}/sub-tool`;
export const getGeneralCustomTriggerStatisticsUrl = `${statisticsRoute}/custom-trigger`;
export const getRepurchasePercentageUrl = `${statisticsRoute}/sub-tool/repurchase/percentage`;
export const exportStatisticsRoute = `${statisticsRoute}/export`;
export const getSendingTechnologyDetailedStatisticsUrl = (transactionItemId: string) =>
    `${customersStatisticsRoute}/${transactionItemId}/sending-technology`;
export const getDetailedGeneralStatisticsUrl = (subToolId: number) =>
    `${statisticsRoute}/sub-tool/${subToolId}/sending-technology`;
export const getCustomTriggerDetailedGeneralStatisticsUrl = (customTriggerId: number) =>
    `${statisticsRoute}/custom-trigger/${customTriggerId}/sending-technology`;

export const GetGeneralStatisticsAsync = async (
    params: GetGeneralStatisticsRequestType,
    signal: AbortSignal = null
) => {
    return axiosClient.get<GeneralStatistics>(getGeneralStatisticsUrl, { params, signal });
};

export const GetGeneralCustomTriggerStatisticsAsync = async (
    params: Omit<GetGeneralStatisticsRequestType, 'subToolId'>,
    signal: AbortSignal = null
) => {
    return axiosClient.get<CustomTriggerGeneralStatistics>(getGeneralCustomTriggerStatisticsUrl, { params, signal });
};

export const GetDetailedGeneralStatisticsAsync = async (
    { subToolId, ...params }: GetGeneralStatisticsRequestType,
    signal: AbortSignal = null
) => {
    return axiosClient.get<Array<SendingTechnologyTrackStatistics>>(getDetailedGeneralStatisticsUrl(subToolId), {
        params,
        signal
    });
};

export const GetCustomTriggerDetailedGeneralStatisticsAsync = async (
    { customTriggerId, ...params }: Omit<GetGeneralStatisticsRequestType, 'subToolId'> & { customTriggerId: number },
    signal: AbortSignal = null
) => {
    return axiosClient.get<Array<SendingTechnologyTrackStatistics>>(
        getCustomTriggerDetailedGeneralStatisticsUrl(customTriggerId),
        {
            params,
            signal
        }
    );
};

export const GetDetailedStatistics = async (params: GetDetailedStatisticsRequestType, signal: AbortSignal = null) => {
    return axiosClient.get<PaginatedList<GeneralDetailedStatistics>>(customersStatisticsRoute, {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
        signal
    });
};

export const GetSendingTechnologyDetailedStatistics = async (transactionItemId: string, signal: AbortSignal = null) => {
    return axiosClient.get<Array<SendingTechnologyTrackStatistics>>(
        getSendingTechnologyDetailedStatisticsUrl(transactionItemId),
        {
            signal
        }
    );
};

export const StartExportingStatistics = async (
    params: GetDetailedStatisticsRequestType,
    format: ExportStatisticsSelection,
    signal: AbortSignal = null
) => {
    return axiosClient.get<string>(`${exportStatisticsRoute}/start`, {
        signal,
        params: {
            startDate: params.startDate,
            endDate: params.endDate,
            subToolIDs: params.subToolIDs,
            statuses: params.statuses,
            search: params.search,
            itemsWithSales: params.itemsWithSales,
            format
        }
    });
};

export const CheckStatistics = async (keyName: string, signal: AbortSignal = null) => {
    return axiosClient.get<string>(`${exportStatisticsRoute}/check/${keyName}`, {
        signal
    });
};

export const GetLatestCampaignsAsync = async (limit = 5, signal: AbortSignal = null) => {
    return axiosClient.get<Array<Campaign>>(getLatestCampaignsUrl, {
        params: {
            limit
        },
        signal
    });
};

type GetRepurchasePercentageProps = {
    startDate: Date;
    endDate?: Date;
};

export const GetRepurchasePercentageAsync = (params: GetRepurchasePercentageProps, signal: AbortSignal = null) => {
    return axiosClient.get<number>(getRepurchasePercentageUrl, {
        params,
        signal
    });
};
