import styled from 'styled-components';

export const SendingTechnologyTooltipStyled = styled.div`
    max-width: 35rem;
    & > p {
        & > b {
            font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        }

        &:not(:last-child) {
            margin-bottom: 1.6rem;
        }
    }
`;

export const SendingTechnologyContentStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
