import styled, { css } from 'styled-components';
import { mobileAndTabletOnly } from '~/styles';

export const ContractLooseBalanceStyled = styled.div`
    margin-bottom: 10rem;

    & > h1 {
        margin-bottom: 3.2rem;
    }

    & > form {
        footer {
            margin-top: 3.2rem;
            display: flex;
            justify-content: space-between;

            button {
                min-width: 19.2rem;
            }
        }
    }
`;

export const ContractLooseBalanceContentStyled = styled.div`
    display: flex;
    gap: 3.2rem;

    ${mobileAndTabletOnly(css`
        flex-direction: column;
    `)}
`;
