import { useCallback, useState } from 'react';
import { secondsToMilliseconds } from 'date-fns';
import { CardBody, Pagination } from '@components';
import { subToolsFunctions, useFetch, useSubToolPage } from '@hooks';
import {
    MessageSequence,
    MessageSequenceStep,
    PaginatedList,
    SendingTechnologyTypeEnum,
    SubToolConfiguration,
    SubToolIdEnum
} from '@models';
import { AutomationRulerProvider, useAuth } from '@providers';
import { GetMessageSequencesCacheKey } from '~/services/cacheKeys';
import { SubToolColors } from '~/styles/theme';
import { AutomationRulerHeader, RulerSteps, SubToolActionButtons } from '..';
import { CardStyled, CustomTriggerRulerStyled } from './styles';

type CustomTriggerRulerProps = {
    actions?: unknown[];
    subToolConfiguration: SubToolConfiguration;
};

export const defaultMessageSequenceItem: Array<MessageSequence> = [
    {
        id: 0,
        name: '',
        type: 1,
        subToolConfiguration: null,
        steps: Array.from({ length: 5 }).map(
            (_, index) =>
                ({
                    id: index,
                    templates: [
                        { sendingTechnology: SendingTechnologyTypeEnum.Email, isActive: true },
                        { sendingTechnology: SendingTechnologyTypeEnum.SMS, isActive: true },
                        { sendingTechnology: SendingTechnologyTypeEnum.Push, isActive: false },
                        { sendingTechnology: SendingTechnologyTypeEnum.WhatsApp, isActive: false }
                    ],
                    intervalMinutes: 60,
                    name: 'Your site is not yet integrated with our platform, to learn how to integrate.',
                    shippingOrder: 1,
                    creationDate: new Date(),
                    updateDate: new Date(),
                    messageSequence: null
                } as MessageSequenceStep)
        )
    }
];

const MIN_INTERVAL_IN_SECONDS_TO_REQUERY_DATA = 10;

export const CustomTriggerRuler = ({ subToolConfiguration }: CustomTriggerRulerProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const { user } = useAuth();
    const {
        subTool: { id: subToolId }
    } = subToolConfiguration;

    const { data, isLoading, mutate } = useFetch(
        user?.activeSubscriptionId &&
            subToolId &&
            ({
                url: subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequencesUrl,
                subToolId,
                currentPage
            } as GetMessageSequencesCacheKey),
        async ({ subToolId, currentPage }, signal) => {
            let response;
            try {
                response = await subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequences(
                    { page: currentPage },
                    signal
                );
            } catch (error) {
                response = { data: { items: [] } };
            }

            if (response.data.items) {
                return response.data as PaginatedList<MessageSequence>;
            }

            return {
                items: [response.data]
            } as PaginatedList<MessageSequence>;
        },
        {
            keepPreviousData: true,
            dedupingInterval: secondsToMilliseconds(MIN_INTERVAL_IN_SECONDS_TO_REQUERY_DATA)
        }
    );

    const { items } = data ?? ({} as PaginatedList<MessageSequence>);

    let messageSequences: Array<MessageSequence> = [];
    messageSequences = items ?? (isLoading ? defaultMessageSequenceItem : []);

    const { getSubToolPageData } = useSubToolPage();
    const { subToolKey } = getSubToolPageData(subToolId as SubToolIdEnum);

    const handleRevalidateOnChange = useCallback(async () => {
        const result = await mutate();

        if (result?.items?.length === 0) {
            setCurrentPage(1);
        }

        return result;
    }, [mutate]);

    return (
        <>
            {messageSequences.map((messageSequence) => (
                <AutomationRulerProvider
                    subToolConfiguration={subToolConfiguration}
                    customTrigger={messageSequence?.customTrigger}
                    sequenceId={messageSequence.id}
                    revalidateOnChange={handleRevalidateOnChange}
                    key={messageSequence.id}>
                    <CustomTriggerRulerStyled>
                        <CardStyled
                            type='group'
                            borderColor={subToolKey as SubToolColors}
                            id={subToolKey}
                            hasItems={messageSequences.length > 0}>
                            <AutomationRulerHeader name={messageSequence.name} messageSequenceId={messageSequence.id}>
                                <SubToolActionButtons isLoading={isLoading} />
                            </AutomationRulerHeader>
                            <CardBody>
                                <RulerSteps
                                    key={messageSequence.id}
                                    messageSequence={messageSequence}
                                    showMessageSequenceName={false}
                                    isLoading={isLoading && !data}
                                />
                            </CardBody>
                        </CardStyled>
                    </CustomTriggerRulerStyled>
                </AutomationRulerProvider>
            ))}
            <Pagination
                currentPage={data?.pageNumber}
                totalRecords={data?.totalItems}
                pageLimit={data?.pageSize}
                onPageChange={({ currentPage }) => setCurrentPage(currentPage)}
            />
        </>
    );
};
