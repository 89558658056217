import { Reducer } from 'react';
import { PaymentMethodTypeEnum } from '@models';
import { ActionMap } from './ActionMap';

export type SubscriptionPlanFormData = {
    creditVolume?: number;
    sendingTechnologies: Array<number>;
    emailMarketingAmount?: number;
    billingPeriodId?: number;
};

export type BillingResponsibleFormData = {
    name: string;
    email: string;
    telephone: string;
    cellPhoneForWhatsApp?: string;
};

export type BillingFormData = {
    corporateName: string;
    cpfCnpj: string;
    // Same as BillingResponsibleFormData but used in the financial data modal for suspended subscriptions
    telephone?: string;
    address: {
        zipCode: string;
        street: string;
        number: string;
        complement?: string;
        neighborhood: string;
        city: string;
        countryCode: string;
        stateCode: string;
    };
};

export type SubscriptionDataFormData = {
    billingResponsible?: BillingResponsibleFormData;
    billingData: BillingFormData;
};

export type PaymentFormData = {
    paymentMethod: PaymentMethodTypeEnum;
    creditCard?: {
        number: string;
        cardholderName: string;
        cvv: string;
        brand: string;
        expirationMonth: number;
        expirationYear: number;
    };
    bankSlip?: {
        firstDueDate: string;
    };
    payPal?: {
        nonce: string;
    };
};

export type SubscriptionPaymentFormData = PaymentFormData & {
    discountCoupon: string;
    hasAcceptedTheTerms: boolean;
};

export type SubscriptionFormData = {
    subscriptionId?: number;
    subscriptionPlan?: SubscriptionPlanFormData;
    subscriptionData?: SubscriptionDataFormData;
    subscriptionPayment?: SubscriptionPaymentFormData;
};

export enum SubscriptionReducerActionTypesEnum {
    Update = 'UPDATE',
    UpdatePlan = 'UPDATE_PLAN',
    UpdateSubscriptionData = 'UPDATE_SUBSCRIPTION_DATA',
    UpdatePayment = 'UPDATE_PAYMENT',
    UpdateCouponAttemptResult = 'UPDATE_APPLIED_COUPON'
}

type SubscriptionActionPayload = {
    [SubscriptionReducerActionTypesEnum.Update]: SubscriptionFormData;
    [SubscriptionReducerActionTypesEnum.UpdatePlan]: { subscriptionPlan: SubscriptionPlanFormData };
    [SubscriptionReducerActionTypesEnum.UpdateSubscriptionData]: { subscriptionData: SubscriptionDataFormData };
    [SubscriptionReducerActionTypesEnum.UpdatePayment]: { subscriptionPayment: SubscriptionPaymentFormData };
};

export type SubscriptionReducerActions =
    ActionMap<SubscriptionActionPayload>[keyof ActionMap<SubscriptionActionPayload>];

export const subscriptionReducer: Reducer<SubscriptionFormData, SubscriptionReducerActions> = (prevState, action) => {
    switch (action.type) {
        case SubscriptionReducerActionTypesEnum.Update:
            return action.payload;

        case SubscriptionReducerActionTypesEnum.UpdatePlan:
            return { ...prevState, subscriptionPlan: action.payload.subscriptionPlan };

        case SubscriptionReducerActionTypesEnum.UpdateSubscriptionData:
            return { ...prevState, subscriptionData: action.payload.subscriptionData };

        case SubscriptionReducerActionTypesEnum.UpdatePayment:
            return { ...prevState, subscriptionPayment: action.payload.subscriptionPayment };

        default:
            return prevState;
    }
};
