import { SWRConfig, SWRConfiguration } from 'swr';
import { requestCancellation } from './middlewares';

const swrConfig: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    shouldRetryOnError: false,
    use: [requestCancellation]
};

export const SWRProvider = ({ children }) => {
    return <SWRConfig value={{ ...swrConfig }}>{children}</SWRConfig>;
};
