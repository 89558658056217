import { CanceledError } from 'axios';
import { minutesToMilliseconds } from 'date-fns';
import { CardBody, Pagination, Shimmer, Text } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { DetailedStatisticsStatus, GeneralDetailedStatistics, transactionStatusByStatisticsStatus } from '@models';
import { CUSTOMER_SECTION_ID, ExpandableGroupProvider, useAlert, useAutomationStatisticsProvider } from '@providers';
import { isBrowser } from '@utils';
import { customersStatisticsRoute, GetDetailedStatistics } from '~/services/statisticsApiService';
import { ExportEstatisticsModal } from '../exportStatisticsModal';
import { CustomerItem } from './customerItem';
import { StatisticsPerCustomerHeader } from './header';
import { SendingTechnologiesContent } from './sendingTechnologiesContent';
import {
    CustomerListHeaderStyled,
    CustomerListItem,
    CustomerListItemContentStyled,
    CustomerListItemStyled,
    CustomerListStyled,
    DefaultPaginationContentStyled,
    EmptyCustomerListItem,
    StatisticsPerCustomerContainer
} from './styles';

const MINUTES_TO_REQUERY_DATA = 1;

const PAGE_SIZE = 10;

const defaultValues = {
    pageNumber: 1,
    totalItems: PAGE_SIZE,
    pageSize: PAGE_SIZE,
    items: Array.from({ length: PAGE_SIZE }).map(
        (_, index) =>
            ({
                transactionItemId: String(index),
                date: new Date(),
                shopperName: '',
                shopperEmail: '',
                shopperMobile: '',
                subToolId: 1,
                status: 2,
                totalSalesValue: 0,
                currency: 'BRL',
                sendingTechnologyStatistics: []
            } as GeneralDetailedStatistics)
    )
};

export const AutomationStatisticsPerCustomer = () => {
    const { error: errorAlert } = useAlert();
    const {
        common: {
            lists: { emptyLabel }
        },
        dialogs: {
            errors: { failedToQueryStatistics }
        },
        pages: {
            automationStatistics: {
                statisticsPerCustomerSection: { table }
            }
        }
    } = useTranslator();

    const { allowSearch, filterParams, subscriptionSendingTechnologies, filter } = useAutomationStatisticsProvider(
        (state) => ({
            filterParams: state.filterParams,
            filter: state.filterByCustomerParams,
            allowSearch: state.allowSearch,
            subscriptionSendingTechnologies: state.subscriptionSendingTechnologies
        })
    );

    const { data: customerStatistics, isLoading } = useFetch(
        allowSearch && { url: customersStatisticsRoute, ...filterParams },
        async ({ startDate, endDate, page, search, sortByDate, status, subTool, customTriggerId }, signal) => {
            let statuses = [];
            let itemsWithSales = null;

            if (status && status !== DetailedStatisticsStatus.GeneratedSales) {
                statuses = transactionStatusByStatisticsStatus[status] as unknown as Array<number>;
                itemsWithSales = false;
            } else if (status === DetailedStatisticsStatus.GeneratedSales) {
                itemsWithSales = true;
            }

            try {
                const response = await GetDetailedStatistics(
                    {
                        startDate,
                        endDate,
                        pageNumber: page,
                        pageSize: PAGE_SIZE,
                        sortByDate,
                        subToolIDs: [subTool],
                        statuses,
                        itemsWithSales,
                        search,
                        customTriggerIds: [customTriggerId]
                    },
                    signal
                );

                return response.data;
            } catch (error) {
                const isCanceled = error instanceof CanceledError;

                if (!isCanceled) {
                    isBrowser() &&
                        errorAlert(String(failedToQueryStatistics.title), String(failedToQueryStatistics.description));
                }

                return { pageNumber: 1, totalItems: PAGE_SIZE, pageSize: PAGE_SIZE, items: [] };
            }
        },
        {
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_REQUERY_DATA),
            fallbackData: defaultValues,
            keepPreviousData: true
        }
    );

    const hasItems = customerStatistics?.items?.length > 0;

    return (
        <StatisticsPerCustomerContainer type='group' id={CUSTOMER_SECTION_ID}>
            <StatisticsPerCustomerHeader disableForm={isLoading} />
            <CardBody>
                <CustomerListStyled>
                    <CustomerListHeaderStyled>
                        <CustomerListItemContentStyled>
                            {Object.entries(table.headers).map(([key, value]) => (
                                <Text as='strong' size='lg' key={key}>
                                    {String(value)}
                                </Text>
                            ))}
                        </CustomerListItemContentStyled>
                    </CustomerListHeaderStyled>
                    <Shimmer isActive={isLoading}>
                        {customerStatistics?.items?.map((item: GeneralDetailedStatistics) => {
                            return (
                                <CustomerListItemStyled
                                    data-transaction={item.transactionItemId}
                                    key={item.transactionItemId}
                                    data-shimmer>
                                    <ExpandableGroupProvider data={item} enabled={!isLoading}>
                                        <CustomerItem />
                                        <SendingTechnologiesContent
                                            subscriptionSendingTechnologies={subscriptionSendingTechnologies}
                                        />
                                    </ExpandableGroupProvider>
                                </CustomerListItemStyled>
                            );
                        })}
                        {!hasItems && (
                            <CustomerListItem>
                                <EmptyCustomerListItem>
                                    <Text as='span'>{String(emptyLabel)}</Text>
                                </EmptyCustomerListItem>
                            </CustomerListItem>
                        )}
                    </Shimmer>
                </CustomerListStyled>
                <Pagination
                    currentPage={filterParams?.page}
                    totalRecords={customerStatistics?.totalItems}
                    pageLimit={customerStatistics?.pageSize}
                    onPageChange={({ currentPage }) => filter({ page: String(currentPage) })}
                    content={(props) => (
                        <DefaultPaginationContentStyled {...props}>
                            <ExportEstatisticsModal />
                        </DefaultPaginationContentStyled>
                    )}
                />
            </CardBody>
        </StatisticsPerCustomerContainer>
    );
};
