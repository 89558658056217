import { useCallback, useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import toNumber from 'lodash/toNumber';
import uniqBy from 'lodash/uniqBy';
import { Card, CardHeader, ToggleButton, ToggleButtonFormGroup, InfoTooltip, Text } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { SendingTechnology, SendingTechnologyTypeEnum } from '@models';
import { TooltipContent } from '~/components/elements/tooltip/content';
import {
    SubscriptionPlanFormData,
    SubscriptionReducerActionTypesEnum,
    useCheckout,
    usePaymentMethods
} from '~/providers';
import {
    GetSendingTechnologiesByCreditAmount,
    GetSendingTechnologyByName,
    GetSendingPriceByPeriodicity,
    GetBillingPeriodById
} from '~/services/subscriptionService';
import { CardBodyStyled } from '../styles';
import { SendingTechnologyContentStyled, SendingTechnologyTooltipStyled } from './styles';

type ThirdStepProps = {
    title: string;
    sendingTechnologies: Array<SendingTechnology>;
};

export const ThirdStep = ({ title, sendingTechnologies }: ThirdStepProps) => {
    const { formatCurrency } = useNumberFunctions();
    const { billingPeriods } = usePaymentMethods();
    const { dispatchSubscriptionFormData, subscriptionFormData } = useCheckout();
    const [list, setList] = useState<Array<SendingTechnology>>(
        uniqBy(
            sendingTechnologies.filter((item) => !item.isCustomValue),
            'type'
        )
    );
    const [creditVolume, setCreditVolume] = useState<number>(0);

    const {
        checkoutPage,
        common: { free }
    } = useTranslator();
    const { common, steps } = checkoutPage.subscriptionPlan.automationPlans;
    const textTranslated = steps.third;

    useEffect(() => {
        const currentCreditVolume = subscriptionFormData?.subscriptionPlan?.creditVolume;

        if (!sendingTechnologies.find((item) => item.creditAmount === currentCreditVolume)) {
            return;
        }

        if (currentCreditVolume && currentCreditVolume !== creditVolume) {
            const filteredSendingTechnologies = GetSendingTechnologiesByCreditAmount(
                sendingTechnologies,
                currentCreditVolume
            );

            if (filteredSendingTechnologies) {
                setCreditVolume(currentCreditVolume);
                setList(filteredSendingTechnologies);
            }
        }
    }, [subscriptionFormData, sendingTechnologies, creditVolume]);

    const handleChange = useCallback(
        (value: Array<number | string>) => {
            value = value.map((datum) => toNumber(datum));

            const pushTechnology = (list ?? []).find(
                (item) => item.creditAmount === creditVolume && item.type === SendingTechnologyTypeEnum.Push
            );

            const emailTechnology = (list ?? []).find(
                (item) => item.creditAmount === creditVolume && item.type === SendingTechnologyTypeEnum.Email
            );

            if (value.includes(emailTechnology.id) && pushTechnology && !value.includes(pushTechnology.id)) {
                value.push(pushTechnology?.id);
            } else if (
                pushTechnology?.id &&
                value.includes(pushTechnology?.id) &&
                !value.includes(emailTechnology.id)
            ) {
                value = value.filter((item) => item !== pushTechnology?.id);
            }

            const subscriptionPlan: SubscriptionPlanFormData = subscriptionFormData?.subscriptionPlan ?? {
                sendingTechnologies: null
            };

            subscriptionPlan.sendingTechnologies = value as Array<number>;

            dispatchSubscriptionFormData({
                type: SubscriptionReducerActionTypesEnum.UpdatePlan,
                payload: { subscriptionPlan }
            });
        },
        [dispatchSubscriptionFormData, subscriptionFormData, list, creditVolume]
    );

    const periodicityInMonths = GetBillingPeriodById(
        billingPeriods,
        subscriptionFormData?.subscriptionPlan?.billingPeriodId
    )?.periodicityInMonths;

    const currentCreditVolume = subscriptionFormData?.subscriptionPlan?.creditVolume ?? 0;

    return (
        <Card>
            <CardHeader
                bulletNumber={{ show: true, value: '03' }}
                subtitle={
                    <>
                        {title}
                        <InfoTooltip>
                            <TooltipContent>
                                <SendingTechnologyTooltipStyled>
                                    <Text size='xs'>{HTMLReactParser(String(textTranslated.tooltip.email))}</Text>
                                    <Text size='xs'>{HTMLReactParser(String(textTranslated.tooltip.sms))}</Text>
                                    <Text size='xs'>{HTMLReactParser(String(textTranslated.tooltip.push))}</Text>
                                    <Text size='xs'>{HTMLReactParser(String(textTranslated.tooltip.whatsApp))}</Text>
                                </SendingTechnologyTooltipStyled>
                            </TooltipContent>
                        </InfoTooltip>
                    </>
                }
            />
            <CardBodyStyled>
                <ToggleButtonFormGroup name='sendingTechnologies' spacing={0.8} onChange={handleChange}>
                    {list?.map((sendingTechnology) => {
                        const technologyName = GetSendingTechnologyByName(
                            sendingTechnology.type,
                            common.sendingTechnology
                        );

                        const technologyPrice = GetSendingPriceByPeriodicity(sendingTechnology, periodicityInMonths);

                        return (
                            <ToggleButton
                                value={sendingTechnology.id}
                                key={sendingTechnology.id}
                                inputSize='small'
                                disabled={
                                    currentCreditVolume === 0 ||
                                    sendingTechnology.type === SendingTechnologyTypeEnum.Push
                                }
                                testId={`toggle-button__${technologyName}`}>
                                <SendingTechnologyContentStyled>
                                    <span>{technologyName}</span>
                                    <span>
                                        {(!currentCreditVolume || currentCreditVolume === 0) && '-'}
                                        {currentCreditVolume > 0 &&
                                            (!technologyPrice
                                                ? String(free)
                                                : formatCurrency(technologyPrice, sendingTechnology.currency))}
                                    </span>
                                </SendingTechnologyContentStyled>
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonFormGroup>
            </CardBodyStyled>
        </Card>
    );
};

export default ThirdStep;
