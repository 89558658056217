import styled, { css } from 'styled-components';
import TedIcon from '~/assets/svg/ted--sad.svg';
import { mobileAndTabletOnly } from '~/styles';

export const ErrorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto 0;
    gap: 3rem;
    flex: 1 1 0;
    color: ${({ theme }) => theme.colors.purple};

    & > div {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 13rem;
        }

        h3 {
            text-align: center;
        }

        div {
            margin-top: 4rem;
            margin-bottom: 12rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                margin-bottom: 1.6rem;
                color: inherit;
            }

            p {
                max-width: 90%;
                margin-bottom: 1.6rem;
            }

            span {
                margin-bottom: 1.6rem;
            }
        }
    }

    ${mobileAndTabletOnly(css`
        flex-direction: column;

        & > div {
            h1 {
                font-size: 10rem;
                margin-top: 0;
            }

            div {
                margin-top: 3rem;
                margin-bottom: 0;

                h2 {
                    font-size: 4.5rem;
                }
            }
        }
    `)}
`;

export const TedIconStyled = styled(TedIcon)`
    width: 100%;
    height: 42vh;
    margin: auto;

    ${mobileAndTabletOnly(css`
        height: 24vh;
    `)}
`;
