import styled from 'styled-components';
import semicircleRightDark from '~/assets/svg/background/semicircle--right-dark.svg?url';
import semicircleRight from '~/assets/svg/background/semicircle--right.svg?url';
import { cssMaxWidthWithPaddingX } from '~/styles';

export const DefaultLayoutStyled = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    & > main {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        background-image: url(${({ theme }) => (theme.theme === 'light' ? semicircleRight : semicircleRightDark)});
        background-repeat: no-repeat;
        background-position: left;
        background-size: 90% 90%;
    }
`;

export const DefaultLayoutContentStyled = styled.div`
    width: 100%;
    padding: 3.2rem;
    ${cssMaxWidthWithPaddingX}
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
    justify-content: flex-start;
    align-content: center;
    flex: 1;
`;
