import _has from 'lodash/has';
import {
    AddVariableFieldName,
    getVariable,
    LocalVariables,
    TemplateVariable,
    VariableParentKey
} from './localVariables';
import {
    getAllTemplateVariables,
    replaceTemplateVariableName,
    replaceTemplateVariableRegex
} from './templateVariables';
import { normalizeVariable } from './variableFunctions';

export const translateVariable = (variable: TemplateVariable, localVariables: LocalVariables) => {
    if (!variable) {
        return null;
    }

    const parentKey = variable.parentKey;
    let key = variable.key;
    let fieldName: AddVariableFieldName = 'name';

    if (variable.isRegex && variable.originalKey) {
        key = variable.originalKey;
        fieldName = 'regex';
    }

    let translatedVariable: string = null;

    if (parentKey && _has(localVariables, parentKey)) {
        const localVariable = localVariables[parentKey];

        if (key && _has(localVariable, key) && _has(localVariable[key], fieldName)) {
            translatedVariable = localVariable[key][fieldName];
        }
    } else if (key && _has(localVariables, key) && _has(localVariables[key], fieldName)) {
        translatedVariable = localVariables[key][fieldName];
    }

    return translatedVariable?.toUpperCase();
};

export const translateAllTemplateVariable = (
    template: string,
    localVariables: LocalVariables,
    parentKey: VariableParentKey
) => {
    let translatedTemplate = template;
    const templateVariables = getAllTemplateVariables(template);

    templateVariables.forEach((variableName) => {
        variableName = normalizeVariable(variableName);
        const variable = getVariable(variableName, parentKey);

        const translatedVariable = translateVariable(variable, localVariables);

        if (translatedVariable) {
            if (!variable.isRegex) {
                translatedTemplate = replaceTemplateVariableName(translatedTemplate, variableName, translatedVariable);
            } else {
                translatedTemplate = replaceTemplateVariableRegex(translatedTemplate, variable, translatedVariable);
            }
        }
    });

    return translatedTemplate;
};
