import { useEffect, useRef } from 'react';
import { RadioButtonGroup, RadioButtonGroupProps, Label } from '@components';
import { useField } from '@unform/core';
import { RadioButtonFormGroupStyledProps, RadioButtonFormGroupStyled } from './styles';

type RadioGroupProps = RadioButtonGroupProps &
    Partial<RadioButtonFormGroupStyledProps> & {
        label?: string;
    };

export const RadioButtonFormGroup = ({ children, name, label, flexGrid = {}, ...props }: RadioGroupProps) => {
    const ref = useRef<Array<HTMLInputElement>>([]);
    const { fieldName, defaultValue = props.defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField<number | string>({
            name: fieldName,
            ref: ref.current,
            getValue: (refs) => refs?.find((ref) => ref.checked)?.value ?? null,
            setValue: (refs, value) => refs?.forEach((ref) => ref && (ref.checked = ref.value === value)),
            clearValue: (refs) => refs.forEach((ref) => ref && (ref.checked = false))
        });
    }, [fieldName, registerField]);

    return (
        <RadioButtonFormGroupStyled flexGrid={flexGrid}>
            {label && <Label>{label}</Label>}
            <RadioButtonGroup {...props} ref={ref} name={fieldName} error={error} defaultValue={defaultValue}>
                {children}
            </RadioButtonGroup>
        </RadioButtonFormGroupStyled>
    );
};
