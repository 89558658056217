import styled from 'styled-components';
import { Box } from '~/components/elements/box';
import { DialogContent } from '~/components/elements/dialog';

export const PlanCalculatorContentStyled = styled(DialogContent)`
    margin-top: 10vh;
    width: 100%;
    max-width: 96.2rem;

    background-color: transparent;
    border-radius: 0;
    box-shadow: none;

    & > div:first-child {
        position: relative;
    }

    & > div {
        padding: 4rem;
        background-color: #ffffff;
        border-radius: 1rem;
        box-shadow: 0 0.3rem 1rem rgba(42, 51, 74, 0.15);
    }

    header {
        h2,
        p {
            text-align: start;
        }

        h2 {
            text-transform: uppercase;
        }

        p {
            margin-top: 1.6rem;
        }
    }

    footer {
        margin-top: 4.2rem;
        justify-content: space-between;
        button {
            width: 14rem;

            &:last-child {
                width: fit-content;
            }
        }
    }
`;

export const SubCustomPlanContentStyled = styled.div`
    margin-top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    & > div {
        display: flex;
        gap: 3.2rem;
    }
`;

export const PlanCalculatorStyled = styled.div`
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    max-width: 64rem;
`;

export const PlanCalculatorResultStyled = styled.div`
    margin-top: 7rem;
    margin-bottom: 8.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
        max-width: 60rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > strong {
            color: ${({ theme }) => theme.colors.deepCerise};
            margin-bottom: 0.8rem;
        }

        & > button {
            width: 100%;
            justify-content: flex-end;
            & > span {
                font-size: ${({ theme }) => theme.fontSizes.md};
            }
        }
    }
`;

export const PlanCalculatorResultBoxStyled = styled(Box)`
    margin: 2.4rem 0;

    & > strong {
        display: flex;
        gap: 1.6rem;
        justify-content: space-between;
    }
`;

export const CustomPlanContentStyled = styled.div`
    text-align: center;
    margin-top: 6.4rem;

    strong {
        color: ${({ theme }) => theme.colors.deepCerise};
        margin-bottom: 0.8rem;
    }

    button {
        margin: 1.6rem auto 0;
        width: auto;
    }
`;
