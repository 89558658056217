import { css, FlattenSimpleInterpolation } from 'styled-components';
import { desktopOnly, tabletOnly, mobileOnly, mobileAndTabletOnly, tabletAndDesktopOnly } from './mediaQueries';

const MAXIMUM_COLUMN_IN_THE_GRID = 12;
const A_HUNDRED_PER_CENT = 100;

export type FlexGrid = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type FlexGridSize = 'mobile' | 'mobileAndTablet' | 'tablet' | 'tabletAndDesktop' | 'desktop';

export type FlexGridValues = {
    /** Number of columns when device resolution is maximum 640px. */
    mobile?: FlexGrid;
    /** Number of columns when device resolution is maximum 1007px */
    mobileAndTablet?: FlexGrid;
    /** Number of columns when device resolution is between 641px and 1007px */
    tablet?: FlexGrid;
    /** Number of columns when device resolution is at least 640px */
    tabletAndDesktop?: FlexGrid;
    /** Number of columns when the device's resolution is at least 1008px */
    desktop?: FlexGrid;
};

export const cssMaxWidthWithPaddingX = css`
    max-width: ${({ theme }) =>
        theme.container.rem.maxWidth + (theme.container.rem.padding + theme.container.rem.padding)}rem;
    padding-left: ${({ theme }) => theme.container.paddingX};
    padding-right: ${({ theme }) => theme.container.paddingX};
`;

/**
 * Formats the field width according to device size using media query
 * @param grid :
 * @param gridSize
 * @returns
 */
export const cssFlexGrid = (grid: FlexGrid, gridSize: FlexGridSize = 'desktop') => {
    if (!grid) {
        return;
    }

    const cssGrid = css`
        flex: ${(grid / MAXIMUM_COLUMN_IN_THE_GRID) * A_HUNDRED_PER_CENT - grid}%;
    `;
    if (gridSize) {
        switch (gridSize) {
            case 'mobile':
                return mobileOnly(cssGrid);
            case 'mobileAndTablet':
                return mobileAndTabletOnly(cssGrid);
            case 'tablet':
                return tabletOnly(cssGrid);
            case 'tabletAndDesktop':
                return tabletAndDesktopOnly(cssGrid);
            case 'desktop':
                return desktopOnly(cssGrid);
            default:
                return;
        }
    }
    return cssGrid;
};

/**
 * Formats the field width according to device size using media query
 * @param deviceGridValues The pattern value
 * @returns media queries of CSS
 */
export const cssFlexGridByDevice = (deviceGridValues: FlexGridValues) => {
    if (!deviceGridValues) {
        return;
    }
    const cssGrid: Array<FlattenSimpleInterpolation> = [];
    Object.entries(deviceGridValues)?.forEach(([key, value]) => {
        if (value) {
            cssGrid.push(cssFlexGrid(value, key as FlexGridSize));
        }
    });

    return cssGrid;
};

export const dataValidateStyle = css`
    ${({ theme }) => css`
        &[data-validate] {
            position: relative;
            font-weight: ${theme.fontWeights.regular};

            & + button {
                margin-top: 2rem;
            }

            &.input-error,
            & > textarea,
            & > button {
                border-color: ${theme.default.dangerColor};
            }

            &::before {
                content: attr(data-validate);
                position: absolute;
                max-width: fit-content;
                bottom: -2rem;
                left: 2rem;
                pointer-events: none;
                color: ${theme.default.dangerColor};
                font-size: 1.4rem;
                font-family: ${theme.fonts.primary};
                line-height: ${theme.lineHeights.base};
                text-align: left;
                visibility: visible;
                opacity: 1;
                transition: opacity 0.4s;
                border-image-slice: 1;
                max-height: 1.6rem;
            }

            &::after {
                content: '!';
                position: absolute;
                font-family: ${theme.fonts.primary};
                font-size: 1rem;
                text-align: center;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: solid 1px;
                color: ${theme.default.dangerColor};
                background-color: ${theme.default.input.backgroundColor};
                bottom: -1.71rem;
                left: 0;
            }
        }
    `}
`;
