export enum Environment {
    Production = 'production',
    Sandbox = 'sandbox'
}

export enum ButtonColorOption {
    Gold = 'gold',
    Blue = 'blue',
    Silver = 'silver',
    Black = 'black'
}

export enum ButtonShapeOption {
    Pill = 'pill',
    Rect = 'rect'
}

export enum ButtonSizeOption {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    Responsive = 'responsive'
}

export enum ButtonLabelOption {
    Checkout = 'checkout',
    Credit = 'credit',
    Pay = 'pay',
    BuyNow = 'buynow',
    PayPal = 'paypal'
}

export type ButtonStyle = {
    color: ButtonColorOption;
    label: ButtonLabelOption;
    shape: ButtonShapeOption;
    size: ButtonSizeOption;
    tagline?: boolean;
    fundingicons: boolean;
};
