import { useCallback, useRef, useState, useMemo } from 'react';
import { useAnimation } from 'framer-motion';
import _first from 'lodash/first';
import { useReplace, useTranslator } from '@hooks';
import { NavigationTriggerRule, NavigationTriggerRuleId } from '@models';
import { useAlert } from '@providers';
import { Scope } from '@unform/core';
import { findItem, getFirstLeafItem, removeItem, TreeItem } from '@utils';
import { MAX_SIZE_LEVELS_IN_RULES_TREE, TRANSITION_DELAY } from '../';
import { RulesGroup } from '../rulesGroup';
import { ContainerStyled } from './styles';

type RulesTreeProps = {
    rules: Array<TreeItem<NavigationTriggerRule>>;
    onChange?: (newRules: Array<TreeItem<NavigationTriggerRule>>) => void;
};

export const RulesTree = ({ rules: initialRules }: RulesTreeProps) => {
    const { warning } = useAlert();
    const replace = useReplace();
    const {
        dialogs: {
            warnings: { abandonmentRuleLimitExceeded }
        }
    } = useTranslator();

    const ruleElementAnimationIndexRef = useRef(0);
    const [rules, setRules] = useState(initialRules);

    const animation = useAnimation();
    const rootRule = _first(rules);

    const handleAddRule = useCallback(
        (rule: TreeItem<NavigationTriggerRule>) => {
            const newRules = [...rules];

            if (!rule?.parentId) {
                return;
            }

            findItem<NavigationTriggerRule>('id', rule.parentId, rootRule, (value) => {
                value.children.push({
                    parentId: value.id,
                    id: new Date().getTime(),
                    level: rule.level
                } as TreeItem<NavigationTriggerRule>);
            });

            ruleElementAnimationIndexRef.current = 0;
            setRules([...newRules]);
        },
        [rules, rootRule]
    );

    const handleAddRuleGroup = useCallback(
        (rule: TreeItem<NavigationTriggerRule>) => {
            if (rule?.level === MAX_SIZE_LEVELS_IN_RULES_TREE) {
                warning(
                    String(abandonmentRuleLimitExceeded.title),
                    replace(String(abandonmentRuleLimitExceeded.description), MAX_SIZE_LEVELS_IN_RULES_TREE)
                );
                return;
            }

            const newRules = [...rules];

            findItem<NavigationTriggerRule>('id', rule.parentId, rootRule, (value) => {
                if (!value.children) {
                    value.children = [];
                }
                const id = new Date().getTime();
                value.children.push({
                    id,
                    parentId: value.id,
                    level: rule.level,
                    children: [
                        {
                            parentId: id,
                            id: new Date().getTime() + 1,
                            level: rule.level + 1
                        } as TreeItem<NavigationTriggerRule>
                    ]
                });
            });

            ruleElementAnimationIndexRef.current = 0;
            setRules([...newRules]);
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rules, rootRule]
    );

    const removeRule = useCallback(
        (ruleId: NavigationTriggerRuleId) => {
            const newRules = [...rules];

            removeItem('id', ruleId, newRules);

            // TODO: Balance rule tree to remove nodes with unique children
            ruleElementAnimationIndexRef.current = 0;
            setRules([...newRules]);
        },
        [rules]
    );

    const handleRemoveItem = useCallback(
        async (ruleId: NavigationTriggerRuleId, ruleElementAnimationIndex: number) => {
            await animation.start((animationIndex) => {
                if (ruleElementAnimationIndex === animationIndex) {
                    return {
                        opacity: 0,
                        x: -40,
                        transition: { delay: TRANSITION_DELAY }
                    };
                } else {
                    return {
                        opacity: 1
                    };
                }
            });

            removeRule(ruleId);
        },
        [removeRule, animation]
    );

    const firstRule = useMemo(() => getFirstLeafItem(_first(rules)), [rules]);

    return (
        <Scope path='rulesTree[0]'>
            <ContainerStyled>
                <RulesGroup
                    ruleToDisplayLabels={firstRule}
                    rules={rootRule?.children ?? []}
                    isRoot={true}
                    ruleId={rootRule?.id}
                    onRemove={handleRemoveItem}
                    onCreateRule={handleAddRule}
                    onCreateRuleGroup={handleAddRuleGroup}
                    animation={animation}
                    ruleElementAnimationIndex={ruleElementAnimationIndexRef.current}
                    ruleElementAnimationIndexRef={ruleElementAnimationIndexRef}
                />
            </ContainerStyled>
        </Scope>
    );
};
