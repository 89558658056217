import { lighten, rgba } from 'polished';
import styled, { css } from 'styled-components';
import { dataValidateStyle } from '~/styles/mixins';
import { Text } from '../text';

export const ToggleSwitchStyled = styled.div`
    ${dataValidateStyle}
`;

const LIGHTEN_SIZE = 0.4;
const LABEL_AFTER_BOX_SHADOW_ALPHA = 0.3;
const LABEL_AFTER_HOVER_BOX_SHADOW_ALPHA = 0.25;
const LABEL_AFTER_HOVER_BOX_SHADOW_ALPHA_TWO = 0.15;

export const ToggleSwitchInputStyled = styled.input.attrs(({ theme, color }) => ({
    type: 'checkbox',
    toggleColor: color ?? theme.colors.green
}))`
    ${({ theme, toggleColor }) => css`
        display: none;

        & + label::before {
            content: '';
            display: block;
            height: 1.4rem;
            width: 4.5rem;
            border: 1px solid ${lighten(LIGHTEN_SIZE, theme.colors.gray)};
            border-radius: 0.9rem;
            position: absolute;
            top: -0.1rem;
            left: -5.5rem;
            background: ${lighten(LIGHTEN_SIZE, theme.colors.gray)};
            transition: all 0.3s;
        }

        & + label::after {
            content: '';
            display: block;
            height: 2.4rem;
            width: 2.4rem;
            border: 1px solid ${theme.colors.white};
            border-radius: 50%;
            position: absolute;
            top: -0.5rem;
            left: -5.5rem;
            background: ${theme.colors.white};
            transition: all 0.3s, background 0.4s linear, border-color 0.4s linear;
            box-shadow: 0 0 0.5rem ${rgba(theme.colors.lightBlack, LABEL_AFTER_BOX_SHADOW_ALPHA)};
        }

        &:focus + label::after,
        &:hover + label::after {
            box-shadow: 0 0.2rem 1.5rem 0 ${rgba(theme.colors.lightBlack, LABEL_AFTER_HOVER_BOX_SHADOW_ALPHA)},
                0 0.3rem 0.8rem 0 ${rgba(theme.colors.lightBlack, LABEL_AFTER_HOVER_BOX_SHADOW_ALPHA_TWO)};
        }

        &:checked + label::after {
            background: ${toggleColor};
            border-color: ${toggleColor};
        }

        &:checked + label::after {
            left: -3rem;
            transition: all 0.3s cubic-bezier(0.54, 1.6, 0.5, 1);
        }

        &:checked + label::before {
            border-color: ${toggleColor};
            background: ${toggleColor};
            opacity: 0.4;
        }

        &:disabled + label {
            opacity: 0.5;
            cursor: default;

            &::before {
                border-color: ${lighten(LIGHTEN_SIZE, theme.colors.gray)};
                background: ${lighten(LIGHTEN_SIZE, theme.colors.gray)};
            }
        }

        &:disabled:hover + label::after {
            box-shadow: 0 0 0.5rem ${rgba(theme.colors.lightBlack, LABEL_AFTER_BOX_SHADOW_ALPHA)};
        }
    `}
`;

export const ToggleSwitchLabelStyled = styled(Text)`
    position: relative;
    margin-left: 5.6rem;
    color: ${(props) => props.color};
    cursor: pointer;
`;
