import jwtDecode, { JwtPayload } from 'jwt-decode';

const SECONDS_TO_MILLISECONDS_MULTIPLIER = 1000;

export const isExpired = (token: string | null) => {
    if (!token) {
        return true;
    }

    try {
        return Date.now() >= getExpiration(token);
    } catch (error) {
        /* istanbul ignore next */
        return true;
    }
};

export const getExpiration = (token: string | null) => {
    if (!token) {
        return null;
    }

    try {
        const decodedToken = jwtDecode<JwtPayload>(token);

        return decodedToken.exp * SECONDS_TO_MILLISECONDS_MULTIPLIER;
    } catch (error) {
        return null;
    }
};
