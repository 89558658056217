import { ReactNode } from 'react';
import { PhoneContainerStyled, PhoneCameraStyled, PhoneDisplayStyled, ContentDataStyled, PhoneProps } from './styles';

type PhoneFramePreviewProps = PhoneProps & {
    children?: ReactNode;
};

export const PhoneFramePreview = ({ isReduced = false, children }: PhoneFramePreviewProps) => {
    return (
        <div>
            <PhoneContainerStyled isReduced={isReduced}>
                <PhoneCameraStyled />
                <PhoneDisplayStyled isReduced={isReduced}>
                    <ContentDataStyled isReduced={isReduced}>{children}</ContentDataStyled>
                </PhoneDisplayStyled>
            </PhoneContainerStyled>
        </div>
    );
};
