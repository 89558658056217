import styled from 'styled-components';

export const SubToolConfigurationStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
`;

export const SubToolConfigurationButtonGroupStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 7.2rem;

    button {
        min-width: 16rem;
    }
`;
