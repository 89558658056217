import styled, { css } from 'styled-components';
import { Card, CardBody } from '~/components/elements/card';
import { mobileAndTabletOnly } from '~/styles';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    & > div > h1 {
        margin-bottom: 2.4rem;
    }
`;

export const BankSlipCardSyled = styled(Card)`
    transition: width 0.15s ease-out;

    ${mobileAndTabletOnly(css`
        width: 100%;
    `)}
`;

export const BankSlipCardBodyStyled = styled(CardBody)`
    transition: margin 0.15s ease-out;
    gap: 3rem;
`;
