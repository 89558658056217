import {
    ContactConfigurationStatusEnum,
    CustomTrigger,
    CustomTriggerStatus,
    MessageSequence,
    MessageSequenceStep,
    Phone,
    SendingTechnologyTypeEnum,
    SubToolConfiguration,
    SubToolConfigurationStatusEnum
} from '@models';
import { axiosClient } from './apiService';

export type UpdateCartRecoveryConfigurationContactsPayload = {
    id?: number;
    subToolConfigurationId: number;
    senderName: string;
    senderContact: string;
    contactAnswer?: string;
    status: ContactConfigurationStatusEnum;
    sendingTechnology: SendingTechnologyTypeEnum;
};

export type UpdateCartRecoveryConfigurationPayload = {
    id?: number;
    subToolId: number;
    contactConfigurations: Array<UpdateCartRecoveryConfigurationContactsPayload>;
};

export type SubmitContactListPayload = {
    name: string;
    email: string;
    cellPhone: Phone;
    infoAdd1: string;
    infoAdd2: string;
};

export type UpdateCustomTriggerPayload = {
    messageSequenceId: number;
    name: string;
};

const customTriggerBaseUrl = '/sub-tool/custom-trigger';

export const getMessageSequencesUrl = `${customTriggerBaseUrl}/message-sequences`;
export const externalSubmitContactListUrl = `/triggers/start`;
export const getAllTriggersUrl = customTriggerBaseUrl;

export const GetAllTriggersAsync = () => {
    return axiosClient.get<Record<number, string>>(getAllTriggersUrl);
};

export const GetTokenAsync = (messageSequenceId: number) => {
    return axiosClient.get<string>(`${customTriggerBaseUrl}/token`, { params: { messageSequenceId } });
};

export const RegenerateToken = (messageSequenceId: number) => {
    return axiosClient.delete<string>(`${customTriggerBaseUrl}/token`, { params: { messageSequenceId } });
};

export const UpdateCustomTriggerAsync = (data: UpdateCustomTriggerPayload) => {
    return axiosClient.put<CustomTrigger>(`${customTriggerBaseUrl}/configuration`, data);
};

export const GetMessageSequencesAsync = ({ page, pageSize }, signal: AbortSignal = null) => {
    return axiosClient.get<MessageSequence>(getMessageSequencesUrl, { signal, params: { page, pageSize } });
};

export const DeleteMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.delete<boolean>(`${customTriggerBaseUrl}/message-sequences/steps/${stepId}`);
};

export const DuplicateMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.post<MessageSequenceStep>(`${customTriggerBaseUrl}/message-sequences/steps/${stepId}/duplicate`);
};

export const ChangeStatusAsync = async (newStatus: SubToolConfigurationStatusEnum, id: number) => {
    return axiosClient.patch<boolean>(
        `${customTriggerBaseUrl}/configuration/status`,
        {
            newStatus
        },
        {
            params: {
                messageSequenceId: id
            }
        }
    );
};

export const ChangeCustomTriggerStatusAsync = async (newStatus: CustomTriggerStatus, customTriggerId: number) => {
    return axiosClient.patch<boolean>(`${customTriggerBaseUrl}/${customTriggerId}/status`, {
        newStatus
    });
};

export const GetSubToolConfigurationAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<SubToolConfiguration>(`${customTriggerBaseUrl}/configuration`, { signal });
};

export const SubmitContactListAsync = async (
    contactList: Array<SubmitContactListPayload>,
    messageSequenceId: number
) => {
    return axiosClient.post(`${customTriggerBaseUrl}/start`, contactList, { params: { messageSequenceId } });
};

/*
export const CreateOrUpdateSubToolConfigurationAsync = async (payload: UpdateCartRecoveryConfigurationPayload) => {
    return axiosClient.put<SubToolConfiguration>(`${customTriggerBaseUrl}/configuration`, payload);
};
*/
