import { useState } from 'react';
import { useRouter } from 'next/router';
import { Dropdown, OptionProps } from '@components';
import { useTranslator } from '@hooks';
import { PushActionContent } from '~/models/pushNotificationContent';
import { AndroidViewElement } from './android';
import { MacOsViewElement } from './macOs';
import { ContainerStyled } from './styles';
import { WindowsViewElement } from './windows';

type PushActionType = 'click' | 'pushOneButton' | 'pushTwoButtons';

type PushTemplatePreviewProps = {
    title?: string;
    body?: string;
    iconUrl?: string;
    imageUrl?: string;
    redirectUrl?: string;
    selectedPushAction?: PushActionType;
    pushActionList: { [key: string]: PushActionContent[] };
};

export type ViewModeProps = {
    title?: string;
    body?: string;
    iconUrl?: string;
    imageUrl?: string;
    domain?: string;
    currentDate: string;
    currentTime: string;
    selectedPushAction?: PushActionType;
    pushActionList: { [key: string]: PushActionContent[] };
};

export const PushTemplatePreview = ({
    body,
    title,
    iconUrl,
    imageUrl,
    redirectUrl,
    selectedPushAction,
    pushActionList
}: PushTemplatePreviewProps) => {
    const {
        viewOn: { label, options }
    } = useTranslator().subTools.pushTemplateEditor;
    const [viewMode, setViewMode] = useState<string | number>('android');

    const viewOptions: OptionProps[] = Object.keys(options).map((key) => ({
        value: key,
        text: options[key]
    }));

    const { locale } = useRouter();
    const today = new Date();
    const currentDate = today.toLocaleDateString(locale);
    const currentTime = today.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
    const domain = redirectUrl ?? new URL(redirectUrl).hostname;

    return (
        <ContainerStyled>
            <label>{String(label)}</label>
            <Dropdown
                inputSize='small'
                name='viewOn'
                options={viewOptions}
                defaultValue='android'
                onChange={setViewMode}
                testId='dropdown__viewOn'
            />
            <>
                {viewMode === 'android' && (
                    <AndroidViewElement
                        body={body}
                        title={title}
                        imageUrl={imageUrl}
                        domain={domain}
                        currentDate={currentDate}
                        currentTime={currentTime}
                        pushActionList={pushActionList}
                    />
                )}
                {viewMode === 'windows' && (
                    <WindowsViewElement
                        body={body}
                        title={title}
                        imageUrl={imageUrl}
                        domain={domain}
                        currentDate={currentDate}
                        currentTime={currentTime}
                        iconUrl={iconUrl}
                        selectedPushAction={selectedPushAction}
                        pushActionList={pushActionList}
                    />
                )}
                {viewMode === 'macOs' && (
                    <MacOsViewElement
                        body={body}
                        title={title}
                        imageUrl={imageUrl}
                        domain={domain}
                        currentDate={currentDate}
                        currentTime={currentTime}
                        iconUrl={iconUrl}
                        selectedPushAction={selectedPushAction}
                        pushActionList={pushActionList}
                    />
                )}
            </>
        </ContainerStyled>
    );
};
