import { ReactNode } from 'react';
import { ModalBodyStyled } from './styles';

type ModalBodyProps = {
    children: ReactNode;
};

export const ModalBody = ({ children, ...props }: ModalBodyProps) => {
    return <ModalBodyStyled {...props}>{children}</ModalBodyStyled>;
};
