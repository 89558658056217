import styled from 'styled-components';
import { Card } from '~/components/elements/card';

export const CardStyled = styled(Card)`
    padding: 3.2rem 4rem;
    display: flex;
    flex-direction: column;
    height: fit-content;

    & > header {
        padding: 0;
        margin-bottom: 3.2rem;

        h1 {
            margin-bottom: 0;
        }
    }
`;

export const PaymentContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
`;
