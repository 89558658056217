import styled from 'styled-components';
import { ExpandableGroup } from '~/components/elements/expandableGroup';
export const EmptyItemConfigurationRulerStepsStyled = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
    p {
        text-align: center;
        padding: 5rem 0;
        max-width: 66rem;
        margin: 0 auto;
    }
`;

export const ItemConfigurationRulerStyled = styled(ExpandableGroup)`
    & > div {
        padding: 2.4rem;
        padding-top: 0;
    }
`;

export const ItemConfigurationRulerStepsStyled = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
    padding-top: 3.2rem;

    h5 {
        text-transform: uppercase;
        margin-bottom: 1.6rem;
    }

    & > button {
        margin-top: 2.4rem;
    }
`;
