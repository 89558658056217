import { useRef, useState } from 'react';
import _first from 'lodash/first';
import _orderBy from 'lodash/orderBy';
import {
    Button,
    Dialog,
    DialogButtonClose,
    DialogClose,
    DialogDescription,
    DialogFooter,
    DialogHeading,
    Heading,
    Link
} from '@components';
import { useTranslatedRoute, useTranslator } from '@hooks';
import { SendingTechnology, ToolTypeEnum } from '@models';
import { Alert } from '@providers';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { COMERCIAL_WHATSAPP_CHAT } from '@utils';
import { routesName } from '~/locales/route';
import { CalculatePlanServiceAsync } from '~/services/calculatePlanApiService';
import { findSendingTechnologyWithClosestAmountOfCredit } from '~/services/sendingTechnologyService';
import { validateForm } from '~/utils/validateForm';
import { AutomationToolFormContent } from './automationToolFormContent';
import { CalculatorResultContent } from './calculatorResultContent';
import { EmailMarketingFormContent } from './emailMarketingFormContent';
import { PlanCalculatorContentStyled, SubCustomPlanContentStyled } from './styles';
import { useValidation } from './validation';

type AlertDialogProps = Alert & {
    onSelectPlan?: (creditsAmount: number) => void;
    toolType: ToolTypeEnum;
    sendingTechnologies: SendingTechnology[];
    showCustomPlan?: boolean;
    showSelectPlanButton?: boolean;
};

export type FormData = {
    storeVisitsAmount: number;
    storeOrderAmount: number;
    storeBankSlipOrderAmount: number;
    numberOfCustomers: number;
    campaignsAmount: number;
};

type CalculatorStep = 'data' | 'result';

const formContent = {
    [ToolTypeEnum.AutomationTool]: AutomationToolFormContent,
    [ToolTypeEnum.BulkSending]: EmailMarketingFormContent
};

export const PlanCalculatorModal = ({
    sendingTechnologies,
    options,
    toolType,
    onSelectPlan,
    showCustomPlan = false,
    showSelectPlanButton = true,
    ...props
}: AlertDialogProps) => {
    const [step, setStep] = useState<CalculatorStep>('data');
    const [storeData, setStoreData] = useState<FormData>();
    const [totalCredits, setTotalCredits] = useState<number | null>(null);
    const [selectedCreditsAmount, setSelectedCreditsAmount] = useState<number | null>(null);

    const {
        checkoutPage: { planCalculatorModal },
        buttons
    } = useTranslator();
    const { formSchema } = useValidation(toolType);

    const formRef = useRef<FormHandles>();
    const firstStep = step === 'data';

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        const { validate } = validateForm({ formRef });

        if (firstStep) {
            const isValid = await validate(data, formSchema);

            if (!isValid) {
                return;
            }

            const calculateResponse = await CalculatePlanServiceAsync({
                toolType,
                campaignsAmountPerMonth: data.campaignsAmount,
                numberOfCustomers: data.numberOfCustomers,
                storeBankSlipOrderAmountPerMonth: data.storeBankSlipOrderAmount,
                storeOrderAmountPerMonth: data.storeOrderAmount,
                storeVisitsAmountPerMonth: data.storeVisitsAmount
            });

            const credits = calculateResponse.data;

            const selectedSendingTechnology = findSendingTechnologyWithClosestAmountOfCredit(
                sendingTechnologies,
                credits
            );

            setStoreData(data);
            setStep('result');
            setSelectedCreditsAmount(selectedSendingTechnology.creditAmount);
            setTotalCredits(credits);

            return;
        }

        onSelectPlan && onSelectPlan(selectedCreditsAmount);
        options.onOpenChange(false);
    };

    const handleChangeAnswersClick = () => {
        setStep('data');
    };

    const FormContent = formContent[toolType];

    const increasedCredit = _first(_orderBy(sendingTechnologies, 'creditAmount', 'desc'))?.creditAmount;
    const showCustomPlanContent = totalCredits > increasedCredit;

    const translateRoute = useTranslatedRoute();

    return (
        <Dialog {...options}>
            <PlanCalculatorContentStyled {...props}>
                <div>
                    <header>
                        <DialogHeading>{String(planCalculatorModal.title)}</DialogHeading>
                        {firstStep && <DialogDescription>{String(planCalculatorModal.description)}</DialogDescription>}
                    </header>
                    <Form ref={formRef} onSubmit={handleSubmit} initialData={storeData}>
                        {firstStep ? (
                            <FormContent />
                        ) : (
                            <CalculatorResultContent
                                toolType={toolType}
                                showCustomPlanContent={showCustomPlanContent}
                                totalAvailable={selectedCreditsAmount}
                                totalCalculated={totalCredits}
                                onChangeAnswersClick={handleChangeAnswersClick}
                                storeData={storeData}
                            />
                        )}
                        <DialogFooter>
                            <DialogButtonClose type='button' color='red' isOutlined>
                                {String(buttons.cancel)}
                            </DialogButtonClose>
                            {(step === 'data' || (showSelectPlanButton && step === 'result')) && (
                                <Button type='button' color='purple' onClick={() => formRef.current.submitForm()}>
                                    {String(firstStep ? buttons.generatePlan : buttons.selectThisPlan)}
                                </Button>
                            )}
                        </DialogFooter>
                    </Form>
                    <DialogClose />
                </div>
                {showCustomPlan && (
                    <SubCustomPlanContentStyled>
                        <Heading as='h6'>Prefere personalizar seu plano outra forma?</Heading>
                        <div>
                            <Link href={routesName.checkout.plan} as={translateRoute(routesName.checkout.plan)}>
                                <Button isOutlined color='purple' onClick={() => options.onOpenChange(false)}>
                                    {String(buttons.goToCheckoutPage)}
                                </Button>
                            </Link>

                            <a href={COMERCIAL_WHATSAPP_CHAT} target='_blank' rel='noreferrer'>
                                <Button isOutlined color='purple'>
                                    {String(buttons.talkToTheSalesRepresentative)}
                                </Button>
                            </a>
                        </div>
                    </SubCustomPlanContentStyled>
                )}
            </PlanCalculatorContentStyled>
        </Dialog>
    );
};
