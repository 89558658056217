export enum CouponTypeEnum {
    DiscountPercentage = 0,
    ClosedPlan = 1,
    ExtraCreditsForFixedValue = 2,
    ExtraCreditsForAccumulatedValue = 3
}

export type Coupon = {
    id?: number;
    couponCode: string;
    bulkSendingTotal?: number;
    bulkSendingPrice?: number;
    totalBulkSendingPercentage?: number;
    automationToolTotal?: number;
    automationToolPrice?: number;
    totalAutomationToolPercentage?: number;
    onlyFirstMonth?: boolean;
    type?: CouponTypeEnum;
};

export type CouponAttemptResult = {
    coupon?: Coupon;
    couponMessage: string;
    isCouponApplied: boolean;
};
