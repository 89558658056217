import styled from 'styled-components';
import { Box } from '~/components/elements/box';
import { Card } from '~/components/elements/card';
import { DefaultPaginationContent } from '~/components/elements/pagination';

export const StatisticsPerCustomerContainer = styled(Card)`
    padding: 3.2rem 4rem;
    header {
        padding: 0;
        flex-direction: column;
        gap: 2.4rem;

        h1 {
            margin-bottom: 0;
        }

        & > div {
            width: 100%;
        }
    }

    main {
        margin-top: 2.4rem;
        padding: 0;
        gap: 1.6rem;

        nav {
            gap: 0.8rem;
        }
    }
`;

export const CustomerListStyled = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    overflow-x: auto;
    width: 100%;
`;

export const CustomerListItem = styled(Box).attrs({ as: 'li' })`
    padding: 0;
    position: relative;
    width: fit-content;
`;

export const CustomerListHeaderStyled = styled(CustomerListItem)`
    & > div {
        padding: 1.4rem 2.4rem;

        strong {
            color: ${({ theme }) => theme.colors.purple};
            text-align: center;
        }

        strong:first-child,
        strong:nth-child(2) {
            text-align: start;
        }
    }
`;

export const CustomerListItemStyled = styled(CustomerListItem)`
    cursor: pointer;
`;

export const DefaultPaginationContentStyled = styled(DefaultPaginationContent)`
    display: flex;
    gap: 1.6rem;
    flex-direction: row;
    justify-content: space-between;

    & > button {
        align-self: end;
        white-space: nowrap;
        align-self: baseline;
    }
`;

export const CustomerListItemContentStyled = styled.div`
    display: grid;
    grid-template-columns: 10rem minmax(59rem, 1fr) minmax(20rem, 1fr) minmax(9.6rem, 1fr) 10rem;
    grid-gap: 0.8rem;
    padding: 2rem 2.4rem;
    align-items: center;

    time {
        text-align: center;
    }

    & > span:not(:first-child) {
        text-align: center;
    }

    & > button {
        text-decoration: underline;
        white-space: nowrap;
    }
`;

export const EmptyCustomerListItem = styled(CustomerListItemContentStyled)`
    span {
        grid-column: 1 / span 5;
        display: block;
        text-align: center;
    }
`;
