import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { desktopOnly, mobileAndTabletOnly } from '~/styles/mediaQueries';

export const SHADOW_TRANSPARENCY = 0.16;
export const BORDER_RADIUS = '0.8rem';
export const MIN_WIDTH_FIRST_COLUMN = '16.8rem';
export const PADDLES_DISTANCE = '0.4rem';

export type TableCommonProps = {
    borderRadius?: string | number;
    minWidthFirstColumn?: string | number;
    shadowTransparency?: number;
    hideFilter?: boolean;
};

export const TableContainer = styled.div`
    position: relative;
    width: 100%;
`;

export type TableContainerScrollProps = TableCommonProps;

export const TableContainerScroll = styled.div<TableContainerScrollProps>`
    ${({
        theme,
        hideFilter = false,
        minWidthFirstColumn = MIN_WIDTH_FIRST_COLUMN,
        shadowTransparency = SHADOW_TRANSPARENCY,
        borderRadius = BORDER_RADIUS
    }) => css`
        width: 100%;
        overflow-x: auto;
        padding: 0;
        ${!hideFilter && `filter: drop-shadow(0 1rem 5rem ${rgba(theme.colors.lightBlack, shadowTransparency)});`}
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding: 0.4rem;

        &::-webkit-scrollbar {
            display: none;
        }

        table {
            border-radius: ${borderRadius};
            td {
                scroll-snap-align: end;
            }
        }

        th:first-child {
            position: sticky;
            left: 0;
            background-color: inherit;
        }

        ${mobileAndTabletOnly(css`
            table {
                thead,
                tbody,
                tfoot {
                    th:first-child {
                        width: ${minWidthFirstColumn};
                    }
                }
            }
        `)}
    `}
`;

export type TableStyledProps = Pick<TableCommonProps, 'borderRadius' | 'shadowTransparency'>;

export const TableStyled = styled.table<TableStyledProps>`
    ${({ theme, borderRadius = BORDER_RADIUS }) => css`
        display: table;
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        background-color: ${theme.colors.white};
        border-radius: ${borderRadius};
        border-collapse: unset;
        border-spacing: unset;
        position: static;

        td,
        th {
            padding: 1rem;
            &:not(:first-child) {
                border-left: none;
            }
        }
    `}

    ${desktopOnly(css`
        tr:not(.expansable-row) td:first-child,
        th:first-child {
            padding-left: 2.4rem;
        }
    `)}
`;

export type TablePaddlesContainerProps = Pick<TableCommonProps, 'minWidthFirstColumn'>;

export const TablePaddlesContainer = styled.div<TablePaddlesContainerProps>`
    ${({ theme, minWidthFirstColumn = MIN_WIDTH_FIRST_COLUMN }) => css`
        max-width: ${theme.container.maxWidth};
        position: absolute;
        top: 0;
        bottom: 0;
        left: ${minWidthFirstColumn};
        right: 0;

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 3rem;
            z-index: 5;
        }

        &::before {
            background-image: linear-gradient(to right, ${theme.colors.darkenedGrayPurple}, transparent);
            left: 0;
        }

        &::after {
            background-image: linear-gradient(to left, ${theme.colors.darkenedGrayPurple}, transparent);
            right: 0;
        }

        &.hidden {
            display: none;
        }
    `}
`;

export type TablePaddleStyledProps = {
    paddlesDistance?: string | number;
    paddleSide: 'right' | 'left';
};

const tablePaddleSideSwitch = ({
    paddleSide,
    paddlesDistance
}: Pick<TablePaddleStyledProps, 'paddleSide' | 'paddlesDistance'>) => {
    if (paddleSide === 'right') {
        return css`
            right: ${paddlesDistance};
        `;
    } else if (paddleSide === 'left') {
        return css`
            left: ${paddlesDistance};

            img,
            svg {
                transform: rotate(180deg);
            }
        `;
    }
};

export const TablePaddleStyled = styled.button<TablePaddleStyledProps>`
    ${({ theme, paddleSide, paddlesDistance = PADDLES_DISTANCE }) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border-radius: 50%;
        height: 3.2rem;
        width: 3.2rem;
        border: none;
        color: ${theme.colors.purple};

        img,
        svg {
            width: 1.92rem;
            height: 1.92rem;
        }

        &:hover {
            filter: drop-shadow(0px 0px 9.6px ${rgba(theme.colors.lightBlack, 1.0)});
        }

        &.hidden {
            display: none;
        }

        ${tablePaddleSideSwitch({ paddleSide, paddlesDistance })}
    `}
`;
