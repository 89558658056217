import { MouseEventHandler, useEffect, useState, useMemo, ReactElement, ReactNode, memo } from 'react';
import HTMLReactParser from 'html-react-parser';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import { useReplace, useTranslator } from '@hooks';
import { PaginationContentStyled, PaginationItemStyled, PaginationStyled } from './styles';

const MAX_NUMBER_NEIGHBOURS = 2;

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const generatePaginationRange = (from: number, to: number, step = 1) => {
    return range(from, to + 1, step);
};

enum PaginationNavigationActionsEnum {
    LeftPage = 0,
    RightPage = -1
}

export type PaginationData = {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
    totalRecords: number;
    children?: ReactNode;
};

type PaginationProps = {
    totalRecords: number;
    pageLimit?: number;
    pageNeighbours?: number;
    onPageChange?: (data: PaginationData) => void;
    currentPage: number;
    content?: (data: PaginationData) => ReactElement;
};

const DefaultPaginationContentComponent = ({
    currentPage,
    pageLimit,
    totalRecords,
    children,
    ...props
}: PaginationData) => {
    const description = useTranslator().common.pagination.content;
    const replace = useReplace();

    let endPageAmount = currentPage * pageLimit;
    const startingPageAmount = endPageAmount - pageLimit + 1;

    if (endPageAmount > totalRecords) {
        endPageAmount = totalRecords;
    }

    const formattedDescription = replace(String(description), { startingPageAmount, endPageAmount, totalRecords });

    return (
        <PaginationContentStyled {...props}>
            <span>{HTMLReactParser(formattedDescription)}</span>
            {children}
        </PaginationContentStyled>
    );
};

export const DefaultPaginationContent = memo(DefaultPaginationContentComponent);

export const Pagination = ({
    content,
    totalRecords: records,
    pageLimit: limit,
    pageNeighbours: neighbours,
    currentPage: currentPropsPage,
    onPageChange,
    ...props
}: PaginationProps) => {
    const [totalRecords, setTotalRecords] = useState(records);
    const [pageLimit, setPageLimit] = useState(limit);
    const [pageNeighbours, setPageNeighbours] = useState(neighbours);
    const [currentPage, setCurrentPage] = useState(currentPropsPage);
    const [totalPages, setTotalPages] = useState(() => Math.ceil((records ?? 0) / limit));

    useEffect(() => {
        setPageLimit(limit);
        setTotalRecords(records ?? 0);
        setPageNeighbours(Math.max(0, Math.min(neighbours, MAX_NUMBER_NEIGHBOURS)));
        setCurrentPage(currentPropsPage);
        setTotalPages(Math.ceil((records ?? 0) / limit));
    }, [records, limit, neighbours, currentPropsPage]);

    useEffect(() => {
        if (isNil(currentPropsPage) || currentPropsPage < 1) {
            gotoPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gotoPage = (page: number) => {
        const currentPage = Math.max(0, Math.min(page, totalPages));

        const paginationData = {
            currentPage: currentPage === 0 ? 1 : currentPage,
            totalPages: totalPages,
            pageLimit: pageLimit,
            totalRecords: totalRecords
        };

        onPageChange && onPageChange(paginationData);
    };

    const handleClick =
        (page: number): MouseEventHandler<HTMLButtonElement> =>
        (event) => {
            event.preventDefault();
            gotoPage(page);
        };

    const handleMoveLeft: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        gotoPage(currentPage - pageNeighbours * MAX_NUMBER_NEIGHBOURS - 1);
    };

    const handleMoveRight: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        gotoPage(currentPage + pageNeighbours * MAX_NUMBER_NEIGHBOURS + 1);
    };

    const pages = useMemo((): Array<number> => {
        const totalNumbers = pageNeighbours * MAX_NUMBER_NEIGHBOURS + MAX_NUMBER_NEIGHBOURS + 1;
        const totalBlocks = totalNumbers + MAX_NUMBER_NEIGHBOURS;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(MAX_NUMBER_NEIGHBOURS, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pages = generatePaginationRange(startPage, endPage);

            const hasLeftSpill = startPage > MAX_NUMBER_NEIGHBOURS;
            const hasRightSpill = totalPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) << {5 6} [7] {8 9} (10)
                case hasLeftSpill && !hasRightSpill: {
                    const extraPages = generatePaginationRange(startPage - spillOffset, startPage - 1);
                    pages = [PaginationNavigationActionsEnum.LeftPage, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} >> (10)
                case !hasLeftSpill && hasRightSpill: {
                    const extraPages = generatePaginationRange(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, PaginationNavigationActionsEnum.RightPage];
                    break;
                }

                // handle: (1) << {4 5} [6] {7 8} >> (10)
                case hasLeftSpill && hasRightSpill:
                default: {
                    pages = [
                        PaginationNavigationActionsEnum.LeftPage,
                        ...pages,
                        PaginationNavigationActionsEnum.RightPage
                    ];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return generatePaginationRange(1, totalPages);
    }, [currentPage, pageNeighbours, totalPages]);

    if (records <= pageLimit) {
        return <></>;
    }

    const Content = content;

    return (
        <PaginationStyled {...props}>
            <Content
                totalPages={totalPages}
                totalRecords={totalRecords}
                pageLimit={pageLimit}
                currentPage={currentPage}
            />
            <ul>
                {pages.map((page) => {
                    if (page === PaginationNavigationActionsEnum.LeftPage)
                        return (
                            <PaginationItemStyled key={page}>
                                <button onClick={handleMoveLeft}>
                                    <span>&laquo;</span>
                                </button>
                            </PaginationItemStyled>
                        );

                    if (page === PaginationNavigationActionsEnum.RightPage)
                        return (
                            <PaginationItemStyled key={page}>
                                <button onClick={handleMoveRight}>
                                    <span>&raquo;</span>
                                </button>
                            </PaginationItemStyled>
                        );

                    return (
                        <PaginationItemStyled key={page} isActive={currentPage === page}>
                            <button onClick={handleClick(page)}>{page}</button>
                        </PaginationItemStyled>
                    );
                })}
            </ul>
        </PaginationStyled>
    );
};

Pagination.defaultProps = {
    pageLimit: 20,
    pageNeighbours: 2,
    totalRecords: 0,
    currentPage: 1,
    content: (props) => <DefaultPaginationContent {...props} />
} as PaginationProps;
