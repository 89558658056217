import { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useTranslator } from '@hooks';
import { BalanceConsumption, DetailedBalanceConsumption, ToolTypeEnum } from '@models';
import { MakeAutomationToolSummary, MakeBulkSendingToolSummary } from '~/services/subscriptionService';
import { AutomationSummary, Summary } from '.';

export const useMakeAutomationSummary = () => {
    const { colors } = useTheme();
    const {
        common: { noDescription }
    } = useTranslator();

    return useCallback(
        (balanceConsumptions: Array<BalanceConsumption> = [], automationToolFallback: Array<Summary> = []) => {
            const createSummary = (
                totalCredits: number,
                totalCreditsUsed: number,
                detailedBalanceConsumption: Array<DetailedBalanceConsumption> = [],
                toolType: ToolTypeEnum = ToolTypeEnum.AutomationTool
            ) => {
                if (toolType === ToolTypeEnum.AutomationTool) {
                    return MakeAutomationToolSummary(
                        totalCredits,
                        totalCreditsUsed,
                        detailedBalanceConsumption,
                        automationToolFallback
                    );
                }

                return MakeBulkSendingToolSummary(
                    totalCredits,
                    totalCreditsUsed,
                    colors.eminencePurple,
                    String(noDescription),
                    detailedBalanceConsumption
                );
            };

            const automations = balanceConsumptions.map((item) => {
                return {
                    toolType: item.toolType,
                    haveUnlimitedBalance: item.haveUnlimitedBalance,
                    totalCredits: item.totalCredits,
                    totalLooseCredits: item.totalLooseCredits,
                    totalUsedCredits: item.totalUsedCredits,
                    totalUsedLooseCredits: item.totalUsedLooseCredits,
                    summary: createSummary(item.totalCredits, item.totalUsedCredits, item.details ?? [], item.toolType)
                } as AutomationSummary;
            });

            return {
                automationTool: automations.find((item) => item.toolType === ToolTypeEnum.AutomationTool),
                bulkSendingTool: automations.find((item) => item.toolType === ToolTypeEnum.BulkSending)
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
};
