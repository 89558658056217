import { SubToolIdEnum, SendingTechnologyTypeEnum } from '@models';
import ScheduleIcon from '~/assets/svg/icons/icon__clock.svg';
import CursorClickIcon from '~/assets/svg/icons/icon__cursor--click.svg';
import EyeIcon from '~/assets/svg/icons/icon__eye.svg';
import MoneyIcon from '~/assets/svg/icons/icon__money.svg';
import SentIcon from '~/assets/svg/icons/icon__sent.svg';
import EmailIcon from '~/assets/svg/icons/sendingTechnologies/icon__email.svg';
import PushIcon from '~/assets/svg/icons/sendingTechnologies/icon__push.svg';
import SmsIcon from '~/assets/svg/icons/sendingTechnologies/icon__sms.svg';
import WhatsappIcon from '~/assets/svg/icons/sendingTechnologies/icon__whatsapp.svg';
import AbandonedCartRecovery from '~/assets/svg/icons/subTool/icon__abandoned-cart-recovery.svg';
import BankslipReminder from '~/assets/svg/icons/subTool/icon__bankslip-reminder.svg';
import CustomTriggers from '~/assets/svg/icons/subTool/icon__custom-triggers.svg';
import EmailMarketing from '~/assets/svg/icons/subTool/icon__email-marketing.svg';
import Repurchase from '~/assets/svg/icons/subTool/icon__repurchase.svg';
import NavigationTrigger from '~/assets/svg/icons/subTool/icon__retargeting.svg';
import AbandonedCartRecoveryV2 from '~/assets/svg/icons/subTool/v2/icon__abandoned-cart-recovery.svg';
import BankslipReminderV2 from '~/assets/svg/icons/subTool/v2/icon__bankslip-reminder.svg';
import CustomTriggersV2 from '~/assets/svg/icons/subTool/v2/icon__custom-triggers.svg';
import EmailMarketingV2 from '~/assets/svg/icons/subTool/v2/icon__email-marketing.svg';
import RepurchaseV2 from '~/assets/svg/icons/subTool/v2/icon__repurchase.svg';
import NavigationTriggerV2 from '~/assets/svg/icons/subTool/v2/icon__retargeting.svg';

const checkoutSubToolIcons = {
    [SubToolIdEnum.CartRecovery]: <AbandonedCartRecovery />,
    [SubToolIdEnum.BankSlipReminder]: <BankslipReminder />,
    [SubToolIdEnum.Repurchase]: <Repurchase />,
    [SubToolIdEnum.CustomTrigger]: <CustomTriggers />,
    [SubToolIdEnum.NavigationTrigger]: <NavigationTrigger />,
    [SubToolIdEnum.Emails]: <EmailMarketing />
};

const subToolIcons = {
    [SubToolIdEnum.CartRecovery]: <AbandonedCartRecoveryV2 />,
    [SubToolIdEnum.BankSlipReminder]: <BankslipReminderV2 />,
    [SubToolIdEnum.Repurchase]: <RepurchaseV2 />,
    [SubToolIdEnum.CustomTrigger]: <CustomTriggersV2 />,
    [SubToolIdEnum.NavigationTrigger]: <NavigationTriggerV2 />,
    [SubToolIdEnum.Emails]: <EmailMarketingV2 />
};

export const GetIconSubTool = (id: SubToolIdEnum, version: 'checkout' | 'subTools' = 'checkout') => {
    if (version === 'subTools') {
        return subToolIcons[id] ?? <></>;
    }

    return checkoutSubToolIcons[id] ?? <></>;
};

export const getSendingTechnologyIcons = () => {
    return [
        {
            type: SendingTechnologyTypeEnum.SMS,
            icon: <SmsIcon />
        },
        {
            type: SendingTechnologyTypeEnum.WhatsAppManual,
            icon: <WhatsappIcon />
        },
        {
            type: SendingTechnologyTypeEnum.Push,
            icon: <PushIcon />
        },
        {
            type: SendingTechnologyTypeEnum.Email,
            icon: <EmailIcon />
        },
        {
            type: SendingTechnologyTypeEnum.WhatsApp,
            icon: <WhatsappIcon />
        }
    ];
};

export const getSendingTechnologyIconsByType = (type: SendingTechnologyTypeEnum) => {
    return getSendingTechnologyIcons().find((icon) => icon.type === type)?.icon;
};

export const getAutomationStatisticsSummaryIcons = () => {
    return [
        {
            type: 'schedule',
            icon: <ScheduleIcon />
        },
        {
            type: 'sent',
            icon: <SentIcon />
        },
        {
            type: 'opening',
            icon: <EyeIcon />
        },
        {
            type: 'click',
            icon: <CursorClickIcon />
        },
        {
            type: 'sale',
            icon: <MoneyIcon />
        }
    ] as const;
};
