export enum CardType {
    None = 0,
    Credit = 1,
    PayPal = 2
}

export type Card = {
    int?: number;
    type: CardType;
    number?: string;
    cardholderName?: string;
    cvv?: string;
    expirationMonth?: number;
    expirationYear?: number;
    hash?: string;
    brand?: string;
    accessTokenPayPal?: string;
    customerIdPaypal?: string;
    billingAgreementIdPaypal?: string;
    isCurrent?: boolean;
};
