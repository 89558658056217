import { ReactNode } from 'react';
import Head from 'next/head';
import { Header } from '@components';
import { LayoutContentStyled, LayoutStyled } from './styles';

type ErrorLayoutProps = {
    children: ReactNode;
    title: string;
};

export const ErrorLayout = ({ children, title }: ErrorLayoutProps) => {
    return (
        <LayoutStyled>
            <Head>
                <title>{title}</title>
            </Head>

            <Header showNavbar={false} isStickyHeader={false} />
            <LayoutContentStyled>{children}</LayoutContentStyled>
        </LayoutStyled>
    );
};
