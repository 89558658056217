import { ReactNode } from 'react';
import { Button, Link, Video } from '@components';
import { DialogOptions, useTranslator } from '@hooks';
import { Dialog, DialogDescription, DialogFooter, DialogHeading, DialogTrigger } from '~/components/elements/dialog';
import { SubToolColors } from '~/styles/theme';
import { TutorialSubToolIconStyled, WatchTutorialModalContent, WatchTutorialModalVideo } from './styles';

type WatchTutorialModalProps = {
    title: string;
    description: string;
    subToolColor: SubToolColors;
    subToolIcon: ReactNode;
    videoUrl?: string;
    children: ReactNode;
    dialogOptions?: DialogOptions;
    createTemplateLink: {
        href: string;
        as?: string;
    };
};

export const WatchTutorialModal = ({
    title,
    description,
    subToolIcon,
    subToolColor,
    videoUrl,
    children,
    dialogOptions,
    createTemplateLink,
    ...props
}: WatchTutorialModalProps) => {
    const { buttons } = useTranslator();

    return (
        <Dialog {...dialogOptions}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <WatchTutorialModalContent {...props}>
                <TutorialSubToolIconStyled subToolColor={subToolColor}>{subToolIcon}</TutorialSubToolIconStyled>
                <DialogHeading>{title}</DialogHeading>
                <DialogDescription>{description}</DialogDescription>
                <WatchTutorialModalVideo>
                    <Video videoUrl={videoUrl} />
                </WatchTutorialModalVideo>
                <DialogFooter>
                    <Button
                        buttonSize='small'
                        color='purple'
                        isOutlined
                        onClick={() => dialogOptions.onOpenChange(false)}>
                        {buttons.toGoBack}
                    </Button>
                    <Link href={createTemplateLink.href} as={createTemplateLink.as}>
                        <Button buttonSize='small' color='purple'>
                            {String(buttons.createTemplate)}
                        </Button>
                    </Link>
                </DialogFooter>
            </WatchTutorialModalContent>
        </Dialog>
    );
};
