import { useMemo, useState } from 'react';
import { useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';

export type DialogOptions = {
    initialOpen?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    enableOutsideClosed?: boolean;
    isBlurredBackground?: boolean;
    returnFocusOnClose?: boolean;
};

export function useDialog({
    initialOpen = false,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    enableOutsideClosed = true,
    isBlurredBackground = true,
    returnFocusOnClose = true
}: DialogOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const [labelId, setLabelId] = useState<string | undefined>();
    const [descriptionId, setDescriptionId] = useState<string | undefined>();

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        open,
        onOpenChange: setOpen
    });

    const context = data.context;

    const click = useClick(context, {
        enabled: controlledOpen == null,
        event: 'click'
    });

    const dismiss = useDismiss(context, {
        outsidePressEvent: 'mousedown',
        enabled: enableOutsideClosed
    });

    const role = useRole(context);

    const interactions = useInteractions([click, dismiss, role]);

    return useMemo(
        () => ({
            open,
            setOpen,
            ...interactions,
            ...data,
            labelId,
            descriptionId,
            isBlurredBackground,
            returnFocusOnClose,
            setLabelId,
            setDescriptionId
        }),
        [open, setOpen, interactions, data, labelId, descriptionId, isBlurredBackground, returnFocusOnClose]
    );
}
