import styled, { css } from 'styled-components';
import { Text } from '../text';

type PageNumberStyledProps = {
    isActive?: boolean;
};

export const PaginationStyled = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        list-style: none;
    }
`;

export const PaginationContentStyled = styled(Text).attrs({ size: 'xs' })`
    color: ${({ theme }) => theme.colors.lightGray};
`;

export const PaginationItemStyled = styled(Text).attrs({
    as: 'li',
    size: 'xs',
    fontWeight: 'bold'
})<PageNumberStyledProps>`
    color: ${({ theme }) => theme.colors.lightBlack};
    background-color: ${({ theme }) => theme.colors.white};

    & > button {
        width: 4rem;
        height: 4rem;
        border-radius: 0.4rem;
        text-align: center;
        background-color: transparent;
        border: 0.1rem solid ${({ theme }) => theme.colors.lightPurple};
        cursor: pointer;
        transition: all 0.2s ease-out;

        & > span {
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1;
        }
    }

    ${({ isActive }) =>
        isActive &&
        css`
            button {
                background-color: ${({ theme }) => theme.colors.purple};
                border-color: ${({ theme }) => theme.colors.purple};
                color: ${({ theme }) => theme.colors.white};
            }
        `}
    ${({ isActive }) =>
        !isActive &&
        css`
            button {
                background: transparent;
                color: ${({ theme }) => theme.colors.purple};

                &:hover {
                    background-color: ${({ theme }) => theme.colors.lightPurple};
                }
            }
        `}
`;
