import { registerServiceWorker, getReadyServiceWorker } from '@utils';

export const requestPermission = async () => {
    if (!('serviceWorker' in navigator)) {
        return;
    }

    if (!('PushManager' in window)) {
        return;
    }

    if ('Notification' in window) {
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
            throw new Error('Permission not granted to send notifications');
        }
    }
};

export const getCurrentPushSubscription = async (): Promise<PushSubscription | null> => {
    const serviceWorker = await getReadyServiceWorker();

    return serviceWorker.pushManager.getSubscription();
};

export const registerPushNotifications = async (): Promise<PushSubscription | null> => {
    await requestPermission();

    await registerServiceWorker();

    const existingSubscription = await getCurrentPushSubscription();

    if (existingSubscription !== null) {
        return existingSubscription;
    }

    const serviceWorker = await getReadyServiceWorker();

    const subscription = await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.NEXT_PUBLIC_PUSH_NOTIFICATION_PUBLIC_KEY
    });

    if (!subscription) {
        return null;
    }

    return subscription;
};
