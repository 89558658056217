import { minutesToMilliseconds } from 'date-fns';
import { Dialog, DialogClose, DialogDescription, DialogHeading, Shimmer, Text, Video } from '@components';
import { useFetch, useSubToolPage, useTranslator } from '@hooks';
import { SubTool, SubToolIdEnum } from '@models';
import { AlertOptions } from '@providers';
import { GetSubToolById, GetSubToolByIdURL } from '~/services/apiService';
import { SubToolColors } from '~/styles/theme';
import {
    AutomationToolModalStyled,
    AutomationToolModalVideoContainerStyled,
    AutomationToolSolutionsStyled,
    DialogContentStyled,
    ModalVideo,
    SubToolIconStyled
} from './styles';

type AutomationToolModalProps = {
    options: AlertOptions;
    subToolId: SubToolIdEnum;
};

const DEDUPING_INTERVAL_TIMEOUT_IN_MINUTES = 10;

export const AutomationToolModal = ({ subToolId, options }: AutomationToolModalProps) => {
    const { automationToolModal: translatedTexts } = useTranslator().checkoutPage.subscriptionPlan;
    const { data, isLoading } = useFetch(
        subToolId && { url: GetSubToolByIdURL, subToolId },
        async ({ subToolId: id }) => (await GetSubToolById(Number(id))).data,
        {
            keepPreviousData: true,
            dedupingInterval: minutesToMilliseconds(DEDUPING_INTERVAL_TIMEOUT_IN_MINUTES),
            fallbackData: {
                details: 'empty',
                resources: ['empty', 'empty', 'empty', 'empty']
            } as SubTool
        }
    );

    const { getSubToolPageData } = useSubToolPage();
    const { translatedSubToolName, subToolKey, icon } = getSubToolPageData(subToolId);

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(translatedSubToolName)}</DialogHeading>
                <SubToolIconStyled subToolColor={subToolKey as SubToolColors}>{icon}</SubToolIconStyled>
                <Shimmer isActive={isLoading}>
                    <DialogDescription data-shimmer>
                        <span>{data?.details}</span>
                    </DialogDescription>
                    <AutomationToolModalStyled>
                        <AutomationToolModalVideoContainerStyled>
                            <ModalVideo data-shimmer>
                                <Video videoUrl={data?.videoUrl} />
                            </ModalVideo>
                        </AutomationToolModalVideoContainerStyled>
                        <AutomationToolSolutionsStyled>
                            <Text as='strong' size='lg'>
                                {String(translatedTexts.videoMessage)}
                            </Text>
                            <ul>
                                {JSON.parse(translatedTexts.subToolsTags[subToolKey]).map((resource) => (
                                    <li key={resource} data-shimmer>
                                        <Text as='span' size='xxs'>
                                            {resource}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </AutomationToolSolutionsStyled>
                    </AutomationToolModalStyled>
                </Shimmer>
                <DialogClose />
            </DialogContentStyled>
        </Dialog>
    );
};
