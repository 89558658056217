import { ReactNode, forwardRef } from 'react';
import { CardBodyStyled, CardBodyStyledProps } from './styles';

export type CardBodyProps = CardBodyStyledProps & {
    children?: ReactNode;
    cardChild?: boolean;
};

export const CardBody = forwardRef<HTMLMediaElement, CardBodyProps>(({ children, type, ...props }, ref) => {
    return (
        <CardBodyStyled {...props} type={type} ref={ref}>
            {children}
        </CardBodyStyled>
    );
});

CardBody.displayName = 'CardBody';
CardBody.defaultProps = {
    cardChild: true
};
