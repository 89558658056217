import _first from 'lodash/first';
import _orderBy from 'lodash/orderBy';
import { translator as translatorGlobal, TranslatorProps } from '@hooks';
import { User } from '@models';
import { formatDate, getRandomArbitrary } from '@utils';
import { LocalVariables, VariableParentKey } from '../utils';
import { generateTemplatePreview } from './generateTemplatePreview';
import { getPreviewProducts, getRandomPreviewProducts, Product } from './getProducts';

type NavigatedProductVariableType = 'lasted' | 'highestValue';

type ProductBlockProps = {
    translator: TranslatorProps;
    parentKey: VariableParentKey;
    productHtml?: string;
    recommendationHTML?: string;
    reviewHtml?: string;
    variableParams?: Array<string>;
    reserveProductHtml?: string;
    displayReserveProduct?: boolean;
};

type GenerateNavigatedProductBlockProps = ProductBlockProps & {
    variableKey: keyof LocalVariables['navigationTrigger'];
};

type GenerateCartProductBlockProps = ProductBlockProps & {
    user?: User;
    variableKey: keyof LocalVariables['repurchase'];
};

type GenerateBankSlipProductBlockProps = ProductBlockProps & {
    user?: User;
    variableKey: keyof LocalVariables['bankSlipReminder'];
};

type GetCartProductProps = {
    translator: TranslatorProps;
    parentKey: VariableParentKey;
    user: User;
    productHtml?: string;
    reviewHtml?: string;
    products?: Array<Product>;
};

const generateCardProductReviewBlock = (translatorData: TranslatorProps, reviewUserName: string) => {
    const { reviewMessage } =
        translatorGlobal(translatorData).subTools.repurchase.customizationVariables.preview.products;

    const maxRate = 5;
    const minRate = 1;
    return {
        'cart.product.review.name': reviewUserName,
        'cart.product.review.date': formatDate(new Date(), 'short'),
        'cart.product.review.message': reviewMessage,
        'cart.product.review.rate': getRandomArbitrary(minRate, maxRate)
    };
};

const generateNavigatedProductItem = (product: Product) => {
    return {
        'navigated.product.name': product.name,
        'navigated.product.url': product.url,
        'navigated.product.url.encoded': encodeURIComponent(product.url),
        'navigated.product.picture': product.image,
        'navigated.product.quantity': product.quantity,
        'navigated.product.price': product.formactedPrice,
        'navigated.product.review': ''
    };
};

const generateCartProductItem = (product: Product) => {
    return {
        'cart.product.name': product.name,
        'cart.product.category': product.category,
        'cart.product.url': product.url,
        'cart.product.picture': product.image,
        'cart.product.quantity': product.quantity,
        'cart.product.price': product.formactedPrice,
        'cart.product.old_price': product.formactedOldPrice,
        'cart.product.addinfo': product.additionalInformation,
        'cart.product.review': ''
    };
};

const generateBankSlipProductItem = (product: Product) => {
    return {
        'bankslip.product.name': product.name,
        'bankslip.product.price': product.formactedPrice,
        'bankslip.product.url': product.url,
        'bankslip.product.quantity': product.quantity,
        'bankslip.product.picture': product.image,
        'bankslip.product.total_purchase': product.formactedTotalPrice
    };
};

const generateCartProductRecommendations = (product: Product) => {
    return {
        'cart.product.recommendation.name': product.name,
        'cart.product.recommendation.price': product.formactedOldPrice,
        'cart.product.recommendation.description': product.additionalInformation,
        'cart.product.recommendation.link': product.url,
        'cart.product.recommendation.picture': product.image,
        'cart.product.recommendation.sale_price': product.formactedSalePrice
    };
};

const getRecommendationProducts = (
    parentKey: VariableParentKey,
    recommendationHTML?: string,
    products: Array<Product> = []
) => {
    let recommendationPreview = '';

    for (const product of products) {
        const productData = generateCartProductRecommendations(product);
        recommendationPreview += generateTemplatePreview(recommendationHTML, productData, parentKey, null);
    }

    return recommendationPreview;
};

export const generateRecommendationProductBlock = ({
    translator,
    parentKey,
    recommendationHTML,
    variableParams
}: GenerateNavigatedProductBlockProps) => {
    let recommendationPreview = '';

    const [, productsAmount] = variableParams;
    const products = getRandomPreviewProducts(translator, Number(productsAmount));

    recommendationPreview = getRecommendationProducts(parentKey, recommendationHTML, products);

    return recommendationPreview;
};

const getLatestNavigatedProducts = (
    parentKey: VariableParentKey,
    productHtml?: string,
    products: Array<Product> = []
) => {
    let productsPreview = '';

    for (const product of products) {
        const productData = generateNavigatedProductItem(product);
        productsPreview += generateTemplatePreview(productHtml, productData, parentKey, null);
    }

    return productsPreview;
};

const getNavigatedProductsByHighestValue = (
    parentKey: VariableParentKey,
    productHtml?: string,
    products: Array<Product> = []
) => {
    let productsPreview = '';

    for (const product of _orderBy(products, ['price'], ['desc'])) {
        const productData = generateNavigatedProductItem(product);

        productsPreview += generateTemplatePreview(productHtml, productData, parentKey, null);
    }

    return productsPreview;
};

export const generateNavigatedProductBlock = ({
    translator,
    parentKey,
    productHtml,
    reserveProductHtml,
    displayReserveProduct,
    variableParams,
    variableKey
}: GenerateNavigatedProductBlockProps) => {
    let productsPreview = '';

    const [, productsAmount] = variableParams;
    const products = getRandomPreviewProducts(translator, Number(productsAmount));

    const variableType: NavigatedProductVariableType =
        variableKey === 'navigated.products.latest.x' ? 'lasted' : 'highestValue';

    if (variableType === 'lasted') {
        productsPreview = displayReserveProduct
            ? reserveProductHtml
            : getLatestNavigatedProducts(parentKey, productHtml, products);
    } else {
        productsPreview = getNavigatedProductsByHighestValue(parentKey, productHtml, products);
    }

    return productsPreview;
};

const getCartProducts = ({
    translator,
    parentKey,
    user,
    productHtml,
    reviewHtml,
    products = []
}: GetCartProductProps) => {
    let productsPreview = '';

    const maxProducts = 4;
    for (const product of products.slice(0, maxProducts)) {
        const productData = generateCartProductItem(product);

        if (product.hasReview) {
            const reviewData = generateCardProductReviewBlock(translator, user.name);
            productData['cart.product.review'] = generateTemplatePreview(reviewHtml, reviewData, parentKey, null);
        }

        productsPreview += generateTemplatePreview(productHtml, productData, parentKey, null);
    }

    return productsPreview;
};

const getBankSlipProducts = ({
    translator,
    parentKey,
    user,
    productHtml,
    reviewHtml,
    products = []
}: GetCartProductProps) => {
    let productsPreview = '';

    for (const product of products) {
        const productData = generateBankSlipProductItem(product);

        if (product.hasReview) {
            const reviewData = generateCardProductReviewBlock(translator, user.name);
            productData['bankslip.product.review'] = generateTemplatePreview(reviewHtml, reviewData, parentKey, null);
        }

        productsPreview += generateTemplatePreview(productHtml, productData, parentKey, null);
    }

    return productsPreview;
};

const getTopSellingCartProducts = ({
    translator,
    parentKey,
    user,
    productHtml,
    reviewHtml,
    products = []
}: GetCartProductProps) => {
    let productsPreview = '';

    for (const product of _orderBy(products, ['quantity'], ['desc'])) {
        const productData = generateCartProductItem(product);

        if (product.hasReview) {
            const reviewData = generateCardProductReviewBlock(translator, user.name);
            productData['cart.product.review'] = generateTemplatePreview(reviewHtml, reviewData, parentKey, null);
        }

        productsPreview += generateTemplatePreview(productHtml, productData, parentKey, null);
    }

    return productsPreview;
};

const getCartProductsByVariableKey = {
    'products.top_selling.general.x': getTopSellingCartProducts,
    'products.top_selling.category.x': getTopSellingCartProducts,
    'cart.products': getCartProducts
};

export const generateCartProductBlock = ({
    translator,
    user,
    parentKey,
    productHtml,
    reviewHtml,
    variableParams,
    variableKey = 'cart.products'
}: GenerateCartProductBlockProps) => {
    let products = getPreviewProducts(translator);
    let productsData = '';

    if (variableParams) {
        const [, productsAmount] = variableParams;

        products = getRandomPreviewProducts(
            translator,
            Number(productsAmount),
            variableKey === 'products.top_selling.category.x' ? _first(products)?.category : null
        );
    }

    productsData = getCartProductsByVariableKey[variableKey]({
        translator,
        user,
        parentKey,
        productHtml,
        reviewHtml,
        products
    });

    return productsData;
};

export const generateBankSlipProductBlock = ({
    translator,
    user,
    parentKey,
    productHtml,
    reviewHtml
}: GenerateBankSlipProductBlockProps) => {
    const products = getPreviewProducts(translator);
    let productsData = '';

    const maxProducts = 3;
    productsData = getBankSlipProducts({
        translator,
        user,
        parentKey,
        productHtml,
        reviewHtml,
        products: products.slice(0, maxProducts)
    });

    return productsData;
};

export const generateSubjectBlock = (storeName: string, productName: string, consumerName: string) => {
    return {
        'store.name': storeName,
        'product.name': productName,
        'consumer.name': consumerName,
        'consumer.first_name': _first(consumerName.split(' ')),
        'bankslip.name': consumerName
    };
};

export const generateStoreBlock = (user: User) => {
    return {
        'store.name': user.name,
        'store.url': user.storeUrl,
        'store.logo': user.avatarUrl,
        'store.telephone': user.telephone,
        'store.email': user.email,
        'store.cpf_cnpj': '00.000.000/0001-00',
        'store.pix':
            '<img border="0" src="http://host-imagem-web.com.br/data/enviou/temas/padrao/pix.png" height="38" alt="Pix">'
    };
};

export const generateConsumerBlock = (translatorData: TranslatorProps) => {
    const mock = translatorGlobal(translatorData).variablesMock;

    return {
        'consumer.name': mock['consumer.name'],
        'consumer.email': mock['consumer.email'],
        'consumer.telephone': mock['consumer.telephone'],
        'consumer.first_name': _first(String(mock['consumer.name']).split(' '))
    };
};

export const generateBankSlipBlock = (translatorData: TranslatorProps) => {
    const mock = translatorGlobal(translatorData).variablesMock;

    return {
        'bankslip.barcode': '0360.00291452 600000.0000001 4000312.12323123 8 6587618712312420',
        'bankslip.url': mock['store.url'],
        'bankslip.shipping_cost': mock['bankslip.shipping_cost'],
        'bankslip.total_value': mock['bankslip.total_value'],
        'bankslip.name': mock['consumer.name']
    };
};

export const generateCustomTriggerBlock = (translatorData: TranslatorProps) => {
    const mock = translatorGlobal(translatorData).variablesMock;
    return {
        'cart.url': mock['store.url'],
        'cart.addinfo': mock['cart.addinfo'],
        infoadd1: mock.infoadd1,
        infoadd2: mock.infoadd2
    };
};

export const generateTextBlock = (translatorData: TranslatorProps, user: User) => {
    const mock = translatorGlobal(translatorData).variablesMock;

    return {
        'consumer.name': mock['consumer.name'],
        'consumer.first_name': _first(String(mock['consumer.name']).split(' ')),
        'consumer.telephone': mock['consumer.telephone'],
        'consumer.email': mock['consumer.email'],
        'store.name': user?.name ?? mock['store.name'],
        'store.url': user?.storeUrl ?? mock['store.url'],
        'store.telephone': user?.telephone ?? mock['store.telephone'],
        'store.email': user?.email ?? mock['store.email'],
        'product.name': mock['product.name'],
        'product.url': mock['product.url'],
        'cart.product.name': mock['cart.product.name'],
        'cart.product.price': mock['cart.product.price'],
        'cart.product.url': mock['cart.product.url'],
        'cart.product.quantity': mock['cart.product.quantity'],
        'navigated.product.name': mock['cart.product.name'],
        'navigated.product.price': mock['cart.product.price'],
        'navigated.product.url': mock['cart.product.url'],
        'navigated.product.quantity': mock['cart.product.quantity'],
        'cart.name': user?.name ?? mock['store.name'],
        'cart.product.short': mock['product.name'],
        'bankslip.name': user?.name ?? mock['store.name'],
        'bankslip.url': mock['store.url'],
        'bankslip.total_value': mock['bankslip.total_value'],
        infoadd1: mock.infoadd1,
        infoadd2: mock.infoadd2
    };
};
