import styled, { css } from 'styled-components';
import { Button } from '~/components/elements/button';

export const TabsHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 2.1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.default.color};
    margin-bottom: 1rem;
`;

export const TabsStyled = styled.section`
    width: 100%;
    border-radius: 1rem;
    padding: 3rem;
    border: 1px solid ${({ theme }) => theme.colors.purple};

    ${({ theme }) => css`
        background-color: ${theme.colors.white};
    `}
`;

export const TabLabelStyled = styled(Button)`
    ${({ theme }) => css`
        width: 100%;
        background-color: transparent;
        color: ${theme.default.label.disabled};
        border: none;
        border-radius: 0;
        box-shadow: none;
        white-space: nowrap;
        position: relative;

        &[aria-selected='true'] {
            color: ${theme.colors.purple};
        }

        &:hover {
            background-color: transparent;
            &[aria-selected='false'] {
                color: ${theme.colors.purple};
            }
        }
    `}
`;

export const TabsGroupLabelsStyled = styled.div`
    display: block;
    position: relative;
    margin-bottom: 1.6rem;
`;

export const TabListStyled = styled.ul`
    display: flex;
    border-bottom: 2px solid ${({ theme }) => theme.colors.darkenedGrayPurple};
    overflow-x: auto;
    overflow-y: hidden;
`;

export const TabsContentStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 0.4rem;
    overflow: hidden;
`;
