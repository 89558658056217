import { Card, CardHeader } from '@components';
import { useTranslator } from '@hooks';
import { BillingResponsibleForm } from '../form';
import { CardBodyStyled } from '../styles';

export const CreateBillingResponsible = () => {
    const { title } = useTranslator().checkoutPage.subscriptionData.billingResponsible;

    return (
        <Card type='group'>
            <CardHeader title={String(title)} />
            <CardBodyStyled type='group'>
                <BillingResponsibleForm enableUnform />
            </CardBodyStyled>
        </Card>
    );
};
