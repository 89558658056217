import { useCallback, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { SubToolConfiguration } from '@models';

type MyAutomationsContextData = {
    subTools?: Array<SubToolConfiguration>;
    filter: (subToolIds: Array<number | string>) => void;
};

const MyAutomationsContext = createContext({} as MyAutomationsContextData);

export const MyAutomationsProvider = ({ children, subToolConfigurations }) => {
    const [subTools, setSubTools] = useState(subToolConfigurations);

    const filter = useCallback(
        (subToolIds: Array<number | string>) => {
            const filterValues = subToolIds.filter((item) => item !== null);

            if (filterValues.length === 0) {
                setSubTools(subToolConfigurations);
                return;
            }

            const newSubTools = subToolConfigurations.filter((configuration) =>
                filterValues.includes(configuration.subTool.id)
            );

            setSubTools(newSubTools);
        },
        [subToolConfigurations]
    );

    return <MyAutomationsContext.Provider value={{ subTools, filter }}>{children}</MyAutomationsContext.Provider>;
};

export const useMyAutomationsProvider = <Selected,>(selector: (value: MyAutomationsContextData) => Selected) =>
    useContextSelector(MyAutomationsContext, selector);
