import { useEffect, useRef, useCallback } from 'react';
import { Middleware, SWRHook } from 'swr';

export const requestCancellation: Middleware = (useSWRNext: SWRHook) => {
    return (key, fetcher, config) => {
        const abortController = useRef(new AbortController()).current;
        const signal = abortController.signal;

        useEffect(() => {
            return () => {
                abortController.abort();
            };

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const abortRequest = useCallback(() => {
            abortController.abort();
        }, [abortController]);

        const extendedFetcher = (...args: unknown[]) => {
            return fetcher(...[...args, signal]);
        };

        const swr = useSWRNext(key, extendedFetcher, config);

        return Object.assign({}, swr, {
            abortRequest
        });
    };
};
