import upperFirst from 'lodash/upperFirst';
import { init } from '@sentry/nextjs';
import { isDevelopment, onBeforeSendEvent } from '@utils';

// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/
const SENTRY_DSN: string = process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
    debug: isDevelopment(),
    environment: upperFirst(process.env.NODE_ENV),
    beforeSend: (event, hint) => onBeforeSendEvent(event, hint, false)
});
