import { useEffect, useMemo } from 'react';
import _lowerFirst from 'lodash/lowerFirst';
import _orderBy from 'lodash/orderBy';
import { useRouter } from 'next/router';
import { AutomationData, Automation } from '@containers';
import { useTranslator } from '@hooks';
import { SubToolConfiguration, SubToolIdEnum, subToolOrder } from '@models';
import { useMyAutomationsProvider } from '@providers';
import { scrollTo } from '@utils';
import { NoticeProps } from '~/components/layouts';
import { GetSubToolIdByName } from '~/services/subToolApiService';
import { ContainerStyled, MyAutomationsTemplateStyled } from './styles';

export type MyAutomationsTemplateProps = {
    subToolConfigurations?: Array<SubToolConfiguration>;
    notice?: NoticeProps;
};

const scrollToSubToolSection = (subTool: string, locale: string) => {
    const subToolId = GetSubToolIdByName(subTool as string, locale);

    if (subToolId) {
        const subToolKey = _lowerFirst(SubToolIdEnum[subToolId]);
        const subToolSection = document.getElementById(subToolKey);

        scrollTo(subToolSection, { isScrollIntoView: false, offsetTop: -120 });
    }
};

export const MyAutomationsTemplate = () => {
    const subTools = useMyAutomationsProvider((selector) => {
        return selector.subTools;
    });

    const router = useRouter();
    const {
        enums,
        pages: {
            myAutomations: { sections: translatedSections }
        }
    } = useTranslator();

    const automations = useMemo(() => {
        return [
            {
                type: 'myAutomations',
                title: String(translatedSections.myAutomations.title),
                subtitle: String(translatedSections.myAutomations.description),
                subTools: _orderBy(
                    subTools
                        .filter((config) => config?.subTool?.id !== SubToolIdEnum.CustomTrigger)
                        .map((config) => {
                            return {
                                ...config,
                                subTool: {
                                    ...config?.subTool,
                                    name: String(enums.subToolIdEnum[SubToolIdEnum[config.subTool.id]]),
                                    order: subToolOrder[config.subTool.id]
                                }
                            };
                        }),
                    'subTool.order'
                )
            },
            {
                type: 'myCustomTriggers',
                title: String(translatedSections.myCustomTriggers.title),
                subtitle: String(translatedSections.myCustomTriggers.description),
                subTools: subTools.filter(
                    (subToolConfiguration) => subToolConfiguration.subTool.id === SubToolIdEnum.CustomTrigger
                )
            }
        ] as Array<AutomationData>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subTools]);

    useEffect(() => {
        scrollToSubToolSection((router.query as { subTool: string })?.subTool, router.locale);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MyAutomationsTemplateStyled>
            <ContainerStyled>
                {automations.map((automation) => (
                    <Automation key={automation.type} automation={automation} />
                ))}
            </ContainerStyled>
        </MyAutomationsTemplateStyled>
    );
};
