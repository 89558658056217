import { Button, ExpandableGroup, Text } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { ExpandableGroupProvider, useExpandableGroup } from '@providers';
import { SendingTechnologyContainer } from './styles';

export type CurrentSendingTechnologyValue = {
    id: number;
    value: number;
    name: string;
};

type CurrentSendingTechnologiesProps = {
    sendingTechnologies: Array<CurrentSendingTechnologyValue>;
};

const Title = () => {
    const { common, buttons } = useTranslator();
    const { expanded, triggerId, contentId, toogleExpanded } = useExpandableGroup();
    return (
        <Text as='strong' size='lg'>
            {String(common.sendingTechnologyDescription)}
            <Button
                buttonSize='small'
                styledAs='link'
                color='deepCerise'
                id={triggerId}
                aria-expanded={expanded}
                aria-controls={contentId}
                onClick={toogleExpanded}>
                {String(expanded ? buttons.viewLess : buttons.viewMore)}
            </Button>
        </Text>
    );
};

const Content = ({ sendingTechnologies = [] }: CurrentSendingTechnologiesProps) => {
    const { expanded, triggerId, contentId } = useExpandableGroup();
    const { formatCurrency } = useNumberFunctions();

    return (
        <ExpandableGroup isExpanded={expanded} role='region' aria-labelledby={triggerId} id={contentId}>
            <ul>
                {sendingTechnologies.map((item) => {
                    return (
                        <li key={item.id}>
                            <Text as='span' size='sm'>
                                {item.name}
                            </Text>
                            <Text as='span' size='sm'>
                                {formatCurrency(item.value)}
                            </Text>
                        </li>
                    );
                })}
            </ul>
        </ExpandableGroup>
    );
};

export const CurrentSendingTechnologies = ({ sendingTechnologies = [] }: CurrentSendingTechnologiesProps) => {
    if (sendingTechnologies?.length === 0) {
        return <></>;
    }

    return (
        <SendingTechnologyContainer>
            <ExpandableGroupProvider initial={false}>
                <Title />
                <Content sendingTechnologies={sendingTechnologies} />
            </ExpandableGroupProvider>
        </SendingTechnologyContainer>
    );
};
