import styled, { DefaultTheme } from 'styled-components';
import { Text } from '~/components/elements/text';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { CustomerListItemContentStyled } from '../styles';

const statusColors = (theme: DefaultTheme) => ({
    sent: theme.colors.lightBlack,
    inProgress: theme.colors.lightBlack,
    generatedSales: theme.colors.darkMossGreen,
    rejected: theme.colors.sangria,
    error: theme.colors.redOrange
});

export type StatusColor = keyof ReturnType<typeof statusColors>;
type StatusStyledProps = { status: StatusColor };

export const CustomerItemStyled = styled(CustomerListItemContentStyled)``;

export const CustomerDetails = styled.div`
    display: flex;
    flex-direction: column;

    // iury.sousa - Descomentar Quando a Modal de Modelos de Whatsapp manual for feita
    /* strong {
        max-width: 25.8rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    } */

    .cell-number {
        text-decoration: underline;
    }
`;

export const CustomerContentStyled = styled.div`
    text-align: start;
    display: flex;
`;

export const StatusStyled = styled(Text)<StatusStyledProps>`
    color: ${({ theme, status }) => statusColors(theme)[status]};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TooltipContentStyled = styled(TooltipContent)`
    max-width: 40rem;
    z-index: 9999;

    color: ${({ theme }) => theme.colors.redOrange};
`;
