import _first from 'lodash/first';
import { replace } from '@utils';
import { getAllVariables, TemplateVariable } from './localVariables';
import { delimiter, makeVariable, normalizeVariable, replaceRegexVariableParameterPattern } from './variableFunctions';

const { start, end } = delimiter;

export const getAllTemplateVariables = (template: string) => {
    const pattern = `\\${start}(.*?)\\${end}`;
    const regex = new RegExp(pattern, 'gi');

    return template?.match(regex) ?? [];
};

export const getFirstTemplateVariableByRegex = (template: string, pattern: string) => {
    const regex = new RegExp(pattern, 'i');
    return template?.match(regex) ?? [];
};

export const replaceTemplateVariableName = (template: string, variableName: string, newVariableName: string) => {
    const variable = makeVariable(normalizeVariable(newVariableName)).toUpperCase();

    return replace(template, { [variableName]: variable }, delimiter);
};

export const replaceTemplateVariableRegex = (
    template: string,
    variable: TemplateVariable,
    translatedRegexVariable: string
) => {
    const regexVariables = _first(
        getAllVariables({ parentKey: variable.parentKey, isRegex: true, key: [variable.originalKey] })
    );

    for (const pattern of regexVariables?.variables) {
        const variableRegex = new RegExp(pattern, 'i');
        const variableValues = makeVariable(variable.key)?.match(variableRegex) ?? [];

        let templateVariableName = replaceRegexVariableParameterPattern(variableValues, translatedRegexVariable);

        if (templateVariableName) {
            templateVariableName = normalizeVariable(templateVariableName);

            template = replaceTemplateVariableName(template, variable.key, templateVariableName);
            break;
        }
    }

    return template;
};
