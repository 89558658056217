import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { generatePathParts } from '@utils';

export type Breadcrumb = {
    href: string;
    as?: string;
    query?: ParsedUrlQuery;
    text: string;
};

const _transformText = (path: string) => path;
const _transformUrl = (href: string, as?: string) => ({ href, as });

export type UseBreadcrumbsProps = {
    transformText?: (path: string, href: string, locale?: string) => string;
    transformUrl?: (href: string, as?: string, locale?: string) => { href: string; as?: string };
};

export const useBreadcrumbs = ({
    transformText = _transformText,
    transformUrl = _transformUrl
}: UseBreadcrumbsProps = {}) => {
    const router = useRouter();

    const breadcrumbs = useMemo(() => {
        const asPathNestedRoutes = generatePathParts(router.asPath);
        const pathnameNestedRoutes = generatePathParts(router.pathname);

        if (pathnameNestedRoutes) {
            const pathnames = pathnameNestedRoutes;
            const asPaths = asPathNestedRoutes;

            asPaths.shift();
            pathnames.shift();

            const crumbs: Array<Breadcrumb> = asPaths.map((subpath, index) => {
                const href = '/' + pathnames.slice(0, index + 1).join('/');
                const as = '/' + asPaths.slice(0, index + 1).join('/');

                return {
                    ...transformUrl(href, as, router.locale),
                    query: router.query,
                    text: transformText(subpath || '/', href, router.locale)
                };
            });

            return crumbs;
        }

        return [];
    }, [router.pathname, router.asPath, router.query, router.locale, transformText, transformUrl]);

    return { breadcrumbs };
};
