import { useReplace, useTranslator } from '@hooks';
import { SubscriptionTool, ToolTypeEnum } from '@models';
import { GetTranslatedEnumName } from '@utils';
import { AutomationPlanDetails } from '../automationPlanDetails';
import { CurrentAndNewAutomationPlanDetails } from '../currentAndNewAutomationPlanDetails';
import { Container } from './styles';

export type ToolsDetailsData = {
    subscriptionTool: SubscriptionTool;
    showUpdatePlanButton?: boolean;
};

type ToolsDetailsProps = {
    selectedTools?: Array<{ type: ToolTypeEnum; currentPlan?: SubscriptionTool; newPlan: SubscriptionTool }>;

    hasSubscription: boolean;
    isSuspendedPlan?: boolean;
};

export const ToolsDetails = ({ hasSubscription, isSuspendedPlan = false, selectedTools = [] }: ToolsDetailsProps) => {
    const replace = useReplace();
    const {
        enums: { toolType },
        checkoutPage: {
            invoiceDetails: { subgroups }
        }
    } = useTranslator();

    return (
        <Container>
            {selectedTools.map((selectedTool) => {
                const toolName = GetTranslatedEnumName(ToolTypeEnum[selectedTool.type], toolType);

                if (hasSubscription && !isSuspendedPlan) {
                    return (
                        <CurrentAndNewAutomationPlanDetails
                            key={selectedTool.type}
                            title={replace(subgroups.discount.descriptionPlan, toolName)}
                            newPlan={selectedTool.newPlan}
                            currentPlan={selectedTool.currentPlan}
                        />
                    );
                }

                return (
                    <AutomationPlanDetails
                        key={selectedTool.type}
                        title={replace(subgroups.discount.descriptionPlan, toolName)}
                        showUpdatePlanButton={isSuspendedPlan}
                        subscriptionTool={selectedTool.newPlan}
                    />
                );
            })}
        </Container>
    );
};
