import { ChangeEvent, useCallback, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { PaymentOptions } from '@containers';
import { PaymentMethodTypeEnum } from '@models';
import { useCheckout } from '@providers';
import { PaymentMethodForm } from '../form';
import { ExpandableGroupStyled } from './styles';

type EditPaymentMethodProps = {
    onChangePaymentMethod?: (paymentMethod: PaymentMethodTypeEnum) => void;
    onBeforeChange?: (paymentMethod: PaymentMethodTypeEnum) => Promise<boolean> | boolean;
};

const paymentFormFields = [
    'creditCard.number',
    'creditCard.cardholderName',
    'creditCard.expirationMonth',
    'creditCard.expirationYear',
    'creditCard.cvv',
    'bankSlip.firstDueDate',
    'payPal.nonce'
];

export const EditPaymentMethod = ({ onChangePaymentMethod, onBeforeChange }: EditPaymentMethodProps) => {
    const { subscriptionFormData, formRef, isSuspendedPlan } = useCheckout();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
        subscriptionFormData?.subscriptionPayment?.paymentMethod ?? PaymentMethodTypeEnum.CreditCard
    );

    const handlePaymentMethodChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            const paymentMethod = Number(event.target.value);

            const beforeChangeResult = !onBeforeChange ? true : await onBeforeChange(paymentMethod);

            if (selectedPaymentMethod === paymentMethod || !beforeChangeResult) {
                formRef.current.setFieldValue('paymentMethod', String(selectedPaymentMethod));
                return;
            }

            paymentFormFields.forEach((field) => {
                formRef.current.setFieldError(field, null);
            });

            setSelectedPaymentMethod(paymentMethod);

            if (onChangePaymentMethod) {
                onChangePaymentMethod(paymentMethod);
            }
        },
        [selectedPaymentMethod, formRef, onChangePaymentMethod, onBeforeChange]
    );

    return (
        <>
            <PaymentOptions
                onChange={handlePaymentMethodChange}
                selectedValue={selectedPaymentMethod}
                showDiscounts={!isSuspendedPlan}
            />
            <ExpandableGroupStyled isExpanded initial={{ height: 'fit-content' }}>
                <AnimatePresence initial={false}>
                    <PaymentMethodForm paymentMethod={selectedPaymentMethod} />
                </AnimatePresence>
            </ExpandableGroupStyled>
        </>
    );
};
