import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

type ExpandableGroupProps<T = unknown> = {
    children: ReactNode;
    initial?: boolean;
    enabled?: boolean;
    data?: T;
};

type ExpandableGroupData<T = unknown> = {
    toogleExpanded: () => void;
    expanded: boolean;

    triggerId: string;
    contentId: string;
    data?: T;
};

const ExpandableGroupContext = createContext({} as ExpandableGroupData);

export const ExpandableGroupProvider = <T = unknown,>({
    children,
    initial = false,
    data = null,
    enabled = true
}: ExpandableGroupProps<T>) => {
    const [expanded, setExpanded] = useState(initial);

    const triggerId = useRef(_uniqueId('expandable-trigger-')).current;
    const contentId = useRef(_uniqueId('expandable-content-')).current;

    const toogleExpanded = () => {
        enabled && setExpanded((state) => !state);
    };

    return (
        <ExpandableGroupContext.Provider value={{ expanded, triggerId, contentId, data, toogleExpanded }}>
            {children}
        </ExpandableGroupContext.Provider>
    );
};

export const useExpandableGroup = () => {
    return useContext(ExpandableGroupContext);
};
