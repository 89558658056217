import { useCallback } from 'react';
import { useFetch } from '@hooks';
import { DownloadFile } from '~/services/apiService';

const MILLISECONDS_TO_SHOW_PRINTER = 400;
type usePrinterData = {
    openPrinter: () => void;
};
export const usePrinter = (url: string, idPrinter = 'printWindow'): usePrinterData => {
    const { data: fileData } = useFetch(
        url && { idPrinter, fileUrl: url },
        async ({ fileUrl }) => (await DownloadFile(fileUrl)).data,
        {
            onError: (error) => console.log('Error downloading file: ', { error })
        }
    );

    const removeIframe = useCallback(() => {
        const documentPrintWindow = document.getElementById(idPrinter);
        if (documentPrintWindow) {
            document.body.removeChild(documentPrintWindow);
        }
    }, [idPrinter]);

    const startPrinter = useCallback(
        (iframe: HTMLIFrameElement) => {
            setTimeout(() => {
                if (!iframe.contentWindow) {
                    console.error('Printing failed because the `contentWindow` of the print iframe did not load.');
                    return;
                }

                if (!iframe.contentWindow.print) {
                    console.error('Printing failed because the `contentWindow` of the print iframe did not load.');
                    return;
                }

                iframe.contentWindow.focus();
                iframe.contentWindow.print();

                removeIframe();
            }, MILLISECONDS_TO_SHOW_PRINTER);
        },
        [removeIframe]
    );

    const openPrinter = useCallback(() => {
        const printWindow = document.createElement('iframe');
        printWindow.id = idPrinter;

        printWindow.onload = () => {
            printWindow.onload = null;

            if (fileData) {
                printWindow.src = window.URL.createObjectURL(fileData);
                startPrinter(printWindow);
            }
        };
        removeIframe();
        document.body.appendChild(printWindow);
    }, [removeIframe, startPrinter, fileData, idPrinter]);

    return { openPrinter };
};
