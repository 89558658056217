import styled from 'styled-components';
import { cssFlexGridByDevice, dataValidateStyle, FlexGridValues } from '~/styles';

export type SelectFormGroupStyledProps = {
    flexGrid?: FlexGridValues;
    inputSize?: 'small' | 'medium' | 'large';
};

export const SelectFormGroupStyled = styled.div<SelectFormGroupStyledProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    ${({ flexGrid }) => flexGrid && cssFlexGridByDevice(flexGrid)}

    label {
        margin-bottom: 0.8rem;
    }

    ${dataValidateStyle}
`;
