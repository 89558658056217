import {
    ChangeEventHandler,
    FocusEventHandler,
    InputHTMLAttributes,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import { Label } from '@components';
import { useField, FormContext } from '@unform/core';
import { ApplyMask, InputMaskEnum, RemoveMask } from '@utils';
import { InputFormGroupStyled, InputFormGroupStyledProps, WrapperInputStyled, InputIconStyled } from './styles';

export type InputType =
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'range'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
    type: InputType;
    testId?: string;
};

type InputFormGroupProps = InputFormGroupStyledProps &
    InputProps & {
        name: string;
        label?: string | ReactNode;
        icon?: ReactNode;
        mask?: keyof typeof InputMaskEnum;
        enableUnform?: boolean;
        containerClassName?: string;
        error?: string;
    };

export const InputFormGroup = ({
    name,
    label,
    type,
    value,
    placeholder,
    onBlur,
    flexGrid,
    icon,
    mask,
    onChange,
    testId,
    children,
    containerClassName,
    autoComplete = 'off',
    list,
    'aria-autocomplete': ariaAutoComplete,
    error: inputError,
    inputSize = 'small',
    isInvisible = false,
    enableUnform = true,
    ...props
}: InputFormGroupProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocus, setIsFocus] = useState(props.autoFocus);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const { unregisterField } = useContext(FormContext);

    useEffect(() => {
        if (!enableUnform) {
            return;
        }

        registerField<string>({
            name: fieldName,
            ref: inputRef.current,
            getValue(ref) {
                if (mask) {
                    return RemoveMask(ref.value);
                }
                return ref.value;
            },
            setValue(ref, value) {
                if (mask) {
                    ref.value = ApplyMask(value, InputMaskEnum[mask]);
                } else {
                    ref.value = value;
                }
            },
            clearValue(ref) {
                ref.value = '';
            }
        });

        return () => {
            unregisterField(fieldName);
        };
    }, [fieldName, registerField, unregisterField, mask, enableUnform]);

    useEffect(() => {
        if (mask && defaultValue) {
            inputRef.current.value = ApplyMask(defaultValue, InputMaskEnum[mask]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    useEffect(() => {
        if (mask) {
            inputRef.current.value = ApplyMask(inputRef.current.value, InputMaskEnum[mask]);
        }
    }, [mask]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (mask) {
            inputRef.current.value = ApplyMask(event.target.value, InputMaskEnum[mask]);
        }

        if (onChange) {
            onChange(event);
        }
    };

    const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
        setIsFocus(true);
        props.onFocus && props.onFocus(event);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
        setIsFocus(false);
        onBlur && onBlur(event);
    };

    const isAutoCompleteOff = autoComplete === 'off';

    return (
        <InputFormGroupStyled
            flexGrid={flexGrid}
            onClick={(event) => event.stopPropagation()}
            isInvisible={isInvisible}
            className={containerClassName}
            inputSize={inputSize}>
            {label && <Label htmlFor={fieldName}>{label}</Label>}

            <WrapperInputStyled
                className={`${error || inputError ? 'input-error' : ''}`}
                data-validate={error || inputError || undefined}
                isFocus={isFocus}
                arial-label='Input'>
                {icon && <InputIconStyled>{icon}</InputIconStyled>}

                <input
                    {...props}
                    name={name}
                    id={fieldName}
                    ref={inputRef}
                    defaultValue={type !== 'file' ? props.defaultValue ?? defaultValue : undefined}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    data-testid={testId}
                    list={isAutoCompleteOff ? 'autocompleteOff' : list}
                    aria-autocomplete={isAutoCompleteOff ? 'none' : ariaAutoComplete}
                    autoComplete={autoComplete}
                />

                {children}
            </WrapperInputStyled>
        </InputFormGroupStyled>
    );
};
