import { css, FlattenSimpleInterpolation } from 'styled-components';
import { screenWidth } from '~/utils/screenWidth';

export const mobileOnly = (content: FlattenSimpleInterpolation | string) => css`
    @media (max-width: ${screenWidth.SMALL}px) {
        ${content}
    }
`;

export const mobileAndTabletOnly = (content: FlattenSimpleInterpolation | string) => css`
    @media only screen and (max-width: ${screenWidth.LARGE - 1}px) {
        ${content}
    }
`;

export const tabletOnly = (content: FlattenSimpleInterpolation | string) => css`
    @media only screen and (min-width: ${screenWidth.SMALL + 1}px) and (max-width: ${screenWidth.LARGE - 1}px) {
        ${content}
    }
`;

export const tabletAndDesktopOnly = (content: FlattenSimpleInterpolation | string) => css`
    @media only screen and (min-width: ${screenWidth.SMALL}px) {
        ${content}
    }
`;

export const desktopOnly = (content: FlattenSimpleInterpolation | string) => css`
    @media only screen and (min-width: ${screenWidth.LARGE}px) {
        ${content}
    }
`;
