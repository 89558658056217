import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    margin-bottom: 12rem;

    .customer-alert {
        margin-bottom: 6.4rem;
    }
`;
