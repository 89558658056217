import { ReactNode } from 'react';
import { DialogOptions } from '@hooks';
import { Button, ButtonProps } from '~/components/elements/button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeading,
    DialogDescription,
    DialogFooter
} from '~/components/elements/dialog';

export type ConfirmDialogButtonProps = Omit<ButtonProps, 'children'> & {
    title: string;
};

export type ConfirmDialogProps = {
    title: string;
    description?: string;
    triggerElement?: ReactNode | string;
    buttons?: Array<ConfirmDialogButtonProps>;
    options?: DialogOptions;
};

export const ConfirmDialog = ({
    triggerElement,
    title,
    description,
    options,
    buttons = [],
    ...props
}: ConfirmDialogProps) => {
    return (
        <Dialog {...options}>
            {triggerElement && <DialogTrigger asChild>{triggerElement}</DialogTrigger>}
            <DialogContent {...props}>
                <DialogHeading color='deepCerise'>{title}</DialogHeading>
                {description && <DialogDescription>{description}</DialogDescription>}
                {buttons.length > 0 && (
                    <DialogFooter>
                        {buttons.map((button, index) => (
                            <Button key={index} buttonSize='small' {...button}>
                                {button.title}
                            </Button>
                        ))}
                    </DialogFooter>
                )}
            </DialogContent>
        </Dialog>
    );
};
