import { useCallback, useEffect, useRef, useState } from 'react';
import { ToggleButtonGroup, ToggleButtonGroupProps, Label } from '@components';
import { useField } from '@unform/core';
import { ToggleButtonFormGroupStyledProps, ToggleButtonFormGroupStyled } from './styles';

type ToggleGroupProps = ToggleButtonGroupProps &
    Partial<ToggleButtonFormGroupStyledProps> & {
        label?: string;
    };

export const ToggleButtonFormGroup = ({
    children,
    name,
    label,
    flexGrid = {},
    onChange,
    ...props
}: ToggleGroupProps) => {
    const toggleButtonGroupRef = useRef<HTMLDivElement>(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [internalValue, setInternalValue] = useState<Array<string | number>>(defaultValue || []);
    const isMounted = useRef(false);

    const handleChange = useCallback(
        (newValue: Array<string | number>) => {
            setInternalValue(newValue);

            if (onChange) {
                onChange(newValue);
            }
        },
        [onChange]
    );

    useEffect(() => {
        isMounted.current = true;
        registerField<Array<string | number>>({
            name: fieldName,
            ref: toggleButtonGroupRef.current,
            getValue: () => internalValue,
            setValue: (ref, newValue) => {
                if (isMounted.current) {
                    return setInternalValue(newValue);
                }
            },
            clearValue: () => {
                if (isMounted.current) {
                    return setInternalValue([]);
                }
            }
        });

        return () => {
            isMounted.current = false;
        };
    }, [registerField, fieldName, internalValue]);

    useEffect(() => setInternalValue(defaultValue), [defaultValue]);

    return (
        <ToggleButtonFormGroupStyled flexGrid={flexGrid}>
            {label && <Label>{label}</Label>}
            <ToggleButtonGroup
                {...props}
                ref={toggleButtonGroupRef}
                name={fieldName}
                error={error}
                value={internalValue}
                onChange={handleChange}>
                {children}
            </ToggleButtonGroup>
        </ToggleButtonFormGroupStyled>
    );
};
