import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AnimatedContainer = styled(motion.div).attrs({
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
})`
    .gateway-content {
        border: none;
        border-bottom: 1px solid transparent;
    }
`;

export const CardsAcceptedStyled = styled(AnimatedContainer)`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    & > div {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 1.6rem;
    }
`;
