import { Dispatch, MutableRefObject, ReactNode, SetStateAction, useRef, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { FormHandles } from '@unform/core';
import { Sms } from '~/models/sms';

type RepurchaseContextData = {
    sms: Sms;
    setSms: Dispatch<SetStateAction<Sms>>;
    formRef: MutableRefObject<FormHandles>;
    smsSendModalOpen: boolean;
    setSmsSendModalOpen: Dispatch<SetStateAction<boolean>>;
};

type RepurchaseProviderProps = {
    children?: ReactNode;
    sms?: Sms;
};

const contextDefaultValue = {
    sms: {}
} as RepurchaseContextData;

export const RepurchaseContext = createContext<RepurchaseContextData>(contextDefaultValue);

export function RepurchaseProvider({ children, ...props }: RepurchaseProviderProps) {
    const formRef = useRef<FormHandles>(null);
    const [smsSendModalOpen, setSmsSendModalOpen] = useState(false);
    const [sms, setSms] = useState<Sms>(props?.sms ?? contextDefaultValue?.sms);

    return (
        <RepurchaseContext.Provider value={{ sms, setSms, formRef, smsSendModalOpen, setSmsSendModalOpen }}>
            {children}
        </RepurchaseContext.Provider>
    );
}

export function useRepurchase() {
    const sms = useContextSelector(RepurchaseContext, (select) => select.sms);
    const setSms = useContextSelector(RepurchaseContext, (select) => select.setSms);
    const smsSendModalOpen = useContextSelector(RepurchaseContext, (select) => select.smsSendModalOpen);
    const setSmsSendModalOpen = useContextSelector(RepurchaseContext, (select) => select.setSmsSendModalOpen);
    const formRef = useContextSelector(RepurchaseContext, (select) => select.formRef);

    return {
        sms,
        setSms,
        formRef,
        smsSendModalOpen,
        setSmsSendModalOpen
    };
}
