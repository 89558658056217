import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { SubscriptionStatusEnum, SubscriptionVersion, User } from '@models';
import { routes } from '~/locales/route';
import { GetUserIsAuthenticated } from './authService';
import { GetCurrentSubscriptionDetailsAsync } from './subscriptionApiService';
import { CheckIfInvoiceIsDueForMoreThanThreeMonths } from './subscriptionStatusService';

export enum CheckoutStepsEnum {
    subscriptionPlan = 0,
    subscriptionData = 1,
    subscriptionPayment = 2,
    suspededPlan = 3
}

export const stepUrlParamsTypes = {
    plan: CheckoutStepsEnum.subscriptionPlan,
    plano: CheckoutStepsEnum.subscriptionPlan,
    subscriptionData: CheckoutStepsEnum.subscriptionData,
    payment: CheckoutStepsEnum.subscriptionPayment,
    pagamento: CheckoutStepsEnum.subscriptionPayment,
    pago: CheckoutStepsEnum.subscriptionPayment,
    'subscription-data': CheckoutStepsEnum.subscriptionData,
    'dados-assinatura': CheckoutStepsEnum.subscriptionData,
    'datos-suscription': CheckoutStepsEnum.subscriptionData,
    'suspended-plan': CheckoutStepsEnum.suspededPlan,
    'plano-suspendido': CheckoutStepsEnum.suspededPlan,
    'plan-suspendido': CheckoutStepsEnum.suspededPlan
};

type Data = {
    user?: User;
    token?: string;
    subscriptionStatus: SubscriptionStatusEnum;
    currentStep: number;
    isSuspendedPlan: boolean;
    isPlanCancelled: boolean;
};

type CheckWhetherToRedirectToThePlanPageProps = {
    step: number;
    isSuspendedPlan: boolean;
    hasSubscription: boolean;
    isPlanCancelled: boolean;
};

export const suspendedStatus = [
    SubscriptionStatusEnum.CanceledByNonpayment,
    SubscriptionStatusEnum.ErrorProcessingRecurrence
];

const getCheckoutStepParam = (context: GetServerSidePropsContext) => {
    const currentStep = (context.query as { step: string })?.step;
    const stepNumber = stepUrlParamsTypes[currentStep ?? 'plan'];

    return stepNumber;
};

const checkIfPlanIsSupended = (
    subscriptionStatus: SubscriptionStatusEnum,
    subscriptionVersion: SubscriptionVersion,
    hasInvoiceDueForMoreThenThreeMonths?: boolean
) => {
    return (
        suspendedStatus.includes(subscriptionStatus) &&
        subscriptionVersion >= SubscriptionVersion.v3_0 &&
        hasInvoiceDueForMoreThenThreeMonths === false
    );
};

const checkIfToRedirectToThePlanPage = ({
    step,
    isSuspendedPlan,
    hasSubscription,
    isPlanCancelled
}: CheckWhetherToRedirectToThePlanPageProps) => {
    return (
        (step !== stepUrlParamsTypes.plan && !hasSubscription) ||
        (step === stepUrlParamsTypes['suspended-plan'] && !isSuspendedPlan) ||
        ((step === stepUrlParamsTypes.payment || step === stepUrlParamsTypes['subscription-data']) && isPlanCancelled)
    );
};

export const withCheckoutServerSide = <P extends { [key: string]: unknown } = { [key: string]: unknown }>(
    handler: (
        context: GetServerSidePropsContext,
        data?: Data
    ) => GetServerSidePropsResult<P> | Promise<GetServerSidePropsResult<P>>
) => {
    return async (context: GetServerSidePropsContext) => {
        const { userIsAuthenticated, user, token, redirect } = await GetUserIsAuthenticated(context);

        if (!userIsAuthenticated) {
            return { redirect };
        }

        const currentStep = getCheckoutStepParam(context);

        if (currentStep === undefined) {
            return {
                notFound: true
            };
        }

        const subscriptionDetailsResponse = await GetCurrentSubscriptionDetailsAsync();
        const { id, version, status } = subscriptionDetailsResponse.data ?? {};

        let currentStatus = status;

        if (CheckIfInvoiceIsDueForMoreThanThreeMonths(subscriptionDetailsResponse.data)) {
            currentStatus = SubscriptionStatusEnum.Canceled;
        }

        const isPlanCancelled = currentStatus === SubscriptionStatusEnum.Canceled;
        const isSuspendedPlan = checkIfPlanIsSupended(status, version, isPlanCancelled);

        const shouldRedirectToPlanStep = checkIfToRedirectToThePlanPage({
            hasSubscription: !!id,
            isPlanCancelled,
            isSuspendedPlan,
            step: currentStep
        });

        let newStepNumber = currentStep;

        if (shouldRedirectToPlanStep) {
            newStepNumber = CheckoutStepsEnum.subscriptionPlan;

            return {
                redirect: {
                    destination: routes['/checkout?step=plan'][context.locale]
                }
            };
        }

        if (currentStep === CheckoutStepsEnum.suspededPlan) {
            newStepNumber = CheckoutStepsEnum.subscriptionPayment;
        }

        if (newStepNumber !== stepUrlParamsTypes.payment && isSuspendedPlan) {
            return {
                redirect: {
                    destination: routes['/checkout?step=suspended-plan'][context.locale],
                    permanent: false
                }
            };
        }

        return handler(context, {
            user,
            token,
            currentStep: newStepNumber,
            isSuspendedPlan,
            isPlanCancelled,
            subscriptionStatus: currentStatus
        });
    };
};
