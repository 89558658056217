import styled, { css } from 'styled-components';
import { Card, CardBody } from '~/components/elements/card';
import { mobileAndTabletOnly } from '~/styles/mediaQueries';
import { ExpandableGroup } from '../expandableGroup';
import { InputGroup } from '../inputGroup';
import { TooltipContent } from '../tooltip/content';

type CardBodyStyledProps = {
    isExpanded: boolean;
};

export const CardBodyStyled = styled(CardBody)<CardBodyStyledProps>`
    ${({ isExpanded }) =>
        !isExpanded &&
        css`
            padding-bottom: 3.2rem;
            padding-top: 0;
            transition: padding 0.2s ease;
        `}
`;

export const Content = styled(ExpandableGroup)`
    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        ${mobileAndTabletOnly(css`
            gap: 4rem;
        `)}
    }
`;

export const TitleStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    position: relative;

    & > div {
        position: relative;
        bottom: 0.3rem;
    }
`;

export const SmsEditor = styled(Card)`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border: none;
    background: transparent;

    ${mobileAndTabletOnly(css`
        gap: 1rem;
    `)}

    .textInfoGroup {
        display: flex;
        flex-direction: column;

        & > div {
            margin-top: 1.6rem;
            margin-bottom: 0.8rem;
            max-height: 18rem;
            transition: margin-bottom 0.15s ease-out;

            &[data-validate] {
                margin-bottom: 1.8rem;
            }

            &:last-child {
                margin-top: 2.4rem;
                margin-bottom: 0;
            }
        }

        div + span {
            color: ${({ theme }) => theme.colors.philippineSilver};
        }

        textarea {
            max-height: 18rem;
        }
    }

    div[data-validate] {
        &::before {
            max-width: max-content;
            width: max-content;
        }
        & + span {
            margin-top: 0;
        }
    }
`;
export const PhoneContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    border: none;
    background: transparent;
    position: relative;

    & > div {
        position: relative;
        bottom: 1.6rem;
    }
`;

export const VariableInfoContainer = styled.div`
    display: flex;
    align-items: center;

    button > div {
        height: auto;
    }
`;

export const ToolTipChildrenStyled = styled(TooltipContent)`
    max-width: 28rem;
    display: block;
`;

export const WhatsappLabelToolTipChildrenStyled = styled(TooltipContent)`
    max-width: 38rem;

    h3 {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    h4 {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    p {
        font-size: 1.4rem;
        line-height: 1.8rem;

        span {
            font-family: monospace;
        }
    }
`;

export const InputGroupStyled = styled(InputGroup)`
    margin-bottom: 0.2rem;
    margin-top: 2.4rem;
    div:first-child {
        max-width: 25.6rem;
    }

    button {
        width: auto;
    }
`;
