import { Fragment } from 'react';
import HTMLReactParser from 'html-react-parser';
import { Dialog, DialogClose, DialogHeading, Text, Heading, InfoTooltip, DialogDescription } from '@components';
import { makeVariable, useTemplate, useTranslator, VariableParentKey } from '@hooks';
import { CustomizationVariable } from '@models';
import { AlertOptions } from '@providers';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import {
    CustomizationVariableSectionsStyled,
    CustomizationVariableValuesSectionContainerStyled,
    CustomizationVariableValuesSectionStyled,
    DialogContentStyled,
    TooltipContentStyled
} from './styles';

type CustomizationVariablesModalProps = {
    options: AlertOptions;
    subToolKeyIdentifier: VariableParentKey;
    customizationVariables: Array<CustomizationVariable>;
};

export const CustomizationVariablesModal = ({
    customizationVariables,
    subToolKeyIdentifier,
    options
}: CustomizationVariablesModalProps) => {
    const {
        variables,
        variables: { section },
        subTools: {
            common: { customizationVariables: translatedCustomizationVariables },
            repurchase: {
                customizationVariables: { copyInfo }
            }
        }
    } = useTranslator();

    const { getVariable, translateVariable, getVariableValue } = useTemplate(subToolKeyIdentifier);

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(translatedCustomizationVariables)}</DialogHeading>
                <DialogDescription>
                    {String(copyInfo)} <HelpIcon />
                </DialogDescription>
                <CustomizationVariableSectionsStyled>
                    {customizationVariables?.map((customizationVariable) => (
                        <Fragment key={customizationVariable.name}>
                            <Heading as='h6'>{section[customizationVariable.name]}</Heading>

                            <CustomizationVariableValuesSectionContainerStyled>
                                {customizationVariable.variables.map((variableValue) => {
                                    const variable = getVariable(variableValue);

                                    let variableName = translateVariable(variable, variables);
                                    const variableData = getVariableValue(variables, variable);
                                    const hasDescription =
                                        variableData?.paramsDescription &&
                                        !String(variableData?.paramsDescription).startsWith('Missing translation');

                                    variableName = makeVariable(variableName);
                                    return (
                                        <Fragment key={variableValue}>
                                            <CustomizationVariableValuesSectionStyled>
                                                <Text as='span' size='sm'>
                                                    {variableData?.description}
                                                </Text>
                                                <Text
                                                    as='span'
                                                    size='xs'
                                                    fontWeight='semiBold'
                                                    className='variable-name'
                                                    onClick={() => navigator.clipboard.writeText(variableName)}>
                                                    {variableName}
                                                    {hasDescription && (
                                                        <InfoTooltip>
                                                            <TooltipContentStyled>
                                                                {HTMLReactParser(
                                                                    String(variableData?.paramsDescription)
                                                                )}
                                                            </TooltipContentStyled>
                                                        </InfoTooltip>
                                                    )}
                                                </Text>
                                            </CustomizationVariableValuesSectionStyled>
                                        </Fragment>
                                    );
                                })}
                            </CustomizationVariableValuesSectionContainerStyled>
                        </Fragment>
                    ))}
                </CustomizationVariableSectionsStyled>
                <DialogClose />
            </DialogContentStyled>
        </Dialog>
    );
};
