import { produce } from 'immer';
import _findIndex from 'lodash/findIndex';
import { Cache, mutate } from 'swr';
import { serializeSWRKey } from '@hooks';
import {
    PaginatedList,
    RepurchaseCategory,
    RepurchaseProduct,
    RepurchaseStore,
    StoreItemConfiguration,
    SubToolIdEnum
} from '@models';
import { RepurchaseItemData } from '@providers';
import { UpdateRepurchaseItemCacheKey } from './cacheKeys';

type UpdateRepurchaseItemProps = {
    cacheKey: UpdateRepurchaseItemCacheKey;
    itemIdentifierKey: string;
    data: RepurchaseItemData;
    cache: Cache;
};

type UpdateRepurchaseStepAmountProps = {
    cacheKey: UpdateRepurchaseItemCacheKey;
    itemId: string | number;
    stepAmount: number;
    cache: Cache;
};

// INFO: Use this file to add methods that updated the global cache
// In case it is necessary to update the cache of something specific, preferably to mutate the useFetch hook directly by the component

export const UpdateRepurchaseItem = async ({ cacheKey, itemIdentifierKey, data, cache }: UpdateRepurchaseItemProps) => {
    const paginatedList = cache.get(serializeSWRKey(cacheKey)).data as PaginatedList<
        RepurchaseProduct & RepurchaseCategory & RepurchaseStore
    >;

    if (!paginatedList) {
        return;
    }

    const { type, id, subToolId, manualAverageRepurchasePeriod, templateType } = data;

    const filterData = {
        [itemIdentifierKey]: type === 'general' ? Number(id) : id
    };

    const currentItemIndex = _findIndex(paginatedList.items, filterData);

    if (currentItemIndex < 0) {
        return;
    }

    const newItems = produce(paginatedList, (draft) => {
        const currentItem = draft.items[currentItemIndex];
        const configuration =
            currentItem.configurations.find((config) => config.subToolId === subToolId) ??
            ({} as StoreItemConfiguration);

        currentItem.repurchaseData.manualAverageRepurchasePeriod = manualAverageRepurchasePeriod;
        configuration.templateType = templateType;
    });

    await mutate(cacheKey, newItems, false);
};

export const UpdateRepurchaseStepAmount = async ({
    cache,
    cacheKey,
    itemId,
    stepAmount
}: UpdateRepurchaseStepAmountProps) => {
    const paginatedCache = cache.get(serializeSWRKey(cacheKey)).data as PaginatedList<
        RepurchaseProduct & RepurchaseCategory & RepurchaseStore
    >;

    if (!paginatedCache) {
        return;
    }

    const currentItemIndex = paginatedCache.items?.findIndex((item) => {
        return String(item.id) === String(itemId);
    });

    if (currentItemIndex < 0) {
        return;
    }

    const newItems = produce(paginatedCache, (draft) => {
        const configuration = draft.items[currentItemIndex].configurations.find(
            (config) => config.subToolId === SubToolIdEnum.Repurchase
        );

        configuration.stepsAmount = stepAmount;
    });

    return mutate(cacheKey, newItems, false);
};
