import styled from 'styled-components';
import { CardBody } from '~/components/elements/card/body';

export const CardBodyStyled = styled(CardBody)`
    padding-top: 2.4rem;

    div > h6 {
        margin-bottom: 1.2rem;
        padding-top: 0;
    }
`;

export const CustomOptionStyled = styled.p`
    display: initial;
    & > span {
        color: ${({ theme }) => theme.colors.pink};
    }
`;
