import styled, { css } from 'styled-components';
import { DialogContent } from '~/components/elements/dialog';
import { TooltipContent } from '~/components/elements/tooltip';
import { mobileAndTabletOnly } from '~/styles';

export const CustomizationVariableSectionsStyled = styled.div`
    width: 100%;
    min-width: 40rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 60vh;

    & > span {
        display: flex;
        gap: 0.8rem;
        align-items: center;
    }

    h6 {
        margin: 1.6rem 0;
        text-align: left;
    }
`;

export const CustomizationVariableValuesSectionContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
    gap: 0.4rem;

    .variable-name {
        position: relative;
        display: flex;
    }
`;

export const CustomizationVariableValuesSectionStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;

    padding: 0 0.4rem;

    ${mobileAndTabletOnly(css`
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-bottom: 2rem;
    `)}

    span {
        text-align: left;
        &:last-child {
            color: ${({ theme }) => theme.colors.purple};
            cursor: copy;

            &:active {
                transform: translateY(2px);
            }
        }
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightPurple};
    }
`;

export const TooltipContentStyled = styled(TooltipContent)`
    z-index: 9999999;

    strong {
        color: ${({ theme }) => theme.colors.purple};
    }
`;

export const DialogContentStyled = styled(DialogContent)`
    position: relative;
    margin-top: 10vh;
    width: 100%;
    max-width: 111rem;
    padding: 4rem;

    h2 {
        color: ${({ theme }) => theme.colors.licorice};
        text-transform: uppercase;
        text-align: start;
    }

    & > p {
        text-align: start;
        margin-top: 0.8rem;
        margin-bottom: 1.6rem;
        display: flex;
        gap: 0.8rem;
        line-height: 0;
        align-items: center;

        svg {
            height: 1.6rem;
            color: ${({ theme }) => theme.colors.purple};
        }
    }
`;
