import styled from 'styled-components';
import { Form } from '@unform/web';
import { DialogContent } from '~/components/elements/dialog/content';

export const DialogContentStyled = styled(DialogContent)`
    max-width: 72rem;
    margin-top: 10vh !important;
    padding: 3.2rem;
    width: 100%;

    h2 {
        text-align: start;
        margin-bottom: 2.4rem;
        color: ${({ theme }) => theme.colors.deepCerise};
    }

    & > div > button {
        margin-top: 3.2rem;
    }

    footer {
        justify-content: space-between;
        button {
            width: 12.6rem;
            max-height: 4rem;
        }
    }
`;

export const Content = styled(Form)``;

export const HeaderStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
`;

export const DropdownContentStyled = styled.div`
    display: flex;
    flex-direction: column;

    & > h6 {
        margin-bottom: 1.6rem;
    }

    & > div {
        max-width: 32rem;
    }
`;

export const LatestExportsStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3.2rem;
    font-size: 1.4rem;
    letter-spacing: 0.01px;
    line-height: 1.8rem;

    h4 {
        margin-bottom: 0.8rem;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        max-height: 14rem;
        overflow-y: auto;

        li {
            display: grid;
            grid-template-columns: 0.4fr 1fr;
            gap: 1.6rem;
            align-items: center;

            time {
                white-space: nowrap;
                color: ${({ theme }) => theme.colors.philippineSilver};
            }

            a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-decoration: underline;
                color: ${({ theme }) => theme.colors.deepCerise};
            }
        }
    }
`;
