import styled from 'styled-components';
import { Box } from '~/components/elements/box';
import { Card } from '~/components/elements/card';
import { Text } from '~/components/elements/text';
import { TooltipContent } from '~/components/elements/tooltip/content';

export const StatisticsPerSubToolContainer = styled(Card)`
    padding: 3.2rem 4rem;
    header {
        padding: 0;
        h1 {
            margin-bottom: 0;
        }
    }

    main {
        margin-top: 2.8rem;
        padding: 0;
    }
`;

export const SubToolListStyled = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: 30rem auto;
    flex-direction: column;
    gap: 0.8rem;
    overflow-x: auto;
    width: 100%;
`;

const SubToolListItem = styled(Box).attrs({ as: 'li' })`
    padding: 1.6rem 2.4rem;
    width: fit-content;
    grid-column: 1 / span 2;
`;

export const SubToolListItemContentStyled = styled.div`
    display: grid;
    grid-template-columns: minmax(30rem, 1fr) repeat(4, minmax(11.6rem, 0.6fr)) minmax(15rem, 1fr);
    justify-content: space-between;
    grid-gap: 0.8rem;

    & > strong {
        color: ${({ theme }) => theme.colors.purple};
        text-align: center;
    }

    & > div {
        display: flex;
        flex-direction: column;
        text-align: flex-start;
        gap: 0.4rem;
    }

    & > span {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 0.4rem;
    }
`;

export const ValueStyled = styled(Text).attrs({ as: 'strong', size: 'sm' })``;

export const PercentageValueStyled = styled(Text).attrs({ as: 'span', size: 'xxs' })`
    color: ${({ theme }) => theme.colors.darkMossGreen};
    line-height: 2.4rem;
`;

export const SubToolListHeaderStyled = styled(SubToolListItem)`
    grid-column: 2;
    width: fit-content;
    min-width: -webkit-fill-available;
    margin-bottom: 0.8rem;

    & > div {
        grid-template-columns: repeat(4, minmax(11.6rem, 0.6fr)) minmax(15rem, 1fr);

        & > strong {
            white-space: nowrap;
            display: flex;
            line-height: 0;
            align-items: center;
            justify-content: center;

            div {
                margin-left: 0.4rem;
                height: auto;
            }
        }
    }
`;

export const SubToolListItemStyled = styled(SubToolListItem)``;

export const TooltipContentStyled = styled(TooltipContent)`
    max-width: 20.8rem;
    z-index: 9999;
`;
