import { cardRegex } from './regex';

const VALUE_TWO = 2;
const BASE_NUMBER = 9;
const BASE_TEN = 10;

/**
 * Check if the value corresponds to any of the flags
 * @param cardNumber The Number of card (Accepts only numbers, dashes and spaces).
 * @returns Returns the name of the corresponding flag in PascalCase
 */
export const getCardFlagName = (cardNumber: string): string | null => {
    if (!cardNumber) {
        return null;
    }

    cardNumber = cardNumber.replace(/\D/g, '');

    for (const flag in cardRegex) {
        const regex = new RegExp(cardRegex[flag]);

        if (regex.test(cardNumber)) {
            return flag;
        }
    }

    return null;
};

/**
 * Check if the card number is valid
 * @param cardNumber The card number (Accepts only numbers, dashes and spaces).
 * @returns True if card is valid
 */
export const isAValidCardNumber = (cardNumber: string): boolean => {
    if (/[^0-9-\s]+/.test(cardNumber) || !cardNumber) {
        return false;
    }

    // Luhn Algorithm
    let numberToCheck = 0;
    let even = false;
    cardNumber = cardNumber.replace(/\D/g, '');

    for (let currentPosition = cardNumber.length - 1; currentPosition >= 0; currentPosition--) {
        let digit = parseInt(cardNumber.charAt(currentPosition), 10);

        if (even) {
            if ((digit *= VALUE_TWO) > BASE_NUMBER) {
                digit -= BASE_NUMBER;
            }
        }

        numberToCheck += digit;
        even = !even;
    }

    return numberToCheck % BASE_TEN === 0;
};
