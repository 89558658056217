import { ReactNode } from 'react';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import { InfoTooltipTriggerStyled } from './styles';
import { Tooltip } from './tooltip';

type InfoTooltipProps = {
    children: ReactNode;
};

export const InfoTooltip = ({ children }: InfoTooltipProps) => {
    return (
        <Tooltip>
            <InfoTooltipTriggerStyled>
                <HelpIcon />
            </InfoTooltipTriggerStyled>
            {children}
        </Tooltip>
    );
};
