import {
    Children,
    cloneElement,
    isValidElement,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    forwardRef,
    Ref
} from 'react';
import { useOnClickOutside, useTranslator } from '@hooks';
import { useField } from '@unform/core';
import CalendarIcon from '~/assets/svg/icons/icon__calendar.svg';
import { OptionProps, Option, OptionValueType } from './option';
import { DropdownStyled, DropdownStyleProps, IconContainer, IconPosition, NoOptions, SelectStyled } from './styles';

type Direction = 'top' | 'bottom';

type OptionElement = ReactElement<OptionProps>;
export type DropdownValue = string | number | Array<number | string | null>;

type ChangeHandler<T extends OptionValueType = string> = ((value: T) => void) | ((value: Array<T>) => void);

export type DropdownProps<T extends OptionValueType = string> = DropdownStyleProps & {
    children?: ReactNode;
    name: string;
    placeholder?: string;
    options?: Array<OptionProps<T>>;
    value?: DropdownValue;
    onChange?: ChangeHandler<T>;
    autoComplete?: string;
    testId?: string;
    disabled?: boolean;
    icon?: ReactNode;
    iconPosition?: IconPosition;
    enableUnform?: boolean;
    defaultValue?: DropdownValue;
    multiSelection?: boolean;
    error?: string;
};
const TIMEOUT_IN_MILLISECONDS_SCROLL = 200;

export const Dropdown = forwardRef<HTMLButtonElement, DropdownProps>(function Dropdown<
    T extends OptionValueType = string
>(
    {
        name,
        placeholder,
        children,
        options,
        value,
        onChange,
        testId,
        disabled,
        error: dropdownError,
        icon = null,
        iconPosition = 'start',
        inputSize = 'small',
        enableUnform = true,
        defaultValue: dropdownDefaultValue = null,
        disableCheck = false,
        isDatePicker = false,
        multiSelection = false,
        ...args
    }: DropdownProps<T>,
    ref
) {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [optionSelected, setOptionSelected] = useState<DropdownValue>(value ?? defaultValue ?? dropdownDefaultValue);
    const [open, setOpen] = useState<boolean>(false);
    const [scroll, setScroll] = useState<number>();
    const [directionOptions, setDirectionOptions] = useState<Direction>('bottom');
    const OptionsContentReference = useRef<HTMLDivElement>();

    const { dropdown } = useTranslator().common;

    useEffect(() => {
        setScroll(window.pageYOffset);
    }, []);

    useEffect(() => {
        let watchTimeScroll: NodeJS.Timeout;

        const handleScroll = () => {
            clearTimeout(watchTimeScroll);
            watchTimeScroll = setTimeout(setScroll, TIMEOUT_IN_MILLISECONDS_SCROLL, window.pageYOffset);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(watchTimeScroll);
        };
    });

    useEffect(() => {
        if (open) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { offsetHeight, offsetParent }: any = OptionsContentReference.current; // Gets the current height of the options content
            const maxOffsetHeightOptionsContent = offsetHeight + offsetParent?.offsetHeight + offsetParent?.offsetTop;
            if (scroll + window.innerHeight < maxOffsetHeightOptionsContent) setDirectionOptions('top');
            else setDirectionOptions('bottom');
        }
    }, [open, scroll]);

    const toggleDropdown = () => setOpen(!open);

    const closeDropdown = () => setOpen(false);

    const handleChange = useCallback(
        (value: string | number) => {
            if (multiSelection) {
                const isExists = (optionSelected as Array<string | number>)?.includes(value);

                let selectedItems = (optionSelected as Array<string | number>) ?? [];
                const exitsNullValue = selectedItems.includes(null);

                if (isExists === true) {
                    // eslint-disable-next-line eqeqeq
                    selectedItems = selectedItems.filter((item) => item != value);
                } else {
                    if (value === null) {
                        selectedItems = [];
                    } else if (exitsNullValue && value !== null) {
                        selectedItems = selectedItems.filter((item) => item !== null);
                    }
                    selectedItems.push(value);
                }

                if (selectedItems.length === 0) {
                    selectedItems.push(null);
                }

                setOptionSelected([...selectedItems]);
                closeDropdown();
                onChange && (onChange as (items: Array<string | number>) => void)(selectedItems);
            } else {
                setOptionSelected(value);
                closeDropdown();
                onChange && (onChange as (items: string | number) => void)(value);
            }
        },
        [onChange, multiSelection, optionSelected]
    );

    const optionIsSelected = useCallback(
        (value: string | number) => {
            if (multiSelection) {
                return (optionSelected as Array<string | number>)?.includes(value);
            }

            return value === optionSelected;
        },
        [optionSelected, multiSelection]
    );

    const currentOption: OptionProps<T> = useMemo(() => {
        return options?.find(({ value }) => optionIsSelected(value));
    }, [optionIsSelected, options]);

    const currentChildren = useMemo(() => {
        if (children) {
            return (Children.toArray(children) as Array<OptionElement>)?.find((child: OptionElement) =>
                optionIsSelected(child.props.value)
            );
        }
    }, [children, optionIsSelected]);

    useEffect(() => {
        if (!enableUnform) {
            return;
        }
        registerField<DropdownValue>({
            name: fieldName,
            ref: dropdownRef.current,
            getValue: () => optionSelected,
            setValue: (ref, value) => setOptionSelected(value),
            clearValue: () => setOptionSelected(multiSelection ? [] : null)
        });
    }, [fieldName, optionSelected, registerField, enableUnform, multiSelection]);

    useEffect(() => {
        if (!enableUnform && value !== optionSelected) {
            setOptionSelected(value);
        }
    }, [enableUnform, value, optionSelected]);

    const RenderOptions = (): JSX.Element => {
        if (children) {
            return (
                <>
                    {Children.map<ReactNode, ReactNode>(children, (child) => {
                        if (isValidElement<OptionProps>(child)) {
                            return cloneElement<OptionProps>(child, {
                                onChange: handleChange,
                                checked: disableCheck ? false : optionIsSelected(child.props.value),
                                ...child.props
                            });
                        }
                    })}
                </>
            );
        } else {
            return (
                <>
                    {options?.map(({ value, ...props }) => (
                        <Option
                            {...props}
                            key={value}
                            value={value}
                            checked={disableCheck ? false : optionIsSelected(value)}
                            onChange={handleChange}
                        />
                    )) ?? <NoOptions>{dropdown.noOption}</NoOptions>}
                </>
            );
        }
    };

    useOnClickOutside(dropdownRef, closeDropdown);

    return (
        <DropdownStyled
            ref={dropdownRef}
            defaultValue={defaultValue}
            data-validate={error || dropdownError || undefined}
            disableCheck={disableCheck}
            {...args}>
            <SelectStyled
                ref={ref}
                name={name}
                id={fieldName}
                onClick={toggleDropdown}
                background={args.background}
                data-testid={testId}
                disabled={disabled}
                hasValue={!currentOption && !currentChildren}
                inputSize={inputSize}
                isDatePicker={isDatePicker}
                className={`${error || dropdownError ? 'input-error' : ''}`}
                iconPosition={iconPosition}>
                {icon !== null && <IconContainer position={iconPosition}>{icon}</IconContainer>}
                {(currentOption?.text || currentChildren?.props.children) ?? placeholder}
                {isDatePicker && (
                    <IconContainer position='end'>
                        <CalendarIcon />
                    </IconContainer>
                )}
            </SelectStyled>

            {open && (
                <div ref={OptionsContentReference} className={`show-direction-${directionOptions}`}>
                    <RenderOptions />
                </div>
            )}
        </DropdownStyled>
    );
}) as <T extends OptionValueType = string>(props: DropdownProps<T> & { ref?: Ref<HTMLButtonElement> }) => ReactElement;
