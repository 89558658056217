import { InputFormGroup, SelectFormGroup } from '@components';
import { useTranslator } from '@hooks';
import { PlanCalculatorStyled } from './styles';

const campaignsAmountOptions = Array.from({ length: 20 }).map((_, index) => ({
    id: index + 1,
    label: String(index + 1)
}));

export const EmailMarketingFormContent = () => {
    const {
        checkoutPage: { planCalculatorModal }
    } = useTranslator();

    return (
        <PlanCalculatorStyled>
            <InputFormGroup
                label={String(planCalculatorModal.form.numberOfCustomers.label)}
                name='numberOfCustomers'
                type='text'
                mask='integer'
                autoFocus
                placeholder={String(planCalculatorModal.form.numberOfCustomers.placeholder)}
            />

            <SelectFormGroup
                label={String(planCalculatorModal.form.campaignsAmount.label)}
                name='campaignsAmount'
                placeholder={String(planCalculatorModal.form.campaignsAmount.placeholder)}
                options={campaignsAmountOptions}
            />
        </PlanCalculatorStyled>
    );
};
