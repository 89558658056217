import { Modal, ModalBody, ModalHeader, Recaptcha } from '@components';
import { useTranslator } from '@hooks';
import { SendSmsModalStyled } from './styles';

type CheckSecurityModalProps = {
    isOpen: boolean;
    onClose: (recaptcha?: string) => void;
};

export const CheckSecurityModal = ({ isOpen, onClose }: CheckSecurityModalProps) => {
    const { title, subTitle } = useTranslator().subTools.smsModelData.smsSendModal;

    return (
        <Modal isOpen={isOpen} onClose={onClose} shouldCloseOnOverlayClick={false}>
            <ModalHeader showCloseButton>
                <span>{String(title)}</span>
            </ModalHeader>

            <ModalBody>
                <SendSmsModalStyled>
                    <span>{String(subTitle)}</span>
                    <Recaptcha name='recaptcha' onChange={(recaptcha) => onClose(recaptcha)} />
                </SendSmsModalStyled>
            </ModalBody>
        </Modal>
    );
};
