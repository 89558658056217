import styled from 'styled-components';

export const SendSmsModalStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
        padding: 1.6rem 0;
    }

    div + div {
        margin-bottom: 1.6rem;
    }
`;
