import styled from 'styled-components';
import { Button } from '~/components/elements/button';
import { Card } from '~/components/elements/card';

export const CardStyled = styled(Card)`
    padding: 3.2rem 4rem;
    display: flex;
    flex-direction: column;
    min-height: 69.5rem;

    & > header {
        padding: 0;
        margin-bottom: 3.2rem;

        h1 {
            margin-bottom: 0;
        }
    }

    & > main {
        padding: 0;
        flex: 1;
    }
`;

export const AutomationContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6.4rem;

    .used-credits-amount-bar-chart__labels {
        height: 18.4rem;
        overflow: auto;
    }
`;

export const ContractBalanceButtonStyled = styled(Button)`
    margin-left: auto;
    margin-top: 3.2rem;
`;

export const LooseBalanceButtonsContainerStyled = styled.div`
    margin-top: 3.2rem;
    gap: 1.6rem;
    display: flex;
    align-content: center;
    justify-content: flex-end;
`;
