import { css } from 'styled-components';

export const TWO = 2;
export const HORIZONTAL_SPACING = 9;
export const HORIZONTAL_LINE_SPACING = HORIZONTAL_SPACING / TWO;
export const VERTICAL_SPACING = 3.2;
export const VERTICAL_LINE_POSITION = VERTICAL_SPACING + TWO;
export const SHADOW_TRANSPARENCY = 0.2;

export type DebugMode = {
    debugValue?: string;
};

export const horizontalLineStyle = css`
    &:after {
        content: '';
        position: absolute;
        border-top: 1px solid ${({ theme }) => theme.colors.enlightenedPurple};
        opacity: 0.4;
        border-radius: 4px;
        top: 1.9rem;
        left: -${HORIZONTAL_LINE_SPACING}rem;
        width: ${HORIZONTAL_LINE_SPACING}rem;
        transition: all 0.15s ease-out;
    }
`;

export const showTreeValue = (text?: string) => {
    if (!text) {
        return;
    }

    return css`
        &:before {
            content: '${text}';
            position: absolute;
            top: -50%;
            left: 0;
            color: ${({ theme }) => theme.colors.enlightenedPurple};
            opacity: 0.4;
            font-size: 1.4rem;
            width: max-content;
        }
    `;
};
