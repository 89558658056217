import { CardBody, CardHeader, InputFormGroup } from '@components';
import { useReplace, useTranslator } from '@hooks';
import { CardStyled } from './styles';

export const BankSlipReminderConfiguration = () => {
    const {
        enums,
        subTools: {
            subToolConfiguration: { form, title }
        }
    } = useTranslator();
    const replace = useReplace();

    return (
        <CardStyled type='group' subToolColor='bankSlipReminder'>
            <CardHeader
                title={replace(title, {
                    subTool: enums.subToolIdEnum.BankSlipReminder
                })}
            />
            <CardBody>
                <InputFormGroup
                    type='text'
                    label={String(form.bankSlipReminderExpirationDays.label)}
                    name='expirationDays'
                    flexGrid={{ desktop: 4 }}
                    testId='input__expiration-days-name'
                    inputSize='small'
                    mask='integer'
                />
            </CardBody>
        </CardStyled>
    );
};
