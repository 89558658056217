import styled, { css } from 'styled-components';
import { cssFlexGridByDevice, FlexGridValues } from '~/styles';
import theme, { SubToolColors } from '~/styles/theme';

export const cardPadding = '3rem 2.4rem';
export const cardPaddingDesktop = '3rem 3rem';
export const cardGroupPaddingDesktop = '3.2rem 4rem';

export type CardType = 'default' | 'group';

type BorderColorType = 'default' | 'green' | 'purple' | SubToolColors | string;

export type CardStyleProps = {
    type?: CardType;
    borderColor?: BorderColorType;
    flexGrid?: FlexGridValues;
    showFocusBorder?: boolean;
};

const getCardBorderColor = (borderColor: BorderColorType) => {
    switch (borderColor) {
        case 'default':
            return theme.colors.lightBlack;
        case 'green':
            return theme.colors.darkGreen;
        case 'purple':
            return theme.colors.purple;
        default:
            return theme.subToolColors[borderColor] || borderColor;
    }
};

const cardGroupStyle = (showFocusBorder = true, borderColor: BorderColorType = 'default') => css`
    ${({ theme }) => css`
        width: 100%;
        position: relative;
        background-color: ${theme.colors.grayPurple};
        border-radius: 0 1rem 1rem 0;
        box-shadow: ${({ theme }) => theme.default.boxShadow.default};

        ${showFocusBorder &&
        css`
            ::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 0.2rem;
                background-color: ${getCardBorderColor(borderColor)};
                transform: translateX(-50%);
                transition: ease-in-out 0.1s;
            }

            &:hover,
            &:focus {
                ::after {
                    width: 0.4rem;
                }
            }
        `}
    `}
`;

const cardDefaultStyle = (borderColor: BorderColorType = 'default') => css`
    ${({ theme }) => css`
        width: 100%;
        background-color: ${theme.colors.white};
        border-radius: 1rem;
        border: 0.1rem solid ${borderColor === 'green' ? theme.colors.darkGreen : theme.colors.lightPurple};

        &:has(.input-error) {
            border-color: ${theme.colors.redOrange};
        }
    `}
`;

export const CardStyled = styled.section<CardStyleProps>`
    ${({ type, showFocusBorder = true, borderColor = 'purple' }) =>
        type === 'group' ? cardGroupStyle(showFocusBorder, borderColor) : cardDefaultStyle(borderColor)}

    ${({ flexGrid }) => flexGrid && cssFlexGridByDevice(flexGrid)}
`;
