import styled from 'styled-components';

export const PayPalContainerStyled = styled.div.attrs({ 'data-testid': 'div__paypal-container' })<{ show?: boolean }>`
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.001;

    display: ${({ show }) => (show ? 'initial' : 'none')};
`;
