import { useCallback, useState } from 'react';
import { secondsToMilliseconds } from 'date-fns';
import _first from 'lodash/first';
import { CardBody, Pagination, PaginationData } from '@components';
import { subToolsFunctions, useFetch, useSubToolPage } from '@hooks';
import {
    MessageSequence,
    MessageSequenceStep,
    PaginatedList,
    SendingTechnologyTypeEnum,
    SubToolConfiguration,
    SubToolIdEnum
} from '@models';
import { AutomationRulerProvider, useAuth } from '@providers';
import { isGreaterThanZero } from '@utils';
import { GetMessageSequencesCacheKey } from '~/services/cacheKeys';
import { SubToolColors } from '~/styles/theme';
import { AutomationRulerHeader, RulerSteps, SubToolActionButtons } from '../';
import { AutomationRulerStyled, CardStyled } from './styles';

type AutomationRulerProps = {
    actions?: unknown[];
    subToolConfiguration: SubToolConfiguration;
};

export const defaultMessageSequenceItem: Array<MessageSequence> = [
    {
        id: 1,
        name: '',
        type: 1,
        subToolConfiguration: null,
        steps: Array.from({ length: 5 }).map(
            (_, index) =>
                ({
                    id: index,
                    templates: [
                        { sendingTechnology: SendingTechnologyTypeEnum.Email, isActive: true },
                        { sendingTechnology: SendingTechnologyTypeEnum.SMS, isActive: true },
                        { sendingTechnology: SendingTechnologyTypeEnum.Push, isActive: false },
                        { sendingTechnology: SendingTechnologyTypeEnum.WhatsApp, isActive: false }
                    ],
                    intervalMinutes: 60,
                    name: 'Your site is not yet integrated with our platform, to learn how to integrate.',
                    shippingOrder: 1,
                    creationDate: new Date(),
                    updateDate: new Date(),
                    messageSequence: null
                } as MessageSequenceStep)
        )
    }
];

const MIN_INTERVAL_IN_SECONDS_TO_REQUERY_DATA = 10;

export const AutomationRuler = ({ subToolConfiguration }: AutomationRulerProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const { user } = useAuth();
    const {
        subTool: { id: subToolId }
    } = subToolConfiguration;

    const { data, isLoading, mutate } = useFetch(
        user?.activeSubscriptionId &&
            subToolId &&
            ({
                url: subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequencesUrl,
                subToolId,
                currentPage
            } as GetMessageSequencesCacheKey),
        async ({ subToolId, currentPage }, signal) => {
            let response;
            try {
                if (
                    (subToolId as SubToolIdEnum) === SubToolIdEnum.Repurchase ||
                    (subToolId as SubToolIdEnum) === SubToolIdEnum.NavigationTrigger
                ) {
                    const pageSize = 3;
                    response = await subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequences(
                        currentPage,
                        pageSize,
                        signal
                    );
                } else {
                    response = await subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequences(signal);
                }
            } catch (error) {
                response = { data: { items: [] } };
            }

            if (response.data.items) {
                return response.data;
            }

            return {
                items: [response.data]
            } as PaginatedList<unknown>;
        },
        {
            keepPreviousData: true,
            dedupingInterval: secondsToMilliseconds(MIN_INTERVAL_IN_SECONDS_TO_REQUERY_DATA)
        }
    );

    const { items, pageSize, totalItems, pageNumber } = data ?? {};

    const handlePageChange = useCallback(
        ({ currentPage: pageNumber }: PaginationData) => {
            if (pageNumber === currentPage) {
                return;
            }

            setCurrentPage(pageNumber);
        },
        [currentPage]
    );

    let messageSequences: Array<MessageSequence> = [];

    messageSequences = items ?? (isLoading ? defaultMessageSequenceItem : []);

    const { getSubToolPageData } = useSubToolPage();
    const { subToolKey } = getSubToolPageData(subToolId as SubToolIdEnum);

    const hasItems =
        isGreaterThanZero(messageSequences.length) && isGreaterThanZero(_first(messageSequences)?.steps?.length);

    // Essa verificação só é necessário pois carrinho e lembrador de boleto não usam a estrutura de TBEmailPro.
    // Sendo assim o objeto subToolConfiguration não contém o id da ferramenta necessário para alterar o status.
    // Outro motivo é que algumas lojas possuem mais de um carrinho ativo e por isso a consulta inicial da página só tras o carrinho ativo,
    // o que não permite alterar o status já que retorna nulo se a ferramenta estiver inativa.
    const defaultSubToolConfiguration =
        isGreaterThanZero(messageSequences.length) &&
        (subToolId === SubToolIdEnum.BankSlipReminder || subToolId === SubToolIdEnum.CartRecovery)
            ? _first(messageSequences)?.subToolConfiguration ?? subToolConfiguration
            : subToolConfiguration;

    const sequenceId =
        (isGreaterThanZero(messageSequences.length) && subToolId === SubToolIdEnum.BankSlipReminder) ||
        subToolId === SubToolIdEnum.CartRecovery
            ? _first(messageSequences)?.id
            : null;

    return (
        <AutomationRulerProvider
            sequenceId={sequenceId}
            subToolConfiguration={defaultSubToolConfiguration}
            revalidateOnChange={mutate}>
            <AutomationRulerStyled>
                <CardStyled type='group' borderColor={subToolKey as SubToolColors} id={subToolKey} hasItems={hasItems}>
                    <AutomationRulerHeader>
                        <SubToolActionButtons isLoading={isLoading} />
                    </AutomationRulerHeader>
                    <CardBody>
                        {messageSequences.map((messageSequence) => {
                            const showMessageSequenceName =
                                subToolId !== SubToolIdEnum.BankSlipReminder &&
                                subToolId !== SubToolIdEnum.CartRecovery;

                            return (
                                <RulerSteps
                                    key={messageSequence.id}
                                    messageSequence={messageSequence}
                                    showMessageSequenceName={showMessageSequenceName}
                                    showCategoryName={subToolId === SubToolIdEnum.Repurchase}
                                    isLoading={isLoading && !data}
                                />
                            );
                        })}

                        <Pagination
                            currentPage={pageNumber}
                            pageLimit={pageSize}
                            totalRecords={totalItems}
                            onPageChange={handlePageChange}
                        />
                    </CardBody>
                </CardStyled>
            </AutomationRulerStyled>
        </AutomationRulerProvider>
    );
};
