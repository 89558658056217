import styled, { css } from 'styled-components';
import { Form } from '@unform/web';
import { cssFlexGridByDevice, FlexGridValues, mobileAndTabletOnly } from '~/styles';

export type FieldStyledProps = {
    flexGrid?: FlexGridValues;
};

export const CurrentSubscriptionCardBodyStyled = styled.div`
    padding: 0.4rem 0;
`;

export const ModalFooterStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
`;

export const ModalFormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;

export const RowStyled = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.lightPurple};
    }
`;

export const FieldStyled = styled.div<FieldStyledProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2.4rem 3.2rem;

    ${({ theme }) =>
        mobileAndTabletOnly(css`
            &:not(:last-child) {
                border-bottom: 1px solid ${theme.colors.lightPurple};
            }
        `)}

    ${({ flexGrid }) => flexGrid && cssFlexGridByDevice(flexGrid)}
`;
