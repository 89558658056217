import { useEffect, useState } from 'react';
import { Checkbox, InputFormGroup, InputType } from '@components';
import { useReplace, useTranslator } from '@hooks';
import { ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY, SendingTechnologyFieldEnum, SendingTechnologyTypeEnum } from '@models';
import { Scope, useField } from '@unform/core';
import { InputMaskEnum } from '@utils';
import { GetSendingTechnologyByName } from '~/services/subscriptionService';
import { ContactContent, FormGroupStyled } from '../styles';

type ContactData = {
    contactConfigurationId?: number;
    sendingTechnology: SendingTechnologyTypeEnum;
    index: number;
    isActive: boolean;
};
type ContactType = {
    contact: ContactData;
    showIsActiveCheckbox?: boolean;
};

export const Contact = ({ contact, showIsActiveCheckbox = true }: ContactType) => {
    const { index, contactConfigurationId, isActive: active, sendingTechnology } = contact;

    const [isActive, setIsActive] = useState(active);
    const { checkoutPage, subTools } = useTranslator();
    const replace = useReplace();

    const { common } = checkoutPage?.subscriptionPlan?.automationPlans;
    const { contact: contactTranslator } = subTools.contactConfiguration;

    const { registerField } = useField('sendingTechnology');
    const contactFieldName = `contactConfigurations[${index}]`;

    const sendingTechnologyDescription = GetSendingTechnologyByName(
        sendingTechnology,
        common?.sendingTechnology
    )?.toLowerCase();

    let senderContactDescription = '';
    let mask: InputMaskEnum = null;
    let inputType: InputType = 'text';

    switch (sendingTechnology) {
        case SendingTechnologyTypeEnum.Email:
            senderContactDescription = String(contactTranslator.email);
            mask = null;
            inputType = 'text';
            break;
        case SendingTechnologyTypeEnum.SMS:
            senderContactDescription = String(contactTranslator.cell);
            inputType = 'tel';
            mask = InputMaskEnum.phone;
            break;
        default:
            break;
    }

    const canShowSenderName = ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY[sendingTechnology].includes(
        SendingTechnologyFieldEnum.SenderName
    );
    const canShowSenderContact = ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY[sendingTechnology].includes(
        SendingTechnologyFieldEnum.SenderContact
    );
    const canShowContactAnswer = ALLOWED_INPUTS_BY_SENDING_TECHNOLOGY[sendingTechnology].includes(
        SendingTechnologyFieldEnum.ContactAnswer
    );

    useEffect(() => {
        registerField<number>({
            name: `${contactFieldName}.sendingTechnology`,
            getValue() {
                return sendingTechnology;
            }
        });
    }, [registerField, index, sendingTechnology, contactFieldName]);

    useEffect(() => {
        registerField<number>({
            name: `${contactFieldName}.contactConfigurationId`,
            getValue() {
                return contactConfigurationId;
            }
        });
    }, [registerField, index, contactConfigurationId, contactFieldName]);

    return (
        <ContactContent>
            <Scope path={`${contactFieldName}`}>
                {showIsActiveCheckbox && (
                    <Checkbox
                        enableUnform
                        name='isActive'
                        checked={isActive}
                        onChange={(event) => setIsActive(event.currentTarget.checked)}>
                        {replace(contactTranslator.useSendingTechnology, sendingTechnologyDescription)}
                    </Checkbox>
                )}
                <FormGroupStyled flexGrid={{ desktop: 12 }}>
                    {canShowSenderName && (
                        <InputFormGroup
                            flexGrid={{ desktop: 4 }}
                            name='senderName'
                            label={String(contactTranslator.senderName)}
                            type='text'
                            inputSize='small'
                            disabled={!isActive && showIsActiveCheckbox}
                            placeholder={String(contactTranslator.senderName)}
                        />
                    )}
                    {canShowSenderContact && (
                        <InputFormGroup
                            flexGrid={{ desktop: 4 }}
                            name='senderContact'
                            label={senderContactDescription}
                            type={inputType}
                            inputSize='small'
                            autoComplete={sendingTechnology === SendingTechnologyTypeEnum.Email ? 'email' : 'tel'}
                            mask={!!mask && mask}
                            disabled={!isActive && showIsActiveCheckbox}
                            placeholder={senderContactDescription}
                        />
                    )}
                    {canShowContactAnswer && (
                        <InputFormGroup
                            flexGrid={{ desktop: 4 }}
                            name='contactAnswer'
                            inputSize='small'
                            label={String(contactTranslator.contactAnswer)}
                            autoComplete={sendingTechnology === SendingTechnologyTypeEnum.Email ? 'email' : undefined}
                            type='text'
                            disabled={!isActive && showIsActiveCheckbox}
                            placeholder={String(contactTranslator.contactAnswerEmail)}
                        />
                    )}
                </FormGroupStyled>
            </Scope>
        </ContactContent>
    );
};
