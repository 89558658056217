import styled from 'styled-components';
import { DialogContent } from '~/components/elements/dialog/content';

export const DialogContentStyled = styled(DialogContent)`
    padding: 3.2rem;
    width: 100%;
    max-width: 50rem;

    & > h2 {
        margin-bottom: 1.2rem;
        color: ${({ theme }) => theme.colors.deepCerise};
    }

    & > p {
        font-size: ${({ theme }) => theme.fontSizes.md};
        max-width: 34rem;
        margin: 0 auto;
    }

    footer {
        margin-top: 4.8rem;
        justify-content: space-between;

        button {
            flex: 1;
        }
    }
`;
