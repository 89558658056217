import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { mobileOnly } from '~/styles';
import { HORIZONTAL_SPACING } from '../styles';

export const RulesGroupStyled = styled(motion.div)`
    .rule-item:last-child:after {
        ${mobileOnly(css`
            top: calc(50% + -2rem);
            width: ${HORIZONTAL_SPACING}rem;
        `)}
    }
`;
