import { isString } from 'lodash';
import { domainRegex } from './regex';

const YOUTUBE_VIDEO_ID_LENGHT = 11;

export const generatePathParts = (pathname: string): string[] | null => {
    if (!pathname) {
        return null;
    }

    const pathWithoutQuery = pathname.split('?')[0];
    return pathWithoutQuery.split('/');
};

export const convertToYouTubeEmbed = (url: string) => {
    const videoId = getYouTubeVideoId(url);

    if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
    } else {
        return null;
    }
};

export const getYouTubeVideoId = (url: string) => {
    if (!url) {
        return null;
    }

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#\\?]*).*/;
    const match = url.match(regExp);

    const videoIdGroup = 2;

    if (match && match[videoIdGroup].length === YOUTUBE_VIDEO_ID_LENGHT) {
        return match[videoIdGroup];
    } else {
        console.error('Invalid YouTube URL');
        return null;
    }
};

export const extractDomainFromUrl = (url: string) => {
    const domainPattern = /[\w-]+(\.[\w-]+)+/g;

    if (!isString(url)) {
        return null;
    }

    const urlWithoutProtocol = url.replace(/^(https?:)?\/\//, '');

    const domainEndIndex = urlWithoutProtocol.search(/\/|\?|#|$/);

    const domain = urlWithoutProtocol.substring(0, domainEndIndex);

    return domainPattern.test(domain) ? domain : null;
};

export const getQueryString = (url: string): string => {
    if (!isString(url)) {
        return '';
    }

    const questionMarkIndex = url.indexOf('?');
    return questionMarkIndex > -1 ? url.substring(questionMarkIndex) : '';
};

export const getPathString = (url: string) => {
    if (!isString(url)) {
        return '';
    }

    const pathMatch = url.match(/https?:\/\/[^/]*(\/[^?#]*)?/i);

    if (!pathMatch || !pathMatch[1] || pathMatch[1] === '/') {
        return '';
    }

    return pathMatch[1].replace(/\/$/, '');
};

export const normalizeUrl = (url: string, protocol = 'https') => {
    const domainPattern = /[\w-]+(\.[\w-]+)+/g;
    if (!isString(url) || url === '' || !domainPattern.test(url)) {
        return null;
    }

    const domain = extractDomainFromUrl(url);
    const path = getPathString(url);
    const query = getQueryString(url);

    if (!domain) {
        return null;
    }

    const protocolMatch = url.match(/^(https?):\/\//);
    const urlProtocol = protocolMatch ? protocolMatch[1] : protocol;

    return `${urlProtocol}://${domain}${path}${query}`;
};

export const validateDomainWithSubdomains = (domain: string) => {
    return domainRegex.test(domain);
};
