import { EmailMarketingSection, MarketingAutomationSection } from '@containers';
import FilterRoundedBorder from '~/assets/svg/filters/filter__rounded-border.svg';
import { Container } from './styles';

export const HomeTemplate = () => {
    return (
        <Container>
            <MarketingAutomationSection />
            <EmailMarketingSection />
            <FilterRoundedBorder />
        </Container>
    );
};
