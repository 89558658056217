import styled, { css } from 'styled-components';
import { mobileAndTabletOnly, mobileOnly } from '~/styles';

export const SumaryContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: 2rem;
    margin-top: 3.2rem;

    ${mobileOnly(css`
        flex-wrap: wrap;
        gap: 1.6rem;
    `)}
`;

export const SumaryStyled = styled.div`
    background-color: ${({ theme }) => theme.colors.grayPurple};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    padding: 1.6rem;
    border-radius: 1rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    width: 25%;

    ${mobileOnly(css`
        flex: 1 1 auto;
        width: 100%;
        max-width: calc(50% - 0.8rem);
    `)}

    & > span:first-child {
        color: ${({ theme }) => theme.colors.purple};
        display: flex;
        align-items: center;
        gap: 0.4rem;

        svg {
            height: 3.2rem;
            width: 3.2rem;
        }
    }

    & > span:last-child {
        color: ${({ theme }) => theme.colors.darkMossGreen};
        padding-left: 0.4rem;
    }

    & > strong {
        flex: 1;
        margin: ${({ theme }) => theme.space.xxs} 0;
        max-height: 7.2rem;
        display: flex;
        padding-left: 0.4rem;

        span {
            margin: auto 0;
        }

        ${({ theme }) =>
            mobileAndTabletOnly(css`
                font-size: ${theme.fontSizes.xl};
                line-height: ${theme.lineHeights.base};
            `)}
    }
`;
