import { motion } from 'framer-motion';
import styled from 'styled-components';
import { TooltipTrigger } from '~/components/elements/tooltip/trigger';
import { SubToolColors } from '~/styles/theme';

export const RulerStepStyled = styled(motion.div).withConfig({
    shouldForwardProp: (propName: unknown) => propName !== 'borderColor'
})<{ borderColor: SubToolColors }>`
    position: relative;

    & > div {
        border-radius: 1rem;
        border: 1px solid ${({ borderColor, theme }) => theme.subToolColors[borderColor]};
        overflow: hidden;

        & > header {
            background-color: ${({ borderColor, theme }) =>
                theme.subToolColors[borderColor] ?? theme.colors.lightBlack};
        }

        & > main {
            min-height: 15.4rem;
        }
    }
`;

export const RulerStepHeaderStyled = styled.header`
    padding: 0.8rem 1.6rem;
    padding-right: 0.2rem;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    justify-content: space-between;
`;

export const RulerStepContentStyled = styled.main`
    padding: 0.8rem 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background-color: ${({ theme }) => theme.colors.white};

    span {
        color: ${({ theme }) => theme.colors.philippineSilver};
    }

    & > div {
        flex: 1;
    }

    p {
        display: -webkit-box;
        max-width: 21.4rem;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;

export const RulerStepSendingTechonlogyListStyled = styled.ul`
    list-style: none;
    display: flex;
    gap: 0.8rem;
    align-self: baseline;
`;

export const TooltipTriggerStyled = styled(TooltipTrigger)<{ isEnabled?: boolean }>`
    display: inherit;
    position: relative;

    svg {
        height: 3.2rem;
        width: 3.2rem;
        transition: color 0.15s ease-out;
        color: ${({ theme, isEnabled = true }) =>
            isEnabled ? theme.colors.eminencePurple : theme.colors.philippineSilver};

        &:hover {
            color: ${({ theme, isEnabled = true }) => (isEnabled ? theme.colors.darkPurple : theme.colors.gray)};
        }
    }
`;
