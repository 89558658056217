import { daysToWeeks, hoursToMinutes, minutesToHours, minutesToSeconds, secondsToMinutes, weeksToDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import {
    NavigationTriggerRule,
    NavigationTriggerRuleConditionType,
    NavigationTriggerRuleLogicalOperator,
    SubToolTemplateConfigurationFrequencyRange
} from '@models';
import { buildTree, daysToMinutes, minutesToDays, TreeItem } from '@utils';
import Locales from '~/locales/locales.json';
import { ROOT_NODE_NAME } from '~/utils/treeFunctions';

type RuleLogicalOperatorType =
    | typeof Locales.enums.navigationTriggerRuleLogicalOperator
    | Record<keyof typeof Locales.enums.navigationTriggerRuleLogicalOperator, string>;

export const ConvertSendingPeriodToMinutes = (
    frequencyRange: SubToolTemplateConfigurationFrequencyRange,
    sendingPeriod: number
) => {
    let minutes = 0;
    switch (frequencyRange) {
        case SubToolTemplateConfigurationFrequencyRange.Minutes:
            minutes = sendingPeriod;
            break;
        case SubToolTemplateConfigurationFrequencyRange.Hours:
            minutes = hoursToMinutes(sendingPeriod);
            break;
        case SubToolTemplateConfigurationFrequencyRange.Days:
            minutes = daysToMinutes(sendingPeriod);
            break;
        case SubToolTemplateConfigurationFrequencyRange.Weeks:
            minutes = weeksToDays(daysToMinutes(sendingPeriod));
            break;
        case SubToolTemplateConfigurationFrequencyRange.Seconds:
            minutes = secondsToMinutes(sendingPeriod);
            break;
    }

    return minutes;
};

export const ConvertMinutesToSendingPeriod = (
    frequencyRange: SubToolTemplateConfigurationFrequencyRange,
    sendingPeriod: number
) => {
    let period = sendingPeriod;
    switch (frequencyRange) {
        case SubToolTemplateConfigurationFrequencyRange.Minutes:
            period = sendingPeriod;
            break;
        case SubToolTemplateConfigurationFrequencyRange.Hours:
            period = minutesToHours(sendingPeriod);
            break;
        case SubToolTemplateConfigurationFrequencyRange.Days:
            period = minutesToDays(sendingPeriod);
            break;
        case SubToolTemplateConfigurationFrequencyRange.Weeks:
            period = daysToWeeks(minutesToDays(sendingPeriod));
            break;
        case SubToolTemplateConfigurationFrequencyRange.Seconds:
            period = minutesToSeconds(sendingPeriod);
            break;
    }

    return period;
};

const CreateRootNodeIfNotExists = (rules: Array<NavigationTriggerRule>) => {
    if (!isEmpty(rules)) {
        const hasRootNode = !!rules.find((item) => item.id === ROOT_NODE_NAME);

        if (!hasRootNode) {
            rules.forEach((rule) => (rule.parentId = ROOT_NODE_NAME));
        }
    }
};

const defaultRulesTree = [
    {
        id: ROOT_NODE_NAME,
        level: 0,
        logicalOperator: NavigationTriggerRuleLogicalOperator.And,
        children: [
            {
                id: new Date().getTime(),
                level: 1,
                parentId: ROOT_NODE_NAME,
                conditionType: NavigationTriggerRuleConditionType.Equals,
                children: []
            }
        ]
    }
];

export const CreateRulesTree = (rules: Array<NavigationTriggerRule>) => {
    CreateRootNodeIfNotExists(rules);

    const ruleList = !isEmpty(rules) ? rules : defaultRulesTree;

    return buildTree<NavigationTriggerRule>(ruleList, 'id', 'parentId');
};

export const CreateRuleName = (
    ruleTree: TreeItem<NavigationTriggerRule>,
    logicalOperatorTranslated?: RuleLogicalOperatorType
) => {
    const expression = [];
    const logicalOperator = ruleTree.isRoot !== true ? ruleTree.parent.logicalOperator ?? null : null;
    let logical = '';

    if (logicalOperator !== null) {
        logical =
            logicalOperator === NavigationTriggerRuleLogicalOperator.And
                ? String(logicalOperatorTranslated.and)
                : String(logicalOperatorTranslated.or);
    }

    const children = ruleTree.children ?? [];
    const isParentNode = children?.length > 0 && !ruleTree.isRoot;

    if (children.length === 0) {
        const isLastChild = _last(ruleTree.parent?.children)?.id === ruleTree.id;
        const value = ruleTree.conditionValue;

        expression.push(`/${value};${isLastChild ? '' : ` ${logical?.toLowerCase()}`}`);
    } else if (isParentNode) {
        expression.push('(');
    }

    for (const item of children) {
        expression.push(`${CreateRuleName(item, logicalOperatorTranslated)} `);
    }

    if (isParentNode) {
        expression.push(')');
    }

    return expression
        .map((item) => item.trim())
        .join(' ')
        .trim();
};
