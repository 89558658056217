import { SOCIAL_ADDRESS, COMPANY_INFORMATION } from '@utils';
import LogoEnviou from '~/assets/svg/logo/logo__enviou.svg';
import { FacebookWhite, InstagramWhite, YoutubeWhite } from '~/assets/svg/social';
import { Text } from '../../text';
import { FooterMediumStyled, CompanyInformationContainerStyled, SocialIconsContainerStyled } from './styles';

export const FooterMedium = () => {
    return (
        <FooterMediumStyled>
            <LogoEnviou title={COMPANY_INFORMATION.CORPORATE_NAME} />

            <CompanyInformationContainerStyled>
                <Text as='a' size='xs' fontWeight='light' href={`mailto:${COMPANY_INFORMATION.EMAIL}`}>
                    {COMPANY_INFORMATION.EMAIL}
                </Text>
                <Text size='xs' fontWeight='light'>
                    {COMPANY_INFORMATION.ADDRESS}
                </Text>
            </CompanyInformationContainerStyled>

            <SocialIconsContainerStyled>
                <a href={SOCIAL_ADDRESS.FACEBOOK} target='_blank' rel='noopener noreferrer'>
                    <FacebookWhite title='Facebook Enviou' />
                </a>
                <a href={SOCIAL_ADDRESS.INSTAGRAM} target='_blank' rel='noopener noreferrer'>
                    <InstagramWhite title='Instagram Enviou' />
                </a>
                <a href={SOCIAL_ADDRESS.YOUTUBE} target='_blank' rel='noopener noreferrer'>
                    <YoutubeWhite title='Youtube Enviou' />
                </a>
            </SocialIconsContainerStyled>
        </FooterMediumStyled>
    );
};

export default FooterMedium;
