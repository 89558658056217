export enum CampaignStatus {
    None = 0,
    Creation = 1,
    WaitingForApproval = 2,
    Approved = 3,
    QueueOK = 4,
    InProgress = 5,
    Finished = 6,
    Paused = 7,
    Canceled = 8,
    Error = 9,
    InTransition = 10,
    PreparedForPartner = 11,
    ErrorCreateQueue = 12,
    CreatingFMTAQueue = 13,
    ForceCancellation = 14,
    Birthdays = 15,
    InsufficientBalance = 16,
    Duplicate = 17,
    Removed = 18
}

export enum FriendlyCampaignStatus {
    Draft = 1,
    Programmed = 2,
    Sending = 3,
    Sent = 4,
    Paused = 5,
    Cancelled = 6
}

export type Campaign = {
    id: number;
    name: string;
    status: CampaignStatus;
    friendlyStatus: FriendlyCampaignStatus;
    startDate: Date;
    sendingStartDate?: Date;
    sentCount?: number;
    isSent: boolean;
};
