import * as yup from 'yup';
import { useTranslator } from '@hooks';
import { NavigationTriggerRule } from '@models';
import { TreeItem } from '@utils';

export type TemplateConfigurationFormData = {
    rulesTree: Array<TreeItem<NavigationTriggerRule>>;
};

// TODO: iury.sousa - Centralize the main validations in function and use all sub tools templates
// Remove duplicity of code
export const useValidation = () => {
    const translator = useTranslator();

    const { condition } = translator.subTools.subToolTemplateConfiguration.form;

    const ruleTree = yup.object({
        id: yup.string().required().nullable(),
        conditionType: yup.number().optional().nullable(),
        logicalOperator: yup.number().optional().nullable(),
        conditionValue: yup
            .string()
            .when('logicalOperator', {
                is: (logicalOperator: number) => !logicalOperator,
                then: (schema) => schema.required(condition.value.required).nullable(),
                otherwise: (schema) => schema.optional().nullable()
            })
            .nullable(),
        eventType: yup.number().optional(),
        parentId: yup.string().optional().nullable(),
        isRoot: yup.boolean().optional().nullable(),
        children: yup.lazy(() => yup.array(ruleTree).default(undefined))
    });

    // NOTE: Keep the order of validations according to the structure and positions of the inputs on the page.
    // In this way, when generating an error, the screen focus will be redirected to the input in the correct order
    const subToolTemplateConfigurationSchema: yup.SchemaOf<TemplateConfigurationFormData> = yup.object().shape({
        rulesTree: yup.array().of(ruleTree)
    });

    return { subToolTemplateConfigurationSchema };
};
