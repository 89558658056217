import { Text, defaultHeadingStyle } from '@components';
import { useSubToolPage, useTranslator } from '@hooks';
import { AutomationToolUsageSummary, SubToolIdEnum } from '@models';
import { SubToolColors } from '~/styles/theme';
import { CardItemStyled } from '../styles';
import { CardItemContentStyled, CardItemTitleStyled } from './styles';
import { SubToolStatusButton, SubToolStatusRedirectToLink } from './subToolStatusButton';
import { TotalSale } from './totalSale';
import { useMarketingAutomation } from './useMarketingAutomation';

export type MarketingAutomationResultStatus = 'inactive' | 'active' | 'noTemplateFound';

type MarketingAutomationResultProps = {
    automationToolUsageSummary: AutomationToolUsageSummary;
};

export const MarketingAutomationResult = ({
    automationToolUsageSummary: subTool,
    ...props
}: MarketingAutomationResultProps) => {
    const {
        pages: {
            home: {
                lastedMarketingAutomationResultsCard: {
                    marketingAutomationResult: { salesAmountLabel, status: translatedStatus }
                }
            }
        },
        enums: { subToolIdEnum }
    } = useTranslator();

    const { subToolId, totalConversionValue, conversionCount } = subTool;

    const { getSubToolPageData } = useSubToolPage();
    const { icon, subToolKey } = getSubToolPageData(subToolId);

    const { status, redirectToSubToolLink } = useMarketingAutomation(subTool);

    return (
        <CardItemStyled {...props}>
            <CardItemContentStyled>
                <CardItemTitleStyled subToolColor={subToolKey as SubToolColors}>
                    {icon}
                    <Text as='strong' {...defaultHeadingStyle.h5}>
                        {subToolIdEnum[SubToolIdEnum[subToolId]]}
                    </Text>
                </CardItemTitleStyled>
                <TotalSale
                    status={status}
                    conversionCount={conversionCount}
                    salesAmountLabel={String(salesAmountLabel)}
                    totalConversionValue={totalConversionValue}
                />
                <SubToolStatusButton
                    status={status}
                    statusButtonDescription={String(translatedStatus[status].button)}
                    message={String(translatedStatus[status].message)}
                    redirectToLink={
                        {
                            showLink: redirectToSubToolLink.hasLink,
                            ...redirectToSubToolLink
                        } as SubToolStatusRedirectToLink
                    }
                />
            </CardItemContentStyled>
        </CardItemStyled>
    );
};
