import styled from 'styled-components';
import { cssFlexGridByDevice, FlexGridValues } from '~/styles';

export type ToggleButtonFormGroupStyledProps = {
    flexGrid: FlexGridValues;
};

export const ToggleButtonFormGroupStyled = styled.div<ToggleButtonFormGroupStyledProps>`
    display: flex;
    flex-direction: column;
    ${({ flexGrid }) => cssFlexGridByDevice(flexGrid)}

    & > label {
        margin-bottom: 1.6rem;
    }
`;
