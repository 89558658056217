import styled from 'styled-components';
import { Form } from '@unform/web';
import { Box } from '~/components/elements/box';
import { Card } from '~/components/elements/card';
import { Text } from '~/components/elements/text';
import { TooltipContent } from '~/components/elements/tooltip/content';

export const FormStyled = styled(Form)`
    margin-bottom: 10rem;

    h1 {
        margin-bottom: 3.2rem;
    }
`;

export const CardStyled = styled(Card).attrs(({ theme }) => ({ borderColor: theme.colors.purple }))`
    padding: 3.2rem;
    display: flex;
    flex-direction: column;

    & > header {
        padding: 0;
        margin-bottom: 3.2rem;
    }

    & > main {
        padding: 0;
        flex: 1;
    }
`;

export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4.2rem;
`;

export const InputBlockStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;

    ${Box} {
        display: flex;
        flex-direction: column;

        & > .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1;
        }

        p {
            margin-top: 1.6rem;
            color: ${({ theme }) => theme.colors.philippineSilver};
        }
    }
`;

export const TooltipContentStyled = styled(TooltipContent)`
    max-width: 24.8rem;
`;

export const GroupContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    & > button {
        width: fit-content;
    }
`;

export const DomainInputGroupStyled = styled.div`
    display: flex;
    gap: 1.6rem;

    & > div {
        max-width: 60rem;
    }

    & > button {
        text-wrap: nowrap;
    }
`;

export const ListStyled = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    overflow-x: auto;
    width: 100%;
`;

export const ListItemStyled = styled(Box).attrs({ as: 'li' })`
    padding: 0;
    position: relative;
    width: fit-content;
`;

export const ListHeaderStyled = styled(ListItemStyled)`
    & > div {
        padding: 1.4rem 2.4rem;

        strong {
            color: ${({ theme }) => theme.colors.purple};
            text-align: start;
        }

        strong:first-child,
        strong:last-child {
            text-align: center;
        }
    }
`;

export const CustomListItemStyled = styled(ListItemStyled)`
    strong {
        text-transform: uppercase;
        text-align: center;
        color: ${({ theme }) => theme.colors.purple};
    }

    & div > span {
        text-align: start;
        display: flex;
        align-items: center;
        gap: 0.8rem;

        & > span > span {
            color: ${({ theme }) => theme.colors.philippineSilver};
        }
    }
`;

export const StatusStyled = styled(Text)<{ color: 'darkMossGreen' | 'oldGold' }>`
    text-align: center;
    display: block;

    border-radius: ${({ theme }) => theme.radii.xl};
    border: 1px solid ${({ theme, color }) => theme.colors[color]};
    color: ${({ theme, color }) => theme.colors[color]};
    padding: 0.2rem 0.4rem 0.1rem 0.4rem;

    svg {
        width: 1.4rem;
        margin-right: 0.6rem;
        color: ${({ theme, color }) => theme.colors[color]};
    }
`;

export const ListItemContentStyled = styled.div`
    display: grid;
    grid-template-columns: 6.6rem minmax(60rem, 1fr) minmax(36rem, 1fr) 11.5rem;
    grid-gap: 0.8rem;
    padding: 2rem 2.4rem;
    align-items: center;
`;
