import styled from 'styled-components';

export const CodeBlockStyled = styled.div`
    position: relative;
    pre {
        font-family: ${({ theme }) => theme.fonts.code};
        color: ${({ theme }) => theme.colors.philippineSilver};
        background-color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSizes.xs};
        padding: 1.2rem 1.6rem;
        border-radius: 4px;
        line-height: 1.35;
        overflow: auto;
        hyphens: none;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
    }

    button {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
        cursor: pointer;
        opacity: 0;
        transition: all 0.15s;
        font-size: ${({ theme }) => theme.fontSizes.xxs};
        padding: 0.8rem 0.8rem 0.4rem;
    }

    &:hover button {
        opacity: 1;
    }
`;
