import styled, { css } from 'styled-components';
import { tabletAndDesktopOnly } from '~/styles';
import { cssMaxWidthWithPaddingX } from '~/styles/mixins';

export type MultiStepMenuStyleProps = {
    marginTop?: string | number;
};

export const MultiStepMenuStyled = styled.div<MultiStepMenuStyleProps>`
    width: 100%;
    margin-top: ${({ marginTop }) => marginTop};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        border-bottom: 0.15rem solid ${({ theme }) => theme.colors.lightPurple};
        position: absolute;
        top: 1.65rem;
        left: 0;
        right: 0;
    }
`;

export const MultiStepMenuListStyled = styled.ul`
    ${cssMaxWidthWithPaddingX}
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-evenly;
    gap: 1rem;

    li {
        z-index: 10;
    }

    ${tabletAndDesktopOnly(css`
        gap: 8rem;
    `)}
`;
