import styled from 'styled-components';

export const ButtonStyled = styled.strong`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.purple};

    &:hover {
        text-decoration: underline;
    }
`;
