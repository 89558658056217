import styled from 'styled-components';
import { Box } from '~/components/elements/box';

export const BoxStyled = styled(Box)`
    padding: 0.8rem 0;
    overflow: initial;

    & > div {
        padding: 1.6rem 2.4rem;
    }
`;
