import { useState } from 'react';
import { DatePicker } from '@components';
import { useTranslator } from '@hooks';
import {
    calculateDatePeriod,
    DateFilterOptionsWithCustomTimePeriod as PeriodType,
    isCustomizeTimePeriodType
} from '@utils';
import { FilterDropdown } from './filterDropdown';

export type ChangePeriodTypeHandler = (periodType: PeriodType, startDate?: Date, endDate?: Date) => void;

type PeriodTypeDropdownProps = {
    startDate?: Date;
    endDate?: Date;
    periodType?: PeriodType;
    disabled?: boolean;
    onChangePeriodType?: ChangePeriodTypeHandler;
};

export const PeriodTypeDropdown = ({
    startDate,
    endDate,
    disabled,
    periodType,
    onChangePeriodType = () => null,
    ...props
}: PeriodTypeDropdownProps) => {
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const {
        common: { datePicker }
    } = useTranslator();

    const handleDatePickerChange = (dates: [Date, Date]) => {
        const [start, end] = dates;

        if (!start) {
            onChangePeriodType('thisMonth');
            return;
        }

        if (end) {
            setOpenDatePicker(false);
        }

        onChangePeriodType('date', start, end);
    };

    const handleFilterDropdownChange = (selectedValue: PeriodType) => {
        if (isCustomizeTimePeriodType(selectedValue)) {
            onChangePeriodType(selectedValue);
            setOpenDatePicker(true);
            return;
        }

        const { startDate, endDate } = calculateDatePeriod[selectedValue]();
        onChangePeriodType(selectedValue, startDate, endDate);
    };

    return (
        <DatePicker
            {...props}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            open={openDatePicker}
            customInput={<FilterDropdown filterDateType={periodType} onDropdownChange={handleFilterDropdownChange} />}
            popperPlacement='top-start'
            maxDate={new Date()}
            disabled={disabled}
            selectsRange
            onChange={handleDatePickerChange}
            onClickOutside={() => setOpenDatePicker(false)}
            todayButton={String(datePicker.todayButton)}
            title={startDate && !endDate ? String(datePicker.labels.endDate) : String(datePicker.labels.startDate)}
        />
    );
};
