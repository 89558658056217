import { ReactElement, isValidElement, cloneElement, Children } from 'react';

export const isInvisible = (element?: HTMLElement) => element?.offsetParent === null;

export const recursiveMapChildren = (
    children: ReactElement[],
    callback: (child: ReactElement) => ReactElement
): ReactElement[] => {
    return Children.map(children, (child) => {
        if (!isValidElement(child)) {
            return child;
        }

        if ((child as ReactElement).props.children) {
            const props = {
                children: recursiveMapChildren((child as ReactElement).props.children, callback)
            };
            child = cloneElement(child, props);
        }

        return callback(child);
    });
};
