import styled, { css } from 'styled-components';
import { mobileOnly } from '~/styles/mediaQueries';

export const FooterMediumStyled = styled.div`
    flex: 1;
    margin: ${({ theme }) => theme.space['3xl']} auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & > svg {
        height: 4rem;
        color: ${({ theme }) => (theme.theme === 'light' ? theme.colors.white : theme.colors.seaSalt)};
    }

    ${mobileOnly(css`
        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;

        > div {
            align-items: flex-start;
        }
    `)}
`;

export const CompanyInformationContainerStyled = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    color: ${({ theme }) => theme.default.footer.color};

    a {
        color: inherit;

        &:first-child {
            margin-bottom: ${({ theme }) => theme.space.xxs};
        }
    }
`;

export const SocialIconsContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: ${({ theme }) => theme.space.md};
    line-height: 0;

    svg,
    img {
        width: ${({ theme }) => theme.space.xl};
        height: ${({ theme }) => theme.space.xl};
    }
`;
