import styled, { css } from 'styled-components';
import { Box } from '~/components/elements/box';
import { DialogContent } from '~/components/elements/dialog/content';
import { mobileOnly, tabletOnly } from '~/styles';
import { SubToolColors } from '~/styles/theme';

export const SubToolTutorialContent = styled.section`
    margin-bottom: 10rem;

    ul {
        list-style: none;
        display: flex;
        gap: 2.4rem;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const SubToolTutorialItemStyled = styled.li`
    margin-top: 10rem;
    flex: 1;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.default.backgroundColor};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    padding: 2.4rem 1.6rem;

    ${tabletOnly(css`
        flex: 29%;
        max-width: 29%;
    `)}

    ${mobileOnly(css`
        flex: 45%;
        max-width: 45%;
    `)}

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        position: relative;

        i {
            top: -7rem;
        }

        h6 {
            text-align: center;
            margin-bottom: 0.8rem;
            margin-top: 4.2rem;
            max-width: 12.8rem;
        }

        p {
            text-align: center;
            max-width: 20.6rem;
            flex: 1;
        }

        & > div {
            display: inherit;
            flex-direction: inherit;
            gap: 1.6rem;
            margin-top: 1.6rem;

            button {
                height: fit-content;
                width: 100%;
                padding: 0.6rem 1.2rem;
                padding-right: 1.6rem;

                svg {
                    width: 2.6rem;
                    height: 2.6rem;
                    margin-right: auto;
                }

                span {
                    flex: 1;
                    line-height: 0;
                    text-align: initial;
                    margin-top: 0.4rem;
                }
            }
        }
    }
`;

export const TutorialSubToolIconStyled = styled.i<{ subToolColor: SubToolColors }>`
    position: absolute;
    width: 9.8rem;
    height: 9.8rem;
    border-radius: 50%;
    background-color: ${({ theme, subToolColor }) => theme.subToolColors[subToolColor]};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);

    svg {
        width: 7.2rem;
        height: 7.2rem;
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const WatchTutorialModalContent = styled(DialogContent)`
    position: relative;
    margin-top: 14vh;

    h2 {
        color: ${({ theme }) => theme.colors.licorice};
        margin-top: 4rem;
        text-transform: uppercase;
    }

    p {
        max-width: 73.6rem;
        margin-top: ${({ theme }) => theme.space.xs};
    }

    i {
        top: -5rem;
    }

    footer {
        justify-content: space-between;
        button,
        a {
            max-width: 20.2rem;
            width: 100%;
        }
    }
`;

export const WatchTutorialModalVideo = styled(Box)`
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 64rem;
    margin: auto;
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
        width: 100%;
        height: 35.3rem;
    }

    i {
        height: 35.3rem;
        display: inherit;

        svg {
            margin: auto;
            width: 12.2rem;
            height: 12.2rem;
            color: ${({ theme }) => theme.colors.lightPurple};
        }
    }
`;
