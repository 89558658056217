import styled from 'styled-components';
import { CardBody } from '~/components/elements/card/body';
import { cssFlexGridByDevice, FlexGridValues } from '~/styles';

type AutomationPlansColumnStyledProps = {
    flexGrid?: FlexGridValues;
};

export const AutomationPlansRowStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2.4rem);
    margin: -1.2rem;
`;

export const AutomationPlansColumnStyled = styled.div<AutomationPlansColumnStyledProps>`
    display: flex;
    flex-direction: column;
    ${({ flexGrid }) => cssFlexGridByDevice(flexGrid)}
    padding: 1.2rem;
`;

export const AmountStyled = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CardBodyStyled = styled(CardBody)`
    padding-top: 2.4rem;
`;
