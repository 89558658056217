import { forwardRef, HTMLProps, MutableRefObject, ReactNode } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useTooltipProvider } from '@providers';
import { TooltipStyled, TooltipStyleProps } from './styles';

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & TooltipStyleProps>(
    function TooltipTrigger({ children, ...props }, propRef) {
        const context = useTooltipProvider();
        const childrenRef = (children as { ref: MutableRefObject<ReactNode> }).ref;
        const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

        return (
            <TooltipStyled
                ref={ref}
                data-state={context.open ? 'open' : 'closed'}
                {...context.getReferenceProps(props)}>
                {children}
            </TooltipStyled>
        );
    }
);
