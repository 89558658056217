import { ReactNode, useCallback, useMemo, useState, SetStateAction, Dispatch } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { createContext, useContextSelector } from 'use-context-selector';
import { useFetch } from '~/hooks/useFetch';
import { useQueryParams } from '~/hooks/useQueryParams';
import { Subscription } from '~/models/subscription';
import { Tool, ToolTypeEnum } from '~/models/tool';
import { GetBalanceConsumptionAsync, GetBalanceConsumptionUrl } from '~/services/subscriptionApiService';
import {
    DateFilterOptionsWithCustomTimePeriod,
    isCustomizeTimePeriodType,
    isDateType
} from '~/utils/calculateDatePeriod';
import { DateFormatEnum, formatDate } from '~/utils/dateFunctions';
import {
    calculateAndFormatDateParams,
    createDateParams,
    isDateParamsChanged,
    mergeDateQueryParamsObject
} from '~/utils/dateQueryParams';
import { useFallbackData } from './fallbackData';
import { useMakeAutomationSummary } from './makeAutomationSummary';

export type MySubscriptionFilterParamsState = {
    startDate?: Date;
    endDate?: Date;
    periodType?: DateFilterOptionsWithCustomTimePeriod;
    page?: number;
};

export type MySubscriptionQueryParamsType = Partial<Record<keyof MySubscriptionFilterParamsState, string>>;

export type Summary = {
    id: number;
    totalCreditsUsed: number;
    color: string;
    percentage: number;
    text: string;
};

export type AutomationSummary = {
    toolType: ToolTypeEnum;
    haveUnlimitedBalance: boolean;
    totalCredits: number;
    totalLooseCredits: number;
    totalUsedCredits: number;
    totalUsedLooseCredits: number;
    summary: Array<Summary>;
};

type MySubscriptionProviderContextData = {
    filterByDate: (dateType: DateFilterOptionsWithCustomTimePeriod, startDate?: Date, endDate?: Date) => void;
    changePage: (newPage: number) => void;
    filterParams?: MySubscriptionFilterParamsState;
    automationTool: AutomationSummary;
    bulkSendingTool: AutomationSummary;
    isLoading: boolean;
    subscription: Subscription;
    tools?: Array<Tool>;
    setTools: Dispatch<SetStateAction<Array<Tool>>>;
};

type MySubscriptionProviderProps = {
    children: ReactNode;
    subscription: Subscription;
    tools?: Array<Tool>;
};

const MySubscriptionProviderContext = createContext({} as MySubscriptionProviderContextData);

/** Time in minutes swr will wait for query key before querying again */
const MINUTES_TO_REQUERY_DATA = 1;

export const MySubscriptionProvider = ({ children, subscription, ...props }: MySubscriptionProviderProps) => {
    const makeAutomationSummary = useMakeAutomationSummary();
    const { updateQueryParams, queryParams } = useQueryParams<MySubscriptionQueryParamsType>();
    const fallbackData = useFallbackData();
    const [tools, setTools] = useState<Array<Tool>>(props.tools);

    const queryParamsState = useMemo(() => {
        const page = !isNaN(parseInt(queryParams.page)) ? Number(queryParams.page) : 1;

        // Felipe asked to add as default the start date being the date of the last update of the subscription,
        // so that the user sees the credits from that day
        if (!queryParams?.periodType && subscription?.lastCheckDate) {
            queryParams.periodType = 'date';
            queryParams.startDate = formatDate(subscription?.lastCheckDate, DateFormatEnum.OnlyDateUSFormat);
            queryParams.endDate = formatDate(new Date(), DateFormatEnum.OnlyDateUSFormat);
        }

        return mergeDateQueryParamsObject<MySubscriptionFilterParamsState>(queryParams, { page });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    const allowSearch = !isCustomizeTimePeriodType(queryParams.periodType) && !!queryParamsState.endDate;

    const { data, isLoading } = useFetch(
        allowSearch && {
            url: GetBalanceConsumptionUrl,
            startDate: queryParamsState.startDate,
            endDate: queryParamsState.endDate
        },
        async ({ startDate, endDate }, signal) => {
            const response = await GetBalanceConsumptionAsync({ startDate, endDate }, signal);

            return makeAutomationSummary(response.data, fallbackData.automationTool.summary);
        },
        {
            keepPreviousData: true,
            fallbackData: fallbackData,
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_REQUERY_DATA)
        }
    );

    const filterByDate = useCallback(
        (periodType: DateFilterOptionsWithCustomTimePeriod, startDate: Date = null, endDate: Date = null) => {
            if (isLoading) {
                return;
            }

            const isDateChanged = isDateParamsChanged({ periodType, startDate, endDate }, queryParamsState);

            if (!isDateChanged) {
                return;
            }

            if (!isCustomizeTimePeriodType(periodType)) {
                if (!isDateType(periodType)) {
                    updateQueryParams({ ...createDateParams(periodType), page: null });
                    return;
                }

                const page = endDate ? '1' : String(queryParamsState.page);
                updateQueryParams({ ...calculateAndFormatDateParams(periodType, startDate, endDate), page });
                return;
            }

            if (!isDateType(queryParamsState.periodType)) {
                updateQueryParams({ ...createDateParams(periodType), page: null });
            }
        },
        [queryParamsState, isLoading, updateQueryParams]
    );

    const changePage = useCallback(
        (newPage: number) => {
            if (isLoading) {
                return;
            }

            if (newPage !== queryParamsState.page) {
                updateQueryParams({ page: String(newPage) });
            }
        },
        [isLoading, queryParamsState.page, updateQueryParams]
    );

    return (
        <MySubscriptionProviderContext.Provider
            value={{
                filterParams: queryParamsState,
                filterByDate,
                changePage,
                automationTool: data.automationTool,
                bulkSendingTool: data.bulkSendingTool,
                isLoading,
                subscription,
                tools,
                setTools
            }}>
            {children}
        </MySubscriptionProviderContext.Provider>
    );
};

export const useMySubscriptionProvider = <Selected,>(
    selector: (value: MySubscriptionProviderContextData) => Selected
) => useContextSelector(MySubscriptionProviderContext, selector);
