import { useRouter } from 'next/router';
import { numberFormatter, currencyFormatter, percentageFormatter, DEFAULT_CURRENCY } from '@utils';
import { useAuth } from '~/providers/authProvider';

export const useNumberFunctions = () => {
    const { locale: defaultLocale } = useRouter();
    const { user } = useAuth();

    return {
        formatNumber: (value: number, options: Intl.NumberFormatOptions = {}) =>
            numberFormatter(value, defaultLocale, options),
        formatCurrency: (
            value: number,
            currency: string = user?.currency ?? DEFAULT_CURRENCY,
            options: Omit<Intl.NumberFormatOptions, 'currency'> = {}
        ) => currencyFormatter(value, defaultLocale, currency || (user?.currency ?? DEFAULT_CURRENCY), options),
        formatPercentage: (value: number, locale: string = defaultLocale) => percentageFormatter(value, locale)
    };
};
