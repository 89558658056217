import { useCallback, useMemo } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import _first from 'lodash/first';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import { HandleSaveLiveData, useFetch, useQueryParams, useTranslator } from '@hooks';
import {
    ItemConfigurationSort,
    MessageSequenceType,
    PaginatedList,
    RepurchaseCategory,
    RepurchaseData,
    RepurchaseProduct,
    RepurchaseStore
} from '@models';
import { captureException } from '@sentry/nextjs';
import { UpdateRepurchaseItemCacheKey } from '~/services/cacheKeys';
import { UpdateRepurchaseItem } from '~/services/cacheService';
import {
    GetRepurchaseCategoriesWithConfigurationAsync,
    GetRepurchaseProductsWithConfigurationAsync,
    GetRepurchaseStoresWithConfigurationAsync,
    repurchaseCategoriesWithConfigurationRoute,
    repurchaseProductsWithConfigurationRoute,
    repurchaseStoresWithConfigurationRoute,
    UpdateRepurchaseCategoryConfigurationAsync,
    UpdateRepurchaseProductConfigurationAsync,
    UpdateRepurchaseStoreConfigurationAsync
} from '~/services/repurchaseApiService';
import { fakeRepurchaseItemConfigurations } from './fakeData';
import {
    parseCategoryToRepurchaseItemConfiguration,
    parseProductToRepurchaseItemConfiguration,
    parseStoreToRepurchaseItemConfiguration
} from './parses';
import { createRepurchaseItemsFilterParamsState } from './queryParamsUtils';
import { getNoCategoryIfTextIsEqualsNoCategoryValue } from './utils';

export const MAX_ITEMS_PER_PAGE = 16;
export type RepurchaseItemType = Lowercase<keyof typeof MessageSequenceType>;

export type RepurchaseItemsQueryParamsState = {
    itemType?: RepurchaseItemType;
    search?: string;
    sort?: ItemSort;
    page?: number;
};

export type QueryParamsType = Partial<Record<keyof RepurchaseItemsQueryParamsState, string>>;

export type RepurchaseItemData = {
    subToolId?: number;
    id: string | null;
    isActive?: boolean;
    manualAverageRepurchasePeriod?: number;
    templateType: MessageSequenceType;
    cacheKey: UpdateRepurchaseItemCacheKey;
    type: RepurchaseItemType;
};

export type RepurchaseItemConfiguration = RepurchaseData & {
    id: string | number;
    identifier?: string;
    imageUrl?: string;
    name: string;
    category?: string;
    hasTemplate?: boolean;
    stepsAmount?: number;
    type: RepurchaseItemType;
    templateType: MessageSequenceType;
    repurchaseDetails?: string;
    countConsideredRepurchasesDescription?: string;
    averageRepurchasePeriodDescription?: string;
    cacheKey?: UpdateRepurchaseItemCacheKey;
};

export const itemsConfiguration = {
    general: {
        url: repurchaseStoresWithConfigurationRoute,
        getItems: GetRepurchaseStoresWithConfigurationAsync,
        updateData: UpdateRepurchaseStoreConfigurationAsync,
        parseToRepurchaseItemConfiguration: parseStoreToRepurchaseItemConfiguration,
        identifier: 'id'
    },
    category: {
        url: repurchaseCategoriesWithConfigurationRoute,
        getItems: GetRepurchaseCategoriesWithConfigurationAsync,
        updateData: UpdateRepurchaseCategoryConfigurationAsync,
        parseToRepurchaseItemConfiguration: parseCategoryToRepurchaseItemConfiguration,
        identifier: 'name'
    },
    product: {
        url: repurchaseProductsWithConfigurationRoute,
        getItems: GetRepurchaseProductsWithConfigurationAsync,
        updateData: UpdateRepurchaseProductConfigurationAsync,
        parseToRepurchaseItemConfiguration: parseProductToRepurchaseItemConfiguration,
        identifier: 'productIdentifier'
    }
};

export type ItemSort = Uncapitalize<keyof typeof ItemConfigurationSort> & string;

export type ManageRepurchaseItemsOptions = {
    subToolId: number;
};

const defaultParams: QueryParamsType = {
    search: '',
    sort: 'reliabilityDesc',
    page: '1',
    itemType: 'general'
};

export const useManageRepurchaseItems = ({ subToolId }: ManageRepurchaseItemsOptions) => {
    const translator = useTranslator();
    const { queryParams, updateQueryParams } = useQueryParams<QueryParamsType>();
    const queryParamsState = useMemo(() => createRepurchaseItemsFilterParamsState(queryParams), [queryParams]);
    const { locale } = useRouter();

    const cacheKey = useMemo(
        () => ({
            url: itemsConfiguration[queryParamsState.itemType].url,
            currentPage: queryParamsState.page,
            search: queryParamsState.search,
            sort: queryParamsState.sort
        }),
        [queryParamsState]
    );

    const { cache } = useSWRConfig();
    const { isLoading, data: repurchaseItems } = useFetch(
        cacheKey,
        async ({ currentPage, search, sort }) => {
            const text = getNoCategoryIfTextIsEqualsNoCategoryValue(translator, search);

            const response = itemsConfiguration[queryParamsState.itemType].getItems({
                page: currentPage,
                search: text,
                quantityPerPage: MAX_ITEMS_PER_PAGE,
                sort: sort as unknown as ItemConfigurationSort
            });

            return (await response).data;
        },
        {
            keepPreviousData: true,
            onError: (error) => captureException(error),
            dedupingInterval: minutesToMilliseconds(1)
        }
    );

    const updateItem: HandleSaveLiveData<RepurchaseItemData> = useCallback(
        async (data) => {
            try {
                if (!data || !data.cacheKey) {
                    return {
                        status: 'failed',
                        error: new Error('Empty data or invalid cache key')
                    };
                }

                const { cacheKey, type, id, templateType, isActive, manualAverageRepurchasePeriod } = data;

                await itemsConfiguration[type].updateData({
                    id,
                    templateType,
                    isActive,
                    manualAverageRepurchasePeriod
                });

                await UpdateRepurchaseItem({
                    cacheKey,
                    itemIdentifierKey: itemsConfiguration[type].identifier,
                    data: { ...data, subToolId },
                    cache
                });

                return {
                    status: 'success'
                };
            } catch (error) {
                captureException(error);

                return {
                    status: 'failed',
                    error: error as Error
                };
            }
        },
        [subToolId, cache]
    );

    const onItemTypeChange = useCallback(
        (newItemType: RepurchaseItemType) => {
            if (newItemType !== queryParamsState.itemType) {
                updateQueryParams({ ...defaultParams, itemType: newItemType }, { scroll: false });
            }
        },
        [queryParamsState.itemType, updateQueryParams]
    );

    const onSortByChange = useCallback(
        (newSort: ItemSort) => {
            if (newSort !== queryParamsState.sort) {
                updateQueryParams({ sort: newSort, page: defaultParams.page }, { scroll: false });
            }
        },
        [queryParamsState.sort, updateQueryParams]
    );

    const onFilter = useCallback(
        (newSearch: string) => {
            if (queryParamsState.search !== newSearch) {
                updateQueryParams(
                    { page: defaultParams.page, sort: defaultParams.sort, search: newSearch },
                    { scroll: false }
                );
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queryParamsState.search, updateQueryParams]
    );

    const onPaginationChange = useCallback(
        (newPage: number) => {
            if (queryParamsState.page !== newPage) {
                updateQueryParams({ page: String(newPage) }, { scroll: true });
            }
        },
        [queryParamsState.page, updateQueryParams]
    );

    const { data } = useMemo(() => {
        const items = repurchaseItems ?? ({ items: [] } as PaginatedList);

        const newData = itemsConfiguration[queryParamsState.itemType].parseToRepurchaseItemConfiguration({
            data: items as PaginatedList<RepurchaseProduct & RepurchaseCategory & RepurchaseStore>,
            translation: translator,
            locale,
            cacheKey
        });

        if (isLoading && (!newData?.items || newData?.items?.length === 0)) {
            newData.items =
                queryParamsState.itemType === 'general'
                    ? [_first(fakeRepurchaseItemConfigurations)]
                    : fakeRepurchaseItemConfigurations;
        }

        return { data: newData };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repurchaseItems, queryParamsState.itemType, isLoading, locale, cacheKey]);

    return {
        updateItem,
        filter: {
            params: queryParamsState,
            onItemTypeChange,
            onSortByChange,
            onFilter,
            onPaginationChange
        },
        data,
        paginationData: { ...data, items: null } as Omit<PaginatedList, 'items'>,
        isLoading
    };
};
