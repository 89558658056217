import {
    SubToolTemplateConfiguration,
    SubToolTemplateConfigurationFrequencyRange,
    SubToolTemplateConfigurationTypeEnum
} from './subToolTemplateConfiguration';

export enum NavigationTriggerRuleEventType {
    UrlLoad = 1,
    Hover = 2,
    Click = 3
}

export enum NavigationTriggerRuleConditionType {
    Contains = 1,
    Distinct = 2,
    StartsWith = 3,
    EndsWith = 4,
    Equals = 5,
    Regex = 6,
    DoesNotContain = 7
}

export enum NavigationTriggerRuleLogicalOperator {
    And = 1,
    Or = 2
}

export type NavigationTriggerRuleId = string | number;

export type NavigationTriggerRule = {
    id: NavigationTriggerRuleId;
    parentId?: number | string;
    eventType?: NavigationTriggerRuleEventType;
    conditionValue?: string;
    conditionType?: NavigationTriggerRuleConditionType;
    logicalOperator?: NavigationTriggerRuleLogicalOperator;
};

export type NavigationTriggerRuleTree = {
    parent: NavigationTriggerRule;
    children: Array<NavigationTriggerRule>;
    isRoot: boolean;
};

export type NavigationTriggerTemplateConfiguration = {
    ruleConfigurationId: string;
    isActive: boolean;
    templateConfigurationType: SubToolTemplateConfigurationTypeEnum;
    frequencyInMinutes: number;
    frequencyRange: SubToolTemplateConfigurationFrequencyRange;
    rules: Array<NavigationTriggerRule>;
    templates: Array<SubToolTemplateConfiguration>;
};

export type NavigationTriggerRuleConfiguration = {
    id?: string;
    isActive: boolean;
    frequencyInMinutes: number;
    rules: Array<NavigationTriggerRule>;
};

export type NavigationTriggerRuleConfigurationUpdate = {
    id?: string;
    frequencyInMinutes: number;
    rules: Array<NavigationTriggerRule>;
};
