import { useRef } from 'react';
import { CanceledError } from 'axios';
import _sortBy from 'lodash/sortBy';
import { useRouter } from 'next/router';
import { FunnelChart } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { SubscriptionStatusEnum, subToolOrder, ToolTypeEnum } from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import {
    GetAutomationSubToolsSummaryAsync,
    getAutomationSubToolsSummaryUrl,
    GetToolSummaryAsync,
    GetToolSummaryUrl
} from '~/services/toolApiService';
import {
    CreateToolFunnelChartStages,
    GetDefaultAutomationToolUsageSummary,
    GetDefaultToolFunnelChartStages,
    ParseToolUsageSummaryToToolFunnelChartSummaries
} from '~/services/toolService';
import { PageSubTitle, PageTitle } from '~/styles';
import theme from '~/styles/theme';
import { InactiveToolContent, ToolFunnelChartContent, LastedMarketingAutomationResultsCard } from '../';
import { ContentStyled, SectionStyled } from '../styles';

export const MarketingAutomationSection = () => {
    const {
        errorMessage,
        funnelChart: { summary },
        marketingAutomationSection: { title, subTitle }
    } = useTranslator().pages.home;

    const { user } = useAuth();
    const router = useRouter();
    const alert = useAlert();
    const errorAlertId = useRef(alert.generateAlertId()).current;

    const dataForTranslation = { routerData: { ...router }, currency: user?.currency, translatedSumary: summary };

    const defaultAutomationTool = GetDefaultToolFunnelChartStages(
        dataForTranslation,
        theme.funnelChart.automationToolFunnelChartColors
    );

    const { data: automationToolData, isLoading: isAutomationToolSummaryLoading } = useFetch(
        { url: GetToolSummaryUrl(ToolTypeEnum.AutomationTool) },
        async (_, signal) => {
            try {
                const response = await GetToolSummaryAsync(ToolTypeEnum.AutomationTool, signal);

                const automationToolSummaries = ParseToolUsageSummaryToToolFunnelChartSummaries(
                    response.data,
                    ToolTypeEnum.AutomationTool
                );

                const toolFunnelChartStages = CreateToolFunnelChartStages(
                    dataForTranslation,
                    automationToolSummaries,
                    theme.funnelChart.automationToolFunnelChartColors
                );

                return { funnelChartStages: toolFunnelChartStages, summary: response.data };
            } catch (error) {
                // throws exception not to save anything in cache
                if (error instanceof CanceledError || error instanceof EnviouApiResponseError) {
                    throw error;
                }

                captureException(error);
                alert.error(String(errorMessage), null, { id: errorAlertId, autoClose: true });

                return {
                    funnelChartStages: defaultAutomationTool.funnelStages,
                    summary: defaultAutomationTool.summary
                };
            }
        }
    );

    const { data: automationSubToolsSummary, isLoading: isAutomationSubToolSummaryLoading } = useFetch(
        { url: getAutomationSubToolsSummaryUrl },
        async () => {
            try {
                const { data } = await GetAutomationSubToolsSummaryAsync();

                return _sortBy(
                    data.map((item) => {
                        return {
                            ...item,
                            order: subToolOrder[item.subToolId]
                        };
                    }),
                    'order'
                );
            } catch (error) {
                if (error instanceof CanceledError || error instanceof EnviouApiResponseError) {
                    throw error;
                }

                captureException(error);
                alert.error(String(errorMessage), null, { id: errorAlertId, autoClose: true });

                return GetDefaultAutomationToolUsageSummary();
            }
        }
    );

    const funnelChartStages = automationToolData?.funnelChartStages ?? defaultAutomationTool.funnelStages;
    const subscriptionStatus = automationToolData?.summary?.subscriptionStatus ?? SubscriptionStatusEnum.Active;
    const isActive = automationToolData?.summary?.isActive ?? true;

    return (
        <SectionStyled>
            <PageTitle>{String(title)}</PageTitle>
            <PageSubTitle>{String(subTitle)}</PageSubTitle>
            <ContentStyled>
                <FunnelChart
                    backgroundColor={funnelChartStages.backgroundColor}
                    content={({ data }) => <ToolFunnelChartContent {...data} />}
                    data={funnelChartStages.data}
                    stage={funnelChartStages.stage}
                    isLoading={isAutomationToolSummaryLoading && !automationToolData?.funnelChartStages}
                />
                <LastedMarketingAutomationResultsCard
                    isLoading={isAutomationSubToolSummaryLoading && !automationSubToolsSummary}
                    automationSubToolsSummary={automationSubToolsSummary ?? GetDefaultAutomationToolUsageSummary()}
                />
            </ContentStyled>
            <InactiveToolContent isActiveTool={isActive} subscriptionStatus={subscriptionStatus} />
        </SectionStyled>
    );
};
