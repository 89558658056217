export const space = {
    xxxs: '0.4rem',
    xxs: '0.6rem',
    xs: '0.8rem',
    sm: '1.2rem',
    md: '1.6rem',
    lg: '2rem',
    xl: '2.4rem',
    '2xl': '2.8rem',
    '3xl': '3.2rem',
    '4xl': '4rem',
    '5xl': '4.8rem',
    '6xl': '6.4rem',
    '7xl': '8rem',
    '8xl': '16rem',
    '9xl': '25.6rem',
    '10xl': '32rem'
} as const;
