import styled from 'styled-components';
import { Card } from '~/components/elements/card';

export const TagsAnalyticsCardStyled = styled(Card)``;

export const Content = styled.div`
    flex-direction: row;
    display: flex;
    width: 100%;
    gap: 1.6rem;

    & > div {
        padding: 0;
    }

    label {
        div > div:has(svg) {
            height: 0;
        }
    }
`;
