import { motion } from 'framer-motion';
import styled from 'styled-components';

export const IndicatorStyled = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.purple};
    opacity: 1;
    border-radius: 0.6rem;
`;
