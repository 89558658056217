import { Button, CardBody, CardHeader, Link, Shimmer } from '@components';
import { useTranslator, useTranslatedRoute } from '@hooks';
import { AutomationToolUsageSummary } from '@models';
import { routesName } from '~/locales/route';
import { CardItemsStyled, CardStyled } from '../styles';
import { MarketingAutomationResult } from './marketingAutomationResult';

type LastedMarketingAutomationResultsCardProps = {
    automationSubToolsSummary: Array<AutomationToolUsageSummary>;
    isLoading?: boolean;
};

export const LastedMarketingAutomationResultsCard = ({
    automationSubToolsSummary = [],
    isLoading = false
}: LastedMarketingAutomationResultsCardProps) => {
    const {
        lastedMarketingAutomationResultsCard: { title, viewStatisticsButton }
    } = useTranslator().pages.home;
    const translateRoute = useTranslatedRoute();

    return (
        <Shimmer isActive={isLoading}>
            <CardStyled type='group'>
                <CardHeader
                    title={title}
                    data-shimmer
                    actions={
                        <Link
                            href={{
                                pathname: routesName.tool._statistics
                            }}
                            showLink={!isLoading}
                            as={translateRoute(routesName.tool.statistics)}>
                            <Button buttonSize='small' color='purple' disabled={isLoading}>
                                {String(viewStatisticsButton)}
                            </Button>
                        </Link>
                    }
                />
                <CardBody>
                    <CardItemsStyled>
                        {automationSubToolsSummary.map((subTool) => (
                            <MarketingAutomationResult
                                key={subTool.subToolId}
                                automationToolUsageSummary={subTool}
                                data-shimmer
                            />
                        ))}
                    </CardItemsStyled>
                </CardBody>
            </CardStyled>
        </Shimmer>
    );
};
