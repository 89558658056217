import { createContext, ReactNode, useContext, useState } from 'react';
import { AlertColors } from '~/styles/theme';
import { NoticeCardContainerStyled } from './styles';

type NoticeCardRootProps = {
    className?: string;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    initialOpen?: boolean;
    children: ReactNode;
    variant?: AlertColors;
};

type NoticeCardContextProps = {
    isOpen: boolean;
    initialExpansionValueNoticeCard: { height: string | number };
    onOpenChange: (open: boolean) => void;
    variant: AlertColors;
};

export const NoticeCardContext = createContext<NoticeCardContextProps>({} as NoticeCardContextProps);

export const useNoticeCard = () => {
    return useContext(NoticeCardContext);
};

export const NoticeCardRoot = ({
    children,
    className,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    initialOpen = true,
    variant = 'default'
}: NoticeCardRootProps) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

    const isOpen = controlledOpen ?? uncontrolledOpen;
    const onOpenChange = setControlledOpen ?? setUncontrolledOpen;

    const initialExpansionValueNoticeCard = { height: initialOpen && isOpen ? 'inherit' : 0 };

    return (
        <NoticeCardContext.Provider value={{ isOpen, initialExpansionValueNoticeCard, variant, onOpenChange }}>
            <NoticeCardContainerStyled className={className}>{children}</NoticeCardContainerStyled>
        </NoticeCardContext.Provider>
    );
};
