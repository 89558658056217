import { InputHTMLAttributes, forwardRef } from 'react';
import { InputStyledProps, InputStyled } from './styles';

export type InputProps = {
    type:
        | 'color'
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'file'
        | 'hidden'
        | 'image'
        | 'month'
        | 'number'
        | 'password'
        | 'range'
        | 'search'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week';
    testId?: string;
} & InputStyledProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | keyof InputStyledProps>;

export const Input = forwardRef<HTMLInputElement, InputProps>(({ testId, ...props }, ref) => {
    return <InputStyled ref={ref} data-testid={testId} {...props} />;
});

Input.displayName = 'Input';
