import { MutableRefObject } from 'react';
import { AnimationControls } from 'framer-motion';
import { Card, CardBody, CardHeader } from '@components';
import { useTranslator } from '@hooks';
import { NavigationTriggerRule, NavigationTriggerRuleId } from '@models';
import { TreeItem } from '@utils';
import { RulesTree } from './rulesTree';

global.showRulesValue = false;

export const TRANSITION_DELAY = 0.1;
export const MAX_SIZE_LEVELS_IN_RULES_TREE = 3;

export type RuleDefaultProps = {
    onCreateRule: (rule: TreeItem<NavigationTriggerRule>) => void;
    onCreateRuleGroup: (rule: TreeItem<NavigationTriggerRule>) => void;
    onRemove: (ruleId: NavigationTriggerRuleId, ruleElementAnimationIndex: number) => void;
    path?: string;
    ruleElementAnimationIndexRef: MutableRefObject<number>;
    ruleElementAnimationIndex: number;
    rule?: TreeItem<NavigationTriggerRule>;
    rules?: Array<TreeItem<NavigationTriggerRule>>;
    animation?: AnimationControls;
    showRemoveButton?: boolean;
    ruleToDisplayLabels?: TreeItem<NavigationTriggerRule>;
};

export const getDebugValue = (data: string | number, path: string, isActive = global.showRulesValue) =>
    isActive ? `${path} - data: ${data}` : null;

type AbandonmentSettingsProps = {
    subToolColor?: string;
    initial?: Array<TreeItem<NavigationTriggerRule>>;
    onChange?: (newRules: Array<TreeItem<NavigationTriggerRule>>) => void;
};

export const AbandonmentSettings = ({ subToolColor, initial: rules }: AbandonmentSettingsProps) => {
    const {
        subTools: {
            navigationTrigger: { abandonmentConfigurationCard }
        }
    } = useTranslator();

    return (
        <Card type='group' borderColor={subToolColor}>
            <CardHeader title={String(abandonmentConfigurationCard.head.title)} />
            <CardBody>
                <RulesTree rules={rules} />
            </CardBody>
        </Card>
    );
};

export * from './ruleInputs';
export * from './rulesGroup';
export * from './rule';
export * from './rulesTree';
