import { motion } from 'framer-motion';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { Card } from '~/components/elements/card';
import type { CardStyleProps } from '~/components/elements/card/styles';

type SubToolCardStyledProps = {
    hasItems?: boolean;
};
type CardProps = ThemedStyledProps<CardStyleProps & SubToolCardStyledProps, DefaultTheme>;

export const CardStyled = styled(Card).attrs((props: CardProps) => ({
    borderColor: props.theme.subToolColors[props.borderColor]
}))<SubToolCardStyledProps>`
    padding: 3.2rem 4rem;

    & > main {
        margin-top: ${({ hasItems }) => (hasItems ? '3.2rem' : 0)};
        padding: 0;
    }
`;

export const CustomTriggerRulerStyled = styled(motion.div).attrs(() => ({
    layout: true,
    initial: { scale: 0.8, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0.8, opacity: 0 },
    transition: { ease: 'easeInOut', duration: 0.3, layout: { duration: 0.2 } }
}))``;
