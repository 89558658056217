import { InputHTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { ToggleSwitchStyled, ToggleSwitchInputStyled, ToggleSwitchLabelStyled } from './styles';

type ToogleSwitchProps = {
    name: string;
    children?: ReactNode;
    enableUnform?: boolean;
    testId?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'name'>;

export const ToggleSwitch = ({ name, children, enableUnform = false, testId, ...props }: ToogleSwitchProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        if (enableUnform) {
            registerField<boolean>({
                name: fieldName,
                ref: inputRef.current,
                path: 'checked'
            });
        }
    }, [enableUnform, fieldName, registerField]);

    return (
        <ToggleSwitchStyled
            className={`${error ? 'input-error' : ''}`}
            data-validate={error}
            onClick={(event) => event.stopPropagation()}>
            <ToggleSwitchInputStyled
                id={fieldName}
                ref={inputRef}
                defaultChecked={defaultValue}
                role='switch'
                data-testid={testId}
                {...props}
            />

            <ToggleSwitchLabelStyled as='label' htmlFor={fieldName} color={props.color}>
                {children}
            </ToggleSwitchLabelStyled>
        </ToggleSwitchStyled>
    );
};
