import styled, { css } from 'styled-components';
import { desktopOnly } from '~/styles/mediaQueries';
import { CardType, cardPadding, cardPaddingDesktop, cardGroupPaddingDesktop } from '../styles';

export type CardBodyStyledProps = {
    type?: CardType;
};

export const CardBodyStyled = styled.main<CardBodyStyledProps>`
    ${({ type }) => css`
        display: flex;
        flex-direction: column;
        padding: ${cardPadding};

        ${type === 'group' && 'gap: 3rem;'}

        ${desktopOnly(css`
            padding: ${type === 'group' ? cardGroupPaddingDesktop : cardPaddingDesktop};
        `)}
    `}
`;
