import styled from 'styled-components';

export const AddButtonContainerStyled = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2rem;
    height: 100%;

    a {
        outline: none;
    }

    button {
        background-color: ${({ theme }) => theme.colors.white};
    }
`;
