import styled, { css } from 'styled-components';
import { Button } from '~/components/elements/button';
import { Card, CardBody } from '~/components/elements/card';
import { InputGroup } from '../inputGroup';

type CardBodyStyledProps = {
    isExpanded: boolean;
};

export const CardBodyStyled = styled(CardBody)<CardBodyStyledProps>`
    ${({ isExpanded }) =>
        !isExpanded &&
        css`
            padding-bottom: 1.8rem;
            padding-top: 0;
            transition: padding 0.2s ease;
        `}
`;

export const TitleStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    position: relative;

    & > div {
        position: relative;
        bottom: 0.3rem;
    }
`;

export const PhoneContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    border: none;
    background: transparent;
    position: relative;

    & > div {
        position: relative;
    }
`;

export const SendPushTestButtonStyled = styled(Button)`
    margin-top: 1.6rem;
    width: 100%;
`;

export const VariableInfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ToolTipChildrenStyled = styled.span`
    max-width: 28rem;
    display: block;
`;

export const InputGroupStyled = styled(InputGroup)`
    margin-bottom: 0.2rem;
    margin-top: 2.4rem;
    div:first-child {
        max-width: 25.6rem;
    }

    button {
        width: auto;
    }
`;

export const PushActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
`;
