import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { LooseBalanceCredits } from '~/components/containers/contractLooseBalance';
import { Button } from '~/components/elements/button';
import { Link } from '~/components/elements/link';
import { Shimmer } from '~/components/elements/shimmer';
import { useNumberFunctions } from '~/hooks/useNumberFunctions';
import { useTranslator } from '~/hooks/useTranslator';
import { routesName } from '~/locales/route';
import { SendingTechnologyTypeEnum } from '~/models/sendingTechnology';
import { Subscription } from '~/models/subscription';
import { Tool, ToolTypeEnum } from '~/models/tool';
import { useAlert } from '~/providers/alertProvider';
import { GetToolNameById, GetSendingTechnologiesByType } from '~/services/toolService';
import { divideOrFallback } from '~/utils/math';
import { UsedCreditsAmountBarChart } from '../usedCreditsAmount';
import { AutomaticContractingLooseBalanceModal } from './automaticContractingLooseBalanceModal';
import { BarChartHeaderItems } from './barChartHeaderItems';
import { LooseBalanceButtonsContainerStyled } from './styles';

type LooseCreditsProps = {
    totalCredits: number;
    usedCreditsAmount: number;
    toolType: ToolTypeEnum;
    isLoading?: boolean;
    subscription: Subscription;
    tools: Array<Tool>;
};

export const LooseCreditsBarChart = ({
    toolType,
    totalCredits = 0,
    usedCreditsAmount = 0,
    isLoading = false,
    subscription,
    tools = []
}: LooseCreditsProps) => {
    const { colors } = useTheme();
    const {
        buttons,
        pages: {
            contractLooseBalance: { balanceContractingSection }
        }
    } = useTranslator();
    const { formatNumber, formatPercentage } = useNumberFunctions();
    const { locale } = useRouter();
    const { show } = useAlert();

    const percentageOfCreditsUsed = divideOrFallback(usedCreditsAmount, totalCredits);
    const formattedUsedCreditAmount = formatNumber(usedCreditsAmount);
    const formattedAmountCreditsAvailable = formatNumber(totalCredits - usedCreditsAmount);

    const toolName = GetToolNameById(toolType, locale);

    const credits = useMemo(() => {
        const technologies = GetSendingTechnologiesByType(tools, toolType);

        return technologies
            ?.filter((technology) => technology.type === SendingTechnologyTypeEnum.Email)
            ?.map<LooseBalanceCredits>((technology) => ({
                id: technology.id,
                currency: technology.currency,
                looseValue: technology.looseValue,
                creditAmount: technology.creditAmount
            }));
    }, [tools, toolType]);

    return (
        <Shimmer isActive={isLoading}>
            <UsedCreditsAmountBarChart.Root>
                <UsedCreditsAmountBarChart.Header>
                    <BarChartHeaderItems
                        data-shimmer
                        percentageUsedCredits={percentageOfCreditsUsed}
                        totalCredits={totalCredits}
                        usedCreditsAmount={usedCreditsAmount}
                        isLooseBalance
                    />
                </UsedCreditsAmountBarChart.Header>
                <UsedCreditsAmountBarChart.Bar data-shimmer title={formattedAmountCreditsAvailable}>
                    <UsedCreditsAmountBarChart.BarItem
                        data-shimmer
                        title={formattedUsedCreditAmount}
                        color={colors.eminencePurple}
                        formattedPercentage={formatPercentage(percentageOfCreditsUsed)}
                    />
                </UsedCreditsAmountBarChart.Bar>
                <LooseBalanceButtonsContainerStyled>
                    <Link
                        href={{ pathname: routesName.mySubscription.contractLooseBalance, query: { tool: toolName } }}
                        as={routesName.mySubscription.contractLooseBalance}
                        translatedParams={{ tool: toolName }}>
                        <Button buttonSize='small' color='purple' disabled={isLoading}>
                            {String(buttons.contractLooseBalance)}
                        </Button>
                    </Link>
                    <Button
                        buttonSize='small'
                        color='purple'
                        isOutlined
                        disabled={isLoading}
                        onClick={() =>
                            show(
                                String(balanceContractingSection.automaticContracting.text),
                                String(balanceContractingSection.automaticContractingLooseBalance),
                                {
                                    content: (options) => (
                                        <AutomaticContractingLooseBalanceModal
                                            {...options}
                                            toolType={toolType}
                                            subscription={subscription}
                                            credits={credits}
                                            isLoading={isLoading}
                                        />
                                    )
                                }
                            )
                        }>
                        {String(buttons.automaticLooseBalanceConfiguration)}
                    </Button>
                </LooseBalanceButtonsContainerStyled>
            </UsedCreditsAmountBarChart.Root>
        </Shimmer>
    );
};
