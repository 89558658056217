import { PhoneFramePreview, Text, ViewModeProps } from '@components';
import { AndroidContainer, ChromeIconStyle, NotificationContainerStyled } from './styles';

export const AndroidViewElement = ({ body, title, domain, currentTime }: ViewModeProps) => {
    return (
        <AndroidContainer>
            <PhoneFramePreview>
                <div className='screen-content'>
                    <Text>{currentTime}</Text>
                    <NotificationContainerStyled>
                        <div className='browser-info'>
                            <ChromeIconStyle />
                            <Text className='browser-name'>Chrome</Text>
                            <Text className='domain'>{domain}</Text>
                        </div>
                        <Text className='title'>{title}</Text>
                        <Text className='text'>{body}</Text>
                    </NotificationContainerStyled>
                </div>
            </PhoneFramePreview>
        </AndroidContainer>
    );
};
