import styled, { css } from 'styled-components';
import ArrowDownIcon from '~/assets/svg/controls/arrow--down--purple.svg';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import { desktopOnly } from '~/styles';

export type CardHeaderStyleProps = {
    type?: 'default' | 'group';
    showExpandIcon?: boolean;
};

const getStyleCardHeaderGroup = () => css`
    padding: 3.6rem 2.4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;

    ${desktopOnly(css`
        padding: 3.6rem 4rem 0;
        flex-direction: row;
        gap: 5rem;
    `)}
`;

const getStyleCardHeaderDefault = () => css`
    padding: 3rem 2.4rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1.6rem;

    ${desktopOnly(css`
        padding: 3rem 3rem 0;
    `)}
`;

export const CardHeaderStyled = styled.header<CardHeaderStyleProps>`
    ${({ type }) => {
        switch (type) {
            case 'group':
                return getStyleCardHeaderGroup();

            case 'default':
            default:
                return getStyleCardHeaderDefault();
        }
    }}

    ${({ showExpandIcon }) =>
        showExpandIcon &&
        css`
            cursor: pointer;
        `};

    & > svg {
        color: ${({ theme }) => theme.colors.purple};
        height: 1em;
        align-self: flex-start;
        vertical-align: middle;
        margin-top: 1rem;
    }
`;

export const ArrowDownStyled = styled(ArrowDownIcon)`
    align-self: flex-start;
    margin-left: auto;
    width: 1.6rem;
    height: 2.4rem;
    margin-top: 0;
`;

export const CardHeaderGroupStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
`;

export const CardHeaderHelpIconStyled = styled(HelpIcon)`
    padding-left: 0.8rem;
    height: 1em;
    color: ${({ theme }) => theme.colors.purple};
`;

export const TitleStyled = styled.div`
    flex: 1;
    p {
        margin-top: 1.2rem;
    }
`;

export const ActionsStyled = styled.div``;
