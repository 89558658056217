import { PeriodTypeDropdown } from '@components';
import { EmailMarketingSummary, MarketingAutomationSummary, MyPayments } from '@containers';
import { useTranslator } from '@hooks';
import { useMySubscriptionProvider } from '@providers';
import { PageSubTitle, PageTitle } from '~/styles';
import { AutomationsContent, MySubscriptionContainer, PageHeaderStyled } from './styles';

// const looseBalance = {
//     totalCredits: 1000,
//     item: {
//         id: '0',
//         totalCreditsUsed: 300,
//         color: colors.eminencePurple,
//         percentage: 50,
//         text: ''
//     }
// };

export const MySubscriptionTemplate = () => {
    const {
        pages: { mySubscription }
    } = useTranslator();

    const { isLoading, filterParams, filterByDate, changePage } = useMySubscriptionProvider((state) => {
        return {
            filterParams: state.filterParams,
            filterByDate: state.filterByDate,
            changePage: state.changePage,
            isLoading: state.isLoading
        };
    });

    return (
        <MySubscriptionContainer>
            <PageHeaderStyled>
                <div>
                    <PageTitle as='h2'>{String(mySubscription.title)}</PageTitle>
                    <PageSubTitle>{String(mySubscription.subtitle)}</PageSubTitle>
                </div>
                <PeriodTypeDropdown {...filterParams} onChangePeriodType={filterByDate} disabled={isLoading} />
            </PageHeaderStyled>

            <AutomationsContent>
                <MarketingAutomationSummary />
                <EmailMarketingSummary />
            </AutomationsContent>

            <MyPayments page={filterParams.page} onChangePage={changePage} />
        </MySubscriptionContainer>
    );
};
