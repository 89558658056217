import { useMemo } from 'react';
import _first from 'lodash/first';
import { CurrentPlanDetails, CurrentPlanItemDetails } from '@containers';
import { useNumberFunctions, useReplace, useTranslator } from '@hooks';
import { CustomerPlanType } from '@models';
import { useCheckout } from '@providers';
import { GetSendingTechnologyByName } from '~/services/subscriptionService';

export const CurrentAutomationPlanDetails = () => {
    const { hasSubscription, subscription } = useCheckout();

    const { automationPlans } = useTranslator().checkoutPage.subscriptionPlan;
    const { formatNumber } = useNumberFunctions();
    const replace = useReplace();

    const currentSendingTechnologies = useMemo(() => {
        if (hasSubscription === false) {
            return;
        }

        const automationToolData = subscription.subscriptionTools.find(
            (item) => item.type === CustomerPlanType.AutomationTool
        );

        if (!automationToolData) {
            return {
                totalCredits: null
            };
        }

        const technologiesList: Array<CurrentPlanItemDetails> = automationToolData?.sendingTechnologies?.map((item) => {
            return {
                label: String(automationPlans.technology),
                name: GetSendingTechnologyByName(item.sendingTechnology.type, automationPlans.common.sendingTechnology),
                value: item.price
            };
        });

        return {
            technologies: technologiesList ?? [],
            currency: _first(automationToolData.sendingTechnologies)?.currency ?? 'BRL',
            totalCredits: formatNumber(automationToolData.totalCredits)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSubscription, subscription]);

    if (hasSubscription === false) {
        return <></>;
    }

    const creditsAmount: CurrentPlanItemDetails = {
        label: String(automationPlans.creditsAmount),
        value: currentSendingTechnologies.totalCredits
            ? replace(automationPlans.credits, currentSendingTechnologies.totalCredits)
            : String(automationPlans.nullValue)
    };

    return (
        <CurrentPlanDetails
            currentPlanItemDetails={creditsAmount}
            currentPlanItemDetailsList={currentSendingTechnologies.technologies}
            description={String(automationPlans.descriptionPlanChange)}
            currency={currentSendingTechnologies.currency}
        />
    );
};
