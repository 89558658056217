import { RepurchaseSetupSection, TemplateTypeSection } from '@containers';
import { useTranslator } from '@hooks';
import { SubToolIdEnum } from '@models';
import { ManageRepurchaseItemsProvider } from '@providers';
import { PageTitle } from '~/styles/defaultComponentStyles';
import { ContainerStyled } from './styles';

export const ManageRepurchaseItemsTemplate = () => {
    const {
        enums: { subToolIdEnum }
    } = useTranslator();

    return (
        <ManageRepurchaseItemsProvider subToolId={SubToolIdEnum.Repurchase}>
            <ContainerStyled>
                <PageTitle>{String(subToolIdEnum.Repurchase)}</PageTitle>
                <TemplateTypeSection />
                <RepurchaseSetupSection />
            </ContainerStyled>
        </ManageRepurchaseItemsProvider>
    );
};
