import { CustomTrigger } from './customTrigger';
import { PushNotificationContent } from './pushNotificationContent';
import { SendingTechnologyTypeEnum } from './sendingTechnology';
import { SubToolConfiguration } from './subToolConfiguration';
import { ShippingOrderEnum, TrackAnalytics } from './subToolTemplateConfiguration';

export enum MessageSequenceType {
    General = 1,
    Category = 2,
    Product = 3
}

export enum MessageSequenceStepRange {
    Seconds = 0,
    Minutes = 1,
    Hours = 2,
    Days = 3,
    Weeks = 4
}

export type MessageSequence = {
    id: number;
    name: string;
    type: MessageSequenceType;
    identifier?: string;
    subToolConfiguration?: SubToolConfiguration;
    steps?: Array<MessageSequenceStep>;
    customTrigger?: CustomTrigger;
};

export type MessageSequenceStep = {
    id: number;
    name: string;
    trackAnalytics?: TrackAnalytics;
    intervalMinutes: number;
    intervalRange?: MessageSequenceStepRange;
    creationDate?: Date;
    updateDate?: Date;
    messageSequence?: MessageSequence;
    shippingOrder?: ShippingOrderEnum;
    templates?: Array<MessageSequenceStepTemplate>;
    frequencyType?: number;
};

export type MessageSequenceStepTemplate = {
    id: number;
    isActive: boolean;
    isDefault?: boolean;
    baseThemeId?: number;
    sendingTechnology: SendingTechnologyTypeEnum;
    subject: string;
    baseContent: string;
    productContent?: string;
    reserveProductContent?: string;
    recommendationContent?: string;
    reviewContent?: string;
    step?: MessageSequenceStep;
    pushNotificationContent?: PushNotificationContent;
    whatsAppTemplateId?: string;
};

export type MessageSequenceStepUpdate = {
    id: number;
    name: string;
    messageSequenceName?: string;
    trackAnalytics?: TrackAnalytics;
    intervalMinutes: number;
    intervalRange?: MessageSequenceStepRange;
    frequencyType?: number;
    templates?: Array<MessageSequenceStepTemplatePayload>;
};

export type MessageSequenceStepCreate = {
    messageSequenceId: number;
    messageSequenceName: string;
    messageSequenceType: MessageSequenceType;
    messageSequenceIdentifier: string;
    name: string;
    trackAnalytics?: TrackAnalytics;
    intervalMinutes: number;
    intervalRange?: MessageSequenceStepRange;
    frequencyType?: number;
    templates?: Array<MessageSequenceStepTemplatePayload>;
};

export type MessageSequenceStepTemplatePayload = {
    isActive: boolean;
    baseThemeId?: number;
    sendingTechnology: SendingTechnologyTypeEnum;
    subject?: string;
    baseContent?: string;
    productContent?: string;
    reserveProductContent?: string;
    recommendationContent?: string;
    reviewContent?: string;
    whatsAppTemplateId?: string;
};
