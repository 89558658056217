import { createContext, ElementType, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { DialogOptions } from '@hooks';

export type AlertType = 'default' | 'success' | 'error' | 'warning' | 'info';

export type AlertOptions<T = unknown> = DialogOptions & {
    id?: string;
    timeout?: number;
    autoClose?: boolean;
    hideProgressBar?: boolean;
    type?: AlertType;
    onClose?: () => void;
    content?: ElementType | ReactNode;
    data?: T;
};

export type Alert = {
    id: string;
    title: string;
    description?: string;
    createdAt?: Date;
    options?: AlertOptions;
};

type AlertProviderProps = Pick<AlertOptions, 'timeout' | 'type' | 'autoClose' | 'hideProgressBar'> & {
    content: ElementType | ReactNode;
    children?: ReactNode;
};

type AlertOptionsWithoutOpenProps = Omit<AlertOptions, 'open' | 'onOpenChange' | 'isBlurredBackground'>;

type AlertContextData = {
    show: (title: string, description?: string, options?: AlertOptionsWithoutOpenProps) => Alert;
    success: (title: string, description?: string, options?: Omit<AlertOptionsWithoutOpenProps, 'type'>) => Alert;
    warning: (title: string, description?: string, options?: Omit<AlertOptionsWithoutOpenProps, 'type'>) => Alert;
    error: (title: string, description?: string, options?: Omit<AlertOptionsWithoutOpenProps, 'type'>) => Alert;
    info: (title: string, description?: string, options?: Omit<AlertOptionsWithoutOpenProps, 'type'>) => Alert;
    remove: (alert: Alert) => Promise<void>;
    removeAll: (predicate: (value: Alert) => boolean) => void;
    generateAlertId: () => string;
    alerts?: Array<Alert>;
};

const AlertContext = createContext({} as AlertContextData);

export const defaultAlertOptions: AlertOptions = {
    enableOutsideClosed: false,
    autoClose: false,
    hideProgressBar: false,
    timeout: 5_000
};

export const AlertProvider = ({
    content,
    children,
    type = 'default',
    timeout = 5_000,
    hideProgressBar = false,
    autoClose = false
}: AlertProviderProps) => {
    const [alerts, setAlerts] = useState<Array<Alert>>([]);
    const timersId = useRef<Array<NodeJS.Timeout>>([]);

    useEffect(() => {
        const timersIdRef = timersId.current ?? [];

        return () => timersIdRef.forEach(clearTimeout);
    }, []);

    const remove = useCallback(async (alert) => {
        setAlerts((state) => {
            const filteredAlerts = state.filter((currentAlert) => currentAlert.id !== alert.id);
            const removedAlert = { ...alert, options: { ...alert.options, open: false } };

            return [removedAlert, ...filteredAlerts];
        });

        const timeoutToRemoveItem = 250;
        await new Promise((resolve) => {
            const timeoutId = setTimeout(() => {
                setAlerts((state) => state.filter((currentAlert) => currentAlert.id !== alert.id));
                clearTimeout(timeoutId);
                resolve(true);
            }, timeoutToRemoveItem);
        });
    }, []);

    const removeAll = useCallback((predicate: (value: Alert) => boolean) => {
        setAlerts((state) => {
            const removedAlerts = state.filter(predicate);
            return state.filter((item) => !removedAlerts.includes(item));
        });
    }, []);

    const generateAlertId = useCallback(() => {
        return _uniqueId('enviou-alert__alert-');
    }, []);

    const show = useCallback(
        (title: string, description: string = null, options: AlertOptionsWithoutOpenProps = defaultAlertOptions) => {
            const id = options.id ?? generateAlertId();

            const alertOptions = {
                autoClose,
                timeout,
                type,
                hideProgressBar,
                ...defaultAlertOptions,
                ...options
            };

            const alert: Alert = {
                id,
                title,
                description,

                createdAt: new Date(),
                options: {
                    ...alertOptions,
                    open: true,
                    onOpenChange: (isOpen) => {
                        if (isOpen === false) {
                            remove(alert);
                            alertOptions.onClose && alertOptions.onClose();
                        }
                    }
                }
            };

            if (alert.options.autoClose && alert.options.timeout) {
                const timerId = setTimeout(() => {
                    remove(alert);

                    timersId.current.splice(timersId.current.indexOf(timerId), 1);
                }, alert.options.timeout);

                timersId.current.push(timerId);
            }

            setAlerts((state) => {
                const isAlertExists = state.find((item) => item.id === id);

                if (isAlertExists) {
                    return state;
                }

                return [alert, ...state];
            });

            return alert;
        },
        [timeout, type, autoClose, hideProgressBar, remove, generateAlertId]
    );

    const success = useCallback(
        (
            title: string,
            description: string = null,
            options: Omit<AlertOptionsWithoutOpenProps, 'type'> = defaultAlertOptions
        ) => {
            return show(title, description, { ...options, type: 'success' });
        },
        [show]
    );

    const warning = useCallback(
        (
            title: string,
            description: string = null,
            options: Omit<AlertOptionsWithoutOpenProps, 'type'> = defaultAlertOptions
        ) => {
            return show(title, description, { ...options, type: 'warning' });
        },
        [show]
    );

    const error = useCallback(
        (
            title: string,
            description: string = null,
            options: Omit<AlertOptionsWithoutOpenProps, 'type'> = defaultAlertOptions
        ) => {
            return show(title, description, { ...options, type: 'error' });
        },
        [show]
    );

    const info = useCallback(
        (
            title: string,
            description: string = null,
            options: Omit<AlertOptionsWithoutOpenProps, 'type'> = defaultAlertOptions
        ) => {
            return show(title, description, { ...options, type: 'info' });
        },
        [show]
    );

    return (
        <AlertContext.Provider
            value={{ alerts, show, success, warning, error, remove, removeAll, info, generateAlertId }}>
            {children}
            {alerts.map((alert, index) => {
                alert.options.isBlurredBackground = index === 0;

                if (alert.options.content) {
                    const AlertContent = alert.options.content as ElementType;
                    return <AlertContent key={alert.id} {...alert} />;
                }

                const AlertComponent = content as ElementType;
                return <AlertComponent key={alert.id} {...alert} />;
            })}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);
