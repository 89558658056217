import { cloneElement, forwardRef, HTMLProps, isValidElement, ReactNode, MutableRefObject } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useDialogContext } from '~/providers';
import { Button } from '../button';

type DialogTriggerProps = {
    children: ReactNode;
    asChild?: boolean;
};

export const DialogTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & DialogTriggerProps>(
    function DialogTrigger({ children, asChild = false, ...props }, propRef) {
        const context = useDialogContext();
        const childrenRef = (children as { ref: MutableRefObject<ReactNode> })?.ref;
        const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

        if (asChild && isValidElement(children)) {
            return cloneElement(
                children,
                context.getReferenceProps({
                    ref,
                    ...props,
                    ...children.props,
                    'data-state': context.open ? 'open' : 'closed'
                })
            );
        }

        return (
            <Button
                testId='dialog-trigger-button'
                ref={ref}
                data-state={context.open ? 'open' : 'closed'}
                {...context.getReferenceProps(props)}>
                {children}
            </Button>
        );
    }
);
