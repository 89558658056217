import { useCallback, useMemo } from 'react';
import _toNumber from 'lodash/toNumber';
import { Card, CardHeader, CardLink, InfoTooltip, Text, SelectFormGroup, SelectData } from '@components';
import { useNumberFunctions, useReplace, useTranslator } from '@hooks';
import { SendingTechnologyTypeEnum, ToolTypeEnum } from '@models';
import {
    SubscriptionPlanFormData,
    SubscriptionReducerActionTypesEnum,
    useAlert,
    useCheckout,
    useTools
} from '@providers';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { GetSendingTechnologiesByCreditAmount, GetToolByType } from '~/services/subscriptionService';
import { PlanCalculatorModal } from '../../planCalculatorModal';
import { CardBodyStyled } from '../styles';
import { VolumeResourceCreditContentStyled, VolumeResourceCreditTooltipStyled } from './styles';

type VolumeResourceCreditProps = {
    credits: Array<number>;
};

export const SecondStep = ({ credits = [] }: VolumeResourceCreditProps) => {
    const { automationPlans } = useTranslator().checkoutPage.subscriptionPlan;
    const { tools } = useTools();
    const { dispatchSubscriptionFormData, subscriptionFormData, formRef } = useCheckout();
    const { formatNumber } = useNumberFunctions();
    const replace = useReplace();
    const { show } = useAlert();

    const creditVolumeOptions = useMemo(() => {
        const options: SelectData[] = credits?.map((credit) => ({
            id: credit,
            label: replace(automationPlans.steps.second.creditsPerMonth, formatNumber(credit))
        }));

        options.unshift({ id: 0, label: String(automationPlans.steps.second.nullValue) });

        return options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credits]);

    const sendingTechnologies = useMemo(
        () => GetToolByType(tools, ToolTypeEnum.AutomationTool)?.sendingTechnologies ?? [],
        [tools]
    );

    const handleChange = useCallback(
        (value: number | string) => {
            value = _toNumber(value);
            const subscriptionPlan: SubscriptionPlanFormData = subscriptionFormData?.subscriptionPlan ?? {
                sendingTechnologies: null
            };

            const filteredSendingTechnologiesByCreditAmount = GetSendingTechnologiesByCreditAmount(
                sendingTechnologies,
                value
            );

            const emailTechnology = filteredSendingTechnologiesByCreditAmount?.find(
                (item) => item.type === SendingTechnologyTypeEnum.Email
            );
            subscriptionPlan.creditVolume = value;
            subscriptionPlan.sendingTechnologies = [];

            formRef.current.clearField('subscriptionPlan.sendingTechnologies');

            if (emailTechnology) {
                const pushTechnology = filteredSendingTechnologiesByCreditAmount.find(
                    (item) => item.type === SendingTechnologyTypeEnum.Push
                );
                const newSendingTechnologies = [emailTechnology.id, pushTechnology?.id].filter(Boolean);
                subscriptionPlan.sendingTechnologies = newSendingTechnologies;
                formRef.current.setFieldValue('subscriptionPlan.sendingTechnologies', newSendingTechnologies);
            }

            dispatchSubscriptionFormData({
                type: SubscriptionReducerActionTypesEnum.UpdatePlan,
                payload: { subscriptionPlan }
            });
        },
        [subscriptionFormData?.subscriptionPlan, sendingTechnologies, formRef, dispatchSubscriptionFormData]
    );

    return (
        <Card>
            <CardHeader
                bulletNumber={{ show: true, value: '02' }}
                subtitle={
                    <>
                        {String(automationPlans.steps.second.title)}
                        <InfoTooltip>
                            <TooltipContent>
                                <VolumeResourceCreditTooltipStyled>
                                    <Text size='xs'>{String(automationPlans.tooltip.description)}</Text>
                                    <br />
                                    <Text size='xs'>{String(automationPlans.tooltip.detail)}</Text>
                                </VolumeResourceCreditTooltipStyled>
                            </TooltipContent>
                        </InfoTooltip>
                    </>
                }
            />
            <CardBodyStyled>
                <VolumeResourceCreditContentStyled>
                    <SelectFormGroup
                        name='creditVolume'
                        placeholder={String(automationPlans.steps.second.placeholder)}
                        testId='dropdown__credit-volume'
                        options={creditVolumeOptions}
                        onOptionChange={(data) => handleChange(data.id)}
                    />
                    <CardLink
                        text={String(automationPlans.steps.second.creditCalculatorTitle)}
                        onClick={() =>
                            show(null, null, {
                                content: (props) => (
                                    <PlanCalculatorModal
                                        {...props}
                                        toolType={ToolTypeEnum.AutomationTool}
                                        sendingTechnologies={sendingTechnologies}
                                        onSelectPlan={handleChange}
                                    />
                                )
                            })
                        }
                    />
                </VolumeResourceCreditContentStyled>
            </CardBodyStyled>
        </Card>
    );
};
