import styled from 'styled-components';
import { AlertColors } from '~/styles/theme';

export const CardItemContentStyled = styled.div`
    padding: 0.4rem 0;
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: minmax(20rem, auto) minmax(11.2rem, auto) minmax(11.4rem, auto) auto;
    justify-content: space-between;
    min-height: 6.2rem;
`;

export const CardItemFieldStyled = styled.div<{ variant?: AlertColors }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${({ theme }) => theme.space.xxxs};

    h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20rem;
    }

    &.status,
    &.total-sent {
        text-align: center;
    }

    &.status strong {
        color: ${({ theme, variant }) => theme.alertColors[variant]};
    }

    &.total-sent > strong {
        color: ${({ theme }) => theme.colors.purple};
    }
`;

export const EmptyCampaignsStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100%;
    width: 100%;
    gap: 2.4rem;

    strong {
        max-width: 49rem;
        text-align: center;
    }

    & > a {
        button {
            width: 26.8rem;
        }
    }
`;
