import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import { CardBody, CardHeader, DropdownFormGroup, OptionProps, Tooltip, TooltipTrigger } from '@components';
import { useReplace, useTranslator } from '@hooks';
import {
    RepurchaseConsideredPeriodEnum,
    SubTool as SubToolModel,
    SubToolConfigurationStatusEnum as ConfigurationStatus,
    SubToolIdEnum
} from '@models';
import { GetSubToolConfigurationStatusByName } from '~/services/subToolService';
import { SubToolColors } from '~/styles/theme';
import { CardStyled, DropDownFormGroupStyled, HelpIconStyled, TooltipBodyStyled } from './styles';

type SubTool = Pick<SubToolModel, 'name' | 'description' | 'id'>;

type SubToolConfigurationProps = {
    subToolConfigurationStatus?: ConfigurationStatus;
    subTool: SubTool;
    subToolColor?: SubToolColors;
};

export const SubToolConfiguration = ({
    subToolConfigurationStatus,
    subTool,
    subToolColor
}: SubToolConfigurationProps) => {
    const {
        enums,
        subTools: { subToolConfiguration }
    } = useTranslator();
    const replace = useReplace();

    const statusOptions = useMemo(() => {
        if (subToolConfigurationStatus === ConfigurationStatus.SendingsPaused) {
            return [
                {
                    text: GetSubToolConfigurationStatusByName(
                        ConfigurationStatus[subToolConfigurationStatus],
                        enums.subToolConfigurationStatusEnum
                    ),
                    value: subToolConfigurationStatus
                } as OptionProps
            ];
        }

        const options: Array<OptionProps> = Object.keys(ConfigurationStatus)
            .filter(
                (key) =>
                    typeof ConfigurationStatus[key] === 'number' &&
                    ConfigurationStatus[key] !== ConfigurationStatus.SendingsPaused
            )
            .map((value) => {
                const configurationStatus = GetSubToolConfigurationStatusByName(
                    value,
                    enums.subToolConfigurationStatusEnum
                );

                return {
                    text: configurationStatus,
                    value: ConfigurationStatus[value]
                } as OptionProps;
            })
            .sort();

        return orderBy<OptionProps>(options, (option) => option.text);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subToolConfigurationStatus]);

    const periodTypeOptions: Array<OptionProps> = [
        { value: RepurchaseConsideredPeriodEnum.Consumer, text: String(enums.repurchaseConsideredPeriodEnum.consumer) },
        { value: RepurchaseConsideredPeriodEnum.Product, text: String(enums.repurchaseConsideredPeriodEnum.product) }
    ];

    return (
        <CardStyled type='group' subToolColor={subToolColor}>
            <CardHeader
                title={replace(subToolConfiguration.title, { subTool: enums.subToolIdEnum[SubToolIdEnum[subTool.id]] })}
            />
            <CardBody>
                <DropDownFormGroupStyled>
                    {subTool.id === SubToolIdEnum.Repurchase && (
                        <DropdownFormGroup
                            flexGrid={{ desktop: 2, tablet: 4, mobile: 12 }}
                            inputSize='small'
                            label={<RepurchaseConsideredPeriodTooltip />}
                            name='repurchaseConsideredPeriod'
                            options={periodTypeOptions}
                        />
                    )}
                    <DropdownFormGroup
                        flexGrid={{ desktop: 2, tablet: 4, mobile: 12 }}
                        label={String(subToolConfiguration.form.subToolConfigurationStatus.label)}
                        name='status'
                        inputSize='small'
                        disabled={subToolConfigurationStatus === ConfigurationStatus.SendingsPaused}
                        options={statusOptions}
                    />
                </DropDownFormGroupStyled>
            </CardBody>
        </CardStyled>
    );
};

const RepurchaseConsideredPeriodTooltip = () => {
    const {
        subTools: { subToolConfiguration }
    } = useTranslator();
    return (
        <>
            {String(subToolConfiguration.form.repurchaseConsideredPeriod.label)}
            <Tooltip>
                <TooltipTrigger>
                    <HelpIconStyled />
                </TooltipTrigger>
                <TooltipBodyStyled>
                    {String(subToolConfiguration.form.repurchaseConsideredPeriod.tooltip)}
                </TooltipBodyStyled>
            </Tooltip>
        </>
    );
};
