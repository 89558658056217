import { useTranslator } from '@hooks';
import { Card, CardBody, CardHeader } from '~/components/elements';
import { InputFormGroup } from '~/components/forms';
import { Content } from './styles';

export const CustomTriggerConfigCard = () => {
    const {
        subTools: { customTriggerCardName }
    } = useTranslator();

    return (
        <Card type='group' borderColor='customTrigger'>
            <CardHeader title={String(customTriggerCardName.title)}></CardHeader>
            <CardBody>
                <Content>
                    <InputFormGroup
                        name='customTriggerName'
                        inputSize='small'
                        type='text'
                        autoComplete='off'
                        label={String(customTriggerCardName.subtitle)}
                    />
                </Content>
            </CardBody>
        </Card>
    );
};
