import styled, { css } from 'styled-components';
import { desktopOnly } from '~/styles';

type InvoiceDetailsListStyledProps = {
    isDiscount?: boolean;
};

export const InvoiceDetailsStyled = styled.div`
    width: 100%;
    header {
        padding: 3rem 2.4rem 0;
        ${desktopOnly(css`
            padding: 3.6rem 3rem 0;
        `)}
    }

    main {
        ${desktopOnly(css`
            padding: 0 3rem 3rem;
        `)}
        gap: 3.2rem;
    }
`;

export const InvoiceDetailsGroupStyled = styled.div`
    ${desktopOnly(css`
        &:first-child {
            padding-top: 2.4rem;
        }
    `)}

    & > h6 + div {
        margin-top: 1.2rem;
    }
`;

export const InvoiceDetailsTotalStyled = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightPurple};
    padding-bottom: 1.6rem;

    strong {
        display: flex;
        flex-direction: column;
        margin: auto 0;

        span {
            text-align: end;
        }
    }
`;

const invoiceDetailsListItem = css`
    display: flex;
    justify-content: space-between;
`;

export const InvoiceDetailsListStyled = styled.ul<InvoiceDetailsListStyledProps>`
    width: 100%;
    height: 100%;
    margin-top: 1.6rem;

    display: flex;
    flex-direction: column;
    gap: 1.6rem 1rem;

    & > li {
        ${invoiceDetailsListItem}

        & > ul {
            width: 100%;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 1.6rem 1rem;

            li {
                ${invoiceDetailsListItem}
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &:not(:last-child) span:last-child {
            ${({ isDiscount, theme }) =>
                isDiscount &&
                css`
                    color: ${theme.colors.deepCerise};
                `}
        }

        &:last-of-type {
            margin-bottom: 0;
            flex: 1;
            align-items: flex-end;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    button {
        margin-top: 0.8rem;
        width: fit-content;
        margin-left: auto;
    }
`;

export const InvoiceDetailsCheckboxContentStyled = styled.div`
    a > span {
        color: ${({ theme }) => theme.colors.deepCerise};
    }
`;
