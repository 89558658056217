import { CompareDateResult, compareDates, DateFormatEnum } from '@utils';

export type ExportData = {
    fileUrl: string;
    fileName: string;
    downloadDate: Date;
    expirationDate: Date;
};

const LATEST_EXPORT_KEY = 'enviou.latest-exports';

export const getLatestExports = () => {
    const currentExports = (JSON.parse(localStorage.getItem(LATEST_EXPORT_KEY)) as Array<ExportData>) ?? [];
    const exports = currentExports.filter(
        (item) =>
            compareDates(new Date(), new Date(item.expirationDate), DateFormatEnum.DateAndTimeFormatUS) ===
            CompareDateResult.Minor
    );

    const isRemovedExports = currentExports.length !== exports.length;
    if (isRemovedExports) {
        localStorage.setItem(LATEST_EXPORT_KEY, JSON.stringify(exports));
    }

    return exports;
};

export const saveLatestExports = (exports: Array<ExportData>) => {
    localStorage.setItem(LATEST_EXPORT_KEY, JSON.stringify(exports));
};
