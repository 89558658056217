import { MouseEventHandler, ReactNode } from 'react';
import IconClose from '~/assets/svg/controls/icon__close.svg';
import { ModalHeaderStyled } from './styles';

type ModalHeaderProps = {
    children?: ReactNode;
    showCloseButton?: boolean;
    onClose?: () => void;
    closeButtonTestId?: string;
};

export const ModalHeader = ({ children, showCloseButton, onClose, closeButtonTestId, ...props }: ModalHeaderProps) => {
    const handleCloseButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <ModalHeaderStyled {...props}>
            {children}
            {showCloseButton && (
                <button onClick={handleCloseButtonClick} data-testid={closeButtonTestId}>
                    <IconClose />
                </button>
            )}
        </ModalHeaderStyled>
    );
};
