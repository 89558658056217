import { Button, Link } from '@components';
import { useTranslator } from '@hooks';
import { AlertOptions, useAlert } from '@providers';
import { Dialog, DialogClose, DialogDescription, DialogFooter, DialogHeading } from '~/components/elements/dialog';
import { routesName } from '~/locales/route';
import { DialogContentStyled } from './styles';

type ContactImportedSuccessfullyModalProps = {
    options: AlertOptions;
};

export const ContactImportedSuccessfullyModal = ({ options }: ContactImportedSuccessfullyModalProps) => {
    const {
        buttons,
        pages: {
            myAutomations: { contactImportedSuccessfullyModal }
        }
    } = useTranslator();
    const { removeAll } = useAlert();

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(contactImportedSuccessfullyModal.title)}</DialogHeading>
                <DialogDescription>{String(contactImportedSuccessfullyModal.subtitle)}</DialogDescription>
                <DialogFooter>
                    <Button color='purple' buttonSize='small' isOutlined onClick={() => removeAll(() => true)}>
                        {String(buttons.toGoBack)}
                    </Button>
                    <Link href={routesName.tool._statistics} as={routesName.tool.statistics}>
                        <Button color='purple' buttonSize='small' onClick={() => removeAll(() => true)}>
                            {String(buttons.goToStatistics)}
                        </Button>
                    </Link>
                </DialogFooter>
                <DialogClose onClick={() => removeAll(() => true)} />
            </DialogContentStyled>
        </Dialog>
    );
};
