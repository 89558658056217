import { useEffect, useState, useRef, ReactNode } from 'react';
import { HTMLMotionProps, Transition } from 'framer-motion';
import { useSize } from '@hooks';
import { ExpandableGroupStyled } from './styles';

type ExpandableGroupProps = HTMLMotionProps<'div'> & {
    isExpanded: boolean;
    isExpandableGroup?: boolean;
    children?: ReactNode;
    transition: Transition;
};

export const ExpandableGroup = ({
    isExpanded: isOpen = true,
    isExpandableGroup = true,
    children,
    transition,
    ...props
}: ExpandableGroupProps) => {
    const contentRef = useRef<HTMLDivElement>();
    const [height, setHeight] = useState((props.initial as { height: string | number })?.height ?? 0);

    const size = useSize(contentRef);
    const contentHeight = size?.height ? size?.height : 0;

    useEffect(() => {
        if (contentRef.current) {
            const clientHeight = contentRef.current?.getBoundingClientRect()?.height ?? contentRef.current.clientHeight;
            setHeight(clientHeight);
        }
    }, []);

    useEffect(() => {
        if (isExpandableGroup && contentHeight) {
            const clientHeight = contentRef.current?.getBoundingClientRect()?.height ?? contentRef.current.clientHeight;
            const newHeight = contentHeight && contentHeight > clientHeight ? contentHeight : clientHeight;

            setHeight(!isOpen ? 0 : newHeight);
        }
    }, [isOpen, isExpandableGroup, children, contentHeight]);

    return (
        <ExpandableGroupStyled
            animate={{
                height,
                transition
            }}
            {...props}>
            <div ref={contentRef}>{children}</div>
        </ExpandableGroupStyled>
    );
};

ExpandableGroup.defaultProps = {
    transition: {
        ease: 'easeOut',
        duration: 0.3
    },
    initial: { height: 0 }
};
