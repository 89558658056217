import styled, { css } from 'styled-components';
import { Box } from '~/components/elements/box';
import { DialogContent } from '~/components/elements/dialog';
import { mobileAndTabletOnly } from '~/styles/mediaQueries';
import { SubToolColors } from '~/styles/theme';

export const AutomationToolModalStyled = styled.div`
    display: flex;
    gap: 2.4rem;
    max-width: 119rem;
    margin-top: 2.4rem;
    align-items: center;
    flex-direction: column;

    ${mobileAndTabletOnly(css`
        width: 100%;
        max-width: 100%;
    `)}
`;

export const AutomationToolModalVideoContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 47.5rem;
    margin: 0 auto;

    & > div {
        max-height: 26.6rem;
    }

    ${mobileAndTabletOnly(css`
        max-width: 100%;
        width: 100%;
    `)}

    strong {
        margin-top: 2.4rem;
        text-align: center;
    }
`;

export const AutomationToolSolutionsStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.2rem;
    flex-direction: column;

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        max-width: 89.2rem;
        flex-wrap: wrap;
        gap: 0.8rem;
        justify-content: center;

        li {
            padding: 0.9rem 1.6rem 0.6rem;
            background-color: ${({ theme }) => theme.colors.deepCerise};
            color: ${({ theme }) => theme.colors.white};
            border-radius: 38px;
        }
    }
`;

export const DialogContentStyled = styled(DialogContent)`
    position: relative;
    margin-top: 10vh;
    width: 100%;
    max-width: 89.2rem;
    padding: 4rem;

    h2 {
        color: ${({ theme }) => theme.colors.licorice};
        margin-top: 2.4rem;
        text-transform: uppercase;
    }

    p {
        margin-top: ${({ theme }) => theme.space.xs};
        max-width: 73.8rem;
        align-self: center;
    }

    i {
        top: -4rem;
    }
`;

export const SubToolIconStyled = styled.i<{ subToolColor: SubToolColors }>`
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: ${({ theme, subToolColor }) => theme.subToolColors[subToolColor]};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);

    svg {
        width: 7.2rem;
        height: 7.2rem;
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const ModalVideo = styled(Box)`
    padding: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
        width: 100%;
        height: 26.6rem;
    }

    i {
        height: 35.3rem;
        display: inherit;

        svg {
            margin: auto;
            width: 12.2rem;
            height: 12.2rem;
            color: ${({ theme }) => theme.colors.lightPurple};
        }
    }
`;
