import { Children, isValidElement, cloneElement, ReactNode, useCallback, useState, forwardRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { ToggleButtonGroupStylesProps, ToggleButtonGroupStyled } from './styles';
import { ToggleButtonProps } from './toggleButton';

export type ToggleButtonGroupProps = ToggleButtonGroupStylesProps & {
    children: ReactNode;
    name: string;
    value?: Array<string | number>;
    onChange?: (value: Array<string | number>) => void;
    error?: string;
    delayInMilliseconds?: number;
};

type RenderToggleProps = {
    children: ReactNode;
    handleChange: (buttonValue: string | number) => void;
    internalValue: Array<string | number>;
};

const RenderChildren = ({ children, handleChange, internalValue }: RenderToggleProps): JSX.Element => {
    const isSelected = useCallback(
        (buttonValue: string | number): boolean => internalValue?.includes(buttonValue),
        [internalValue]
    );

    return (
        <>
            {Children.map<ReactNode, ReactNode>(children, (child) => {
                if (isValidElement<ToggleButtonProps>(child)) {
                    return cloneElement<ToggleButtonProps>(child, {
                        ...child.props,
                        onChange: handleChange,
                        selected: isSelected(child.props.value)
                    });
                }
            })}
        </>
    );
};

export const ToggleButtonGroup = forwardRef<HTMLDivElement, ToggleButtonGroupProps>(
    ({ children, error, value: initialValue, onChange, delayInMilliseconds = 400, ...props }, ref) => {
        const [internalValue, setInternalValue] = useState<Array<string | number>>(initialValue || []);
        const debounceHandleChange = useCallback(debounce(onChange, delayInMilliseconds), [onChange]); // eslint-disable-line react-hooks/exhaustive-deps

        const handleChange = useCallback(
            (buttonValue: string | number) => {
                const newValue = [...(internalValue ?? [])];

                const valueIndex = newValue?.findIndex((arrayValue) => arrayValue === buttonValue);

                if (valueIndex > -1) {
                    newValue.splice(valueIndex, 1);
                } else {
                    newValue.push(buttonValue);
                }

                setInternalValue(newValue);

                if (onChange) {
                    debounceHandleChange(newValue);
                }
            },
            [internalValue, onChange, debounceHandleChange]
        );

        useEffect(() => {
            setInternalValue(initialValue);
        }, [initialValue]);

        return (
            <ToggleButtonGroupStyled
                {...props}
                ref={ref}
                data-validate={error}
                className={`${error ? 'input-error' : ''}`}>
                <RenderChildren handleChange={handleChange} internalValue={internalValue}>
                    {children}
                </RenderChildren>
            </ToggleButtonGroupStyled>
        );
    }
);

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

export * from './toggleButton';
