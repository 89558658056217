import { useMemo } from 'react';
import { Card, CardHeader, Button, Field } from '@components';
import { useTranslator } from '@hooks';
import { useBillingResponsible, useCheckout } from '@providers';
import { ApplyMask, InputMaskEnum, convertPhoneToString } from '@utils';
import { CurrentSubscriptionCardBodyStyled, RowStyled, FieldStyled } from '~/components/containers/checkout/styles';
import { EditCardBody } from '../styles';

export const CurrentBillingResponsible = () => {
    const { subscription } = useCheckout();
    const { subscriptionData } = useTranslator().checkoutPage;
    const { actions } = subscriptionData;
    const { title, form } = subscriptionData.billingResponsible;
    const { billingResponsible } = subscription.customer;
    const { setIsUpdatingBillingResponsible } = useBillingResponsible();

    const [phone, cellPhone] = useMemo(
        () => [
            convertPhoneToString(billingResponsible?.phone, true),
            convertPhoneToString(billingResponsible?.cellPhone, true)
        ],
        [billingResponsible]
    );

    return (
        <Card type='group'>
            <CardHeader
                title={String(title)}
                actions={
                    <Button
                        color='purple'
                        isOutlined
                        buttonSize='small'
                        testId='button__billing-responsible'
                        onClick={() => setIsUpdatingBillingResponsible(true)}>
                        {actions.edit}
                    </Button>
                }
            />
            <EditCardBody type='group'>
                <Card>
                    <CurrentSubscriptionCardBodyStyled>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 6 }}>
                                <Field name={String(form.name.name)} value={billingResponsible?.name} />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 6 }}>
                                <Field name={String(form.email.name)} value={billingResponsible?.email} />
                            </FieldStyled>
                        </RowStyled>
                        <RowStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 6 }}>
                                <Field
                                    name={String(form.telephone.name)}
                                    value={ApplyMask(phone, InputMaskEnum.phone)}
                                />
                            </FieldStyled>
                            <FieldStyled flexGrid={{ mobileAndTablet: 12, desktop: 6 }}>
                                <Field
                                    name={String(form.cell.name)}
                                    value={ApplyMask(cellPhone, InputMaskEnum.phone)}
                                />
                            </FieldStyled>
                        </RowStyled>
                    </CurrentSubscriptionCardBodyStyled>
                </Card>
            </EditCardBody>
        </Card>
    );
};
