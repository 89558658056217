import { ReactNode, useCallback, useState, useEffect } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { ConfirmDeletionDialog } from '@dialogs';
import {
    useApi,
    UseSubToolOptions,
    useSubToolPage,
    UseSubToolPageData,
    useTranslator,
    useAutomationRuler
} from '@hooks';
import {
    MessageSequence,
    MessageSequenceStep,
    MessageSequenceType,
    PaginatedList,
    SubToolConfiguration,
    SubToolConfigurationStatusEnum,
    SubToolIdEnum,
    CustomTrigger,
    CustomTriggerStatus
} from '@models';
import { ChangeCustomTriggerStatusAsync } from '~/services/customTriggerApiService';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { Alert, useAlert } from './alertProvider';

export type ChangeStatusHandler = (newStatus: SubToolConfigurationStatusEnum, subToolConfigurationId?: number) => void;

export type StepData = {
    subToolId?: SubToolIdEnum;
    messageSequence: {
        identifier?: string;
        type: MessageSequenceType;
    };
    step: MessageSequenceStep;
};

type AutomationRulerContextData = {
    confirmStepRemoval: (data: StepData) => void;
    changeSubToolStatus: ChangeStatusHandler;
    changeCustomTriggerStatus: (newStatus: CustomTriggerStatus, customTriggerId: number) => void;
    duplicateStep: (stepId: number) => void;
    getSubToolPageDetails: (options?: UseSubToolOptions) => UseSubToolPageData;
    subToolConfiguration: SubToolConfiguration;
    automationRuler: ReturnType<typeof useAutomationRuler>;
    isChangingStatus: boolean;
    customTrigger: CustomTrigger;
    isCustomTriggerChangingStatus: boolean;
};

type AutomationRulerProviderProps = {
    children: ReactNode;
    subToolConfiguration?: SubToolConfiguration;
    customTrigger?: CustomTrigger;
    revalidateOnChange?: () => Promise<boolean | MessageSequence | PaginatedList<MessageSequence>>;
    sequenceId?: number;
};

const AutomationRulerContext = createContext({} as AutomationRulerContextData);

export const AutomationRulerProvider = ({
    children,
    subToolConfiguration: subTool,
    customTrigger,
    revalidateOnChange,
    sequenceId
}: AutomationRulerProviderProps) => {
    const {
        dialogs: { template },
        api: { statusError }
    } = useTranslator();

    const [subToolConfiguration, setSubToolConfiguration] = useState(subTool);
    const [customTriggerConfiguration, setCustomTriggerConfiguration] = useState(customTrigger);
    const { show, success, error, remove: closeAlert } = useAlert();

    useEffect(() => {
        if (!subTool.id) {
            return;
        }

        setSubToolConfiguration(subTool);
    }, [subTool]);

    useEffect(() => {
        if (!customTrigger?.id) {
            return;
        }

        setCustomTriggerConfiguration(customTrigger);
    }, [customTrigger]);

    const {
        subTool: { id: subToolId }
    } = subToolConfiguration;

    const { deactivateTemplateAsync, duplicateTemplateAsync, changeStatusAsync, getSubToolPageData } = useSubToolPage();

    const { execute: duplicateStep } = useApi(duplicateTemplateAsync(subToolId), {
        onSuccess: () => {
            revalidateOnChange && revalidateOnChange();
        },
        onError: (exception) => {
            const errorMessage =
                exception instanceof EnviouApiResponseError
                    ? exception.getFirstError('createOrUpdateMessageSequenceStatusError').message
                    : String(statusError.systemErrorStatus.internalError);

            error(errorMessage);
        }
    });

    const { execute: changeSubToolStatus, loading: isChangingStatus } = useApi(changeStatusAsync(subToolId), {
        onSuccess: () => {
            setSubToolConfiguration((state) => ({
                ...state,
                status:
                    state.status === SubToolConfigurationStatusEnum.Inactive
                        ? SubToolConfigurationStatusEnum.Active
                        : SubToolConfigurationStatusEnum.Inactive
            }));
        },
        onError: (exception) => {
            const errorMessage =
                exception instanceof EnviouApiResponseError
                    ? exception.getFirstError('updateSubToolErrorStatus').message
                    : String(statusError.systemErrorStatus.internalError);

            error(errorMessage);
        }
    });

    const { execute: changeCustomTriggerStatus, loading: isCustomTriggerChangingStatus } = useApi(
        ChangeCustomTriggerStatusAsync,
        {
            onSuccess: () => {
                setCustomTriggerConfiguration((state) => ({
                    ...state,
                    status:
                        state.status === CustomTriggerStatus.Inactive
                            ? CustomTriggerStatus.Active
                            : CustomTriggerStatus.Inactive
                }));
            },
            onError: (exception) => {
                const errorMessage =
                    exception instanceof EnviouApiResponseError
                        ? exception.getFirstError('updateSubToolErrorStatus').message
                        : String(statusError.systemErrorStatus.internalError);

                error(errorMessage);
            }
        }
    );

    const removeStep = useCallback(
        async (data: StepData, confirmAlert: Alert = null) => {
            if (!data) {
                return;
            }

            try {
                await deactivateTemplateAsync(subToolId)(data.step.id);
                revalidateOnChange && revalidateOnChange();
                confirmAlert && closeAlert(confirmAlert);

                success(String(template.deleted.success.title), undefined, { enableOutsideClosed: true });
            } catch (exception) {
                const errorMessage =
                    exception instanceof EnviouApiResponseError
                        ? exception.getFirstError('createOrUpdateMessageSequenceStatusError').message
                        : String(statusError.systemErrorStatus.internalError);

                error(errorMessage);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [subToolId, deactivateTemplateAsync, success, error, closeAlert]
    );

    const confirmStepRemoval = useCallback(
        (data: StepData) => {
            const alert = show(String(template.confirmDeletion.title), undefined, {
                content: (props) => (
                    <ConfirmDeletionDialog
                        {...props}
                        onDeleteClick={async () => await removeStep(data, alert)}
                        onToBackClick={() => closeAlert(alert)}
                    />
                )
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [show, closeAlert, removeStep]
    );

    const getSubToolPageDetails = useCallback(
        (options?: UseSubToolOptions) => {
            return getSubToolPageData(subToolId, options);
        },
        [getSubToolPageData, subToolId]
    );

    const automationRuler = useAutomationRuler({
        subToolId,
        hasSubToolConfigured: !!subToolConfiguration.id,
        sequenceId,
        customTriggerInactive: customTriggerConfiguration?.status === CustomTriggerStatus.Inactive
    });

    return (
        <AutomationRulerContext.Provider
            value={{
                subToolConfiguration,
                automationRuler,
                isChangingStatus,
                customTrigger: customTriggerConfiguration,
                isCustomTriggerChangingStatus,
                changeCustomTriggerStatus,
                confirmStepRemoval,
                changeSubToolStatus,
                duplicateStep,
                getSubToolPageDetails
            }}>
            {children}
        </AutomationRulerContext.Provider>
    );
};

export const useAutomationRulerProvider = <Selected,>(selector: (value: AutomationRulerContextData) => Selected) =>
    useContextSelector(AutomationRulerContext, selector);
