import { motion } from 'framer-motion';
import styled from 'styled-components';
import { dataValidateStyle } from '~/styles';

export const HTMLTextEditorContainer = styled(motion.div)`
    ${dataValidateStyle};
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &[data-validate] {
        ::after {
            left: 1rem;
            bottom: 3.15rem;
        }

        ::before {
            left: 3rem;
            bottom: 3.2rem;
        }
    }
`;
