import { Text, CardLink, Button } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { ToolTypeEnum } from '@models';
import { useAlert } from '@providers';
import { SubmitCustomPlanRequestAsync } from '~/services/calculatePlanApiService';
import { FormData } from '.';
import { CustomPlanContentStyled, PlanCalculatorResultBoxStyled, PlanCalculatorResultStyled } from './styles';

type CalculatorResultContentProps = {
    onChangeAnswersClick: () => void;
    totalCalculated: number;
    totalAvailable: number;
    showCustomPlanContent?: boolean;
    storeData: FormData;
    toolType: ToolTypeEnum;
};

export const CalculatorResultContent = ({
    toolType,
    totalAvailable,
    totalCalculated,
    onChangeAnswersClick,
    storeData,
    showCustomPlanContent = false
}: CalculatorResultContentProps) => {
    const {
        buttons,
        dialogs,
        checkoutPage: {
            planCalculatorModal: { resultContent }
        }
    } = useTranslator();
    const { show, removeAll } = useAlert();
    const { formatNumber } = useNumberFunctions();

    const handleSubmitCustomPlanRequest = async () => {
        SubmitCustomPlanRequestAsync({
            toolType,
            campaignsAmountPerMonth: storeData.campaignsAmount,
            numberOfCustomers: storeData.numberOfCustomers,
            storeBankSlipOrderAmountPerMonth: storeData.storeBankSlipOrderAmount,
            storeOrderAmountPerMonth: storeData.storeOrderAmount,
            storeVisitsAmountPerMonth: storeData.storeVisitsAmount,
            totalCreditsRequested: totalCalculated
        }).then(() => {
            show(
                String(dialogs.successes.successfullyCustomPlanRequestSent.title),
                String(dialogs.successes.successfullyCustomPlanRequestSent.description),
                {
                    onClose: () => removeAll(() => true)
                }
            );
        });
    };

    return (
        <PlanCalculatorResultStyled>
            <div>
                <Text as='strong' size='lg'>
                    {String(resultContent.title)}
                </Text>
                <Text size='md'> {String(resultContent.description)}</Text>

                <PlanCalculatorResultBoxStyled>
                    <Text as='strong' size='md'>
                        <span>{String(resultContent.creditsAmount)}</span>
                        <span>{formatNumber(totalAvailable)}</span>
                    </Text>
                </PlanCalculatorResultBoxStyled>
                <CardLink
                    text={String(buttons.changeAnswers)}
                    showArrow={false}
                    isUnderline
                    onClick={onChangeAnswersClick}
                />
                {showCustomPlanContent && (
                    <CustomPlanContentStyled>
                        <Text as='strong' size='lg'>
                            {String(resultContent.customPlan.title)}
                        </Text>
                        <Text>{String(resultContent.customPlan.message)}</Text>
                        <Button color='purple' isOutlined onClick={handleSubmitCustomPlanRequest}>
                            {String(buttons.requestCustomPlan)}
                        </Button>
                    </CustomPlanContentStyled>
                )}
            </div>
        </PlanCalculatorResultStyled>
    );
};
