import styled, { css } from 'styled-components';
import { TableCommonProps, MIN_WIDTH_FIRST_COLUMN, BORDER_RADIUS } from '../styles';

export type TfootStyledProps = Pick<TableCommonProps, 'minWidthFirstColumn' | 'borderRadius'>;

export const TfootStyled = styled.tfoot<TfootStyledProps>`
    ${({ theme, borderRadius = BORDER_RADIUS, minWidthFirstColumn = MIN_WIDTH_FIRST_COLUMN }) => css`
        tr {
            &:nth-child(odd) {
                background-color: ${theme.colors.grayPurple};
            }

            &:nth-child(even) {
                background-color: ${theme.colors.white};
            }

            th {
                text-align: left;
                min-width: ${minWidthFirstColumn};

                font-weight: bold;
                font-size: 1.8rem;
            }
        }

        tr {
            &:last-child {
                th:first-child {
                    border-bottom-left-radius: ${borderRadius};
                }

                td {
                    &:first-child {
                        border-bottom-left-radius: ${borderRadius};
                    }

                    &:last-child {
                        border-bottom-right-radius: ${borderRadius};
                    }
                }
            }

            th {
                img {
                    width: 2rem;
                    height: 2rem;
                    margin-bottom: -0.4rem;
                }

                small {
                    font-weight: normal;
                }
            }

            td {
                color: ${theme.colors.purple};
                font-weight: bold;
                font-size: 1.8rem;
                white-space: nowrap;
            }
        }
    `}
`;
