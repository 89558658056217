import styled from 'styled-components';
import { Card } from '~/components/elements/card';
import { TooltipContent } from '~/components/elements/tooltip/content';

export const CardStyled = styled(Card).attrs((props) => ({
    borderColor: props.theme.subToolColors.repurchase
}))`
    padding: 3.2rem 4rem;

    & > header {
        padding: 0;

        h1 {
            margin-bottom: 0;
        }

        form {
            display: flex;
            flex: 1;
        }
    }

    & > main {
        margin: 0;
        padding: 0;
    }

    &.setup-section {
        & > header {
            min-height: 4rem;
        }
    }
`;

export const TooltipContentStyled = styled(TooltipContent)``;
