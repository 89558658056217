import * as yup from 'yup';
import { useTranslator } from '@hooks';
import { ToolTypeEnum } from '@models';

export const useValidation = (toolType: ToolTypeEnum) => {
    const {
        checkoutPage: { planCalculatorModal }
    } = useTranslator();

    const numberValidate = yup
        .number()
        .required(String(planCalculatorModal.form.required))
        .typeError(String(planCalculatorModal.form.required))
        .nullable();

    const formSchema =
        toolType === ToolTypeEnum.AutomationTool
            ? yup.object({
                  storeVisitsAmount: numberValidate,
                  storeOrderAmount: numberValidate,
                  storeBankSlipOrderAmount: numberValidate
              })
            : yup.object({
                  numberOfCustomers: numberValidate,
                  campaignsAmount: numberValidate
              });

    return {
        formSchema
    };
};
