import { darken, rgba } from 'polished';
import styled, { css, CSSProperties } from 'styled-components';
import { cssFlexGridByDevice, FlexGridValues } from '~/styles';

const DARKEN_PERCENTAGE = 0.09;
const ACTIVE_DARKEN_PERCENTAGE = 0.13;
const SHADOW_TRANSPARENCY = 0.2;

export type ButtonStyledProps = Pick<CSSProperties, 'color' | 'backgroundColor'> & {
    isOutlined?: boolean;
    isIcon?: boolean;
    disabled?: boolean;
    showHover?: boolean;
    flexGrid?: FlexGridValues;
    buttonSize?: 'small' | 'medium' | 'large';
};

type StyleButtonProps = Pick<CSSProperties, 'color' | 'backgroundColor' | 'borderColor'> & {
    isOutlined: boolean;
    showBoxShadow?: boolean;
    colorOutlined?: string;
};

const styleButtonIcon = (isIcon = false) => {
    return (
        isIcon &&
        css`
            padding: 0;
            overflow: hidden;
            width: 4rem;
            height: 4rem;
            border: none;
            border-radius: 50%;
            box-shadow: 1px 1px 6px ${({ theme }) => rgba(theme.colors.lightBlack, SHADOW_TRANSPARENCY)};

            &:focus-visible {
                outline-width: 0.2rem;
            }
        `
    );
};

const styleButton = ({
    isOutlined,
    color,
    backgroundColor,
    colorOutlined,
    showBoxShadow = true,
    borderColor
}: StyleButtonProps) => {
    if (isOutlined) {
        return css`
            ${showBoxShadow &&
            css`
                box-shadow: 1px 1px 6px ${({ theme }) => rgba(theme.colors.lightBlack, SHADOW_TRANSPARENCY)};
            `};
            color: ${colorOutlined || backgroundColor};
            background-color: ${color};
            border-color: ${colorOutlined || backgroundColor};

            &:not(:disabled, :active):hover {
                color: ${color};
                border-color: ${darken(DARKEN_PERCENTAGE, colorOutlined || backgroundColor)};
                background-color: ${darken(DARKEN_PERCENTAGE, backgroundColor)};
            }

            &:active {
                background-color: ${darken(ACTIVE_DARKEN_PERCENTAGE, backgroundColor)};
                color: ${color};
            }
        `;
    }

    return css`
        ${showBoxShadow &&
        css`
            box-shadow: 0.2rem 0.3rem 0.6rem ${({ theme }) => rgba(theme.colors.lightBlack, SHADOW_TRANSPARENCY)};
        `};
        color: ${color};
        background-color: ${backgroundColor};
        border-color: ${borderColor || backgroundColor};

        &:not(:disabled, :active):hover {
            background-color: ${darken(DARKEN_PERCENTAGE, backgroundColor)};
            border-color: ${darken(DARKEN_PERCENTAGE, borderColor || backgroundColor)};
        }

        &:active {
            background-color: ${darken(ACTIVE_DARKEN_PERCENTAGE, backgroundColor)};
        }
    `;
};

const BaseButton = styled.button`
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-family: ${({ theme }) => theme.fonts.primary};
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 0.8rem 1.6rem;
    transition: ease-in-out 0.1s;
    border-style: solid;
    border-width: 0.1rem;
    cursor: pointer;

    &:focus-visible {
        outline-width: 0.1rem;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &:disabled {
        opacity: 0.75;
        cursor: not-allowed;
    }
`;

const smallButtonStyle = css`
    font-size: ${({ theme }) => theme.fontSizes.sm};
    padding: ${({ theme }) => `1rem ${theme.space.md} 0.83rem`};
    line-height: ${({ theme }) => theme.lineHeights.base};

    & > .loading {
        height: 2.1rem;
        width: 2.1rem;
    }
`;

export const DefaultButtonStyled = styled(BaseButton)<ButtonStyledProps>`
    ${({ theme, isOutlined = false, color = theme.colors.lightBlack }) => css`
        ${styleButton({
            isOutlined,
            color,
            backgroundColor: theme.colors.white,
            colorOutlined: theme.colors.lightBlack,
            showBoxShadow: false,
            borderColor: theme.colors.lightPurple
        })};
    `}
    ${({ flexGrid }) => cssFlexGridByDevice(flexGrid)};
    ${({ buttonSize = 'small' }) => buttonSize === 'small' && smallButtonStyle}

    ${({ isIcon }) => styleButtonIcon(isIcon)}
`;

export const ColoredButtonStyled = styled(BaseButton)<ButtonStyledProps>`
    ${({ theme, isOutlined = false, color = theme.colors.white, backgroundColor = theme.colors.greenLeaf }) => css`
        ${styleButton({
            isOutlined,
            color,
            backgroundColor
        })};
    `}
    ${({ flexGrid }) => cssFlexGridByDevice(flexGrid)};
    ${({ buttonSize = 'small' }) => buttonSize === 'small' && smallButtonStyle}

    ${({ isIcon }) => styleButtonIcon(isIcon)}
`;

export const LinkButtonStyled = styled.button.attrs({ type: 'button' })<ButtonStyledProps>`
    ${({ theme, color = theme.default.anchor.color }) => css`
        color: ${color};
        text-decoration: none;
        background: initial;
        border: initial;
        line-height: inherit;
        text-align: start;
        transition: ease-in-out 0.1s;
        font-family: ${theme.fonts.primary};

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        &:disabled {
            opacity: 0.75;
            pointer-events: none;
            cursor: not-allowed;
        }

        &:not(:disabled):hover,
        &:focus {
            cursor: pointer;
        }

        &:active {
            color: ${darken(ACTIVE_DARKEN_PERCENTAGE, color)};
        }

        &:not(:disabled, :active):hover {
            color: ${darken(DARKEN_PERCENTAGE, color)};
        }
    `}

    ${({ flexGrid }) => cssFlexGridByDevice(flexGrid)};
    ${({ buttonSize = 'small' }) => buttonSize === 'small' && smallButtonStyle}
`;
