/* eslint-disable @typescript-eslint/ban-types */
import { useCallback } from 'react';
import { DelimiterProps, replace, ReplaceData } from '@utils';

export const useReplace = <T extends object = {}>() => {
    return useCallback(
        <D extends T = T>(string: string | object, data: ReplaceData<D>, delimiter?: DelimiterProps) =>
            replace(string, data, delimiter),
        []
    );
};
