import { useEffect, useRef, useState } from 'react';
import { CKEditor, CKEditorEventPayload } from 'ckeditor4-react';
import _debounce from 'lodash/debounce';
import Router from 'next/router';
import { EmailTemplateData } from '@models';
import { useField } from '@unform/core';
import { EDITOR_CONFIG } from '@utils';
import { ckeditorDefaultStyle } from './ckeditorContent';
import { HTMLTextEditorContainer } from './styles';

type HTMLTextEditorProps = {
    uniqueName: string;
    formGroupIdentifier: string;
    initialData?: string;
    onDataChange: (newData?: string) => string | null;
    currentHTMLError: string | null;
    setCurrentHTMLError: (errorMessage: string | null) => void;
    formData: EmailTemplateData;
    onError?: (errorMessage: string) => void;
};

const INPUT_TIMEOUT_DEBOUNCE = 1000;

export const HTMLEmailTemplateTextEditor = ({
    uniqueName,
    initialData,
    onDataChange,
    formGroupIdentifier,
    currentHTMLError,
    setCurrentHTMLError,
    formData,
    onError,
    ...props
}: HTMLTextEditorProps) => {
    const ref = useRef(null);
    const [value, setValue] = useState(initialData);

    const { fieldName, registerField, error, defaultValue } = useField(uniqueName);

    const handleChange = (event: CKEditorEventPayload<'change'>) => {
        setCurrentHTMLError(null);
        const currentData = event.editor?.getData();
        const newData = onDataChange(currentData);

        if (newData && newData !== currentData) {
            event.editor?.setData(newData ?? currentData);
        }
    };

    useEffect(() => {
        EDITOR_CONFIG.language = Router.locale;

        if (ckeditorDefaultStyle) {
            EDITOR_CONFIG.contentsCss = String(ckeditorDefaultStyle);
        }
    }, []);

    useEffect(() => {
        setValue(initialData);
    }, [initialData]);

    useEffect(() => {
        registerField({
            name: formGroupIdentifier,
            ref,
            getValue() {
                return {
                    baseHTML: formData.baseHTML,
                    productHTML: formData.productHTML,
                    reserveProductHTML: formData.reserveProductHTML,
                    recommendationHTML: formData.recommendationHTML,
                    reviewHTML: formData.reviewHTML
                };
            }
        });
    }, [ref, formGroupIdentifier, fieldName, registerField, formData]);

    useEffect(() => {
        if (onError && error) {
            onError(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const debouncedOnChange = _debounce(handleChange, INPUT_TIMEOUT_DEBOUNCE);

    return (
        <HTMLTextEditorContainer
            data-validate={currentHTMLError ?? error}
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1, ease: 'easeOut', duration: 0.3 } }}>
            <CKEditor
                name={uniqueName}
                /* NOTE: Key is required for instance to be correctly set, even though CKEditor 4 documentation does
                not mention it. If key is removed, all <HTMLTextEditor /> components across the entire page will refer
                to the same editor, which will probably generate unexpected bugs. */
                key={uniqueName}
                initData={value ?? defaultValue ?? ''}
                onChange={debouncedOnChange}
                config={EDITOR_CONFIG}
                {...props}
            />
        </HTMLTextEditorContainer>
    );
};

export * from './ckeditorContent';
