import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;

    & > div {
        max-width: 50rem;
    }
`;
