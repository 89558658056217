import styled from 'styled-components';

export const ContainerStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-y: auto;

    > div {
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.colors.white};
        padding: 4rem 1.5rem;
    }
`;

export const SMSChatStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 1.5rem;
        background: #fce7ff;
        border-radius: 30px 30px 30px 0;

        p {
            height: auto;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            font-size: 1.5rem;
            text-align: left;
            width: 100%;
            color: black;
        }

        &:last-child {
            width: 30%;
            align-self: end;
            background: #e497ef;
            margin-top: 1rem;
            border-radius: 30px 30px 0 30px;
            padding: 1rem;

            span {
                font-size: 2rem;
            }
        }
    }
`;
