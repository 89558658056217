import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { GetBraintreeAuthorization } from '~/services/braintreeService';

type BraintreeProviderProps = {
    children?: ReactNode;
};

type BraintreeProviderData = {
    authorization: string;
    GetAuthorization: () => void;
};

export const BraintreeContext = createContext({} as BraintreeProviderData);
BraintreeContext.displayName = 'BraintreeContext';

export const BraintreeProvider = ({ children }: BraintreeProviderProps) => {
    const [authorization, setAuthorization] = useState(null);

    const GetAuthorization = useCallback(async () => {
        const response = await GetBraintreeAuthorization();
        setAuthorization(response?.data);
    }, []);

    useEffect(() => {
        if (!authorization) {
            GetAuthorization();
        }
    }, [GetAuthorization, authorization]);

    return (
        <BraintreeContext.Provider value={{ authorization, GetAuthorization }}>{children}</BraintreeContext.Provider>
    );
};

export const useBraintree = () => useContext(BraintreeContext);

export default BraintreeProvider;
