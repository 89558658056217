/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CookieSerializeOptions } from 'cookie';
import { NextPageContext } from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

/**
 * Sets state in a cookie.
 *
 * @param cookieName The name of your cookie.
 * @param initialState The initial state value.
 * @param context NextJS page or API context, null or undefined.
 * @param options Options that we pass down to `cookie` library.
 * @returns Returns a stateful value, and a function to update it.
 */
export function useCookieState<T>(
    cookieName: string,
    initialState?: T,
    context?: Pick<NextPageContext, 'req' | 'res'>,
    options?: CookieSerializeOptions
): [T, Dispatch<SetStateAction<T>>] {
    const [state, setState] = useState<T>(() => {
        const { [cookieName]: cookieValue } = parseCookies(context);

        if (cookieValue && cookieValue !== 'null' && cookieValue !== 'undefined') {
            return JSON.parse(cookieValue) as T;
        } else {
            return initialState;
        }
    });

    useEffect(() => {
        if (state) {
            const oldValue = parseCookies(context)[cookieName];

            if (!oldValue || oldValue !== JSON.stringify(state)) {
                setCookie(context, cookieName, JSON.stringify(state), options);
            }
        } else {
            destroyCookie(context, cookieName, options);
        }
    }, [state]);

    return [state, setState];
}

export default useCookieState;
