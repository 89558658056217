import styled from 'styled-components';

export const ItemConfigurationDetailsStyled = styled.div`
    display: flex;
    gap: 1.6rem;
    align-items: center;

    div:not(.image-content) {
        flex: 1;
        strong {
            color: ${({ theme }) => theme.colors.purple};
            text-overflow: ellipsis;
            margin-bottom: 0.6rem;
            overflow: hidden;
            max-width: 38rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
`;

export const ItemConfigurationDetailsImageStyled = styled.div`
    width: 5rem;
    height: 5rem;
    border: 1px solid ${({ theme }) => theme.colors.lightPurple};
    border-radius: ${({ theme }) => theme.radii.sm};

    svg {
        width: 5rem;
        height: 5rem;
        color: ${({ theme }) => theme.colors.purple};
    }
`;
