import { useCheckout, useBillingData } from '@providers';
import { CreateBillingData } from './createBillingData';
import { CurrentBillingData } from './currentBillingData';

export const BillingData = () => {
    const { hasSubscription } = useCheckout();
    const { isUpdatingBillingData } = useBillingData();

    return hasSubscription && !isUpdatingBillingData ? <CurrentBillingData /> : <CreateBillingData />;
};
