import React, {
    Children,
    createRef,
    isValidElement,
    ReactNode,
    useMemo,
    useState,
    cloneElement,
    useEffect
} from 'react';
import { TabProps } from '@components';
import { usePrevPropsAndState } from '@hooks';
import { TabIndicator } from '../tabIndicator';
import { TabLabelStyled, TabListStyled, TabsContentStyled, TabsGroupLabelsStyled, TabsStyled } from './styles';

type TabsProps = {
    children: ReactNode;
    labels: Array<string>;
    defaultTab?: number;
    scrollTab?: boolean;
    onTabChange?: (tabIndex: number) => void;
};

export const Tabs = ({ children, labels, onTabChange, defaultTab = 0, scrollTab = false }: TabsProps) => {
    if (labels.length !== Children.count(children)) {
        console.error('You must have exactly one label for each child element.');
    }

    const [activeTabIndex, setActiveTabIndex] = useState(defaultTab);
    const { prevState } = usePrevPropsAndState(null, activeTabIndex);

    const tabRefs = useMemo(() => {
        return labels.reduce((previousValue, _, index) => {
            previousValue[index] = createRef<HTMLButtonElement>();
            return previousValue;
        }, {});
    }, [labels]);

    const contentRefs = useMemo(() => {
        if (!scrollTab) {
            return {};
        }

        return Children.toArray(children).reduce((previousValue, _, index) => {
            previousValue[index] = createRef();
            return previousValue;
        }, {});
    }, [children, scrollTab]);

    const scrollTo = (element: HTMLDivElement) => {
        const positionLeft = element.offsetLeft + element.offsetWidth;

        const parent = element.parentNode as HTMLDivElement;
        const parentPositionLeft = parent.offsetLeft + parent.offsetWidth;

        if (positionLeft >= parentPositionLeft + parent.scrollLeft) {
            parent.scroll({ left: positionLeft - parentPositionLeft, behavior: 'smooth' });
        } else if (positionLeft <= parent.offsetLeft + parent.scrollLeft) {
            parent.scroll({
                left: element.offsetLeft - parent.offsetLeft,
                behavior: 'smooth'
            });
        }
    };

    const handleClick = (tabIndex: number) => {
        setActiveTabIndex(tabIndex);

        if (scrollTab) {
            const element = contentRefs[tabIndex];
            if (element) {
                scrollTo(element);
            }
        }

        if (onTabChange) {
            onTabChange(tabIndex);
        }
    };

    useEffect(() => {
        if (prevState !== defaultTab) {
            setActiveTabIndex(defaultTab);
        }
    }, [defaultTab, prevState]);

    const content = useMemo(() => {
        if (scrollTab) {
            return null;
        }

        let content: ReactNode = null;

        Children.forEach(children, (child, index) => {
            if (index === activeTabIndex && isValidElement<TabProps>(child)) {
                content = child;
            }
        });

        return content;
    }, [activeTabIndex, scrollTab, children]);

    useEffect(() => {
        if (contentRefs && scrollTab) {
            const element = contentRefs[activeTabIndex];
            scrollTo(element);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollTab, activeTabIndex]);

    return (
        <TabsStyled>
            <TabsGroupLabelsStyled>
                <TabListStyled role='tablist' aria-orientation='horizontal'>
                    {labels.map((label, index) => (
                        <TabLabelStyled
                            key={index}
                            role='tab'
                            buttonSize='small'
                            ref={tabRefs[index]}
                            aria-selected={index === activeTabIndex}
                            color='default'
                            onClick={() => handleClick(index)}>
                            {label}
                            {activeTabIndex === index && <TabIndicator />}
                        </TabLabelStyled>
                    ))}
                </TabListStyled>
            </TabsGroupLabelsStyled>
            <TabsContentStyled>
                {!scrollTab
                    ? content
                    : Children.map<ReactNode, ReactNode>(children, (child, index) => {
                          if (isValidElement(child)) {
                              return cloneElement(child, {
                                  ...child?.props,
                                  ref: (node: ReactNode) => {
                                      contentRefs[index] = node;
                                  }
                              });
                          }
                      })}
            </TabsContentStyled>
        </TabsStyled>
    );
};
