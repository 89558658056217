import _lowerFirst from 'lodash/lowerFirst';

export const GetTranslatedEnumName = (name: string, translationData: unknown) => {
    return translationData[_lowerFirst(name)];
};

export const GetNumericValuesFromEnum = (enumType: unknown) => {
    return Object.keys(enumType)
        .filter((key) => typeof enumType[key] === 'string')
        .map((value) => Number(value));
};

export const hasEnumKey = (key: string, enumType: unknown) => {
    return Object.keys(enumType)
        .filter((keyValue) => typeof enumType[keyValue] === 'number')
        .map((keyValue) => keyValue.toLowerCase())
        .includes(key?.toLowerCase());
};

export const uncapitalizeEnumKeys = <T>(enumType: T) => {
    const getKeys = () => {
        return Object.keys(enumType)
            .filter((key) => typeof enumType[key] === 'number')
            .map((key) => _lowerFirst(key));
    };

    return getKeys() as Array<keyof typeof enumType>;
};

export const getUncapitalizeEnumKeyByValue = <T>(enumValue: number, enumType: T) => {
    const getKey = () => {
        const enumKey = Object.values(enumType)
            .filter((value) => typeof enumType[value] === 'string')
            .find((value) => value === enumValue) as unknown as keyof typeof enumType;

        if (!enumKey) {
            return null;
        }

        return _lowerFirst(enumType[enumKey] as string);
    };

    return getKey();
};

export const getCapitalizeEnumKey = <T>(keyName: string, enumType: T) => {
    const getKey = () => {
        if (!hasEnumKey(keyName, enumType)) {
            return null;
        }

        const enumKey = Object.keys(enumType)
            .filter((key) => typeof enumType[key] === 'number')
            .find((key) => key.toLowerCase() === keyName.toLowerCase()) as unknown as keyof typeof enumType;

        return enumType[enumKey];
    };

    return getKey();
};
