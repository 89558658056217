import { MutableRefObject } from 'react';
import * as yup from 'yup';
import { captureException } from '@sentry/nextjs';
import { FormHandles } from '@unform/core';

type ValidateFormProps = {
    formRef: MutableRefObject<FormHandles>;
};

export const validateForm = ({ formRef }: ValidateFormProps) => {
    const validate = async <T = unknown>(data: T, validationSchema: yup.AnySchema) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            formRef.current.setErrors({});

            return true;
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));
                formRef.current.setErrors(errorMessages);
            } else {
                captureException(exception);
            }
            return false;
        }
    };

    return { validate };
};
