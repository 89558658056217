import styled from 'styled-components';
import { SubToolListItemContentStyled, SubToolListItemStyled } from '../../statisticsPerSubTool/styles';

export const SendingTechnologyItemStyled = styled(SubToolListItemStyled)`
    border: none;
    padding: 0 2.4rem;
`;

export const SendingTechnologyListItemContentStyled = styled(SubToolListItemContentStyled)`
    grid-template-columns: minmax(26rem, 1fr) repeat(4, minmax(11.6rem, 0.6fr)) minmax(15rem, 1fr);

    & > strong {
        color: ${({ theme }) => theme.colors.lightBlack};
    }

    span:first-child {
        text-align: start;
        align-self: center;
    }
`;

export const SendingTechnologyItemFooterStyled = styled.li`
    grid-column: 1 / span 2;
    padding: 0 2.4rem;

    div {
        padding-top: 1.6rem;
        border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${({ theme }) => theme.space.xxs};

        strong:last-child {
            color: ${({ theme }) => theme.colors.eminencePurple};
        }
    }
`;
