import { ReactNode } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { ManageRepurchaseItemsOptions, useManageRepurchaseItems } from './useManageRepurchaseItems';

type ContextType = ReturnType<typeof useManageRepurchaseItems> | null;
const ManageRepurchaseItemsContext = createContext<ContextType>(null);

export const useManageRepurchaseItemsProvider = <Selected,>(selector: (value: ContextType) => Selected) =>
    useContextSelector(ManageRepurchaseItemsContext, selector);

export const ManageRepurchaseItemsProvider = ({
    children,
    ...options
}: { children: ReactNode } & ManageRepurchaseItemsOptions) => {
    const data = useManageRepurchaseItems(options);
    return <ManageRepurchaseItemsContext.Provider value={data}>{children}</ManageRepurchaseItemsContext.Provider>;
};

export {
    type ItemSort,
    type RepurchaseItemData,
    type RepurchaseItemConfiguration,
    type RepurchaseItemType,
    type RepurchaseItemsQueryParamsState
} from './useManageRepurchaseItems';
