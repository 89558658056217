import { useCallback, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { useRouter } from 'next/router';
import { customLocales } from '@utils';
import { CustomHeader } from './customHeader';
import { DatePickerStyled } from './styles';

type DatePickerProps = ReactDatePickerProps<string, boolean>;

export type CalendarType = 'day' | 'month' | 'year';

export const DatePicker = ({
    onChange,
    selectsRange,
    value,
    locale,
    portalId = 'portal',
    dateFormat = 'P',
    title,
    ...props
}: DatePickerProps) => {
    const [calendarType, setCalendarType] = useState<CalendarType>('day');

    const router = useRouter();
    const currentLocale = locale || customLocales[router.locale];

    const handleCalendarClose = useCallback(() => setCalendarType('day'), []);

    const handleChange = useCallback(
        (date: Date, event: React.SyntheticEvent<unknown> | undefined) => {
            if (!selectsRange || (selectsRange && calendarType === 'day')) {
                onChange && onChange(date, event);
            }
        },
        [selectsRange, calendarType, onChange]
    );

    return (
        <DatePickerStyled
            {...props}
            dateFormat={dateFormat}
            locale={currentLocale}
            value={value}
            portalId={portalId}
            selectsRange={selectsRange}
            shouldCloseOnSelect={calendarType === 'day'}
            showMonthYearPicker={calendarType === 'month'}
            showYearPicker={calendarType === 'year'}
            onChangeRaw={() => setCalendarType((type) => (type === 'year' ? 'month' : 'day'))}
            onChange={handleChange}
            onCalendarClose={handleCalendarClose}
            renderCustomHeader={({ ...params }) => (
                <CustomHeader
                    {...params}
                    onCalendarTypeChange={setCalendarType}
                    calendarType={calendarType}
                    title={title}
                />
            )}
        />
    );
};
