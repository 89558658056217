import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { CardBody, CardText, Pagination, Shimmer, Text } from '@components';
import { useTranslator } from '@hooks';
import { useAlert, useManageRepurchaseItemsProvider } from '@providers';
import { Form } from '@unform/web';
import { isGreaterThanZero } from '@utils';
import { CardHeaderStyled } from '~/components/elements/card/header/styles';
import { StoreHasXMLAsync } from '~/services/storeApiService';
import { CardStyled } from '../styles';
import { ItemConfiguration } from './itemConfiguration';
import { ItemConfigurationListHeader } from './itemConfigurationListHeader';
import { ProductImportFailedModal } from './productImportFailedModal';
import { SearchItemConfigurationForm } from './searchItemConfigurationForm';
import { ItemConfigurationListStyled, SetupConfigurationContentStyled } from './styles';

export const RepurchaseSetupSection = () => {
    const {
        enums: { subToolTemplateConfigurationType },
        pages: {
            manageRepurchaseItem: {
                sections: {
                    setup: {
                        title,
                        listItem: { notFoundItems }
                    }
                }
            }
        }
    } = useTranslator();

    const {
        data,
        paginationData,
        isLoading,
        filter: { params, onPaginationChange }
    } = useManageRepurchaseItemsProvider((context) => ({
        data: context.data,
        paginationData: context.paginationData,
        isLoading: context.isLoading,
        filter: context.filter
    }));

    const { show } = useAlert();

    useEffect(() => {
        if (isLoading || params.itemType === 'general') {
            return;
        }

        const storeHasXml = async () => {
            const response = await StoreHasXMLAsync();
            const hasXml = response.data;

            if (!isGreaterThanZero(data?.items?.length) && !hasXml) {
                show(null, null, { content: ProductImportFailedModal });
            }
        };

        storeHasXml();
    }, [show, data?.items, isLoading, params.itemType]);

    return (
        <CardStyled type='group' className='setup-section'>
            <CardHeaderStyled type='group'>
                <CardText value={`${String(title)} - ${String(subToolTemplateConfigurationType[params.itemType])}`} />
                <AnimatePresence initial={false}>
                    {params.itemType !== 'general' && <SearchItemConfigurationForm />}
                </AnimatePresence>
            </CardHeaderStyled>
            <CardBody>
                <Form onSubmit={() => null}>
                    <SetupConfigurationContentStyled>
                        <ItemConfigurationListStyled>
                            <ItemConfigurationListHeader itemType={params.itemType} />
                            <Shimmer isActive={isLoading}>
                                <AnimatePresence initial={!isLoading}>
                                    {!isLoading && !isGreaterThanZero(data?.items?.length) && (
                                        <Text size='lg' fontWeight='semiBold' className='not-found-items'>
                                            {String(notFoundItems[params.itemType])}
                                        </Text>
                                    )}
                                    {(data.items || []).map((item) => (
                                        <ItemConfiguration
                                            key={`${item.type}-${item.id}`}
                                            data-shimmer
                                            allowExpandableContent={!isLoading}
                                            item={item}
                                        />
                                    ))}
                                </AnimatePresence>
                            </Shimmer>
                        </ItemConfigurationListStyled>
                        <Pagination
                            totalRecords={paginationData.totalItems}
                            pageLimit={paginationData.pageSize}
                            currentPage={paginationData.pageNumber}
                            onPageChange={(pagination) => onPaginationChange(pagination.currentPage)}
                        />
                    </SetupConfigurationContentStyled>
                </Form>
            </CardBody>
        </CardStyled>
    );
};
