import { useCallback, useState, MouseEventHandler } from 'react';
import { Checkbox, Text } from '@components';
import { useTranslator } from '@hooks';
import { SubscriptionPaymentFormData, SubscriptionReducerActionTypesEnum, useCheckout } from '@providers';
import { InvoiceDetailsCheckboxContentStyled } from '../styles';
import { TermsAndConditionModal } from '../termsAndConditionModal';
import { Container } from './styles';

export const AcceptTerms = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { subgroups } = useTranslator().checkoutPage.invoiceDetails;
    const { subscriptionFormData, dispatchSubscriptionFormData } = useCheckout();

    const showModal: MouseEventHandler = useCallback((event) => {
        event.preventDefault();
        setIsModalOpen(true);
    }, []);

    const handleChangeAcceptTerms = useCallback(
        (hasAcceptedTheTerms: boolean) => {
            const subscriptionPayment: SubscriptionPaymentFormData = {
                ...subscriptionFormData?.subscriptionPayment,
                hasAcceptedTheTerms
            };

            dispatchSubscriptionFormData({
                type: SubscriptionReducerActionTypesEnum.UpdatePayment,
                payload: { subscriptionPayment }
            });
        },
        [subscriptionFormData, dispatchSubscriptionFormData]
    );

    return (
        <Container>
            <Checkbox
                enableUnform
                onChange={(event) => handleChangeAcceptTerms(event.target.checked)}
                name='hasAcceptedTheTerms'
                testId='checkbox__accept-terms-and-conditions'>
                <InvoiceDetailsCheckboxContentStyled>
                    <Text size='md' as='span'>
                        {subgroups.acceptTerms.accept}{' '}
                    </Text>
                    <a onClick={showModal}>
                        <Text size='md' as='span'>
                            {subgroups.acceptTerms.termsAndcondition}
                        </Text>
                    </a>
                </InvoiceDetailsCheckboxContentStyled>
            </Checkbox>

            <TermsAndConditionModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </Container>
    );
};
