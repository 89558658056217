import styled, { css } from 'styled-components';

export const MenuStyled = styled.ul`
    outline: none;
    overflow: hidden;
    width: max-content;
    z-index: 999;
    border-radius: 0.4rem;
    background-color: ${({ theme }) => theme.colors.grayPurple};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    display: flex;
    flex-direction: column;
    list-style: none;
`;

export const MenuItemStyled = styled.li<{ isRoot: boolean }>`
    border-radius: 0.2rem;
    cursor: pointer;
    transition: background-color 0.1s ease-out;

    & > button {
        cursor: pointer;
    }

    & > button,
    & > a {
        font-size: ${({ theme }) => theme.fontSizes.md};
        font-family: ${({ theme }) => theme.fonts.primary};
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.licorice};
        transition: color 0.1s ease, text-shadow 0.1s ease;
        background: none;
        border: none;
        align-items: center;
    }

    ${({ isRoot }) =>
        isRoot &&
        css`
            & > button,
            & > a {
                padding: 0 1.6rem;
                align-items: center;
                line-height: 3.2rem;
                & > span {
                    margin-left: 1rem;
                }
            }

            &[data-open],
            &:hover {
                & > button,
                & > a {
                    color: ${({ theme }) => theme.colors.deepCerise};
                    text-shadow: -0.3px -0.3px 0 currentColor, 0.3px 0.3px currentColor;
                }
            }
        `}

    ${({ isRoot }) =>
        !isRoot &&
        css`
            outline: none;
            & > button,
            & > a {
                display: flex;
                width: 100%;
                text-align: left;
                min-width: 28rem;
                margin: 0;
                outline: 0;
                padding: 1.3rem 1.6rem;
                gap: 0.8rem;
            }

            &[data-open],
            &:focus,
            &:not([disabled]):active &:hover {
                background-color: ${({ theme }) => theme.colors.darkenedPurple};

                & > button,
                & > a {
                    color: ${({ theme }) => theme.colors.white};

                    svg {
                        color: ${({ theme }) => theme.colors.white};
                    }
                }
            }
        `}

    &[role="separator"] {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightPurple};
        margin: 0 1.6rem;
        background-color: transparent;
    }
`;

export const ArrowContentStyled = styled.span`
    & > svg {
        width: 1rem;
        height: 1rem;
        color: ${({ theme }) => theme.colors.darkenedPurple};
        transition: all 0.1s ease;
    }

    &.icon__right {
        margin-left: auto;
    }
`;
