import Image, { ImageProps } from 'next/image';
import { blurInBase64 } from '@utils';
import IconProfile from '~/assets/svg/icons/icon__profile.svg';

type ProfileImageProps = {
    src: string;
    width: number | string;
    height: number | string;
} & Omit<ImageProps, 'src' | 'width' | 'height' | 'layout'>;

export const ImageProfile = ({ src, alt, width, height, ...props }: ProfileImageProps) => (
    <div>
        {src ? (
            <Image
                {...props}
                src={src}
                alt={alt}
                width={100}
                height={100}
                quality={100}
                placeholder='blur'
                blurDataURL={blurInBase64(width, height)}
                layout='responsive'
                objectFit='cover'
            />
        ) : (
            <IconProfile title={alt} width={width} height={height} />
        )}
    </div>
);
