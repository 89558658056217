import styled from 'styled-components';

export const ContainerStyled = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    position: relative;

    .rule-item:before,
    .rule-item:after,
    .add-rule-dropdown:after {
        transition: all 0.15s ease-out;
    }

    .logical-operator:hover {
        & + .rule-group {
            .rule-item:before,
            .rule-item:after,
            .add-rule-dropdown:after {
                opacity: 1;
            }
        }
    }
`;
