import { useNumberFunctions, useReplace, useTranslator } from '@hooks';
import { SendingTechnologyTypeEnum } from '@models';
import { defaultHeadingStyle, Text } from '~/components/elements';
import { TrackStatisticData } from '~/services/statisticsService';
import { PercentageValueStyled, ValueStyled } from '../../statisticsPerSubTool/styles';
import { SendingTechnologyContentStyled } from '../styles';
import {
    SendingTechnologyItemStyled,
    SendingTechnologyListItemContentStyled,
    SendingTechnologyItemFooterStyled
} from './styles';

type SendingTechnologyContentProps = {
    className?: string;
    subToolName: string;
    showRepurchaseRate?: boolean;
    repurchasePercentage?: number;
    statistics: {
        data: Array<TrackStatisticData>;
        sendingTechnologyType: SendingTechnologyTypeEnum;
        sendingTechnologyName: string;
    }[];
};

export const SendingTechnologyContent = ({
    subToolName,
    className,
    repurchasePercentage,
    statistics = [],
    showRepurchaseRate = false
}: SendingTechnologyContentProps) => {
    const {
        pages: {
            automationStatistics: { sendingTechnologyStatisticsModal }
        },
        common: {
            lists: { emptyLabel }
        }
    } = useTranslator();

    const { formatNumber, formatCurrency, formatPercentage } = useNumberFunctions();
    const replace = useReplace();

    const hasItems = statistics?.length > 0;

    return (
        <SendingTechnologyContentStyled>
            {statistics.map((statistic) => {
                return (
                    <SendingTechnologyItemStyled key={statistic.sendingTechnologyType}>
                        <SendingTechnologyListItemContentStyled className={className} data-shimmer>
                            <ValueStyled>{statistic.sendingTechnologyName}</ValueStyled>
                            {statistic.data.map((item) => {
                                const isSaleType = item.type === 'sale';

                                const value = isSaleType
                                    ? `${formatCurrency(item.amount)} (${formatNumber(item.count)})`
                                    : formatNumber(item.count);

                                return (
                                    <span key={item.type}>
                                        <ValueStyled>{value}</ValueStyled>
                                        <PercentageValueStyled>
                                            {item.percentage > 0 ? formatPercentage(item.percentage) : '-'}
                                        </PercentageValueStyled>
                                    </span>
                                );
                            })}
                        </SendingTechnologyListItemContentStyled>
                    </SendingTechnologyItemStyled>
                );
            })}
            {!hasItems && (
                <SendingTechnologyItemStyled>
                    <SendingTechnologyListItemContentStyled data-shimmer className={className}>
                        <Text size='sm'>{String(emptyLabel)}</Text>
                    </SendingTechnologyListItemContentStyled>
                </SendingTechnologyItemStyled>
            )}
            {showRepurchaseRate && hasItems && (
                <SendingTechnologyItemFooterStyled>
                    <div data-shimmer>
                        <Text size='md'>{String(sendingTechnologyStatisticsModal.subToolRate.description)}</Text>
                        <Text as='strong' size='md'>
                            {replace(String(sendingTechnologyStatisticsModal.subToolRate.title), subToolName)}
                        </Text>
                        <Text as='strong' {...defaultHeadingStyle.h3} data-shimmer className={className}>
                            <span>{formatPercentage(repurchasePercentage)}</span>
                        </Text>
                    </div>
                </SendingTechnologyItemFooterStyled>
            )}
        </SendingTechnologyContentStyled>
    );
};
