import { addMinutes, addMonths } from 'date-fns';
import _isNumber from 'lodash/isNumber';
import { GetServerSidePropsContext } from 'next';
import { parseCookies, setCookie } from 'nookies';
import { SubscriptionStatusEnum, SubscriptionVersion } from '@models';
import { isProduction, LAST_DATE_QUERY_SUBSCRIPTION_STATUS_DATE_COOKIENAME } from '@utils';
import { GetCurrentSubscriptionDetailsAsync, CurrentSubscriptionDetailsData } from './subscriptionApiService';

const MIN_TIME_IN_MINUTES_TO_REQUERY_STATUS = 1;
const MONTHS_TO_CANCEL_SUBSCRIPTION = 3;

export type SubscriptionStatusDetails = {
    isCanceled: boolean;
    isPlanSuspense: boolean;
    hasSubscription: boolean;
};

export const CheckIfInvoiceIsDueForMoreThanThreeMonths = ({
    status,
    version,
    nextInvoiceDate
}: CurrentSubscriptionDetailsData) => {
    return (
        status === SubscriptionStatusEnum.CanceledByNonpayment &&
        version < SubscriptionVersion.v3_5 &&
        addMonths(new Date(nextInvoiceDate), MONTHS_TO_CANCEL_SUBSCRIPTION) < new Date()
    );
};

export const getSubscriptionStatusDetails = (status: SubscriptionStatusEnum): SubscriptionStatusDetails => {
    if (!_isNumber(status)) {
        return {
            hasSubscription: false,
            isCanceled: false,
            isPlanSuspense: false
        };
    }

    if (
        status === SubscriptionStatusEnum.CanceledByNonpayment ||
        status === SubscriptionStatusEnum.ErrorProcessingRecurrence
    ) {
        return {
            isCanceled: false,
            isPlanSuspense: true,
            hasSubscription: true
        };
    }

    if (status === SubscriptionStatusEnum.Canceled) {
        return {
            isCanceled: true,
            isPlanSuspense: false,
            hasSubscription: true
        };
    }

    return {
        isCanceled: false,
        isPlanSuspense: false,
        hasSubscription: true
    };
};

export const CheckSubscriptionStatusAsync = async (context: GetServerSidePropsContext) => {
    const {
        [LAST_DATE_QUERY_SUBSCRIPTION_STATUS_DATE_COOKIENAME]: lastDateQuerySubscriptionStatusDate,
        currentSubscription = null
    } = parseCookies(context);

    if (
        !lastDateQuerySubscriptionStatusDate ||
        addMinutes(new Date(lastDateQuerySubscriptionStatusDate), MIN_TIME_IN_MINUTES_TO_REQUERY_STATUS) < new Date()
    ) {
        const response = await GetCurrentSubscriptionDetailsAsync();
        const { status } = response.data ?? {};

        let currentStatus = status;

        if (CheckIfInvoiceIsDueForMoreThanThreeMonths(response.data)) {
            currentStatus = SubscriptionStatusEnum.Canceled;
        }

        setCookie(context, LAST_DATE_QUERY_SUBSCRIPTION_STATUS_DATE_COOKIENAME, new Date().toISOString(), {
            secure: isProduction(),
            sameSite: 'strict',
            path: '/'
        });

        setCookie(context, 'currentSubscription', JSON.stringify(response.data), {
            secure: isProduction(),
            sameSite: 'strict',
            path: '/'
        });

        return { details: getSubscriptionStatusDetails(currentStatus), subscription: response.data };
    } else {
        const subscription = (JSON.parse(currentSubscription) ?? {}) as CurrentSubscriptionDetailsData;

        return {
            details: getSubscriptionStatusDetails(_isNumber(subscription?.status) ? Number(subscription.status) : null),
            subscription
        };
    }
};
