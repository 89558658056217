import { memo } from 'react';
import { Text, defaultHeadingStyle } from '@components';
import { useNumberFunctions, useReplace } from '@hooks';
import { ToolStatisticData } from '~/services/statisticsService';
import { SumaryStyled } from './styles';

const MINIMUM_VALUE_TO_COMPRESS_THE_SALES_TATOAL = 1_000_000;

type SummaryProps = {
    toolStatistic: ToolStatisticData;
};

const SummaryComponent = ({ toolStatistic, ...props }: SummaryProps) => {
    const { formatNumber, formatCurrency, formatPercentage } = useNumberFunctions();
    const replace = useReplace();

    const isSaleType = toolStatistic.type === 'sale';

    const count = formatNumber(toolStatistic.count);
    const compactCount = formatNumber(toolStatistic.count, { notation: 'compact' });

    const amount = formatCurrency(toolStatistic.amount);
    const compactAmount = formatCurrency(toolStatistic.amount, null, {
        notation: toolStatistic.amount > MINIMUM_VALUE_TO_COMPRESS_THE_SALES_TATOAL ? 'compact' : 'standard'
    });

    return (
        <SumaryStyled key={toolStatistic.type} {...props}>
            <Text as='span' size='xs'>
                {toolStatistic.icon}
                {toolStatistic.title}
            </Text>
            <Text as='strong' {...defaultHeadingStyle.h3}>
                {isSaleType && (
                    <span title={amount}>
                        {compactAmount} <span title={`${count} ${toolStatistic.title}`}>({compactCount})</span>
                    </span>
                )}
                {!isSaleType && <span {...defaultHeadingStyle.h3}>{count}</span>}
            </Text>
            {!!toolStatistic.percentage && (
                <Text as='span' size='xxs'>
                    {replace(toolStatistic.percentageLabel, formatPercentage(toolStatistic.percentage))}{' '}
                </Text>
            )}
        </SumaryStyled>
    );
};

export const Summary = memo(SummaryComponent);
