import styled, { css } from 'styled-components';
import { desktopOnly } from '~/styles/mediaQueries';
import { CardType, cardPadding, cardPaddingDesktop, cardGroupPaddingDesktop } from '../styles';

export type CardFooterStyledProps = {
    type?: CardType;
};

export const CardFooterStyled = styled.footer<CardFooterStyledProps>`
    ${({ theme, type }) => css`
        padding: ${cardPadding};
        border-top: 0.1rem solid ${theme.colors.lightPurple};

        ${desktopOnly(css`
            padding: ${type === 'group' ? cardGroupPaddingDesktop : cardPaddingDesktop};
        `)}
    `}
`;
