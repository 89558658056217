import { useCheckout, useBillingResponsible } from '@providers';
import { CreateBillingResponsible } from './createBillingResponsible';
import { CurrentBillingResponsible } from './currentBillingResponsible';
import { BillingResponsibleStyled } from './styles';

export const BillingResponsible = () => {
    const { hasSubscription } = useCheckout();
    const { isUpdatingBillingResponsible } = useBillingResponsible();

    return (
        <BillingResponsibleStyled>
            {hasSubscription && !isUpdatingBillingResponsible ? (
                <CurrentBillingResponsible />
            ) : (
                <CreateBillingResponsible />
            )}
        </BillingResponsibleStyled>
    );
};
