import isEmpty from 'lodash/isEmpty';
import { isIsoOrUtcDateString } from '@utils';

/**
 * Returns the first keypath of a given object, with each key separated by a dot.
 *
 * @example
 * > const myObject = { field1: { field2: 'my_value' } };
 * > firstKeyPath(myObject);
 * 'field1.field2'
 */
export const firstKeyPath = (objectToAnalyze: Record<string, unknown> | string): string => {
    let path = '';
    const current = objectToAnalyze;

    if (typeof current === 'object') {
        const objectKeys = Object.keys(current);
        if (objectKeys?.length > 0 && typeof objectKeys[0] !== 'undefined') {
            path += objectKeys[0];
        }
    }

    const nextValues = Object.values(current);

    if (nextValues?.length <= 0 && typeof nextValues[0] === 'undefined') {
        return path;
    }

    const next = nextValues[0] as Record<string, unknown> | string;

    if (typeof next === 'object' && !isEmpty(next)) {
        path += '.';
    } else {
        return path;
    }

    return path + firstKeyPath(next);
};

/**
 * It loops through the entire object and converts the dates into an instance of `Date()`
 * @param {unknown} object The object to be parsed
 */
export const parseDateTypes = (object: unknown) => {
    if (object === null || object === undefined || typeof object !== 'object') {
        return object;
    }

    for (const key of Object.keys(object)) {
        const value = object[key];

        if (isIsoOrUtcDateString(value)) {
            object[key] = new Date(value);
        } else if (typeof value === 'object') {
            parseDateTypes(value);
        }
    }

    return object;
};

/**
 * Parse AXIOS responde
 * @param {unknown} rawObject The object to be parsed
 */
export const parseResponse = (rawObject: unknown) => {
    let object = null;

    try {
        object = JSON.parse(rawObject as string);

        return parseDateTypes(object);
    } catch (exception) {
        return rawObject;
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const propertiesOf = <O>(_obj: O | undefined = undefined) => {
    return <T extends keyof O>(name: T) => {
        return name;
    };
};
