import styled from 'styled-components';

type FunnelStageStyledProps = {
    backgroundColor?: string;
};

export const FunnelChartStyled = styled.div`
    margin: 0;
    padding: 0;
    filter: url('#filter__rounded-border_svg__rounded-border');
`;

export const FunnelStageStyled = styled.div<FunnelStageStyledProps>`
    display: flex;
    flex-direction: column;
    clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);
    text-align: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
