import { HTMLAttributes, MouseEventHandler, useRef } from 'react';
import { useTranslator } from '@hooks';
import { Button } from '../button';
import { CodeBlockStyled } from './styles';

type CodeBlockProps = HTMLAttributes<HTMLDivElement>;

export const CodeBlock = ({ children, ...props }: CodeBlockProps) => {
    const codeRef = useRef<HTMLElement>();
    const { buttons } = useTranslator();

    const handleCopy: MouseEventHandler<HTMLButtonElement> = (event) => {
        navigator.clipboard.writeText(codeRef.current?.innerText);
        const button = event.currentTarget;
        button.innerText = String(buttons.copied);

        const timeout = 600;
        setTimeout(() => {
            button.innerText = String(buttons.copy);
        }, timeout);
    };

    return (
        <CodeBlockStyled {...props}>
            <pre>
                <code ref={codeRef}>{children}</code>
            </pre>
            <Button buttonSize='small' onClick={handleCopy}>
                {String(buttons.copy)}
            </Button>
        </CodeBlockStyled>
    );
};
