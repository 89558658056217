import { ReactNode, useCallback, useMemo } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { createContext, useContextSelector } from 'use-context-selector';
import { useFetch, useQueryParams, useTranslator } from '@hooks';
import { DetailedStatisticsStatus, GeneralStatistics, SendingTechnologyTypeEnum, SubToolIdEnum } from '@models';
import { captureException } from '@sentry/nextjs';
import {
    scrollTo,
    SortEnum,
    DateFilterOptionsWithCustomTimePeriod,
    isCustomizeTimePeriodType,
    isDateType
} from '@utils';
import {
    GetGeneralStatisticsAsync,
    getGeneralStatisticsUrl,
    GetGeneralCustomTriggerStatisticsAsync,
    getGeneralCustomTriggerStatisticsUrl
} from '~/services/statisticsApiService';
import {
    GeneralStatisticsDetails,
    GenerateGeneralStatisticsDetailsResume,
    GenerateCustomTriggerGeneralStatisticsDetailsSummary,
    CustomTriggerStatisticData
} from '~/services/statisticsService';
import {
    calculateAndFormatDateParams,
    createAutomationStatisticsFilterParamsState,
    createDateParams,
    isCustomerParamsChanged,
    isDateParamsChanged
} from './queryParamsUtils';

export type AutomationStatisticsFilterParamsState = {
    startDate?: Date;
    endDate?: Date;
    periodType?: DateFilterOptionsWithCustomTimePeriod;
    search?: string;
    sortByDate?: SortEnum;
    subTool?: SubToolIdEnum;
    status?: DetailedStatisticsStatus;
    page?: number;
    customTriggerId: number;
};

export type QueryParamsType = Partial<Record<keyof AutomationStatisticsFilterParamsState, string>>;

export type HandleFilterCustomerParams = (
    newParams: Omit<QueryParamsType, 'startDate' | 'endDate' | 'periodType'>
) => void;

type AutomationStatisticsProviderContextData = {
    filterByDate: (dateType: DateFilterOptionsWithCustomTimePeriod, startDate?: Date, endDate?: Date) => void;
    filterParams?: AutomationStatisticsFilterParamsState;
    allowSearch: boolean;
    subscriptionSendingTechnologies?: Array<SendingTechnologyTypeEnum>;
    filterByCustomerParams: HandleFilterCustomerParams;
    generalStatistics: {
        data: GeneralStatisticsDetails;
        isLoading: boolean;
    };
    generalCustomTriggerStatistics: {
        data: Array<CustomTriggerStatisticData>;
        isLoading: boolean;
    };
};

type AutomationStatisticsProviderProps = {
    subscriptionSendingTechnologies?: Array<SendingTechnologyTypeEnum>;
    children: ReactNode;
};

const defaultGeneralStatistics = {
    clickCount: 0,
    openingCount: 0,
    rejectionCount: 0,
    saleCount: 0,
    sentCount: 0,
    soldAmount: 0,
    subToolStatisticsCollection: []
} as GeneralStatistics;

const AutomationStatisticsProviderContext = createContext({} as AutomationStatisticsProviderContextData);

/** Time the swr will automatically requery */
const MINUTES_TO_REFRESH_DATA = 5;

/** Time in minutes swr will wait for query key before querying again */
const MINUTES_TO_REQUERY_DATA = 1;

export const CUSTOMER_SECTION_ID = 'statisticsPerCustomerSection';

export const AutomationStatisticsProvider = ({
    children,
    subscriptionSendingTechnologies = []
}: AutomationStatisticsProviderProps) => {
    const {
        pages: {
            automationStatistics: { summaryStatistics }
        },
        enums: { subToolIdEnum }
    } = useTranslator();

    const { updateQueryParams, queryParams } = useQueryParams<QueryParamsType>();

    const queryParamsState = useMemo(() => createAutomationStatisticsFilterParamsState(queryParams), [queryParams]);

    const allowSearch = !isCustomizeTimePeriodType(queryParams.periodType) && !!queryParamsState.endDate;

    const generalStatisticsResponse = useFetch(
        allowSearch && {
            url: getGeneralStatisticsUrl,
            startDate: queryParamsState.startDate,
            endDate: queryParamsState.endDate
        },
        async ({ startDate, endDate }, signal) => {
            const statisticsData = (await GetGeneralStatisticsAsync({ startDate, endDate }, signal)).data;
            return GenerateGeneralStatisticsDetailsResume(statisticsData, summaryStatistics, subToolIdEnum);
        },
        {
            keepPreviousData: true,
            fallbackData: GenerateGeneralStatisticsDetailsResume(
                defaultGeneralStatistics,
                summaryStatistics,
                subToolIdEnum
            ),
            refreshInterval: minutesToMilliseconds(MINUTES_TO_REFRESH_DATA),
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_REQUERY_DATA),
            onError: (error) => captureException(error)
        }
    );

    const generalCustomTriggerStatisticsResponse = useFetch(
        allowSearch && {
            url: getGeneralCustomTriggerStatisticsUrl,
            startDate: queryParamsState.startDate,
            endDate: queryParamsState.endDate
        },
        async ({ startDate, endDate }, signal) => {
            const customTriggerStatistics = (
                await GetGeneralCustomTriggerStatisticsAsync({ startDate, endDate }, signal)
            ).data;

            return GenerateCustomTriggerGeneralStatisticsDetailsSummary(customTriggerStatistics);
        },
        {
            keepPreviousData: true,
            fallbackData: [],
            refreshInterval: minutesToMilliseconds(MINUTES_TO_REFRESH_DATA),
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_REQUERY_DATA),
            onError: (error) => captureException(error)
        }
    );

    const filterByDate = useCallback(
        (periodType: DateFilterOptionsWithCustomTimePeriod, startDate: Date = null, endDate: Date = null) => {
            if (generalStatisticsResponse.isLoading && generalCustomTriggerStatisticsResponse.isLoading) {
                return;
            }

            const isDateChanged = isDateParamsChanged({ periodType, startDate, endDate }, queryParamsState);

            if (!isDateChanged) {
                return;
            }

            if (!isCustomizeTimePeriodType(periodType)) {
                if (!isDateType(periodType)) {
                    updateQueryParams({ ...createDateParams(periodType), page: null });
                    return;
                }

                const page = endDate ? '1' : String(queryParamsState.page);
                updateQueryParams({ ...calculateAndFormatDateParams(periodType, startDate, endDate), page });
                return;
            }

            if (!isDateType(queryParamsState.periodType)) {
                updateQueryParams({ ...createDateParams(periodType), page: null });
            }
        },
        [
            queryParamsState,
            generalStatisticsResponse.isLoading,
            updateQueryParams,
            generalCustomTriggerStatisticsResponse.isLoading
        ]
    );

    const filterByCustomerParams: HandleFilterCustomerParams = useCallback(
        (newParams) => {
            if (generalStatisticsResponse.isLoading && generalCustomTriggerStatisticsResponse.isLoading) {
                return;
            }

            if (isCustomerParamsChanged(newParams, queryParams)) {
                scrollTo(`#${CUSTOMER_SECTION_ID}`, {
                    scrollPosition: 'start',
                    offsetTop: -100,
                    isScrollIntoView: false
                });
                updateQueryParams(newParams);
            }
        },
        [
            updateQueryParams,
            queryParams,
            generalStatisticsResponse.isLoading,
            generalCustomTriggerStatisticsResponse.isLoading
        ]
    );

    return (
        <AutomationStatisticsProviderContext.Provider
            value={{
                filterParams: queryParamsState,
                generalStatistics: {
                    data: generalStatisticsResponse.data,
                    isLoading: generalStatisticsResponse.isLoading
                },
                generalCustomTriggerStatistics: {
                    data: generalCustomTriggerStatisticsResponse.data,
                    isLoading: generalCustomTriggerStatisticsResponse.isLoading
                },
                filterByDate,
                filterByCustomerParams,
                allowSearch,
                subscriptionSendingTechnologies
            }}>
            {children}
        </AutomationStatisticsProviderContext.Provider>
    );
};

export const useAutomationStatisticsProvider = <Selected,>(
    selector: (value: AutomationStatisticsProviderContextData) => Selected
) => useContextSelector(AutomationStatisticsProviderContext, selector);

export * from './queryParamsUtils';
