/* istanbul ignore file */
import { toBase64 } from '@utils';
/**
 * Generates a blur effect to display while the image is not loading
 * @param width The width of the content
 * @param height The height of the content
 * @returns The blur effect in SVG
 */
export const shimmerSVG = (width: string | number, height: string | number): string => `
 <svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
     <linearGradient id="g">
       <stop stop-color="#eff1f3" offset="4%" />
       <stop stop-color="#e2e2e2" offset="25%" />
       <stop stop-color="#eff1f3" offset="36%" />
     </linearGradient>
   </defs>
   <rect width="${width}" height="${height}" fill="#aaa" />
   <rect id="r" width="${width}" height="${height}" fill="url(#g)" />
   <animate xlink:href="#r" attributeName="x" from="-${width}" to="${width}" dur="0.5s" repeatCount="indefinite"  />
 </svg>`;

/**
 * Generates a blur effect to display while the image is not loading
 * @param width The width of the content
 * @param height The height of the content
 * @returns The blur effect to be injected into css as base64
 */
export const blurInBase64 = (width: string | number, height: string | number): string => {
    return `data:image/svg+xml;base64,${toBase64(shimmerSVG(width, height))}`;
};
