import React, { useEffect, useState } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { parseCookies, setCookie } from 'nookies';
import { ThemeProvider } from 'styled-components';
import { useFetch } from '@hooks';
import { AlertProvider, AuthProvider, SWRProvider } from '@providers';
import { initLogger, isBrowser, isGreaterThanZero, LOCAL_STORAGE_TEMP_PRODUCTS_KEY } from '@utils';
import { AlertDialog } from '~/components/dialogs/alertDialog';
import type { User } from '~/models/user';
import {
    GetProductsForTemplateSubmissionTestingAsync,
    GetProductsForTemplateSubmissionTestingURL
} from '~/services/apiService';
import darkTheme from '~/styles/darkTheme';
import GlobalStyles from '~/styles/global';
import { Theme, Themes } from '~/styles/styled';
import lightTheme from '~/styles/theme';

type PageProps = {
    user: User;
    token: string;
};

type MyAppProps = AppProps & {
    err?: Error;
    pageProps: PageProps;
};

// suppress useLayoutEffect warnings when running outside a browser
// https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
if (!isBrowser()) {
    React.useLayoutEffect = React.useEffect;
}

initLogger();

const MAX_PRODUCTS = 10;
const TIME_IN_MILLISECONDS_TO_REQUERY_TEMPLATE_PRODUCTS = 10;

const MyApp = ({ Component, pageProps, err }: MyAppProps) => {
    const [theme, setTheme] = useState<Themes>(pageProps?.user?.theme ?? 'light');
    const isDarkTheme = theme === 'dark';

    useFetch(
        pageProps.user && { url: GetProductsForTemplateSubmissionTestingURL, user: pageProps.user },
        async (_, signal) => await GetProductsForTemplateSubmissionTestingAsync(MAX_PRODUCTS, signal),
        {
            keepPreviousData: true,
            dedupingInterval: minutesToMilliseconds(TIME_IN_MILLISECONDS_TO_REQUERY_TEMPLATE_PRODUCTS),
            onSuccess: (response) => {
                if (isGreaterThanZero(response.data.length)) {
                    localStorage.setItem(LOCAL_STORAGE_TEMP_PRODUCTS_KEY, JSON.stringify(response.data));
                }
            }
        }
    );

    useEffect(() => {
        const cookies = parseCookies();

        const savedTheme = cookies?.theme as Themes;

        if (!savedTheme) {
            setCookie(null, 'theme', 'light');
            return;
        }

        if (savedTheme && ['dark', 'light'].includes(savedTheme)) {
            setTheme(savedTheme);
        }
    }, []);

    return (
        <ThemeProvider theme={(isDarkTheme ? darkTheme : lightTheme) as Theme}>
            {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_URL && (
                <iframe
                    src={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_URL}
                    height='0'
                    width='0'
                    style={{ display: 'none', visibility: 'hidden' }}
                />
            )}
            <SWRProvider>
                <AuthProvider user={pageProps.user} token={pageProps.token}>
                    <Head>
                        <meta charSet='utf-8' />
                        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                    </Head>
                    <GlobalStyles />
                    <AlertProvider content={AlertDialog}>
                        <Component {...pageProps} err={err} />
                    </AlertProvider>
                </AuthProvider>
            </SWRProvider>
        </ThemeProvider>
    );
};

export default MyApp;
