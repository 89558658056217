import styled, { css, keyframes } from 'styled-components';

const threeFourths = 0.75;
const half = 2;

const Sizes = {
    small: 3,
    medium: 4,
    large: 5
};

export type LoadingStyledProps = {
    size: 'small' | 'medium' | 'large';
};

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`;

export const LoadingStyled = styled.div<LoadingStyledProps>`
    ${({ size }) => {
        const currentSize = Sizes[size];
        const radiusSize = (currentSize * threeFourths) / half;

        return css`
            width: ${currentSize}rem;
            height: ${currentSize}rem;

            svg {
                width: 100%;
                height: 100%;
                animation: ${rotate} 2s linear infinite;

                circle {
                    r: ${radiusSize}rem;
                    stroke-width: ${currentSize};
                    stroke-linecap: round;
                    animation: ${dash} 1.5s ease-in-out infinite;
                }
            }
        `;
    }}
`;
