import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import { AnimatedArrow } from '~/components/elements/animatedArrow';
import { cssMaxWidthWithPaddingX, desktopOnly, mobileAndTabletOnly, mobileOnly } from '~/styles';
import { MenuItem } from './menu/menuItem';

const BOX_SHADOW_ALPHA_VALUE = 0.12;

type HeaderStyledProps = {
    isStickyHeader?: boolean;
};

export const HeaderStyled = styled.header<HeaderStyledProps>`
    z-index: 100;
    width: 100%;
    background-color: ${(props) => props.theme.default.backgroundColor};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: ${({ isStickyHeader }) => (isStickyHeader ? 'sticky' : 'relative')};
    top: 0;
    left: 0;

    &.header--sticky {
        box-shadow: 0 0.05rem 1rem ${({ theme }) => rgba(theme.colors.lightBlack, BOX_SHADOW_ALPHA_VALUE)};

        & > div {
            padding-bottom: 1rem;
            padding-top: 1rem;

            a:first-child svg {
                height: 3rem;
            }
        }
    }
`;

export const NavbarStyled = styled.nav`
    width: 100%;
    margin-left: calc(6rem);

    ${mobileAndTabletOnly(css`
        margin-left: 1.6rem;
    `)}

    & > ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        gap: calc(5rem - 3.2rem);
        height: 100%;
    }
`;

export const HeaderContentStyled = styled.div`
    ${desktopOnly(css`
        padding: 2.6rem;
    `)}

    ${cssMaxWidthWithPaddingX}
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: padding 0.15s ease-out;

    ${mobileOnly(css`
        padding: 1.6rem 2.4rem;
    `)}
`;

export const UserSectionStyled = styled.div``;

type AnimatedArrowStyledProps = {
    marginLeft: string;
};

export const AnimatedArrowStyled = styled(AnimatedArrow)<AnimatedArrowStyledProps>`
    margin-left: ${({ marginLeft }) => marginLeft};
    height: 0.7rem;
`;

export const EnviouLogoSectionStyled = styled.a`
    .icon__enviou {
        display: none;
    }

    .logo__enviou {
        display: block;
    }

    ${mobileAndTabletOnly(css`
        .logo__enviou {
            display: none;
        }

        .icon__enviou {
            display: block;
        }
    `)}

    span {
        color: ${({ theme }) => theme.colors.enlightenedPurple};
        white-space: nowrap;
    }
    cursor: pointer;
`;

export const UserMenuHeaderStyled = styled(MenuItem)`
    width: 100%;
    max-width: 32rem;

    & > button {
        color: ${({ theme }) => theme.colors.deepCerise};
        padding: 1.6rem 2.4rem;
        & > div {
            display: flex;
            flex-direction: column;
            cursor: default;

            div {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;

                strong {
                    display: block;
                    height: 100%;
                    line-height: 2.1rem;
                    letter-spacing: 0.03px;
                    font: 600 2rem ${({ theme }) => theme.fonts.primary};
                }

                & > span {
                    font: 300 1.8rem ${({ theme }) => theme.fonts.primary};
                }
            }
        }
    }
`;

export const UserMenuButtonStyled = styled(MenuItem).attrs({ as: 'div' })`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 0.2rem;

    strong {
        color: ${({ theme }) => theme.colors.purple};
        margin: 0 0.8rem;
        white-space: nowrap;
    }

    & > div {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        overflow: hidden;
        color: ${({ theme }) => theme.colors.purple};
    }

    ${mobileOnly(css`
        & > strong {
            display: none;
        }
    `)}
`;

export const HelpIconStyled = styled(HelpIcon)`
    height: 2rem;
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.colors.deepCerise};
`;

export const HelpAndTutorialsContentStyled = styled.div`
    margin-right: 5rem;
    font: normal 2rem ${({ theme }) => theme.fonts.primary};
    display: flex;
    flex-wrap: nowrap;
    line-height: 3.2rem;
    align-items: center;

    ${mobileAndTabletOnly(css`
        * {
            display: none;
        }

        .icon__enviou {
            display: block;
        }
    `)}

    span {
        color: ${({ theme }) => theme.colors.deepCerise};
        white-space: nowrap;
    }
    cursor: pointer;
`;

export const IconStyled = styled.span`
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        transition: color 0.1s ease;
        color: ${({ theme }) => theme.colors.purple};
    }
`;
