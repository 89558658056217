import { tedVariationIcons } from '.';
import { ExpandableGroup } from '../expandableGroup';
import { useNoticeCard } from './root';
import { IconStyled } from './styles';

export const NoticeCardTed = () => {
    const { isOpen, initialExpansionValueNoticeCard, variant } = useNoticeCard();
    const TedIcon = tedVariationIcons[variant];

    return (
        <ExpandableGroup isExpanded={isOpen} initial={initialExpansionValueNoticeCard}>
            <IconStyled>{<TedIcon />}</IconStyled>
        </ExpandableGroup>
    );
};
