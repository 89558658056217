import { useCallback, useState, useEffect } from 'react';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import { useRouter } from 'next/router';
import { createQueryParams, isBrowser } from '@utils';

type Params = { [key: string]: unknown };
type PushOptions = {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
};

type UseQueryParamsProps = {
    useNextRouter?: boolean;
};

// ATTENTION: By setting useNextRouter to true the page may be rendered in addition because of the Router context of Next.
// If false, the page is not rendered, but when fast refresh occurs, the page will be reloaded to the current state before history changes by window.history.pustate.
// Check when upgrading to next 13 if this rendering is resolved or has a solution

export const useQueryParams = <T extends Params>({ useNextRouter = true }: UseQueryParamsProps = {}) => {
    const router = useRouter();

    const [queryParams, setQueryParams] = useState<T>(
        (router.query ?? Object.fromEntries(new URLSearchParams(window.location.search))) as T
    );

    const generateUrl = useCallback(
        (newParams: T) => {
            const mergedParams = { ...queryParams, ...newParams };
            const pathname = isBrowser() ? new URL(router.asPath, window.location.origin).pathname : router.pathname;
            const updatedUrl = `${pathname}?${createQueryParams(mergedParams as { [key: string]: string })}`;

            return { url: router.pathname, as: updatedUrl };
        },
        [queryParams, router]
    );

    const updateQueryParams = useCallback(
        (newParams: T, options: PushOptions = { shallow: true }) => {
            const mergedParams = { ...queryParams, ...newParams };
            const pathname = new URL(router.asPath, window.location.origin).pathname;
            const updatedUrl = `${pathname}?${createQueryParams(mergedParams as { [key: string]: string })}`;

            const currentUrl = `${window.location.pathname}${window.location.search}`;
            if (updatedUrl === currentUrl) {
                return;
            }

            if (useNextRouter) {
                router.push(router.pathname, updatedUrl, options);
                setQueryParams(mergedParams);

                return;
            }

            window.history.pushState({ ...window.history.state, as: updatedUrl, url: router.pathname }, '', updatedUrl);
            setQueryParams(mergedParams);
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queryParams, router?.asPath, useNextRouter]
    );

    const search = isBrowser() ? window?.location?.search : null;
    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(search));

        if (_isEqual(_omitBy(queryParams as T, _isNil), _omitBy(params, _isNil))) {
            return;
        }

        setQueryParams(params as T);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return {
        queryParams,
        updateQueryParams,
        generateUrl
    };
};
