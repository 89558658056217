import { ChangeEventHandler, ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Card, FormGroup, InfoTooltip, InputFormGroup, SelectData, SelectFormGroup } from '@components';
import { useTranslator } from '@hooks';
import { useCheckout } from '@providers';
import { useField } from '@unform/core';
import { getCardFlagName, isAValidCardNumber } from '@utils';
import * as CardBrands from '~/assets/svg/credit-card-brand';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { CardBodyStyled, CVVLabelStyled } from './styles';

const currentYear = new Date().getFullYear();

const TARGET_LENGTH_OF_THE_VALUE = 2;
const MIN_LENGTH_CARD_NUMBER = 12;

const months: Array<SelectData> = Array.from<SelectData, SelectData>({ length: 12 }, (option, index) => ({
    ...option,
    id: index + 1,
    label: String(index + 1).padStart(TARGET_LENGTH_OF_THE_VALUE, '0')
}));

const years: Array<SelectData> = Array.from<SelectData, SelectData>({ length: 10 }, (option, index) => ({
    ...option,
    id: currentYear + index,
    label: String(currentYear + index)
}));

const creditCardFields = [
    'creditCard.number',
    'creditCard.cardholderName',
    'creditCard.expirationMonth',
    'creditCard.expirationYear',
    'creditCard.cvv'
];

export const CreditCardForm = () => {
    const { defaultValue: cardFlagName } = useField('cardFlagName');
    const { defaultValue: disableCreditCardFields } = useField('disableCreditCardFields');

    const FlagElement = cardFlagName ? CardBrands[cardFlagName] : null;

    const { formRef } = useCheckout();

    const [showChangeCreditCardButton, setShowChangeCreditCardButton] = useState(disableCreditCardFields);
    const [cardFlagIcon, setCardFlagIcon] = useState<ReactElement>(FlagElement ? <FlagElement /> : null);
    const [brand, setBrand] = useState(cardFlagName);
    const { checkoutPage, buttons } = useTranslator();
    const { creditCardForm } = checkoutPage.subscriptionPayment;

    const RenderCVVLabel = (): JSX.Element => (
        <CVVLabelStyled>
            {String(creditCardForm.cvv.label)}
            <InfoTooltip>
                <TooltipContent>{String(creditCardForm.cvv.toolTipContent)}</TooltipContent>
            </InfoTooltip>
        </CVVLabelStyled>
    );

    const handleChangeCardNumber: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        const { value } = event.target;

        if (value.length >= MIN_LENGTH_CARD_NUMBER && isAValidCardNumber(value)) {
            const cardFlag = getCardFlagName(value);

            if (cardFlag && CardBrands[cardFlag]) {
                const FlagSVG = CardBrands[cardFlag];

                setCardFlagIcon(<FlagSVG />);
                setBrand(cardFlag);
                return;
            }
        }

        setCardFlagIcon(null);
    }, []);

    const handleChangeCreditCard = async () => {
        setCardFlagIcon(null);
        setShowChangeCreditCardButton(false);

        creditCardFields.forEach((fieldName) => {
            formRef.current.clearField(fieldName);
            formRef.current.setFieldError(fieldName, null);

            const fieldElement = formRef.current.getFieldRef(fieldName) as HTMLInputElement;
            fieldElement.disabled = false;

            if (fieldName === 'creditCard.number') {
                fieldElement?.focus();
            }
        });
    };

    useEffect(() => {
        if (!disableCreditCardFields || !formRef.current) {
            return;
        }

        creditCardFields.forEach((fieldName) => {
            if (fieldName !== 'creditCard.cvv') {
                const fieldElement = formRef.current.getFieldRef(fieldName) as HTMLInputElement;
                fieldElement.disabled = true;
            }
        });
    }, [disableCreditCardFields, formRef]);

    const disableFields = disableCreditCardFields;

    return (
        <Card>
            <CardBodyStyled>
                <FormGroup enableUnform unformScopePath='creditCard'>
                    <InputFormGroup
                        type='text'
                        name='brand'
                        disabled
                        hidden
                        value={brand}
                        containerClassName='hidden'
                    />
                    <InputFormGroup
                        type='text'
                        name='number'
                        autoComplete='cc-number'
                        placeholder='1234 1234 1234 1234'
                        label={String(creditCardForm.number.label)}
                        minLength={16}
                        mask={showChangeCreditCardButton ? 'hiddenCreditCardNumberMask' : 'creditCardNumber'}
                        icon={cardFlagIcon}
                        onChange={handleChangeCardNumber}
                        flexGrid={{ tabletAndDesktop: 6, mobile: 12 }}
                        testId='input__credit-card-number'
                        inputSize='small'
                    />

                    <InputFormGroup
                        type='text'
                        name='cardholderName'
                        autoComplete='cc-name'
                        label={String(creditCardForm.cardholderName.label)}
                        flexGrid={{ tabletAndDesktop: 6, mobile: 12 }}
                        testId='input__credit-card-holder-name'
                        inputSize='small'
                    />

                    <SelectFormGroup
                        name='expirationMonth'
                        label={String(creditCardForm.expiration.month.label)}
                        flexGrid={{ desktop: 4, mobileAndTablet: 12 }}
                        placeholder={String(creditCardForm.expiration.month.placeholder)}
                        testId='dropdown__credit-card-expiration-month'
                        options={[{ id: null, label: String(creditCardForm.expiration.month.nullValue) }, ...months]}
                    />

                    <SelectFormGroup
                        name='expirationYear'
                        label={String(creditCardForm.expiration.year.label)}
                        flexGrid={{ desktop: 4, mobileAndTablet: 12 }}
                        placeholder={String(creditCardForm.expiration.year.placeholder)}
                        testId='dropdown__credit-card-expiration-year'
                        options={[{ id: null, label: String(creditCardForm.expiration.year.nullValue) }, ...years]}
                    />

                    <FormGroup flexGrid={{ desktop: 4, mobileAndTablet: 12 }}>
                        <InputFormGroup
                            type='text'
                            name='cvv'
                            autoComplete='cc-csc'
                            mask='creditCardCVV'
                            label={<RenderCVVLabel />}
                            placeholder='000'
                            min={0}
                            max={999}
                            minLength={3}
                            maxLength={3}
                            pattern='[\d]{3}'
                            testId='input__credit-card-CVV'
                            inputSize='small'
                            autoFocus={disableFields}
                        />
                    </FormGroup>
                </FormGroup>
                {showChangeCreditCardButton && (
                    <Button type='button' styledAs='link' color='deepCerise' onClick={handleChangeCreditCard}>
                        {String(buttons.changeCreditCard)}
                    </Button>
                )}
            </CardBodyStyled>
        </Card>
    );
};
