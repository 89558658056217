import styled, { css } from 'styled-components';

export type CardFlagsStyledProps = {
    height?: number;
};

export const CardFlagsStyled = styled.ul<CardFlagsStyledProps>`
    ${({ height = 1.6 }) => css`
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        gap: 1.6rem;
        width: fit-content;
        list-style: none;

        li {
            display: inline-flex;
        }

        img,
        svg {
            height: ${height}rem;
        }
    `}
`;
