import { Button, Heading, Text } from '@components';
import { useTranslator } from '@hooks';
import { useAlert } from '@providers';
import { EditBillingDataModal } from '../../billingData/billingDataModal';
import { ReasonSuspendedPlanStyled, IconAttentionSymbolTriangleStyled } from './styles';

export const ReasonSuspendedPlan = () => {
    const { show } = useAlert();
    const { reasonSuspendedPlan } = useTranslator().checkoutPage.subscriptionPayment;
    return (
        <ReasonSuspendedPlanStyled>
            <main>
                <IconAttentionSymbolTriangleStyled />
                <div>
                    <Heading as='h6'>{reasonSuspendedPlan.title}</Heading>
                    <Text size='sm'>{reasonSuspendedPlan.description}</Text>
                </div>
                <Button
                    color='purple'
                    isOutlined
                    type='button'
                    id='changeBillingData'
                    onClick={() => show(null, null, { content: EditBillingDataModal })}>
                    {reasonSuspendedPlan.editBillingDataButton}
                </Button>
            </main>
        </ReasonSuspendedPlanStyled>
    );
};
