import styled from 'styled-components';
import { DialogContent } from '../dialog/content';

type SendingTechnologyItemStyledProps = {
    disabled?: boolean;
};

export const DialogContentStyled = styled(DialogContent)`
    padding: ${({ theme }) => theme.space['3xl']};
    max-width: 90rem;
    margin-top: 12vh;

    h2 {
        margin: ${({ theme }) => theme.space.xl} 0;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        display: flex;
        gap: ${({ theme }) => theme.space.xl};
        flex-wrap: wrap;
    }
`;

export const SendingTechnologyItemStyled = styled.li<SendingTechnologyItemStyledProps>`
    border-radius: ${({ theme }) => theme.radii.lg};
    box-shadow: ${({ theme }) => theme.default.boxShadow.secondary};
    flex: 1;

    div {
        padding: ${({ theme }) => `${theme.space['3xl']} ${theme.space.xl}`};
        color: ${({ theme, disabled = true }) => (disabled ? theme.colors.philippineSilver : theme.colors.purple)};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        i {
            width: 14.2rem;
            height: 14.2rem;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        p {
            text-align: center;
            align-self: center;
        }
    }
`;
