import { Button, Link } from '@components';
import { MessageSequenceType } from '@models';
import { useAutomationRulerProvider } from '@providers';
import { createQueryParams } from '@utils';
import PlusIcon from '~/assets/svg/icons/icon__plus.svg';
import { AddButtonContainerStyled } from './styles';

type AddRulerStepButtonProps = {
    messageSequence: {
        type: MessageSequenceType;
        identifier?: string;
        id: number;
    };
};

export const AddRulerStepButton = ({ messageSequence }: AddRulerStepButtonProps) => {
    const getSubToolPageDetails = useAutomationRulerProvider((context) => context.getSubToolPageDetails);

    const {
        urls: { createTemplatePage }
    } = getSubToolPageDetails({
        params: {
            createTemplatePage: {
                params: createQueryParams({
                    type: String(Number(messageSequence.type)),
                    identifier: messageSequence?.identifier,
                    sequenceId: String(Number(messageSequence.id))
                })
            }
        }
    });

    return (
        <AddButtonContainerStyled className='add-button'>
            <Link href={createTemplatePage.href} as={createTemplatePage.as} tabIndex={-1}>
                <Button isIcon color='purple' isOutlined>
                    <PlusIcon />
                </Button>
            </Link>
        </AddButtonContainerStyled>
    );
};
