import { useMemo, useCallback } from 'react';
import toNumber from 'lodash/toNumber';
import uniq from 'lodash/uniq';
import { Card, CardBody, CardHeader, CardLink, CardText, SelectData, SelectFormGroup } from '@components';
import { CurrentPlanDetails, CurrentPlanItemDetails, PlanCalculatorModal } from '@containers';
import { useNumberFunctions, useReplace, useTranslator } from '@hooks';
import { CustomerPlanType, SendingTechnologyTypeEnum, ToolTypeEnum } from '@models';
import { SubscriptionPlanFormData, SubscriptionReducerActionTypesEnum, useAlert, usePaymentMethods } from '@providers';
import { useCheckout, useTools } from '~/providers/checkoutProvider';
import { GetBillingPeriodById, GetSendingPriceByPeriodicity, GetToolByType } from '~/services/subscriptionService';
import { PlanEmailMarketingModal } from './modal';
import { EmailMarketingStyled } from './styles';

export const EmailMarketing = () => {
    const { emailMarketing } = useTranslator().checkoutPage.subscriptionPlan;
    const { formatNumber, formatCurrency } = useNumberFunctions();
    const replace = useReplace();
    const { show } = useAlert();
    const { tools } = useTools();
    const { billingPeriods } = usePaymentMethods();
    const { dispatchSubscriptionFormData, subscriptionFormData, hasSubscription, subscription } = useCheckout();

    const emailMarketingAmountOptions = useMemo(() => {
        const periodicityInMonths = GetBillingPeriodById(
            billingPeriods,
            subscriptionFormData?.subscriptionPlan?.billingPeriodId
        )?.periodicityInMonths;

        const technologies = uniq(
            tools
                ?.find((tool) => tool.type === ToolTypeEnum.BulkSending)
                ?.sendingTechnologies?.filter(
                    (sendingTech) => sendingTech.type === SendingTechnologyTypeEnum.Email && !sendingTech.isCustomValue
                )
                ?.map((sendingTechnology) => ({
                    creditAmount: sendingTechnology.creditAmount,
                    price: GetSendingPriceByPeriodicity(sendingTechnology, periodicityInMonths)
                }))
        );

        const optionsList: Array<SelectData> = technologies?.map((technology) => ({
            id: technology.creditAmount,
            label: replace(emailMarketing.dropdown.creditsPerMonth, {
                amount: formatNumber(technology.creditAmount),
                value: formatCurrency(technology.price)
            })
        }));

        optionsList.unshift({ id: 0, label: String(emailMarketing.dropdown.nullValue) });

        return optionsList;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tools, subscriptionFormData, billingPeriods]);

    const currentCreditsPerMonth = useMemo(() => {
        if (!hasSubscription) {
            return;
        }

        const emailMarketingData = subscription.subscriptionTools?.find(
            (item) => item.type === CustomerPlanType.BulkSendingTool
        );

        return {
            label: String(emailMarketing.amount),
            value: emailMarketingData?.totalCredits
                ? replace(emailMarketing.dropdown.creditsPerMonth, {
                      amount: formatNumber(emailMarketingData.totalCredits),
                      value: formatCurrency(emailMarketingData.price)
                  })
                : String(emailMarketing.dropdown.nullValue)
        } as CurrentPlanItemDetails;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription, hasSubscription]);

    const handleChange = useCallback(
        (value: number | string) => {
            const subscriptionPlan: SubscriptionPlanFormData = subscriptionFormData?.subscriptionPlan ?? {
                sendingTechnologies: null
            };

            subscriptionPlan.emailMarketingAmount = toNumber(value);

            dispatchSubscriptionFormData({
                type: SubscriptionReducerActionTypesEnum.UpdatePlan,
                payload: { subscriptionPlan }
            });
        },
        [dispatchSubscriptionFormData, subscriptionFormData]
    );

    const sendingTechnologies = useMemo(
        () => GetToolByType(tools, ToolTypeEnum.BulkSending)?.sendingTechnologies ?? [],
        [tools]
    );

    return (
        <Card type='group' borderColor='purple'>
            <CardHeader
                title={String(emailMarketing.title)}
                description={emailMarketing.description}
                actions={
                    <CardLink
                        text={String(emailMarketing.moreDetails)}
                        size='md'
                        isUnderline
                        onClick={() => show(null, null, { content: PlanEmailMarketingModal })}
                    />
                }
            />
            <CardBody>
                {hasSubscription && (
                    <CurrentPlanDetails
                        currentPlanItemDetails={currentCreditsPerMonth}
                        description={String(emailMarketing.descriptionPlanChange)}
                    />
                )}

                <EmailMarketingStyled>
                    <CardText type='subtitle' value={emailMarketing.dropdown.title} />
                    <SelectFormGroup
                        name='emailMarketingAmount'
                        placeholder={String(emailMarketing.dropdown.placeholder)}
                        testId='dropdown__email-marketing'
                        options={emailMarketingAmountOptions}
                        onOptionChange={(data) => handleChange(data.id)}
                    />
                    <CardLink
                        text={String(emailMarketing.dropdown.creditCalculatorTitle)}
                        onClick={() =>
                            show(null, null, {
                                content: (props) => (
                                    <PlanCalculatorModal
                                        {...props}
                                        toolType={ToolTypeEnum.BulkSending}
                                        sendingTechnologies={sendingTechnologies}
                                        onSelectPlan={handleChange}
                                    />
                                )
                            })
                        }
                    />
                </EmailMarketingStyled>
            </CardBody>
        </Card>
    );
};

export default EmailMarketing;
