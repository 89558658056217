import { NewslatterThemeSort, NewsletterThemeType, PaginatedList, Theme, ThemeTemplate } from '@models';
import axiosClient from './apiService';

export const NEWSLETTER_THEME_URL = '/newsletter/themes';
export const NEWSLETTER_THEME_CATEGORIES_URL = '/newsletter/themes/categories';
export const NEWSLETTER_THEME_DETAILS_URL = '/newsletter/themes/details';

type GetAvailableThemesAsyncProps = {
    themeName: string;
    categoryName: string;
    sort: NewslatterThemeSort;
    pageSize?: number;
    pageNumber?: number;
    themeType: NewsletterThemeType;
};

export const GetAvailableThemesAsync = async (params: GetAvailableThemesAsyncProps) => {
    return axiosClient.get<PaginatedList<Theme>>(NEWSLETTER_THEME_URL, { params });
};

export const GetThemeTemplateAsync = async (themeId: number, themeType: NewsletterThemeType) => {
    return axiosClient.get<ThemeTemplate>(NEWSLETTER_THEME_DETAILS_URL, { params: { themeId, themeType } });
};

export const GetAvailableCategoriesAsync = async () => {
    return axiosClient.get<Array<string>>(NEWSLETTER_THEME_CATEGORIES_URL);
};
