import { isNil } from 'lodash';
import { useRouter } from 'next/router';
import { isDevelopment } from '@utils';
import JSONLocales from '~/locales/locales.json';

type LocalesTypes = typeof JSONLocales;
export type StatusErrorType = keyof typeof JSONLocales.api.statusError;

export type TranslatorProps = {
    locale?: string;
    locales?: Array<string>;
    defaultLocale?: string;
};

export const translator = ({ locale, locales, defaultLocale }: TranslatorProps) => {
    const getLocaleProperty: ProxyHandler<LocalesTypes> = {
        get: (target, property) => {
            const currentTarget = target[property];

            if (isNil(currentTarget)) {
                const key = String(property);
                if (isDevelopment()) {
                    const stackTrace = new Error().stack;
                    const stackRows = stackTrace.split('\n');

                    console.warn(
                        `useTranslator - Key ${key} is missing a translation. Consider adding it to locales resources.`,
                        stackRows
                    );
                    return `Missing translation for key '${key}'`;
                }

                return '';
            }

            const entries = Object.entries(currentTarget);
            if (entries.some(([prop]) => locales.includes(prop))) {
                return currentTarget[locale] ?? currentTarget[defaultLocale] ?? entries[0][1];
            }

            return new Proxy(currentTarget, getLocaleProperty);
        }
    };

    return new Proxy<LocalesTypes>(JSONLocales, getLocaleProperty);
};

export const useTranslator = () => {
    const { locale, locales, defaultLocale } = useRouter();

    return translator({ locale, locales, defaultLocale });
};

export default useTranslator;
