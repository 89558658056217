import React, { ReactNode } from 'react';
import { Button, Card, CardHeader, InfoTooltip, ToggleSwitch, Label } from '@components';
import { CustomizationVariablesModal, CheckSecurityModal } from '@containers';
import { VariableParentKey, useTranslator } from '@hooks';
import { CustomizationVariable, SendingTechnologyTypeEnum } from '@models';
import { useAlert } from '@providers';
import { CUSTOM_VARIABLES_GUIDE_LINK } from '@utils';
import { theme } from '~/styles/theme';
import {
    CardBodyStyled,
    Content,
    PhoneContainer,
    SmsEditor,
    TitleStyled,
    ToolTipChildrenStyled,
    VariableInfoContainer
} from './styles';

type ModelsTemplateEditorProps = {
    title: string;
    subtitle: string;
    customizationVariables?: CustomizationVariable[];
    hasCustomizationVariables: boolean;
    inputTestComponent?: ReactNode;
    subToolKeyIdentifier: VariableParentKey;
    onChangeEnableTechnology?: (checked: boolean) => void;
    closeCheckSecurityModal?: () => void;
    isCheckSecurityModalOpen: boolean;
    tooltipElement?: ReactNode;
    enableTechnology?: boolean;
    subToolColor?: string;
    modelPreview: ReactNode;
    formComponents: ReactNode;
    sendingTechnology: SendingTechnologyTypeEnum;
};

export const ModelsTemplateEditor = ({
    title,
    subtitle,
    customizationVariables = [],
    hasCustomizationVariables,
    inputTestComponent,
    subToolKeyIdentifier,
    onChangeEnableTechnology,
    closeCheckSecurityModal,
    isCheckSecurityModalOpen,
    subToolColor,
    tooltipElement,
    enableTechnology = true,
    modelPreview,
    formComponents,
    sendingTechnology
}: ModelsTemplateEditorProps) => {
    const {
        subTools: {
            modelsTemplateEditor: { customizationVariablesText }
        }
    } = useTranslator();
    const { show } = useAlert();

    const openCustomizationVariablesModal = () => {
        show(null, null, {
            content: (data) => (
                <CustomizationVariablesModal
                    {...data}
                    customizationVariables={customizationVariables}
                    subToolKeyIdentifier={subToolKeyIdentifier}
                />
            )
        });
    };

    const getTechnologyName = () => {
        return SendingTechnologyTypeEnum[sendingTechnology].toString().toLowerCase();
    };

    return (
        <>
            <CheckSecurityModal onClose={closeCheckSecurityModal} isOpen={isCheckSecurityModalOpen} />
            <Card type='group' borderColor={subToolColor}>
                <CardHeader
                    title={
                        <TitleStyled>
                            <ToggleSwitch
                                name={`${getTechnologyName()}Enabled`}
                                onChange={(event) => onChangeEnableTechnology(event.target.checked)}
                                enableUnform
                                checked={enableTechnology}
                                color={theme.colors.purple}
                            />
                            {title}
                        </TitleStyled>
                    }
                    actions={
                        hasCustomizationVariables && enableTechnology && customizationVariables ? (
                            <VariableInfoContainer>
                                <Button onClick={openCustomizationVariablesModal} color='purple' isOutlined>
                                    {customizationVariablesText.label}
                                    <InfoTooltip>
                                        <ToolTipChildrenStyled onClick={(event) => event.stopPropagation()}>
                                            {customizationVariablesText.helpButton.title}
                                            <a
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href={CUSTOM_VARIABLES_GUIDE_LINK}>
                                                {customizationVariablesText.helpButton.clickHere}
                                            </a>
                                        </ToolTipChildrenStyled>
                                    </InfoTooltip>
                                </Button>
                            </VariableInfoContainer>
                        ) : null
                    }
                />
                <CardBodyStyled cardChild isExpanded={enableTechnology ?? true}>
                    <Content
                        isExpanded={enableTechnology}
                        initial={{ height: enableTechnology === false ? 0 : 'auto' }}>
                        <SmsEditor flexGrid={{ mobile: 12, desktop: 6 }}>
                            <div className='textInfoGroup'>
                                <Label>
                                    {subtitle}
                                    {tooltipElement && (
                                        <InfoTooltip>
                                            <ToolTipChildrenStyled onClick={(event) => event.stopPropagation()}>
                                                {tooltipElement}
                                            </ToolTipChildrenStyled>
                                        </InfoTooltip>
                                    )}
                                </Label>
                                <>{formComponents}</>
                            </div>
                            {inputTestComponent}
                        </SmsEditor>
                        <PhoneContainer flexGrid={{ mobile: 12, desktop: 6 }}>{modelPreview}</PhoneContainer>
                    </Content>
                </CardBodyStyled>
            </Card>
        </>
    );
};
