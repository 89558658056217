import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePrevPropsAndState = <P = any, S = any>(props = null, state = null) => {
    const prevPropsAndStateRef = useRef({ props: null, state: null });
    const prevProps: P = prevPropsAndStateRef.current.props;
    const prevState: S = prevPropsAndStateRef.current.state;

    useEffect(() => {
        prevPropsAndStateRef.current = { props, state };
    });

    return { prevProps, prevState };
};
