import styled, { css } from 'styled-components';
import ArrowDownIcon from '~/assets/svg/controls/arrow--down--purple.svg';

type ArrowIconProps = {
    direction: 'up' | 'down';
};

export const ArrowIconStyled = styled((props) => <ArrowDownIcon {...props} />)<ArrowIconProps>`
    transform: ${({ direction }) => (direction === 'up' ? css`rotate(-180deg)` : css`rotate(0deg)`)};
    transition: transform 0.25s cubic-bezier(0, 0.84, 0.83, 0.67);
`;
