import styled, { css } from 'styled-components';
import { Button } from '~/components/elements/button';
import { ExpandableGroup } from '~/components/elements/expandableGroup';
import { tabletAndDesktopOnly, mobileOnly } from '~/styles';
import { AlertColors } from '~/styles/theme';
import { Heading } from '../heading';
import { Text } from '../text';

export const NoticeCardContainerStyled = styled.div`
    width: 100%;
    position: relative;
    max-width: ${({ theme }) => theme.container.rem.maxWidth}rem;
`;

export const CloseButton = styled(Button).attrs({ isOutline: true, isIcon: true })`
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    background-color: ${({ theme }) => theme.colors.grayPurple};
    box-shadow: none;
    width: 3rem;
    height: 3rem;
    align-items: center;
    overflow: hidden;

    svg {
        position: absolute;
        transition: opacity 0.2s ease-out;
        color: ${({ theme }) => theme.colors.eminencePurple};
    }
`;

export const NoticeGroupStyled = styled.div<{ variant: AlertColors }>`
    padding: 2rem 2.4rem;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.default.backgroundColor};
    box-shadow: ${({ theme }) => theme.default.boxShadow.default};
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 2rem;

    .notice-card-content__body {
        width: 100%;
        flex: 1;

        h5 {
            color: ${({ theme, variant }) => theme.alertColors[variant]};
        }

        .notice-card-content__description {
            padding-top: ${({ theme }) => theme.space.sm};
        }
    }

    ${tabletAndDesktopOnly(css`
        flex-direction: row;
        padding: 2.4rem 4rem;
    `)}

    ${mobileOnly(css`
        padding: 1rem 4rem;
    `)}
`;

export const NoticeGroupTitleStyled = styled(Heading)<{ variant: AlertColors }>`
    color: ${({ theme, variant }) => theme.alertColors[variant]};
`;

export const NoticeGroupDescriptionStyled = styled(Text)`
    padding-top: ${({ theme }) => theme.space.sm};
`;

export const NoticeGroupContentBodyStyled = styled.div`
    width: 100%;
    flex: 1;
`;

export const IconStyled = styled.div`
    width: 8rem;
    height: 8rem;
`;

export const AdditionalButtonStyled = styled(Button)`
    width: 100%;
`;

export const AdditionalGroup = styled(ExpandableGroup)`
    div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        span {
            margin-bottom: 0.8rem;
        }
    }

    ${tabletAndDesktopOnly(css`
        padding: 0 1.6rem;
    `)}
`;
