import styled, { css } from 'styled-components';
import SearchIcon from '~/assets/svg/icons/icon__search.svg';
import { mobileAndTabletOnly, mobileOnly, tabletOnly } from '~/styles';

export const ThemeStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.4rem 0;

    & > div {
        & > div > button {
            height: fit-content;
            place-self: flex-end;
        }
    }

    nav {
        min-height: 3.9rem;
    }
`;

export const ThemeListStyled = styled.div`
    max-width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    margin: 1.6rem 0;
    gap: 1.6rem;

    ${mobileAndTabletOnly(css`
        gap: 1.6rem;
    `)}

    & > div {
        width: 25%;
        background: transparent;

        ${tabletOnly(css`
            width: 33.33%;
        `)}

        ${mobileOnly(css`
            width: 50%;
        `)}
    }

    & + span {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: ${({ theme }) => theme.colors.lightGray};
        margin-bottom: 1.6rem;
    }
`;

export const ThemeCardStyled = styled.div`
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.lightPurple};
    box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.05);
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > header {
        height: 9.6rem;
        display: flex;

        span {
            width: 100% !important;
        }

        & > div {
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 9.6rem;
            max-width: 100%;
            max-height: 9.6rem;
        }

        img {
            width: 100%;
            height: 9.6rem;
            object-fit: cover;
        }
    }

    & > div {
        padding: 1.6rem 1.2rem 1.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: ${({ theme }) => theme.colors.white};
        flex: 1;
        justify-content: space-between;

        span {
            background: ${({ theme }) => theme.colors.pink};
            border-radius: 50px;
            font-size: 1rem;
            margin: 0;
            color: ${({ theme }) => theme.colors.white};
            padding: 0.2rem 0.8rem;
            text-transform: uppercase;
            width: fit-content;
        }

        h4 {
            margin: 0.8rem 0;
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.1rem;
        }
    }

    & > footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: ${({ theme }) => theme.colors.white};
        gap: 0.8rem;
        padding: 0 1.2rem 1.2rem;

        button {
            width: 100%;
            flex: 1 1 0;
            font-size: 1.6rem;
        }
    }
`;

export const CustomizeYourThemeStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    justify-content: flex-start;
`;

export const InputStyled = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    gap: 2.4rem;
`;

export const SearchInputStyled = styled.div`
    width: 100%;

    & > div {
        padding: 0;
        margin-left: auto;

        div {
            flex-direction: row-reverse;
        }
    }
`;

export const FiltersInputStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    width: 100%;
    align-self: flex-end;
    justify-content: flex-end;

    & > div {
        padding: 0;
        max-width: 23.3rem;
        flex: 1;

        & > div {
            max-height: 18rem;
        }
    }

    label {
        white-space: nowrap;
    }
`;

export const FormGroupStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.6rem;
`;

export const SearchIconStyled = styled(SearchIcon)`
    margin-left: 1.6rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.purple};
`;
