export enum PaymentMethodTypeEnum {
    None = 0,
    CreditCard = 3,
    BankSlip = 5,
    Gateway = 4
}

export type PaymentMethod = {
    id: number;
    type: PaymentMethodTypeEnum;
    name: string;
    discountPercentage?: number;
};
