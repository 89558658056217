import axios, { AxiosError, AxiosResponse } from 'axios';
import _capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import { Address, AddressCity, AddressCountry, AddressState } from '@models';
import { captureException } from '@sentry/nextjs';
import { HttpStatusCodeEnum } from '@utils';
import { BrasilApiResponseError } from './errors/brasilApiResponseError';

type BrasilApiCountry = {
    cep: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    service: string;
    location?: {
        type: string;
        coordinates?: {
            longitude: string;
            latitude: string;
        };
    };
};

type BrasilApiState = {
    id: number;
    sigla: string;
    nome: string;
    regiao?: {
        id: number;
        sigla: string;
        nome: string;
    };
};

type BrasilApiCity = {
    codigo_ibge: number;
    nome: string;
};

type BrasilApiError = {
    name: string;
    message: string;
    errors: Array<{
        name: string;
        message: string;
        service: string;
    }>;
};

export const STATES_OF_BRAZIL_URL = 'ibge/uf/v1';
export const getCitiesByStateUrl = (state: string) => `ibge/municipios/v1/${state}`;
export const ADDRESS_BY_ZIP_CODE_URL = 'cep/v2/';

const defaultCountry: AddressCountry = { id: 1, name: 'Brasil', code: 'BR', callingCode: '+55' };

// #region Axios Config
const axiosClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BRASIL_API
});

axiosClient.interceptors.response.use(
    (response) => response,
    (error: AxiosError<BrasilApiError>) => {
        try {
            if (error.response.status === HttpStatusCodeEnum.NotFound) {
                return Promise.reject(new BrasilApiResponseError('Zip code not found'));
            }

            return Promise.reject(new BrasilApiResponseError(error.message));
        } catch (exception) {
            captureException(exception);
        }
    }
);

// #endregion

// #region Address
export const GetAddressByZipCode = async (zipCode: string) => {
    return axiosClient.get<Address>(`${ADDRESS_BY_ZIP_CODE_URL}${zipCode}`, {
        transformResponse: [
            (data) => {
                try {
                    const response = JSON.parse(data) as BrasilApiCountry;

                    return {
                        zipCode: response?.cep,
                        country: defaultCountry,
                        state: { id: null, name: null, code: response?.state },
                        city: response?.city ?? '',
                        street: response?.street ?? '',
                        number: null,
                        neighborhood: response?.neighborhood ?? '',
                        complement: null
                    } as Address;
                } catch (error) {
                    throw Error(`Error parsing response JSON data - ${JSON.stringify(error)}`);
                }
            }
        ]
    });
};

export const GetStatesOfBrazil = async () => {
    return axiosClient
        .get<Array<AddressState>>(STATES_OF_BRAZIL_URL, {
            transformResponse: [
                (data) => {
                    try {
                        const response = JSON.parse(data) as Array<BrasilApiState>;

                        return response.map<AddressState>((state: BrasilApiState) => ({
                            id: state.id,
                            name: state.nome,
                            code: state.sigla
                        }));
                    } catch (error) {
                        throw Error(`Error parsing response JSON data - ${JSON.stringify(error)}`);
                    }
                }
            ]
        })
        .then((response) => {
            if (!response?.data) {
                return {} as AxiosResponse<AddressState[]>;
            }
            response.data = sortBy(response.data ?? [], ['name']);
            return response;
        });
};

export const GetCitiesByState = async (state: string) => {
    return axiosClient
        .get<Array<AddressCity>>(getCitiesByStateUrl(state), {
            params: {
                providers: 'gov,wikipedia,dados-abertos-br'
            },
            transformResponse: [
                (data) => {
                    try {
                        const response = JSON.parse(data) as Array<BrasilApiCity>;

                        return response.map<AddressCity>((state: BrasilApiCity) => ({
                            id: state.codigo_ibge,
                            name: _capitalize(state.nome)
                        }));
                    } catch (error) {
                        throw Error(`Error parsing response JSON data - ${JSON.stringify(error)}`);
                    }
                }
            ]
        })
        .then((response) => {
            if (!response?.data) {
                return {} as AxiosResponse<AddressState[]>;
            }

            response.data = sortBy(response.data ?? [], ['name']);
            return response;
        });
};

export const GetStateByCode = async (code: string) => {
    return axiosClient.get<AddressState>(`ibge/uf/v1/${code}`, {
        transformResponse: [
            (data) => {
                try {
                    const response = JSON.parse(data);

                    return {
                        id: response.id,
                        name: response.nome,
                        uf: response.sigla
                    };
                } catch (error) {
                    throw Error(`Error parsing response JSON data - ${JSON.stringify(error)}`);
                }
            }
        ]
    });
};

// #endregion
