import { useMemo } from 'react';
import { first } from 'lodash';
import _isNumber from 'lodash/isNumber';
import _orderBy from 'lodash/orderBy';
import { OptionProps, DropdownFormGroup, Button, Link } from '@components';
import { useSubToolPage, useTranslator } from '@hooks';
import { SubToolIdEnum } from '@models';
import { useAlert, useMyAutomationsProvider } from '@providers';
import { Form } from '@unform/web';
import SettingIcon from '~/assets/svg/icons/icon__setting.svg';
import { PageSubTitle, PageTitle } from '~/styles/defaultComponentStyles';
import { AutomationData } from '.';
import { ButtonStyled } from '../automationRuler/subToolActionButtons/styles';
import { HeaderStyled, CustomTriggerButtonsContainer } from './styles';

export type HeaderProps = {
    automation: AutomationData;
};

export const Header = ({ automation }: HeaderProps) => {
    const { warning } = useAlert();
    const {
        pages: {
            myAutomations: {
                sections: {
                    myAutomations: { filterBy }
                }
            }
        },
        dialogs: { warnings },
        enums: { subToolIdEnum },
        buttons: { createNewCustomTrigger }
    } = useTranslator();
    const { getSubToolPageData } = useSubToolPage();

    const subToolFilterOptions: Array<OptionProps> = useMemo(() => {
        const nullValue: OptionProps = {
            value: null,
            text: String(filterBy.nullValue)
        };

        return [nullValue].concat(
            _orderBy(
                Object.values(SubToolIdEnum)
                    .filter((value) => _isNumber(value) && value !== SubToolIdEnum.Emails)
                    .map<OptionProps>((configuration) => ({
                        value: configuration,
                        text: subToolIdEnum[SubToolIdEnum[configuration]]
                    })),
                'text'
            )
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { type, title, subtitle } = automation;
    const showFilterFrom = type === 'myAutomations';
    const showCreateNewCustomTriggerButton = type === 'myCustomTriggers';

    const handleFilter = useMyAutomationsProvider((selector) => {
        return selector.filter;
    });

    const {
        translatedSubToolQueryParam,
        urls: { createTemplatePage, configurationPage }
    } = getSubToolPageData(SubToolIdEnum.CustomTrigger, { params: { createTemplatePage: { params: '' } } });

    const triggerConfiguration = first(automation?.subTools);
    const showWarningNotSubToolConfigured = () => {
        if (!triggerConfiguration.id) {
            warning(String(warnings.subToolNotConfigured), null, {
                enableOutsideClosed: true
            });

            return true;
        }

        return false;
    };

    return (
        <HeaderStyled>
            <div>
                <PageTitle>{title}</PageTitle>
                <PageSubTitle>{subtitle}</PageSubTitle>
            </div>
            {showFilterFrom && (
                <Form onSubmit={() => null} initialData={{ filterBySubToolId: [null] }}>
                    <DropdownFormGroup
                        label={String(filterBy.label)}
                        inputSize='small'
                        name='filterBySubToolId'
                        onChange={handleFilter}
                        multiSelection
                        options={subToolFilterOptions}
                    />
                </Form>
            )}
            {showCreateNewCustomTriggerButton && (
                <CustomTriggerButtonsContainer>
                    <Link
                        showLink={!!triggerConfiguration.id}
                        as={createTemplatePage?.as}
                        href={createTemplatePage?.href ?? ''}>
                        <Button color='purple' buttonSize='small' onClick={showWarningNotSubToolConfigured}>
                            {String(createNewCustomTrigger)}
                        </Button>
                    </Link>
                    <Link
                        showLink
                        as={configurationPage?.as}
                        href={{ pathname: configurationPage?.href, query: { subTool: translatedSubToolQueryParam } }}>
                        <ButtonStyled buttonSize='small' color='purple' hasIcon isOutlined>
                            <SettingIcon />
                        </ButtonStyled>
                    </Link>
                </CustomTriggerButtonsContainer>
            )}
        </HeaderStyled>
    );
};
