import { Button, Link, LinkProps, Text } from '@components';
import type { MarketingAutomationResultStatus } from './marketingAutomationResult';
import { SubToolStatusButtonStyled } from './styles';

export type SubToolStatusRedirectToLink = Omit<LinkProps, 'children'>;

type SubToolStatusButtonProps = {
    status: MarketingAutomationResultStatus;
    isLoading?: boolean;
    statusButtonDescription: string;
    message: string;
    redirectToLink: SubToolStatusRedirectToLink;
};

export const SubToolStatusButton = ({
    status,
    message,
    redirectToLink,
    statusButtonDescription,
    isLoading = false
}: SubToolStatusButtonProps) => {
    if (status === 'active') {
        return <></>;
    }

    return (
        <SubToolStatusButtonStyled status={status}>
            <Text as='span' size='xs'>
                {message}
            </Text>
            <Link {...redirectToLink}>
                <Button buttonSize='small' color='purple' inLoading={isLoading}>
                    {statusButtonDescription}
                </Button>
            </Link>
        </SubToolStatusButtonStyled>
    );
};
