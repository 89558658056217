import { CanceledError } from 'axios';
import { Event, EventHint } from '@sentry/nextjs';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { consoleColours } from './consoleFunctions';

export const onBeforeSendEvent = (event: Event, hint: EventHint, isServerSide = false) => {
    console.info(
        `${consoleColours.foreground.cyan}${isServerSide ? 'Server' : 'Client'} error code:`,
        event.event_id,
        consoleColours.reset
    );

    const error = hint.originalException as EnviouApiResponseError;

    if (error.name === EnviouApiResponseError.name || error.name === CanceledError.name) {
        if (error.name === EnviouApiResponseError.name) {
            console.info(
                consoleColours.foreground.yellow,
                `Not capturing ${event.event_id}, as it was already handled by back-end.`,
                error.code ? `For the original error, see: ${error.code}` : '',
                consoleColours.reset
            );
        }
        return null;
    }

    return event;
};
