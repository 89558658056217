import { ReactNode } from 'react';
import Head from 'next/head';
import { Footer, Header } from '@components';
import { LayoutContentStyled, LayoutStyled } from './styles';

type PreCheckoutLayoutProps = {
    children: ReactNode;
};

export const PreCheckoutLayout = ({ children }: PreCheckoutLayoutProps) => {
    return (
        <LayoutStyled>
            <Head>
                <title>Pré-Checkout</title>
            </Head>

            <Header />
            <LayoutContentStyled>{children}</LayoutContentStyled>
            <Footer contentSize='medium' />
        </LayoutStyled>
    );
};
