import groupBy from 'lodash/groupBy';
import lowerFirst from 'lodash/lowerFirst';
import {
    ContactConfiguration,
    ContactConfigurationStatusEnum,
    SendingTechnologyTypeEnum,
    SubToolConfiguration,
    SubToolIdEnum,
    SubToolTemplateConfiguration
} from '@models';
import { WHATSAPP_API_URL, convertToPhoneModel, PAINELN2_CART_RECOVERY_STATISTICS_LINK } from '@utils';
import {
    ContactConfigurationFormData,
    SubToolConfigurationFormData
} from '~/components/templates/subTools/configurationTemplate/validation';
import { SendTestEmail as BankSlipReminderSendTestEmail } from './bankSlipReminderApiService';
import { SendTestEmail as CartRecoverySendTestEmail } from './cartRecoveryApiService';
import { SendTestEmail, SendTestSMS, TestEmailRequestPayload, TestSmsRequestPayload } from './subToolApiService';

export const GetSubToolConfigurationStatusByName = (statusName: string, translationData: unknown) => {
    const configurationStatus = lowerFirst(statusName);
    return translationData[configurationStatus];
};

export const ParseFormDataToSubToolConfiguration = (
    subToolConfigurationId: number,
    subToolConfigurationFormData: SubToolConfigurationFormData
): SubToolConfiguration => {
    return {
        id: subToolConfigurationId,
        status: subToolConfigurationFormData.status,
        contactConfigurations: subToolConfigurationFormData?.contactConfigurations?.map(
            (contact) =>
                ({
                    subToolConfiguration: {
                        id: subToolConfigurationId
                    },
                    senderName: contact.senderName ?? '',
                    senderContact: contact.senderContact ?? '',
                    contactAnswer: contact.contactAnswer ?? '',
                    sendingTechnology: contact?.sendingTechnology,
                    status: contact?.isActive
                        ? ContactConfigurationStatusEnum.Active
                        : ContactConfigurationStatusEnum.Inactive,
                    id: contact?.contactConfigurationId ?? undefined
                } as ContactConfiguration)
        )
    } as SubToolConfiguration;
};

export const ParseSubToolConfigurationToFormData = (
    subToolConfiguration: SubToolConfiguration
): SubToolConfigurationFormData => {
    const formData: SubToolConfigurationFormData = {
        status: subToolConfiguration.status,
        repurchaseConsideredPeriod: subToolConfiguration.repurchaseConsideredPeriod,
        contactConfigurations: subToolConfiguration?.contactConfigurations?.map(
            (contact) =>
                ({
                    senderName:
                        contact.sendingTechnology === SendingTechnologyTypeEnum.Email ? contact.senderName ?? '' : '',
                    senderContact: contact.senderContact ?? '',
                    contactAnswer: contact.contactAnswer ?? '',
                    isActive: contact?.status === ContactConfigurationStatusEnum.Active,
                    sendingTechnology: contact?.sendingTechnology,
                    contactConfigurationId: contact?.id ?? undefined
                } as ContactConfigurationFormData)
        )
    };

    return formData;
};

export const HandleSendTestSMS = async (payload: TestSmsRequestPayload) => {
    if (!payload) {
        console.log({ payload });

        return;
    }

    return await SendTestSMS(payload);
};

export const HandleSendTestEmail = async (props: TestEmailRequestPayload) => {
    if (props.subToolId === SubToolIdEnum.CartRecovery) {
        return await CartRecoverySendTestEmail(props);
    } else if (props.subToolId === SubToolIdEnum.BankSlipReminder) {
        return await BankSlipReminderSendTestEmail(props);
    }

    return await SendTestEmail(props);
};

export const GroupTemplatesBySubTool = (templates?: Array<SubToolTemplateConfiguration>) => {
    if (!templates) {
        return templates;
    }

    return groupBy(templates, (template) => template.subToolConfiguration.subTool.id);
};

/**
 * Filters sending technologies such that only these that are configured as 'active' in contact configurations
 * are returned.
 *
 * @param sendingTechnologies The initial sending technologies.
 * @param contactConfigurations The contact configurations which will check which sending technologies are active.
 * @returns The filtered sending technologies.
 */
export const FilterSendingTechnologiesByActiveContactConfiguration = (
    sendingTechnologies: SendingTechnologyTypeEnum[],
    contactConfigurations?: ContactConfiguration[]
): SendingTechnologyTypeEnum[] =>
    sendingTechnologies.filter(
        (sendingTechnology) =>
            contactConfigurations?.find(
                (configuration) =>
                    configuration.sendingTechnology === sendingTechnology &&
                    configuration.status === ContactConfigurationStatusEnum.Active
            ) !== undefined
    );

export const GetCartStatisticsRedirectURL = () => {
    return PAINELN2_CART_RECOVERY_STATISTICS_LINK;
};

export const SendManualWhatsAppMessage = (phoneNumber: string, message: string) => {
    const phoneConverted = convertToPhoneModel(phoneNumber);

    if (!phoneConverted.ddi && phoneConverted.ddd) {
        window && window.open(`${WHATSAPP_API_URL}send?phone=55${phoneNumber}&text=${encodeURIComponent(message)}`);
    } else {
        window && window.open(`${WHATSAPP_API_URL}send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`);
    }
};
