import { ChangeEvent, useRef, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import { Button, CardBody, CardHeader, CodeBlock, Heading, InputGroup, Text, Box } from '@components';
import { useTranslator } from '@hooks';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles, SubmitHandler } from '@unform/core';
import { blurInBase64, DownloadFileToDesktop, getFilenameFromURL, normalizeUrl } from '@utils';
import ImageFileIcon from '~/assets/svg/icons/icon__image-file.svg';
import { InfoTooltip } from '~/components/elements/tooltip/infoTooltip';
import { routesName } from '~/locales/route';
import {
    DownloadPushSdksAsync,
    GetPushConfigurationIconUrl,
    SaveConfigurationAsync
} from '~/services/pushConfigurationServiceApi';
import { PageTitle } from '~/styles';
import { CardStyled, FormContent, FormStyled, InputBlockStyled, TooltipContentStyled } from './styles';
import { useValidation, ACCEPT_IMAGES, ICON_INPUT_NAME, PushConfigurationFormData } from './validation';

const SERVICE_WORKER_FILE_NAME = 'enviou-service-worker.js';
const ICON_SIZE = 96;

export type PushConfigurationTemplateProps = {
    iconUrl?: string;
};

export const PushConfigurationTemplate = ({ iconUrl }: PushConfigurationTemplateProps) => {
    const [file, setFile] = useState<File>(null);
    const [imageUrl, setImageUrl] = useState<string>(iconUrl);
    const [isLoading, setIsLoading] = useState(false);

    const {
        common,
        buttons,
        pages: { sendingTechnologySetting }
    } = useTranslator();
    const { title, pushConfiguration } = sendingTechnologySetting;
    const { user } = useAuth();
    const formRef = useRef<FormHandles>();
    const validateForm = useValidation(formRef, !!iconUrl);
    const { success, error } = useAlert();
    const { mutate } = useSWRConfig();
    const router = useRouter();

    const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (files) {
            const currentFile = files[0];

            if (currentFile?.size > 0) {
                const reader = new FileReader();
                reader.onload = (event) => setImageUrl(event.target.result as string);
                reader.readAsDataURL(currentFile);
            } else {
                setImageUrl(iconUrl ?? null);
                formRef.current.clearField(ICON_INPUT_NAME);
            }

            setFile(currentFile);
        }
    };

    const handleDownloadSdkFiles = async () => {
        const response = await DownloadPushSdksAsync();
        const url = URL.createObjectURL(response.data);

        DownloadFileToDesktop(url, SERVICE_WORKER_FILE_NAME);
    };

    const handleSubmit: SubmitHandler<PushConfigurationFormData> = async (data, _, event) => {
        const { alerts } = sendingTechnologySetting.pushConfiguration;
        try {
            setIsLoading(true);
            const isValidForm = await validateForm(data, event);

            if (isValidForm) {
                const formData = new FormData(event.target as HTMLFormElement);

                await SaveConfigurationAsync(formData);

                await mutate({ url: GetPushConfigurationIconUrl });

                success(String(alerts.success), null, {
                    onClose: async () => await router.push(routesName.root)
                });
            }
        } catch (exception) {
            error(String(alerts.failedTitle), String(alerts.failed));
            captureException(exception);
        } finally {
            setIsLoading(false);
        }
    };

    const serviceWorkerPath = new URL(
        SERVICE_WORKER_FILE_NAME,
        normalizeUrl(user?.storeUrl) ?? 'https://www.sualoja.com.br/'
    ).href;
    const filename = file?.name ?? getFilenameFromURL(iconUrl);

    return (
        <FormStyled ref={formRef} onSubmit={handleSubmit}>
            <PageTitle>{String(title)}</PageTitle>
            <CardStyled type='group'>
                <CardHeader title={String(pushConfiguration.title)} />
                <CardBody>
                    <FormContent>
                        <div>
                            <Heading as='h6'>
                                {String(pushConfiguration.content.addIcon.label)}
                                <InfoTooltip>
                                    <TooltipContentStyled>
                                        {HTMLReactParser(String(pushConfiguration.content.addIcon.tooltip))}
                                    </TooltipContentStyled>
                                </InfoTooltip>
                            </Heading>
                            <InputBlockStyled>
                                <Box>
                                    {!imageUrl && <ImageFileIcon />}
                                    {imageUrl && (
                                        <Image
                                            src={imageUrl}
                                            height={ICON_SIZE}
                                            width={ICON_SIZE}
                                            alt=''
                                            objectFit='contain'
                                            placeholder='blur'
                                            blurDataURL={blurInBase64(ICON_SIZE, ICON_SIZE)}
                                            onError={() => setImageUrl(null)}
                                        />
                                    )}
                                </Box>

                                <InputGroup
                                    accept={ACCEPT_IMAGES.join(',')}
                                    buttonText={String(buttons.chooseFile)}
                                    filename={filename}
                                    inputType='file'
                                    inputName={ICON_INPUT_NAME}
                                    inputPlaceHolder={String(common.inputGroup.fileType.placeholder)}
                                    onChange={handleFileSelected}
                                />
                            </InputBlockStyled>

                            <Text as='span' size='sm'>
                                {String(pushConfiguration.content.form.supportedFiles)}
                            </Text>
                        </div>
                    </FormContent>
                    <div>
                        <Heading as='h6'>{String(pushConfiguration.content.sdkIntallation)}</Heading>
                        <Button color='purple' buttonSize='small' onClick={handleDownloadSdkFiles}>
                            {String(buttons.downloadSdkFiles)}
                        </Button>
                    </div>
                    <div>
                        <Heading as='h6'>
                            {String(pushConfiguration.content.uploadFileExample.label)}
                            <InfoTooltip>
                                <TooltipContentStyled>
                                    {String(pushConfiguration.content.uploadFileExample.tooltip)}
                                </TooltipContentStyled>
                            </InfoTooltip>
                            <a href={serviceWorkerPath} target='_blank' rel='noopener noreferrer'>
                                <Button styledAs='link' color='purple'>
                                    {String(buttons.consult)}
                                </Button>
                            </a>
                        </Heading>
                        <CodeBlock>{serviceWorkerPath}</CodeBlock>
                    </div>
                </CardBody>
            </CardStyled>
            <Button type='submit' color='purple' buttonSize='small' inLoading={isLoading} disabled={isLoading}>
                {String(buttons.save)}
            </Button>
        </FormStyled>
    );
};
