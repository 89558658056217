import { Text } from '@components';
import { useTranslator } from '@hooks';
import { formatDate } from '@utils';
import { ExportData } from './exportsData';
import { LatestExportsStyled } from './styles';

type LatestExportsProps = {
    latestExports: Array<ExportData>;
};

export const LatestExports = ({ latestExports = [] }: LatestExportsProps) => {
    const {
        pages: {
            automationStatistics: { exportDataModal }
        }
    } = useTranslator();

    if (latestExports?.length === 0) {
        return <></>;
    }

    return (
        <LatestExportsStyled>
            <Text as='h4' size='xs'>
                {String(exportDataModal.latestExports)}
            </Text>
            <ul>
                {latestExports.map((exportData, index) => (
                    <li key={index}>
                        <Text as='time' size='xxs' title={formatDate(new Date(exportData.downloadDate), 'datetime')}>
                            {formatDate(new Date(exportData.downloadDate), 'relative')}
                        </Text>
                        <Text
                            as='a'
                            size='xxs'
                            href={exportData.fileUrl}
                            download={exportData.fileName}
                            target='_blank'
                            rel='noreferrer noopener'>
                            {exportData.fileName}
                        </Text>
                    </li>
                ))}
            </ul>
        </LatestExportsStyled>
    );
};
