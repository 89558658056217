import theme from '~/styles/theme';
import { isDevelopment } from './environment';
import { isBrowser } from './next';

/* istanbul ignore file */
export const consoleColours = {
    reset: '\x1b[0m',
    bright: '\x1b[1m',
    dim: '\x1b[2m',
    underscore: '\x1b[4m',
    blink: '\x1b[5m',
    reverse: '\x1b[7m',
    hidden: '\x1b[8m',

    foreground: {
        black: '\x1b[30m',
        red: '\x1b[31m',
        green: '\x1b[32m',
        yellow: '\x1b[33m',
        blue: '\x1b[34m',
        magenta: '\x1b[35m',
        cyan: '\x1b[36m',
        white: '\x1b[37m',
        crimson: '\x1b[38m'
    },
    background: {
        black: '\x1b[40m',
        red: '\x1b[41m',
        green: '\x1b[42m',
        yellow: '\x1b[43m',
        blue: '\x1b[44m',
        magenta: '\x1b[45m',
        cyan: '\x1b[46m',
        white: '\x1b[47m',
        crimson: '\x1b[48m'
    }
};

export const initLogger = () => {
    if (isBrowser() && isDevelopment()) {
        global.logger = {
            log: window.console.log.bind(
                window.console,
                '%cENVIOU LOG: ',
                `color: ${theme.colors.purple};font-weight:bold`
            ),
            error: window.console.error.bind(
                window.console,
                '%cENVIOU ERROR: ',
                `color: ${theme.colors.sangria};font-weight:bold`
            ),
            info: window.console.info.bind(
                window.console,
                '%cENVIOU INFO: ',
                `color: ${theme.colors.blue};font-weight:bold`
            ),
            warn: window.console.warn.bind(
                window.console,
                '%cENVIOU WARN: ',
                `color: ${theme.colors.yellow};font-weight:bold`
            )
        };
    } else {
        const noFunction = () => null;
        global.logger = {
            log: noFunction,
            error: noFunction,
            warn: noFunction,
            info: noFunction
        };
    }
};
