import { Heading, Text } from '@components';
import { useNumberFunctions, useReplace, useTranslator } from '@hooks';
import { CustomerPlanType, SubscriptionTool } from '@models';
import { InvoiceDetailsList } from '../invoiceDetailsList';
import { InvoiceDetailsGroupStyled } from '../styles';
import { ToolsDetailsData } from '../toolsDetails';
import { AutomationPlanDetailsStyled, CurrentAndNewAutomationPlanDetailsStyled } from './styles';

type CurrentAndNewAutomationPlanDetailsProps = {
    title: string;
    currentPlan: SubscriptionTool;
    newPlan: SubscriptionTool;
};

type CurrentAndNewAutomationPlanDetailsItemProps = ToolsDetailsData & {
    title: string;
    subscriptionTool: SubscriptionTool;
};

const CurrentAndNewAutomationPlanDetailsItem = ({
    subscriptionTool,
    title
}: CurrentAndNewAutomationPlanDetailsItemProps) => {
    const { subgroups } = useTranslator().checkoutPage.invoiceDetails;
    const { formatNumber } = useNumberFunctions();
    const replace = useReplace();

    const toolLabel =
        subscriptionTool?.type === CustomerPlanType.BulkSendingTool
            ? subgroups.automationPlans.shipments
            : subgroups.automationPlans.credits;

    return (
        <AutomationPlanDetailsStyled>
            <Text as='span' size='xs'>
                {title}
            </Text>
            <Text as='strong' size='md'>
                {replace(toolLabel, formatNumber(subscriptionTool?.totalCredits ?? 0))}
            </Text>
            <InvoiceDetailsList subscriptionTool={subscriptionTool} />
        </AutomationPlanDetailsStyled>
    );
};

export const CurrentAndNewAutomationPlanDetails = ({
    currentPlan,
    newPlan,
    title
}: CurrentAndNewAutomationPlanDetailsProps) => {
    const { subgroups } = useTranslator().checkoutPage.invoiceDetails;
    return (
        <InvoiceDetailsGroupStyled>
            <Heading as='h6'>{title}</Heading>
            <CurrentAndNewAutomationPlanDetailsStyled>
                <CurrentAndNewAutomationPlanDetailsItem
                    subscriptionTool={currentPlan}
                    title={String(subgroups.automationPlans.previounsPlan)}
                />
                <CurrentAndNewAutomationPlanDetailsItem
                    subscriptionTool={newPlan}
                    title={String(subgroups.automationPlans.newPlan)}
                />
            </CurrentAndNewAutomationPlanDetailsStyled>
        </InvoiceDetailsGroupStyled>
    );
};
