import { useMemo } from 'react';
import { Button, Link, Text } from '@components';
import { useTranslatedRoute, useTranslator } from '@hooks';
import { SubscriptionStatusEnum } from '@models';
import { SOCIAL_ADDRESS } from '@utils';
import { routesName } from '~/locales/route';
import { ContentStyled } from './styles';

type InactiveToolContentProps = {
    isActiveTool: boolean;
    subscriptionStatus: SubscriptionStatusEnum;
};

const activeStatus = [SubscriptionStatusEnum.Active, SubscriptionStatusEnum.BillingByThirdParty];

export const InactiveToolContent = ({ isActiveTool, subscriptionStatus }: InactiveToolContentProps) => {
    const { messages, buttons } = useTranslator().pages.home.inactiveToolMessage;
    const translateRoute = useTranslatedRoute();

    const { description, showSubscribeNowButton, isSubscriptionActive } = useMemo(() => {
        const isSubscriptionActive = activeStatus.includes(subscriptionStatus);

        let description = '';

        if (!isActiveTool && isSubscriptionActive) {
            description = String(messages.inactiveTool);
        } else {
            if (subscriptionStatus === SubscriptionStatusEnum.AwaitingPayment) {
                description = String(messages.awaitingPayment);
            } else if (subscriptionStatus === SubscriptionStatusEnum.CanceledByNonpayment) {
                description = String(messages.canceled);
            } else {
                description = String(messages.errorProcessingRecurrence);
            }
        }

        const showSubscribeNowButton =
            (!isSubscriptionActive && subscriptionStatus !== SubscriptionStatusEnum.AwaitingPayment) ||
            subscriptionStatus === SubscriptionStatusEnum.ErrorProcessingRecurrence ||
            (!isActiveTool && isSubscriptionActive);

        return {
            isSubscriptionActive,
            showSubscribeNowButton,
            description
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionStatus, isActiveTool]);

    if (isActiveTool && isSubscriptionActive) {
        return <></>;
    }

    return (
        <ContentStyled onClick={(event) => event.stopPropagation()}>
            <Text as='strong' size='6xl'>
                {description}
            </Text>
            <div>
                {showSubscribeNowButton && (
                    <Link
                        href={{
                            pathname: routesName.checkout.base
                        }}
                        as={translateRoute(routesName.checkout.base)}>
                        <Button color='purple' buttonSize='small'>
                            {subscriptionStatus !== SubscriptionStatusEnum.ErrorProcessingRecurrence
                                ? String(buttons.subscribeNow)
                                : String(buttons.updateData)}
                        </Button>
                    </Link>
                )}

                {description !== String(messages.canceled) &&
                    subscriptionStatus !== SubscriptionStatusEnum.ErrorProcessingRecurrence && (
                        <a href={SOCIAL_ADDRESS.ZENDESK} target='_blank' rel='noreferrer'>
                            <Button color='purple' buttonSize='small' isOutlined className='documentation-button'>
                                {String(buttons.seeDocumentation)}
                            </Button>
                        </a>
                    )}
            </div>
        </ContentStyled>
    );
};
