import Minus from '~/assets/svg/icons/icon__minus.svg';
import Plus from '~/assets/svg/icons/icon__plus.svg';
import { useNoticeCard } from './root';
import { CloseButton } from './styles';

export const NoticeCardCloseButton = () => {
    const { isOpen, onOpenChange } = useNoticeCard();

    return (
        <CloseButton onClick={() => onOpenChange(!isOpen)}>
            <Minus opacity={Number(isOpen)} aria-label='close notice card' />
            <Plus opacity={Number(!isOpen)} aria-label='open notice card' />
        </CloseButton>
    );
};
