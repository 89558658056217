import { ReactNode, forwardRef } from 'react';
import { TabStyled } from './styles';

export type TabProps = {
    children: ReactNode;
};

export const Tab = forwardRef<HTMLDivElement, TabProps>(({ children }, ref) => {
    return <TabStyled ref={ref}>{children}</TabStyled>;
});

Tab.displayName = 'Tab';
