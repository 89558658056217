import { useTranslator } from '@hooks';
import { RepurchaseItemType } from '@providers';
import { InfoTooltip, Text } from '~/components/elements';
import { TooltipContentStyled } from '../../styles';
import { ListItemStyled } from '../styles';
import { ItemConfigurationListHeaderStyled } from './styles';

type ItemConfigurationListHeaderProps = {
    itemType?: RepurchaseItemType;
};

export const ItemConfigurationListHeader = ({ itemType = 'general' }: ItemConfigurationListHeaderProps) => {
    const {
        pages: {
            manageRepurchaseItem: {
                sections: {
                    setup: { listHeader }
                }
            }
        },
        enums: { subToolTemplateConfigurationType }
    } = useTranslator();

    const itemTypeLabel =
        itemType === 'general'
            ? String(listHeader.generalTemplateType)
            : String(subToolTemplateConfigurationType[itemType]);

    return (
        <ListItemStyled className='list-header'>
            <ItemConfigurationListHeaderStyled>
                <Text as='span' size='lg' fontWeight='semiBold'>
                    {itemTypeLabel}
                </Text>
                <Text as='span' size='lg' fontWeight='semiBold'>
                    {String(listHeader.templateUsed.label)}
                    <InfoTooltip>
                        <TooltipContentStyled>{String(listHeader.templateUsed.tooltip)}</TooltipContentStyled>
                    </InfoTooltip>
                </Text>
                <Text as='span' size='lg' fontWeight='semiBold'>
                    {String(listHeader.deadlineForRepurchase.label)}
                    <InfoTooltip>
                        <TooltipContentStyled>{String(listHeader.deadlineForRepurchase.tooltip)}</TooltipContentStyled>
                    </InfoTooltip>
                </Text>
            </ItemConfigurationListHeaderStyled>
        </ListItemStyled>
    );
};
