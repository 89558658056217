import { useTranslator } from '@hooks';
import { SubscriptionInvoice, SubTool } from '@models';
import { CardHeader } from '~/components/elements';
import { PageTitle } from '~/styles';
import { BankSlipInformation } from './bankSlipInformation';
import { BankSlipCardBodyStyled, BankSlipCardSyled, Container } from './styles';
import { SubscriptionTools } from './subscriptionTools';

type BankSlipCardType = {
    subscriptionInvoice?: SubscriptionInvoice;
    subTools?: Array<SubTool>;
};

export const BankSlipCard = ({ subscriptionInvoice, subTools }: BankSlipCardType) => {
    const { bankSlipPage } = useTranslator();

    return (
        <Container>
            <div>
                <PageTitle>{String(bankSlipPage.head.title)}</PageTitle>

                <BankSlipCardSyled type='group'>
                    <CardHeader title={String(bankSlipPage.bankSlipInformation.title)} />
                    <BankSlipCardBodyStyled type='group'>
                        <BankSlipInformation subscriptionInvoice={subscriptionInvoice} />
                    </BankSlipCardBodyStyled>
                </BankSlipCardSyled>
            </div>
            <BankSlipCardSyled type='group'>
                <CardHeader
                    title={String(bankSlipPage.subscriptionTools.title)}
                    description={String(bankSlipPage.subscriptionTools.description)}
                />
                <BankSlipCardBodyStyled type='group'>
                    <SubscriptionTools subTools={subTools} />
                </BankSlipCardBodyStyled>
            </BankSlipCardSyled>
        </Container>
    );
};
