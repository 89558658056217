import Image from 'next/image';
import { Heading, Text } from '@components';
import { useTranslator } from '@hooks';
import {
    WindowsContainer,
    ImageIconStyle,
    ChromeIconStyle,
    WifiIconStyle,
    VolumeIconStyle,
    BaterryIconStyled,
    TaskbarStyled,
    MoreHorizontalStyled,
    CloseIconStyled,
    NotificationContainerStyled
} from './styles';
import { ViewModeProps } from '..';

export const WindowsViewElement = ({
    body,
    title,
    iconUrl,
    imageUrl,
    domain,
    currentDate,
    currentTime,
    selectedPushAction,
    pushActionList
}: ViewModeProps) => {
    const {
        subTools: {
            pushTemplateEditor: {
                pushActionButtons: { buttonText }
            }
        }
    } = useTranslator();
    return (
        <WindowsContainer>
            <NotificationContainerStyled>
                {imageUrl ? (
                    <Image
                        width={800}
                        height={600}
                        src={imageUrl}
                        alt='windos OS push notification preview'
                        objectFit='cover'
                    />
                ) : (
                    <div className='no-image'>
                        <ImageIconStyle />
                    </div>
                )}
                <div className='notification-header'>
                    <div className='browser-info'>
                        <ChromeIconStyle />
                        <Text className='browser-name'>Google Chrome</Text>
                    </div>
                    <div className='notification-header-icons'>
                        <MoreHorizontalStyled />
                        <CloseIconStyled />
                    </div>
                </div>
                <div className='notification-body'>
                    <div className='icon'>
                        {iconUrl ? (
                            <Image
                                width={64}
                                height={64}
                                src={iconUrl}
                                alt='windos OS push notification preview'
                                objectFit='contain'
                            />
                        ) : (
                            <ImageIconStyle className='no-image' />
                        )}
                    </div>
                    <div className='notification-message'>
                        <Heading className='title'>{title}</Heading>
                        <Text className={'text'}>{body}</Text>
                        <Text className='domain'>{domain}</Text>
                    </div>
                </div>
                <div className='notification-actions'>
                    {selectedPushAction === 'pushOneButton' && (
                        <Text className='action-button'>
                            {pushActionList[selectedPushAction][0].title || String(buttonText)}
                        </Text>
                    )}
                    {selectedPushAction === 'pushTwoButtons' &&
                        pushActionList[selectedPushAction].map((button, index) => (
                            <Text key={index} className='action-button'>
                                {button?.title || String(buttonText)}
                            </Text>
                        ))}
                </div>
            </NotificationContainerStyled>

            <TaskbarStyled>
                <WifiIconStyle />
                <VolumeIconStyle />
                <BaterryIconStyled />
                <div className='date-container'>
                    <Text>{currentTime}</Text>
                    <Text>{currentDate}</Text>
                </div>
            </TaskbarStyled>
        </WindowsContainer>
    );
};
