import { routes } from '~/locales/route';
import { replaceRouteVariable } from './routeFunctions';
import { ReplaceData } from './stringFunctions';

export const translateRoute = (
    route: string,
    locale = process.env.NEXT_PUBLIC_DEFAULT_CULTURE,
    params: ReplaceData = ''
): string => {
    if (!route) {
        return;
    }

    if (route.startsWith('//')) {
        route = route.slice(1);
    }

    if (route.startsWith('/') === false) {
        route = `/${route}`;
    }

    const translatedRoute = routes[route]?.[locale];

    if (translatedRoute) {
        if (params) {
            let url = replaceRouteVariable(translatedRoute, params);

            if (url.endsWith('?')) {
                url = url.slice(0, -1);
            }

            return url;
        }

        return translatedRoute;
    }

    return route;
};
