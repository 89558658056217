import styled, { css } from 'styled-components';
import { Button } from '~/components/elements/button';

export const GroupActionStyled = styled.div`
    display: flex;
    gap: 1.6rem;

    button {
        height: fit-content;
        white-space: nowrap;
    }
`;

export const ButtonStyled = styled(Button)<{ hasIcon: boolean; isOutlined: boolean }>`
    height: fit-content;
    white-space: nowrap;

    ${({ hasIcon }) =>
        hasIcon &&
        css`
            padding: 0.2rem;
        `}

    ${({ isOutlined }) =>
        isOutlined &&
        css`
            background-color: ${({ theme }) => theme.colors.white};
        `}    

    & > svg {
        height: 3.3rem;
        width: 3.3rem;
    }
`;
