import { FormGroup } from '@components';
import {
    AutomationPlans,
    BillingData,
    BillingResponsible,
    EmailMarketing,
    InvoiceDetails,
    PaymentMethods,
    SelectBillingPeriod,
    ResumeInformation
} from '@containers';
import { usePrevPropsAndState } from '@hooks';
import { CheckoutStepsEnum } from '@providers';
import { Scope } from '@unform/core';
import { scrollTo } from '@utils';
import { CheckoutContentStyled } from './styles';

/** Time in milesseconds to perform the scroll after changing the step.
 * This time is required to ensure frame-motion animation compatibility with page scrolling */
const SCROLL_TIMEOUT = 100;

export const CheckoutContent = ({ step = CheckoutStepsEnum.subscriptionPlan }) => {
    const { prevProps } = usePrevPropsAndState<CheckoutStepsEnum>(step);

    if (prevProps !== null && prevProps !== step) {
        setTimeout(() => {
            scrollTo('#multiStepMenu');
        }, SCROLL_TIMEOUT);
    }

    const currentStep = step === CheckoutStepsEnum.suspendedPlan ? CheckoutStepsEnum.subscriptionPayment : step;

    switch (currentStep) {
        case CheckoutStepsEnum.subscriptionPlan:
            return (
                <Scope path='subscriptionPlan'>
                    <AutomationPlans />
                    <EmailMarketing />
                    <SelectBillingPeriod />
                    <ResumeInformation />
                </Scope>
            );

        case CheckoutStepsEnum.subscriptionData:
            return (
                <Scope path='subscriptionData'>
                    <BillingResponsible />
                    <BillingData />
                </Scope>
            );

        case CheckoutStepsEnum.subscriptionPayment:
            return (
                <Scope path='subscriptionPayment'>
                    <CheckoutContentStyled>
                        <FormGroup flexGrid={{ mobileAndTablet: 12, desktop: 7 }}>
                            <PaymentMethods />
                        </FormGroup>
                        <FormGroup flexGrid={{ mobileAndTablet: 12, desktop: 5 }}>
                            <InvoiceDetails />
                        </FormGroup>
                    </CheckoutContentStyled>
                </Scope>
            );
    }
};
