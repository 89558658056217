import { SubTool } from '@models';
import { SubToolTutorialContent } from './styles';
import { SubToolTutorialItem } from './tutorialItem';

type TutorialSecctionProps = {
    subTools?: Array<SubTool>;
};

export const TutorialSection = ({ subTools = [] }: TutorialSecctionProps) => {
    return (
        <SubToolTutorialContent>
            <ul>
                {subTools.map((subTool) => (
                    <SubToolTutorialItem key={subTool.id} subToolId={subTool.id} videoUrl={subTool.videoUrl} />
                ))}
            </ul>
        </SubToolTutorialContent>
    );
};
