import { ReactNode } from 'react';
import { ToggleButtonStylesProps, ToggleButtonStyled } from './styles';

export type ToggleButtonProps = ToggleButtonStylesProps & {
    children?: ReactNode;
    value: string | number;
    onChange?: (value: string | number) => void;
    testId?: string;
};

export const ToggleButton = ({
    children,
    value,
    onChange,
    testId,
    inputSize = 'large',
    ...args
}: ToggleButtonProps) => (
    <ToggleButtonStyled
        type='button'
        value={value}
        inputSize={inputSize}
        onClick={() => onChange(value)}
        data-testid={testId}
        {...args}>
        {children}
    </ToggleButtonStyled>
);
