import { ReactNode } from 'react';
import { SelectContent } from './content';
import { SelectContext } from './context';
import { SelectOption } from './option';
import { SelectTrigger } from './trigger';
import { useSelect, type UseSelectProps } from './useSelect';

export type SelectDataType = { [key: string]: unknown } & { id?: string | number; label?: string };

export type SelectData = {
    id: string | number;
    label: string;
    data?: SelectDataType;
    children?: ReactNode;
};

export type SelectProps = UseSelectProps & {
    children: ReactNode;
};

export const SelectComponent = ({ children, ...props }: SelectProps) => {
    const data = useSelect(props);

    return <SelectContext.Provider value={{ ...data }}>{children}</SelectContext.Provider>;
};

export const Select = {
    Root: SelectComponent,
    Trigger: SelectTrigger,
    Content: SelectContent,
    Option: SelectOption
};
