import {
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
    useMemo
} from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { usePrevPropsAndState } from '@hooks';
import {
    AddressCountry,
    AddressState,
    CouponAttemptResult,
    BillingPeriod,
    PaymentMethod,
    Subscription,
    SubTool,
    Tool,
    PaymentMethodTypeEnum,
    SubscriptionStatusEnum
} from '@models';
import { FormHandles } from '@unform/core';
import { addDays, BANK_SLIP_COMPENSATION_OFFSET, DateFormatEnum, formatDate } from '@utils';
import { CurrentSubscriptionDetailsData } from '~/services/subscriptionApiService';
import { CreateSubscriptionFormData } from '~/services/subscriptionService';
import { CheckIfInvoiceIsDueForMoreThanThreeMonths } from '~/services/subscriptionStatusService';
import {
    SubscriptionFormData,
    SubscriptionPaymentFormData,
    subscriptionReducer,
    SubscriptionReducerActions,
    SubscriptionReducerActionTypesEnum
} from './reducers/subscriptionReducer';

type CheckoutContextData = {
    subscription: Subscription;
    setSubscription: Dispatch<SetStateAction<Subscription>>;
    currentStepIndex: number;
    setCurrentStepIndex: Dispatch<SetStateAction<number>>;
    isNewCustomer: boolean;
    setIsNewCustomer: Dispatch<SetStateAction<boolean>>;
    subscriptionFormData: SubscriptionFormData;
    dispatchSubscriptionFormData: Dispatch<SubscriptionReducerActions>;
    couponAttemptResult?: CouponAttemptResult;
    activateCoupon?: (couponAttemptResult: CouponAttemptResult) => void;
    formRef?: MutableRefObject<FormHandles>;
    paymentMethodFormRef?: MutableRefObject<FormHandles>;
    tools: Array<Tool>;
    setTools: Dispatch<SetStateAction<Array<Tool>>>;
    subTools: Array<SubTool>;
    setSubTools: Dispatch<SetStateAction<Array<SubTool>>>;
    billingPeriods: Array<BillingPeriod>;
    setBillingPeriods: Dispatch<SetStateAction<Array<BillingPeriod>>>;
    countries: Array<AddressCountry>;
    setCountries: Dispatch<SetStateAction<Array<AddressCountry>>>;
    states: Array<AddressState>;
    setStates: Dispatch<SetStateAction<Array<AddressState>>>;
    paymentMethods: Array<PaymentMethod>;
    setPaymentMethods: Dispatch<SetStateAction<Array<PaymentMethod>>>;
    initialDataSubscriptionForm?: SubscriptionFormData;
    isUpdatingPaymentMethod: boolean;
    setIsUpdatingPaymentMethod: Dispatch<SetStateAction<boolean>>;
    isUpdatingBillingResponsible: boolean;
    setIsUpdatingBillingResponsible: Dispatch<SetStateAction<boolean>>;
    isUpdatingBillingData: boolean;
    setIsUpdatingBillingData: Dispatch<SetStateAction<boolean>>;
    isSuspendedPlan?: boolean;
    setIsSuspendedPlan?: Dispatch<SetStateAction<boolean>>;
    goToFirstStep?: () => void;
    oldPaymentMethod?: SubscriptionPaymentFormData;
    setOldPaymentMethod: Dispatch<SetStateAction<SubscriptionPaymentFormData>>;
    updateSubscription: (subscription: Subscription) => void;
};

type CheckoutProviderProps = {
    children?: ReactNode;
    subscription?: Subscription;
    tools?: Array<Tool>;
    subTools?: Array<SubTool>;
    billingPeriods?: Array<BillingPeriod>;
    countries?: Array<AddressCountry>;
    paymentMethods?: Array<PaymentMethod>;
    initialDataSubscriptionForm?: SubscriptionFormData;
    initialStepNumber?: CheckoutStepsEnum;
    isSuspendedPlan?: boolean;
    states?: Array<AddressState>;
};

const contextDefaultValue = {
    currentStepIndex: 0,
    isNewCustomer: true,
    tools: [],
    subTools: [],
    billingPeriods: [],
    countries: [],
    paymentMethods: []
} as CheckoutContextData;

export enum CheckoutStepsEnum {
    subscriptionPlan = 0,
    subscriptionData = 1,
    subscriptionPayment = 2,
    suspendedPlan = 3
}

export const CheckoutContext = createContext<CheckoutContextData>(contextDefaultValue);
CheckoutContext.displayName = 'CheckoutContext';

export const CheckoutProvider = ({ children, ...props }: CheckoutProviderProps) => {
    const [subscription, setSubscription] = useState<Subscription>(props.subscription);
    const [initialDataSubscriptionForm] = useState<SubscriptionFormData>(props?.initialDataSubscriptionForm);

    const [isNewCustomer, setIsNewCustomer] = useState<boolean>(contextDefaultValue.isNewCustomer);

    const [couponAttemptResult, setCouponAttemptResult] = useState<CouponAttemptResult>({
        isCouponApplied: false,
        couponMessage: null
    });
    const formRef = useRef<FormHandles>(null);
    const paymentMethodFormRef = useRef<FormHandles>(null);

    const [tools, setTools] = useState<Array<Tool>>(props.tools);
    const [subTools, setSubTools] = useState<Array<SubTool>>(props.subTools);
    const [billingPeriods, setBillingPeriods] = useState<Array<BillingPeriod>>(props.billingPeriods);
    const [countries, setCountries] = useState<Array<AddressCountry>>(props.countries);
    const [states, setStates] = useState<Array<AddressState>>(props.states);
    const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod>>(props.paymentMethods);
    const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);
    const [isUpdatingBillingResponsible, setIsUpdatingBillingResponsible] = useState(false);
    const [isUpdatingBillingData, setIsUpdatingBillingData] = useState(false);

    const [isSuspendedPlan, setIsSuspendedPlan] = useState(props.isSuspendedPlan);
    const [oldPaymentMethod, setOldPaymentMethod] = useState<SubscriptionPaymentFormData | null>();

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(
        isSuspendedPlan ? CheckoutStepsEnum.subscriptionPayment : props.initialStepNumber
    );

    const cleanCreditCard = useMemo(
        () => ({
            cardholderName: '',
            cvv: '',
            brand: '',
            expirationMonth: null,
            expirationYear: null,
            number: ''
        }),
        []
    );

    const subscriptionPayment = useMemo(() => {
        const subscriptionPayment = { ...initialDataSubscriptionForm?.subscriptionPayment };
        if (isSuspendedPlan) {
            if (subscriptionPayment.payPal) {
                subscriptionPayment.payPal.nonce = '';
            }
            if (subscriptionPayment.bankSlip) {
                subscriptionPayment.bankSlip.firstDueDate = formatDate(
                    addDays(new Date(), BANK_SLIP_COMPENSATION_OFFSET),
                    DateFormatEnum.OnlyDateUSFormat
                );
            }
            if (subscriptionPayment.creditCard) {
                subscriptionPayment.creditCard = cleanCreditCard;
                subscriptionPayment.paymentMethod = PaymentMethodTypeEnum.CreditCard;
            }
        }

        return subscriptionPayment;
    }, [initialDataSubscriptionForm, isSuspendedPlan, cleanCreditCard]);

    const subscriptionPlan = useMemo(() => {
        const subscriptionPlan = { ...initialDataSubscriptionForm?.subscriptionPlan };

        if (subscriptionPlan && subscriptionPlan?.sendingTechnologies) {
            subscriptionPlan.sendingTechnologies = [...subscriptionPlan.sendingTechnologies];
        }

        return subscriptionPlan;
    }, [initialDataSubscriptionForm]);

    const { subscriptionData } = initialDataSubscriptionForm ?? {};

    const [subscriptionFormData, dispatchSubscriptionFormData] = useReducer(subscriptionReducer, {
        subscriptionPlan,
        subscriptionData,
        subscriptionPayment
    });

    // TODO: Manter apenas uma validação (isNewCustomer ou hasSubscription)
    useEffect(() => {
        setIsNewCustomer(!subscriptionFormData?.subscriptionId);
    }, [subscriptionFormData?.subscriptionId]);

    const { prevState } = usePrevPropsAndState(null, { initialDataSubscriptionForm, isSuspendedPlan });

    useEffect(() => {
        if (
            initialDataSubscriptionForm &&
            prevState?.initialDataSubscriptionForm !== initialDataSubscriptionForm &&
            prevState?.isSuspendedPlan !== isSuspendedPlan
        ) {
            const subscriptionPlan = { ...initialDataSubscriptionForm?.subscriptionPlan } || {};
            const subscriptionData = { ...initialDataSubscriptionForm?.subscriptionData } || {};
            let paymentMethod = subscriptionPayment.paymentMethod;

            if (isSuspendedPlan) {
                paymentMethod = PaymentMethodTypeEnum.CreditCard;
            }

            formRef.current.setData({
                subscriptionPlan,
                subscriptionData,
                subscriptionPayment: { ...subscriptionPayment, paymentMethod: String(paymentMethod) }
            });
        }
    }, [initialDataSubscriptionForm, subscriptionPayment, prevState, isSuspendedPlan]);

    const goToFirstStep = useCallback(() => {
        if (!props?.initialDataSubscriptionForm) {
            return;
        }
        setCurrentStepIndex(CheckoutStepsEnum.subscriptionPlan);
        setIsSuspendedPlan(false);

        const { subscriptionPlan, subscriptionPayment, subscriptionData } = props?.initialDataSubscriptionForm;

        if (isSuspendedPlan) {
            // I set it to null for the user to be required to enter a new plan
            subscriptionPlan.emailMarketingAmount = null;
            subscriptionPlan.creditVolume = null;
            subscriptionPlan.sendingTechnologies = [];
            subscriptionPlan.billingPeriodId = null;
        }

        dispatchSubscriptionFormData({
            type: SubscriptionReducerActionTypesEnum.UpdatePlan,
            payload: { subscriptionPlan }
        });

        dispatchSubscriptionFormData({
            type: SubscriptionReducerActionTypesEnum.UpdateSubscriptionData,
            payload: { subscriptionData }
        });

        dispatchSubscriptionFormData({
            type: SubscriptionReducerActionTypesEnum.UpdatePayment,
            payload: { subscriptionPayment }
        });
    }, [props?.initialDataSubscriptionForm, isSuspendedPlan]);

    const updateSubscription = useCallback(
        (subscription: Subscription) => {
            setSubscription(subscription);
            setIsSuspendedPlan(subscription?.status === SubscriptionStatusEnum.CanceledByNonpayment);

            const subscriptionFormData = CreateSubscriptionFormData(subscription);

            if (subscription?.status === SubscriptionStatusEnum.CanceledByNonpayment) {
                const subscriptionPayment = { ...subscriptionFormData.subscriptionPayment };

                subscriptionPayment.creditCard = cleanCreditCard;

                formRef.current.setData({
                    subscriptionPlan: subscriptionFormData.subscriptionPlan,
                    subscriptionPayment: {
                        ...subscriptionPayment,
                        paymentMethod: String(PaymentMethodTypeEnum.CreditCard)
                    }
                });
            }
        },
        [cleanCreditCard]
    );

    const activateCoupon = useCallback((coupon: CouponAttemptResult) => {
        setCouponAttemptResult(coupon);
    }, []);

    return (
        <CheckoutContext.Provider
            value={{
                subscription,
                setSubscription,
                currentStepIndex,
                setCurrentStepIndex,
                isNewCustomer,
                setIsNewCustomer,
                subscriptionFormData,
                dispatchSubscriptionFormData,
                couponAttemptResult,
                activateCoupon,
                formRef,
                paymentMethodFormRef,
                tools,
                setTools,
                subTools,
                setSubTools,
                billingPeriods,
                setBillingPeriods,
                countries,
                setCountries,
                states,
                setStates,
                paymentMethods,
                setPaymentMethods,
                initialDataSubscriptionForm,
                isUpdatingPaymentMethod,
                setIsUpdatingPaymentMethod,
                isUpdatingBillingResponsible,
                setIsUpdatingBillingResponsible,
                isUpdatingBillingData,
                setIsUpdatingBillingData,
                isSuspendedPlan,
                setIsSuspendedPlan,
                goToFirstStep,
                oldPaymentMethod,
                setOldPaymentMethod,
                updateSubscription
            }}>
            {children}
        </CheckoutContext.Provider>
    );
};

export const useAddress = () => {
    const countries = useContextSelector(CheckoutContext, (select) => select.countries);
    const setCountries = useContextSelector(CheckoutContext, (select) => select.setCountries);
    const states = useContextSelector(CheckoutContext, (select) => select.states);
    const setStates = useContextSelector(CheckoutContext, (select) => select.setStates);

    return {
        countries,
        setCountries,
        states,
        setStates
    };
};

export const useCheckout = () => {
    const subscription = useContextSelector(CheckoutContext, (select) => select.subscription);
    const setSubscription = useContextSelector(CheckoutContext, (select) => select.setSubscription);
    const currentStepIndex = useContextSelector(CheckoutContext, (select) => select.currentStepIndex);
    const setCurrentStepIndex = useContextSelector(CheckoutContext, (select) => select.setCurrentStepIndex);
    const subscriptionFormData = useContextSelector(CheckoutContext, (select) => select.subscriptionFormData);
    const isSuspendedPlan = useContextSelector(CheckoutContext, (select) => select.isSuspendedPlan);
    const setIsSuspendedPlan = useContextSelector(CheckoutContext, (select) => select.setIsSuspendedPlan);
    const initialDataSubscriptionForm = useContextSelector(
        CheckoutContext,
        (select) => select.initialDataSubscriptionForm
    );

    const dispatchSubscriptionFormData = useContextSelector(
        CheckoutContext,
        (select) => select.dispatchSubscriptionFormData
    );
    const formRef = useContextSelector(CheckoutContext, (select) => select.formRef);
    const paymentMethodFormRef = useContextSelector(CheckoutContext, (select) => select.paymentMethodFormRef);
    const goToFirstStep = useContextSelector(CheckoutContext, (select) => select.goToFirstStep);
    const updateSubscription = useContextSelector(CheckoutContext, (select) => select.updateSubscription);

    return {
        subscription,
        setSubscription,
        currentStepIndex,
        setCurrentStepIndex,
        subscriptionFormData,
        dispatchSubscriptionFormData,
        formRef,
        paymentMethodFormRef,
        hasSubscription:
            !!subscription &&
            subscription.status !== SubscriptionStatusEnum.Canceled &&
            !CheckIfInvoiceIsDueForMoreThanThreeMonths(subscription as CurrentSubscriptionDetailsData),
        initialDataSubscriptionForm,
        isSuspendedPlan,
        hasACardInCanceledSubscription:
            !!subscription &&
            subscription.status === SubscriptionStatusEnum.Canceled &&
            subscription.paymentMethod.type === PaymentMethodTypeEnum.CreditCard &&
            subscription.card,
        setIsSuspendedPlan,
        goToFirstStep,
        updateSubscription
    };
};

export const useCoupon = () => {
    const activateCoupon = useContextSelector(CheckoutContext, (select) => select.activateCoupon);
    const couponAttemptResult = useContextSelector(CheckoutContext, (select) => select.couponAttemptResult);

    return {
        activateCoupon,
        couponAttemptResult
    };
};

export const useCustomer = () => {
    const isNewCustomer = useContextSelector(CheckoutContext, (select) => select.isNewCustomer);
    const setIsNewCustomer = useContextSelector(CheckoutContext, (select) => select.setIsNewCustomer);

    return {
        isNewCustomer,
        setIsNewCustomer
    };
};

export const useBillingResponsible = () => {
    const isUpdatingBillingResponsible = useContextSelector(
        CheckoutContext,
        (select) => select.isUpdatingBillingResponsible
    );

    const setIsUpdatingBillingResponsible = useContextSelector(
        CheckoutContext,
        (select) => select.setIsUpdatingBillingResponsible
    );

    return {
        isUpdatingBillingResponsible,
        setIsUpdatingBillingResponsible
    };
};

export const useBillingData = () => {
    const isUpdatingBillingData = useContextSelector(CheckoutContext, (select) => select.isUpdatingBillingData);

    const setIsUpdatingBillingData = useContextSelector(CheckoutContext, (select) => select.setIsUpdatingBillingData);

    return {
        isUpdatingBillingData,
        setIsUpdatingBillingData
    };
};

type FormOpener = { isOpen: boolean; open: () => void };
type FormOpeners = Record<'billingResponsible' | 'billingData', FormOpener>;

export const useFormOpeners: () => FormOpeners = () => {
    const { isUpdatingBillingResponsible, setIsUpdatingBillingResponsible } = useBillingResponsible();
    const { isUpdatingBillingData, setIsUpdatingBillingData } = useBillingData();
    const { hasSubscription } = useCheckout();

    return {
        billingResponsible: {
            isOpen: isUpdatingBillingResponsible,
            open: () => {
                if (hasSubscription) {
                    setIsUpdatingBillingResponsible(true);
                }
            }
        },
        billingData: {
            isOpen: isUpdatingBillingData,
            open: () => {
                if (hasSubscription) {
                    setIsUpdatingBillingData(true);
                }
            }
        }
    };
};

export const usePaymentMethods = () => {
    const paymentMethods = useContextSelector(CheckoutContext, (select) => select.paymentMethods);
    const setPaymentMethods = useContextSelector(CheckoutContext, (select) => select.setPaymentMethods);
    const billingPeriods = useContextSelector(CheckoutContext, (select) => select.billingPeriods);
    const setBillingPeriods = useContextSelector(CheckoutContext, (select) => select.setBillingPeriods);
    const isUpdatingPaymentMethod = useContextSelector(CheckoutContext, (select) => select.isUpdatingPaymentMethod);
    const oldPaymentMethod = useContextSelector(CheckoutContext, (select) => select.oldPaymentMethod);
    const setOldPaymentMethod = useContextSelector(CheckoutContext, (select) => select.setOldPaymentMethod);
    const setIsUpdatingPaymentMethod = useContextSelector(
        CheckoutContext,
        (select) => select.setIsUpdatingPaymentMethod
    );

    return {
        billingPeriods,
        setBillingPeriods,
        paymentMethods,
        setPaymentMethods,
        isUpdatingPaymentMethod,
        setIsUpdatingPaymentMethod,
        oldPaymentMethod,
        setOldPaymentMethod
    };
};

export const useTools = () => {
    const tools = useContextSelector(CheckoutContext, (select) => select.tools);
    const setTools = useContextSelector(CheckoutContext, (select) => select.setTools);
    const subTools = useContextSelector(CheckoutContext, (select) => select.subTools);
    const setSubTools = useContextSelector(CheckoutContext, (select) => select.setSubTools);

    return {
        tools,
        setTools,
        subTools,
        setSubTools
    };
};

export default CheckoutProvider;

export * from './reducers/subscriptionReducer';
