import { ReactElement, useMemo } from 'react';
import { useSWRConfig } from 'swr';
import { ButtonProps } from '@components';
import { ImportContactModal } from '@containers';
import { UseSubToolPageUrl, serializeSWRKey, subToolsFunctions, useSubToolPage, useTranslator } from '@hooks';
import { MessageSequence, PaginatedList, SubToolIdEnum } from '@models';
import { useAlert } from '@providers';
import { createQueryParams } from '@utils';
import SettingIcon from '~/assets/svg/icons/icon__setting.svg';
import { GetMessageSequencesCacheKey } from '~/services/cacheKeys';
import { SubToolColors } from '~/styles/theme';

export type ActionButton = {
    type:
        | 'importContactsButton'
        | 'apiButton'
        | 'viewProductButton'
        | 'createNewTemplateButton'
        | 'configurationButton'
        | string;
    url?: UseSubToolPageUrl;
    button: ButtonProps;
    description: string;
    icon?: ReactElement;
};

type UseAutomationRulerProps = {
    subToolId: SubToolIdEnum;
    hasSubToolConfigured?: boolean;
    customTriggerInactive?: boolean;
    sequenceId?: number;
};

const MAX_TEMPLATE_SIZE_FOR_SUBTOOL_RULER = 5;
const SUBTOOL_TYPES_WITH_ONLY_ONE_RULER = [
    SubToolIdEnum.CartRecovery,
    SubToolIdEnum.BankSlipReminder,
    SubToolIdEnum.CustomTrigger
];

export const useAutomationRuler = ({
    subToolId,
    hasSubToolConfigured,
    sequenceId,
    customTriggerInactive
}: UseAutomationRulerProps) => {
    const { getSubToolPageData } = useSubToolPage();
    const {
        pages: {
            myAutomations: { subToolsDescriptions }
        },
        buttons: { createNewTemplate, viewProducts, api, importContacts },
        dialogs: { warnings }
    } = useTranslator();
    const { show, warning } = useAlert();
    const { cache } = useSWRConfig();

    const messageSequencesCached = cache.get(
        serializeSWRKey({
            url: subToolsFunctions[subToolId as SubToolIdEnum].getMessageSequencesUrl,
            subToolId,
            currentPage: 1
        } as GetMessageSequencesCacheKey)
    )?.data as PaginatedList<MessageSequence>;

    let stepsLength = messageSequencesCached?.items[0]?.steps?.length;
    if (subToolId === SubToolIdEnum.CustomTrigger) {
        stepsLength = messageSequencesCached?.items.filter((item) => item.id === sequenceId)[0]?.steps?.length;
    }
    const disabledCreateNewTemplateButton =
        SUBTOOL_TYPES_WITH_ONLY_ONE_RULER.includes(subToolId) && stepsLength >= MAX_TEMPLATE_SIZE_FOR_SUBTOOL_RULER;

    return useMemo(() => {
        const createTemplateParams = createQueryParams({
            sequenceId:
                subToolId !== SubToolIdEnum.NavigationTrigger && subToolId !== SubToolIdEnum.Repurchase
                    ? String(sequenceId)
                    : null
        });
        const {
            subToolKey,
            icon: subToolIcon,
            translatedSubToolName: title,
            translatedSubToolQueryParam,
            urls: { createTemplatePage, configurationPage, repurchaseProductsPage, customTriggerApiTriggerPage }
        } = getSubToolPageData(subToolId, {
            params: {
                createTemplatePage: { params: createTemplateParams },
                customTriggerApiTriggerPage: { params: createTemplateParams }
            }
        });
        let customTriggerButtons: Array<ActionButton> = [];
        const blockRedirection = !hasSubToolConfigured;

        function showWarningNotSubToolConfigured() {
            if (blockRedirection) {
                warning(String(warnings.subToolNotConfigured), null, {
                    enableOutsideClosed: true
                });

                return true;
            }

            return false;
        }

        if (subToolId === SubToolIdEnum.CustomTrigger) {
            customTriggerButtons = [
                {
                    type: 'importContactsButton',
                    button: {
                        isOutlined: true,
                        onClick: () => {
                            if (customTriggerInactive) {
                                return warning(String(warnings.customTriggerInative), null, {
                                    enableOutsideClosed: true
                                });
                            }

                            show('', '', {
                                content: (options) => <ImportContactModal {...options} sequenceId={sequenceId} />
                            });
                        }
                    },
                    description: String(importContacts)
                },
                {
                    type: 'apiButton',
                    url: {
                        ...customTriggerApiTriggerPage,
                        usePathname: true,
                        query: { sequenceId: String(sequenceId) }
                    },
                    button: {
                        isOutlined: true
                    },
                    description: String(api)
                }
            ];
        }

        const actions = [
            ...customTriggerButtons,
            subToolId === SubToolIdEnum.Repurchase && {
                type: 'viewProductButton',
                url: !blockRedirection ? repurchaseProductsPage : null,
                button: {
                    isOutlined: true,
                    disabled: disabledCreateNewTemplateButton,
                    onClick: showWarningNotSubToolConfigured
                },
                description: String(viewProducts)
            },
            {
                type: 'createNewTemplateButton',
                url: !blockRedirection
                    ? subToolId === SubToolIdEnum.Repurchase
                        ? repurchaseProductsPage
                        : createTemplatePage
                    : null,
                button: {
                    isOutlined: false,
                    disabled: disabledCreateNewTemplateButton,
                    onClick: showWarningNotSubToolConfigured
                },
                description: String(createNewTemplate)
            },
            subToolId !== SubToolIdEnum.CustomTrigger && {
                type: 'configurationButton',
                url: { ...configurationPage, usePathname: true, query: { subTool: translatedSubToolQueryParam } },
                button: {
                    isOutlined: true,
                    className: 'config-button'
                },
                icon: <SettingIcon />
            }
        ].filter(Boolean) as Array<ActionButton>;

        const description = String(subToolsDescriptions[subToolKey]);

        return {
            description,
            title,
            subTool: {
                icon: subToolIcon,
                key: subToolKey as SubToolColors,
                actions
            },
            showWarningNotSubToolConfigured
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        subToolId,
        hasSubToolConfigured,
        disabledCreateNewTemplateButton,
        sequenceId,
        show,
        warning,
        customTriggerInactive
    ]);
};
