import { Children, forwardRef, isValidElement, HTMLProps } from 'react';
import {
    FloatingFocusManager,
    FloatingNode,
    FloatingPortal,
    FloatingTree,
    useFloatingParentNodeId
} from '@floating-ui/react';
import { useMenu } from '@hooks';
import RightArrowIcon from '~/assets/svg/controls/arrow--right.svg';
import { AnimatedArrow } from '../../animatedArrow';
import { LinkProps } from '../../link';
import { MenuItem } from './menuItem';
import { ArrowContentStyled, MenuStyled } from './styles';

export type MenuProps = {
    label: string;
    nested?: boolean;
    children?: React.ReactNode;
    link?: Pick<LinkProps, 'href' | 'as'>;
    allowHover?: boolean;
};

const MenuComponent = forwardRef<HTMLLIElement, MenuProps & HTMLProps<HTMLLIElement>>(function MenuComponent(
    { children, label, link, allowHover, ...props },
    forwardedRef
) {
    const labels = Children.map(children, (child) => (isValidElement(child) ? child.props.label : null));

    const {
        nodeId,
        isNested,
        isOpen,
        isMounted,
        referenceRef,
        getReferenceProps,
        styles,
        getFloatingProps,
        context,
        refs,
        items
    } = useMenu<HTMLLIElement>({
        elementRef: forwardedRef,
        labels,
        children,
        allowHoverEvent: allowHover
    });

    return (
        <FloatingNode id={nodeId}>
            <MenuItem
                isRoot={!isNested}
                ref={referenceRef}
                link={link}
                data-open={isOpen ? '' : undefined}
                tabIndex={!isNested ? 0 : undefined}
                renderElementAnchor={!!link?.href}
                {...getReferenceProps({
                    ...props,
                    onClick(event) {
                        event.stopPropagation();
                    }
                })}>
                {label}
                {isNested && (
                    <ArrowContentStyled aria-hidden className='icon__right'>
                        <RightArrowIcon />
                    </ArrowContentStyled>
                )}
                {!isNested && children && (
                    <ArrowContentStyled aria-hidden>
                        <AnimatedArrow direction={isOpen ? 'up' : 'down'} />
                    </ArrowContentStyled>
                )}
            </MenuItem>
            <FloatingPortal>
                {isMounted && (
                    <FloatingFocusManager
                        context={context}
                        modal={false}
                        initialFocus={isNested ? -1 : 0}
                        returnFocus={!!children}>
                        <MenuStyled
                            ref={refs.setFloating}
                            tabIndex={0}
                            style={{
                                position: context.strategy,
                                top: context.y ?? 0,
                                left: context.x ?? 0,
                                ...styles
                            }}
                            {...getFloatingProps()}>
                            {items}
                        </MenuStyled>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        </FloatingNode>
    );
});

export const Menu = forwardRef<HTMLLIElement, MenuProps & HTMLProps<HTMLLIElement>>(function Menu(props, ref) {
    const parentId = useFloatingParentNodeId();

    if (parentId === null) {
        return (
            <FloatingTree>
                <MenuComponent {...props} ref={ref} />
            </FloatingTree>
        );
    }

    return <MenuComponent {...props} ref={ref} />;
});
