import styled from 'styled-components';
import { CardBody } from '~/components/elements/card/body';

export const ActionsButtonStyled = styled.div`
    display: flex;
    gap: 1.6rem;
`;

export const CardBodyStyled = styled(CardBody)<{ editPayment?: boolean }>`
    padding-top: ${({ editPayment = false }) => (editPayment ? '2.4rem' : '1.6rem')};
`;
