import { useTranslator } from '@hooks';
import { CardBody, CardHeader, InfoTooltip } from '~/components/elements';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { InputFormGroup } from '~/components/forms';
import { Content, TagsAnalyticsCardStyled } from './styles';

type TagsAnalyticsCardProps = {
    subToolColor?: string;
};
export const TagsAnalyticsCard = ({ subToolColor }: TagsAnalyticsCardProps) => {
    const {
        tagsAnalytics: { title, tags }
    } = useTranslator().subTools.subToolTemplateConfiguration;

    const tagsList = [
        { name: 'utm_source', description: tags.utmSource },
        { name: 'utm_medium', description: tags.utmMedium },
        { name: 'utm_campaign', description: tags.utmCompaign }
    ];

    return (
        <TagsAnalyticsCardStyled type='group' borderColor={subToolColor}>
            <CardHeader title={String(title)} />
            <CardBody>
                <Content>
                    {tagsList.map((tag) => (
                        <InputFormGroup
                            key={tag.name}
                            inputSize='small'
                            type='text'
                            label={
                                <div>
                                    {String(tag.description)}
                                    <InfoTooltip>
                                        <TooltipContent>{String(tag.name)}</TooltipContent>
                                    </InfoTooltip>
                                </div>
                            }
                            name={`trackAnalytics[${tag.name}]`}
                            flexGrid={{ desktop: 4, tablet: 6, mobile: 12 }}
                        />
                    ))}
                </Content>
            </CardBody>
        </TagsAnalyticsCardStyled>
    );
};
