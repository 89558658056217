import Image from 'next/image';
import { Button, Shimmer } from '@components';
import { useTranslator } from '@hooks';
import { Theme } from '@models';
import { ThemeCardStyled } from '../styles';

type ThemeCardProps = {
    theme: Theme;
    onSelectedTheme: (theme: Theme) => void;
    onClickViewTheme: (theme: Theme) => void;
    isLoading?: boolean;
};

export const ThemeCard = ({ theme, onSelectedTheme, onClickViewTheme, isLoading }: ThemeCardProps) => {
    const {
        subTools: { newsletterTheme }
    } = useTranslator();
    const externaImageLoader = ({ src }: { src: string }) => src;

    return (
        <div>
            <Shimmer isActive={isLoading}>
                <ThemeCardStyled data-shimmer>
                    <header>
                        <Image
                            src={theme.thumbnail}
                            alt=''
                            width={100}
                            height={96}
                            unoptimized
                            layout='responsive'
                            objectFit='cover'
                            loader={externaImageLoader}
                        />
                    </header>
                    <div>
                        <h4>{theme.name}</h4>
                        <span>{theme.category}</span>
                    </div>
                    <footer>
                        <Button onClick={() => onClickViewTheme(theme)}>{String(newsletterTheme.actions.view)}</Button>
                        <Button color='purple' onClick={() => onSelectedTheme(theme)}>
                            {String(newsletterTheme.actions.selectTheme)}
                        </Button>
                    </footer>
                </ThemeCardStyled>
            </Shimmer>
        </div>
    );
};
