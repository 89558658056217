import { rgba } from 'polished';
import styled from 'styled-components';

const BACKGROUND_OPACITY = 0.5;

export const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4.2rem;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -2%;
    top: -2%;
    width: 104%;
    height: 104%;
    backdrop-filter: blur(3px);

    &::before {
        content: '';
        position: absolute;
        top: 12%;
        left: 0;
        width: 98%;
        height: 88%;
        background: ${({ theme }) => rgba(theme.colors.grayPurple, BACKGROUND_OPACITY)};
        z-index: -1;
    }

    strong {
        max-width: 100.8rem;
        text-align: center;
        color: ${({ theme }) => theme.colors.deepCerise};
    }

    div {
        display: flex;
        gap: 3.2rem;
        justify-content: center;
        align-items: center;

        & > a:not(:hover) button.documentation-button {
            background: ${({ theme }) => theme.colors.white};
        }
    }
`;
