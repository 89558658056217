import { AxiosResponse } from 'axios';
import {
    Subscription,
    BillingPeriod,
    Tool,
    AddressCountry,
    AddressState,
    CouponAttemptResult,
    PaymentMethod,
    ToolTypeEnum,
    SubscriptionTool,
    SubscriptionToolSendingTechnology,
    SendingTechnologyTypeEnum,
    CustomerPlanType,
    PaymentMethodTypeEnum,
    Customer,
    DocumentTypeEnum,
    PaymentTypeEnum,
    CardType,
    SubscriptionVersion
} from '@models';
import {
    BillingFormData,
    BillingResponsibleFormData,
    SubscriptionDataFormData,
    SubscriptionFormData,
    SubscriptionPaymentFormData,
    SubscriptionPlanFormData
} from '@providers';
import { captureException } from '@sentry/nextjs';
import { convertPhoneToString, convertToPhoneModel, CPF_LENGTH, getCardFlagName } from '@utils';
import { CreateSubscriptionRequest, SubscriptionResponseType, UpdateSubscriptionRequest } from './apiService';
import { PayCurrentSubscription } from './subscriptionApiService';
import { GetBillingPeriodById, GetSendingPriceByPeriodicity, GetToolByType } from './subscriptionService';

type CreateSubscriptionProps = {
    isUpdate: boolean;
    subscriptionFormData: SubscriptionFormData;
    subscription: Subscription;
    billingPeriods: Array<BillingPeriod>;
    tools: Array<Tool>;
    countries: Array<AddressCountry>;
    states: Array<AddressState>;
    paymentMethods: Array<PaymentMethod>;
    couponAttemptResult?: CouponAttemptResult;
    isSuspendedPlan?: boolean;
};

const CreateSubscriptionPlan = (
    billingPeriods: Array<BillingPeriod>,
    subscriptionPlan: SubscriptionPlanFormData,
    tools: Array<Tool>
) => {
    const { billingPeriodId, creditVolume, sendingTechnologies, emailMarketingAmount } = subscriptionPlan;

    const billingPeriod = GetBillingPeriodById(billingPeriods, billingPeriodId);
    const subscriptionTools = [];

    if (creditVolume > 0 && sendingTechnologies.length > 0) {
        const automationTool = GetToolByType(tools, ToolTypeEnum.AutomationTool);

        const selectedSendingTechnologies = automationTool.sendingTechnologies.filter((sendingTechnology) =>
            sendingTechnologies.includes(sendingTechnology.id)
        );

        const subscriptionAutomationTool: SubscriptionTool = {
            type: CustomerPlanType.AutomationTool,
            sendingTechnologies: selectedSendingTechnologies.map<SubscriptionToolSendingTechnology>(
                (sendingTechnology) => ({
                    sendingTechnology: sendingTechnology,
                    price: GetSendingPriceByPeriodicity(sendingTechnology, billingPeriod.periodicityInMonths),
                    currency: sendingTechnology.currency
                })
            ),
            price: selectedSendingTechnologies.reduce(
                (total, technology) =>
                    total + GetSendingPriceByPeriodicity(technology, billingPeriod.periodicityInMonths),
                0
            ),
            totalCredits: creditVolume
        };

        subscriptionTools.push(subscriptionAutomationTool);
    }

    if (emailMarketingAmount > 0) {
        const bulkSendingTool = GetToolByType(tools, ToolTypeEnum.BulkSending);

        const selectedSendingTechnologies = bulkSendingTool.sendingTechnologies.filter(
            (sendingTechnology) =>
                sendingTechnology.type === SendingTechnologyTypeEnum.Email &&
                sendingTechnology.creditAmount === emailMarketingAmount
        );

        const subscriptionBulkSendingTool: SubscriptionTool = {
            type: CustomerPlanType.BulkSendingTool,
            sendingTechnologies: selectedSendingTechnologies.map<SubscriptionToolSendingTechnology>(
                (sendingTechnology) => ({
                    sendingTechnology: sendingTechnology,
                    price: GetSendingPriceByPeriodicity(sendingTechnology, billingPeriod.periodicityInMonths),
                    currency: sendingTechnology.currency
                })
            ),
            price: selectedSendingTechnologies.reduce(
                (total, technology) =>
                    total + GetSendingPriceByPeriodicity(technology, billingPeriod.periodicityInMonths),
                0
            ),
            totalCredits: emailMarketingAmount
        };

        subscriptionTools.push(subscriptionBulkSendingTool);
    }

    return {
        subscriptionTools,
        billingPeriod
    };
};

const CreateSubscriptionCustomer = (
    billingData: BillingFormData,
    billingResponsible: BillingResponsibleFormData,
    country: AddressCountry,
    state: AddressState
) => {
    const customer: Customer = {
        name: billingData.corporateName,
        documentNumber: billingData.cpfCnpj,
        documentType: billingData.cpfCnpj.length > CPF_LENGTH ? DocumentTypeEnum.Cnpj : DocumentTypeEnum.Cpf,
        billingResponsible: {
            name: billingResponsible.name,
            email: billingResponsible.email,
            phone: convertToPhoneModel(billingResponsible.telephone),
            cellPhone: convertToPhoneModel(billingResponsible.cellPhoneForWhatsApp)
        },
        address: {
            street: billingData.address.street,
            city: billingData.address.city,
            country,
            neighborhood: billingData.address.neighborhood,
            number: billingData.address.number,
            state,
            zipCode: billingData.address.zipCode,
            complement: billingData.address.complement
        },
        paymentTemplate: PaymentTypeEnum.Subscription
    };

    return customer;
};

const CreateSubscriptionPayment = (
    paymentMethods: Array<PaymentMethod>,
    subscriptionPayment: SubscriptionPaymentFormData,
    couponAttemptResult?: CouponAttemptResult
) => {
    const paymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.type === Number(subscriptionPayment.paymentMethod)
    );

    let nextInvoiceDate = new Date();
    let card = null;

    switch (paymentMethod.type) {
        case PaymentMethodTypeEnum.CreditCard:
            card = {
                ...subscriptionPayment.creditCard,
                type: CardType.Credit,
                brand: getCardFlagName(subscriptionPayment.creditCard.number),
                isCurrent: true
            };
            break;

        case PaymentMethodTypeEnum.BankSlip:
            nextInvoiceDate = new Date(subscriptionPayment.bankSlip.firstDueDate);
            break;

        case PaymentMethodTypeEnum.Gateway:
            card = {
                ...subscriptionPayment.creditCard,
                type: CardType.PayPal
            };
            break;
    }

    const coupons = [];

    if (couponAttemptResult?.isCouponApplied) {
        coupons.push(couponAttemptResult.coupon);
    }

    return {
        paymentMethod,
        nextInvoiceDate,
        card,
        coupons
    };
};

export const CreateSubscription = async (props: CreateSubscriptionProps) => {
    const {
        isUpdate,
        subscriptionFormData,
        subscription,
        billingPeriods,
        tools,
        couponAttemptResult,
        paymentMethods,
        states,
        countries,
        isSuspendedPlan
    } = props;

    const { subscriptionPlan, subscriptionData, subscriptionPayment } = subscriptionFormData;
    const newSubscription = {
        subscriptionTools: [],
        coupons: [],
        createdDate: new Date(),
        id: subscription?.id,
        version: SubscriptionVersion.v3_5
    } as Subscription;

    // #region Subscription Plan

    const { billingPeriod, subscriptionTools: newSubscriptionTools } = CreateSubscriptionPlan(
        billingPeriods,
        subscriptionPlan,
        tools
    );

    newSubscription.billingPeriod = billingPeriod;
    newSubscription.subscriptionTools = newSubscriptionTools;

    // #endregion

    // #region Subscription Data

    const { billingData, billingResponsible } = subscriptionData;

    const country = countries?.find((country) => country.code === billingData.address.countryCode);
    const state = states?.find((state) => state.code === billingData.address.stateCode);

    newSubscription.customer = CreateSubscriptionCustomer(billingData, billingResponsible, country, state);

    // #endregion

    // #region Subscription Payment

    const { paymentMethod, nextInvoiceDate, card, coupons } = CreateSubscriptionPayment(
        paymentMethods,
        subscriptionPayment,
        couponAttemptResult
    );
    newSubscription.paymentMethod = paymentMethod;
    newSubscription.nextInvoiceDate = nextInvoiceDate;
    newSubscription.card = card;
    newSubscription.coupons = coupons;

    // #endregion

    try {
        let requestResult: AxiosResponse<SubscriptionResponseType> = null;

        const requestData = {
            subscription: newSubscription,
            hasAcceptedTheTerms: subscriptionPayment.hasAcceptedTheTerms,
            payPalNonce: subscriptionPayment.payPal?.nonce,
            isInterestedInMeuDimDim: false
        };

        if (isSuspendedPlan) {
            const payResponse = await PayCurrentSubscription({
                paymentMethodType: newSubscription.paymentMethod.type,
                payPalNonce: subscriptionPayment.payPal?.nonce,
                nextInvoiceDate: newSubscription.nextInvoiceDate,
                couponsIds: newSubscription.coupons?.map((item) => item.id),
                card: subscriptionPayment.creditCard,
                billingData: {
                    address: newSubscription.customer.address,
                    documentNumber: newSubscription.customer.documentNumber,
                    documentType: newSubscription.customer.documentType,
                    name: newSubscription.customer.name,
                    telephone: newSubscription.customer.billingResponsible.phone
                }
            });

            requestResult = {
                data: {
                    subscription: payResponse.data.subscription,
                    bankSlipUrl: payResponse.data.bankSlipUrl,
                    invoiceId: payResponse.data.id
                }
            } as AxiosResponse<SubscriptionResponseType>;
        } else if (!isUpdate) {
            requestResult = await CreateSubscriptionRequest(requestData);
        } else {
            requestResult = await UpdateSubscriptionRequest(requestData);
        }

        return requestResult.data;
    } catch (error) {
        captureException(error);
        throw error;
    }
};

export const GetSubscriptionDataBySubscription = (currentSubscription: Subscription) => {
    if (!currentSubscription) {
        return null;
    }

    const { name, documentNumber, address, billingResponsible } = currentSubscription?.customer;

    const phone = convertPhoneToString(billingResponsible.phone, true);
    const cellPhone = convertPhoneToString(billingResponsible?.cellPhone, true);

    return {
        billingData: {
            corporateName: name,
            cpfCnpj: documentNumber,
            address: {
                ...address,
                countryCode: address?.country?.code,
                stateCode: address?.state?.code
            }
        },
        billingResponsible: {
            name: billingResponsible.name,
            email: billingResponsible.email,
            telephone: phone,
            cellPhoneForWhatsApp: cellPhone
        }
    } as SubscriptionDataFormData;
};
