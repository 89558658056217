import styled from 'styled-components';
import { cssFlexGridByDevice, FlexGridValues } from '~/styles';

export type DropdownFormGroupStyledProps = {
    flexGrid?: FlexGridValues;
    inputSize?: 'small' | 'medium' | 'large';
};

export const DropdownFormGroupStyled = styled.div<DropdownFormGroupStyledProps>`
    display: flex;
    flex-direction: column;
    ${({ flexGrid }) => flexGrid && cssFlexGridByDevice(flexGrid)}

    label {
        margin-bottom: 0.8rem;
    }
`;
