import { useRef, useEffect, HTMLAttributes } from 'react';
import { useEventListener } from '@hooks';

type FocusLockProps = HTMLAttributes<HTMLDivElement> & {
    isLocked?: boolean;
};

export const FocusLock = ({ isLocked = true, children, ...props }: FocusLockProps) => {
    const divRef = useRef<HTMLDivElement>(null);
    const focusableElementsRef = useRef<NodeListOf<HTMLElement>>();

    useEffect(() => {
        const updateFocusableElements = () => {
            const focusedElementsSelector =
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video';
            focusableElementsRef.current = divRef.current.querySelectorAll(focusedElementsSelector);
        };

        // https://developer.mozilla.org/pt-BR/docs/Web/API/MutationObserver
        const observer = new MutationObserver(() => updateFocusableElements());
        updateFocusableElements();
        observer.observe(divRef.current, { childList: true });

        return () => observer.disconnect();
    }, [divRef]);

    const onKeyPress = (event: KeyboardEvent) => {
        if (!focusableElementsRef.current) {
            return;
        }

        const { key, shiftKey } = event;
        const { length, 0: firstItem, [length - 1]: lastItem } = focusableElementsRef.current;

        if (!isLocked || key !== 'Tab') {
            return;
        }

        if (length === 1) {
            event.preventDefault();
            return;
        }

        if (!shiftKey && document.activeElement === lastItem) {
            event.preventDefault();
            firstItem?.focus();
            return;
        }

        if (shiftKey && document.activeElement === firstItem) {
            event.preventDefault();
            lastItem?.focus();
        }
    };

    useEventListener('keydown', onKeyPress);

    useEffect(() => {
        if (!focusableElementsRef.current) {
            return;
        }

        if (focusableElementsRef.current.length === 1) {
            focusableElementsRef.current[0]?.focus();
        } else {
            let firstMainElement: HTMLElement = null;
            const mainElements = ['input', 'select', 'a'];
            for (const element of focusableElementsRef.current) {
                const nodeName = element.nodeName.toLowerCase();

                if (mainElements.find((elementName) => elementName === nodeName)) {
                    firstMainElement = element;
                    break;
                }
            }

            if (firstMainElement) {
                firstMainElement?.focus();
            } else {
                focusableElementsRef.current[0]?.focus();
            }
        }
    }, [focusableElementsRef]);

    return (
        <div {...props} ref={divRef}>
            {children}
        </div>
    );
};
