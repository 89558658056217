import TedError from '~/assets/svg/ted--error.svg';
import TedInfo from '~/assets/svg/ted--info.svg';
import TedSuccess from '~/assets/svg/ted--success.svg';
import TedWarning from '~/assets/svg/ted--warning.svg';
import { AlertColors } from '~/styles/theme';
import { NoticeCardCloseButton } from './closeButton';
import {
    NoticeCardContent,
    NoticeCardContentBody,
    NoticeCardDescription,
    NoticeCardExpandableContent,
    NoticeCardTitle
} from './content';
import { NoticeCardRoot } from './root';
import { NoticeCardTed } from './tedIcon';

export const tedVariationIcons: { [key in AlertColors]: typeof TedError } = {
    error: TedError,
    default: TedInfo,
    success: TedSuccess,
    warning: TedWarning,
    info: TedInfo
};

export const NoticeCard = {
    Root: NoticeCardRoot,
    Content: NoticeCardContent,
    ExpandableContent: NoticeCardExpandableContent,
    ContentBody: NoticeCardContentBody,
    Title: NoticeCardTitle,
    Description: NoticeCardDescription,
    TedIcon: NoticeCardTed,
    CloseButton: NoticeCardCloseButton
};
