import styled, { css } from 'styled-components';
import { dataValidateStyle } from '~/styles';

export type RadioButtonGroupStyledProps = {
    direction?: 'row' | 'column';
};

export const RadioButtonGroupStyled = styled.div<RadioButtonGroupStyledProps>`
    ${({ direction = 'row' }) => css`
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: ${direction};
        gap: 1.25em;
    `}

    ${dataValidateStyle}
`;
