import { ReactNode } from 'react';
import { Link } from '@components';
import { ReplaceData } from '@utils';
import { MultiStepItemStyled, MultiStepItemTextStyled, IconCircleStyled, MultiStepItemStyleProps } from './styles';

export type MultiStepItemProps = MultiStepItemStyleProps & {
    /** The title of step */
    children?: ReactNode;
    route?: string;
    tabIndex?: number;
    translatedParams?: ReplaceData;
    showLink?: boolean;
};

export const MultiStepItem = ({
    children,
    showLink = true,
    route,
    tabIndex,
    translatedParams,
    backgroundColor,
    state = 'Unknown'
}: MultiStepItemProps) => {
    const SwitchIcon = (): JSX.Element => {
        switch (state) {
            case 'Selected':
                return (
                    <svg height='0.6rem' viewBox='0 0 6 6'>
                        <circle cx='3' cy='3' r='3' stroke='none' />
                    </svg>
                );

            case 'Concluded':
                return (
                    <svg height='1rem' viewBox='0 0 12 9'>
                        <path fill='none' strokeWidth='1.4' strokeLinecap='round' d='M11,1L4.1,8L1,4.9' />
                    </svg>
                );

            default:
                return <></>;
        }
    };

    return (
        <MultiStepItemStyled>
            <Link
                showLink={showLink ?? !!route}
                href={route}
                as={route}
                tabIndex={tabIndex}
                translatedParams={translatedParams}
                role='menuitem'>
                <IconCircleStyled state={state} backgroundColor={backgroundColor}>
                    <SwitchIcon />
                </IconCircleStyled>

                <MultiStepItemTextStyled as='span' size='xl' state={state}>
                    {children}
                </MultiStepItemTextStyled>
            </Link>
        </MultiStepItemStyled>
    );
};

export default MultiStepItem;
