import { ToolTypeEnum } from '@models';
import axiosClient from './apiService';

type AutomationToolParams = {
    storeVisitsAmountPerMonth: number;
    storeOrderAmountPerMonth: number;
    storeBankSlipOrderAmountPerMonth: number;
};

type EmailMarketinhParams = {
    numberOfCustomers: number;
    campaignsAmountPerMonth: number;
};

type CalculatePlanServiceProps = EmailMarketinhParams &
    AutomationToolParams & {
        toolType: ToolTypeEnum;
    };

type SubmitCustomPlanRequestProps = CalculatePlanServiceProps & {
    totalCreditsRequested: number;
};

export const CalculatePlanServiceAsync = (data: CalculatePlanServiceProps) => {
    return axiosClient.post<number>('/subscription/calculate-plan', data);
};

export const SubmitCustomPlanRequestAsync = (data: SubmitCustomPlanRequestProps) => {
    return axiosClient.post<number>('/subscription/request-custom-plan', data);
};
