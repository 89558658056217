import Link from 'next/link';
import { useRouter } from 'next/router';
import IconEnviou from '~/assets/svg/logo/icon__enviou--original.svg';
import LogoEnviou from '~/assets/svg/logo/logo__enviou-slogan.svg';
import { routesName } from '~/locales/route';
import { translateRoute } from '~/utils';
import { Navbar } from './navbar';
import { EnviouLogoSectionStyled, HeaderContentStyled } from './styles';
import { UserSection } from './userSection';

type HeaderContentProps = {
    showNavbar: boolean;
};

export const HeaderContent = ({ showNavbar }: HeaderContentProps) => {
    const { locale } = useRouter();

    return (
        <HeaderContentStyled>
            <Link href={routesName.root} as={translateRoute(routesName.root, locale)}>
                <EnviouLogoSectionStyled href={translateRoute(routesName.root, locale)}>
                    <LogoEnviou title='Logo Enviou' height='4rem' className='logo__enviou' />
                    <IconEnviou title='Icone Enviou' height='3rem' className='icon__enviou' />
                </EnviouLogoSectionStyled>
            </Link>

            {showNavbar && (
                <>
                    <Navbar />
                    <UserSection />
                </>
            )}
        </HeaderContentStyled>
    );
};
