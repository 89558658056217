import { BarChart } from './barChart';
import { BarChartHeader } from './barChartHeader';
import { BarChartItem } from './barChartItem';
import { BarChartLabel } from './barChartLabel';
import { BarChartLabels } from './barChartLabels';
import { BarChartRoot } from './barChartRoot';

// Composition Pattern
export const UsedCreditsAmountBarChart = {
    Bar: BarChart,
    BarItem: BarChartItem,
    Header: BarChartHeader,
    Label: BarChartLabel,
    Labels: BarChartLabels,
    Root: BarChartRoot
};
