import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useTranslator } from '@hooks';
import { SubToolIdEnum, ToolTypeEnum } from '@models';
import { AutomationSummary } from '.';

export const useFallbackData = () => {
    const { subToolColors, colors } = useTheme();
    const {
        enums: { subToolIdEnum: translationSubToolEnum }
    } = useTranslator();

    return useMemo(() => {
        const defaultAutomatonToolSummary = [
            {
                id: SubToolIdEnum.NavigationTrigger,
                totalCreditsUsed: 0,
                text: String(translationSubToolEnum.NavigationTrigger),
                color: subToolColors.navigationTrigger,
                percentage: 0
            },
            {
                id: SubToolIdEnum.BankSlipReminder,
                totalCreditsUsed: 0,
                text: String(translationSubToolEnum.BankSlipReminder),
                color: subToolColors.bankSlipReminder,
                percentage: 0
            },
            {
                id: SubToolIdEnum.CustomTrigger,
                totalCreditsUsed: 0,
                text: String(translationSubToolEnum.CustomTrigger),
                color: subToolColors.customTrigger,
                percentage: 0
            },
            {
                id: SubToolIdEnum.Repurchase,
                totalCreditsUsed: 0,
                text: String(translationSubToolEnum.Repurchase),
                color: subToolColors.repurchase,
                percentage: 0
            },
            {
                id: SubToolIdEnum.CartRecovery,
                totalCreditsUsed: 0,
                text: String(translationSubToolEnum.CartRecovery),
                color: subToolColors.cartRecovery,
                percentage: 0
            }
        ];

        const defaultBulkSendingToolSummary = [
            {
                id: 0,
                totalCreditsUsed: 0,
                color: colors.eminencePurple,
                percentage: 0,
                text: ''
            },
            ...Array.from({ length: 5 }).map((_, index) => ({
                id: index,
                totalCreditsUsed: 0,
                text: ' ',
                percentage: 0,
                color: null
            }))
        ];

        return {
            automationTool: {
                toolType: ToolTypeEnum.AutomationTool,
                haveUnlimitedBalance: false,
                totalCredits: 0,
                totalLooseCredits: 0,
                totalUsedCredits: 0,
                totalUsedLooseCredits: 0,
                summary: defaultAutomatonToolSummary
            } as AutomationSummary,
            bulkSendingTool: {
                toolType: ToolTypeEnum.BulkSending,
                haveUnlimitedBalance: false,
                totalCredits: 0,
                totalLooseCredits: 0,
                totalUsedCredits: 0,
                totalUsedLooseCredits: 0,
                summary: defaultBulkSendingToolSummary
            } as AutomationSummary
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
