import { TranslatorProps } from '@hooks';
import { SendingTechnologyTypeEnum, User } from '@models';
import localVariables from '~/locales/locales.json';
import { VariableParentKey } from '../utils';
import { generateEmailTemplatePreview } from './generateEmailPreview';
import { generatePushTemplatePreview } from './generatePushPreview';
import { generateSmsTemplatePreview } from './generateSmsPreview';
import { generateWhatsappTemplatePreview } from './generateWhatsappPreview';

const localVariableToDefinePreviewValueType = localVariables.variablesMock['consumer.name'];

export type PreviewValue = typeof localVariableToDefinePreviewValueType;
export type PreviewData = {
    [key: string]: Partial<PreviewValue> | string | number;
};

export type GenerateTemplatePreviewProps = {
    translator: TranslatorProps;
    user: User;
    parentKey?: VariableParentKey;
    baseHtml?: string;
    productHtml?: string;
    reserveProductHtml?: string;
    recommendationHTML?: string;
    displayReserveProduct?: boolean;
    reviewHtml?: string;
    allowedVariables?: Array<string>;
    sendingTechnology?: SendingTechnologyTypeEnum;
};

const previewBySendingTechnology: { [key: number]: (props: GenerateTemplatePreviewProps) => string } = {
    [SendingTechnologyTypeEnum.Email]: generateEmailTemplatePreview,
    [SendingTechnologyTypeEnum.SMS]: generateSmsTemplatePreview,
    [SendingTechnologyTypeEnum.WhatsAppManual]: generateWhatsappTemplatePreview,
    [SendingTechnologyTypeEnum.WhatsApp]: generateWhatsappTemplatePreview,
    [SendingTechnologyTypeEnum.Push]: generatePushTemplatePreview
};

export const generateTemplatePreviewBySendingTechnology = ({
    sendingTechnology,
    ...props
}: GenerateTemplatePreviewProps) => {
    const generateTemplate = previewBySendingTechnology[sendingTechnology];
    return generateTemplate({ ...props });
};
