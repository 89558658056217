import { ContainerStyled, SMSChatStyled } from './styles';

type SmsChatProps = {
    message?: string;
};

export const SmsChat = ({ message }: SmsChatProps) => {
    return (
        <ContainerStyled>
            <div>
                <SMSChatStyled>
                    <div>
                        <p>{message}</p>
                    </div>
                    <div>
                        <p>😀</p>
                    </div>
                </SMSChatStyled>
            </div>
        </ContainerStyled>
    );
};
