import { Text, defaultHeadingStyle } from '@components';
import { useNumberFunctions } from '@hooks';
import type { MarketingAutomationResultStatus } from './marketingAutomationResult';
import { TotalSaleStyled } from './styles';

type TotalSaleProps = {
    salesAmountLabel: string;
    totalConversionValue: number;
    conversionCount: number;
    status: MarketingAutomationResultStatus;
};

export const TotalSale = ({ salesAmountLabel, totalConversionValue, conversionCount, status }: TotalSaleProps) => {
    const { formatCurrency, formatNumber } = useNumberFunctions();

    if (status !== 'active') {
        return <></>;
    }

    return (
        <TotalSaleStyled>
            <Text as='span' size='xs'>
                {String(salesAmountLabel)}
            </Text>
            <Text as='strong' {...defaultHeadingStyle.h5}>
                {formatCurrency(totalConversionValue)} ({formatNumber(conversionCount)})
            </Text>
        </TotalSaleStyled>
    );
};
