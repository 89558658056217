export const fontSizes = {
    xxs: '1.6rem',
    xs: '1.8rem',
    sm: '1.9rem',
    md: '2rem',
    lg: '2.1rem',
    xl: '2.4rem',
    '2xl': '2.6rem',
    '4xl': '2.9rem',
    '5xl': '3.1rem',
    '6xl': '3.7rem',
    '7xl': '4.8rem',
    '8xl': '6rem'
} as const;

export type FontSizes = keyof typeof fontSizes;
