import styled from 'styled-components';
import { CardBody } from '~/components/elements/card/body';

export const BillingResponsibleStyled = styled.div`
    .billing-responsible__card-default {
        main {
            padding: 2.2rem;
        }
    }
`;

export const CardBodyStyled = styled(CardBody)`
    padding-top: 2.4rem;
`;

export const EditCardBody = styled(CardBody)`
    padding-top: 1.6rem;
`;
