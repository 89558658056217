import { SendingTechnologyTypeEnum } from './sendingTechnology';
import { SubToolConfiguration } from './subToolConfiguration';

export const enum ContactConfigurationStatusEnum {
    Inactive = 0,
    Active = 1,
    Deleted = 2
}

export type ContactConfiguration = {
    id?: number;
    subToolConfiguration: SubToolConfiguration;
    senderName: string;
    senderContact: string;
    contactAnswer: string;
    status: ContactConfigurationStatusEnum;
    sendingTechnology: SendingTechnologyTypeEnum;
    creationDate?: Date;
};
