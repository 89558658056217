import { useEffect, useMemo, useState } from 'react';
import first from 'lodash/first';
import uniq from 'lodash/uniq';
import { Card, CardBody, CardFooter, CardHeader, Text } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { SendingTechnologyTypeEnum, ToolTypeEnum } from '@models';
import { useCheckout, usePaymentMethods, useTools } from '~/providers/checkoutProvider';
import {
    CalculateTotalSendingTechnologies,
    GetBillingPeriodById,
    GetSubToolByType,
    GetToolByType
} from '~/services/subscriptionService';
import { CurrentAutomationPlanDetails } from './currentAutomationPlanDetails';
import { FirstStep } from './firstStep';
import { SecondStep } from './secondStep';
import { AmountStyled, AutomationPlansColumnStyled, AutomationPlansRowStyled } from './styles';
import { ThirdStep } from './thirdStep';

export const AutomationPlans = () => {
    const { subscriptionFormData, hasSubscription } = useCheckout();
    const { billingPeriods } = usePaymentMethods();
    const { tools, subTools } = useTools();
    const { formatCurrency } = useNumberFunctions();
    const { automationPlans } = useTranslator().checkoutPage.subscriptionPlan;
    const [total, setTotal] = useState<number>(0);

    const automationSubTools = useMemo(() => GetSubToolByType(subTools, ToolTypeEnum.AutomationTool), [subTools]);

    const sendingTechnologies = useMemo(
        () =>
            GetToolByType(tools, ToolTypeEnum.AutomationTool)?.sendingTechnologies?.filter(
                (sending) => sending.type !== SendingTechnologyTypeEnum.WhatsAppManual && !sending.isCustomValue
            ),
        [tools]
    );

    const currency = first(sendingTechnologies)?.currency ?? 'BRL';
    const credits = uniq(
        sendingTechnologies
            ?.filter((technology) => !technology.isCustomValue)
            ?.map((technology) => technology.creditAmount)
    );

    useEffect(() => {
        const {
            creditVolume,
            sendingTechnologies: currentSendingTechnologies,
            billingPeriodId
        } = subscriptionFormData?.subscriptionPlan || {};

        const periodicityInMonths = GetBillingPeriodById(billingPeriods, billingPeriodId)?.periodicityInMonths;

        const totalSendingTechnologies = CalculateTotalSendingTechnologies(
            creditVolume,
            sendingTechnologies,
            currentSendingTechnologies,
            periodicityInMonths
        );

        setTotal(totalSendingTechnologies);
    }, [subscriptionFormData, sendingTechnologies, billingPeriods]);

    return (
        <Card type='group'>
            <CardHeader
                type='group'
                title={String(automationPlans.title)}
                description={!hasSubscription && String(automationPlans.description)}
            />

            <CardBody>
                <CurrentAutomationPlanDetails />
                <AutomationPlansRowStyled>
                    <AutomationPlansColumnStyled flexGrid={{ mobileAndTablet: 12, desktop: 6 }}>
                        <FirstStep title={String(automationPlans.steps.first.title)} subTools={automationSubTools} />
                    </AutomationPlansColumnStyled>
                    <AutomationPlansColumnStyled flexGrid={{ mobileAndTablet: 12, desktop: 6 }}>
                        <AutomationPlansRowStyled>
                            <AutomationPlansColumnStyled flexGrid={{ mobileAndTablet: 12, desktop: 12 }}>
                                <SecondStep credits={credits} />
                            </AutomationPlansColumnStyled>
                            <AutomationPlansColumnStyled flexGrid={{ mobileAndTablet: 12, desktop: 12 }}>
                                <ThirdStep
                                    title={String(automationPlans.steps.third.title)}
                                    sendingTechnologies={sendingTechnologies}
                                />
                            </AutomationPlansColumnStyled>
                        </AutomationPlansRowStyled>
                    </AutomationPlansColumnStyled>
                </AutomationPlansRowStyled>
            </CardBody>

            <CardFooter>
                <AmountStyled>
                    <Text as='strong' size='lg'>
                        {String(automationPlans.amount)}
                    </Text>
                    <Text as='strong' size='lg'>
                        {formatCurrency(total, currency)}
                    </Text>
                </AmountStyled>
            </CardFooter>
        </Card>
    );
};

export default AutomationPlans;
export * from './automationToolModal';
