import styled from 'styled-components';
import { SubToolColors } from '~/styles/theme';

export const FirstStepStyled = styled.div`
    display: inline-block;
    width: 100%;
`;

export const SubToolItemStyled = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.2rem 0 2.4rem;

    &:first-child {
        padding-top: 1.8rem;
    }

    &:not(:last-child) {
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.lightPurple};
    }

    &:last-child {
        padding-bottom: 0rem;
    }
`;

export const SubToolIconStyled = styled.div<{ subToolColor: SubToolColors }>`
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.darkenedGrayPurple};
    background-color: ${({ theme }) => theme.colors.grayPurple};
    color: ${({ theme, subToolColor }) => theme.subToolColors[subToolColor]};
`;

export const SubToolInfoStyled = styled.div`
    flex: 1;
    padding-inline-start: 1.6rem;

    p {
        margin-top: 0.8rem;
        margin-bottom: 1.2rem;
    }
`;
