import { ReactNode, useCallback } from 'react';
import HtmlReactParser, { HTMLReactParserOptions } from 'html-react-parser';
import _upperFirst from 'lodash/upperFirst';
import Head from 'next/head';
import {
    Footer,
    Header,
    Text,
    defaultHeadingStyle,
    NoticeCard,
    Breadcrumbs,
    useBreadcrumbs,
    Button
} from '@components';
import { useTranslator } from '@hooks';
import { checkRouteWithPattern } from '@utils';
import { routes, overwriteRouteParts, overwriteRouteRoutes } from '~/locales/route';
import { AlertColors } from '~/styles/theme';
import { LayoutStyled, LayoutContentStyled } from './styles';

export type NoticeCardConditionalProps =
    | {
          onButtonClick?: never;
          buttonText?: never;
      }
    | {
          onButtonClick: () => void;
          buttonText: string;
      };

export type NoticeProps = {
    title: string | ReactNode;
    description?: string | ReactNode;
    descriptionHtmlParserOptions?: HTMLReactParserOptions;
    variant?: AlertColors;
    amount?: string;
} & NoticeCardConditionalProps;

type SubToolLayoutProps = {
    children?: ReactNode;
    showHeader?: boolean;
    notice?: NoticeProps;
    showBreadcrumb?: boolean;
};

export const SubToolLayout = ({
    children,
    notice = null,
    showHeader = true,
    showBreadcrumb = true
}: SubToolLayoutProps) => {
    const {
        subTools: {
            common: { head }
        }
    } = useTranslator();

    const transformUrl = useCallback((href: string, as: string, locale: string) => {
        // Overridden to redirect to automation-tool page

        const customRoute = overwriteRouteRoutes
            .map((item) => {
                const automationToolRoute = routes[item.route][locale];
                const pattern = `${automationToolRoute}${item.params}`;

                return { isMatch: checkRouteWithPattern(as, pattern), href: item.redirectTo, as: automationToolRoute };
            })
            .find((item) => item.isMatch);

        if (customRoute) {
            return {
                href: customRoute.href,
                as: customRoute.as
            };
        }

        return {
            href,
            as
        };
    }, []);

    const transformText = useCallback((path: string, href: string, locale: string) => {
        const routePart = overwriteRouteParts[locale][`/${path === '/' ? '' : path}`];

        if (routePart) {
            return _upperFirst(routePart);
        }

        return _upperFirst(path);
    }, []);

    const { breadcrumbs } = useBreadcrumbs({
        transformUrl,
        transformText
    });

    return (
        <LayoutStyled>
            <Head>
                <title>{String(head.title)}</title>
            </Head>

            {showHeader && <Header />}
            <main>
                <LayoutContentStyled>
                    <div>
                        {showBreadcrumb && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                        {notice && (
                            <NoticeCard.Root className='notice' variant={notice.variant}>
                                <NoticeCard.Content>
                                    <NoticeCard.TedIcon />
                                    <NoticeCard.ContentBody>
                                        <NoticeCard.Title>{notice.title}</NoticeCard.Title>
                                        <NoticeCard.Description>
                                            {typeof notice.description === 'string'
                                                ? HtmlReactParser(
                                                      notice.description,
                                                      notice?.descriptionHtmlParserOptions
                                                  )
                                                : notice.description}
                                        </NoticeCard.Description>
                                    </NoticeCard.ContentBody>
                                    <NoticeCard.ExpandableContent>
                                        {notice.amount && (
                                            <Text as='span' {...defaultHeadingStyle.h5}>
                                                {notice.amount}
                                            </Text>
                                        )}
                                        {notice.buttonText && (
                                            <Button color='purple' isOutlined onClick={notice.onButtonClick}>
                                                {notice.buttonText}
                                            </Button>
                                        )}
                                    </NoticeCard.ExpandableContent>
                                </NoticeCard.Content>
                                <NoticeCard.CloseButton />
                            </NoticeCard.Root>
                        )}
                    </div>
                    {children}
                </LayoutContentStyled>
                <Footer contentSize='medium' />
            </main>
        </LayoutStyled>
    );
};
