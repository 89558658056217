import { useRef } from 'react';
import { CanceledError } from 'axios';
import { useRouter } from 'next/router';
import { FunnelChart } from '@components';
import { useFetch, useTranslator } from '@hooks';
import { SubscriptionStatusEnum, ToolTypeEnum } from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { GetLatestCampaignsAsync, getLatestCampaignsUrl } from '~/services/statisticsApiService';
import { GetToolSummaryAsync, GetToolSummaryUrl } from '~/services/toolApiService';
import {
    CreateToolFunnelChartStages,
    GetDefaultCampaigns,
    GetDefaultToolFunnelChartStages,
    ParseToolUsageSummaryToToolFunnelChartSummaries
} from '~/services/toolService';
import { PageSubTitle, PageTitle } from '~/styles';
import theme from '~/styles/theme';
import { InactiveToolContent, LastedCampaignsCard, ToolFunnelChartContent } from '../';
import { ContentStyled, SectionStyled } from '../styles';

export const EmailMarketingSection = () => {
    const {
        errorMessage,
        funnelChart: { summary },
        emailMarketingSection: { title, subTitle }
    } = useTranslator().pages.home;

    const { user } = useAuth();
    const router = useRouter();
    const alert = useAlert();
    const errorAlertId = useRef(alert.generateAlertId()).current;

    const dataForTranslation = { routerData: { ...router }, currency: user?.currency, translatedSumary: summary };

    const defaultMarketingTool = GetDefaultToolFunnelChartStages(
        dataForTranslation,
        theme.funnelChart.emailMarketingFunnelChartColors
    );

    const { data: marketingToolData, isLoading: isMarketingToolSummaryLoading } = useFetch(
        { url: GetToolSummaryUrl(ToolTypeEnum.BulkSending) },
        async (_, signal) => {
            try {
                const response = await GetToolSummaryAsync(ToolTypeEnum.BulkSending, signal);

                const automationToolSummaries = ParseToolUsageSummaryToToolFunnelChartSummaries(
                    response.data,
                    ToolTypeEnum.AutomationTool
                );

                const toolFunnelChartStages = CreateToolFunnelChartStages(
                    dataForTranslation,
                    automationToolSummaries,
                    theme.funnelChart.emailMarketingFunnelChartColors
                );

                return { funnelChartStages: toolFunnelChartStages, summary: response.data };
            } catch (error) {
                if (error instanceof CanceledError || error instanceof EnviouApiResponseError) {
                    throw error;
                }

                alert.error(String(errorMessage), null, { id: errorAlertId, autoClose: true });
                captureException(error);

                return {
                    funnelChartStages: defaultMarketingTool.funnelStages,
                    summary: defaultMarketingTool.summary
                };
            }
        }
    );

    const { data: latestCampaigns, isLoading: isLatestCampaignsLoading } = useFetch(
        { url: getLatestCampaignsUrl },
        async (_, signal) => {
            try {
                const maxCampaigns = 5;
                return (await GetLatestCampaignsAsync(maxCampaigns, signal)).data;
            } catch (error) {
                if (error instanceof CanceledError || error instanceof EnviouApiResponseError) {
                    throw error;
                }

                alert.error(String(errorMessage), null, { id: errorAlertId, autoClose: true });
                return [];
            }
        }
    );

    const funnelChartStages = marketingToolData?.funnelChartStages ?? defaultMarketingTool.funnelStages;
    const subscriptionStatus = marketingToolData?.summary?.subscriptionStatus ?? SubscriptionStatusEnum.Active;
    const isActive = marketingToolData?.summary?.isActive ?? true;
    const searchPeriodDays = marketingToolData?.summary?.searchPeriodDays ?? 1;

    return (
        <SectionStyled>
            <PageTitle>{String(title)}</PageTitle>
            <PageSubTitle>{String(subTitle)}</PageSubTitle>
            <ContentStyled>
                <FunnelChart
                    backgroundColor={funnelChartStages.backgroundColor}
                    content={({ data }) => <ToolFunnelChartContent {...data} />}
                    data={funnelChartStages.data}
                    stage={funnelChartStages.stage}
                    isLoading={isMarketingToolSummaryLoading && !marketingToolData?.funnelChartStages}
                />
                <LastedCampaignsCard
                    latestCampaigns={latestCampaigns ?? GetDefaultCampaigns()}
                    searchPeriodDays={searchPeriodDays}
                    isLoading={isLatestCampaignsLoading && !latestCampaigns}
                />
            </ContentStyled>
            <InactiveToolContent isActiveTool={isActive} subscriptionStatus={subscriptionStatus} />
        </SectionStyled>
    );
};
