import { Button, Heading, Text, Link } from '@components';
import { useTranslator } from '@hooks';
import { routesName } from '~/locales/route';
import { ErrorContainer, TedIconStyled } from './styles';

export type Error = {
    statusCode: number;
    description: string;
    subTitle: string;
    message: string;
    errorCode?: string;
};

type ErrorTemplateProps = {
    error: Error;
};

export const ErrorTemplate = ({ error }: ErrorTemplateProps) => {
    const { details } = useTranslator().pageError;

    return (
        <ErrorContainer>
            <div>
                <Heading as='h1' color='purple'>
                    {error.statusCode}
                </Heading>
                <Heading as='h3' color='purple'>
                    {error.description}
                </Heading>

                <div>
                    <Heading as='h2' size='8xl'>
                        {error.subTitle}
                    </Heading>
                    <Text size='md'>{error.message}</Text>
                    {error.errorCode && (
                        <Text as='span' size='md'>
                            {String(details.errorCode)} {error.errorCode}
                        </Text>
                    )}
                    <Link href={routesName.root} as={routesName.root}>
                        <Button color='purple' buttonSize='small'>
                            {String(details.backButtonText)}
                        </Button>
                    </Link>
                </div>
            </div>
            <TedIconStyled />
        </ErrorContainer>
    );
};
