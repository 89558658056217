import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import HelpIcon from '~/assets/svg/controls/icon__help.svg';
import { Card } from '~/components/elements/card';
import type { CardStyleProps } from '~/components/elements/card/styles';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { SubToolColors } from '~/styles/theme';

type SubToolCardStyledProps = {
    subToolColor: SubToolColors;
};

type CardProps = ThemedStyledProps<CardStyleProps & SubToolCardStyledProps, DefaultTheme>;

export const TooltipBodyStyled = styled(TooltipContent)`
    display: block;
`;

export const HelpIconStyled = styled(HelpIcon)`
    padding-left: 0.8rem;
    height: 0.8em;
    color: ${({ theme }) => theme.colors.purple};
`;

export const CardStyled = styled(Card).attrs((props: CardProps) => ({
    borderColor: props.theme.subToolColors[props.subToolColor]
}))<SubToolCardStyledProps>`
    padding: 3.2rem 4rem;
    font-family: Roboto, sans-serif;

    & > header {
        padding: 0;

        h1,
        h3 {
            margin-bottom: 0;
        }
    }
    & > main {
        margin-top: 2.4rem;
        padding: 0;
    }
`;

export const SubToolConfigurationStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
`;

export const SubToolConfigurationButtonGroupStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 7.2rem;

    button {
        min-width: 16rem;
    }
`;
