import Circle from '~/assets/svg/circle.svg';
import { LoadingStyledProps, LoadingStyled } from './styles';

type LoadingProps = LoadingStyledProps & {
    inLoading: boolean;
};

export const Loading = ({ inLoading = false, size = 'medium' }: LoadingProps) => {
    if (!inLoading) {
        return null;
    }

    return (
        <LoadingStyled size={size} className='loading'>
            <Circle />
        </LoadingStyled>
    );
};
