import _isEmpty from 'lodash/isEmpty';
import { Heading, Text } from '@components';
import { RulerSteps } from '@containers';
import { useTranslator } from '@hooks';
import { MessageSequence } from '@models';
import {
    EmptyItemConfigurationRulerStepsStyled,
    ItemConfigurationRulerStepsStyled,
    ItemConfigurationRulerStyled
} from './styles';

type ItemConfigurationRulerProps = {
    expanded: boolean;
    triggerId: string;
    templatesContentId: string;
    messageSequence?: MessageSequence;
    isLoading?: boolean;
};

export const ItemConfigurationRuler = ({
    expanded,
    triggerId,
    templatesContentId,
    isLoading = false,
    messageSequence = {} as MessageSequence
}: ItemConfigurationRulerProps) => {
    const {
        subTools: { common }
    } = useTranslator();

    const hasSteps = !_isEmpty(messageSequence.steps);

    return (
        <ItemConfigurationRulerStyled
            isExpanded={expanded}
            role='region'
            aria-labelledby={triggerId}
            id={templatesContentId}>
            {hasSteps && (
                <ItemConfigurationRulerStepsStyled>
                    <Heading as='h5'>{common.templates.title}</Heading>
                    <RulerSteps
                        isLoading={isLoading}
                        messageSequence={messageSequence}
                        showMessageSequenceName={false}
                    />
                </ItemConfigurationRulerStepsStyled>
            )}

            {!hasSteps && (
                <EmptyItemConfigurationRulerStepsStyled>
                    <Text size='sm'>{String(common.templates.templateNotRegistered)}</Text>
                </EmptyItemConfigurationRulerStepsStyled>
            )}
        </ItemConfigurationRulerStyled>
    );
};
