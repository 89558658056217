import { getOnlyNumbers } from './stringFunctions';

type ScrollToOptions = {
    isScrollIntoView?: boolean;
    scrollPosition?: ScrollLogicalPosition;
    offsetTop?: number;
    offsetLeft?: number;
};

/**
 * Scrolls view into an element.
 * If `search` is a string, it is assumed to be a css selector, but you may also pass an HTML Element directly.
 */
export const scrollTo = (search: string | HTMLElement, options?: ScrollToOptions) => {
    if (typeof window !== 'undefined') {
        const { isScrollIntoView = true, offsetTop = 0, offsetLeft = 0, scrollPosition = 'center' } = options ?? {};
        const element = search instanceof HTMLElement ? search : document.querySelector(search);

        if (isScrollIntoView && element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: scrollPosition
            });
            return;
        }

        if (element) {
            const { top, left } = element.getBoundingClientRect();
            const elementStyle = window.getComputedStyle(element);
            const marginTop = parseInt(getOnlyNumbers(elementStyle?.marginTop));
            window.scrollTo(left + offsetLeft, top + offsetTop + window.scrollY - marginTop);
        }
    }
};
