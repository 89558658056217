import styled from 'styled-components';

export const MyAutomationsTemplateStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7.2rem;
`;

export const ContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    overflow: inherit;
`;
