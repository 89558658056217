import { Text, defaultHeadingStyle } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { getCompactValue } from '@utils';

type BarChartHeaderItemsProps = {
    haveUnlimitedBalance?: boolean;
    totalCredits: number;
    usedCreditsAmount: number;
    percentageUsedCredits: number;
    isLooseBalance?: boolean;
    className?: string;
};

export const BarChartHeaderItems = ({
    haveUnlimitedBalance = false,
    className,
    totalCredits = 0,
    usedCreditsAmount = 0,
    percentageUsedCredits = 0.0,
    isLooseBalance = false
}: BarChartHeaderItemsProps) => {
    const {
        pages: {
            mySubscription: { barChart }
        }
    } = useTranslator();
    const { formatNumber, formatPercentage } = useNumberFunctions();

    return (
        <>
            <Text as='strong' {...defaultHeadingStyle.h6}>
                {isLooseBalance ? String(barChart.header.looseBalance) : String(barChart.header.credits)}
            </Text>
            <Text as='span' size='xs' title={formatNumber(usedCreditsAmount)}>
                <Text as='strong' {...defaultHeadingStyle.h6}>
                    {String(barChart.header.usedCredits)}
                </Text>
                <p className={className}>
                    <span>
                        {formatNumber(usedCreditsAmount, { notation: getCompactValue(usedCreditsAmount) })}(
                        {formatPercentage(percentageUsedCredits)})
                    </span>
                </p>
            </Text>
            <Text as='span' size='xs' title={formatNumber(totalCredits)}>
                <Text as='strong' {...defaultHeadingStyle.h6}>
                    {String(barChart.header.totalCredits)}
                </Text>
                <p className={className}>
                    <span>
                        {haveUnlimitedBalance
                            ? String(barChart.header.unlimited)
                            : formatNumber(totalCredits, { notation: getCompactValue(totalCredits) })}
                    </span>
                </p>
            </Text>
        </>
    );
};
