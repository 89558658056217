import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Card } from '~/components/elements/card';
import { type SelectData } from '~/components/elements/select';
import { ToggleSwitch } from '~/components/elements/toggleSwitch';
import { TooltipContent } from '~/components/elements/tooltip/content';
import { InfoTooltip } from '~/components/elements/tooltip/infoTooltip';
import { useNumberFunctions } from '~/hooks/useNumberFunctions';
import { useReplace } from '~/hooks/useReplace';
import { useTranslator } from '~/hooks/useTranslator';
import { AutomaticContractingLooseBalance } from '~/models/subscriptionTool';
import { ONE_HUNDRED, VALUE_TEN } from '~/utils/constants';
import { nameOf } from '~/utils/tsFunctions';
import { LooseBalanceCredits } from '../balanceContracting';
import { HeaderStyled, SelectFormGroupStyled, ToggleSwitchContent, CardBodyStyled } from './styles';
import { useValidation as useAutomaticContractingLooseBalanceValidation } from './validation';

const balanceUsageLimit = Array.from({ length: VALUE_TEN }, (_, index) => ((index + 1) * VALUE_TEN) / ONE_HUNDRED);
const hiringLimit = Array.from({ length: VALUE_TEN }, (_, index) => index + 1);

type AutomaticBalanceContractingProps = {
    credits: Array<LooseBalanceCredits>;
};

const AutomaticContractingLooseBalanceForm = ({ credits = [] }: AutomaticBalanceContractingProps) => {
    const theme = useTheme();
    const replace = useReplace();
    const { formatNumber, formatPercentage } = useNumberFunctions();
    const {
        pages: {
            contractLooseBalance: { balanceContractingSection }
        }
    } = useTranslator();
    const { dropdowns } = balanceContractingSection;

    const { minBalancePercentageOptions, balanceToBeContractedOptions, maxTimesToRecontractOptions } = useMemo(() => {
        const minBalancePercentageOptions = balanceUsageLimit.map<SelectData>((item) => ({
            id: item,
            label: formatPercentage(item)
        }));

        const balanceToBeContractedOptions = credits.map<SelectData>((item) => ({
            id: item.id,
            label: replace(String(dropdowns.balanceToBeContracted.option), formatNumber(item.creditAmount))
        }));

        const maxTimesToRecontractOptions = hiringLimit.map<SelectData>((item) => ({
            id: item,
            label: replace(
                String(
                    item > 1 ? dropdowns.maxTimesToRecontract.option : dropdowns.maxTimesToRecontract.singularOption
                ),
                formatNumber(item)
            )
        }));

        return {
            minBalancePercentageOptions,
            balanceToBeContractedOptions,
            maxTimesToRecontractOptions
        };
    }, [credits]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <HeaderStyled>
                {String(balanceContractingSection.automaticContracting.text)}
                <InfoTooltip>
                    <TooltipContent>{String(balanceContractingSection.automaticContracting.tooltip)}</TooltipContent>
                </InfoTooltip>
            </HeaderStyled>

            <Card type='default'>
                <CardBodyStyled>
                    <ToggleSwitchContent>
                        <ToggleSwitch
                            enableUnform
                            name={nameOf<AutomaticContractingLooseBalance>('isEnable')}
                            color={theme.colors.purple}>
                            {String(balanceContractingSection.automaticContractingLooseBalance)}
                        </ToggleSwitch>
                    </ToggleSwitchContent>

                    <SelectFormGroupStyled
                        name={nameOf<AutomaticContractingLooseBalance>('minBalancePercentage')}
                        label={String(dropdowns.minBalancePercentage.label)}
                        options={minBalancePercentageOptions}
                    />

                    <SelectFormGroupStyled
                        name={nameOf<AutomaticContractingLooseBalance>('balanceToBeContracted')}
                        label={String(dropdowns.balanceToBeContracted.label)}
                        options={balanceToBeContractedOptions}
                    />

                    <SelectFormGroupStyled
                        name={nameOf<AutomaticContractingLooseBalance>('maxTimesToRecontract')}
                        label={String(dropdowns.maxTimesToRecontract.label)}
                        options={maxTimesToRecontractOptions}
                    />
                </CardBodyStyled>
            </Card>
        </>
    );
};

export { AutomaticContractingLooseBalanceForm, useAutomaticContractingLooseBalanceValidation };
