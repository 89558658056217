import * as yup from 'yup';
import { useTranslator } from '@hooks';
import { MAX_SUBJECT_SIZE } from '@utils';

export const useTesteEmailValidation = (editorFormGroupIdentifier: string) => {
    const { subject, baseHTML, email } = useTranslator().subTools.subToolTemplateConfiguration.form;

    const testeEmailSchema = yup.object().shape({
        [editorFormGroupIdentifier]: yup.object().shape({
            subject: yup
                .string()
                .trim()
                .required(String(subject.required))
                .max(MAX_SUBJECT_SIZE, subject.max)
                .nullable(),
            baseHTML: yup.string().required(String(baseHTML.required)).nullable(),
            testEmail: yup.string().required(String(email.required)).email(email.email).nullable()
        })
    });

    return {
        testeEmailSchema
    };
};
