import { ReactNode } from 'react';
import { CardTextTitleStyled, CardTextSubTitleStyled, CardTextDescriptionStyled } from './styles';

type CardTextType = 'title' | 'subtitle' | 'description';
export type CardTextProps = {
    value: ReactNode;
    type?: CardTextType;
    show?: boolean;
    children?: ReactNode;
};

export const CardText = ({ value, children, show = true, type = 'title' }: CardTextProps) => {
    if (!show) {
        return <></>;
    }
    switch (type) {
        case 'title':
            return (
                <CardTextTitleStyled>
                    {value}
                    {children}
                </CardTextTitleStyled>
            );
        case 'subtitle':
            return (
                <CardTextSubTitleStyled>
                    {value}
                    {children}
                </CardTextSubTitleStyled>
            );
        case 'description':
            return (
                <CardTextDescriptionStyled>
                    {value}
                    {children}
                </CardTextDescriptionStyled>
            );
        default:
            return <></>;
    }
};
