import { ReactNode } from 'react';
import HTMLReactParser from 'html-react-parser';
import { FieldStyled, FieldNameStyled, FieldValueStyled } from './styles';

type FieldProps = {
    name: string;
    value: string | ReactNode;
    error?: string;
};

export const Field = ({ name, value, error }: FieldProps) => {
    return (
        <FieldStyled data-validate={error}>
            <FieldNameStyled size='xs' lineHeight='shorter'>
                {HTMLReactParser(name)}
            </FieldNameStyled>
            <FieldValueStyled as='strong' size='md' lineHeight='shorter' empty={!value}>
                {value ?? '...'}
            </FieldValueStyled>
        </FieldStyled>
    );
};
