import _first from 'lodash/first';
import _sortBy from 'lodash/sortBy';
import { ToolFunnelChartContentProps } from '~/components/containers/home/toolFunnelChartContent';
import { FunnelStageData } from '~/components/elements/funnelChart/funnelStage';
import JSONLocales from '~/locales/locales.json';
import { toolParamsTranslator } from '~/locales/route';
import { Campaign, FriendlyCampaignStatus } from '~/models/campaign';
import { SubscriptionStatusEnum } from '~/models/subscription';
import { AutomationToolUsageSummary, Tool, ToolTypeEnum, ToolUsageSummary } from '~/models/tool';
import { DEFAULT_CURRENCY } from '~/utils/constants';
import { currencyFormatter, numberFormatter, percentageFormatter } from '~/utils/numberFunctions';
import { replace } from '~/utils/stringFunctions';

type FunnelChartSummary = typeof JSONLocales.pages.home.funnelChart.summary;
type FunnelChartSummaryId = keyof FunnelChartSummary;

type ToolFunnelChartSummary = {
    id: FunnelChartSummaryId;
    amount: number;
    percentage?: number;
    count?: number;
    order: number;
};

type TranslateToolFunnelChartSummaryProps = {
    translatedSumary: FunnelChartSummary;
    item: ToolFunnelChartSummary;
    routerData: {
        locale?: string;
        locales?: string[];
        defaultLocale?: string;
    };
    currency: string;
};

export const TranslateToolFunnelChartSummary = ({
    translatedSumary,
    item,
    routerData,
    currency = DEFAULT_CURRENCY
}: TranslateToolFunnelChartSummaryProps) => {
    const translatedSummary = translatedSumary[item.id];
    const locale = routerData?.locale ?? routerData.defaultLocale;

    let formactedAmount = '';

    if (item.amount !== null) {
        formactedAmount =
            item.id !== 'sale'
                ? numberFormatter(item.amount ?? 0, locale)
                : currencyFormatter(item.amount ?? 0, locale, currency);
    }

    const formactedPercentage = item?.percentage && percentageFormatter(item.percentage, locale);
    const formactedCount = item?.count && numberFormatter(item.count, locale);

    const title = replace(translatedSummary.title, { amount: formactedAmount, count: formactedCount ?? 0 });
    const subtitle = replace(translatedSummary.subtitle, formactedPercentage ?? formactedCount);

    return { title, subtitle };
};

export const ParseToolUsageSummaryToToolFunnelChartSummaries = (
    toolUsageSummary: ToolUsageSummary,
    toolId = ToolTypeEnum.AutomationTool
) => {
    const toolFunnelChart: Array<ToolFunnelChartSummary> = [];

    if (toolId === ToolTypeEnum.AutomationTool) {
        toolFunnelChart.push({
            id: 'scheduled',
            amount: toolUsageSummary.futureScheduledCount,
            count: toolUsageSummary.searchPeriodDays,
            order: 0
        });
    } else {
        toolFunnelChart.push({
            id: 'scheduledEmails',
            amount: toolUsageSummary.futureScheduledCount,
            count: toolUsageSummary.searchPeriodDays,
            order: 0
        });
    }

    toolFunnelChart.push({
        id: 'sent',
        amount: toolUsageSummary.sentCount,
        percentage: null,
        count: toolUsageSummary.searchPeriodDays,
        order: 1
    });

    toolFunnelChart.push({
        id: 'opening',
        amount: toolUsageSummary.openingCount,
        percentage: toolUsageSummary.openingsOverSentRatio,
        order: 2
    });

    toolFunnelChart.push({
        id: 'click',
        amount: toolUsageSummary.clickCount,
        percentage: toolUsageSummary.clicksOverOpeningsRatio,
        order: 3
    });

    toolFunnelChart.push({
        id: 'sale',
        amount: toolUsageSummary.totalConversionValue,
        percentage: toolUsageSummary.salesOverClicksRatio,
        count: toolUsageSummary.conversionCount,
        order: 4
    });

    if (toolUsageSummary?.returnOnInvestment) {
        toolFunnelChart.push({
            id: 'roi',
            amount: toolUsageSummary?.returnOnInvestment,
            order: 5
        });
    }

    return toolFunnelChart;
};

export const CreateToolFunnelChartStages = (
    dataForTranslation: Omit<TranslateToolFunnelChartSummaryProps, 'item'>,
    summaries: Array<ToolFunnelChartSummary>,
    stagesColor: { [key: number]: string }
) => {
    const stages: FunnelStageData<ToolFunnelChartContentProps>[] = [];

    const sortedSummaries = _sortBy(summaries, ['order']);
    const tempArray = sortedSummaries.map((_, index) => ({ key: index }));

    for (let index = 0; index < tempArray.length; index++) {
        const currentStage: FunnelStageData<ToolFunnelChartContentProps> = tempArray[index];
        const item = sortedSummaries[index];

        const { title, subtitle } = TranslateToolFunnelChartSummary({
            routerData: { ...dataForTranslation.routerData },
            currency: dataForTranslation.currency,
            translatedSumary: dataForTranslation.translatedSumary,
            item: item
        });

        currentStage.data = { title, subtitle, stagesLength: tempArray.length };
        currentStage.backgroundColor = stagesColor[index];

        if (index < tempArray.length - 1) {
            const currentItem = tempArray[index + 1];
            currentStage.stage = currentItem;
        }

        stages.push(currentStage);
    }

    return _first(stages);
};

export const GetDefaultToolFunnelChartStages = (
    dataForTranslation: Omit<TranslateToolFunnelChartSummaryProps, 'item'>,
    stagesColor: { [key: number]: string }
) => {
    const defaultSummary: ToolUsageSummary = {
        clickCount: 0,
        clicksOverOpeningsRatio: 0,
        conversionCount: 0,
        futureScheduledCount: 0,
        isActive: true,
        subscriptionStatus: SubscriptionStatusEnum.Active,
        openingCount: 0,
        openingsOverSentRatio: 0,
        salesOverClicksRatio: 0,
        scheduledCount: 0,
        searchPeriodDays: 0,
        sentCount: 0,
        sentOverScheduledRatio: 0.0,
        totalConversionValue: 0,
        returnOnInvestment: 0
    };

    return {
        funnelStages: CreateToolFunnelChartStages(
            dataForTranslation,
            ParseToolUsageSummaryToToolFunnelChartSummaries(defaultSummary),
            stagesColor
        ),
        summary: defaultSummary
    };
};

export const GetDefaultCampaigns = () => {
    return [
        {
            id: 0,
            friendlyStatus: FriendlyCampaignStatus.Programmed,
            isSent: false,
            name: 'Campaign 0001',
            startDate: new Date(),
            sendingStartDate: new Date(),
            sentCount: 10_000
        },
        {
            id: 1,
            friendlyStatus: FriendlyCampaignStatus.Sent,
            isSent: false,
            name: 'Campaign 0002',
            startDate: new Date(),
            sendingStartDate: new Date(),
            sentCount: 10_000
        },
        {
            id: 2,
            friendlyStatus: FriendlyCampaignStatus.Sending,
            isSent: false,
            name: 'Campaign 0003',
            startDate: new Date(),
            sendingStartDate: new Date(),
            sentCount: 10_000
        },
        {
            id: 3,
            friendlyStatus: FriendlyCampaignStatus.Paused,
            isSent: false,
            name: 'Campaign 0004',
            startDate: new Date(),
            sendingStartDate: new Date(),
            sentCount: 10_000
        },
        {
            id: 4,
            friendlyStatus: FriendlyCampaignStatus.Draft,
            isSent: false,
            name: 'Campaign 0005',
            startDate: new Date(),
            sendingStartDate: new Date(),
            sentCount: 10_000
        }
    ] as Array<Campaign>;
};

export const GetDefaultAutomationToolUsageSummary = () => {
    return [
        {
            conversionCount: 40_000,
            hasTemplates: false,
            isActive: false,
            subToolId: 1,
            totalConversionValue: 20_000
        },
        {
            conversionCount: 40_000,
            hasTemplates: false,
            isActive: false,
            subToolId: 3,
            totalConversionValue: 20_000
        },
        {
            conversionCount: 40_000,
            hasTemplates: false,
            isActive: false,
            subToolId: 2,
            totalConversionValue: 20_000
        },
        {
            conversionCount: 40_000,
            hasTemplates: false,
            isActive: false,
            subToolId: 5,
            totalConversionValue: 20_000
        },
        {
            conversionCount: 40_000,
            hasTemplates: false,
            isActive: false,
            subToolId: 4,
            totalConversionValue: 20_000
        }
    ] as Array<AutomationToolUsageSummary>;
};

export const GetToolIdByName = (
    toolName: number | string,
    locale = process.env.NEXT_PUBLIC_DEFAULT_CULTURE
): null | number => {
    if (!toolName) {
        return null;
    }

    if (!isNaN(Number(toolName.toString()))) {
        const hasTool = !!Object.keys(ToolTypeEnum).find((key) => key === toolName);

        if (!hasTool) {
            return null;
        }

        return Number(toolName);
    }

    const translatedToolId = toolParamsTranslator[locale][toolName];
    if (!translatedToolId) {
        return null;
    }

    return translatedToolId;
};

export const GetToolNameById = (toolType: number, locale = process.env.NEXT_PUBLIC_DEFAULT_CULTURE) => {
    if (!toolType) {
        return null;
    }

    const tool = Object.entries(toolParamsTranslator[locale]).find(([, value]) => value === toolType);

    if (!tool || tool.length < 1) {
        return null;
    }

    return tool[0];
};

export const GetSendingTechnologiesByType = (tools: Array<Tool>, toolType: ToolTypeEnum) => {
    if (!tools) {
        return null;
    }

    return tools.find((tool) => tool.type === toolType)?.sendingTechnologies;
};
