import { UnprocessableEntityError } from '../apiService';

export class EnviouApiUnprocessableEntityError extends Error {
    constructor(public message: string, public errors?: Array<UnprocessableEntityError>) {
        super(message);
        this.name = 'EnviouApiUnprocessableEntityError';

        Object.setPrototypeOf(this, EnviouApiUnprocessableEntityError.prototype);
    }
}
