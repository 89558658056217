import { Button, Text } from '@components';
import { useAlert } from '@providers';
import MoreIcon from '~/assets/svg/icons/icon__more--horizontal.svg';
import SentIcon from '~/assets/svg/icons/icon__sent.svg';
import WhatsappIcon from '~/assets/svg/icons/sendingTechnologies/icon__whatsapp.svg';
import { WhatsappManualContentStyled, WhatsappManualInputStyled } from './styles';

export const WhatsappInput = () => {
    const { info } = useAlert();
    return (
        <WhatsappManualContentStyled onClick={(event) => event.stopPropagation()}>
            <WhatsappManualInputStyled>
                <WhatsappIcon />
                <Text size='xxs'>Oi! Felicidade será te ver...</Text>
                <Button
                    buttonSize='small'
                    color='purple'
                    onClick={() =>
                        info(
                            'TODO: A ação de redirecionar para a página de whatsapp manual será implementada em um futuro próximo!'
                        )
                    }>
                    <SentIcon />
                </Button>
            </WhatsappManualInputStyled>
            <Button
                buttonSize='small'
                isOutlined
                color='purple'
                onClick={() =>
                    info(
                        'TODO: A modal para exibir os modelos de whatsapp manual será implementada em um futuro próximo!'
                    )
                }>
                <MoreIcon />
            </Button>
        </WhatsappManualContentStyled>
    );
};
