import { useMemo } from 'react';
import { CardBody, Text } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import {
    CurrentPlanDetailsStyled,
    CurrentPlanItemDetailsStyled,
    CurrentPlanTitleStyled,
    CurrentPlanListStyled,
    CurrentPlanListItemStyled
} from './styles';

export type CurrentPlanItemDetails = {
    label?: string;
    value?: string | number;
    name?: string;
};

export type CurrentPlanDetailsProps = {
    currentPlanItemDetails?: CurrentPlanItemDetails;
    currentPlanItemDetailsList?: Array<CurrentPlanItemDetails>;
    description?: string;
    currency?: string;
};

export const CurrentPlanDetails = ({
    description,
    currentPlanItemDetails,
    currentPlanItemDetailsList = [],
    currency = 'BRL'
}: CurrentPlanDetailsProps) => {
    const { currentPlanDetails } = useTranslator().checkoutPage;
    const { formatCurrency } = useNumberFunctions();

    const amount = useMemo(() => {
        if (currentPlanItemDetailsList.length <= 0 && !currentPlanItemDetails) {
            return null;
        }

        return currentPlanItemDetailsList.reduce((total, item) => {
            if (typeof item.value === 'number') {
                return total + item.value;
            }
            return total;
        }, 0);
    }, [currentPlanItemDetailsList, currentPlanItemDetails]);

    return (
        <>
            <CurrentPlanDetailsStyled type='default'>
                <CardBody>
                    <CurrentPlanTitleStyled as='h6'>{currentPlanDetails.title}</CurrentPlanTitleStyled>
                    <div>
                        {currentPlanItemDetails && (
                            <CurrentPlanItemDetailsStyled>
                                <div>
                                    <Text as='span' size='xs'>
                                        {currentPlanItemDetails.label}
                                    </Text>
                                    <Text as='strong' size='md'>
                                        {currentPlanItemDetails.value}
                                    </Text>
                                </div>
                            </CurrentPlanItemDetailsStyled>
                        )}
                        {currentPlanItemDetailsList.length > 0 && (
                            <div>
                                <CurrentPlanListStyled>
                                    {currentPlanItemDetailsList.map((Item, index) => {
                                        return (
                                            <CurrentPlanListItemStyled key={index}>
                                                <CurrentPlanItemDetailsStyled>
                                                    <Text as='span' size='xs'>
                                                        {Item.label}
                                                    </Text>
                                                    <Text as='strong' size='md'>
                                                        {Item.name}
                                                    </Text>
                                                </CurrentPlanItemDetailsStyled>
                                                <Text as='strong' size='md'>
                                                    {Item.value
                                                        ? formatCurrency(Number(Item.value), currency)
                                                        : String(currentPlanDetails.free)}
                                                </Text>
                                            </CurrentPlanListItemStyled>
                                        );
                                    })}

                                    <CurrentPlanListItemStyled>
                                        <Text as='strong' size='md'>
                                            {currentPlanDetails.total}
                                        </Text>
                                        <Text as='strong' size='md'>
                                            {formatCurrency(amount, currency)}
                                        </Text>
                                    </CurrentPlanListItemStyled>
                                </CurrentPlanListStyled>
                            </div>
                        )}
                    </div>
                </CardBody>
            </CurrentPlanDetailsStyled>
            {description && (
                <Text as='strong' size='2xl'>
                    {description}
                </Text>
            )}
        </>
    );
};
