import {
    ReactNode,
    MouseEventHandler,
    useState,
    useEffect,
    useRef,
    useCallback,
    Children,
    isValidElement,
    cloneElement,
    ComponentProps
} from 'react';
import { useEventListener } from '@hooks';
import { ClientOnlyPortal } from '~/components/elements/clientOnlyPortal';
import { FocusLock } from '~/components/elements/focusLock';
import { ModalHeader } from './header';
import { OverlayStyled, ModalStyled } from './styles';

type ModalProps = {
    children: ReactNode;
    portalSelector?: string;
    isOpen: boolean;
    title?: string;
    onClose?: () => void;
    shouldCloseOnOverlayClick?: boolean;
};

export const Modal = ({
    children,
    portalSelector = 'section#portal',
    onClose,
    shouldCloseOnOverlayClick = true,
    ...props
}: ModalProps) => {
    const bodyRef = useRef<HTMLBodyElement>();
    const overlayRef = useRef<HTMLDivElement>();
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => setIsOpen(props.isOpen), [props.isOpen]);

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setIsOpen(false);
    }, [onClose]);

    const handleOverlayClick: MouseEventHandler<HTMLDivElement> = (event) => {
        if (event.target === overlayRef.current && shouldCloseOnOverlayClick) {
            handleClose();
        }
    };

    const handleKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        },
        [handleClose]
    );

    useEffect(() => {
        bodyRef.current = document.querySelector('body');

        const unsetOverlay = () => {
            bodyRef.current.style.overflowY = 'initial';
            bodyRef.current.style.paddingRight = 'initial';
        };

        if (isOpen) {
            const timeout = 10;
            window.setTimeout(() => setIsActive(isOpen), timeout);

            const scrollWidth = `${window.innerWidth - bodyRef.current.offsetWidth}px`;

            bodyRef.current.style.overflowY = 'hidden';
            bodyRef.current.style.paddingRight = scrollWidth;
        } else {
            unsetOverlay();
        }

        return () => {
            unsetOverlay();
        };
    }, [isOpen]);

    const transitionEnd = () => setIsActive(isOpen);

    useEventListener('keyup', handleKeyUp);
    useEventListener('transitionend', transitionEnd, overlayRef);

    return (
        (isOpen || isActive) && (
            <ClientOnlyPortal selector={portalSelector}>
                <FocusLock>
                    <OverlayStyled
                        ref={overlayRef}
                        onClick={handleOverlayClick}
                        className={isActive && isOpen && 'active'}>
                        <ModalStyled className='modal-content'>
                            {Children.map<ReactNode, ReactNode>(children, (child) => {
                                if (isValidElement<ComponentProps<typeof ModalHeader>>(child)) {
                                    return cloneElement(child, { onClose: handleClose, ...child.props });
                                } else {
                                    return child;
                                }
                            })}
                        </ModalStyled>
                    </OverlayStyled>
                </FocusLock>
            </ClientOnlyPortal>
        )
    );
};

export * from './header';
export * from './body';
export * from './footer';
