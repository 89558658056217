import { SubToolIdEnum } from './subTool';

export type ThemeTemplate = {
    id: number;
    htmlBase: string;
    htmlItems: string;
    htmlReviews: string;
};

export type Theme = {
    id: number;
    name: string;
    category: string;
    thumbnail: string;
    themeTemplate: ThemeTemplate;
};

export enum NewslatterThemeSort {
    None = 0,
    MostRecent = 1,
    MostUsed = 2
}

export enum NewsletterThemeType {
    EmailMarketing = 0,
    AbandonedCart = 1,
    Aniversario = 2,
    ProLoja = 3,
    ProCategoria = 4,
    ProProduto = 5,
    Repurchase = 6,
    NavigationTrigger = 7,
    CustomTrigger = 8,
    BankSlipReminder = 9,
    None = 99
}

export const themeTypeBySubToolId = {
    [SubToolIdEnum.BankSlipReminder]: NewsletterThemeType.BankSlipReminder,
    [SubToolIdEnum.CartRecovery]: NewsletterThemeType.AbandonedCart,
    [SubToolIdEnum.Repurchase]: NewsletterThemeType.Repurchase,
    [SubToolIdEnum.CustomTrigger]: NewsletterThemeType.CustomTrigger,
    [SubToolIdEnum.NavigationTrigger]: NewsletterThemeType.NavigationTrigger,
    [SubToolIdEnum.Emails]: NewsletterThemeType.EmailMarketing
};
