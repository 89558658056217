import styled, { css } from 'styled-components';
import { mobileAndTabletOnly } from '~/styles';
import { SubToolColors } from '~/styles/theme';

export const HeaderStyled = styled.div<{ subToolColor: SubToolColors }>`
    display: flex;
    align-items: center;
    gap: 1.6rem;

    & > div:not(.react-datepicker-wrapper) {
        display: flex;
        gap: 1.6rem;
        flex: 1;

        div {
            display: flex;
            flex-direction: column;
            flex: 1;

            h5 {
                margin-bottom: 1.2rem;
                text-transform: uppercase;
            }

            p {
                letter-spacing: 0.01px;
                line-height: 2.1rem;
                max-width: 81.8rem;
            }
        }

        i {
            width: 6.4rem;
            height: 6.4rem;
            border-radius: 50%;
            background-color: ${({ theme, subToolColor }) => theme.subToolColors[subToolColor]};
            color: ${({ theme }) => theme.colors.white};
            display: flex;

            svg {
                margin: auto;
                width: 5.2rem;
                height: 5.2rem;
            }
        }
    }

    .react-datepicker-wrapper {
        align-self: flex-end;
        min-width: 28.6rem;
    }

    ${mobileAndTabletOnly(css`
        flex-direction: column;
    `)}
`;
