import { ReactElement, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import _lowerFirst from 'lodash/lowerFirst';
import { useRouter } from 'next/router';
import { GetIconSubTool } from '@components';
import { useReplace, useTranslatedRoute, useTranslator } from '@hooks';
import {
    MessageSequence,
    MessageSequenceStep,
    PaginatedList,
    SubToolConfigurationStatusEnum,
    SubToolIdEnum
} from '@models';
import { getSubToolPagePathById, replaceRouteVariable } from '@utils';
import { routesName } from '~/locales/route';
import {
    ChangeStatusAsync as ChangeBankSlipReminderStatusAsync,
    DeleteMessageSequenceStepAsync as DeleteBankSlipReminderMessageSequenceStepAsync,
    DuplicateMessageSequenceStepAsync as DuplicateBankSlipReminderMessageSequenceStepAsync,
    GetMessageSequencesAsync as GetBankSlipReminderMessageSequencesAsync,
    getMessageSequencesUrl as getBankSlipReminderMessageSequencesUrl
} from '~/services/bankSlipReminderApiService';
import {
    ChangeStatusAsync as ChangeCartRecoveryStatusAsync,
    DeleteMessageSequenceStepAsync as DeleteCartRecoveryMessageSequenceStepAsync,
    DuplicateMessageSequenceStepAsync as DuplicateCartRecoveryMessageSequenceStepAsync,
    GetMessageSequencesAsync as GetCartRecoveryMessageSequencesAsync,
    getMessageSequencesUrl as getCartRecoveryMessageSequencesUrl
} from '~/services/cartRecoveryApiService';
import {
    GetMessageSequencesAsync as GetCustomTriggerMessageSequencesAsync,
    getMessageSequencesUrl as getCustomTriggerMessageSequencesUrl,
    DeleteMessageSequenceStepAsync as DeleteCustomTriggerMessageSequenceStepAsync,
    DuplicateMessageSequenceStepAsync as DuplicateCustomTriggerMessageSequenceStepAsync,
    ChangeStatusAsync as ChangeCustomTriggerStatusAsync
} from '~/services/customTriggerApiService';
import {
    GetMessageSequencesAsync as GetNavigationTriggerMessageSequencesAsync,
    getMessageSequencesUrl as getNavigationTriggerMessageSequencesUrl,
    DeleteMessageSequenceStepAsync as DeleteNavigationTriggerMessageSequenceStepAsync,
    DuplicateMessageSequenceStepAsync as DuplicateNavigationTriggerMessageSequenceStepAsync,
    ChangeStatusAsync as ChangeNavigationTriggerStatusAsync
} from '~/services/navigationTriggerApiService';
import {
    ChangeStatusAsync as ChangeRepurchaseStatusAsync,
    DeleteMessageSequenceStepAsync as DeleteRepurchaseMessageSequenceStepAsync,
    DuplicateMessageSequenceStepAsync as DuplicateRepurchaseMessageSequenceStepAsync,
    GetMessageSequencesAsync as GetRepurchaseMessageSequencesAsync,
    getMessageSequencesUrl as getRepurchaseMessageSequencesUrl
} from '~/services/repurchaseApiService';
import { GetSubToolNameById } from '~/services/subToolApiService';

type QueryParams = { [key: string]: string };
type UseSubToolOptionsParam = {
    subToolPage?: QueryParams;
    createTemplatePage?: QueryParams;
    editTemplatePage?: QueryParams;
    configurationPage?: QueryParams;
    repurchaseProductsPage?: QueryParams;
    customTriggerConfigureTriggerPage?: QueryParams;
    customTriggerApiTriggerPage?: QueryParams;
};

type UseSubToolOptionsUrls = keyof UseSubToolOptionsParam;
export type UseSubToolOptions = {
    params?: UseSubToolOptionsParam;
};

export type UseSubToolPageUrl = {
    href: string;
    as: string;
    hasUrl: boolean;
    query?: QueryParams;
    usePathname?: boolean;
};

export type UseSubToolPageData = {
    icon: ReactElement;
    subToolKey: string;
    translatedSubToolName: string;
    translatedSubToolQueryParam: string;
    urls: { [key in UseSubToolOptionsUrls]: UseSubToolPageUrl };
};

export const subToolPageUrl = {
    subTool: {
        baseRoute: routesName.tool.automationTool,
        route: routesName.subTool.subTool
    },
    template: {
        baseRoute: routesName.subTool.template,
        route: routesName.subTool.editTemplate
    },
    editTemplate: {
        baseRoute: routesName.subTool.template,
        route: routesName.subTool.editTemplate
    },
    configuration: {
        baseRoute: routesName.subTool.baseConfiguration,
        route: routesName.subTool.configuration
    },
    customConfiguration: {
        baseRoute: routesName.subTool.configuration,
        route: routesName.subTool.configuration
    },
    repurchaseProducts: {
        baseRoute: routesName.subTool.repurchase.itemConfiguration,
        route: routesName.subTool.repurchase.itemConfiguration
    },
    customTriggerConfigureTrigger: {
        baseRoute: routesName.subTool.customTrigger.configureTrigger,
        route: routesName.subTool.customTrigger.configureTrigger
    },
    customTriggerApiTrigger: {
        baseRoute: routesName.subTool.customTrigger.baseApiTrigger,
        route: routesName.subTool.customTrigger.apiTrigger
    }
};

type SubToolPageUrl = typeof subToolPageUrl;

type SubToolFunctions = {
    [key in SubToolIdEnum]: {
        delete: (stepId: number) => Promise<AxiosResponse<boolean>>;
        duplicate: (stepId: number) => Promise<AxiosResponse<MessageSequenceStep>>;
        changeStatus: (
            newStatus: SubToolConfigurationStatusEnum,
            subToolConfigurationId?: number
        ) => Promise<AxiosResponse<boolean>>;
        getMessageSequences: (
            ...props
        ) => Promise<AxiosResponse<PaginatedList<MessageSequence> | MessageSequence>> | null;
        getMessageSequencesUrl?: string;
    };
};

const defaultMessageSequecesResponse = Promise.resolve({ data: { items: [] } } as AxiosResponse<
    PaginatedList<MessageSequence>
>);

export const subToolsFunctions: SubToolFunctions = {
    [SubToolIdEnum.Repurchase]: {
        delete: DeleteRepurchaseMessageSequenceStepAsync,
        duplicate: DuplicateRepurchaseMessageSequenceStepAsync,
        getMessageSequences: GetRepurchaseMessageSequencesAsync,
        changeStatus: ChangeRepurchaseStatusAsync,
        getMessageSequencesUrl: getRepurchaseMessageSequencesUrl
    },
    [SubToolIdEnum.NavigationTrigger]: {
        delete: DeleteNavigationTriggerMessageSequenceStepAsync,
        duplicate: DuplicateNavigationTriggerMessageSequenceStepAsync,
        changeStatus: ChangeNavigationTriggerStatusAsync,
        getMessageSequences: GetNavigationTriggerMessageSequencesAsync,
        getMessageSequencesUrl: getNavigationTriggerMessageSequencesUrl
    },
    [SubToolIdEnum.CartRecovery]: {
        delete: DeleteCartRecoveryMessageSequenceStepAsync,
        duplicate: DuplicateCartRecoveryMessageSequenceStepAsync,
        getMessageSequences: GetCartRecoveryMessageSequencesAsync,
        changeStatus: ChangeCartRecoveryStatusAsync,
        getMessageSequencesUrl: getCartRecoveryMessageSequencesUrl
    },
    [SubToolIdEnum.CustomTrigger]: {
        delete: DeleteCustomTriggerMessageSequenceStepAsync,
        duplicate: DuplicateCustomTriggerMessageSequenceStepAsync,
        getMessageSequences: GetCustomTriggerMessageSequencesAsync,
        changeStatus: ChangeCustomTriggerStatusAsync,
        getMessageSequencesUrl: getCustomTriggerMessageSequencesUrl
    },
    [SubToolIdEnum.BankSlipReminder]: {
        delete: DeleteBankSlipReminderMessageSequenceStepAsync,
        duplicate: DuplicateBankSlipReminderMessageSequenceStepAsync,
        getMessageSequences: GetBankSlipReminderMessageSequencesAsync,
        changeStatus: ChangeBankSlipReminderStatusAsync,
        getMessageSequencesUrl: getBankSlipReminderMessageSequencesUrl
    },
    [SubToolIdEnum.Emails]: {
        delete: () => null,
        duplicate: () => null,
        changeStatus: () => null,
        getMessageSequences: () => defaultMessageSequecesResponse
    }
} as const;

export const getSubToolUrl = (type: keyof SubToolPageUrl) => {
    const { baseRoute, route } = subToolPageUrl[type];

    return {
        href: baseRoute,
        as: route
    };
};

export const useSubToolPage = () => {
    const {
        enums: { subToolIdEnum: translationSubToolEnum }
    } = useTranslator();
    const translateRoute = useTranslatedRoute();
    const { locale } = useRouter();
    const replace = useReplace();

    const getSubToolPageData = useCallback(
        (subToolId: SubToolIdEnum, options?: UseSubToolOptions) => {
            const icon = GetIconSubTool(subToolId, 'subTools');
            const subToolKey = _lowerFirst(SubToolIdEnum[subToolId]);
            const translatedSubToolName = translationSubToolEnum[SubToolIdEnum[subToolId]];
            const translatedSubToolQueryParam = GetSubToolNameById(subToolId, locale);

            const subToolRoute = getSubToolUrl('subTool');
            const subToolConfigurationRoute = getSubToolUrl('configuration');
            const customSubToolConfigurationRoute = getSubToolUrl('customConfiguration');
            const subToolTemplateRoute = getSubToolUrl('template');
            const editSubToolTemplateRoute = getSubToolUrl('editTemplate');
            const repurchaseProductsRoute = getSubToolUrl('repurchaseProducts');
            const configureTriggerRoute = getSubToolUrl('customTriggerConfigureTrigger');
            const apiTriggerRoute = getSubToolUrl('customTriggerApiTrigger');

            const { params } = options ?? {};

            const translatedSubToolRoute = translateRoute(subToolRoute.as, params?.subToolPage);
            const translatedSubToolConfigurationRoute = translateRoute(subToolConfigurationRoute.as, {
                subTool: translatedSubToolQueryParam,
                ...params?.configurationPage
            });
            const translatedSubToolTemplateRoute = translateRoute(subToolTemplateRoute.as, {
                subTool: translatedSubToolQueryParam,
                ...params?.createTemplatePage
            });
            const translatedEditSubToolTemplateRoute = translateRoute(editSubToolTemplateRoute.as, {
                subTool: translatedSubToolQueryParam,
                ...params?.editTemplatePage
            });
            const translatedRepurchaseProductsRoute = translateRoute(repurchaseProductsRoute.as, {
                params: '',
                ...params?.repurchaseProductsPage
            });
            const translatedConfigureTriggerRoute = translateRoute(configureTriggerRoute.as, {
                params: '',
                ...params?.customTriggerConfigureTriggerPage
            });
            const translatedApiTriggerRoute = translateRoute(apiTriggerRoute.as, {
                params: '',
                ...params?.customTriggerApiTriggerPage
            });

            let subToolConfigurationHref: string = subToolConfigurationRoute?.href;

            if (subToolId === SubToolIdEnum.BankSlipReminder || subToolId === SubToolIdEnum.CartRecovery) {
                subToolConfigurationHref = replace(
                    customSubToolConfigurationRoute?.href,
                    { subTool: subToolKey },
                    { start: '{', end: '}' }
                );
            }

            return {
                icon,
                subToolKey,
                translatedSubToolName: String(translatedSubToolName),
                translatedSubToolQueryParam,
                urls: {
                    configurationPage: {
                        href: subToolConfigurationHref,
                        as: translatedSubToolConfigurationRoute,
                        hasUrl: !!subToolConfigurationHref
                    },
                    createTemplatePage: {
                        href: replaceRouteVariable(subToolTemplateRoute.href, {
                            subToolPagePath: getSubToolPagePathById(subToolId),
                            ...params?.createTemplatePage
                        }),
                        as: translatedSubToolTemplateRoute,
                        hasUrl: !!subToolTemplateRoute?.href
                    },
                    editTemplatePage: {
                        href: replaceRouteVariable(editSubToolTemplateRoute.href, {
                            subToolPagePath: getSubToolPagePathById(subToolId),
                            ...params?.editTemplatePage
                        }),
                        as: translatedEditSubToolTemplateRoute,
                        hasUrl: !!editSubToolTemplateRoute?.href
                    },
                    repurchaseProductsPage: {
                        href: repurchaseProductsRoute.href,
                        as: translatedRepurchaseProductsRoute,
                        hasUrl: !!repurchaseProductsRoute?.href
                    },
                    customTriggerConfigureTriggerPage: {
                        href: configureTriggerRoute.href,
                        as: translatedConfigureTriggerRoute,
                        hasUrl: !!configureTriggerRoute?.href
                    },
                    customTriggerApiTriggerPage: {
                        href: replaceRouteVariable(apiTriggerRoute.href, { ...params?.customTriggerApiTriggerPage }),
                        as: translatedApiTriggerRoute,
                        hasUrl: !!apiTriggerRoute?.href
                    },
                    subToolPage: {
                        href: subToolRoute.href,
                        as: translatedSubToolRoute,
                        hasUrl: !!subToolRoute?.href
                    }
                }
            } as UseSubToolPageData;
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [locale]
    );

    const duplicateTemplateAsync = useCallback((subToolId: SubToolIdEnum) => {
        return subToolsFunctions[subToolId].duplicate;
    }, []);

    const deactivateTemplateAsync = useCallback((subToolId: SubToolIdEnum) => {
        return subToolsFunctions[subToolId].delete;
    }, []);

    const changeStatusAsync = useCallback((subToolId: SubToolIdEnum) => {
        return subToolsFunctions[subToolId].changeStatus;
    }, []);

    return { getSubToolPageData, duplicateTemplateAsync, deactivateTemplateAsync, changeStatusAsync };
};
