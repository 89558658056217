import styled, { css } from 'styled-components';

export type SelectTriggerStyledProps = {
    background?: string;
    inputSize?: 'small' | 'medium' | 'large';
    isDatePicker?: boolean;
    hasValue: boolean;
};

export const SelectTriggerStyled = styled.button<SelectTriggerStyledProps>`
    width: 100%;
    padding: ${({ theme }) => `1rem ${theme.space.md} 0.83rem`};

    padding-right: 4.4rem;
    border: 0.1rem solid ${({ theme }) => theme.default.input.borderColor};
    border-radius: ${({ theme }) => theme.radii.sm};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fontSizes.md};

    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: ${({ background, theme }) => background || theme.default.input.backgroundColor};
    transition: all 0.15s ease;

    &[aria-expanded='true'],
    &:focus {
        box-shadow: inset 0 0 0 0.1rem ${({ theme }) => theme.colors.purple};
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        border-color: ${({ theme }) => theme.default.input.disabledBorderColor};
        color: ${({ theme }) => theme.colors.philippineSilver};
        box-shadow: none;
        filter: grayscale(1);
        cursor: normal;

        .select-trigger__arrow-icon {
            color: ${({ theme }) => theme.colors.philippineSilver};
        }
    }

    ${({ inputSize = 'small', hasValue, theme, isDatePicker }) =>
        inputSize === 'small' &&
        css`
            font-size: ${isDatePicker ? theme.fontSizes.xs : theme.fontSizes.sm};
            color: ${!hasValue ? theme.colors.lightGray : theme.colors.purple};
            font-weight: ${theme.fontWeights.semiBold};
            padding: ${`1rem ${theme.space.md} 0.83rem`};
            height: 4rem;

            &:has(svg) {
                svg {
                    margin-bottom: ${theme.space.xxxs};
                }
            }
        `}

    p,
    span,
    b,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit !important;
    }

    .select-trigger__arrow-icon {
        color: ${({ theme }) => theme.colors.purple};
        transition: all 0.1s;
    }

    &[aria-expanded='true'] {
        .select-trigger__arrow-icon {
            transform: rotate(-180deg);
        }
    }

    .select-trigger__label {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }
`;
