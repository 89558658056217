import { memo } from 'react';
import { useNumberFunctions } from '@hooks';
import { SubToolIdEnum } from '@models';
import { SubToolStatisticData } from '~/services/statisticsService';
import { AutomationStatisticsPerSendingTechnologyModal } from '../statisticsPerSendingTechnologyModal';
import { PercentageValueStyled, SubToolListItemContentStyled, SubToolListItemStyled, ValueStyled } from './styles';

type SubToolItemProps = {
    subTool: SubToolStatisticData;
};

const SubToolItemComponent = ({ subTool, ...props }: SubToolItemProps) => {
    const { formatNumber, formatCurrency, formatPercentage } = useNumberFunctions();

    return (
        <SubToolListItemStyled {...props}>
            <SubToolListItemContentStyled>
                <div>
                    <ValueStyled size='md'>{subTool.title}</ValueStyled>
                    <AutomationStatisticsPerSendingTechnologyModal subToolId={subTool.subToolId as SubToolIdEnum} />
                </div>
                <span>
                    <ValueStyled>{formatNumber(subTool.sentCount)}</ValueStyled>
                    <span>-</span>
                </span>
                <span>
                    <ValueStyled>{formatNumber(subTool.rejectionCount)}</ValueStyled>
                    <span>-</span>
                </span>
                <span>
                    <ValueStyled>{formatNumber(subTool.openingCount)}</ValueStyled>
                    <PercentageValueStyled>{formatPercentage(subTool.openingPercentage)}</PercentageValueStyled>
                </span>
                <span>
                    <ValueStyled>{formatNumber(subTool.clickCount)}</ValueStyled>
                    <PercentageValueStyled>{formatPercentage(subTool.clickPercentage)}</PercentageValueStyled>
                </span>
                <span>
                    <ValueStyled>
                        {formatCurrency(subTool.soldAmount)} ({formatNumber(subTool.saleCount)})
                    </ValueStyled>
                    <PercentageValueStyled>{formatPercentage(subTool.salePercentage)}</PercentageValueStyled>
                </span>
            </SubToolListItemContentStyled>
        </SubToolListItemStyled>
    );
};

export const SubToolItem = memo(SubToolItemComponent);
