import { ToolUsageSummary, ToolTypeEnum, AutomationToolUsageSummary } from '@models';
import { axiosClient } from './apiService';

export const getAutomationSubToolsSummaryUrl = '/tool/automation/detailed-summary';
export const GetToolSummaryUrl = (toolId: ToolTypeEnum) => `/tool/${toolId}/summary`;

export const GetToolSummaryAsync = async (toolId: ToolTypeEnum, signal = undefined) => {
    return axiosClient.get<ToolUsageSummary>(GetToolSummaryUrl(toolId), { signal });
};

export const GetAutomationSubToolsSummaryAsync = async () => {
    return axiosClient.get<Array<AutomationToolUsageSummary>>('/tool/automation/detailed-summary');
};
