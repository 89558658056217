import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import HtmlReactParser from 'html-react-parser';
import _lowerFirst from 'lodash/lowerFirst';
import _upperFirst from 'lodash/upperFirst';
import Image from 'next/image';
import { Button, DropdownFormGroup, InputFormGroup, Link, OptionProps, Text } from '@components';
import { useTranslator } from '@hooks';
import { MessageSequenceType, ShippingOrderEnum, SubToolIdEnum } from '@models';
import {
    RepurchaseItemData,
    RepurchaseItemConfiguration,
    RepurchaseItemType,
    useAuth,
    useAutomationRulerProvider
} from '@providers';
import { createQueryParams, isValidURL } from '@utils';
import NoImageIcon from '~/assets/svg/icons/icon__no-image.svg';
import { ItemConfigurationStyled } from '../styles';
import { ItemConfigurationDetailsImageStyled, ItemConfigurationDetailsStyled } from './styles';

type ItemConfigurationDetailsProps = {
    expanded: boolean;
    triggerId: string;
    templatesContentId: string;
    onExpanded: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
    item?: RepurchaseItemConfiguration;
    onItemChange?: (newData: RepurchaseItemData) => void;
    error?: Error;
};

const externaImageLoader = ({ src }: { src: string }) => src;

const DEFAULT_AVERAGE_REPURCHASE_PERIOD_LABEL = 23;

export const ItemConfigurationDetails = ({
    expanded,
    triggerId,
    templatesContentId,
    onItemChange,
    onExpanded,
    error,
    item = {} as RepurchaseItemConfiguration
}: ItemConfigurationDetailsProps) => {
    const { user } = useAuth();

    const imageUrl = item.type === 'general' ? user?.avatarUrl : item.imageUrl;
    const defaultTemplateType: RepurchaseItemType = item.type === 'product' ? 'category' : 'general';

    const [isValidImage, setIsValidImage] = useState(() => isValidURL(imageUrl));
    const [manualAveragePeriod, setManualAveragePeriod] = useState(item.manualAverageRepurchasePeriod ?? undefined);
    const [templateType, setTemplateType] = useState<RepurchaseItemType>(() =>
        item.templateType
            ? (_lowerFirst(MessageSequenceType[item.templateType]) as RepurchaseItemType)
            : defaultTemplateType
    );

    const {
        buttons,
        enums: { typeMeasuresTimeEnum },
        pages: {
            manageRepurchaseItem: {
                sections: {
                    setup: {
                        listItem: { inputs, labels }
                    }
                }
            }
        }
    } = useTranslator();

    const getSubToolPageData = useAutomationRulerProvider((context) => context.getSubToolPageDetails);
    const { createTemplatePage } = getSubToolPageData({
        params: {
            createTemplatePage: {
                params: createQueryParams({
                    type: MessageSequenceType[_upperFirst(item.type)],
                    identifier: item.identifier
                })
            }
        }
    }).urls;

    const defaultRepurchasePeriodPlaceholder = `${DEFAULT_AVERAGE_REPURCHASE_PERIOD_LABEL} ${typeMeasuresTimeEnum.days}`;

    const templateTypeOptions = [
        { value: 'general', text: String(inputs.templateUsed.options.store) },
        item.type !== 'general' && {
            value: 'category',
            text: String(inputs.templateUsed.options.category),
            disabled: item.type === 'category' && !item.hasTemplate
        },
        item.type === 'product' && {
            value: 'product',
            text: String(inputs.templateUsed.options.products),
            disabled: !item.hasTemplate
        }
    ].filter(Boolean) as Array<OptionProps<RepurchaseItemType>>;

    const handleTemplateTypeChange = (value: RepurchaseItemType) => {
        if (templateType === value) {
            return;
        }

        setTemplateType(value);
        onItemChange({
            subToolId: SubToolIdEnum.Repurchase,
            id: item.identifier,
            templateType: MessageSequenceType[_upperFirst(value)],
            manualAverageRepurchasePeriod: manualAveragePeriod,
            cacheKey: item.cacheKey,
            type: item.type
        });
    };

    const handleManualAverageRepurchasePeriodChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const value = event.target.value ? Number(event.target.value) : undefined;

        if (value === manualAveragePeriod || value < 0) {
            return;
        }

        setManualAveragePeriod(value);
    };

    const handleBlur = () => {
        if (item.manualAverageRepurchasePeriod === manualAveragePeriod) {
            return;
        }

        onItemChange({
            subToolId: SubToolIdEnum.Repurchase,
            id: item.identifier,
            templateType: MessageSequenceType[_upperFirst(templateType)],
            manualAverageRepurchasePeriod: manualAveragePeriod,
            cacheKey: item.cacheKey,
            type: item.type
        });
    };

    useEffect(() => {
        setManualAveragePeriod(item.manualAverageRepurchasePeriod);
        setTemplateType(
            item.templateType
                ? (_lowerFirst(MessageSequenceType[item.templateType]) as RepurchaseItemType)
                : defaultTemplateType
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.manualAverageRepurchasePeriod, item.templateType]);

    return (
        <ItemConfigurationStyled
            data-shimmer
            onClick={onExpanded}
            id={triggerId}
            aria-expanded={expanded}
            aria-controls={templatesContentId}>
            <ItemConfigurationDetailsStyled onClick={onExpanded}>
                {item.type !== 'category' && (
                    <ItemConfigurationDetailsImageStyled className='image-content'>
                        <a
                            href={imageUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={(event) => event.stopPropagation()}>
                            {isValidImage && (
                                <Image
                                    loader={externaImageLoader}
                                    unoptimized
                                    src={imageUrl}
                                    width={100}
                                    height={100}
                                    alt={item.name}
                                    layout='responsive'
                                    objectFit='contain'
                                    onError={() => setIsValidImage(false)}
                                />
                            )}
                            {!isValidImage && <NoImageIcon title={String(labels.noImage)} />}
                        </a>
                    </ItemConfigurationDetailsImageStyled>
                )}
                <div>
                    <Text as='strong' size='md' title={item.name}>
                        {HtmlReactParser(item.name)}
                    </Text>
                    <Text size='xxs'>{item.repurchaseDetails}</Text>
                    {item.category && (
                        <Text size='xxs'>
                            {String(labels.category)} {item.category}
                        </Text>
                    )}
                </div>
            </ItemConfigurationDetailsStyled>
            <DropdownFormGroup
                name='templateType'
                enableUnform={false}
                containerClassName='template-type-dropdown'
                options={templateTypeOptions}
                value={templateType}
                onChange={handleTemplateTypeChange}
                inputSize='small'
                error={error?.message}
            />
            <InputFormGroup
                name='manualAverageRepurchasePeriod'
                type='text'
                mask='integer'
                containerClassName='manual-average-period-input'
                inputSize='small'
                autoComplete='off'
                enableUnform={false}
                aria-label={String(inputs.deadlineForRepurchase.label)}
                value={manualAveragePeriod ?? ''}
                onChange={handleManualAverageRepurchasePeriodChange}
                onBlur={handleBlur}
                placeholder={item.averageRepurchasePeriodDescription || defaultRepurchasePeriodPlaceholder}
            />
            <Link href={createTemplatePage.href} as={createTemplatePage.as} tabIndex={-1}>
                <Button
                    color='purple'
                    buttonSize='small'
                    disabled={item.stepsAmount > ShippingOrderEnum.FifthSending}
                    id={`button-${item.identifier}`}>
                    {String(buttons.createTemplate)}
                </Button>
            </Link>

            <Button
                styledAs='link'
                buttonSize='small'
                color='deepCerise'
                className='view-more-button'
                onClick={onExpanded}>
                {String(buttons.viewMore)}
            </Button>
        </ItemConfigurationStyled>
    );
};
