import { useState, useRef } from 'react';
import { Link } from '@components';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    useTransitionStyles,
    FloatingPortal,
    useTypeahead,
    useListNavigation
} from '@floating-ui/react';
import MoreIcon from '~/assets/svg/icons/icon__more.svg';
import { SubToolColors } from '~/styles/theme';
import { PopupActionStyled, PopupStyled } from './styles';

export type RulerStepPopupItem = {
    id: string;
    url?: {
        href: string;
        as?: string;
    };
    description: string;
    isRemoveOption?: boolean;
    onClick?: () => void;
};

type RulerStepPopupProps = {
    subToolKey: SubToolColors;
    items: Array<RulerStepPopupItem>;
};

export const RulerStepPopup = ({ subToolKey, items }: RulerStepPopupProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(-1);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const listRef = useRef<Array<HTMLElement | null>>([]);
    const listContentRef = useRef(items.map((item) => item.description));
    const isTypingRef = useRef(false);

    const { x, y, refs, strategy, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'right',
        middleware: [
            offset({ crossAxis: -55, mainAxis: 2 }),
            flip({
                fallbackAxisSideDirection: 'end'
            }),
            shift()
        ],
        whileElementsMounted: autoUpdate
    });

    const listNav = useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex,
        focusItemOnHover: false,
        onNavigate: setActiveIndex,
        loop: true
    });

    const typeahead = useTypeahead(context, {
        listRef: listContentRef,
        activeIndex,
        selectedIndex,
        onMatch: isOpen ? setActiveIndex : setSelectedIndex,
        onTypingChange(isTyping) {
            isTypingRef.current = isTyping;
        }
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        dismiss,
        role,
        listNav,
        typeahead,
        click
    ]);

    const { isMounted, styles } = useTransitionStyles(context, {
        duration: {
            close: 100,
            open: 200
        }
    });

    const handleSelect = (index: number) => {
        setSelectedIndex(index);
        setIsOpen(false);
        items[index]?.onClick && items[index]?.onClick();
    };

    return (
        <>
            <PopupActionStyled ref={refs.setReference} {...getReferenceProps()} aria-label='open ruler popup'>
                <MoreIcon />
            </PopupActionStyled>
            {isMounted && (
                <FloatingPortal id='section#portal'>
                    <PopupStyled
                        ref={refs.setFloating}
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            ...styles
                        }}
                        subToolColor={subToolKey}
                        {...getFloatingProps()}>
                        {items.map((item, index) => (
                            <li
                                key={item.id}
                                className={`${item.isRemoveOption ? 'remove-option' : ''}`}
                                tabIndex={index === activeIndex ? 0 : -1}
                                ref={(node) => {
                                    listRef.current[index] = node;
                                }}
                                {...getItemProps({
                                    onClick() {
                                        handleSelect(index);
                                    },

                                    onKeyDown(event) {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            handleSelect(index);
                                        }

                                        if (event.key === ' ' && !isTypingRef.current) {
                                            event.preventDefault();
                                            handleSelect(index);
                                        }
                                    }
                                })}>
                                <Link {...item?.url} showLink={!!item?.url}>
                                    <span>{item.description}</span>
                                </Link>
                            </li>
                        ))}
                    </PopupStyled>
                </FloatingPortal>
            )}
        </>
    );
};
