import parsePhoneNumber from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import { Phone } from '@models';
import { completePhoneNumberRegex, getOnlyNumbers, DEFAULT_COUNTRY_TO_VALIDATE_CELL_NUMBER } from '@utils';

export enum phoneGroupIndex {
    ddi = 1,
    ddd = 2,
    ninthDigit = 3,
    numberBeforeDash = 4,
    numberAfterDash = 5
}

export const convertToPhoneModel = (phoneNumber: string): Phone => {
    if (completePhoneNumberRegex.test(phoneNumber)) {
        const match = completePhoneNumberRegex.exec(phoneNumber) as string[];

        return {
            ddi: match[phoneGroupIndex.ddi],
            ddd: match[phoneGroupIndex.ddd],
            number:
                `${match[phoneGroupIndex.ninthDigit] ?? ''}` +
                `${match[phoneGroupIndex.numberBeforeDash]}` +
                `${match[phoneGroupIndex.numberAfterDash]}`
        };
    } else {
        return {
            ddi: null,
            ddd: null,
            number: getOnlyNumbers(phoneNumber)
        };
    }
};

export const convertPhoneToString = (phone?: Phone, onlyNumbers = false) => {
    if (!phone || isEmpty(phone)) {
        return null;
    }

    let phoneText = '';

    if (phone.ddi) {
        phoneText += `+${phone.ddi} `;
    }
    if (phone.ddd) {
        phoneText += `(${phone.ddd}) `;
    }

    phoneText += `${phone.number}`;

    if (onlyNumbers) {
        phoneText = getOnlyNumbers(phoneText);
    }

    return phoneText;
};

type ConvertToPhoneNumberFunction = typeof parsePhoneNumber;
export const convertToPhoneNumber: ConvertToPhoneNumberFunction = (
    text,
    defaultCountry = DEFAULT_COUNTRY_TO_VALIDATE_CELL_NUMBER
) => parsePhoneNumber(text, defaultCountry);
