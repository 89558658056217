import { HelpSection, TutorialSection } from '@containers';
import { useTranslator } from '@hooks';
import { SubTool } from '@models';
import { PageSubTitle, PageTitle } from '~/styles/defaultComponentStyles';

export type HelpAndTutorialsTemplateProps = {
    subTools?: Array<SubTool>;
};

export const HelpAndTutorialsTemplate = ({ subTools }: HelpAndTutorialsTemplateProps) => {
    const {
        pages: {
            helpAndTutorials: { tutorials }
        }
    } = useTranslator();
    return (
        <div>
            <PageTitle>{String(tutorials.title)}</PageTitle>
            <PageSubTitle>{String(tutorials.description)}</PageSubTitle>

            <TutorialSection subTools={subTools} />

            <HelpSection />
        </div>
    );
};
