import { subDays } from 'date-fns';
import { Button, CardBody, CardHeader, Link, Shimmer, Text } from '@components';
import { useTranslator, useRedirectToPainel2 } from '@hooks';
import { Campaign } from '@models';
import { formatDate, DateFormatEnum, PAINELN2_CAMPAIGN_LINK, PAINELN2_CREATE_CAMPAIGN_LINK } from '@utils';
import { CardItemsStyled, CardStyled } from '../styles';
import { CampaignItem } from './campaignItem';
import { EmptyCampaignsStyled } from './styles';

type LastedCampaignsCardProps = {
    latestCampaigns: Array<Campaign>;
    searchPeriodDays?: number;
    isLoading?: boolean;
};

export const LastedCampaignsCard = ({
    latestCampaigns,
    searchPeriodDays = 30,
    isLoading = false
}: LastedCampaignsCardProps) => {
    const {
        lastedCampaignsCard: { title, viewReport, createCampaign, emptyCampaignMessage }
    } = useTranslator().pages.home;
    const { redirect } = useRedirectToPainel2();

    const startDate = formatDate(subDays(new Date(), searchPeriodDays), DateFormatEnum.OnlyDateUSFormat);
    const endDate = formatDate(new Date(), DateFormatEnum.OnlyDateUSFormat);

    const viewReportUrl = new URL(PAINELN2_CAMPAIGN_LINK);
    viewReportUrl.searchParams.set('startDate', startDate);
    viewReportUrl.searchParams.set('endDate', endDate);

    const hasCampaigns = latestCampaigns?.length > 0;

    return (
        <Shimmer isActive={isLoading}>
            <CardStyled type='group'>
                <CardHeader
                    data-shimmer
                    title={title}
                    actions={
                        hasCampaigns && (
                            <Link href={redirect(viewReportUrl.href, false)} showLink={!isLoading}>
                                <Button buttonSize='small' color='purple' disabled={isLoading}>
                                    {String(viewReport)}
                                </Button>
                            </Link>
                        )
                    }
                />
                <CardBody>
                    {hasCampaigns && (
                        <CardItemsStyled>
                            {latestCampaigns.map((campaign) => (
                                <CampaignItem key={campaign.id} campaign={campaign} data-shimmer />
                            ))}
                        </CardItemsStyled>
                    )}
                    {!hasCampaigns && (
                        <EmptyCampaignsStyled>
                            <Text as='strong' size='2xl' lineHeight='short'>
                                {String(emptyCampaignMessage)}
                            </Text>
                            <Link href={redirect(PAINELN2_CREATE_CAMPAIGN_LINK, false)}>
                                <Button buttonSize='small' color='purple'>
                                    {String(createCampaign)}
                                </Button>
                            </Link>
                        </EmptyCampaignsStyled>
                    )}
                </CardBody>
            </CardStyled>
        </Shimmer>
    );
};
