const ZIP_CODE_LENGTH = 5;

export enum InputMaskEnum {
    zipCode = 'zipCode',
    phone = 'phone',
    taxpayerRegistry = 'taxpayerRegistry',
    creditCardNumber = 'creditCardNumber',
    hiddenCreditCardNumberMask = 'hiddenCreditCardNumberMask',
    creditCardCVV = 'creditCardCVV',
    integer = 'integer',
    positiveAndNegativeInteger = 'positiveAndNegativeInteger'
}

const ZipCodeMask = (value: string) => {
    if (value.length > ZIP_CODE_LENGTH) {
        value = value.replace(/^(\d{3,5})(\d{0,4}).*/, '$1-$2');
    }

    return value;
};

const PhoneMask = (value: string) => {
    const lengthPhone = value.length;

    const minLengthEleven = 11;
    if (lengthPhone > minLengthEleven) {
        return value.replace(/^\+?(\d{3}|\d{2})(\d{2})(\d{5})(\d{4}).*/, '+$1 ($2) $3-$4');
    }
    const minLengthTen = 10;
    if (lengthPhone > minLengthTen) {
        return value.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    }

    const minLengthSix = 6;
    if (lengthPhone > minLengthSix) {
        return value.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    }

    const minLengthTwo = 2;
    if (lengthPhone > minLengthTwo) {
        return value.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    }

    return value.replace(/^(\d*)/, '($1');
};

const TaxpayerRegistryMask = (value: string) => {
    const maxLength = 11;

    if (value.length <= maxLength) {
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
        value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{0,4})(\d{2}).*/, '$1.$2.$3/$4-$5');
    }

    return value;
};

export const CreditCardNumberMask = (value: string) => {
    const finalIndex = 16;
    return value
        .slice(0, finalIndex)
        .replace(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/, '$1 $2 $3 $4')
        .trimEnd();
};

export const HiddenCreditCardNumberMask = (value: string) => {
    const creditCardNUmberLenght = 16;
    return value
        .padStart(creditCardNUmberLenght, '*')
        .match(/.{1,4}/g)
        .join(' ');
};

const CreditCardCVVMask = (value: string) => {
    const finalIndex = 3;
    return value
        .slice(0, finalIndex)
        .replace(/([\d]{3})/, '$1')
        .trim();
};

export const ApplyMask = (value: string | null, mask: InputMaskEnum) => {
    if (!value) {
        return value;
    }

    value = String(value);
    if (mask === InputMaskEnum.positiveAndNegativeInteger) {
        value = value.replace(/(?!^-)[^0-9.]/g, '');
    } else {
        value = RemoveMask(value);
    }

    switch (mask) {
        case InputMaskEnum.zipCode:
            return ZipCodeMask(value);
        case InputMaskEnum.phone:
            return PhoneMask(value);
        case InputMaskEnum.taxpayerRegistry:
            return TaxpayerRegistryMask(value);
        case InputMaskEnum.creditCardNumber:
            return CreditCardNumberMask(value);
        case InputMaskEnum.hiddenCreditCardNumberMask:
            return HiddenCreditCardNumberMask(value);
        case InputMaskEnum.creditCardCVV:
            return CreditCardCVVMask(value);
        case InputMaskEnum.integer:
            return value;
        default:
            return value;
    }
};

export const RemoveMask = (value: string) => {
    if (!value) {
        return value;
    }

    return value.replace && value.replace(/\D/g, '');
};
