import { memo } from 'react';
import { useNumberFunctions } from '@hooks';
import { SubToolIdEnum } from '@models';
import { CustomTriggerStatisticData } from '~/services/statisticsService';
import { AutomationStatisticsPerSendingTechnologyModal } from '../statisticsPerSendingTechnologyModal';
import { PercentageValueStyled, SubToolListItemContentStyled, SubToolListItemStyled, ValueStyled } from './styles';

type CustomTriggerItemProps = {
    customTrigger: CustomTriggerStatisticData;
};

const CustomTriggerItemComponent = ({ customTrigger, ...props }: CustomTriggerItemProps) => {
    const { formatNumber, formatCurrency, formatPercentage } = useNumberFunctions();

    return (
        <SubToolListItemStyled {...props}>
            <SubToolListItemContentStyled>
                <div>
                    <ValueStyled size='md'>{customTrigger.title}</ValueStyled>
                    <AutomationStatisticsPerSendingTechnologyModal
                        subToolId={SubToolIdEnum.CustomTrigger}
                        customTriggerId={customTrigger.customTriggerId}
                        title={customTrigger.title}
                    />
                </div>
                <span>
                    <ValueStyled>{formatNumber(customTrigger.sentCount)}</ValueStyled>
                    <span>-</span>
                </span>
                <span>
                    <ValueStyled>{formatNumber(customTrigger.rejectionCount)}</ValueStyled>
                    <span>-</span>
                </span>
                <span>
                    <ValueStyled>{formatNumber(customTrigger.openingCount)}</ValueStyled>
                    <PercentageValueStyled>{formatPercentage(customTrigger.openingPercentage)}</PercentageValueStyled>
                </span>
                <span>
                    <ValueStyled>{formatNumber(customTrigger.clickCount)}</ValueStyled>
                    <PercentageValueStyled>{formatPercentage(customTrigger.clickPercentage)}</PercentageValueStyled>
                </span>
                <span>
                    <ValueStyled>
                        {formatCurrency(customTrigger.soldAmount)} ({formatNumber(customTrigger.saleCount)})
                    </ValueStyled>
                    <PercentageValueStyled>{formatPercentage(customTrigger.salePercentage)}</PercentageValueStyled>
                </span>
            </SubToolListItemContentStyled>
        </SubToolListItemStyled>
    );
};

export const CustomTriggerItem = memo(CustomTriggerItemComponent);
