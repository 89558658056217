import * as yup from 'yup';
import { AutomaticContractingLooseBalance } from '@models';

export const useValidation = () => {
    const automaticContractingLooseBalanceSchema: yup.SchemaOf<AutomaticContractingLooseBalance> = yup
        .object()
        .optional()
        .shape({
            isEnable: yup.boolean().optional().default(false),
            minBalancePercentage: yup.number().optional(),
            balanceToBeContracted: yup.number().optional(),
            maxTimesToRecontract: yup.number().optional()
        })
        .omit(['currentTimeOfContracting']);

    return { automaticContractingLooseBalanceSchema };
};
