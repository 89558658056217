import { useMemo } from 'react';
import { Heading, Text, Box } from '@components';
import { useNumberFunctions, useTranslator, useReplace } from '@hooks';
import { PaymentMethod, BillingPeriodEnum, ToolTypeEnum } from '@models';
import { GetTranslatedEnumName } from '@utils';
import { ToolDiscountData } from '~/services/subscriptionService';
import { InvoiceDetailsGroupStyled, InvoiceDetailsListStyled } from '../styles';

type DiscountDetailsProps = {
    discountPaymentMethodsTotal: number;
    paymentMethods: PaymentMethod;
    currency?: string;
    discountBillingPeriodTotal: number;
    discountCouponTotal: number;
    toolDiscounts: Array<ToolDiscountData>;
    discountTotal: number;
    periodicityInMonths?: BillingPeriodEnum | number;
};

export const DiscountDetails = ({
    discountPaymentMethodsTotal,
    paymentMethods,
    discountBillingPeriodTotal,
    currency,
    discountCouponTotal,
    toolDiscounts,
    discountTotal,
    periodicityInMonths
}: DiscountDetailsProps) => {
    const {
        enums: { toolType },
        checkoutPage: {
            invoiceDetails: { subgroups }
        }
    } = useTranslator();
    const { formatCurrency } = useNumberFunctions();
    const replace = useReplace();
    const hasDiscount = discountTotal > 0;

    const descriptionOfDiscountByPaymentPeriod = useMemo(() => {
        if (!hasDiscount) {
            return;
        }

        const { monthlyPayment, quarterlyPayment, semesterPayment, annualPayment } = subgroups.discount;

        switch (periodicityInMonths) {
            case BillingPeriodEnum.Month:
                return monthlyPayment;
            case BillingPeriodEnum.Quarter:
                return quarterlyPayment;
            case BillingPeriodEnum.Semester:
                return semesterPayment;
            case BillingPeriodEnum.Year:
                return annualPayment;
            default:
                return monthlyPayment;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodicityInMonths, hasDiscount]);

    if (!hasDiscount) {
        return <></>;
    }

    return (
        <InvoiceDetailsGroupStyled>
            <Box>
                <Heading as='h6'>{subgroups.discount.title}</Heading>
                <InvoiceDetailsListStyled isDiscount>
                    {discountCouponTotal > 0 && (
                        <li>
                            <ul>
                                <Text as='span' size='xs'>{`${subgroups.discount.coupon}`}</Text>
                                {toolDiscounts
                                    .filter((discount) => discount?.discountAmount > 0)
                                    .map((discount) => {
                                        return (
                                            <li key={discount.toolId}>
                                                <Text as='span' size='xs'>
                                                    •{' '}
                                                    {replace(
                                                        String(subgroups.discount.descriptionPlan),
                                                        GetTranslatedEnumName(ToolTypeEnum[discount.toolId], toolType)
                                                    )}
                                                </Text>
                                                <Text as='span' size='xs'>
                                                    - {formatCurrency(discount.discountAmount, currency)}
                                                </Text>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </li>
                    )}
                    {discountPaymentMethodsTotal > 0 && (
                        <li>
                            <Text as='span' size='xs'>
                                {paymentMethods?.name}
                            </Text>
                            <Text as='span' size='xs'>
                                - {formatCurrency(discountPaymentMethodsTotal, currency)}
                            </Text>
                        </li>
                    )}
                    {discountBillingPeriodTotal > 0 && (
                        <li>
                            <Text as='span' size='xs'>
                                {String(descriptionOfDiscountByPaymentPeriod)}
                            </Text>
                            <Text as='span' size='xs'>
                                - {formatCurrency(discountBillingPeriodTotal, currency)}
                            </Text>
                        </li>
                    )}
                    <li>
                        <Text as='strong' size='md'>
                            {subgroups.discount.discountTotal}
                        </Text>
                        <Text as='strong' size='md'>
                            {formatCurrency(discountTotal, currency)}
                        </Text>
                    </li>
                </InvoiceDetailsListStyled>
            </Box>
        </InvoiceDetailsGroupStyled>
    );
};
