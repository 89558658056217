import { InputHTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { CheckboxStyledProps, CheckboxStyled, CheckboxInputStyled, CheckboxLabelStyled } from './styles';

type CheckboxProps = {
    name: string;
    children?: ReactNode;
    enableUnform?: boolean;
    testId?: string;
} & CheckboxStyledProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'name' | keyof CheckboxStyledProps>;

export const Checkbox = ({ name, children, enableUnform, testId, ...props }: CheckboxProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        if (enableUnform) {
            registerField<boolean>({
                name: fieldName,
                ref: inputRef.current,
                path: 'checked'
            });
        }
    }, [enableUnform, fieldName, registerField]);

    return (
        <CheckboxStyled data-validate={error} className={`${error ? 'input-error' : ''}`}>
            <CheckboxInputStyled
                id={fieldName}
                ref={inputRef}
                defaultChecked={defaultValue}
                {...props}
                data-testid={testId}
            />

            {children && (
                <CheckboxLabelStyled htmlFor={fieldName} size='md' fontWeight='regular' color={props.color}>
                    {children}
                </CheckboxLabelStyled>
            )}
        </CheckboxStyled>
    );
};
