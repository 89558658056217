import { useMemo, useState } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import _isNil from 'lodash/isNil';
import { Shimmer } from '@components';
import { useFetch, useSubToolPage, useTranslator } from '@hooks';
import { SendingTechnologyTrackStatistics, SendingTechnologyTypeEnum, SubToolIdEnum } from '@models';
import { useAutomationStatisticsProvider } from '@providers';
import { captureException } from '@sentry/nextjs';
import { DateFilterOptionsWithCustomTimePeriod } from '@utils';
import { Dialog, DialogButtonClose, DialogClose, DialogHeading, DialogTrigger } from '~/components/elements/dialog';
import {
    GetCustomTriggerDetailedGeneralStatisticsAsync,
    getCustomTriggerDetailedGeneralStatisticsUrl,
    GetDetailedGeneralStatisticsAsync,
    getDetailedGeneralStatisticsUrl,
    GetRepurchasePercentageAsync,
    getRepurchasePercentageUrl
} from '~/services/statisticsApiService';
import {
    ConvertTrackStatisticsToArray,
    GetDetailedGeneralStatisticsBySendingTechnology
} from '~/services/statisticsService';
import { StatisticsPerSubToolHeader } from '../statisticsPerSubTool/header';
import { Header } from './header';
import { SendingTechnologyContent } from './sendingTechnologyContent';
import { Content, DialogContentStyled, SeeMoreButtonStyled, SendingTechnologyContentStyled } from './styles';

type AutomationStatisticsPerSendingTechnologyModalProps = {
    subToolId?: SubToolIdEnum;
    customTriggerId?: number;
    title?: string;
};

const sendingTechnologyTrackStatisticsData: Array<SendingTechnologyTrackStatistics> = [
    {
        sendingTechnologyType: SendingTechnologyTypeEnum.Email,
        clickCount: 0,
        openingCount: 0,
        rejectionCount: 0,
        saleCount: 0,
        sentCount: 0,
        soldAmount: 0
    },
    {
        sendingTechnologyType: SendingTechnologyTypeEnum.SMS,
        clickCount: 0,
        openingCount: 0,
        rejectionCount: 0,
        saleCount: 0,
        sentCount: 0,
        soldAmount: 0
    }
];

/**  Time in minutes swr will wait for query key before querying again */
const MINUTES_TO_REQUERY_DATA = 1;

export const AutomationStatisticsPerSendingTechnologyModal = ({
    subToolId,
    customTriggerId,
    title
}: AutomationStatisticsPerSendingTechnologyModalProps) => {
    const { filterParams, sendingTechnologies } = useAutomationStatisticsProvider((state) => {
        return {
            filterParams: state.filterParams,
            sendingTechnologies: state.subscriptionSendingTechnologies
        };
    });

    const { periodType: currentPeriodType, startDate: currentStartDate, endDate: currentEndDate } = filterParams;

    const [openModal, setOpenModal] = useState(false);
    const [startDate, setStartDate] = useState<Date>(currentStartDate);
    const [endDate, setEndDate] = useState<Date>(currentEndDate);
    const [periodType, setPeriodType] = useState<DateFilterOptionsWithCustomTimePeriod>(
        currentPeriodType ?? 'thisMonth'
    );

    const {
        buttons: { viewMore, backToGeneralStatistics },
        pages: {
            automationStatistics: { sendingTechnologyStatisticsModal }
        },
        common: { sendingTechnology: sendingTecnologyTranslation }
    } = useTranslator();

    const defaultSendingTechnologyTrackStatisticsData = useMemo(
        () => {
            if (sendingTechnologies?.length > 0) {
                return sendingTechnologies.map((item) => ({
                    data: ConvertTrackStatisticsToArray(sendingTechnologyTrackStatisticsData[0]),
                    sendingTechnologyType: item,
                    sendingTechnologyName: sendingTecnologyTranslation[SendingTechnologyTypeEnum[item].toLowerCase()]
                }));
            }

            return sendingTechnologyTrackStatisticsData.map((item) => {
                return {
                    data: ConvertTrackStatisticsToArray(item),
                    sendingTechnologyType: item.sendingTechnologyType,
                    sendingTechnologyName:
                        sendingTecnologyTranslation[SendingTechnologyTypeEnum[item.sendingTechnologyType].toLowerCase()]
                };
            });
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sendingTechnologies]
    );

    const { getSubToolPageData } = useSubToolPage();
    const isRepurchase = subToolId === SubToolIdEnum.Repurchase;
    const { translatedSubToolName } = getSubToolPageData(subToolId);

    const { data: statistics, isLoading } = useFetch(
        openModal &&
            subToolId &&
            endDate && {
                url: getDetailedGeneralStatisticsUrl(subToolId),
                customTriggerUrl: getCustomTriggerDetailedGeneralStatisticsUrl(customTriggerId),
                startDate,
                endDate
            },
        async (props, signal) => {
            const params = { ...props };
            delete params.url;
            let data: Array<SendingTechnologyTrackStatistics>;
            if (_isNil(customTriggerId)) {
                data = (await GetDetailedGeneralStatisticsAsync({ subToolId, ...props }, signal)).data;
            } else {
                data = (await GetCustomTriggerDetailedGeneralStatisticsAsync({ customTriggerId, ...props }, signal))
                    .data;
            }

            const sendingTechnologiesFromDataAndSubscription = Array.from(
                new Set([...sendingTechnologies, ...data.map((item) => item.sendingTechnologyType)])
            );

            return GetDetailedGeneralStatisticsBySendingTechnology(
                data,
                sendingTechnologiesFromDataAndSubscription,
                sendingTecnologyTranslation
            );
        },
        {
            onError: (error) => captureException(error),
            keepPreviousData: true,
            fallbackData: defaultSendingTechnologyTrackStatisticsData,
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_REQUERY_DATA)
        }
    );

    const { data: repurchasePercentage, isLoading: isLoadingRepurchasePercentage } = useFetch(
        openModal && isRepurchase && { getRepurchasePercentageUrl, startDate, endDate },
        async ({ startDate, endDate }, signal) => {
            const response = await GetRepurchasePercentageAsync({ startDate, endDate }, signal);
            return response.data;
        },
        {
            keepPreviousData: true,
            fallbackData: 0,
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_REQUERY_DATA)
        }
    );

    const handleOpenChange = (open: boolean) => {
        setOpenModal(open);

        if (!open) {
            setPeriodType(currentPeriodType);
            setStartDate(currentStartDate);
            setEndDate(currentEndDate);
        }
    };

    const handleChangePeriodType = (
        periodType: DateFilterOptionsWithCustomTimePeriod,
        startDate?: Date,
        endDate?: Date
    ) => {
        setPeriodType(periodType);
        setStartDate(startDate);
        setEndDate(endDate);
    };

    return (
        <Dialog open={openModal} onOpenChange={handleOpenChange}>
            <DialogTrigger asChild onClick={() => setOpenModal(true)}>
                <SeeMoreButtonStyled color='deepCerise' styledAs='link'>
                    {String(viewMore)}
                </SeeMoreButtonStyled>
            </DialogTrigger>
            <DialogContentStyled>
                <DialogHeading>{String(sendingTechnologyStatisticsModal.title)}</DialogHeading>
                <div>
                    <Header
                        subToolId={subToolId}
                        disableDropdown={isLoading}
                        periodType={periodType}
                        startDate={startDate}
                        endDate={endDate}
                        title={title}
                        onChangePeriodType={handleChangePeriodType}
                    />

                    <Content>
                        <SendingTechnologyContentStyled>
                            <StatisticsPerSubToolHeader />
                        </SendingTechnologyContentStyled>

                        <Shimmer isActive={isLoading || isLoadingRepurchasePercentage}>
                            <SendingTechnologyContent
                                statistics={statistics}
                                subToolName={translatedSubToolName}
                                showRepurchaseRate={isRepurchase}
                                repurchasePercentage={repurchasePercentage}
                                data-shimmer
                            />
                        </Shimmer>
                    </Content>

                    <DialogButtonClose isOutlined color='purple' buttonSize='small'>
                        {String(backToGeneralStatistics)}
                    </DialogButtonClose>
                </div>

                <DialogClose />
            </DialogContentStyled>
        </Dialog>
    );
};
