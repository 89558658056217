/* istanbul ignore file */
import { CountryCode } from 'libphonenumber-js';
import { SubToolIdEnum } from '@models';

export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export const COMPANY_INFORMATION = {
    CORPORATE_NAME: 'Enviou Serviços de Marketing LTDA',
    CNPJ: '24.415.634/0001-73',
    ADDRESS: 'Av. Angélica, nº 2529 - Bela Vista São Paulo - SP',
    EMAIL: 'atendimento@enviou.com.br'
} as const;

export const SOCIAL_ADDRESS = {
    DOCS: 'https://enviou.docs.apiary.io',
    ZENDESK: 'https://faq.enviou.com.br/hc/1222904442',
    FACEBOOK: 'https://www.facebook.com/enviou.oficial',
    INSTAGRAM: 'https://www.instagram.com/goenviou',
    YOUTUBE: 'https://www.youtube.com/channel/UCbpfVdKznmfA_ieaY4NrG3Q'
} as const;

export const ACCEPT_TERMS_LINK = 'https://documentos.enviou.com.br/TERMO_DE_USO_E_ACEITE_DAS_FERRAMENTAS_ENVIOU.pdf';

export const DEFAULT_COUNTRY_TO_VALIDATE_CELL_NUMBER: CountryCode = 'BR';

export const VALUE_TEN = 10;
export const ONE_HUNDRED = 100;
export const CPF_LENGTH = 11;
export const BANK_SLIP_COMPENSATION_OFFSET = 3;
export const MAX_SMS_MESSAGE_SIZE = 120;
export const MAX_SUBJECT_SIZE = 100;
export const MAX_MANUAL_CONTACT_MESSAGE_SIZE = 2000;
export const CUSTOM_VARIABLES_GUIDE_LINK =
    'https://enviou.zendesk.com/hc/pt-br/articles/360026537451-O-que-s%C3%A3o-Vari%C3%A1veis-de-Personaliza%C3%A7%C3%A3o';

export const MIN_LENGTH_TELEPHONE = 10;
export const MAX_LENGTH_TELEPHONE = 14;

export const DEFAULT_LOCALE_REPURCHASE_TEMPLATE = 'en-US';
export const DEFAULT_LOCALE_CART_RECOVERY_TEMPLATE = 'pt-BR';
export const DEFAULT_LOCALE_BANK_SLIP_REMINDER_TEMPLATE = 'pt-BR';
export const DEFAULT_CURRENCY = 'BRL';

export const EDITOR_CONFIG = {
    width: '100%',
    height: '48rem',
    autoGrow_minHeight: '48rem',
    defaultLanguage: 'pt-BR',
    language: 'pt-BR',
    title: 'Enviou',
    contentsCss: '',
    versionCheck: false,
    extraPlugins: [
        'font',
        'justify',
        'colorbutton',
        'colordialog',
        'indent',
        'indentblock',
        'preview',
        'removeformat',
        'copyformatting',
        'div',
        'emoji',
        'dialogadvtab',
        'image',
        'print',
        'find'
    ],
    removeButtons: '',
    removeDialogTabs: '',
    startupShowBorders: true,
    linkShowAdvancedTab: true,
    allowedContent: true,
    colorButton_enableMore: true,
    skin: 'moono-lisa',
    toolbar: [
        {
            name: 'document',
            groups: ['mode', 'document', 'doctools'],
            items: ['Source', '-', 'Preview', 'Print', '-', 'Templates']
        },
        {
            name: 'clipboard',
            groups: ['undo', 'clipboard'],
            items: ['Undo', 'Redo', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord']
        },
        {
            name: 'editing',
            groups: ['find', 'selection', 'spellchecker'],
            items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt']
        },
        '/',
        {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
        },
        {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
            items: [
                'NumberedList',
                'BulletedList',
                '-',
                'Outdent',
                'Indent',
                '-',
                'Blockquote',
                'CreateDiv',
                '-',
                'JustifyLeft',
                'JustifyCenter',
                'JustifyRight',
                'JustifyBlock',
                '-',
                'BidiLtr',
                'BidiRtl',
                'Language'
            ]
        },
        { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        {
            name: 'insert',
            items: ['EmojiPanel', 'Image', 'Flash', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak', 'Iframe']
        },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
        { name: 'others', items: ['-'] },
        { name: 'about', items: ['About'] }
    ],
    toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools', 'docprops'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
        { name: 'links' },
        { name: 'emoji' },
        { name: 'insert' },
        '/',
        { name: 'styles' },
        { name: 'colors' },
        { name: 'tools' },
        { name: 'others' },
        { name: 'about' }
    ]
};

export const NO_CATEGORY = 'NOCATEGORY';
export const WHATSAPP_API_URL = 'https://api.whatsapp.com/';

export const MAX_AMOUNT_OF_CONDITIONS_IN_NAVIGATION_TRIGGERS = 5;

export const SUBTOOL_PAGE_PATH = {
    [SubToolIdEnum.Repurchase]: 'repurchases',
    [SubToolIdEnum.NavigationTrigger]: 'navigationTriggers',
    [SubToolIdEnum.CartRecovery]: 'cartRecovery',
    [SubToolIdEnum.BankSlipReminder]: 'bankSlipReminder',
    [SubToolIdEnum.CustomTrigger]: 'customTrigger'
};

export const LOCAL_STORAGE_TEMP_PRODUCTS_KEY = 'enviou.store.temp-products';

export const PAINEL2_URL = process.env.NEXT_PUBLIC_PAINEL2_URL;
const PAINEL_INTERMEDIARIO_URL = process.env.NEXT_PUBLIC_PAINEL_INTERMEDIARIO_URL;

export const PAINELN2_CART_RECOVERY_STATISTICS_LINK = new URL('/ca/estatistica', PAINEL2_URL).href;

export const PAINELN2_CAMPAIGN_STATISTICS_LINK = new URL(
    '/emailmarketing/campanha/estatisticas/',
    PAINEL_INTERMEDIARIO_URL
).href;
export const PAINELN2_CAMPAIGN_LINK = new URL('/emailmarketing/campanha', PAINEL_INTERMEDIARIO_URL).href;
export const PAINELN2_CREATE_CAMPAIGN_LINK = new URL('/campanha/conteudo', PAINEL_INTERMEDIARIO_URL).href;

export const PAINELN2_LOG_OUT = new URL('/sair', PAINEL_INTERMEDIARIO_URL).href;
export const PAINELN2_STATUS = new URL('/status', PAINEL_INTERMEDIARIO_URL).href;

export const LAST_DATE_QUERY_SUBSCRIPTION_STATUS_DATE_COOKIENAME = 'lastDateQuerySubscriptionStatusDate';

export const SCHEDULE_TRAINING_URL =
    'https://meetings.hubspot.com/enviou/migracao?uuid=5fbe5b16-b539-403e-b4e0-fdcc3594ed5f';

export const COMERCIAL_WHATSAPP_CHAT =
    'https://api.whatsapp.com/send?phone=+5511968456152&text=Ol%C3%A1%2c+gostaria+de+informa%C3%A7%C3%B5es+comerciais';
