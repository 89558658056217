import _lowerFirst from 'lodash/lowerFirst';
import _orderBy from 'lodash/orderBy';
import { Card, CardHeader, CardLink, GetIconSubTool, Heading, Text } from '@components';
import { AutomationToolModal } from '@containers';
import { useTranslator } from '@hooks';
import { useAlert } from '@providers';
import { SubTool, SubToolIdEnum, subToolOrder } from '~/models';
import { SubToolColors } from '~/styles/theme';
import { CardBodyStyled } from '../styles';
import { FirstStepStyled, SubToolIconStyled, SubToolInfoStyled, SubToolItemStyled } from './styles';

type FirstStepProps = {
    title: string;
    subTools: Array<SubTool>;
};

export const FirstStep = ({ title, subTools }: FirstStepProps) => {
    const { steps } = useTranslator().checkoutPage.subscriptionPlan.automationPlans;
    const { show } = useAlert();

    subTools = _orderBy(
        subTools.map((item) => {
            return {
                ...item,
                order: subToolOrder[item.id]
            };
        }),
        'order'
    );

    return (
        <Card>
            <CardHeader bulletNumber={{ show: true, value: '01' }} subtitle={title} />
            <CardBodyStyled>
                <FirstStepStyled>
                    {subTools?.map((subTool) => (
                        <SubToolItemStyled key={subTool.id}>
                            <SubToolIconStyled subToolColor={_lowerFirst(SubToolIdEnum[subTool.id]) as SubToolColors}>
                                {GetIconSubTool(subTool.id, 'subTools')}
                            </SubToolIconStyled>
                            <SubToolInfoStyled>
                                <Heading as='h6' size='md'>
                                    {subTool.name}
                                </Heading>
                                <Text size='md'>{subTool.description}</Text>
                                <CardLink
                                    isUnderline
                                    text={String(steps.first.knowMore)}
                                    onClick={() =>
                                        show(null, null, {
                                            content: (args) => <AutomationToolModal {...args} subToolId={subTool.id} />
                                        })
                                    }
                                />
                            </SubToolInfoStyled>
                        </SubToolItemStyled>
                    ))}
                </FirstStepStyled>
            </CardBodyStyled>
        </Card>
    );
};

export default FirstStep;
