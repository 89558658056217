import _isObject from 'lodash/isObject';
import useSWR, { Key, SWRConfiguration, SWRResponse, unstable_serialize as unstableSerialize } from 'swr';
import { useAuth } from '@providers';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';

type useFetchProps<Data, Error> = SWRResponse<Data, Error>;

type FetcherResponse<Data = unknown> = Data | Promise<Data>;

type Fetcher<Data = unknown, SWRKey extends Key = Key> = SWRKey extends () => infer Arg | null | undefined | false
    ? (arg: Arg, signal?: AbortSignal) => FetcherResponse<Data>
    : SWRKey extends null | undefined | false
    ? never
    : SWRKey extends infer Arg
    ? (arg: Arg, signal?: AbortSignal) => FetcherResponse<Data>
    : never;

export const useFetch = <Data = unknown, Keys extends Key = Key, Error = EnviouApiResponseError>(
    key: Keys,
    fetcher?: Fetcher<Data, Keys> | null,
    configuration?: SWRConfiguration<Data, Error>
): useFetchProps<Data, Error> => {
    const { user } = useAuth();

    if (user?.id && key) {
        if (Array.isArray(key)) {
            (key as unknown[]).push(user.id);
        } else if (key && _isObject(key)) {
            (key as { storeId: number }).storeId = user.id;
        }
    } else if (!key) {
        (key as boolean) = false;
    }

    const { data, error, isValidating, isLoading, mutate, abortRequest } = useSWR<Data, Error>(
        key && key,
        fetcher,
        configuration
    );

    return { data, error, isLoading, isValidating, mutate, abortRequest };
};

// INFO: iury.sousa - Keep track of swr updates to update this function if necessary
export const serializeSWRKey = <T extends Key = unknown>(key: T) => unstableSerialize(key);
