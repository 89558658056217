import { forwardRef, HTMLAttributes } from 'react';
import { FloatingFocusManager, FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react';
import { useDialogContext } from '~/providers';
import { DialogContentStyled, FloatingOverlayStyled } from './styles';

const defaultTransition = {
    open: 400,
    close: 200
};

export const DialogContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(function DialogContent(
    props,
    propRef
) {
    const { context: floatingContext, isBlurredBackground, returnFocusOnClose, ...context } = useDialogContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const { isMounted, styles } = useTransitionStyles(floatingContext, {
        duration: {
            close: defaultTransition.close,
            open: defaultTransition.open
        },
        initial: {
            transform: 'translateY(-100px)',
            opacity: 0
        }
    });

    return (
        <>
            {isMounted && (
                <FloatingPortal>
                    <FloatingOverlayStyled lockScroll isBlurredBackground={isBlurredBackground}>
                        <FloatingFocusManager context={floatingContext} returnFocus={returnFocusOnClose}>
                            <DialogContentStyled
                                ref={ref}
                                aria-labelledby={context.labelId}
                                aria-describedby={context.descriptionId}
                                style={{
                                    ...styles,
                                    ...props.style
                                }}
                                {...context.getFloatingProps(props)}>
                                {props.children}
                            </DialogContentStyled>
                        </FloatingFocusManager>
                    </FloatingOverlayStyled>
                </FloatingPortal>
            )}
        </>
    );
});
