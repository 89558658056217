import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '~/providers';

export const useRedirectToPainel2 = (painelUrl = process.env.NEXT_PUBLIC_PAINEL_INTERMEDIARIO_URL) => {
    const { token } = useAuth();
    const { push } = useRouter();

    const redirect = useCallback(
        (path: string, executePush = true) => {
            const baseUrl = painelUrl;
            const fullUrl = new URL('login', baseUrl);
            fullUrl.searchParams.append('ReturnUrl', path);
            fullUrl.searchParams.append('painel3Token', token);

            const href = fullUrl.href;
            if (executePush) {
                push(href);
                return;
            }

            return href;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [painelUrl, token]
    );

    return { redirect };
};
