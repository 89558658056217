import { CardLink } from '@components';
import { AutomationToolModal, PlanEmailMarketingModal } from '@containers';
import { useSubToolPage } from '@hooks';
import { SubTool, SubToolIdEnum } from '@models';
import { useAlert } from '@providers';
import { SubscriptionToolsStyled } from './styles';

type SubscriptionToolsProps = {
    subTools?: Array<SubTool>;
};
export const SubscriptionTools = ({ subTools }: SubscriptionToolsProps) => {
    const { show } = useAlert();
    const { getSubToolPageData } = useSubToolPage();

    const showSubToolDetailsModal = (subToolId: SubToolIdEnum) => {
        if (subToolId !== SubToolIdEnum.Emails) {
            return show(null, null, {
                content: (args) => <AutomationToolModal {...args} subToolId={subToolId} />
            });
        }

        show(null, null, {
            content: (args) => <PlanEmailMarketingModal {...args} />
        });
    };

    return (
        <SubscriptionToolsStyled>
            <div>
                {subTools?.map((tool) => (
                    <CardLink
                        key={tool.id}
                        text={getSubToolPageData(tool.id)?.translatedSubToolName ?? tool.name}
                        isUnderline
                        size='md'
                        onClick={() => showSubToolDetailsModal(tool.id)}
                        testId={`card-link__subtool-${tool.name}`}
                    />
                ))}
            </div>
        </SubscriptionToolsStyled>
    );
};
