import { ChangeEventHandler, TextareaHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { TextareaFormGroupStyle } from './styles';

type TextareaFormGroupProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    name: string;
};

export const TextareaFormGroup = ({ name, onChange, ...props }: TextareaFormGroupProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField<string>({
            name: fieldName,
            ref: textareaRef.current,
            getValue(ref) {
                return ref.value;
            },
            setValue(ref, value) {
                ref.value = value;
            },
            clearValue(ref) {
                ref.value = '';
            }
        });
    }, [fieldName, registerField]);

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <TextareaFormGroupStyle data-validate={error} className={`${error ? 'input-error' : ''}`}>
            <textarea
                ref={textareaRef}
                onChange={handleChange}
                defaultValue={defaultValue}
                name={name}
                id={name}
                {...props}></textarea>
        </TextareaFormGroupStyle>
    );
};
