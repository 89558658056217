import { FormEvent, MutableRefObject, useCallback } from 'react';
import * as yup from 'yup';
import { useTranslator } from '@hooks';
import { FormHandles } from '@unform/core';
import { isFileOverSize } from '@utils';

export const ACCEPT_IMAGES = ['.png', '.jpeg', '.jpg', '.gif'];
export const ICON_INPUT_NAME = 'icon';
const ALLOWED_IMAGE_TYPES = ACCEPT_IMAGES.map((imageType) => `image/${imageType.substring(1)}`);
const MAX_FILE_SIZE_IN_BYTES = 102_400; // 100KB

export type PushConfigurationFormData = {
    icon: string;
};

export const useValidation = (formRef: MutableRefObject<FormHandles>, hasInitialIcon = false) => {
    const {
        pages: {
            sendingTechnologySetting: {
                pushConfiguration: {
                    content: { form }
                }
            }
        }
    } = useTranslator();

    const validateForm = useCallback(
        async (data: PushConfigurationFormData, event: FormEvent<Element>) => {
            try {
                formRef.current.setErrors({});

                const formSchema = yup.object().shape({
                    [ICON_INPUT_NAME]: !hasInitialIcon
                        ? yup.string().required(String(form.icon.required))
                        : yup.string().nullable()
                });

                await formSchema.validate(data, { abortEarly: false });

                const formData = new FormData(event.target as HTMLFormElement);
                const file = formData.get(ICON_INPUT_NAME) as File;

                // If the customer has not inserted a new image, then they do not need to validate
                if (!(file?.size > 0) && hasInitialIcon) {
                    return true;
                }

                if (!ALLOWED_IMAGE_TYPES.includes(file.type.toLowerCase())) {
                    formRef.current.setFieldError(ICON_INPUT_NAME, String(form.icon.invalidImageFormat));
                    return false;
                }

                if (isFileOverSize(file, MAX_FILE_SIZE_IN_BYTES)) {
                    formRef.current.setFieldError(ICON_INPUT_NAME, String(form.icon.maxFileSize));
                    return false;
                }
            } catch (exception) {
                if (exception instanceof yup.ValidationError) {
                    const errorMessages = {};

                    exception.inner.forEach((error) => (errorMessages[error.path] = error.message));
                    formRef.current.setErrors(errorMessages);
                } else {
                    throw exception;
                }

                return false;
            }

            return true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formRef]
    );

    return validateForm;
};
