import styled from 'styled-components';

export const BarChartHeaderStyled = styled.div`
    margin-bottom: 0.8rem;
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: 25rem 1fr 1fr;

    strong {
        display: flex;
        flex-direction: column;
        justify-content: end;
        margin-bottom: 0.2rem;
    }

    span {
        text-align: end;
    }
`;
