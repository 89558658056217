import styled, { css } from 'styled-components';
import check from '~/assets/svg/controls/check.svg?url';
import { dataValidateStyle } from '~/styles/mixins';
import { Label } from '../label';

export type CheckboxStyledProps = {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
};

export const CheckboxStyled = styled.div`
    ${({ theme, color = theme.default.color }) => css`
        width: 100%;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        color: ${color};

        > span {
            color: ${theme.colors.pink};
        }

        ${dataValidateStyle}
    `}
`;

export const CheckboxInputStyled = styled.input.attrs<CheckboxStyledProps>({ type: 'checkbox' })<CheckboxStyledProps>`
    ${({
        theme,
        color = theme.default.input.color,
        backgroundColor = theme.default.input.backgroundColor,
        borderColor = theme.default.input.borderColor
    }) => css`
        color: ${color};
        background-color: ${backgroundColor};
        appearance: none;
        border-radius: 0.4rem;

        position: relative;
        cursor: pointer;
        width: 1.25em;
        height: 1.25em;
        display: inline-flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;

        &::before {
            content: '';
            position: absolute;
            width: 1.25em;
            height: 1.25em;
            box-sizing: border-box;
            background-color: ${backgroundColor};
            border: 0.1rem solid ${borderColor};
            border-radius: 0.4rem;
        }

        &:checked {
            &::after {
                content: url(${check});
                position: absolute;
                width: 0.85em;
            }
        }

        &:disabled {
            cursor: default;
        }
    `}
`;

export const CheckboxLabelStyled = styled(Label)`
    cursor: pointer;
    margin-left: 0.8em;
    color: ${(props) => props.color};
`;
