import { motion } from 'framer-motion';
import styled from 'styled-components';

type ItemResumeStyledProps = {
    negativeAmount?: boolean;
};

export const ListResumeStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 1.8rem;
`;

const BaseResumeStyled = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ItemResumeStyled = styled(BaseResumeStyled).attrs({
    initial: { opacity: 0, y: -8 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 0 }
})<ItemResumeStyledProps>`
    & > p {
        flex: 1;

        &:last-child {
            color: ${({ negativeAmount, theme }) => (negativeAmount ? theme.colors.deepCerise : 'inherit')};
            flex: initial;
        }
    }
`;

export const TotalResumeStyled = styled(BaseResumeStyled)`
    & > strong {
        &:last-child {
            text-align: right;
        }

        & > span {
            display: block;
        }
    }
`;
