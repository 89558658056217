import { memo } from 'react';
import { Text } from '@components';
import { useAutomationStatisticsProvider } from '@providers';
import { InfoTooltip } from '~/components/elements/tooltip/infoTooltip';
import { SubToolListHeaderStyled, SubToolListItemContentStyled, TooltipContentStyled } from './styles';

const StatisticsPerSubToolHeaderComponent = () => {
    const { data } = useAutomationStatisticsProvider((state) => state.generalStatistics);

    return (
        <SubToolListHeaderStyled>
            <SubToolListItemContentStyled>
                {data.toolStatistics.map((statistic) => {
                    return (
                        <Text as='strong' size='lg' key={statistic.type}>
                            {statistic.title}
                            {!!statistic.description && (
                                <InfoTooltip>
                                    <TooltipContentStyled>{statistic.description}</TooltipContentStyled>
                                </InfoTooltip>
                            )}
                        </Text>
                    );
                })}
            </SubToolListItemContentStyled>
        </SubToolListHeaderStyled>
    );
};

export const StatisticsPerSubToolHeader = memo(StatisticsPerSubToolHeaderComponent);
