import styled, { css } from 'styled-components';
import { dataValidateStyle, mobileAndTabletOnly } from '~/styles';

export const TextareaFormGroupStyle = styled.div`
    ${dataValidateStyle}

    textarea {
        padding: 0.8rem 1.6rem;
        border-radius: 0.4rem;
        resize: none;
        border: 1px solid ${({ theme }) => theme.colors.lightPurple};
        background: ${({ theme }) => theme.default.input.backgroundColor};
        color: ${({ theme }) => theme.default.input.color};
        font-size: 1.6rem;
        width: 100%;
        height: 28rem;
        line-height: 2.1rem;

        ${mobileAndTabletOnly(css`
            height: 24rem;
        `)}
    }
`;
