import styled from 'styled-components';
import { ModalFormStyled } from '~/components/containers/checkout/styles';
import { DialogContent } from '~/components/elements/dialog';
export const BillingDataUnformStyled = styled(ModalFormStyled)``;

export const DialogContentStyled = styled(DialogContent)`
    margin-top: 10vh;
    max-width: 96.8rem;
    padding: 4rem;

    h2 {
        text-align: start;
        text-decoration: uppercase;
        margin-bottom: 3.2rem;
    }

    & > footer {
        margin-top: 3.2rem;
        justify-content: space-between;

        button {
            width: 12rem;
        }
    }
`;
