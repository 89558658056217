import styled from 'styled-components';
import { ColoredButtonStyled } from '~/components/elements/button/styles';
import { InputFormGroup } from '~/components/forms';

export const ContainerInputGroupStyled = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
`;

export const ItemGroupInputStyled = styled(InputFormGroup)<{ filename?: string }>`
    &[type='file'] {
        color: transparent;
        &::-webkit-file-upload-button {
            visibility: hidden;
        }

        &::before {
            content: '${({ filename, placeholder }) => filename ?? placeholder}';
            cursor: pointer;
            display: inline-block;
            color: ${({ theme }) => theme.colors.philippineSilver};
            white-space: nowrap;
            outline: none;
            max-width: calc(100% - 1.6rem);
            font-family: ${({ theme }) => theme.fonts.primary};
            font-size: ${({ theme }) => theme.fontSizes.sm};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const ItemGroupButtonStyled = styled(ColoredButtonStyled).attrs(({ theme }) => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.purple,
    buttonSize: 'small'
}))`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

export const ItemGroupInputWrapper = styled.div`
    width: 100%;

    & > div {
        padding: 0;
        height: 100%;

        div {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &:has(input[type='file']) + label {
        max-width: 19.5rem;
        width: 100%;
    }
`;
