import {
    ContactConfigurationStatusEnum,
    MessageSequence,
    MessageSequenceStep,
    SendingTechnologyTypeEnum,
    SubToolConfiguration,
    SubToolConfigurationStatusEnum
} from '@models';
import { axiosClient } from './apiService';
import { TestEmailRequestPayload, TestEmailResponsePayload } from './subToolApiService';

export type UpdateCartRecoveryConfigurationContactsPayload = {
    id?: number;
    subToolConfigurationId: number;
    senderName: string;
    senderContact: string;
    contactAnswer?: string;
    status: ContactConfigurationStatusEnum;
    sendingTechnology: SendingTechnologyTypeEnum;
};

export type UpdateCartRecoveryConfigurationPayload = {
    id?: number;
    subToolId: number;
    status: SubToolConfigurationStatusEnum;
    contactConfigurations: Array<UpdateCartRecoveryConfigurationContactsPayload>;
};

export const getMessageSequencesUrl = '/sub-tool/cart-recovery/message-sequences';
export const GetMessageSequencesAsync = (signal: AbortSignal = null) => {
    return axiosClient.get<MessageSequence>(getMessageSequencesUrl, { signal });
};

export const DeleteMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.delete<boolean>(`/sub-tool/cart-recovery/message-sequences/steps/${stepId}`);
};

export const DuplicateMessageSequenceStepAsync = async (stepId: number) => {
    return axiosClient.post<MessageSequenceStep>(`/sub-tool/cart-recovery/message-sequences/steps/${stepId}/duplicate`);
};

export const ChangeStatusAsync = async (newStatus: SubToolConfigurationStatusEnum, cartRecoveryId: number) => {
    return axiosClient.patch<boolean>(
        '/sub-tool/cart-recovery/configuration/status',
        {
            newStatus
        },
        {
            params: {
                cartRecoveryId
            }
        }
    );
};

export const GetSubToolConfigurationAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<SubToolConfiguration>('/sub-tool/cart-recovery/configuration', { signal });
};

export const CreateOrUpdateSubToolConfigurationAsync = async (payload: UpdateCartRecoveryConfigurationPayload) => {
    return axiosClient.put<SubToolConfiguration>('/sub-tool/cart-recovery/configuration', payload);
};

export const SendTestEmail = async (payload: TestEmailRequestPayload) => {
    return axiosClient.post<TestEmailResponsePayload>('/sub-tool/cart-recovery/send-test-email', payload);
};
