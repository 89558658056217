import { HTMLAttributes } from 'react';
import { Scope } from '@unform/core';
import { FormGroupStyled, FormGroupStyledProps } from './styles';

type FormGroupProps = HTMLAttributes<HTMLDivElement> &
    FormGroupStyledProps & {
        enableUnform?: boolean;
        unformScopePath?: string;
    };

export const FormGroup = ({
    children,
    flexGrid,
    enableUnform = false,
    unformScopePath = null,
    ...args
}: FormGroupProps) => {
    return (
        <FormGroupStyled flexGrid={flexGrid} {...args}>
            {enableUnform && unformScopePath ? <Scope path={unformScopePath}>{children}</Scope> : children}
        </FormGroupStyled>
    );
};
