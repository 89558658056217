import { Children, ReactNode, cloneElement, useMemo, ReactElement } from 'react';
import { recursiveMapChildren } from '@utils';

type ShimmerProps = {
    children: ReactNode;
    isActive?: boolean;
};

export const Shimmer = ({ children, isActive = false }: ShimmerProps) => {
    const content = useMemo(() => {
        let childrenArray: Array<ReactNode> = [];

        if (Children.count(children) > 0 && isActive) {
            childrenArray = recursiveMapChildren(children as ReactElement[], (child) => {
                if (child.props['data-shimmer']) {
                    const { className = '' } = child as unknown as HTMLElement;
                    return cloneElement(child, {
                        ...child.props,
                        className: `${className} shimmer-effect`
                    });
                }

                return child;
            });
        } else {
            childrenArray = children as Array<ReactNode>;
        }

        return childrenArray;
    }, [children, isActive]);

    return <>{content}</>;
};
