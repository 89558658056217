import styled from 'styled-components';
import { DialogContent, DialogFooter } from '~/components/elements/dialog';

export const ThemePreviewModalBodyStyled = styled(DialogContent)`
    margin-top: 1rem;
    max-width: 76.8rem;
    width: 100%;
    height: 94vh;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 2.4rem 0;

    & > h2 {
        text-transform: uppercase;
        text-align: start;
        padding-left: 2.4rem;
    }

    & > div {
        min-height: 0;
        div {
            margin-bottom: 0;

            & > button {
                padding-left: 2.4rem;
            }
        }
    }

    .loading {
        margin: auto;
    }
`;

export const ThemePreviewModalFooterStyled = styled(DialogFooter)`
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: end;
    gap: 2.4rem;
    padding: 0 2.4rem;

    button {
        width: 14.8rem;
    }
`;
