import { useEffect, useRef } from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';
import { useRouter } from 'next/router';
import { useField } from '@unform/core';
import { googleRecaptchaLocale } from '~/locales/route';
import { RecaptchaStyled } from './styles';

type RecaptchaProps = {
    onChange?: (token: string | null) => void;
    name?: string;
};

export const Recaptcha = ({ onChange, name = 'recaptcha' }: RecaptchaProps) => {
    const recaptchaRef = useRef<ReCAPTCHA>();
    const { fieldName, registerField, error } = useField(name);
    const { locale } = useRouter();

    useEffect(() => {
        registerField<string>({
            name: fieldName,
            ref: recaptchaRef.current,
            getValue(ref) {
                return ref?.getValue();
            },

            clearValue(ref) {
                ref.reset();
            }
        });
    }, [fieldName, registerField]);

    return (
        <RecaptchaStyled
            ref={recaptchaRef}
            data-validate={error}
            onChange={onChange}
            sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
            hl={googleRecaptchaLocale[locale]}
        />
    );
};
