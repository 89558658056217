import styled from 'styled-components';
import { HeaderContentStyled as HeaderContent } from '~/components/elements/header/styles';
import { DefaultLayoutContentStyled } from '../styles';

export const LayoutStyled = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LayoutContentStyled = styled(DefaultLayoutContentStyled)``;

export const HeaderContentStyled = styled(HeaderContent)`
    justify-content: center;
    & > a:first-child {
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: flex-start;
        color: ${({ theme }) => theme.colors.deepCerise};
        position: absolute;
        left: 24px;

        &:hover {
            text-decoration: underline;
        }

        i {
            width: 3.2rem;
            height: 3.2rem;
            padding: 0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(180deg);

            svg {
                width: 1rem;
                height: 1.7rem;
            }
        }
    }
`;
