import { useRef, useState, useMemo } from 'react';
import type { Placement } from '@floating-ui/react';
import {
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    safePolygon
} from '@floating-ui/react';

export type TooltipOptions = {
    initialOpen?: boolean;
    placement?: Placement;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
};

const DEFAULT_OFFSET = 8;

export const useTooltip = ({
    initialOpen = false,
    placement = 'top',
    open: controlledOpen,
    onOpenChange: setControlledOpen
}: TooltipOptions = {}) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const arrowRef = useRef(null);
    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(DEFAULT_OFFSET),
            flip({
                fallbackAxisSideDirection: 'start'
            }),
            shift({ padding: 5 }),
            arrow({ element: arrowRef })
        ]
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        enabled: controlledOpen == null,
        handleClose: safePolygon()
    });
    const focus = useFocus(context, {
        enabled: controlledOpen == null
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return useMemo(
        () => ({
            open,
            setOpen,
            arrowRef,
            ...interactions,
            ...data
        }),
        [open, setOpen, interactions, data, arrowRef]
    );
};
