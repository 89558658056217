import styled, { css } from 'styled-components';
import { mobileOnly, cssMaxWidthWithPaddingX } from '~/styles';

export const FooterStyled = styled.footer`
    width: 100%;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.default.footer.backgroundColor};
    color: ${({ theme }) => theme.default.footer.color};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FooterContentStyled = styled.div`
    width: 100%;
    ${cssMaxWidthWithPaddingX}
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const LegalInformationContainerStyled = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.default.footer.secondBackgroundColor};
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const LegalInformationContentStyled = styled.div`
    padding: ${({ theme }) => theme.space.xs};
    ${cssMaxWidthWithPaddingX}
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${mobileOnly(css`
        flex-direction: column;
        justify-content: center;
        text-align: center;
    `)}
`;

export const LegalInformationColumnStyled = styled.div`
    display: flex;
    flex-direction: column;

    & > p {
        width: 100%;
    }
`;

export const FooterCardFlagsContainerStyled = styled.div`
    margin: ${({ theme }) => theme.space.xs} 0;
    height: 2rem;
`;
