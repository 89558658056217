import { useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import { User } from '@models';
import {
    generateTemplatePreviewBySendingTechnology,
    GenerateTemplatePreviewProps,
    generateEmailSubjectPreview
} from './preview';
import {
    getVariable,
    getVariableValue,
    LocalVariables,
    TemplateVariable,
    translateVariable,
    VariableParentKey
} from './utils';

type TemplateIsEqual = Pick<
    GenerateTemplatePreviewProps,
    'baseHtml' | 'productHtml' | 'reviewHtml' | 'reserveProductHtml' | 'displayReserveProduct' | 'recommendationHTML'
>;

const templateIsEqual = (prevTemplate: TemplateIsEqual, nextTemplate: TemplateIsEqual) => {
    return (
        prevTemplate?.baseHtml === nextTemplate?.baseHtml &&
        prevTemplate?.productHtml === nextTemplate?.productHtml &&
        prevTemplate?.reserveProductHtml === nextTemplate?.reserveProductHtml &&
        prevTemplate?.displayReserveProduct === nextTemplate?.displayReserveProduct &&
        prevTemplate?.recommendationHTML === nextTemplate?.recommendationHTML &&
        prevTemplate?.reviewHtml === nextTemplate?.reviewHtml
    );
};

export const useTemplate = (parentKeyGlobal: VariableParentKey) => {
    const currentTemplatePreview = useRef(null);
    const currentTemplateData = useRef<TemplateIsEqual>({
        baseHtml: '',
        productHtml: '',
        reviewHtml: ''
    });

    const { locale, locales, defaultLocale } = useRouter();

    const getVariableCallback = useCallback(
        (variableName: string, parentKey: VariableParentKey = parentKeyGlobal) => getVariable(variableName, parentKey),
        [parentKeyGlobal]
    );

    const translateVariableCallback = useCallback((variable: TemplateVariable, valuesLocale: LocalVariables) => {
        return translateVariable(variable, valuesLocale);
    }, []);

    const generateTemplatePreviewBySendingTechnologyCallback = useCallback(
        (props: Omit<GenerateTemplatePreviewProps, 'translator'>) => {
            if (templateIsEqual(currentTemplateData.current, props)) {
                return currentTemplatePreview.current;
            }

            const preview = generateTemplatePreviewBySendingTechnology({
                ...props,
                parentKey: props?.parentKey ?? parentKeyGlobal,
                translator: { locale, locales, defaultLocale }
            });

            currentTemplateData.current = props;
            currentTemplatePreview.current = preview;

            return preview;
        },
        [parentKeyGlobal, locale, locales, defaultLocale]
    );

    const generateEmailSubjectPreviewCallback = useCallback(
        (subject: string, user: User, allowedVariables = [], parentKey: VariableParentKey = parentKeyGlobal) => {
            return generateEmailSubjectPreview(
                subject,
                user,
                { locale, locales, defaultLocale },
                parentKey,
                allowedVariables
            );
        },
        [defaultLocale, parentKeyGlobal, locale, locales]
    );

    const getVariableValueCallback = useCallback((variables: LocalVariables, variable: TemplateVariable) => {
        return getVariableValue(variables, variable);
    }, []);

    return {
        generateTemplatePreviewBySendingTechnology: generateTemplatePreviewBySendingTechnologyCallback,
        translateVariable: translateVariableCallback,
        getVariable: getVariableCallback,
        generateEmailSubjectPreview: generateEmailSubjectPreviewCallback,
        getVariableValue: getVariableValueCallback
    };
};

export * from './preview';
export * from './utils';
