import { forwardRef, HTMLProps, MutableRefObject, useEffect } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import ArrowIcon from '~/assets/svg/controls/arrow--down.svg';
import { useSelectContext } from '../context';
import { SelectTriggerStyled } from './styles';

type SelectTriggerProps = {
    placeholder?: string;
    testId?: string;
};

export type TriggerReference = HTMLButtonElement & {
    clear: () => void;
    selectValue: (index: number, label: string) => void;
};

export const SelectTrigger = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement> & SelectTriggerProps>(
    function SelectTrigger({ testId, children, placeholder, disabled, ...props }, propRef) {
        const { refs, selectedIndex, selectedLabel, getReferenceProps, clearValue, selectValue } = useSelectContext();

        useEffect(() => {
            (propRef as MutableRefObject<TriggerReference>).current.clear = clearValue;
            (propRef as MutableRefObject<TriggerReference>).current.selectValue = selectValue;

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [clearValue, selectValue, propRef]);

        const ref = useMergeRefs([refs.setReference, propRef]);

        return (
            <SelectTriggerStyled
                type='button'
                inputSize='small'
                ref={ref}
                tabIndex={0}
                data-testid={testId}
                disabled={disabled}
                value={selectedIndex}
                hasValue={!!selectedLabel}
                {...getReferenceProps(props)}>
                <div className='select-trigger__label'>
                    {children}
                    <span>{selectedLabel ?? placeholder}</span>
                </div>
                <ArrowIcon className='select-trigger__arrow-icon' />
            </SelectTriggerStyled>
        );
    }
);
