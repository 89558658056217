import { HTMLAttributes, ReactNode } from 'react';
import { BulletNumber, CardText, BulletNumberProps } from '~/components/elements';
import { ActionsStyled, ArrowDownStyled, CardHeaderStyled, CardHeaderStyleProps, TitleStyled } from './styles';

export type CardHeaderProps = CardHeaderStyleProps &
    Omit<HTMLAttributes<HTMLHeadingElement>, 'title'> & {
        title?: ReactNode;
        subtitle?: ReactNode;
        description?: ReactNode;
        actions?: ReactNode;
        bulletNumber?: BulletNumberProps;
        icon?: ReactNode;
        onClick?: () => void;
        cardChild?: boolean;
    };

const defaultProps: CardHeaderProps = {
    type: 'default',
    bulletNumber: {
        show: false
    },
    subtitle: 'Displaying subtitle',
    cardChild: true
};

export const CardHeader = ({
    type,
    title,
    subtitle,
    description,
    actions,
    bulletNumber,
    icon,
    onClick,
    showExpandIcon,
    ...props
}: CardHeaderProps) => {
    return (
        <CardHeaderStyled type={type} onClick={onClick} showExpandIcon={showExpandIcon} {...props}>
            {type === 'group' ? (
                <>
                    <TitleStyled>
                        <CardText value={title} show={!!title} type='title' />
                        <CardText value={description} show={!!description} type='description' />
                    </TitleStyled>
                    {actions && <ActionsStyled>{actions}</ActionsStyled>}
                </>
            ) : (
                <>
                    {icon}
                    <BulletNumber {...bulletNumber} />
                    <CardText value={subtitle} show={!!subtitle} type='subtitle' />
                </>
            )}
            {showExpandIcon && <ArrowDownStyled />}
        </CardHeaderStyled>
    );
};

CardHeader.defaultProps = defaultProps;
