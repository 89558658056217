import styled, { css } from 'styled-components';
import { mobileAndTabletOnly } from '~/styles';
import { SubToolColors } from '~/styles/theme';
import type { MarketingAutomationResultStatus } from './marketingAutomationResult';

export const CardItemContentStyled = styled.div`
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    min-height: 6.152rem;

    ${mobileAndTabletOnly(css`
        flex-direction: column;
    `)}
`;

export const CardItemTitleStyled = styled.div<{ subToolColor: SubToolColors }>`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    flex: 1;

    svg {
        width: 5.3rem;
        height: 5.3rem;
        color: ${({ theme, subToolColor }) => theme.subToolColors[subToolColor]};
    }

    strong {
        flex: 1;
    }
`;

export const TotalSaleStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.space.xs};
`;

export const SubToolStatusButtonStyled = styled.div<{ status?: MarketingAutomationResultStatus }>`
    display: flex;
    gap: 1.6rem;
    align-items: center;

    span {
        flex: 1;
        max-width: ${({ status }) => (status === 'inactive' ? '27.6rem' : '23.3rem')};

        ${mobileAndTabletOnly(css`
            max-width: 100%;
        `)}
    }
`;
