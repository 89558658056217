import styled, { css } from 'styled-components';
import { DialogContent } from '~/components/elements/dialog';
import { mobileAndTabletOnly } from '~/styles';

export const EmailMarketingContentStyled = styled.div`
    display: grid;
    gap: 6.4rem 8rem;
    grid-template-columns: minmax(1px, 54.6rem) auto;
    margin-top: 4rem;

    ${mobileAndTabletOnly(css`
        grid-template-columns: 1fr;
    `)}

    & > div {
        display: flex;
        flex-direction: column;

        &:has(.page-image, .chart-image) {
            justify-content: center;
            align-items: center;
        }
    }

    .page-image {
        box-shadow: 0 10px 40px #2a334a29;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 2.2rem;
        margin-top: 2.4rem;

        li {
            display: flex;
            align-items: center;
            gap: 2rem;

            svg {
                height: 2.4rem;
                width: 2.4rem;
            }

            span {
                margin-top: 0.25rem;
                display: block;
                width: 100%;
            }
        }
    }
`;

export const DialogContentStyled = styled(DialogContent)`
    position: relative;
    margin-top: 10vh;
    width: 100%;
    max-width: 120rem;
    padding: 4rem;

    h2 {
        color: ${({ theme }) => theme.colors.licorice};
        text-transform: uppercase;
        text-align: start;
    }

    p {
        text-align: start;
        margin-top: 1.2rem;
    }
`;
