import { PeriodTypeDropdown, Heading, Text } from '@components';
import { useSubToolPage, useTranslator } from '@hooks';
import { SubToolIdEnum } from '@models';
import { DateFilterOptionsWithCustomTimePeriod as PeriodType } from '@utils';
import { SubToolColors } from '~/styles/theme';
import { HeaderStyled } from './styles';

type HeaderProps = {
    subToolId: SubToolIdEnum;
    startDate?: Date;
    endDate?: Date;
    periodType?: PeriodType;
    disableDropdown?: boolean;
    title?: string;
    onChangePeriodType?: (periodType: PeriodType, startDate?: Date, endDate?: Date) => void;
};

export const Header = ({
    subToolId,
    startDate,
    endDate,
    periodType,
    onChangePeriodType,
    title,
    disableDropdown = false
}: HeaderProps) => {
    const {
        pages: {
            automationStatistics: { sendingTechnologyStatisticsModal }
        }
    } = useTranslator();
    const { getSubToolPageData } = useSubToolPage();
    const { icon, translatedSubToolName, subToolKey } = getSubToolPageData(subToolId);

    return (
        <HeaderStyled subToolColor={subToolKey as SubToolColors}>
            <div>
                <i>{icon}</i>
                <div>
                    <Heading as='h5'>{title ?? translatedSubToolName}</Heading>
                    <Text size='md'>{String(sendingTechnologyStatisticsModal.description)}</Text>
                </div>
            </div>
            <PeriodTypeDropdown
                periodType={periodType}
                startDate={startDate}
                endDate={endDate}
                disabled={disableDropdown}
                onChangePeriodType={onChangePeriodType}
            />
        </HeaderStyled>
    );
};
