import HTMLReactParser from 'html-react-parser';
import { Text } from '@components';
import { FunnelStageContentStyled } from './styles';

export type ToolFunnelChartContentProps = {
    title: string;
    subtitle: string;
    stagesLength: number;
};

export const ToolFunnelChartContent = ({ title, subtitle, stagesLength }: ToolFunnelChartContentProps) => {
    return (
        <FunnelStageContentStyled stagesLength={stagesLength}>
            <Text as='strong' size='lg'>
                {HTMLReactParser(title)}
            </Text>
            {subtitle && (
                <Text as='span' size='xxs' lineHeight='short' fontWeight='light'>
                    {subtitle}
                </Text>
            )}
        </FunnelStageContentStyled>
    );
};
