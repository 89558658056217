import HTMLReactParser from 'html-react-parser';
import { NoticeCard } from '@components';
import { BankSlipCard } from '@containers';
import { useTranslator } from '@hooks';
import { SubscriptionInvoice, SubscriptionInvoiceTypeEnum, SubTool } from '@models';
import { Container } from './styles';

type BankSlipTemplateType = {
    subscriptionInvoice?: SubscriptionInvoice;
    subTools?: Array<SubTool>;
};
export const BankSlipTemplate = ({ subscriptionInvoice, subTools }: BankSlipTemplateType) => {
    const { bankSlipPage } = useTranslator();

    return (
        <Container>
            <NoticeCard.Root className='customer-alert'>
                <NoticeCard.Content>
                    <NoticeCard.TedIcon />
                    <NoticeCard.ContentBody>
                        <NoticeCard.Title>
                            {String(
                                subscriptionInvoice.type === SubscriptionInvoiceTypeEnum.Single
                                    ? bankSlipPage.bankSlipHeader.looseTitle
                                    : bankSlipPage.bankSlipHeader.title
                            )}
                        </NoticeCard.Title>
                        <NoticeCard.Description>
                            {HTMLReactParser(String(bankSlipPage.bankSlipHeader.description))}
                        </NoticeCard.Description>
                    </NoticeCard.ContentBody>
                </NoticeCard.Content>
                <NoticeCard.CloseButton />
            </NoticeCard.Root>

            <BankSlipCard subscriptionInvoice={subscriptionInvoice} subTools={subTools} />
        </Container>
    );
};
