import styled, { css } from 'styled-components';
import { mobileOnly } from '~/styles';
import { TooltipContent } from '../tooltip';

export const Content = styled.div`
    & > div:has(#stepName) {
        max-width: 50rem;
        margin-bottom: 2.4rem;
    }
`;

export const PeriodContentStyled = styled.div`
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 1.6rem;

    div:has(#sendingPeriod) {
        width: 9.6rem;
    }

    div:has(#frequencyRange) {
        width: 16rem;
    }

    div:has(#frequencyEventTrigger) {
        width: 30rem;
    }

    ${mobileOnly(css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > div + div {
            margin-top: 1.6rem;
        }

        & > div,
        & > div > div,
        input {
            width: 100% !important;
            max-width: 50rem;
        }

        label:first-child {
            margin-bottom: 0.8rem;
        }

        div + label {
            margin-top: 0.8rem;
        }
    `)}
`;

export const TooltipContentStyled = styled(TooltipContent)``;
