import styled, { css } from 'styled-components';
import { mobileOnly } from '~/styles';

export const SubscriptionToolsStyled = styled.section`
    display: flex;
    gap: 3.2rem;
    flex-direction: column;

    div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1.6rem;
        max-width: 56rem;

        button {
            flex: 1 1 48%;
            justify-content: flex-start;
            margin-top: 0;

            ${mobileOnly(css`
                flex: 1 1 100%;
            `)}
        }
    }
`;
