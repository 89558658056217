import {
    Children,
    isValidElement,
    cloneElement,
    ReactNode,
    forwardRef,
    DetailedHTMLProps,
    HTMLAttributes
} from 'react';
import { CardBodyProps } from './body';
import { CardFooterProps } from './footer';
import { CardHeaderProps } from './header';
import { CardStyleProps, CardStyled } from './styles';

export type CardProps = CardStyleProps & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const Card = forwardRef<HTMLElement, CardProps>(
    ({ children, type = 'default', className, flexGrid, ...props }, ref) => {
        return (
            <CardStyled {...props} type={type} flexGrid={flexGrid} className={className} ref={ref}>
                {Children?.map<ReactNode, ReactNode>(children, (child) => {
                    if (isValidElement<CardHeaderProps | CardBodyProps | CardFooterProps>(child)) {
                        if (child.props.cardChild) {
                            return cloneElement(child, { ...child.props, type: type });
                        }
                        return child;
                    }
                })}
            </CardStyled>
        );
    }
);

Card.displayName = 'Card';

export * from './body';
export * from './bulletNumber';
export * from './footer';
export * from './header';
export * from './link';
export * from './text';
