import styled from 'styled-components';
import { Box } from '~/components/elements/box';
import { DialogContent } from '~/components/elements/dialog/content';

export const DialogContentStyled = styled(DialogContent)`
    padding: 3.2rem;
    width: 100%;
    margin-top: 15vh;
    max-width: 84rem;

    & > h2 {
        text-transform: uppercase;
        margin-bottom: 1.2rem;
        color: ${({ theme }) => theme.colors.licorice};
    }

    & > p {
        font-size: ${({ theme }) => theme.fontSizes.md};

        a {
            color: ${({ theme }) => theme.colors.deepCerise};
            text-decoration: underline;
        }
    }

    footer {
        margin-top: 3.2rem;
        justify-content: space-between;

        button {
            width: fit-content;
        }
    }
`;

export const MappedFieldsListStyled = styled(Box)`
    padding: 0;
    list-style: none;
    margin-top: 4rem;
    max-height: 34rem;
    overflow-y: auto;
    position: relative;

    li {
        padding: 0.8rem 2.4rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 1.6rem;

        & > strong {
            color: ${({ theme }) => theme.colors.deepCerise};
        }

        & + li:not(:nth-child(2)) {
            border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
        }

        &:first-child {
            width: 100%;
            padding: 2rem 2.4rem 1.6rem;
            background-color: ${({ theme }) => theme.colors.white};
            border-bottom: 1px solid ${({ theme }) => theme.colors.lightPurple};

            position: sticky;
            top: 0;
            z-index: 99;
        }

        .remove-icon {
            color: ${({ theme }) => theme.colors.sangria};
        }
    }
`;
