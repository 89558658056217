import _first from 'lodash/first';
import _isEmpty from 'lodash/isEmpty';
import { TranslatorProps } from '@hooks';
import { User } from '@models';
import { getAllVariables, getFirstTemplateVariableByRegex, normalizeVariable, VariableParentKey } from '../utils';
import { GenerateTemplatePreviewProps, PreviewData } from './generatePreview';
import {
    generateBankSlipBlock,
    generateBankSlipProductBlock,
    generateCartProductBlock,
    generateConsumerBlock,
    generateCustomTriggerBlock,
    generateNavigatedProductBlock,
    generateRecommendationProductBlock,
    generateStoreBlock,
    generateSubjectBlock
} from './generatePreviewBlocks';
import { generateTemplatePreview } from './generateTemplatePreview';
import { getPreviewProducts } from './getProducts';

const productBlocks: { [key in VariableParentKey]?: (...props) => string } = {
    navigationTrigger: generateNavigatedProductBlock,
    repurchase: generateCartProductBlock,
    cartRecovery: generateRecommendationProductBlock,
    bankSlipReminder: generateBankSlipProductBlock
};

type AddNewVariableInPreviewDataProps = {
    productData: string;
    variableName: string;
    previewData: PreviewData;
    allowedVariables?: Array<string>;
};

type AddCustomVariableProps = GenerateTemplatePreviewProps & {
    previewData: PreviewData;
    regexVariable?: string;
    variableKey: string;
};

const addNewVariableInPreviewData = ({
    variableName,
    previewData,
    allowedVariables = [],
    productData
}: AddNewVariableInPreviewDataProps) => {
    if (!_isEmpty(variableName)) {
        variableName = normalizeVariable(variableName);
        previewData[variableName] = productData;
        allowedVariables.push(variableName);
    }
};

const addCustomVariable = ({
    translator,
    baseHtml,
    productHtml,
    recommendationHTML,
    reserveProductHtml,
    displayReserveProduct,
    allowedVariables = [],
    parentKey,
    previewData,
    regexVariable,
    variableKey,
    ...props
}: AddCustomVariableProps) => {
    const templateVariable = getFirstTemplateVariableByRegex(baseHtml, regexVariable);
    const productBlockCallback = productBlocks[parentKey];

    if (!_isEmpty(templateVariable) && productBlockCallback) {
        const [variableName] = templateVariable;

        const productData = productBlockCallback({
            ...props,
            translator,
            productHtml,
            recommendationHTML,
            variableKey,
            parentKey,
            reserveProductHtml,
            displayReserveProduct,
            variableParams: templateVariable
        });

        addNewVariableInPreviewData({
            variableName,
            previewData,
            allowedVariables,
            productData
        });
    }
};

const addCustomVariables = ({ parentKey, ...props }: Omit<AddCustomVariableProps, 'regexVariable' | 'variableKey'>) => {
    const localVariables = getAllVariables({
        parentKey,
        isRegex: true
    });

    if (!_isEmpty(localVariables)) {
        for (const localVariable of localVariables) {
            for (const regexVariable of localVariable.variables) {
                addCustomVariable({ ...props, parentKey, regexVariable, variableKey: localVariable.key });
            }
        }
    }
};

export const generateEmailTemplatePreview = ({
    translator,
    user,
    baseHtml,
    productHtml,
    reserveProductHtml,
    recommendationHTML,
    displayReserveProduct,
    reviewHtml,
    allowedVariables = [],
    parentKey
}: GenerateTemplatePreviewProps) => {
    if (!user) {
        return '';
    }

    const productsData = generateCartProductBlock({
        translator,
        variableKey: 'cart.products',
        user,
        parentKey,
        productHtml,
        reviewHtml
    });

    const bankSlipProductsData = generateBankSlipProductBlock({
        translator,
        variableKey: 'bankslip.products',
        user,
        parentKey,
        productHtml,
        reviewHtml
    });

    const storeData = generateStoreBlock(user);
    const consumerData = generateConsumerBlock(translator);
    const bankSlipData = generateBankSlipBlock(translator);
    const customTrigger = generateCustomTriggerBlock(translator);

    const data: PreviewData = {
        ...storeData,
        ...consumerData,
        ...bankSlipData,
        ...customTrigger,
        'cart.products': displayReserveProduct ? reserveProductHtml : productsData,
        'bankslip.products': bankSlipProductsData
    };

    addCustomVariables({
        previewData: data,
        translator,
        baseHtml,
        productHtml,
        displayReserveProduct,
        recommendationHTML,
        reserveProductHtml,
        allowedVariables,
        parentKey,
        reviewHtml,
        user
    });

    return generateTemplatePreview(baseHtml, data, parentKey, allowedVariables);
};

export const generateEmailSubjectPreview = (
    subject: string,
    user: User,
    translator: TranslatorProps,
    parentKey: VariableParentKey,
    allowedVariables = []
) => {
    if (_isEmpty(user)) {
        return '';
    }

    const product = _first(getPreviewProducts(translator));
    const subjectData = generateSubjectBlock(user.name, String(product.name), user.name);

    return generateTemplatePreview(subject, subjectData, parentKey, allowedVariables);
};
