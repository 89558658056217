import { Card, CardBody, CardHeader } from '@components';
import { useGetSubscriptionTools, useSubscriptionPaymentDetails, useSubscriptionTotals, useTranslator } from '@hooks';
import { useCheckout, useCoupon, usePaymentMethods, useTools } from '~/providers/checkoutProvider';
import { DiscountDetails } from './discountDetails';
import { InvoiceDetailsStyled } from './styles';
import { ToolsDetails } from './toolsDetails';
import { TotalDetails } from './totalDetails';

type InvoiceDetailsProps = {
    currency?: string;
};

export const InvoiceDetails = ({ currency = 'BRL' }: InvoiceDetailsProps) => {
    const { title } = useTranslator().checkoutPage.invoiceDetails;
    const { subscriptionFormData, hasSubscription, subscription, isSuspendedPlan } = useCheckout();
    const { tools } = useTools();
    const { couponAttemptResult } = useCoupon();
    const { billingPeriods, paymentMethods } = usePaymentMethods();
    const {
        creditVolume = 0,
        emailMarketingAmount = 0,
        billingPeriodId = 1,
        sendingTechnologies: sendingTechnologyIds
    } = subscriptionFormData?.subscriptionPlan || {};

    const defaultHooksProps = { billingPeriods, paymentMethods, tools, couponAttemptResult };

    const { selectedBillingPeriods, selectedPaymentMethod, sendingTechnologies } = useSubscriptionPaymentDetails({
        ...defaultHooksProps,
        billingPeriodId,
        paymentMethod: subscriptionFormData?.subscriptionPayment?.paymentMethod,
        sendingTechnologyIds
    });

    const { discountTotal, totalToPay, discounts, couponDiscounts } = useSubscriptionTotals({
        ...defaultHooksProps,
        automationCreditAmount: creditVolume,
        bulkSendingCreditAmount: emailMarketingAmount,
        sendingTechnologyIds,
        billingPeriod: selectedBillingPeriods,
        paymentMethod: selectedPaymentMethod,
        enableCustomValue: isSuspendedPlan,
        isSuspendedPlan
    });

    const selectedTools = useGetSubscriptionTools({
        ...defaultHooksProps,
        automationCreditAmount: creditVolume,
        bulkSendingCreditAmount: emailMarketingAmount,
        billingPeriod: selectedBillingPeriods,
        sendingTechnologies,
        currentSubscription: hasSubscription ? subscription : null,
        toolDiscounts: couponDiscounts?.discounts,
        enableCustomValue: isSuspendedPlan
    });

    return (
        <InvoiceDetailsStyled>
            <Card type='group' showFocusBorder={false}>
                <CardHeader title={String(title)} />
                <CardBody>
                    <ToolsDetails
                        selectedTools={selectedTools}
                        hasSubscription={hasSubscription}
                        isSuspendedPlan={isSuspendedPlan}
                    />

                    <DiscountDetails
                        discountPaymentMethodsTotal={discounts.paymentMethod}
                        discountBillingPeriodTotal={discounts.billingPeriod}
                        toolDiscounts={couponDiscounts.discounts}
                        discountCouponTotal={couponDiscounts.total}
                        discountTotal={discountTotal}
                        currency={currency}
                        paymentMethods={selectedPaymentMethod}
                        periodicityInMonths={selectedBillingPeriods?.periodicityInMonths}
                    />
                    <TotalDetails
                        totalToPay={totalToPay}
                        billingPeriodsName={selectedBillingPeriods?.name}
                        currency={currency}
                    />
                </CardBody>
            </Card>
        </InvoiceDetailsStyled>
    );
};

export * from './acceptTerms';
