/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';

type UseApiOptions<T> = {
    onSuccess?: (data: T) => void;
    onError?: (error: unknown) => void;
    onPostExecute?: () => void;
};

export const useApi = <T extends (...args: any[]) => ReturnType<T>>(
    func: T,
    options: UseApiOptions<ReturnType<T>> = {}
) => {
    const [data, setData] = useState<ReturnType<T> | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const execute = useCallback(
        (...args: Parameters<T>) => {
            setLoading(true);
            setData(null);
            setError(null);

            const result = func(...args);
            const { onSuccess, onError, onPostExecute } = options ?? {};

            if (result instanceof Promise) {
                result
                    .then((response) => {
                        setData(response);
                        onSuccess && onSuccess(response);
                    })
                    .catch((error) => {
                        setError(error);
                        onError && onError(error);
                    })
                    .finally(() => {
                        setLoading(false);
                        onPostExecute && onPostExecute();
                    });
            } else {
                setLoading(false);
                setData(result);
            }
        },
        [func, options]
    );

    return { execute, data, loading, error };
};
