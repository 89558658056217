import { useCallback, useMemo } from 'react';
import _lodash from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import {
    BillingPeriod,
    BillingPeriodEnum,
    CouponAttemptResult,
    CustomerPlanType,
    PaymentMethod,
    PaymentMethodTypeEnum,
    SendingTechnology,
    Subscription,
    SubscriptionTool,
    Tool,
    ToolTypeEnum
} from '@models';
import { isGreaterThanZero } from '@utils';
import {
    SelectedToolsWithAmountData,
    GetAllToolDiscountWithCoupon,
    GetBillingPeriodById,
    GetSendingPriceByPeriodicity,
    GetToolByType,
    ToolDiscountData
} from '~/services/subscriptionService';

type GetSubscriptionSummaryProps = {
    tools: Array<Tool>;
    billingPeriods: Array<BillingPeriod>;
    couponAttemptResult?: CouponAttemptResult;
    paymentMethods: Array<PaymentMethod>;
};

type CalculateTotalByToolProps = {
    toolType: ToolTypeEnum;
    creditAmount: number;
    periodicityInMonths: BillingPeriodEnum;
    sendingTechnologyIds?: Array<number>;
    enableCustomValue?: boolean;
};

type CalculateTotalAllToolsProps = {
    automationCreditAmount: number;
    bulkSendingCreditAmount: number;
    periodicityInMonths: BillingPeriodEnum;
    sendingTechnologyIds?: Array<number>;
    enableCustomValue?: boolean;
};

export const useSubscription = ({
    tools,
    billingPeriods,
    couponAttemptResult,
    paymentMethods
}: GetSubscriptionSummaryProps) => {
    const getSendingTechnologies = useCallback(
        (toolType: ToolTypeEnum, sendingTechnologiesIds: Array<number> = []) => {
            const tool = GetToolByType(tools, toolType);

            if (tool) {
                if (!_isEmpty(sendingTechnologiesIds)) {
                    return tool.sendingTechnologies.filter((item) => sendingTechnologiesIds.includes(item.id));
                }
                return tool.sendingTechnologies ?? [];
            }

            return [];
        },
        [tools]
    );

    const getBillingPeriodById = useCallback(
        (billingPeriodId: number) => {
            return GetBillingPeriodById(billingPeriods, billingPeriodId);
        },
        [billingPeriods]
    );

    const getPaymentMethodById = useCallback(
        (paymentMethodId: PaymentMethodTypeEnum = PaymentMethodTypeEnum.CreditCard) => {
            return paymentMethods?.find((payment) => payment.type === paymentMethodId);
        },
        [paymentMethods]
    );

    const calculateTotalByTool = useCallback(
        ({
            toolType,
            creditAmount,
            sendingTechnologyIds = [],
            periodicityInMonths = 1,
            enableCustomValue = false
        }: CalculateTotalByToolProps) => {
            const tool = GetToolByType(tools, toolType);

            if (_isEmpty(tool.sendingTechnologies) || !isGreaterThanZero(creditAmount)) {
                return 0;
            }

            const totalReduce = (total: number, technology: SendingTechnology) =>
                total + GetSendingPriceByPeriodicity(technology, periodicityInMonths);

            const hasCustomValues =
                enableCustomValue && tool.sendingTechnologies.some((technology) => technology.isCustomValue);

            if (toolType === ToolTypeEnum.BulkSending) {
                return tool.sendingTechnologies
                    .filter((technology) =>
                        hasCustomValues
                            ? technology.creditAmount === creditAmount && technology.isCustomValue
                            : technology.creditAmount === creditAmount && !technology.isCustomValue
                    )
                    .reduce(totalReduce, 0);
            }

            if (toolType === ToolTypeEnum.AutomationTool && !_isEmpty(sendingTechnologyIds)) {
                return tool.sendingTechnologies
                    .filter((technology) =>
                        hasCustomValues
                            ? sendingTechnologyIds.some((id) => id === technology.id && technology.isCustomValue)
                            : sendingTechnologyIds.some((id) => id === technology.id && !technology.isCustomValue)
                    )
                    .reduce(totalReduce, 0);
            }

            return 0;
        },
        [tools]
    );

    const calculateTotalAllTools = useCallback(
        ({
            automationCreditAmount,
            bulkSendingCreditAmount,
            periodicityInMonths,
            sendingTechnologyIds,
            enableCustomValue
        }: CalculateTotalAllToolsProps) => {
            const automationToolTotal = calculateTotalByTool({
                toolType: ToolTypeEnum.AutomationTool,
                creditAmount: automationCreditAmount,
                periodicityInMonths: periodicityInMonths,
                sendingTechnologyIds,
                enableCustomValue
            });

            const emailMarketingTotal = calculateTotalByTool({
                toolType: ToolTypeEnum.BulkSending,
                creditAmount: bulkSendingCreditAmount,
                periodicityInMonths: periodicityInMonths,
                enableCustomValue
            });

            return {
                pricesPerTool: [
                    { toolType: ToolTypeEnum.AutomationTool, price: automationToolTotal },
                    { toolType: ToolTypeEnum.BulkSending, price: emailMarketingTotal }
                ],
                total: automationToolTotal + emailMarketingTotal
            };
        },
        [calculateTotalByTool]
    );

    const calculateTotalCouponDiscount = useCallback(
        (automationToolPrice: number, emailMarketingPrice: number) => {
            const allTools: SelectedToolsWithAmountData[] = [
                {
                    tool: { id: ToolTypeEnum.AutomationTool, type: ToolTypeEnum.AutomationTool },
                    amount: automationToolPrice
                },
                { tool: { id: ToolTypeEnum.BulkSending, type: ToolTypeEnum.BulkSending }, amount: emailMarketingPrice }
            ];

            const discounts = GetAllToolDiscountWithCoupon(allTools, couponAttemptResult) ?? [];

            return {
                discounts,
                total: discounts.reduce((total, tool) => total + tool?.discountAmount, 0)
            };
        },
        [couponAttemptResult]
    );

    const calculateDiscounts = useCallback(
        (total: number, billingPeriodDiscountPercentage: number, paymentMethodDiscountPercentage: number) => {
            const billingPeriodTotal = billingPeriodDiscountPercentage ? total * billingPeriodDiscountPercentage : 0;

            const discountPaymentMethodsTotal = paymentMethodDiscountPercentage
                ? (total - billingPeriodTotal) * paymentMethodDiscountPercentage
                : 0;

            return {
                billingPeriod: billingPeriodTotal,
                paymentMethod: discountPaymentMethodsTotal
            };
        },
        []
    );

    const getEmailMarketingPrice = useCallback(
        (creditAmount: number, periodicityInMonths: BillingPeriodEnum, enableCustomValue?: boolean) => {
            return getSendingTechnologies(ToolTypeEnum.BulkSending)
                .filter(
                    (technology) =>
                        technology.creditAmount === creditAmount &&
                        (enableCustomValue === undefined || (technology.isCustomValue ?? false) === enableCustomValue)
                )
                .reduce(
                    (total, technology) => total + GetSendingPriceByPeriodicity(technology, periodicityInMonths),
                    0
                );
        },
        [getSendingTechnologies]
    );

    const getSendingTechnologyPriceByPeriodicity = useCallback(
        (sendingTechnologies: Array<SendingTechnology>, periodicityInMonths: BillingPeriodEnum) => {
            return (
                sendingTechnologies.map((item) => {
                    return {
                        price: GetSendingPriceByPeriodicity(item, periodicityInMonths),
                        currency: 'BRL',
                        sendingTechnology: item
                    };
                }) ?? []
            );
        },
        []
    );

    return {
        subscriptionTools: [],
        getSendingTechnologies,
        getBillingPeriodById,
        getPaymentMethodById,
        getEmailMarketingPrice,
        getSendingTechnologyPriceByPeriodicity,

        calculateTotalByTool,
        calculateTotalAllTools,
        calculateTotalCouponDiscount,
        calculateDiscounts
    };
};

/** Get the selected shipping technologies for the automation tool, the form and the payment period */
export const useSubscriptionPaymentDetails = ({
    tools,
    billingPeriods,
    couponAttemptResult,
    paymentMethods,
    ...props
}: GetSubscriptionSummaryProps & {
    billingPeriodId: number;
    paymentMethod: number;
    sendingTechnologyIds: Array<number>;
}) => {
    const { getBillingPeriodById, getSendingTechnologies, getPaymentMethodById } = useSubscription({
        billingPeriods,
        paymentMethods,
        tools,
        couponAttemptResult
    });

    const { billingPeriodId, paymentMethod, sendingTechnologyIds } = props;

    return useMemo(() => {
        const selectedBillingPeriods = getBillingPeriodById(billingPeriodId);
        const selectedPaymentMethod = getPaymentMethodById(paymentMethod);
        const sendingTechnologies = getSendingTechnologies(ToolTypeEnum.AutomationTool, sendingTechnologyIds);

        return {
            selectedBillingPeriods,
            selectedPaymentMethod,
            sendingTechnologies
        };
    }, [
        billingPeriodId,
        sendingTechnologyIds,
        paymentMethod,
        getBillingPeriodById,
        getPaymentMethodById,
        getSendingTechnologies
    ]);
};

/** Calculates the total payable and all discounts per coupon, payment method, and billing period, if any */
export const useSubscriptionTotals = ({
    tools,
    billingPeriods,
    couponAttemptResult,
    paymentMethods,
    ...props
}: GetSubscriptionSummaryProps & {
    billingPeriod: BillingPeriod;
    paymentMethod?: PaymentMethod;
    sendingTechnologyIds: Array<number>;
    automationCreditAmount: number;
    bulkSendingCreditAmount: number;
    enableCustomValue?: boolean;
    isSuspendedPlan?: boolean;
}) => {
    const { calculateTotalAllTools, calculateTotalCouponDiscount, calculateDiscounts } = useSubscription({
        billingPeriods,
        paymentMethods,
        tools,
        couponAttemptResult
    });

    const {
        billingPeriod,
        paymentMethod,
        sendingTechnologyIds,
        automationCreditAmount,
        bulkSendingCreditAmount,
        enableCustomValue,
        isSuspendedPlan
    } = props;

    return useMemo(() => {
        const totalPerTool = calculateTotalAllTools({
            automationCreditAmount,
            bulkSendingCreditAmount,
            periodicityInMonths: billingPeriod?.periodicityInMonths,
            sendingTechnologyIds,
            enableCustomValue
        });

        const automationTool = totalPerTool.pricesPerTool.find((item) => item.toolType === ToolTypeEnum.AutomationTool);
        const bulkSendingTool = totalPerTool.pricesPerTool.find((item) => item.toolType === ToolTypeEnum.BulkSending);

        const couponDiscounts = calculateTotalCouponDiscount(automationTool?.price, bulkSendingTool?.price);

        let discounts: ReturnType<typeof calculateDiscounts> = {
            billingPeriod: 0,
            paymentMethod: 0
        };

        if (!isSuspendedPlan) {
            discounts = calculateDiscounts(
                totalPerTool.total - couponDiscounts.total,
                billingPeriod?.discountPercentage,
                paymentMethod?.discountPercentage
            );
        }

        const discountTotal = couponDiscounts.total + discounts.billingPeriod + discounts.paymentMethod;
        const totalToPay = totalPerTool.total - discountTotal;

        return {
            discountTotal,
            totalToPay,
            totalPerTool,
            discounts,
            couponDiscounts
        };
    }, [
        calculateTotalCouponDiscount,
        calculateDiscounts,
        calculateTotalAllTools,
        automationCreditAmount,
        bulkSendingCreditAmount,
        enableCustomValue,
        billingPeriod?.periodicityInMonths,
        billingPeriod?.discountPercentage,
        sendingTechnologyIds,
        paymentMethod?.discountPercentage,
        isSuspendedPlan
    ]);
};

/** Returns a list of the tools contracted in the current plan and the new plan */
export const useGetSubscriptionTools = ({
    tools,
    billingPeriods,
    couponAttemptResult,
    paymentMethods,
    currentSubscription,
    toolDiscounts,
    enableCustomValue,
    ...props
}: GetSubscriptionSummaryProps & {
    billingPeriod: BillingPeriod;
    sendingTechnologies: Array<SendingTechnology>;
    automationCreditAmount: number;
    bulkSendingCreditAmount: number;
    currentSubscription?: Subscription;
    toolDiscounts?: ToolDiscountData[];
    enableCustomValue?: boolean;
}) => {
    const { getSendingTechnologyPriceByPeriodicity, getEmailMarketingPrice } = useSubscription({
        billingPeriods,
        paymentMethods,
        tools,
        couponAttemptResult
    });

    const { automationCreditAmount, bulkSendingCreditAmount, sendingTechnologies, billingPeriod } = props;

    return useMemo(() => {
        const sendingTechnologiesByCreditsAmount = sendingTechnologies.filter(
            (item) => item.creditAmount === automationCreditAmount
        );

        const automationSendingTechnologies = _lodash
            .chain(sendingTechnologiesByCreditsAmount)
            .orderBy(['isCustomValue', 'type'], [enableCustomValue ? 'asc' : 'desc'])
            .groupBy('type')
            .mapValues((items) => items[0])
            .values()
            .value();

        const automationToolSendingTechnologies = getSendingTechnologyPriceByPeriodicity(
            automationSendingTechnologies,
            billingPeriod.periodicityInMonths
        );

        const automationToolDiscount = toolDiscounts?.find((item) => item.toolId === ToolTypeEnum.AutomationTool);
        const bulkSendingDiscount = toolDiscounts?.find((item) => item.toolId === ToolTypeEnum.BulkSending);

        const automationTool = {
            price: automationToolSendingTechnologies?.reduce((total, tool) => total + tool.price, 0),
            totalCredits: automationToolDiscount?.totalCredits ?? automationCreditAmount,
            type: CustomerPlanType.AutomationTool,
            sendingTechnologies: automationToolSendingTechnologies
        };

        const bulkSendingPrice = getEmailMarketingPrice(
            bulkSendingCreditAmount,
            billingPeriod.periodicityInMonths,
            enableCustomValue
        );
        const bulkSending = {
            price: bulkSendingPrice,
            totalCredits: bulkSendingDiscount?.totalCredits ?? bulkSendingCreditAmount,
            type: CustomerPlanType.BulkSendingTool,
            sendingTechnologies: null
        };

        const currentAutomationTool =
            currentSubscription?.subscriptionTools?.find((item) => item.type === CustomerPlanType.AutomationTool) ??
            ({ type: CustomerPlanType.AutomationTool } as SubscriptionTool);

        const currentBulkSending =
            currentSubscription?.subscriptionTools?.find((item) => item.type === CustomerPlanType.BulkSendingTool) ??
            ({ type: CustomerPlanType.BulkSendingTool } as SubscriptionTool);

        if (currentBulkSending) {
            currentBulkSending.sendingTechnologies = [];
        }

        return [
            isGreaterThanZero(automationCreditAmount)
                ? { type: ToolTypeEnum.AutomationTool, newPlan: automationTool, currentPlan: currentAutomationTool }
                : null,
            isGreaterThanZero(bulkSendingCreditAmount)
                ? { type: ToolTypeEnum.BulkSending, newPlan: bulkSending, currentPlan: currentBulkSending }
                : null
        ].filter(Boolean);
    }, [
        getSendingTechnologyPriceByPeriodicity,
        sendingTechnologies,
        billingPeriod.periodicityInMonths,
        automationCreditAmount,
        getEmailMarketingPrice,
        bulkSendingCreditAmount,
        currentSubscription,
        toolDiscounts,
        enableCustomValue
    ]);
};
