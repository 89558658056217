import { ReactNode } from 'react';
import { Dropdown, DropdownProps, Label, OptionValueType } from '@components';
import { DropdownFormGroupStyledProps, DropdownFormGroupStyled } from './styles';

type DropdownFormGroupProps<T extends OptionValueType = string> = DropdownFormGroupStyledProps &
    DropdownProps<T> & {
        label?: string | ReactNode;
        dropdownTestId?: string;
        containerClassName?: string;
    };

export const DropdownFormGroup = <T extends OptionValueType = string>({
    children,
    label,
    flexGrid,
    dropdownTestId,
    inputSize,
    containerClassName,
    ...props
}: DropdownFormGroupProps<T>) => {
    return (
        <DropdownFormGroupStyled flexGrid={flexGrid} inputSize={inputSize} className={containerClassName}>
            {label && <Label>{label}</Label>}
            <Dropdown testId={dropdownTestId} {...props} inputSize={inputSize}>
                {children}
            </Dropdown>
        </DropdownFormGroupStyled>
    );
};
