import styled, { css } from 'styled-components';
import { Box } from '~/components/elements/box';
import { Card } from '~/components/elements/card';
import { mobileAndTabletOnly, mobileOnly } from '~/styles';

export const SectionStyled = styled.section`
    position: relative;
`;

export const ContentStyled = styled.div`
    display: flex;
    margin-top: 3.2rem;
    gap: 7rem;
    width: 100%;

    & > section,
    & > div {
        flex: 1 1 0;
    }

    & > div:first-child {
        max-width: 47rem;
    }

    ${mobileAndTabletOnly(css`
        flex-direction: column;
        align-items: center;

        & > div:first-child {
            max-width: 75%;
            width: 100%;

            ${mobileOnly(css`
                max-width: 100%;
            `)}
        }
    `)}
`;

export const CardItemsStyled = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    list-style: none;
`;

export const CardItemStyled = styled(Box).attrs({ as: 'li' })`
    padding: ${({ theme }) => theme.space.lg};
`;

export const CardStyled = styled(Card).attrs(({ borderColor, theme }) => ({
    borderColor: borderColor ?? theme.colors.enlightenedPurple
}))`
    padding: 3.2rem;
    display: flex;
    flex-direction: column;

    & > header {
        padding: 0;
        margin-bottom: 3.2rem;
    }

    & > main {
        padding: 0;
        flex: 1;
    }
`;
