import styled from 'styled-components';

export const SubToolConfigurationTemplateStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
`;

export const SubToolConfigurationTemplateButtonGroupStyled = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    padding-top: calc(5rem - 3.6rem);
    padding-bottom: 8rem;

    & > div {
        display: inherit;
        gap: inherit;
        flex: 1;
        justify-content: flex-end;
    }

    button {
        min-width: 16.4rem;
    }
`;
