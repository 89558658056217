import { useMemo } from 'react';
import HtmlReactParser from 'html-react-parser';
import { Button, Link } from '@components';
import { useRedirectToPainel2, useReplace, useTranslator } from '@hooks';
import { AlertOptions, useAuth } from '@providers';
import { Dialog, DialogClose, DialogDescription, DialogFooter, DialogHeading } from '~/components/elements/dialog';
import { routesName } from '~/locales/route';
import { DialogContentStyled } from './styles';

type ProductmportFailedModalProps = {
    options: AlertOptions;
};

export const ProductImportFailedModal = ({ options }: ProductmportFailedModalProps) => {
    const {
        buttons,
        pages: {
            manageRepurchaseItem: {
                productImportFailedModal: { title, description }
            }
        }
    } = useTranslator();

    const { user } = useAuth();

    const { redirect } = useRedirectToPainel2();
    const replace = useReplace();

    const platformUrl = useMemo(
        () =>
            redirect(
                replace(
                    routesName.integrations,
                    { platformId: user?.platform?.integration || '' },
                    { start: '{', end: '}' }
                ),
                false
            ),
        [redirect, replace, user]
    );

    return (
        <Dialog {...options}>
            <DialogContentStyled>
                <DialogHeading>{String(title)}</DialogHeading>
                <DialogDescription>{HtmlReactParser(String(description))}</DialogDescription>
                <DialogFooter>
                    <Link href={platformUrl}>
                        <Button
                            color='purple'
                            buttonSize='small'
                            isOutlined
                            onClick={() => options.onOpenChange(false)}>
                            {String(buttons.integrateNow)}
                        </Button>
                    </Link>
                    <Link href={platformUrl}>
                        <Button color='purple' buttonSize='small' onClick={() => options.onOpenChange(false)}>
                            {String(buttons.verifyTheIntegration)}
                        </Button>
                    </Link>
                    <a href={`${routesName.zendesk}/category/9`} target='_blank' rel='noopener noreferrer'>
                        <Button
                            color='deepCerise'
                            buttonSize='small'
                            onClick={() => options.onOpenChange(false)}
                            styledAs='link'>
                            {String(buttons.iNeedHelp)}
                        </Button>
                    </a>
                </DialogFooter>
                <DialogClose onClick={options.onClose} />
            </DialogContentStyled>
        </Dialog>
    );
};
