export enum UpdateStoreItemErrorStatus {
    NoCategoryTemplatesAvailable,
    NoProductTemplatesAvailable,
    TemplateCanOnlyBeGeneralForStoreItem,
    TemplateCanOnlyBeCategoryOrGeneralForCategoryItem
}

export enum SystemErrorStatus {
    InternalError,
    UnauthorizedUser,
    RecordNotFound,
    NetworkError,
    TwoFactorCodeInvalid,
    TwoFactorResendTokenError
}

export enum UpdateSubToolErrorStatus {
    StoreNoCreditAvailable,
    SubToolNotConfigured,
    NotAllowedToUpdateToActiveCalculation
}

export enum HireLooseBalanceErrorStatus {
    InactiveSubscription,
    PaymentDeclined,
    PaymentError,
    InvalidTotalCredits,
    InvalidStore
}

export enum ImportContactErrorStatus {
    NoBalance,
    TriggerHasNoTemplates,
    TriggerIsInactive,
    TriggerDoesNotExist,
    NoValidContacts,
    TokenDoesNotExist
}
